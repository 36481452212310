

export interface CountryInfo {
  name: { [key: string]: string };
  flagUrl: string;
}


export interface EnhancedCountryInfo extends CountryInfo {
  countryCode: string;
}


export const countryCodeToCountryInfo: { [key: string]: CountryInfo } = {
  'AL': {
    name: {
      'en': 'Albania',
      'no': 'Albania',
    },
    flagUrl: 'https://media.api-sports.io/flags/al.svg',
  },
  'DZ': {
    name: {
      'en': 'Algeria',
      'no': 'Algerie',
    },
    flagUrl: 'https://media.api-sports.io/flags/dz.svg',
  },
  'AD': {
    name: {
      'en': 'Andorra',
      'no': 'Andorra',
    },
    flagUrl: 'https://media.api-sports.io/flags/ad.svg',
  },
  'AO': {
    name: {
      'en': 'Angola',
      'no': 'Angola',
    },
    flagUrl: 'https://media.api-sports.io/flags/ao.svg',
  },
  'AR': {
    name: {
      'en': 'Argentina',
      'no': 'Argentina',
    },
    flagUrl: 'https://media.api-sports.io/flags/ar.svg',
  },
  'AM': {
    name: {
      'en': 'Armenia',
      'no': 'Armenia',
    },
    flagUrl: 'https://media.api-sports.io/flags/am.svg',
  },
  'AU': {
    name: {
      'en': 'Australia',
      'no': 'Australia',
    },
    flagUrl: 'https://media.api-sports.io/flags/au.svg',
  },
  'AT': {
    name: {
      'en': 'Austria',
      'no': 'Østerrike',
    },
    flagUrl: 'https://media.api-sports.io/flags/at.svg',
  },
  'AZ': {
    name: {
      'en': 'Azerbaijan',
      'no': 'Aserbajdsjan',
    },
    flagUrl: 'https://media.api-sports.io/flags/az.svg',
  },
  'BH': {
    name: {
      'en': 'Bahrain',
      'no': 'Bahrain',
    },
    flagUrl: 'https://media.api-sports.io/flags/bh.svg',
  },
  'BD': {
    name: {
      'en': 'Bangladesh',
      'no': 'Bangladesh',
    },
    flagUrl: 'https://media.api-sports.io/flags/bd.svg',
  },
  'BY': {
    name: {
      'en': 'Belarus',
      'no': 'Hviterussland',
    },
    flagUrl: 'https://media.api-sports.io/flags/by.svg',
  },
  'BE': {
    name: {
      'en': 'Belgium',
      'no': 'Belgia',
    },
    flagUrl: 'https://media.api-sports.io/flags/be.svg',
  },
  'BJ': {
    name: {
      'en': 'Benin',
      'no': 'Benin',
    },
    flagUrl: 'https://media.api-sports.io/flags/bj.svg',
  },
  'BT': {
    name: {
      'en': 'Bhutan',
      'no': 'Bhutan',
    },
    flagUrl: 'https://media.api-sports.io/flags/bt.svg',
  },
  'BO': {
    name: {
      'en': 'Bolivia',
      'no': 'Bolivia',
    },
    flagUrl: 'https://media.api-sports.io/flags/bo.svg',
  },
  'BA': {
    name: {
      'en': 'Bosnia', // Bosnia and Herzegovina
      'no': 'Bosnia', // Bosnia-Hercegovina
    },
    flagUrl: 'https://media.api-sports.io/flags/ba.svg',
  },
  'BW': {
    name: {
      'en': 'Botswana',
      'no': 'Botswana',
    },
    flagUrl: 'https://media.api-sports.io/flags/bw.svg',
  },
  'BR': {
    name: {
      'en': 'Brazil',
      'no': 'Brasil',
    },
    flagUrl: 'https://media.api-sports.io/flags/br.svg',
  },
  'BN': {
    name: {
      'en': 'Brunei',
      'no': 'Brunei',
    },
    flagUrl: 'https://media.api-sports.io/flags/bn.svg',
  },
  'BG': {
    name: {
      'en': 'Bulgaria',
      'no': 'Bulgaria',
    },
    flagUrl: 'https://media.api-sports.io/flags/bg.svg',
  },
  'BF': {
    name: {
      'en': 'Burkina Faso',
      'no': 'Burkina Faso',
    },
    flagUrl: 'https://media.api-sports.io/flags/bf.svg',
  },
  'BI': {
    name: {
      'en': 'Burundi',
      'no': 'Burundi',
    },
    flagUrl: 'https://media.api-sports.io/flags/bi.svg',
  },
  'KH': {
    name: {
      'en': 'Cambodia',
      'no': 'Kambodsja',
    },
    flagUrl: 'https://media.api-sports.io/flags/kh.svg',
  },
  'CM': {
    name: {
      'en': 'Cameroon',
      'no': 'Kamerun',
    },
    flagUrl: 'https://media.api-sports.io/flags/cm.svg',
  },
  'CA': {
    name: {
      'en': 'Canada',
      'no': 'Canada',
    },
    flagUrl: 'https://media.api-sports.io/flags/ca.svg',
  },
  'CV': {
    name: {
      'en': 'Cape Verde',
      'no': 'Kapp Verde',
    },
    flagUrl: 'https://media.api-sports.io/flags/cv.svg',
  },
  'CF': {
    name: {
      'en': 'Central African Republic',
      'no': 'Sentralafrikanske republikk', // Den sentralafrikanske republikk
    },
    flagUrl: 'https://media.api-sports.io/flags/cf.svg',
  },
  'TD': {
    name: {
      'en': 'Chad',
      'no': 'Chad',
    },
    flagUrl: 'https://media.api-sports.io/flags/td.svg',
  },
  'CL': {
    name: {
      'en': 'Chile',
      'no': 'Chile',
    },
    flagUrl: 'https://media.api-sports.io/flags/cl.svg',
  },
  'CN': {
    name: {
      'en': 'China',
      'no': 'Kina',
    },
    flagUrl: 'https://media.api-sports.io/flags/cn.svg',
  },
  'CO': {
    name: {
      'en': 'Colombia',
      'no': 'Colombia',
    },
    flagUrl: 'https://media.api-sports.io/flags/co.svg',
  },
  'KM': {
    name: {
      'en': 'Comoros',
      'no': 'Comoros',
    },
    flagUrl: 'https://media.api-sports.io/flags/km.svg',
  },
  'CG': {
    name: {
      'en': 'Congo',
      'no': 'Kongo',
    },
    flagUrl: 'https://media.api-sports.io/flags/cg.svg',
  },
  'CD': {
    name: {
      'en': 'Congo (DR)', // Democratic Republic of the Congo
      'no': 'Kongo (DR)', // Den demokratiske republikken Kongo
    },
    flagUrl: 'https://media.api-sports.io/flags/cd.svg',
  },
  'CR': {
    name: {
      'en': 'Costa Rica',
      'no': 'Costa Rica',
    },
    flagUrl: 'https://media.api-sports.io/flags/cr.svg',
  },
  'HR': {
    name: {
      'en': 'Croatia',
      'no': 'Kroatia',
    },
    flagUrl: 'https://media.api-sports.io/flags/hr.svg',
  },
  'CU': {
    name: {
      'en': 'Cuba',
      'no': 'Cuba',
    },
    flagUrl: 'https://media.api-sports.io/flags/cu.svg',
  },
  'CY': {
    name: {
      'en': 'Cyprus',
      'no': 'Kypros',
    },
    flagUrl: 'https://media.api-sports.io/flags/cy.svg',
  },
  'CZ': {
    name: {
      'en': 'Czech Republic',
      'no': 'Tsjekkia',
    },
    flagUrl: 'https://media.api-sports.io/flags/cz.svg',
  },
  'DK': {
    name: {
      'en': 'Denmark',
      'no': 'Danmark',
    },
    flagUrl: 'https://media.api-sports.io/flags/dk.svg',
  },
  'DJ': {
    name: {
      'en': 'Djibouti',
      'no': 'Djibouti',
    },
    flagUrl: 'https://media.api-sports.io/flags/dj.svg',
  },
  'DM': {
    name: {
      'en': 'Dominica',
      'no': 'Dominica',
    },
    flagUrl: 'https://media.api-sports.io/flags/dm.svg',
  },
  'DO': {
    name: {
      'en': 'Dominican Republic', // The Dominican Republic
      'no': 'Dominikanske republikk', // Den dominikanske republikk
    },
    flagUrl: 'https://media.api-sports.io/flags/do.svg',
  },
  'EC': {
    name: {
      'en': 'Ecuador',
      'no': 'Ecuador',
    },
    flagUrl: 'https://media.api-sports.io/flags/ec.svg',
  },
  'EG': {
    name: {
      'en': 'Egypt',
      'no': 'Egypt',
    },
    flagUrl: 'https://media.api-sports.io/flags/eg.svg',
  },
  'SV': {
    name: {
      'en': 'El Salvador',
      'no': 'El Salvador',
    },
    flagUrl: 'https://media.api-sports.io/flags/sv.svg',
  },
  'GQ': {
    name: {
      'en': 'Equatorial Guinea',
      'no': 'Ekvatorial-Guinea',
    },
    flagUrl: 'https://media.api-sports.io/flags/gq.svg',
  },
  'ER': {
    name: {
      'en': 'Eritrea',
      'no': 'Eritrea',
    },
    flagUrl: 'https://media.api-sports.io/flags/er.svg',
  },
  'EE': {
    name: {
      'en': 'Estonia',
      'no': 'Estland',
    },
    flagUrl: 'https://media.api-sports.io/flags/ee.svg',
  },
  'ET': {
    name: {
      'en': 'Ethiopia',
      'no': 'Etiopia',
    },
    flagUrl: 'https://media.api-sports.io/flags/et.svg',
  },
  'FO': {
    name: {
      'en': 'Faroe Islands',
      'no': 'Færøyene',
    },
    flagUrl: 'https://media.api-sports.io/flags/fo.svg',
  },
  'FJ': {
    name: {
      'en': 'Fiji',
      'no': 'Fiji',
    },
    flagUrl: 'https://media.api-sports.io/flags/fj.svg',
  },
  'FI': {
    name: {
      'en': 'Finland',
      'no': 'Finland',
    },
    flagUrl: 'https://media.api-sports.io/flags/fi.svg',
  },
  'FR': {
    name: {
      'en': 'France',
      'no': 'Frankrike',
    },
    flagUrl: 'https://media.api-sports.io/flags/fr.svg',
  },
  'GA': {
    name: {
      'en': 'Gabon',
      'no': 'Gabon',
    },
    flagUrl: 'https://media.api-sports.io/flags/ga.svg',
  },
  'GM': {
    name: {
      'en': 'Gambia',
      'no': 'Gambia',
    },
    flagUrl: 'https://media.api-sports.io/flags/gm.svg',
  },
  'GE': {
    name: {
      'en': 'Georgia',
      'no': 'Georgia',
    },
    flagUrl: 'https://media.api-sports.io/flags/ge.svg',
  },
  'DE': {
    name: {
      'en': 'Germany',
      'no': 'Tyskland',
    },
    flagUrl: 'https://media.api-sports.io/flags/de.svg',
  },
  'GH': {
    name: {
      'en': 'Ghana',
      'no': 'Ghana',
    },
    flagUrl: 'https://media.api-sports.io/flags/gh.svg',
  },
  'GR': {
    name: {
      'en': 'Greece',
      'no': 'Hellas',
    },
    flagUrl: 'https://media.api-sports.io/flags/gr.svg',
  },
  'GD': {
    name: {
      'en': 'Grenada',
      'no': 'Grenada',
    },
    flagUrl: 'https://media.api-sports.io/flags/gd.svg',
  },
  'GP': {
    name: {
      'en': 'Guadeloupe',
      'no': 'Guadeloupe',
    },
    flagUrl: 'https://media.api-sports.io/flags/gp.svg',
  },
  'GT': {
    name: {
      'en': 'Guatemala',
      'no': 'Guatemala',
    },
    flagUrl: 'https://media.api-sports.io/flags/gt.svg',
  },
  'GN': {
    name: {
      'en': 'Guinea',
      'no': 'Guinea',
    },
    flagUrl: 'https://media.api-sports.io/flags/gn.svg',
  },
  'GW': {
    name: {
      'en': 'Guinea-Bissau',
      'no': 'Guinea-Bissau',
    },
    flagUrl: 'https://media.api-sports.io/flags/gw.svg',
  },
  'GY': {
    name: {
      'en': 'Guyana',
      'no': 'Guyana',
    },
    flagUrl: 'https://media.api-sports.io/flags/gy.svg',
  },
  'HT': {
    name: {
      'en': 'Haiti',
      'no': 'Haiti',
    },
    flagUrl: 'https://media.api-sports.io/flags/ht.svg',
  },
  'HN': {
    name: {
      'en': 'Honduras',
      'no': 'Honduras',
    },
    flagUrl: 'https://media.api-sports.io/flags/hn.svg',
  },
  'HU': {
    name: {
      'en': 'Hungary',
      'no': 'Ungarn',
    },
    flagUrl: 'https://media.api-sports.io/flags/hu.svg',
  },
  'IS': {
    name: {
      'en': 'Iceland',
      'no': 'Island',
    },
    flagUrl: 'https://media.api-sports.io/flags/is.svg',
  },
  'IN': {
    name: {
      'en': 'India',
      'no': 'India',
    },
    flagUrl: 'https://media.api-sports.io/flags/in.svg',
  },
  'ID': {
    name: {
      'en': 'Indonesia',
      'no': 'Indonesia',
    },
    flagUrl: 'https://media.api-sports.io/flags/id.svg',
  },
  'IR': {
    name: {
      'en': 'Iran',
      'no': 'Iran',
    },
    flagUrl: 'https://media.api-sports.io/flags/ir.svg',
  },
  'IQ': {
    name: {
      'en': 'Iraq',
      'no': 'Irak',
    },
    flagUrl: 'https://media.api-sports.io/flags/iq.svg',
  },
  'IE': {
    name: {
      'en': 'Ireland',
      'no': 'Irland',
    },
    flagUrl: 'https://media.api-sports.io/flags/ie.svg',
  },
  'IL': {
    name: {
      'en': 'Israel',
      'no': 'Israel',
    },
    flagUrl: 'https://media.api-sports.io/flags/il.svg',
  },
  'IT': {
    name: {
      'en': 'Italy',
      'no': 'Italia',
    },
    flagUrl: 'https://media.api-sports.io/flags/it.svg',
  },
  'CI': {
    name: {
      'en': 'Ivory Coast',
      'no': 'Elfenbenskysten',
    },
    flagUrl: 'https://media.api-sports.io/flags/ci.svg',
  },
  'JM': {
    name: {
      'en': 'Jamaica',
      'no': 'Jamaica',
    },
    flagUrl: 'https://media.api-sports.io/flags/jm.svg',
  },
  'JP': {
    name: {
      'en': 'Japan',
      'no': 'Japan',
    },
    flagUrl: 'https://media.api-sports.io/flags/jp.svg',
  },
  'JO': {
    name: {
      'en': 'Jordan',
      'no': 'Jordan',
    },
    flagUrl: 'https://media.api-sports.io/flags/jo.svg',
  },
  'KZ': {
    name: {
      'en': 'Kazakhstan',
      'no': 'Kasakhstan',
    },
    flagUrl: 'https://media.api-sports.io/flags/kz.svg',
  },
  'KE': {
    name: {
      'en': 'Kenya',
      'no': 'Kenya',
    },
    flagUrl: 'https://media.api-sports.io/flags/ke.svg',
  },
  'KI': {
    name: {
      'en': 'Kiribati',
      'no': 'Kiribati',
    },
    flagUrl: 'https://media.api-sports.io/flags/ki.svg',
  },
  'KW': {
    name: {
      'en': 'Kuwait',
      'no': 'Kuwait',
    },
    flagUrl: 'https://media.api-sports.io/flags/kw.svg',
  },
  'KG': {
    name: {
      'en': 'Kyrgyzstan',
      'no': 'Kirgisistan',
    },
    flagUrl: 'https://media.api-sports.io/flags/kg.svg',
  },
  'LA': {
    name: {
      'en': 'Laos',
      'no': 'Laos',
    },
    flagUrl: 'https://media.api-sports.io/flags/la.svg',
  },
  'LV': {
    name: {
      'en': 'Latvia',
      'no': 'Latvia',
    },
    flagUrl: 'https://media.api-sports.io/flags/lv.svg',
  },
  'LB': {
    name: {
      'en': 'Lebanon',
      'no': 'Libanon',
    },
    flagUrl: 'https://media.api-sports.io/flags/lb.svg',
  },
  'LS': {
    name: {
      'en': 'Lesotho',
      'no': 'Lesotho',
    },
    flagUrl: 'https://media.api-sports.io/flags/ls.svg',
  },
  'LR': {
    name: {
      'en': 'Liberia',
      'no': 'Liberia',
    },
    flagUrl: 'https://media.api-sports.io/flags/lr.svg',
  },
  'LY': {
    name: {
      'en': 'Libya',
      'no': 'Libya',
    },
    flagUrl: 'https://media.api-sports.io/flags/ly.svg',
  },
  'LI': {
    name: {
      'en': 'Liechtenstein',
      'no': 'Liechtenstein',
    },
    flagUrl: 'https://media.api-sports.io/flags/li.svg',
  },
  'LT': {
    name: {
      'en': 'Lithuania',
      'no': 'Litauen',
    },
    flagUrl: 'https://media.api-sports.io/flags/lt.svg',
  },
  'LU': {
    name: {
      'en': 'Luxembourg',
      'no': 'Luxembourg',
    },
    flagUrl: 'https://media.api-sports.io/flags/lu.svg',
  },
  'MG': {
    name: {
      'en': 'Madagascar',
      'no': 'Madagaskar',
    },
    flagUrl: 'https://media.api-sports.io/flags/mg.svg',
  },
  'MW': {
    name: {
      'en': 'Malawi',
      'no': 'Malawi',
    },
    flagUrl: 'https://media.api-sports.io/flags/mw.svg',
  },
  'MY': {
    name: {
      'en': 'Malaysia',
      'no': 'Malaysia',
    },
    flagUrl: 'https://media.api-sports.io/flags/my.svg',
  },
  'MV': {
    name: {
      'en': 'Maldives',
      'no': 'Maldivene',
    },
    flagUrl: 'https://media.api-sports.io/flags/mv.svg',
  },
  'ML': {
    name: {
      'en': 'Mali',
      'no': 'Mali',
    },
    flagUrl: 'https://media.api-sports.io/flags/ml.svg',
  },
  'MT': {
    name: {
      'en': 'Malta',
      'no': 'Malta',
    },
    flagUrl: 'https://media.api-sports.io/flags/mt.svg',
  },
  'MH': {
    name: {
      'en': 'Marshall Islands',
      'no': 'Marshalløyene',
    },
    flagUrl: 'https://media.api-sports.io/flags/mh.svg',
  },
  'MR': {
    name: {
      'en': 'Mauritania',
      'no': 'Mauritania',
    },
    flagUrl: 'https://media.api-sports.io/flags/mr.svg',
  },
  'MU': {
    name: {
      'en': 'Mauritius',
      'no': 'Mauritius',
    },
    flagUrl: 'https://media.api-sports.io/flags/mu.svg',
  },
  'MX': {
    name: {
      'en': 'Mexico',
      'no': 'Mexico',
    },
    flagUrl: 'https://media.api-sports.io/flags/mx.svg',
  },
  'FM': {
    name: {
      'en': 'Micronesia',
      'no': 'Mikronesia',
    },
    flagUrl: 'https://media.api-sports.io/flags/fm.svg',
  },
  'MD': {
    name: {
      'en': 'Moldova',
      'no': 'Moldova',
    },
    flagUrl: 'https://media.api-sports.io/flags/md.svg',
  },
  'MC': {
    name: {
      'en': 'Monaco',
      'no': 'Monaco',
    },
    flagUrl: 'https://media.api-sports.io/flags/mc.svg',
  },
  'MN': {
    name: {
      'en': 'Mongolia',
      'no': 'Mongolia',
    },
    flagUrl: 'https://media.api-sports.io/flags/mn.svg',
  },
  'ME': {
    name: {
      'en': 'Montenegro',
      'no': 'Montenegro',
    },
    flagUrl: 'https://media.api-sports.io/flags/me.svg',
  },
  'MA': {
    name: {
      'en': 'Morocco',
      'no': 'Marokko',
    },
    flagUrl: 'https://media.api-sports.io/flags/ma.svg',
  },
  'MZ': {
    name: {
      'en': 'Mozambique',
      'no': 'Mosambik',
    },
    flagUrl: 'https://media.api-sports.io/flags/mz.svg',
  },
  'MM': {
    name: {
      'en': 'Myanmar',
      'no': 'Myanmar',
    },
    flagUrl: 'https://media.api-sports.io/flags/mm.svg',
  },
  'NA': {
    name: {
      'en': 'Namibia',
      'no': 'Namibia',
    },
    flagUrl: 'https://media.api-sports.io/flags/na.svg',
  },
  'NR': {
    name: {
      'en': 'Nauru',
      'no': 'Nauru',
    },
    flagUrl: 'https://media.api-sports.io/flags/nr.svg',
  },
  'NP': {
    name: {
      'en': 'Nepal',
      'no': 'Nepal',
    },
    flagUrl: 'https://media.api-sports.io/flags/np.svg',
  },
  'NL': {
    name: {
      'en': 'Netherlands',
      'no': 'Nederland',
    },
    flagUrl: 'https://media.api-sports.io/flags/nl.svg',
  },
  'NZ': {
    name: {
      'en': 'New Zealand',
      'no': 'New Zealand',
    },
    flagUrl: 'https://media.api-sports.io/flags/nz.svg',
  },
  'NI': {
    name: {
      'en': 'Nicaragua',
      'no': 'Nicaragua',
    },
    flagUrl: 'https://media.api-sports.io/flags/ni.svg',
  },
  'NE': {
    name: {
      'en': 'Niger',
      'no': 'Niger',
    },
    flagUrl: 'https://media.api-sports.io/flags/ne.svg',
  },
  'NG': {
    name: {
      'en': 'Nigeria',
      'no': 'Nigeria',
    },
    flagUrl: 'https://media.api-sports.io/flags/ng.svg',
  },
  'MK': {
    name: {
      'en': 'North Macedonia',
      'no': 'Nord-Makedonia',
    },
    flagUrl: 'https://media.api-sports.io/flags/mk.svg',
  },
  'NO': {
    name: {
      'en': 'Norway',
      'no': 'Norge',
    },
    flagUrl: 'https://media.api-sports.io/flags/no.svg',
  },
  'OM': {
    name: {
      'en': 'Oman',
      'no': 'Oman',
    },
    flagUrl: 'https://media.api-sports.io/flags/om.svg',
  },
  'PK': {
    name: {
      'en': 'Pakistan',
      'no': 'Pakistan',
    },
    flagUrl: 'https://media.api-sports.io/flags/pk.svg',
  },
  'PW': {
    name: {
      'en': 'Palau',
      'no': 'Palau',
    },
    flagUrl: 'https://media.api-sports.io/flags/pw.svg',
  },
  'PA': {
    name: {
      'en': 'Panama',
      'no': 'Panama',
    },
    flagUrl: 'https://media.api-sports.io/flags/pa.svg',
  },
  'PG': {
    name: {
      'en': 'Papua New Guinea',
      'no': 'Papua Ny-Guinea',
    },
    flagUrl: 'https://media.api-sports.io/flags/pg.svg',
  },
  'PY': {
    name: {
      'en': 'Paraguay',
      'no': 'Paraguay',
    },
    flagUrl: 'https://media.api-sports.io/flags/py.svg',
  },
  'PE': {
    name: {
      'en': 'Peru',
      'no': 'Peru',
    },
    flagUrl: 'https://media.api-sports.io/flags/pe.svg',
  },
  'PH': {
    name: {
      'en': 'Philippines',
      'no': 'Filippinene',
    },
    flagUrl: 'https://media.api-sports.io/flags/ph.svg',
  },
  'PL': {
    name: {
      'en': 'Poland',
      'no': 'Polen',
    },
    flagUrl: 'https://media.api-sports.io/flags/pl.svg',
  },
  'PT': {
    name: {
      'en': 'Portugal',
      'no': 'Portugal',
    },
    flagUrl: 'https://media.api-sports.io/flags/pt.svg',
  },
  'QA': {
    name: {
      'en': 'Qatar',
      'no': 'Qatar',
    },
    flagUrl: 'https://media.api-sports.io/flags/qa.svg',
  },
  'RO': {
    name: {
      'en': 'Romania',
      'no': 'Romania',
    },
    flagUrl: 'https://media.api-sports.io/flags/ro.svg',
  },
  'RU': {
    name: {
      'en': 'Russia',
      'no': 'Russland',
    },
    flagUrl: 'https://media.api-sports.io/flags/ru.svg',
  },
  'RW': {
    name: {
      'en': 'Rwanda',
      'no': 'Rwanda',
    },
    flagUrl: 'https://media.api-sports.io/flags/rw.svg',
  },
  // 'KN': {
  //   name: {
  //     'en': 'Saint Kitts and Nevis',
  //     'no': 'Saint Kitts og Nevis',
  //   },
  //   flagUrl: 'https://media.api-sports.io/flags/kn.svg',
  // },
  // 'LC': {
  //   name: {
  //     'en': 'Saint Lucia',
  //     'no': 'Saint Lucia',
  //   },
  //   flagUrl: 'https://media.api-sports.io/flags/lc.svg',
  // },
  // 'VC': {
  //   name: {
  //     'en': 'Saint Vincent and the Grenadines',
  //     'no': 'Saint Vincent og Grenadinene',
  //   },
  //   flagUrl: 'https://media.api-sports.io/flags/vc.svg',
  // },
  'WS': {
    name: {
      'en': 'Samoa',
      'no': 'Samoa',
    },
    flagUrl: 'https://media.api-sports.io/flags/ws.svg',
  },
  'SM': {
    name: {
      'en': 'San Marino',
      'no': 'San Marino',
    },
    flagUrl: 'https://media.api-sports.io/flags/sm.svg',
  },
  // 'ST': {
  //   name: {
  //     'en': 'Sao Tome and Principe',
  //     'no': 'Sao Tome og Principe',
  //   },
  //   flagUrl: 'https://media.api-sports.io/flags/st.svg',
  // },
  'SA': {
    name: {
      'en': 'Saudi Arabia',
      'no': 'Saudi-Arabia',
    },
    flagUrl: 'https://media.api-sports.io/flags/sa.svg',
  },
  'SN': {
    name: {
      'en': 'Senegal',
      'no': 'Senegal',
    },
    flagUrl: 'https://media.api-sports.io/flags/sn.svg',
  },
  'RS': {
    name: {
      'en': 'Serbia',
      'no': 'Serbia',
    },
    flagUrl: 'https://media.api-sports.io/flags/rs.svg',
  },
  'SC': {
    name: {
      'en': 'Seychelles',
      'no': 'Seychellene',
    },
    flagUrl: 'https://media.api-sports.io/flags/sc.svg',
  },
  'SL': {
    name: {
      'en': 'Sierra Leone',
      'no': 'Sierra Leone',
    },
    flagUrl: 'https://media.api-sports.io/flags/sl.svg',
  },
  'SG': {
    name: {
      'en': 'Singapore',
      'no': 'Singapore',
    },
    flagUrl: 'https://media.api-sports.io/flags/sg.svg',
  },
  'SK': {
    name: {
      'en': 'Slovakia',
      'no': 'Slovakia',
    },
    flagUrl: 'https://media.api-sports.io/flags/sk.svg',
  },
  'SI': {
    name: {
      'en': 'Slovenia',
      'no': 'Slovenia',
    },
    flagUrl: 'https://media.api-sports.io/flags/si.svg',
  },
  'SB': {
    name: {
      'en': 'Solomon Islands',
      'no': 'Salomonøyene',
    },
    flagUrl: 'https://media.api-sports.io/flags/sb.svg',
  },
  'SO': {
    name: {
      'en': 'Somalia',
      'no': 'Somalia',
    },
    flagUrl: 'https://media.api-sports.io/flags/so.svg',
  },
  'ZA': {
    name: {
      'en': 'South Africa',
      'no': 'Sør-Afrika',
    },
    flagUrl: 'https://media.api-sports.io/flags/za.svg',
  },
  'KR': {
    name: {
      'en': 'South Korea',
      'no': 'Sør-Korea',
    },
    flagUrl: 'https://media.api-sports.io/flags/kr.svg',
  },
  'ES': {
    name: {
      'en': 'Spain',
      'no': 'Spania',
    },
    flagUrl: 'https://media.api-sports.io/flags/es.svg',
  },
  'LK': {
    name: {
      'en': 'Sri Lanka',
      'no': 'Sri Lanka',
    },
    flagUrl: 'https://media.api-sports.io/flags/lk.svg',
  },
  'SD': {
    name: {
      'en': 'Sudan',
      'no': 'Sudan',
    },
    flagUrl: 'https://media.api-sports.io/flags/sd.svg',
  },
  'SR': {
    name: {
      'en': 'Suriname',
      'no': 'Surinam',
    },
    flagUrl: 'https://media.api-sports.io/flags/sr.svg',
  },
  'SZ': {
    name: {
      'en': 'Swaziland',
      'no': 'Swaziland',
    },
    flagUrl: 'https://media.api-sports.io/flags/sz.svg',
  },
  'SE': {
    name: {
      'en': 'Sweden',
      'no': 'Sverige',
    },
    flagUrl: 'https://media.api-sports.io/flags/se.svg',
  },
  'CH': {
    name: {
      'en': 'Switzerland',
      'no': 'Sveits',
    },
    flagUrl: 'https://media.api-sports.io/flags/ch.svg',
  },
  'SY': {
    name: {
      'en': 'Syria',
      'no': 'Syria',
    },
    flagUrl: 'https://media.api-sports.io/flags/sy.svg',
  },
  'TJ': {
    name: {
      'en': 'Tajikistan',
      'no': 'Tadsjikistan',
    },
    flagUrl: 'https://media.api-sports.io/flags/tj.svg',
  },
  'TZ': {
    name: {
      'en': 'Tanzania',
      'no': 'Tanzania',
    },
    flagUrl: 'https://media.api-sports.io/flags/tz.svg',
  },
  'TH': {
    name: {
      'en': 'Thailand',
      'no': 'Thailand',
    },
    flagUrl: 'https://media.api-sports.io/flags/th.svg',
  },
  // 'TL': {
  //   name: {
  //     'en': 'Timor-Leste',
  //     'no': 'Timor-Leste',
  //   },
  //   flagUrl: 'https://media.api-sports.io/flags/tl.svg',
  // },
  'TG': {
    name: {
      'en': 'Togo',
      'no': 'Togo',
    },
    flagUrl: 'https://media.api-sports.io/flags/tg.svg',
  },
  'TO': {
    name: {
      'en': 'Tonga',
      'no': 'Tonga',
    },
    flagUrl: 'https://media.api-sports.io/flags/to.svg',
  },
  'TT': {
    name: {
      'en': 'Trinidad and Tobago',
      'no': 'Trinidad og Tobago',
    },
    flagUrl: 'https://media.api-sports.io/flags/tt.svg',
  },
  'TN': {
    name: {
      'en': 'Tunisia',
      'no': 'Tunisia',
    },
    flagUrl: 'https://media.api-sports.io/flags/tn.svg',
  },
  'TR': {
    name: {
      'en': 'Turkey',
      'no': 'Tyrkia',
    },
    flagUrl: 'https://media.api-sports.io/flags/tr.svg',
  },
  'TM': {
    name: {
      'en': 'Turkmenistan',
      'no': 'Turkmenistan',
    },
    flagUrl: 'https://media.api-sports.io/flags/tm.svg',
  },
  'TV': {
    name: {
      'en': 'Tuvalu',
      'no': 'Tuvalu',
    },
    flagUrl: 'https://media.api-sports.io/flags/tv.svg',
  },
  'UG': {
    name: {
      'en': 'Uganda',
      'no': 'Uganda',
    },
    flagUrl: 'https://media.api-sports.io/flags/ug.svg',
  },
  'UA': {
    name: {
      'en': 'Ukraine',
      'no': 'Ukraina',
    },
    flagUrl: 'https://media.api-sports.io/flags/ua.svg',
  },
  'AE': {
    name: {
      'en': 'Arab Emirates', // United Arab Emirates
      'no': 'Emiratene', // De forente arabiske emirater
    },
    flagUrl: 'https://media.api-sports.io/flags/ae.svg',
  },
  'GB': {
    name: {
      'en': 'United Kingdom',
      'no': 'Storbritannia',
    },
    flagUrl: 'https://media.api-sports.io/flags/gb.svg',
  },
  'GB-ENG': {
    name: {
      'en': 'England',
      'no': 'England',
    },
    flagUrl: 'https://media.api-sports.io/flags/gb-eng.svg',
  },
  'GB-NIR': {
    name: {
      'en': 'Northern Ireland',
      'no': 'Nord-Irland',
    },
    flagUrl: 'https://media.api-sports.io/flags/gb-nir.svg',
  },
  'GB-SCT': {
    name: {
      'en': 'Scotland',
      'no': 'Skottland',
    },
    flagUrl: 'https://media.api-sports.io/flags/gb-sct.svg',
  },
  'GB-WLS': {
    name: {
      'en': 'Wales',
      'no': 'Wales',
    },
    flagUrl: 'https://media.api-sports.io/flags/gb-wls.svg',
  },
  'US': {
    name: {
      'en': 'United States',
      'no': 'USA',
    },
    flagUrl: 'https://media.api-sports.io/flags/us.svg',
  },
  'UY': {
    name: {
      'en': 'Uruguay',
      'no': 'Uruguay',
    },
    flagUrl: 'https://media.api-sports.io/flags/uy.svg',
  },
  'UZ': {
    name: {
      'en': 'Uzbekistan',
      'no': 'Usbekistan',
    },
    flagUrl: 'https://media.api-sports.io/flags/uz.svg',
  },
  'VU': {
    name: {
      'en': 'Vanuatu',
      'no': 'Vanuatu',
    },
    flagUrl: 'https://media.api-sports.io/flags/vu.svg',
  },
  'VA': {
    name: {
      'en': 'Vatican City',
      'no': 'Vatikanstaten',
    },
    flagUrl: 'https://media.api-sports.io/flags/va.svg',
  },
  'VE': {
    name: {
      'en': 'Venezuela',
      'no': 'Venezuela',
    },
    flagUrl: 'https://media.api-sports.io/flags/ve.svg',
  },
  'VN': {
    name: {
      'en': 'Vietnam',
      'no': 'Vietnam',
    },
    flagUrl: 'https://media.api-sports.io/flags/vn.svg',
  },
  'YE': {
    name: {
      'en': 'Yemen',
      'no': 'Jemen',
    },
    flagUrl: 'https://media.api-sports.io/flags/ye.svg',
  },
  'ZM': {
    name: {
      'en': 'Zambia',
      'no': 'Zambia',
    },
    flagUrl: 'https://media.api-sports.io/flags/zm.svg',
  },
  'ZW': {
    name: {
      'en': 'Zimbabwe',
      'no': 'Zimbabwe',
    },
    flagUrl: 'https://media.api-sports.io/flags/zw.svg',
  },
  'AX': {
    name: {
      'en': 'Åland Islands',
      'no': 'Åland',
    },
    flagUrl: 'https://media.api-sports.io/flags/ax.svg',
  },
  // 'BQ': {
  //   name: {
  //     'en': 'Bonaire, Sint Eustatius and Saba',
  //     'no': 'Bonaire, Sint Eustatius og Saba',
  //   },
  //   flagUrl: 'https://media.api-sports.io/flags/bq.svg',
  // },
  'CW': {
    name: {
      'en': 'Curaçao',
      'no': 'Curaçao',
    },
    flagUrl: 'https://media.api-sports.io/flags/cw.svg',
  },
  'GG': {
    name: {
      'en': 'Guernsey',
      'no': 'Guernsey',
    },
    flagUrl: 'https://media.api-sports.io/flags/gg.svg',
  },
  'IM': {
    name: {
      'en': 'Isle of Man',
      'no': 'Man',
    },
    flagUrl: 'https://media.api-sports.io/flags/im.svg',
  },
  'JE': {
    name: {
      'en': 'Jersey',
      'no': 'Jersey',
    },
    flagUrl: 'https://media.api-sports.io/flags/je.svg',
  },
  'BL': {
    name: {
      'en': 'Saint Barthélemy',
      'no': 'Saint-Barthélemy',
    },
    flagUrl: 'https://media.api-sports.io/flags/bl.svg',
  },
  'MF': {
    name: {
      'en': 'Saint Martin', // (French part)
      'no': 'Saint-Martin',
    },
    flagUrl: 'https://media.api-sports.io/flags/mf.svg',
  },
  'SX': {
    name: {
      'en': 'Sint Maarten', // (Dutch part)
      'no': 'Sint Maarten',
    },
    flagUrl: 'https://media.api-sports.io/flags/sx.svg',
  },
  'SS': {
    name: {
      'en': 'South Sudan',
      'no': 'Sør-Sudan',
    },
    flagUrl: 'https://media.api-sports.io/flags/ss.svg',
  },
  'XK': {
    name: {
      'en': 'Kosovo',
      'no': 'Kosovo',
    },
    flagUrl: 'https://media.api-sports.io/flags/xk.svg',
  },
  'PS': {
    name: {
      'en': 'Palestine',
      'no': 'Palestina',
    },
    flagUrl: 'https://media.api-sports.io/flags/ps.svg',
  },
  'TW': {
    name: {
      'en': 'Taiwan',
      'no': 'Taiwan',
    },
    flagUrl: 'https://media.api-sports.io/flags/tw.svg',
  },
  'AQ': {
    name: {
      'en': 'Antarctica',
      'no': 'Antarktis',
    },
    flagUrl: 'https://media.api-sports.io/flags/aq.svg',
  },
  'BV': {
    name: {
      'en': 'Bouvet Island',
      'no': 'Bouvetøya',
    },
    flagUrl: 'https://media.api-sports.io/flags/bv.svg',
  },
  'GL': {
    name: {
      'en': 'Greenland',
      'no': 'Grønland',
    },
    flagUrl: 'https://media.api-sports.io/flags/gl.svg',
  },
  // 'TF': {
  //   name: {
  //     'en': 'French Southern Territories',
  //     'no': 'De franske sørterritorier',
  //   },
  //   flagUrl: 'https://media.api-sports.io/flags/tf.svg',
  // },
  // 'HM': {
  //   name: {
  //     'en': 'Heard Island and McDonald Islands',
  //     'no': 'Heard- og McDonaldøyene',
  //   },
  //   flagUrl: 'https://media.api-sports.io/flags/hm.svg',
  // },
  // 'IO': {
  //   name: {
  //     'en': 'British Indian Ocean Territory',
  //     'no': 'Det britiske territoriet i Indiahavet',
  //   },
  //   flagUrl: 'https://media.api-sports.io/flags/io.svg',
  // },
  // 'UM': {
  //   name: {
  //     'en': 'United States Minor Outlying Islands',
  //     'no': 'USAs ytre småøyer',
  //   },
  //   flagUrl: 'https://media.api-sports.io/flags/um.svg',
  // },
  'UN': {
    name: {
      'en': 'United Nations',
      'no': 'FN',
    },
    flagUrl: 'https://media.api-sports.io/flags/un.svg',
  },
  'EU': {
    name: {
      'en': 'Europe',
      'no': 'Europa',
    },
    flagUrl: 'https://media.api-sports.io/flags/eu.svg',
  },

};


export const enhancedCountryList: EnhancedCountryInfo[] = Object.entries(countryCodeToCountryInfo).map(([key, value]) => ({
  countryCode: key,
  ...value,
}));


export const countryCodeListSubset: string[] = [
  'NO',
  'SE',
  'DK',
  'FI',
  'IS',
  'DE',
  'NL',
  'BE',
  'GB',
  // 'GB-ENG',
  // 'GB-SCT',
  // 'GB-NIR',
  // 'GB-WLS',
  'FR',
  'ES',
  'PT',
  'IT',
  'GR',
  'AT',
  'CH',
  'IE',
  'LU',
  'EE',
  'LV',
  'LT',
  'PL',
  'CZ',
  'SK',
  'HU',
  'SI',
  'HR',
  'BA',
  'ME',
  'AL',
  'MK',
  'RS',
  'BG',
  'RO',
  'MD',
  'UA',
  'BY',
  'RU',
  'TR',
  'CY',
];
