import { clubConfigs } from '../static/clubConfigs';
import fokus_logo_rounded from '../../assets/clubLogos/fokus_logo_rounded.png';


export const getClubColor = (club: string) => {
  if (club in clubConfigs) {
    return clubConfigs[club]['color'];
  }
  return '#ffdb65';
};


export const getClubSecondaryColor = (club: string) => {
  if (club in clubConfigs) {
    return clubConfigs[club]['secondaryColor'];
  }
  return '#ffdb65';
};


export const getClubLightColor = (club: string) => {
  if (club in clubConfigs) {
    return clubConfigs[club]['lightColor'];
  }
  return '#ffdf76';
};


export const getClubContrastColor = (club: string) => {
  if (club in clubConfigs) {
    return clubConfigs[club]['contrastColor'];
  }
  return '#2bffbf';
};


export const getClubLogo = (club: string) => {
  if (club && club in clubConfigs) {
    return clubConfigs[club]['logo'];
  }
  return fokus_logo_rounded;
};


export const getClubLogoFormat = (club: string) => {
  if (club && club in clubConfigs) {
    return clubConfigs[club]['logoFormat'];
  }
  return 'square';
};


export const getClubLogoShadow = (club: string) => {
  if (club && club in clubConfigs) {
    return clubConfigs[club]['logoShadow'];
  }
  return false;
};
