import '../../platform.css';

import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { squadsState } from '../../recoil/atoms/squadsState';
import { selectedPlayerState } from '../../recoil/atoms/selectedPlayerState';
import { playerOverviewsState } from '../../recoil/atoms/playerOverviewsState';
import { teamsState } from '../../recoil/atoms/teamsState';

import { DynamicTabLine } from '../../components/tabLines/DynamicTabLine';
import { PlayerView } from '../../components/playerView/PlayerView';
import { FlexibleJsonMapping, PlayerOverviews } from '../../types';
import { staticTeamKeys } from '../../static/propertyValues';
import { LogisticTeamView } from '../../components/teamView/LogisticTeamView';


interface TeamOrSquadBuilderProps {
  isSquad: boolean;
}

export const TeamOrSquadBuilder: React.FC<TeamOrSquadBuilderProps> = ({ isSquad }) => {

  const selectedPlayer = useRecoilValue(selectedPlayerState);
  const playerOverviews = useRecoilValue(playerOverviewsState);

  const squads = useRecoilValue(squadsState);
  const teams = useRecoilValue(teamsState);
  const teamsOrSquads = isSquad ? squads : teams;

  const [searchedPlayerData, setSearchedPlayerData] = useState<PlayerOverviews | undefined>(undefined);

  const [logisticTeamViewToggle, setLogisticTeamViewToggle] = useState(false);


  const getDynamicTeams = (squadsOrTeams: FlexibleJsonMapping | null) => {
    if (!squadsOrTeams) {
      return null;
    }

    const dynamicTeams: FlexibleJsonMapping = {};

    // filter out static teams
    Object.keys(squadsOrTeams)
      .filter(key => !(staticTeamKeys.includes(key)))
      .forEach(key => {
        dynamicTeams[key] = squadsOrTeams[key];
      });

    return dynamicTeams;
  };

  const dynamicTeamsOrSquads = isSquad ? teamsOrSquads : getDynamicTeams(teamsOrSquads);

  const [activeTab, setActiveTab] = useState<string | undefined>(
    dynamicTeamsOrSquads && Object.keys(dynamicTeamsOrSquads).length > 0 ? Object.keys(dynamicTeamsOrSquads)[0] : undefined
  );


  const setTabAndResetExpansions = (tab: string | undefined) => {
    setActiveTab(tab);
    if (tab !== activeTab) {
      setLogisticTeamViewToggle(!logisticTeamViewToggle);
    }
  };


  return (
    <div className='platform-view-section platform-view-section-dark'>

      <div className={'player-view-outer-container' + (selectedPlayer ? ' player-view-outer-container-player-selected' : '')}>
        {selectedPlayer && playerOverviews && (
          <PlayerView
            playerOverview={playerOverviews[Number(selectedPlayer.id)] ?? (searchedPlayerData ? searchedPlayerData[Number(selectedPlayer.id)] : undefined)}
            squadId={isSquad ? activeTab : undefined}
            squadName={isSquad && squads && activeTab ? squads[activeTab]?.name : undefined}
          />
        )}
      </div>

      <DynamicTabLine
        teamsOrSquads={dynamicTeamsOrSquads}
        activeTab={activeTab}
        setActiveTab={setTabAndResetExpansions}
        isSquad={isSquad}
      />

      <LogisticTeamView
        teamOrSquadId={activeTab}
        teamOrSquad={dynamicTeamsOrSquads && activeTab ? dynamicTeamsOrSquads[activeTab] : undefined}
        isTabLine={true}
        playerData={searchedPlayerData}
        setPlayerData={setSearchedPlayerData}

        isSquad={isSquad}

        logisticTeamViewToggle={logisticTeamViewToggle}
      />

    </div>
  );
};
