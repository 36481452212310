import './../documents.css';

import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { competitionsState } from '../../../recoil/atoms/competitionsState';
import { useGlobalModal } from '../../../recoil/hooks/openGlobalModal';

import { Dialog } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import EditIcon from '@mui/icons-material/Edit';
import CommentIcon from '@mui/icons-material/Comment';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import NotificationsIcon from '@mui/icons-material/Notifications';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';

// import EuroIcon from '@mui/icons-material/Euro';
// import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
// import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import {
  ClauseCondition,
  TotalCondition,
  TotalSubCondition,
  SeasonCondition,
  PlayerClause,
  PlayerSubClause,
} from '../../../types';

import { staticLanguageMap } from '../../../../common/static/staticLanguageMap';
import { ConditionModal } from '../../modals/clauseModals/ConditionModal';
import { getDisplayEconomicValue } from '../../../utils/currencyUtils';
import { ResolveTotalConditionModal } from '../../modals/clauseModals/ResolveTotalConditionModal';
import { SeasonAccordion } from '../../accordions/SeasonAccordion';
import { getSeasonStartDate } from '../../../views/economy/Economy';
import { ResolveSellOnConditionModal } from '../../modals/clauseModals/ResolveSellOnConditionModal';
import { deepCloneObject } from '../../../utils/utils';
import { OverrideTotalConditionModal } from '../../modals/clauseModals/OverrideTotalConditionModal';
import { ClauseState, SubClauseState } from './clauseInitialState';
import { ClauseAction } from './clauseReducer';


export const getSeasonConditionWithoutCurrentValues = (seasonCondition: SeasonCondition) => {
  const seasonConditionCopy = deepCloneObject(seasonCondition);
  delete seasonConditionCopy.seasonToCurrentValues;
  return seasonConditionCopy;
};


export const getTotalConditionWithoutCurrentValue = (totalCondition: TotalCondition) => {
  const totalConditionCopy = deepCloneObject(totalCondition);
  delete totalConditionCopy.currentValue;
  return totalConditionCopy;
};


export const getSubClauseWithoutCurrentValuesAndNextClubAndConditionalFees = (subClause: SubClauseState): PlayerSubClause => {
  const subClauseCopy = {
    ...subClause,
    totalConditions: subClause.totalConditions.map(getTotalConditionWithoutCurrentValue),
    seasonConditions: subClause.seasonConditions.map(getSeasonConditionWithoutCurrentValues),
  };
  delete subClauseCopy.nextClubId;
  delete subClauseCopy.conditionalFees;
  return subClauseCopy;
};


interface ConditionsProps {
  existingClauseSelected: PlayerClause | undefined;
  state: ClauseState;
  dispatch: React.Dispatch<ClauseAction>;
}


export const Conditions: React.FC<ConditionsProps> = ({
  existingClauseSelected,
  state,
  dispatch,
}) => {

  const userConfig = useRecoilValue(userConfigState);

  const competitions = useRecoilValue(competitionsState);

  const { handleOpenConfirmModal, handleOpenTextModal } = useGlobalModal();

  const [conditionModalHasUnsavedChanges, setConditionModalHasUnsavedChanges] = useState(false);

  const [isResolveSellOnConditionModalOpen, setIsResolveSellOnConditionModalOpen] = useState(false);

  const [isResolveTotalConditionModalOpen, setIsResolveTotalConditionModalOpen] = useState(false);
  const [isOverrideTotalConditionModalOpen, setIsOverrideTotalConditionModalOpen] = useState(false);
  const [totalConditionIndexOfModal, setTotalConditionIndexOfModal] = useState<number | undefined>(undefined);
  const [totalSubConditionIndexOfModal, setTotalSubConditionIndexOfModal] = useState<number | undefined>(undefined);

  const [conditionToEdit, setConditionToEdit] = useState<ClauseCondition | undefined>(undefined);

  const today = new Date();
  const oneYearAgo = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());


  const stateAccessor: ClauseState | SubClauseState = state.subClauses && state.additionalClauseState.activeSubClauseTab !== undefined
    ? state.subClauses[state.additionalClauseState.activeSubClauseTab]
    : state;


  const handleEditConditionClick = (condition: ClauseCondition) => {
    setConditionToEdit(condition);
    dispatch({ type: 'SET_IS_CONDITION_MODAL_OPEN', payload: true });
  };


  const closeConditionModal = () => {
    setConditionModalHasUnsavedChanges(false);
    dispatch({ type: 'SET_IS_CONDITION_MODAL_OPEN', payload: false });

    setTimeout(() => {
      setConditionToEdit(undefined);
    }, 150);
  };


  const addOrEditCondition = (newCondition: ClauseCondition) => {
    dispatch({ type: 'ADD_OR_EDIT_CONDITION', payload: { newCondition, conditionToEdit } });
    closeConditionModal();
  };


  const deleteCondition = () => {
    if (conditionToEdit) {
      dispatch({ type: 'DELETE_CONDITION', payload: conditionToEdit.conditionId });
    }
    closeConditionModal();
  };


  const handleOpenCloseConditionModal = () => {
    handleOpenConfirmModal(
      closeConditionModal,
      conditionToEdit ? 'discardChanges?' : 'discardCondition?',
      conditionToEdit ? 'changesWillNotBeSaved' : 'conditionWillNotBeSaved'
    );
  };


  const handleCloseConditionModalClick = () => {
    if (conditionModalHasUnsavedChanges) {
      handleOpenCloseConditionModal();
    }
    else {
      closeConditionModal();
    }
  };


  // Total condition functions

  const handleTotalConditionValueClick = (totalConditionIndex: number) => {
    setTotalConditionIndexOfModal(totalConditionIndex);
    setIsOverrideTotalConditionModalOpen(true);
  };


  const closeOverrideTotalConditionModal = () => {
    setTotalConditionIndexOfModal(undefined);
    setIsOverrideTotalConditionModalOpen(false);
  };


  const reactivateTotalCondition = async (totalConditionIndex: number, totalSubConditionIndex: number) => {
    dispatch({ type: 'REACTIVATE_TOTAL_CONDITION', payload: { totalConditionIndex, totalSubConditionIndex } });
    closeResolveOrReactivateTotalConditionModal();
  };


  const closeResolveOrReactivateTotalConditionModal = () => {
    setTotalConditionIndexOfModal(undefined);
    setTotalSubConditionIndexOfModal(undefined);
    setIsResolveTotalConditionModalOpen(false);
  };


  const onTotalConditionResolveOrReactivateIconClick = (conditionIndex: number, subConditionIndex: number, isResolve: boolean) => {
    setTotalConditionIndexOfModal(conditionIndex);
    setTotalSubConditionIndexOfModal(subConditionIndex);
    if (isResolve) {
      setIsResolveTotalConditionModalOpen(true);
    }
    else {
      handleOpenConfirmModal(
        () => reactivateTotalCondition(conditionIndex, subConditionIndex),
        'reactivateCondition',
        'reactivateConditionInfo',
        closeResolveOrReactivateTotalConditionModal
      );
    }
  };


  const confirmIncorrectTransfer = () => {
    if (existingClauseSelected && stateAccessor.nextClubId !== undefined) {
      dispatch({ type: 'SET_INCORRECT_NEXT_CLUB', payload: stateAccessor.nextClubId });
    }
  };


  const confirmRegretIncorrectTransfer = (incorrectClubId: number) => {
    if (existingClauseSelected) {
      dispatch({ type: 'REGRET_INCORRECT_NEXT_CLUB', payload: incorrectClubId });
    }
  };


  const getCompetitionsTitle = (competitionIds: number[], competitionString: string) => {
    if (!competitions || competitionString !== 'someCompetitions' || competitionIds.length <= 1) return undefined;

    let title = '';
    competitionIds.forEach(competitionId => {
      const competition = competitions[competitionId];
      title += competition ? competition.name + ', ' : '';
    });

    return title.slice(0, -2);
  };

  const getCompetitionsDisplay = (competitionIds: number[], competitionString: string) => {
    if (!userConfig || !competitions) return '';

    if (competitionString !== 'someCompetitions') {
      return staticLanguageMap['_' + competitionString][userConfig.language];
    }

    if (competitionIds.length === 1) {
      const competitionName = competitions[competitionIds[0]]?.name ??
        (competitionIds[0] === 100000 ? 'European Qualifiers' : '');

      return competitionName;
    }

    return staticLanguageMap['_someCompetitions'][userConfig.language];
  };


  const isActiveClause = !(existingClauseSelected?.isDeleted || existingClauseSelected?.isResolved);

  const isLastOrOnlySubClause = !state.subClauses || (
    state.additionalClauseState.activeSubClauseTab !== undefined && state.additionalClauseState.activeSubClauseTab === state.subClauses.length - 1
  );

  const isActiveSubClause = isActiveClause && isLastOrOnlySubClause;

  const isMaxConditionalFeeReached = stateAccessor.maxConditionalFees !== null && (stateAccessor.conditionalFees ?? 0) >= stateAccessor.maxConditionalFees;

  const sellingClub = stateAccessor.sellingClubId !== undefined ? state.additionalClauseState.allClubs[stateAccessor.sellingClubId] : undefined;
  const buyingClub = stateAccessor.buyingClubId !== undefined ? state.additionalClauseState.allClubs[stateAccessor.buyingClubId] : undefined;
  const nextClub = stateAccessor.nextClubId !== undefined ? state.additionalClauseState.allClubs[stateAccessor.nextClubId] : undefined;


  return (
    <div className='clause-conditions-section'>

      <Dialog
        open={state.additionalClauseState.isConditionModalOpen}
        onClose={() => handleCloseConditionModalClick()}
        PaperProps={{ sx: { borderRadius: '0px' } }}>
        <ConditionModal
          closeModal={() => handleCloseConditionModalClick()}

          conditionToEdit={conditionToEdit}
          state={state}

          addOrEditCondition={addOrEditCondition}
          deleteCondition={deleteCondition}

          hasUnsavedChanges={conditionModalHasUnsavedChanges}
          setHasUnsavedChanges={setConditionModalHasUnsavedChanges}
        />
      </Dialog>

      <Dialog
        open={isOverrideTotalConditionModalOpen}
        onClose={() => closeOverrideTotalConditionModal()}
        PaperProps={{ sx: { borderRadius: '0px' } }}>
        <OverrideTotalConditionModal
          closeModal={() => closeOverrideTotalConditionModal()}
          state={state}
          dispatch={dispatch}
          totalConditionIndex={totalConditionIndexOfModal}
        />
      </Dialog>

      <Dialog
        open={isResolveTotalConditionModalOpen}
        onClose={() => closeResolveOrReactivateTotalConditionModal()}
        PaperProps={{ sx: { borderRadius: '0px' } }}>
        <ResolveTotalConditionModal
          closeModal={() => closeResolveOrReactivateTotalConditionModal()}
          state={state}
          dispatch={dispatch}
          totalConditionIndex={totalConditionIndexOfModal}
          totalSubConditionIndex={totalSubConditionIndexOfModal}
        />
      </Dialog>

      <Dialog
        open={isResolveSellOnConditionModalOpen}
        onClose={() => setIsResolveSellOnConditionModalOpen(false)}
        PaperProps={{ sx: { borderRadius: '0px' } }}>
        <ResolveSellOnConditionModal
          closeModal={() => setIsResolveSellOnConditionModalOpen(false)}
          state={state}
          dispatch={dispatch}
        />
      </Dialog>

      {stateAccessor.totalConditions.map((condition, conditionIndex) => {

        const maxThreshold = condition.subConditions
          ? condition.subConditions[condition.subConditions.length - 1].threshold
          : 0;

        const currentValue = condition.currentValue;
        const progressValue = Math.min(currentValue ?? 0, maxThreshold);

        const allThresholdsAreResolved = condition.subConditions.every(subCondition => subCondition.isResolved);

        const progressBarPositioningOffset = condition.subConditions[0].threshold / maxThreshold < 0.05
          ? 35
          : condition.subConditions[0].threshold / maxThreshold < 0.1
            ? 15
            : 0;

        return (
          <div key={conditionIndex} className='clause-existing-condition'>
            <div
              className='clause-existing-condition-row'
              style={{ height: 32 }}
            >
              <div className='clause-existing-condition-top-row-title'>
                {userConfig && condition.condition ? staticLanguageMap[condition.condition][userConfig.language] : ''}

                {condition.minMinutesPlayed && userConfig && (
                  <div style={{ fontSize: 12, marginLeft: 3, marginTop: 1 }}>
                    {' (' + staticLanguageMap['atLeast'][userConfig.language] + ' ' + condition.minMinutesPlayed + ' min)'}
                  </div>
                )}

                {currentValue !== undefined && ': '}
                {currentValue !== undefined && (
                  <div
                    title={userConfig ? staticLanguageMap['incorrectValue?'][userConfig.language] : undefined}
                    className='clause-existing-condition-top-row-current-value'
                  >
                    {currentValue}
                  </div>
                )}
              </div>

              <div className='clause-existing-condition-top-row-info' title={getCompetitionsTitle(condition.competitionIds, condition.competitionString)}>
                {'(' + getCompetitionsDisplay(condition.competitionIds, condition.competitionString) + ')'}
              </div>

              <div
                className='clause-incorrect-transfer-section'
                style={{ marginLeft: 30, fontSize: 10, marginTop: -1 }}
                onClick={() => handleTotalConditionValueClick(conditionIndex)}
              >
                <div
                  className='clause-existing-condition-status-icon'
                  style={{ transition: '0ms', paddingRight: 20, marginLeft: -20 }}>
                  <ErrorOutlineIcon style={{ fontSize: 14, marginTop: -3 }} />
                </div>
                {userConfig ? staticLanguageMap['incorrectValue?'][userConfig.language] : ''}
              </div>

              {(condition.comment ?? '') !== '' && (
                <div className='clause-existing-condition-top-row-comment'>
                  <div className='clause-content-icon' onClick={() => handleOpenTextModal(condition.comment)}>
                    <CommentIcon style={{ fontSize: 15, marginBottom: -2 }} />
                  </div>
                  <div style={{ marginLeft: 2 }}>
                    {condition.comment}
                  </div>
                </div>
              )}
            </div>

            <div
              className='clause-existing-condition-row'
              style={{
                height: 55,
                // paddingBottom: state.additionalClauseState.activeSubClauseTab !== undefined ? 14 : 0
              }}
            >
              <div
                className='clause-existing-condition-total-progress-bar'
                style={{
                  marginLeft: 15 + progressBarPositioningOffset,
                  width: 'calc(100% - ' + (65 + progressBarPositioningOffset) + 'px)'
                }}
              >

                {condition.subConditions && condition.subConditions.map((subCondition: TotalSubCondition, thresholdIndex: number) => {

                  const isReached = subCondition.threshold <= progressValue;

                  return (
                    <div
                      key={conditionIndex + '-' + thresholdIndex}
                      className='clause-existing-condition-threshold-container'
                      style={{ left: (subCondition.threshold / maxThreshold) * 100 + '%' }}
                    >
                      <div className='clause-existing-condition-threshold'>
                        {subCondition.threshold}
                      </div>

                      <div className='clause-existing-condition-threshold-amount-container'>
                        <div className='clause-existing-condition-threshold-amount'>
                          {subCondition.isResolved && (
                            <div
                              className='clause-existing-condition-status-icon clause-existing-condition-resolved-icon'
                              title={userConfig ? staticLanguageMap['reactivateCondition'][userConfig.language] : undefined}
                              onClick={() => onTotalConditionResolveOrReactivateIconClick(conditionIndex, thresholdIndex, false)}
                            >
                              <TaskAltIcon style={{ fontSize: 14 }} />
                            </div>
                          )}

                          {!subCondition.isResolved && isReached && isActiveSubClause && !isMaxConditionalFeeReached && (
                            <div
                              className='clause-existing-condition-status-icon clause-existing-condition-notification-icon'
                              title={userConfig ? staticLanguageMap['resolveCondition'][userConfig.language] : undefined}
                              onClick={() => onTotalConditionResolveOrReactivateIconClick(conditionIndex, thresholdIndex, true)}
                            >
                              <NotificationsIcon style={{ fontSize: 14, marginTop: -1 }} />
                            </div>
                          )}

                          {!subCondition.isResolved && !isReached && isActiveSubClause && !isMaxConditionalFeeReached && (
                            <div
                              className='clause-existing-condition-status-icon clause-existing-condition-pending-icon'
                              title={userConfig ? staticLanguageMap['resolveCondition'][userConfig.language] : undefined}
                              onClick={() => onTotalConditionResolveOrReactivateIconClick(conditionIndex, thresholdIndex, true)}
                            >
                              <HourglassTopIcon style={{ fontSize: 14 }} />
                            </div>
                          )}

                          {!subCondition.isResolved
                            && existingClauseSelected
                            && (!isActiveSubClause || isMaxConditionalFeeReached)
                            && (
                              <div
                                className='clause-existing-condition-status-icon clause-existing-condition-discarded-icon'
                                title={userConfig ? staticLanguageMap['resolveCondition'][userConfig.language] : undefined}
                                onClick={() => onTotalConditionResolveOrReactivateIconClick(conditionIndex, thresholdIndex, true)}
                              >
                                <CancelOutlinedIcon style={{ fontSize: 14 }} />
                              </div>
                            )
                          }

                          {getDisplayEconomicValue(subCondition.resolvedAmount ?? subCondition.amount, true)}

                          {/* {getDisplayEconomicValue(subCondition.resolvedAmount ?? subCondition.amount * (
                            state.subClauses && state.additionalClauseState.activeSubClauseTab !== undefined
                              ? state.subClauses[state.additionalClauseState.activeSubClauseTab].totalSellOnPercentage / 100
                              : 1
                          ), true)}

                          {state.subClauses && state.additionalClauseState.activeSubClauseTab !== undefined && (
                            <div className='clause-existing-condition-threshold-amount-description'>
                              {state.subClauses[state.additionalClauseState.activeSubClauseTab].totalSellOnPercentage}%
                              {userConfig ? staticLanguageMap[' of '][userConfig.language] : ''}
                              {getDisplayEconomicValue(subCondition.amount)}
                            </div>
                          )} */}
                        </div>
                      </div>

                    </div>
                  );
                })}

                <div
                  className='clause-existing-condition-total-progress'
                  style={{ width: (progressValue / maxThreshold) * 100 + '%' }}>
                  &nbsp;
                </div>
              </div>
            </div>

            {(!existingClauseSelected || state.additionalClauseState.isEditMode) && (
              <div className='clause-existing-condition-edit-icon-container'>
                <div
                  className='clause-content-icon'
                  onClick={() => handleEditConditionClick(condition)}
                  title={userConfig ? staticLanguageMap['editCondition'][userConfig.language] : undefined}>
                  <EditIcon style={{ fontSize: 16, marginTop: 1 }} />
                </div>
              </div>
            )}

            {allThresholdsAreResolved && !(!existingClauseSelected || state.additionalClauseState.isEditMode) && (
              <div className='clause-existing-condition-edit-icon-container fade-in'>
                <div
                  title={userConfig ? staticLanguageMap['editCondition'][userConfig.language] : undefined}>
                  <TaskAltIcon style={{ fontSize: 16, marginTop: 6, marginRight: 6, color: '#9df78b' }} />
                </div>
              </div>
            )}
          </div>
        );
      })}

      {stateAccessor.seasonConditions.map((condition, conditionIndex) => {

        const isMaxSeasonConditionAmountReached = condition.maxAmount !== undefined && (condition.totalResolvedAmount ?? 0) >= condition.maxAmount;

        return (
          <div key={conditionIndex} className='clause-existing-condition'>
            <div
              className='clause-existing-condition-row'
              style={{ height: 32 }}>
              <div className='clause-existing-condition-top-row-title'>
                {userConfig ? staticLanguageMap['seasonCondition'][userConfig.language] : ''}
              </div>

              <div className='flex-row' style={{ marginLeft: 15, marginRight: 3, fontSize: 11, marginBottom: -1, color: 'lightgray' }}>
                {getDisplayEconomicValue((condition.totalResolvedAmount ?? 0), true)}
                {' / '}

                {condition.maxAmount !== undefined && (
                  <div style={{ marginLeft: 3 }}>
                    {getDisplayEconomicValue(condition.maxAmount, true)}
                  </div>
                )}

                {condition.maxAmount === undefined &&
                  <AllInclusiveIcon style={{ fontSize: 14, marginLeft: 3, marginBottom: -2, color: 'lightgray' }} />}
              </div>

              <div className='clause-existing-condition-top-row-info' title={getCompetitionsTitle(condition.competitionIds, condition.competitionString)}>

                {userConfig && condition.competitionString && condition.seasonString
                  ? (
                    '(' +
                    staticLanguageMap['_' + condition.seasonString][userConfig.language] +
                    ' ' +
                    (
                      (condition.competitionString === 'theNationalTeam' || condition.competitionString === 'allNationalTeams')
                        ? staticLanguageMap['for'][userConfig.language]
                        : staticLanguageMap['in'][userConfig.language]
                    ) +
                    ' ' +
                    getCompetitionsDisplay(condition.competitionIds, condition.competitionString) +
                    ')'
                  )
                  : ''}
              </div>

              {(condition.comment ?? '') !== '' && (
                <div className='clause-existing-condition-top-row-comment'>
                  <div className='clause-content-icon' onClick={() => handleOpenTextModal(condition.comment)}>
                    <CommentIcon style={{ fontSize: 15, marginBottom: -2 }} />
                  </div>
                  <div style={{ marginLeft: 2 }}>
                    {condition.comment}
                  </div>
                </div>
              )}
            </div>

            {(!existingClauseSelected || state.additionalClauseState.isEditMode) && (
              <div className='clause-existing-condition-edit-icon-container'>
                <div
                  className='clause-content-icon'
                  onClick={() => handleEditConditionClick(condition)}
                  title={userConfig ? staticLanguageMap['editCondition'][userConfig.language] : undefined}>
                  <EditIcon style={{ fontSize: 16, marginTop: 1 }} />
                </div>
              </div>
            )}

            {isMaxSeasonConditionAmountReached && !(!existingClauseSelected || state.additionalClauseState.isEditMode) && (
              <div className='clause-existing-condition-edit-icon-container fade-in'>
                <div
                  title={userConfig ? staticLanguageMap['editCondition'][userConfig.language] : undefined}>
                  <TaskAltIcon style={{ fontSize: 16, marginTop: 6, marginRight: 6, color: '#9df78b' }} />
                </div>
              </div>
            )}

            {condition.seasonStrings.map((seasonOption, seasonIndex) => {

              // we always want to show all seasons up until the current season, unless any max amount is reached
              // if the max amount is reached, we only want to show resolved seasons
              // we always want to show at least on season, even if it is in the future, as long as the clause is active
              // we always want to show at least one pending season, even if it's in the future, as long the clause is active

              const seasonStartDate = getSeasonStartDate(seasonOption);
              const seasonStartedOverAYearAgo = seasonStartDate < oneYearAgo;
              const seasonIsInTheFuture = seasonStartDate > today;

              const overallStatus = condition.seasonToCurrentStatuses[seasonOption]?.overallStatus;
              if ((isMaxSeasonConditionAmountReached || isMaxConditionalFeeReached) && overallStatus !== 'resolved') return null;

              if (seasonStartDate > today && !overallStatus) {
                // if the season is in the future, we only want to show it if the clause is active
                if (!isActiveSubClause) return null;

                // if the season is in the future and the clause is active, we want to show it if it the first season without a status
                const statusOfPreviousSeason = condition.seasonToCurrentStatuses[condition.seasonStrings[seasonIndex - 1]]?.overallStatus;
                if (seasonIndex > 0 && !statusOfPreviousSeason) return null;
              }

              // we want to expand all seasons that are not resolved and are not in the future
              const shouldStartExpanded = !overallStatus && isActiveSubClause && !isMaxConditionalFeeReached && !seasonIsInTheFuture;

              return (
                <div
                  key={(existingClauseSelected?.id ?? '') + seasonIndex}
                  style={{ marginTop: seasonIndex === 0 ? 2 : 0 }}
                  className='clause-existing-condition-season-accordion'>
                  <SeasonAccordion
                    state={state}
                    seasonString={seasonOption}
                    seasonCondition={condition}
                    shouldStartExpanded={shouldStartExpanded}
                    seasonStartedOverAYearAgo={seasonStartedOverAYearAgo}
                    isActiveSubClause={isActiveSubClause && !isMaxConditionalFeeReached}
                    dispatch={dispatch}
                  />
                </div>
              );
            })}
          </div>
        );
      })}

      {stateAccessor.sellOnCondition && (
        <div className='clause-existing-condition'>

          <div className='clause-existing-condition-row' style={{ height: 30 }}>

            <div className='clause-existing-condition-top-row-title'>
              {userConfig ? staticLanguageMap['sellOnCondition'][userConfig.language] : ''}
              : {stateAccessor.sellOnCondition.sellOnPercentage}%
            </div>

            {stateAccessor.sellOnCondition.dynamicSellOnPercentages && (
              <div className='clause-existing-condition-top-row-info flex-row' style={{ marginLeft: 8 }}>
                {'('}
                {stateAccessor.sellOnCondition.dynamicSellOnPercentages.map((datedValue, index) => {
                  return (
                    <div key={index} className='flex-row'>
                      {datedValue.value}%
                      {stateAccessor.sellOnCondition?.dynamicSellOnPercentages && index < stateAccessor.sellOnCondition.dynamicSellOnPercentages.length - 1
                        ? <ArrowRightAltIcon style={{ fontSize: 12, marginLeft: 1, marginRight: 1, marginTop: 1, color: '#ffffffd4' }} />
                        : ''}
                    </div>
                  );
                })}
                {')'}
              </div>
            )}

            <div className='clause-existing-condition-top-row-info' style={{ marginLeft: stateAccessor.sellOnCondition.dynamicSellOnPercentages ? 6 : 8 }}>
              {userConfig
                ? ('(' + (stateAccessor.sellOnCondition.isNet
                  ? staticLanguageMap['_net'][userConfig.language]
                  : staticLanguageMap['_gross'][userConfig.language]) + ')')
                : ''}
            </div>

            {stateAccessor.sellOnCondition && (stateAccessor.sellOnCondition.comment ?? '') !== '' && (
              <div className='clause-existing-condition-top-row-comment'>
                <div className='clause-content-icon' onClick={() => handleOpenTextModal(stateAccessor.sellOnCondition?.comment ?? '')}>
                  <CommentIcon style={{ fontSize: 15, marginBottom: -2 }} />
                </div>
                <div style={{ marginLeft: 2 }}>
                  {stateAccessor.sellOnCondition.comment}
                </div>
              </div>
            )}
          </div>

          <div className='clause-existing-condition-row clause-existing-condition-sell-on-row'>

            <div className='flex-row'>
              {sellingClub
                && !stateAccessor.sellOnCondition.isResolved
                && !nextClub
                && isActiveSubClause
                && (
                  <div className='clause-sell-on-club-container'>
                    <div className='clause-sell-on-club-logo-container'>
                      <img
                        style={{ maxHeight: 14, maxWidth: 14 }}
                        src={sellingClub.logo_url}
                        alt={sellingClub.name}
                        draggable={false}
                      />
                    </div>
                    {sellingClub.name}
                  </div>
                )}

              {sellingClub
                && !stateAccessor.sellOnCondition.isResolved
                && !nextClub
                && isActiveSubClause
                && <ArrowRightAltIcon style={{ fontSize: 15, marginLeft: 3, marginRight: 3, marginBottom: -2, color: '#ffffffd4' }} />
              }

              {buyingClub && (
                <div className='clause-sell-on-club-container'>
                  <div className='clause-sell-on-club-logo-container'>
                    <img
                      style={{ maxHeight: 14, maxWidth: 14 }}
                      src={buyingClub.logo_url}
                      alt={buyingClub.name}
                      draggable={false}
                    />
                  </div>
                  {buyingClub.name}
                </div>
              )}

              {existingClauseSelected &&
                <ArrowRightAltIcon style={{ fontSize: 15, marginLeft: 3, marginRight: 3, marginBottom: -2, color: '#ffffffd4' }} />
              }

              {existingClauseSelected && nextClub && (
                <div className='clause-sell-on-club-container'>
                  <div className='clause-sell-on-club-logo-container'>
                    <img
                      style={{ maxHeight: 14, maxWidth: 14 }}
                      src={nextClub.logo_url}
                      alt={nextClub.name}
                      draggable={false}
                    />
                  </div>
                  {nextClub.name}
                </div>
              )}

              {existingClauseSelected
                && !nextClub
                && (stateAccessor.sellOnCondition.isResolved || !isActiveSubClause)
                && (
                  <div style={{ marginLeft: 4 }}>
                    ?
                  </div>
                )
              }

              {existingClauseSelected
                && !nextClub
                && !(stateAccessor.sellOnCondition.isResolved || !isActiveSubClause)
                && (
                  <div className='flex-row'>
                    <div
                      className='clause-existing-condition-status-icon clause-existing-condition-pending-icon'
                      title={userConfig ? staticLanguageMap['resolveCondition'][userConfig.language] : undefined}
                      style={{ marginLeft: 1, marginTop: -1 }}
                      onClick={() => setIsResolveSellOnConditionModalOpen(true)}
                    >
                      <HourglassTopIcon style={{ fontSize: 16 }} />
                    </div>

                    <div style={{ marginLeft: 22 }}>
                      {userConfig ? staticLanguageMap['(noTransferRegistered)'][userConfig.language] : ''}
                    </div>
                  </div>
                )
              }
            </div>

            {existingClauseSelected
              && nextClub
              && !(stateAccessor.sellOnCondition.isResolved || !isActiveSubClause)
              && (
                <div>
                  <div
                    className='clause-existing-condition-status-icon clause-existing-condition-notification-icon'
                    title={userConfig ? staticLanguageMap['resolveCondition'][userConfig.language] : undefined}
                    style={{ marginLeft: 16, marginTop: -8.5 }}
                    onClick={() => setIsResolveSellOnConditionModalOpen(true)}
                  >
                    <NotificationsIcon style={{ fontSize: 14, marginTop: -1 }} />
                  </div>
                </div>
              )
            }

            {stateAccessor.sellOnCondition.isResolved && (
              <div>
                <div
                  className='clause-existing-condition-status-icon clause-existing-condition-resolved-icon'
                  title={userConfig ? staticLanguageMap['reactivateCondition'][userConfig.language] : undefined}
                  style={{ marginLeft: 10, marginTop: -8.5 }}
                  onClick={() => handleOpenConfirmModal(
                    () => dispatch({ type: 'REACTIVATE_SELL_ON_CONDITION' }),
                    'reactivateCondition',
                    'reactivateConditionInfo'
                  )}
                >
                  <TaskAltIcon style={{ fontSize: 14 }} />
                </div>
              </div>
            )}

            {existingClauseSelected
              && !stateAccessor.sellOnCondition.isResolved
              && !isActiveSubClause
              && (
                <div>
                  <div
                    className='clause-existing-condition-status-icon clause-existing-condition-discarded-icon'
                    title={userConfig ? staticLanguageMap['reactivateCondition'][userConfig.language] : undefined}
                    style={{ marginLeft: 10, marginTop: -8.5 }}
                    onClick={() => setIsResolveSellOnConditionModalOpen(true)}
                  >
                    <CancelOutlinedIcon style={{ fontSize: 14 }} />
                  </div>
                </div>
              )}

            {existingClauseSelected
              && nextClub
              && !stateAccessor.sellOnCondition.isResolved
              && (
                <div
                  className='clause-incorrect-transfer-section'
                  onClick={() => handleOpenConfirmModal(confirmIncorrectTransfer, 'incorrectTransfer?', 'incorrectTransferInfo')}
                >
                  <div
                    className='clause-existing-condition-status-icon'
                    style={{ transition: '0ms', paddingRight: 20 }}
                  >
                    <ErrorOutlineIcon style={{ fontSize: 14 }} />
                  </div>
                  {userConfig ? staticLanguageMap['incorrectTransfer?'][userConfig.language] : ''}
                </div>
              )
            }
          </div>

          {state.isSoldPlayer
            && stateAccessor.sellOnCondition.isResolved
            && isActiveSubClause
            && (
              <div className='clause-existing-condition-row' style={{ height: 30 }} >
                <div
                  className='clause-sell-on-clause--add-sub-clause-link'
                  onClick={() => dispatch({ type: 'SET_IS_SUB_CLAUSE_MODAL_OPEN', payload: true })}
                >
                  <div
                    className='clause-existing-condition-status-icon clause-existing-condition-notification-icon'
                    style={{ marginLeft: -24, marginTop: -1 }}
                  >
                    <NotificationsIcon style={{ fontSize: 14, marginTop: -1 }} />
                  </div>
                  {userConfig ? staticLanguageMap['addClausesForNextClub'][userConfig.language] : ''}
                </div>
              </div>
            )
          }

          {!stateAccessor.sellOnCondition.isResolved
            && buyingClub
            && isActiveSubClause
            && stateAccessor.incorrectNextClubIds
            && stateAccessor.incorrectNextClubIds.length > 0
            && stateAccessor.incorrectNextClubIds.map((incorrectClubId, index) => {

              const incorrectClub = state.additionalClauseState.allClubs[incorrectClubId];

              return (
                <div
                  key={index}
                  className='clause-existing-condition-row clause-existing-condition-sell-on-row'
                  style={{
                    marginTop: index === 0 ? 6 : 0,
                    backgroundImage: 'linear-gradient(to right, #ffffffaa, #ffffffaa 90%, #ffffff00 97%)'
                  }}
                >
                  <div className='flex-row'>
                    <div className='clause-sell-on-club-container'>
                      <div className='clause-sell-on-club-logo-container'>
                        <img
                          style={{ maxHeight: 14, maxWidth: 14 }}
                          src={buyingClub.logo_url}
                          alt={buyingClub.name}
                          draggable={false}
                        />
                      </div>
                      {buyingClub.name}
                    </div>

                    <ArrowRightAltIcon style={{ fontSize: 15, marginLeft: 3, marginRight: 3, marginBottom: -2, color: '#ffffffd4' }} />

                    <div className='clause-sell-on-club-container'>
                      <div className='clause-sell-on-club-logo-container'>
                        <img
                          style={{ maxHeight: 14, maxWidth: 14 }}
                          src={incorrectClub.logo_url}
                          alt={incorrectClub.name}
                          draggable={false}
                        />
                      </div>
                      {incorrectClub.name}
                    </div>

                    <div
                      className='clause-incorrect-transfer-section'
                      onClick={() => handleOpenConfirmModal(
                        () => confirmRegretIncorrectTransfer(incorrectClubId),
                        'regretIncorrectTransfer?',
                        'regretIncorrectTransferInfo')
                      }
                      style={{ marginLeft: 28, fontSize: 11, marginTop: 1 }}
                    >
                      <div
                        className='clause-existing-condition-status-icon'
                        style={{ transition: '0ms', paddingRight: 10, marginTop: -3, marginLeft: -19 }}
                      >
                        <ErrorOutlineIcon style={{ fontSize: 14 }} />
                      </div>
                      {userConfig ? staticLanguageMap['markedAsIncorrectTransfer'][userConfig.language] : ''}
                    </div>
                  </div>
                </div>
              );
            })
          }

          {(!existingClauseSelected || state.additionalClauseState.isEditMode) && (
            <div className='clause-existing-condition-edit-icon-container'>
              <div
                className='clause-content-icon'
                onClick={() => stateAccessor.sellOnCondition ? handleEditConditionClick(stateAccessor.sellOnCondition) : null}
                title={userConfig ? staticLanguageMap['editCondition'][userConfig.language] : undefined}
              >
                <EditIcon style={{ fontSize: 16, marginTop: 1 }} />
              </div>
            </div>
          )}
        </div>
      )}


      {!stateAccessor.sellOnCondition
        && existingClauseSelected
        && isActiveSubClause
        && (nextClub || (stateAccessor.incorrectNextClubIds && stateAccessor.incorrectNextClubIds.length > 0))
        && (
          <div className='clause-existing-condition'>

            <div
              className='clause-existing-condition-row'
              style={{ height: 30 }}>
              <div className='clause-existing-condition-top-row-title'>
                {userConfig
                  ? nextClub
                    ? staticLanguageMap['transferRegisteredInfo'][userConfig.language]
                    : staticLanguageMap['registeredTransfersMarkedAsIncorrect'][userConfig.language] + ':'
                  : ''}
              </div>
            </div>

            {nextClub && (
              <div className='clause-existing-condition-row clause-existing-condition-sell-on-row'>

                {buyingClub && (
                  <div className='flex-row'>
                    <div className='clause-sell-on-club-container'>
                      <div className='clause-sell-on-club-logo-container'>
                        <img
                          style={{ maxHeight: 14, maxWidth: 14 }}
                          src={buyingClub.logo_url}
                          alt={buyingClub.name}
                          draggable={false}
                        />
                      </div>
                      {buyingClub.name}
                    </div>

                    <ArrowRightAltIcon style={{ fontSize: 15, marginLeft: 3, marginRight: 3, marginBottom: -2, color: '#ffffffd4' }} />

                    <div className='clause-sell-on-club-container'>
                      <div className='clause-sell-on-club-logo-container'>
                        <img
                          style={{ maxHeight: 14, maxWidth: 14 }}
                          src={nextClub.logo_url}
                          alt={nextClub.name}
                          draggable={false}
                        />
                      </div>
                      {nextClub.name}
                    </div>
                  </div>
                )}

                <div
                  className='clause-incorrect-transfer-section'
                  style={{ marginLeft: 35 }}
                  onClick={() => handleOpenConfirmModal(confirmIncorrectTransfer, 'incorrectTransfer?', 'incorrectTransferInfo')}
                >
                  <div
                    className='clause-existing-condition-status-icon'
                    style={{ transition: '0ms', paddingRight: 20, marginLeft: -20 }}>
                    <ErrorOutlineIcon style={{ fontSize: 14 }} />
                  </div>
                  {userConfig ? staticLanguageMap['incorrectTransfer?'][userConfig.language] : ''}
                </div>
              </div>
            )}

            {buyingClub
              && stateAccessor.incorrectNextClubIds
              && stateAccessor.incorrectNextClubIds.length > 0
              && stateAccessor.incorrectNextClubIds.map((incorrectClubId, index) => {

                const incorrectClub = state.additionalClauseState.allClubs[incorrectClubId];

                return (
                  <div
                    key={index}
                    className='clause-existing-condition-row clause-existing-condition-sell-on-row'
                    style={{
                      marginTop: index === 0 ? 6 : 0,
                      backgroundImage: 'linear-gradient(to right, #ffffffaa, #ffffffaa 90%, #ffffff00 97%)'
                    }}
                  >
                    <div className='flex-row'>
                      <div className='clause-sell-on-club-container'>
                        <div className='clause-sell-on-club-logo-container'>
                          <img
                            style={{ maxHeight: 14, maxWidth: 14 }}
                            src={buyingClub.logo_url}
                            alt={buyingClub.name}
                            draggable={false}
                          />
                        </div>
                        {buyingClub.name}
                      </div>

                      <ArrowRightAltIcon style={{ fontSize: 15, marginLeft: 3, marginRight: 3, marginBottom: -2, color: '#ffffffd4' }} />

                      <div className='clause-sell-on-club-container'>
                        <div className='clause-sell-on-club-logo-container'>
                          <img
                            style={{ maxHeight: 14, maxWidth: 14 }}
                            src={incorrectClub.logo_url}
                            alt={incorrectClub.name}
                            draggable={false}
                          />
                        </div>
                        {incorrectClub.name}
                      </div>

                      <div
                        className='clause-incorrect-transfer-section'
                        onClick={() => handleOpenConfirmModal(
                          () => confirmRegretIncorrectTransfer(incorrectClubId),
                          'regretIncorrectTransfer?',
                          'regretIncorrectTransferInfo')
                        }
                        style={{ marginLeft: 28, fontSize: 11, marginTop: 1 }}
                      >
                        <div
                          className='clause-existing-condition-status-icon'
                          style={{ transition: '0ms', paddingRight: 10, marginTop: -3, marginLeft: -19 }}
                        >
                          <ErrorOutlineIcon style={{ fontSize: 14 }} />
                        </div>
                        {userConfig ? staticLanguageMap['markedAsIncorrectTransfer'][userConfig.language] : ''}
                      </div>
                    </div>
                  </div>
                );
              })
            }
          </div>
        )
      }
    </div>
  );
};
