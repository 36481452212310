import './history.css';

import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { allUserConfigsState } from '../../recoil/atoms/allUserConfigsState';
import { teamsState } from '../../recoil/atoms/teamsState';
import { squadsState } from '../../recoil/atoms/squadsState';

import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import DescriptionIcon from '@mui/icons-material/Description';
import MessageIcon from '@mui/icons-material/Message';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import BlockIcon from '@mui/icons-material/Block';

import { FlexibleJsonMapping } from '../../types';
import { staticLanguageMap } from '../../../common/static/staticLanguageMap';
import { getDateLocaleFormat, getShortPlayerName } from '../../utils/playerUtils';
import { staticTeamKeys } from '../../static/propertyValues';
import { getPlatformActivityLast14Days } from '../../services/firestore/activities';
import { getShortUserName, userHasScoutAccess } from '../../utils/userUtils';


export const PlatformHistory: React.FC = () => {

  const userConfig = useRecoilValue(userConfigState);
  const allUsersWithSameClub = useRecoilValue(allUserConfigsState);
  const teams = useRecoilValue(teamsState);
  const squads = useRecoilValue(squadsState);

  const [platformHistory, setPlatformHistory] = useState<FlexibleJsonMapping[]>([]);


  const shouldShowDate = (index: number) => {
    if (index === 0) return true;

    const previousDate = platformHistory[index - 1]['date'].split('T')[0];
    const currentDate = platformHistory[index]['date'].split('T')[0];

    const previousUser = platformHistory[index - 1]['userEmail'];
    const currentUser = platformHistory[index]['userEmail'];

    return previousDate !== currentDate || previousUser !== currentUser;
  };


  const getTeamName = (teamId: string) => {
    if (!userConfig || !teams || !squads) return '';

    if (staticTeamKeys.includes(teamId)) {
      return staticLanguageMap[teamId][userConfig.language];
    }

    if (teams[teamId]) {
      return teams[teamId]['name'];
    }

    if (squads[teamId]) {
      return squads[teamId]['name'];
    }

    return null;
  };


  const getHistoryInfoText = (action: FlexibleJsonMapping, actionType: string, isCurrentUser: boolean) => {
    if (!userConfig || !teams) return '';

    const userName = isCurrentUser
      ? staticLanguageMap['you'][userConfig.language]
      : allUsersWithSameClub[action['userEmail']]
        ? getShortUserName(allUsersWithSameClub[action['userEmail']].name)
        : staticLanguageMap['unknownUser'][userConfig.language];

    if (actionType === 'playerActivity') {

      const fromName: string | undefined = getTeamName(action['fromTeamId']) ?? action['fromTeamName'];
      const toName: string | undefined = getTeamName(action['toTeamId']) ?? action['toTeamName'];

      if (fromName && toName) {
        return userName
          + ' '
          + staticLanguageMap['moved'][userConfig.language]
          + ' '
          + getShortPlayerName(action['playerName'])
          + ' '
          + staticLanguageMap['from'][userConfig.language]
          + ' '
          + fromName
          + ' '
          + staticLanguageMap['to'][userConfig.language]
          + ' '
          + toName;
      }
      else if (fromName) {
        return userName
          + ' '
          + staticLanguageMap['removed'][userConfig.language]
          + ' '
          + getShortPlayerName(action['playerName'])
          + ' '
          + staticLanguageMap['from'][userConfig.language]
          + ' '
          + fromName;
      }
      else if (toName) {
        return userName
          + ' '
          + staticLanguageMap['added'][userConfig.language]
          + ' '
          + getShortPlayerName(action['playerName'])
          + ' '
          + staticLanguageMap['on'][userConfig.language]
          + ' '
          + toName;
      }

      return null;
    }

    if (actionType === 'report') {

      const actionText = action['reportType'] === 'Attachment'
        ? (staticLanguageMap['uploaded'][userConfig.language] + ' ' + staticLanguageMap['anAttachment'][userConfig.language])
        : (staticLanguageMap['leftA'][userConfig.language] + ' ' + staticLanguageMap[action['reportType']][userConfig.language].toLocaleLowerCase());

      return userName
        + ' '
        + actionText
        + ' '
        + staticLanguageMap['on'][userConfig.language]
        + ' '
        + getShortPlayerName(action['playerName']);
    }

    // remaining action types will all be team/squad actions
    const teamOrSquadName = staticTeamKeys.includes(action['teamOrSquadName'])
      ? staticLanguageMap[action['teamOrSquadName']][userConfig.language]
      : action['teamOrSquadName'];

    if (actionType === 'comment') {
      return userName
        + ' '
        + staticLanguageMap['leftA'][userConfig.language]
        + ' '
        + staticLanguageMap['comment'][userConfig.language]
        + ' '
        + staticLanguageMap['on'][userConfig.language]
        + ' '
        + teamOrSquadName
        + ': '
        + action['action']['comment'];
    }

    if (actionType === 'archive') {
      return userName
        + ' '
        + staticLanguageMap['archived'][userConfig.language]
        + ' '
        + teamOrSquadName;
    }

    if (actionType === 'reset') {
      return userName
        + ' '
        + staticLanguageMap['didReset'][userConfig.language]
        + ' '
        + teamOrSquadName;
    }

    if (actionType === 'create') {
      return userName
        + ' '
        + staticLanguageMap['created'][userConfig.language]
        + ' '
        + teamOrSquadName;
    }

    if (actionType === 'editFormation') {
      return userName
        + ' '
        + staticLanguageMap['editedFormation'][userConfig.language]
        + ' '
        + staticLanguageMap['on'][userConfig.language]
        + ' '
        + teamOrSquadName
        + ' '
        + staticLanguageMap['to'][userConfig.language]
        + ' '
        + action['action']['newFormation'];
    }

    return null;
  };


  const scrollToBottom = () => {
    const commentField = document.querySelector('.platform-history-action-section');
    if (commentField) {
      commentField.scrollTop = commentField.scrollHeight;
    }
  };


  useEffect(() => {
    if (userConfig?.club && teams && squads) {
      getPlatformActivityLast14Days(teams, squads, userConfig.club).then((history) => {
        setPlatformHistory(history);

        const timer = setTimeout(() => {
          scrollToBottom();
        }, 100);

        return () => clearTimeout(timer);
      });
    }
  }, [teams, squads, userConfig?.club]);


  return (
    <div className='player-history-container' style={{ height: '100%', marginTop: 0 }}>

      {!userHasScoutAccess(userConfig) && (
        <div className='platform-history-overlay'>
          <BlockIcon style={{ fontSize: 100, color: '#ffffff22', paddingBottom: 30 }} />
        </div>
      )}

      <div className={'team-history-action-section player-history-action-section team-history-action-section-show platform-history-action-section'}>
        {platformHistory && platformHistory.map((action, index) => {

          const actionType = action['reportType'] ? 'report' : (action['action'] ? action['action']['actionType'] : 'playerActivity');

          const isCurrentUser = userConfig !== null && action['userEmail'] === userConfig.email;

          return (
            <div
              key={index}
              className='team-history-action player-history-action'>

              {shouldShowDate(index) && (
                <div
                  className={
                    'team-history-date-row player-history-date-row' +
                    (index === 0 ? ' team-history-first-date-row' : '') + ' platform-history-date-row'
                  }
                >

                  {isCurrentUser && <div className='team-history-date-divider player-history-date-divider'>&nbsp;</div>}

                  <div className={'team-history-date player-history-date' + (isCurrentUser ? ' team-history-date-current-user' : '')}>
                    {getDateLocaleFormat(action['date'].split('T')[0])}
                  </div>

                  {!isCurrentUser && <div className='team-history-date-divider player-history-date-divider'>&nbsp;</div>}

                </div>
              )}

              <div
                className={
                  'team-history-info-text player-history-info-text platform-history-info-text'
                  + (isCurrentUser ? ' team-history-info-text-current-user' : '')
                  + (actionType === 'report' ? (isCurrentUser ? ' player-history-report-info-current-user' : ' player-history-report-info-other-user') : '')
                }
              >
                {getHistoryInfoText(action, actionType, isCurrentUser)}

                {actionType === 'report' && (
                  <div
                    className={
                      'player-history-report-icon' +
                      (isCurrentUser ? ' platform-history-report-icon-current-user' : ' player-history-report-icon-other-user')
                    }
                  >
                    {action['reportType'] === 'ScoutingReport' && <PlagiarismIcon style={{ fontSize: 22, color: '#ffffffaa' }} />}
                    {action['reportType'] === 'MatchReport' && <DescriptionIcon style={{ fontSize: 22, color: '#ffffffaa' }} />}
                    {action['reportType'] === 'Comment' && <MessageIcon style={{ fontSize: 21, marginTop: 1, color: '#ffffffaa' }} />}
                    {action['reportType'] === 'Attachment' && <PictureAsPdfIcon style={{ fontSize: 21, marginTop: 1, color: '#ffffffaa' }} />}
                  </div>
                )}
              </div>

            </div>
          );
        })}
        <div className='platform-history-vertical-space'>&nbsp;</div>
      </div>

    </div>
  );
};
