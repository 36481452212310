import './modals.css';

import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';

import CloseIcon from '@mui/icons-material/Close';

import { staticLanguageMap } from '../../../common/static/staticLanguageMap';
import { PlayerHistory } from '../history/PlayerHistory';
import { FlexibleJsonMapping } from '../../types';


interface PlayerHistoryModalProps {
  history: FlexibleJsonMapping[];
  closeModal: () => void;
}

export const PlayerHistoryModal: React.FC<PlayerHistoryModalProps> = ({ history, closeModal }) => {

  const userConfig = useRecoilValue(userConfigState);

  return (
    <div className='modal-root-container player-history-modal-root'>

      <CloseIcon
        className='modal-icon modal-exit-icon'
        style={{ fontSize: 24 }}
        onClick={() => closeModal()}
      />

      <div className='modal-root-title'>
        {userConfig ? staticLanguageMap['playerHistory'][userConfig.language] : ''}
      </div>

      {history.length === 0 && <div className='modal-divider modal-root-title-divider'>&nbsp;</div>}

      {history.length > 0 && (
        <PlayerHistory
          history={history}
        />
      )}

      {history.length === 0 && (
        <div className='player-history-modal-no-history'>
          {userConfig ? staticLanguageMap['noHistory'][userConfig.language] : ''}
        </div>
      )}

    </div>
  );
};
