import './input.css';

import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { competitionsState } from '../../recoil/atoms/competitionsState';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import { staticLanguageMap } from '../../../common/static/staticLanguageMap';
import { countryCodeToCountryInfo } from '../../static/countries';
import { Toggle } from './Toggle';
import { Iteration } from '../../types';


interface ClubIterationDropDownProps {
  id: string;
  dropDownOptions: Iteration[];
  selectedIterations: number[];
  setSelectedIterations: (value: number[]) => void;
  isDropDownExpanded: boolean;
  setIsDropDownExpanded: (value: boolean) => void;

  isToggled: boolean;
  setIsToggled: (value: boolean) => void;
  toggleText: string;

  emptyBackgroundId: string;
  defaultDropDownText: string;
  defaultDropDownTextColor: string;
  marginBetweenOptions: number;
  maxHeight: string;
}

export const ClubIterationDropDown: React.FC<ClubIterationDropDownProps> = ({
  id,
  dropDownOptions,
  selectedIterations,
  setSelectedIterations,
  isDropDownExpanded,
  setIsDropDownExpanded,

  isToggled,
  setIsToggled,
  toggleText,

  emptyBackgroundId,
  defaultDropDownText,
  defaultDropDownTextColor,
  marginBetweenOptions,
  maxHeight,
}) => {

  const userConfig = useRecoilValue(userConfigState);
  const competitions = useRecoilValue(competitionsState);


  const handleDropDownClick = () => {
    if (isDropDownExpanded) {
      removeDropDownExpansion();
    }
    else {
      expandDropDown();
    }
  };


  const handleOptionSelect = (iterationId: number, isOptionAlreadySelected: boolean) => {
    const newSelectedOptions = selectedIterations.slice();
    if (isOptionAlreadySelected) {
      const i = newSelectedOptions.indexOf(iterationId);
      newSelectedOptions.splice(i, 1);
    }
    else {
      newSelectedOptions.push(iterationId);
    }
    setSelectedIterations(newSelectedOptions);
  };


  const expandDropDown = () => {
    // zIndex handling is not ideal, but must be handlded carefully due to interactions between multiple drop downs
    let element = document.getElementById(id);
    if (element) {
      element.style.transition = '150ms';
      element.style.height = 'auto';
      element.style.zIndex = '110';
    }

    if (emptyBackgroundId) {
      element = document.getElementById(emptyBackgroundId);
      if (element) {
        element.style.transition = '150ms';
        element.style.zIndex = '100';
        element.style.backgroundImage = 'linear-gradient(to bottom, #00000000, #00000012)';
      }
    }

    setIsDropDownExpanded(true);
  };


  const removeDropDownExpansion = () => {
    let element = document.getElementById(id);
    if (element) {
      element.style.transition = '75ms';
      element.style.height = '28px';
      element.style.zIndex = '1';
    }

    if (emptyBackgroundId) {
      element = document.getElementById(emptyBackgroundId);
      if (element) {
        element.style.transition = '75ms';
        element.style.zIndex = '-1';
        element.style.backgroundImage = 'linear-gradient(to bottom, #00000000, #00000000)';
      }
    }

    setIsDropDownExpanded(false);
  };


  const getCompetitionName = (index: number) => {
    const competitionId = Number(dropDownOptions[index].competitionId);
    const competition = competitions[competitionId];
    if (competition) {
      return competition.name;
    }
  };


  const getFlagUrl = (index: number) => {
    const competitionId = Number(dropDownOptions[index].competitionId);
    const competition = competitions[competitionId];
    if (competition && competition.countryCode && countryCodeToCountryInfo[competition.countryCode]) {
      return countryCodeToCountryInfo[competition.countryCode].flagUrl;
    }
  };


  const getDisplayOption = (index: number) => {
    const selectedIteration: Iteration | undefined = dropDownOptions.find((option: Iteration) => option.iterationId === selectedIterations[index]);
    if (selectedIteration) {
      const competitionId = Number(selectedIteration.competitionId);
      const competitionName = competitions[competitionId].name;
      return competitionName + ' ' + selectedIteration.season;
    }
  };


  const showSelectedOptions = () => {
    if (selectedIterations.length === 0) return;

    let firstSelection = getDisplayOption(0);

    for (let i = 1; i < selectedIterations.length; i++) {
      firstSelection += ', ' + getDisplayOption(i);
    }
    return firstSelection;
  };


  useEffect(() => {
    if (!isDropDownExpanded) {
      removeDropDownExpansion();
    }
  }, [isDropDownExpanded]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div
      className={'drop-down-select-container' + ((isDropDownExpanded || selectedIterations.length > 0) ? ' drop-down-select-container-focus' : '')}
      id={id}
      style={{ maxHeight: maxHeight }}
    >

      <div className='drop-down-select-button' onClick={() => handleDropDownClick()}>

        {selectedIterations.length > 0 && <div className='drop-down-select-button-overlay'>&nbsp;</div>}

        <div className='drop-down-select-button-text'>
          {selectedIterations.length === 0 ? (
            <div style={{ color: defaultDropDownTextColor }}>
              {userConfig ? staticLanguageMap[defaultDropDownText][userConfig.language] : ''}
            </div>
          ) : (
            <div className='player-iteration-drop-down-selected-option-display' style={{ color: '#000000' }}>
              {showSelectedOptions()}
            </div>
          )}
        </div>

        {!isDropDownExpanded && (
          <div className='drop-down-select-icon'>
            <ArrowDropDownIcon style={{ fontSize: 24 }} />
          </div>
        )}

        {isDropDownExpanded && (
          <div className='drop-down-select-icon'>
            <ArrowDropUpIcon style={{ fontSize: 24 }} />
          </div>
        )}
      </div>

      <div className='drop-down-select-space'>&nbsp;</div>

      <div className='drop-down-select-options drop-down-select-options-scroll'>
        {dropDownOptions.map((option: Iteration, index: number) => {
          const isSelected = selectedIterations.includes(option.iterationId);
          return (
            <div
              className={'drop-down-select-option player-iteration-drop-down-select-option' + (isSelected ? ' drop-down-select-option-selected' : '')}
              style={{ marginTop: marginBetweenOptions }}
              key={index}
              onClick={() => handleOptionSelect(option.iterationId, isSelected)}
            >
              <div className='player-iteration-drop-down-select-option-row'>
                <img
                  className='league-flag player-iteration-drop-down-option-flag'
                  src={getFlagUrl(index)}
                  draggable={false}
                />
                {getCompetitionName(index)}
                &nbsp;
                {option.season}
              </div>
            </div>
          );
        })}
      </div>

      <div className='drop-down-select-toggle-section'>
        <div className='drop-down-select-toggle-text'>
          {userConfig ? staticLanguageMap[toggleText][userConfig.language] : ''}
        </div>
        <div className='drop-down-select-toggle'>
          <Toggle isToggled={isToggled} setIsToggled={setIsToggled} isWhiteBackground={true} />
        </div>
      </div>
    </div>
  );
};
