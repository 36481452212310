import './modals.css';

import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { teamsState } from '../../recoil/atoms/teamsState';
import { squadsState } from '../../recoil/atoms/squadsState';
import { playerTeamDataState } from '../../recoil/selectors/playerTeamDataState';
import { playerSquadDataState } from '../../recoil/selectors/playerSquadDataState';
import { selectedPlayerState } from '../../recoil/atoms/selectedPlayerState';
import { navigationPropsState } from '../../recoil/atoms/navigationPropsState';
import { useGlobalModal } from '../../recoil/hooks/openGlobalModal';
import { useAddPlayerOverview } from '../../recoil/hooks/addPlayerOverview';
import { useAddPlayerDetails } from '../../recoil/hooks/addPlayerDetails';

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';

import {
  addPlayerToSquad,
  addPlayerToTeam,
  changePlayerPositionInTeamOrSquad,
  removePlayerFromAllTeams,
  removePlayerFromTeamOrSquad,
} from '../../services/firestore/teamsOrSquads';

import { DropDownSelect } from '../input/DropDownSelect';
import { staticLanguageMap } from '../../../common/static/staticLanguageMap';
import { formationToPositionOptions } from '../../static/propertyValues';
import { AuthContextType, useAuthContext } from '../../../common/contexts/AuthContext';
import { addPlayerWithoutData, deleteOwnTeamAndAcademyPlayerData, deletePlayerDocument, setPlayerProperty } from '../../services/firestore/players';
import { NavigationProps, PlayerEntry, PlayerId } from '../../types';
import { getStrictPlayerId } from '../../utils/playerUtils';
import { userHasFullOwnTeamAccess } from '../../utils/userUtils';


interface PlayerViewModalProps {
  closeModal: (feedback?: boolean) => void;
  squadId: string | undefined;
  squadName: string | undefined;
}

export const PlayerViewModal: React.FC<PlayerViewModalProps> = ({ closeModal, squadId, squadName }) => {

  const { currentUser } = useAuthContext() as AuthContextType;
  const userConfig = useRecoilValue(userConfigState);

  const [navigationProps, setNavigationProps] = useRecoilState(navigationPropsState);
  const { handleOpenConfirmModal } = useGlobalModal();

  const [selectedPlayer, setSelectedPlayer] = useRecoilState(selectedPlayerState);

  const addPlayerOverview = useAddPlayerOverview();
  const addPlayerDetails = useAddPlayerDetails();

  const teams = useRecoilValue(teamsState);
  const squads = useRecoilValue(squadsState);
  const playerTeamData = useRecoilValue(playerTeamDataState);
  const playerSquadData = useRecoilValue(playerSquadDataState);

  const currentTeam = selectedPlayer && playerTeamData && selectedPlayer.id in playerTeamData
    ? playerTeamData[selectedPlayer.id].currentTeam
    : undefined;

  const currentTeamPosition = !squadId && selectedPlayer && playerTeamData && selectedPlayer.id in playerTeamData
    ? playerTeamData[selectedPlayer.id].currentPosition
    : undefined;

  const currentSquadPosition = squadId && playerSquadData && selectedPlayer && selectedPlayer.id in playerSquadData
    ? playerSquadData[selectedPlayer.id][squadId]
    : undefined;

  const [selectedPosition, setSelectedPosition] = useState<string[]>(
    currentSquadPosition
      ? [currentSquadPosition]
      : currentTeamPosition ? [currentTeamPosition] : []);

  const [selectedTeam, setSelectedTeam] = useState<string[]>(currentTeam ? [currentTeam] : []);

  const [isPositionDropDownExpanded, setIsPositionDropDownExpanded] = useState(false);
  const [isTeamDropDownExpanded, setIsTeamDropDownExpanded] = useState(false);


  const [formationOfSelectedTeamOrSquad, setFormationOfSelectedTeamOrSquad] = useState<string | undefined>(
    squadId
      ? (squads && squadId in squads ? squads[squadId]['formation'] : undefined)
      : (teams && currentTeam && currentTeam in teams ? teams[currentTeam]['formation'] : undefined)
  );

  const [positionOptions, setPositionOptions] = useState<string[]>(
    formationOfSelectedTeamOrSquad ? formationToPositionOptions[formationOfSelectedTeamOrSquad] : []
  );


  useEffect(() => {
    if (selectedTeam.length > 0) {
      setFormationOfSelectedTeamOrSquad(
        teams && selectedTeam[0] && selectedTeam[0] in teams ? teams[selectedTeam[0]]['formation'] : undefined
      );
    }
  }, [selectedTeam, teams]);


  useEffect(() => {
    if (formationOfSelectedTeamOrSquad) {
      setPositionOptions(formationToPositionOptions[formationOfSelectedTeamOrSquad]);

      if (selectedPosition.length > 0 && !formationToPositionOptions[formationOfSelectedTeamOrSquad].includes(selectedPosition[0])) {
        setSelectedPosition([]);
      }
    }
  }, [formationOfSelectedTeamOrSquad]); // eslint-disable-line react-hooks/exhaustive-deps


  const removeDropDownExpansions = () => {
    setIsPositionDropDownExpanded(false);
    setIsTeamDropDownExpanded(false);
  };


  const closeModalAndResetSlections = (feedback = true) => {
    closeModal(feedback);
    // There's a small delay before the modal is closed
    setTimeout(() => {
      setSelectedTeam([]);
      setSelectedPosition([]);
    }, 250);
  };


  const confirmAddClause = (wasAdded: boolean) => {
    if (selectedPlayer !== undefined) {
      const navigationProps: NavigationProps = {
        activeTab: 'economy',
        activeSubTab: wasAdded ? 1 : 0,
        selectedPlayerId: selectedPlayer.id,
        selectedPlayerName: selectedPlayer.fullname,
      };
      setNavigationProps(navigationProps);
      setSelectedPlayer(undefined);
    }
  };


  const handleOpenAddClauseModal = (wasAdded: boolean) => {
    if (navigationProps && navigationProps.activeTab === 'economy') return;

    handleOpenConfirmModal(
      () => confirmAddClause(wasAdded),
      'addClause?',
      wasAdded ? 'addClauseForBoughtPlayerInfo' : 'addClauseForSoldPlayerInfo',
      undefined,
      undefined,
      undefined,
      300
    );
  };


  const handleRemovePlayerFromOwnTeamOrAcademyTeam = (playerId: PlayerId) => {
    if (!userConfig) return;

    // if this is a player in the database, we delete the player document
    if (!isNaN(Number(playerId))) {
      deletePlayerDocument(playerId, userConfig.club);
    }

    // if this is a player without data, we must only delete the ownTeam and academyTeam player data
    else {
      deleteOwnTeamAndAcademyPlayerData(playerId, userConfig.club);
    }
  };


  const handleAddOrEditPlayer = async (modalType: string) => {
    // modalType is one of [team, ownTeam, newPlayer, squad]

    if (!userConfig || !selectedPlayer || !userConfig || !teams || !squads) return;

    let playerId: string | number | undefined = selectedPlayer.id;
    const fullname = selectedPlayer.fullname;

    if (playerId === 'newPlayerWithoutData') {
      playerId = await addPlayerWithoutData(fullname, userConfig.club);
    }

    if (playerId === undefined) return;

    const playerData: PlayerEntry = {
      id: playerId,
      fullname: fullname,
    };

    if (selectedPosition.length > 0 && (modalType === 'newPlayer' || modalType === 'team')) {
      const newTeamId = selectedTeam[0];

      addPlayerToTeam(playerData, selectedPosition[0], newTeamId, teams[newTeamId]['name'], userConfig.email, userConfig.club);
      addPlayerOverview(selectedPlayer.id, currentUser);

      // if player is added to ownTeam, playerDetails will be added, and modal to add clause be opened unless player is moved from academyTeam
      if (newTeamId === 'ownTeam' && !isNaN(Number(playerId))) {
        addPlayerDetails(playerId, currentUser);

        if (currentTeam !== 'academyTeam') {
          handleOpenAddClauseModal(true);
        }
      }

      // if player is removed from ownTeam, modal to add clause for the removed player will be opened
      if (currentTeam === 'ownTeam' && !isNaN(Number(playerId))) {
        handleOpenAddClauseModal(false);
      }

      // if player is moved from ownTeam or academyTeam, we need to reset parts or all of the player table data
      if (currentTeam === 'ownTeam' || currentTeam === 'academyTeam') {

        // if player is moved between ownTeam and academyTeam, we only reset the role
        if (newTeamId === 'academyTeam' || newTeamId === 'ownTeam') {
          setPlayerProperty(playerId, 'role', null, userConfig.club);
        }

        // if the player moves to another team, we reset the player table data
        else {
          handleRemovePlayerFromOwnTeamOrAcademyTeam(playerId);
        }
      }
    }

    else if (modalType === 'squad' && squadId && selectedPosition.length > 0) {
      if (selectedPlayer.id in playerSquadData && squadId in playerSquadData[selectedPlayer.id]) {
        changePlayerPositionInTeamOrSquad(selectedPlayer.id, squadId, selectedPosition[0], userConfig.club, true, currentSquadPosition);
      }
      else {
        addPlayerToSquad(playerData, selectedPosition[0], squadId, userConfig.email, userConfig.club);
        addPlayerOverview(selectedPlayer.id, currentUser);
      }
    }

    setSelectedPlayer(playerData);

    closeModalAndResetSlections(true);
  };

  const handleRemovePlayer = () => {
    if (!userConfig || !selectedPlayer) return;

    if (squadId) {
      removePlayerFromTeamOrSquad(selectedPlayer.id, squadId, true, userConfig.email, userConfig.club);
    }
    else if (currentTeam) {
      const playerId = getStrictPlayerId(selectedPlayer.id);
      removePlayerFromTeamOrSquad(playerId, currentTeam, false, userConfig.email, userConfig.club);

      if (currentTeam === 'ownTeam' || currentTeam === 'academyTeam') {
        handleRemovePlayerFromOwnTeamOrAcademyTeam(playerId);

        if (currentTeam === 'ownTeam' && !isNaN(Number(playerId))) {
          handleOpenAddClauseModal(false);
        }
      }
    }
    else {
      // should never reach this path
      removePlayerFromAllTeams(selectedPlayer.id, userConfig.club);
    }

    setSelectedPlayer(undefined);
    closeModalAndResetSlections();
  };


  const getModalTitle = () => {
    if (!userConfig) return '';

    if (squadId) {
      if (selectedPlayer && selectedPlayer.id in playerSquadData && squadId in playerSquadData[selectedPlayer.id]) {
        return staticLanguageMap['editSquadStatus'][userConfig.language];
      }

      return staticLanguageMap['addPlayer'][userConfig.language] + ' ' + staticLanguageMap['to'][userConfig.language] + ' ' + squadName;
    }

    return currentTeam ? staticLanguageMap['editTeamStatus'][userConfig.language] : staticLanguageMap['addToTeam'][userConfig.language];
  };


  const shouldDiasbleNewTeam = currentTeam === 'ownTeam' && !userHasFullOwnTeamAccess(userConfig);
  const newTeamOptions = teams
    ? (userHasFullOwnTeamAccess(userConfig) ? Object.keys(teams) : Object.keys(teams).filter(team => team !== 'ownTeam'))
    : [];


  // There are 5 different views:
  // 1. Player is on a team other than ownTeam and squadKey !== undefined
  // 2. Player is on own team and squadKey === undefined
  // 3. Player is not on any team and squadKey === undefined
  // 4. Add player to own team
  // 5. Edit player in own team


  return (
    <div className='modal-root-container player-view-modal-root'>

      <CloseIcon
        className='modal-icon modal-exit-icon'
        style={{ fontSize: 24 }}
        onClick={() => closeModal()}
      />

      {(isPositionDropDownExpanded || isTeamDropDownExpanded) && (
        <div className='modal-empty-background' onClick={() => removeDropDownExpansions()}>
          &nbsp;
        </div>
      )}

      <div className='modal-root-title'>
        {getModalTitle()}
      </div>

      <div className='modal-divider modal-root-title-divider'>&nbsp;</div>

      <div className='modal-content-section'>
        {!squadId && currentTeam && (
          <div>

            <div className='player-view-modal-select-row'>
              {!shouldDiasbleNewTeam && (
                <div className='player-view-modal-select-column'>
                  {userConfig ? staticLanguageMap['selectNewTeam'][userConfig.language] : ''}
                </div>
              )}

              <div
                style={{ margin: shouldDiasbleNewTeam ? 'auto' : undefined }}
                className='player-view-modal-select-column'>
                {userConfig ? staticLanguageMap['selectNewPosition'][userConfig.language] : ''}
              </div>
            </div>

            <div className='player-view-modal-drop-down-row'>
              {!shouldDiasbleNewTeam && (
                <div className='player-view-modal-select-column'>
                  <DropDownSelect
                    id={'player-view-modal-team-select'}
                    dropDownOptions={newTeamOptions}
                    selectedOptions={selectedTeam}
                    setSelectedOptions={setSelectedTeam}
                    isDropDownExpanded={isTeamDropDownExpanded}
                    setIsDropDownExpanded={setIsTeamDropDownExpanded}
                    defaultDropDownText={'selectTeam'}
                    defaultDropDownTextColor={'#000000'}
                    marginBetweenOptions={0}
                    maxHeight={'56vh'}
                  />
                </div>
              )}

              <div
                style={{ margin: shouldDiasbleNewTeam ? 'auto' : undefined }}
                className='player-view-modal-select-column'>
                <DropDownSelect
                  id={'player-view-modal-position-select'}
                  dropDownOptions={positionOptions}
                  selectedOptions={selectedPosition}
                  setSelectedOptions={setSelectedPosition}
                  isDropDownExpanded={isPositionDropDownExpanded}
                  setIsDropDownExpanded={setIsPositionDropDownExpanded}
                  defaultDropDownText={'selectPosition'}
                  defaultDropDownTextColor={'#000000'}
                  marginBetweenOptions={0}
                  maxHeight={'56vh'}
                />
              </div>
            </div>

            <div
              className={'modal-button modal-button-top' + (
                selectedTeam.length > 0 && selectedPosition.length > 0 && (selectedTeam[0] !== currentTeam || selectedPosition[0] !== currentTeamPosition)
                  ? ''
                  : ' modal-button-disabled'
              )}
              style={{ marginLeft: 150 }}
              onClick={() => (
                selectedTeam.length > 0 && selectedPosition.length > 0 && (selectedTeam[0] !== currentTeam || selectedPosition[0] !== currentTeamPosition)
                  ? handleAddOrEditPlayer('team')
                  : null
              )}>
              <div className='modal-button-text'>
                {userConfig ? staticLanguageMap['movePlayer'][userConfig.language] : ''}
              </div>

              <ArrowRightAltIcon
                className='modal-button-icon'
                style={{ fontSize: 24 }}
              />
            </div>
          </div>
        )}

        {!squadId && !currentTeam && (
          <div className='player-view-modal-select-section'>

            <div className='player-view-modal-select-row'>
              <div className='player-view-modal-select-column'>
                {userConfig ? staticLanguageMap['selectTeam'][userConfig.language] : ''}
              </div>
              <div className='player-view-modal-select-column'>
                {selectedTeam.length > 0 && userConfig ? staticLanguageMap['selectPosition'][userConfig.language] : ''}
              </div>
            </div>

            <div className='player-view-modal-drop-down-row'>
              <div className='player-view-modal-select-column'>
                <DropDownSelect
                  id={'player-view-modal-team-select'}
                  dropDownOptions={newTeamOptions}
                  selectedOptions={selectedTeam}
                  setSelectedOptions={setSelectedTeam}
                  isDropDownExpanded={isTeamDropDownExpanded}
                  setIsDropDownExpanded={setIsTeamDropDownExpanded}
                  defaultDropDownText={'selectTeam'}
                  defaultDropDownTextColor={'#000000'}
                  marginBetweenOptions={0}
                  maxHeight={'56vh'}
                />
              </div>

              {selectedTeam.length > 0 && (
                <div className='player-view-modal-select-column'>
                  <DropDownSelect
                    id={'player-view-modal-position-select'}
                    dropDownOptions={positionOptions}
                    selectedOptions={selectedPosition}
                    setSelectedOptions={setSelectedPosition}
                    isDropDownExpanded={isPositionDropDownExpanded}
                    setIsDropDownExpanded={setIsPositionDropDownExpanded}
                    defaultDropDownText={'selectPosition'}
                    defaultDropDownTextColor={'#000000'}
                    marginBetweenOptions={0}
                    maxHeight={'56vh'}
                  />
                </div>
              )}
            </div>

            <div
              className={'modal-button modal-button-middle' + (
                selectedTeam.length > 0 && selectedPosition.length > 0
                  ? ''
                  : ' modal-button-disabled'
              )}
              style={{ marginLeft: 150 }}
              onClick={() => (
                selectedTeam.length > 0 && selectedPosition.length > 0
                  ? handleAddOrEditPlayer('newPlayer')
                  : null
              )}>
              <div className='modal-button-text'>
                {userConfig ? staticLanguageMap['addPlayer'][userConfig.language] : ''}
              </div>

              <AddIcon
                className='modal-button-icon'
                style={{ fontSize: 24 }}
              />
            </div>
          </div>
        )}

        {squadId && (
          <div className='player-view-modal-select-section'>

            <div className='modal-section-title'>
              {userConfig
                ? (selectedPlayer && selectedPlayer.id in playerSquadData && squadId in playerSquadData[selectedPlayer.id]
                  ? staticLanguageMap['selectNewPosition'][userConfig.language]
                  : staticLanguageMap['selectPosition'][userConfig.language])
                : ''}
            </div>

            <div className='player-view-modal-squad-position-select'>
              <DropDownSelect
                id={'player-view-modal-squad-position-select'}
                dropDownOptions={positionOptions}
                selectedOptions={selectedPosition}
                setSelectedOptions={setSelectedPosition}
                isDropDownExpanded={isPositionDropDownExpanded}
                setIsDropDownExpanded={setIsPositionDropDownExpanded}
                defaultDropDownText={'selectPosition'}
                defaultDropDownTextColor={'#000000'}
                marginBetweenOptions={0}
                maxHeight={'56vh'}
              />
            </div>

            <div
              className={'modal-button modal-button-top' + (
                selectedPosition.length > 0 && selectedPosition[0] !== currentSquadPosition
                  ? ''
                  : ' modal-button-disabled'
              )}
              style={{ marginLeft: 150 }}
              onClick={() => (
                selectedPosition.length > 0 && selectedPosition[0] !== currentSquadPosition
                  ? handleAddOrEditPlayer('squad')
                  : null
              )}>
              <div className='modal-button-text'>
                {userConfig
                  ? (selectedPlayer && selectedPlayer.id in playerSquadData && squadId in playerSquadData[selectedPlayer.id]
                    ? staticLanguageMap['changePosition'][userConfig.language]
                    : staticLanguageMap['addToSquad'][userConfig.language])
                  : ''}
              </div>

              <ArrowRightAltIcon
                className='modal-button-icon'
                style={{ fontSize: 24 }}
              />
            </div>
          </div>
        )}

        {(
          (currentTeam && squadId === undefined) ||
          (squadId && playerSquadData && selectedPlayer && selectedPlayer.id in playerSquadData && squadId in playerSquadData[selectedPlayer.id])
        ) &&
          (
            <div>
              <div className='modal-divider modal-bottom-divider'>&nbsp;</div>
              <div
                className={'modal-button modal-button-bottom' + (shouldDiasbleNewTeam ? ' modal-button-disabled' : '')}
                style={{ marginLeft: 150 }}
                onClick={() => !shouldDiasbleNewTeam ? handleRemovePlayer() : null}
              >
                <div className='modal-button-text'>
                  {userConfig
                    ? (squadId ? staticLanguageMap['removePlayerFromSquad'][userConfig.language] : staticLanguageMap['removePlayer'][userConfig.language])
                    : ''}
                </div>

                <PersonRemoveIcon
                  className='modal-button-icon'
                  style={{ fontSize: 24 }}
                />
              </div>
            </div>
          )
        }
      </div>

    </div>
  );
};
