import { useRecoilState } from 'recoil';
import { getPlayerDetails } from '../../services/fokusServer/playerDetails';
import { FirebaseUser } from '../../../firebase';
import { playerDetailsState } from '../atoms/playerDetailsState';
import { PlayerId } from '../../types';


export const useAddPlayerDetails = () => {

  const [playerDetails, setPlayerDetails] = useRecoilState(playerDetailsState);

  const addPlayerDetails = async (playerId: PlayerId, currentUser: FirebaseUser) => {

    const numberId = Number(playerId);

    if (!isNaN(numberId) && playerDetails && !(playerId in playerDetails)) {

      const newPlayerDetails = await getPlayerDetails([numberId], currentUser);

      if (newPlayerDetails) {
        setPlayerDetails((currentDetails) => ({
          ...currentDetails,
          ...newPlayerDetails,
        }));
      }
    }
  };

  return addPlayerDetails;
};


export const useAddMultiplePlayerDetails = () => {

  const [playerDetails, setPlayerDetails] = useRecoilState(playerDetailsState);

  const addPlayerDetails = async (playerIds: PlayerId[], currentUser: FirebaseUser) => {

    if (playerDetails) {
      const anyPlayerMissing = playerIds.some((playerId) => !(playerId in playerDetails));

      if (anyPlayerMissing) {
        const newPlayerDetails = await getPlayerDetails(playerIds, currentUser);

        if (newPlayerDetails) {
          setPlayerDetails((currentDetails) => ({
            ...currentDetails,
            ...newPlayerDetails,
          }));
        }
      }
    }

  };

  return addPlayerDetails;
};
