import { FirebaseUser, db } from '../../../firebase';

import {
  doc,
  getDoc,
  updateDoc,
  collection,
  query,
  where,
  onSnapshot,
} from 'firebase/firestore';
import { UserConfig, UserConfigMap } from '../../types';


// Get user data for the given user
export const getUserConfig = (
  currentUser: FirebaseUser,
  setUserConfig: (user: UserConfig | null) => void
) => {
  if (!currentUser || !currentUser.email) {
    return () => null;
  }

  const userDocRef = doc(db, 'users', currentUser.email);

  const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
    if (!docSnapshot.exists()) {
      setUserConfig(null);
      return;
    }

    const userData = docSnapshot.data();
    if (!userData.club) {
      setUserConfig(null);
      return;
    }

    if (!currentUser.email) {
      setUserConfig(null);
      return;
    }

    const userConfig: UserConfig = {
      email: currentUser.email,
      club: userData.club,
      name: userData.name ?? currentUser.email.split('@')[0],
      language: userData.language ?? 'en',
      isNavigationExpanded: userData.isNavigationExpanded ?? true,
      isColorBlind: userData.isColorBlind ?? false,
      access: userData.access ?? 'scout',
      hasMaintenanceAccess: userData.hasMaintenanceAccess ?? false,
    };

    setUserConfig(userConfig);
  });

  return unsubscribe;
};


// Update user config
export const updateUserConfig = async (
  userEmail: string,
  property: string,
  value: string | boolean
) => {

  const userDocRef = doc(db, 'users', userEmail);
  const userDoc = await getDoc(userDocRef);

  if (userDoc.exists()) {
    try {
      await updateDoc(userDocRef, { [property]: value });
    }
    catch (error) {
      console.error(error); // eslint-disable-line no-console
    }
  }
};


// Get all users with the same club as the current user
export const getAllUsersWithSameClub = (
  setAllUsersWithSameClub: (allUsersWithSameClub: UserConfigMap) => void,
  club: string
) => {

  const usersRef = collection(db, 'users');

  const q = query(usersRef, where('club', '==', club));

  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const users: UserConfigMap = {};
    querySnapshot.forEach((doc) => {
      users[doc.id] = doc.data() as UserConfig;

      if (!users[doc.id].access) {
        users[doc.id].access = 'scout';
      }
    });

    setAllUsersWithSameClub(users);
  });

  return unsubscribe;
};
