import { db } from '../../../firebase';

import {
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
  setDoc,
  addDoc,
  arrayUnion,
  arrayRemove,
  onSnapshot,
  writeBatch,
  query,
  orderBy,
} from 'firebase/firestore';

import { FlexibleJsonMapping, PlayerActivity, PlayerEntry, PlayerId } from '../../types';
import { getTeamWithNewFormation } from '../../utils/formationUtils';
import { getSortDistanceRole, getStrictPlayerId } from '../../utils/playerUtils';
import { positionOptionsPlatform } from '../../static/propertyValues';
import { deepCloneObject } from '../../utils/utils';
import { addActivity } from './activities';


// Get all teams or squads
export const getTeamsOrSquads = (setTeamsOrSquads: (teamsOrSquads: FlexibleJsonMapping) => void, isSquad: boolean, club: string) => {

  const collectionRef = isSquad ? getSquadsCollectionRef(club) : getTeamsCollectionRef(club);

  const unsubscribe = onSnapshot(collectionRef, snapshot => {
    let teamsOrSquads: FlexibleJsonMapping = {};

    snapshot.forEach((doc) => {
      const teamOrSquad = doc.data();
      teamsOrSquads[doc.id] = teamOrSquad;
    });

    teamsOrSquads = Object.fromEntries(Object.entries(teamsOrSquads).sort((a, b) => a[1].orderIndex - b[1].orderIndex));

    setTeamsOrSquads(teamsOrSquads);
  });

  return unsubscribe;
};


// re-order teams or squads
export const reorderTeamsOrSquads = async (tabOptions: string[], isSquad: boolean, club: string) => {

  const collectionRef = isSquad ? getSquadsCollectionRef(club) : getTeamsCollectionRef(club);

  const batch = writeBatch(db);

  tabOptions.forEach((teamOrSquadId, index) => {
    const docRef = doc(collectionRef, teamOrSquadId);
    batch.update(docRef, { orderIndex: index });
  });

  await batch.commit();
};


// Add the given player to the given team
export const addPlayerToTeam = async (
  playerEntry: PlayerEntry,
  positionKey: string,
  teamId: string,
  teamName: string,
  userEmail: string,
  club: string,
) => {

  const playerId = getStrictPlayerId(playerEntry.id);

  const [currentTeamId, currentTeamName] = await removePlayerFromAllTeams(playerId, club);

  const teamDocRef = getTeamDocRef(teamId, club);

  try {
    updateDoc(teamDocRef, {
      [positionKey]: arrayUnion(playerEntry)
    });
  }
  catch (error) {
    console.log(error); // eslint-disable-line no-console
  }

  if (currentTeamId !== teamId) {
    const playerActivity: PlayerActivity = {
      playerId: playerId,
      playerName: playerEntry.fullname,
      userEmail: userEmail,
      date: new Date().toISOString(),
      fromTeamId: currentTeamId ?? null,
      fromTeamName: currentTeamName ?? null,
      toTeamId: teamId,
      toTeamName: teamName,
    };

    addActivity(playerActivity, club);
  }
};


// Add the given player to the given squad
export const addPlayerToSquad = async (
  playerEntry: PlayerEntry,
  positionKey: string,
  squadId: string,
  userEmail: string,
  club: string
) => {

  const playerId = getStrictPlayerId(playerEntry.id);

  const squadDocRef = getSquadDocRef(squadId, club);
  const squadDoc = await getDoc(squadDocRef);
  const squad = squadDoc.data();

  // If player already exists in squad, remove player
  const currentPositionKey = positionOptionsPlatform.find(position => {
    if (squad && squad[position]) {
      return squad[position].some((player: PlayerEntry) => player.id === playerId);
    }
  });

  const squadHistoryAction: FlexibleJsonMapping = {
    playerId: playerId,
    playerName: playerEntry.fullname,
    userEmail: userEmail,
    date: new Date().toISOString(),
    toTeamId: squadId,
  };

  if (currentPositionKey === positionKey) return;

  if (squad && currentPositionKey) {
    let currentPlayerData = undefined;
    squad[currentPositionKey].forEach((player: PlayerEntry) => {
      if (player.id === playerId) {
        currentPlayerData = player;
        return;
      }
    });

    if (currentPlayerData) {
      try {
        await updateDoc(squadDocRef, {
          [currentPositionKey]: arrayRemove(currentPlayerData),
          [positionKey]: arrayUnion(playerEntry),
          history: arrayUnion(squadHistoryAction),
        });
      }
      catch (error) {
        console.log(error); // eslint-disable-line no-console
      }
    }
  }

  else {
    try {
      await updateDoc(squadDocRef, {
        [positionKey]: arrayUnion(playerEntry),
        history: arrayUnion(squadHistoryAction),
      });
    }
    catch (error) {
      console.log(error); // eslint-disable-line no-console
    }
  }
};


// Remove the player with the given playerId from all teams (can only exist in one team) and returns the id and name of the current team, if any
// (it isn't necessary to check every team or to first read the current PlayerEntry, but it provides some simplicity and safety)
export const removePlayerFromAllTeams = async (
  playerId: PlayerId,
  club: string
): Promise<[string | undefined, string | undefined]> => {

  const teamSnapshots = await getDocs(getTeamsCollectionRef(club));

  let currentTeamId: string | undefined = undefined;
  let currentTeamName: string | undefined = undefined;

  teamSnapshots.forEach((doc) => {
    const team: FlexibleJsonMapping = doc.data();

    const currentPositionKey = positionOptionsPlatform.find(position => {
      if (team[position]) {
        return team[position].some((player: PlayerEntry) => player.id === playerId);
      }
    });

    if (currentPositionKey) {
      let currentPlayerData = undefined;
      team[currentPositionKey].forEach((player: PlayerEntry) => {
        if (player.id === playerId) {
          currentPlayerData = player;
          return;
        }
      });

      if (currentPlayerData) {
        const teamRef = getTeamDocRef(doc.id, club);
        updateDoc(teamRef, {
          [currentPositionKey]: arrayRemove(currentPlayerData)
        });
        currentTeamId = doc.id;
        currentTeamName = team['name'];
      }
    }
  });

  return [currentTeamId, currentTeamName];
};


// Remove the player with the given playerId from all squads
export const removePlayerFromAllSquads = async (
  playerId: PlayerId,
  userEmail: string,
  club: string,
) => {

  const squadSnapshots = await getDocs(getSquadsCollectionRef(club));

  squadSnapshots.forEach((doc) => {
    removePlayerFromTeamOrSquad(playerId, doc.id, true, userEmail, club);
  });
};


// Remove a player from a given team or squad
export const removePlayerFromTeamOrSquad = async (
  playerId: PlayerId,
  teamOrSquadId: string,
  isSquad: boolean,
  userEmail: string,
  club: string,
) => {

  playerId = getStrictPlayerId(playerId);

  const teamOrSquadDocRef = isSquad ? getSquadDocRef(teamOrSquadId, club) : getTeamDocRef(teamOrSquadId, club);
  const teamOrSquadDoc = await getDoc(teamOrSquadDocRef);
  const teamOrSquad = teamOrSquadDoc.data();

  const position = positionOptionsPlatform.find(position => {
    if (teamOrSquad && teamOrSquad[position]) {
      return teamOrSquad[position].some((player: PlayerEntry) => player.id === playerId);
    }
  });

  if (teamOrSquad && position) {
    // Get player data of given player
    teamOrSquad[position].forEach((player: PlayerEntry) => {
      if (player['id'] === playerId) {

        if (isSquad) {
          const squadHistoryAction: FlexibleJsonMapping = {
            playerId: playerId,
            playerName: player.fullname,
            userEmail: userEmail,
            date: new Date().toISOString(),
            fromTeamId: teamOrSquadId,
          };

          try {
            updateDoc(teamOrSquadDocRef, {
              [position]: arrayRemove(player),
              history: arrayUnion(squadHistoryAction)
            });
          }
          catch (error) {
            console.log(error); // eslint-disable-line no-console
          }
        }

        else {
          try {
            updateDoc(teamOrSquadDocRef, {
              [position]: arrayRemove(player)
            });

            const playerActivity: PlayerActivity = {
              playerId: playerId,
              playerName: player.fullname,
              userEmail: userEmail,
              date: new Date().toISOString(),
              fromTeamId: teamOrSquadId,
              fromTeamName: teamOrSquad['name'],
              toTeamId: null,
              toTeamName: null,
            };

            addActivity(playerActivity, club);
          }
          catch (error) {
            console.log(error); // eslint-disable-line no-console
          }
        }

        return;
      }
    });
  }
};


// Change the position of the given player in the given team or squad
export const changePlayerPositionInTeamOrSquad = async (
  playerId: PlayerId,
  teamOrSquadId: string,
  newPositionKey: string,
  club: string,
  isSquad: boolean,
  currentPositionKey?: string // sometimes we know the current position, and providing it will save some compute
) => {

  playerId = getStrictPlayerId(playerId);

  const teamOrSquadDocRef = isSquad ? getSquadDocRef(teamOrSquadId, club) : getTeamDocRef(teamOrSquadId, club);
  const teamOrSquadDoc = getDoc(teamOrSquadDocRef);
  const teamOrSquad = (await teamOrSquadDoc).data();

  if (teamOrSquad) {
    // Get current position if not provided
    currentPositionKey = currentPositionKey ?? positionOptionsPlatform.find(position => {
      if (teamOrSquad[position]) {
        return teamOrSquad[position].some((player: PlayerEntry) => player.id === playerId);
      }
    });

    if (currentPositionKey) {

      let currentPlayerData = undefined;
      teamOrSquad[currentPositionKey].forEach((player: PlayerEntry) => {
        if (player['id'] === playerId) {
          currentPlayerData = player;
          return;
        }
      });

      if (currentPlayerData && currentPositionKey !== newPositionKey) {
        try {
          await updateDoc(teamOrSquadDocRef, {
            [currentPositionKey]: arrayRemove(currentPlayerData),
            [newPositionKey]: arrayUnion(currentPlayerData),
          });
        }
        catch (error) {
          console.log(error); // eslint-disable-line no-console
        }
      }
    }
  }
};


// Removes all players from the given team or squad
export const resetTeamOrSquad = async (
  teamOrSquadId: string,
  teamOrSquad: FlexibleJsonMapping,
  isSquad: boolean,
  userEmail: string,
  club: string
) => {

  const teamOrSquadDocRef = isSquad ? getSquadDocRef(teamOrSquadId, club) : getTeamDocRef(teamOrSquadId, club);

  const batch = writeBatch(db);

  const fieldsToUpdate: Record<string, unknown[]> = positionOptionsPlatform.reduce<Record<string, unknown[]>>((acc, fieldName) => {
    acc[fieldName] = [];
    return acc;
  }, {});

  const action: FlexibleJsonMapping = {
    actionType: 'reset',
  };
  addTeamHistoryAction(action, teamOrSquadId, isSquad, userEmail, club);

  if (isSquad) {
    const extendedSquadHistory: FlexibleJsonMapping[] = getRemovedPlayersToAddToSquadHistory(teamOrSquadId, teamOrSquad, userEmail);

    fieldsToUpdate['history'] = [...(teamOrSquad.history || []), ...extendedSquadHistory];
  }
  else {
    addPlayerActivitiesForReset(teamOrSquadId, teamOrSquad, userEmail, club);
  }

  batch.update(teamOrSquadDocRef, fieldsToUpdate);

  await batch.commit();
};


// Add activity for each player in the given team or squad when resetting
const addPlayerActivitiesForReset = async (
  teamId: string,
  team: FlexibleJsonMapping,
  userEmail: string,
  club: string
) => {

  positionOptionsPlatform.forEach(positionKey => {
    const positionList = team[positionKey];

    if (positionList && Array.isArray(positionList) && positionList.length > 0) {
      positionList.forEach((player: PlayerEntry) => {

        const playerActivity: PlayerActivity = {
          playerId: getStrictPlayerId(player.id),
          playerName: player.fullname,
          userEmail: userEmail,
          date: new Date().toISOString(),
          fromTeamId: teamId,
          fromTeamName: team['name'],
          toTeamId: null,
          toTeamName: null,
        };

        addActivity(playerActivity, club);

      });
    }
  });
};


// Add the removed players to the squad history
const getRemovedPlayersToAddToSquadHistory = (
  squadId: string,
  squad: FlexibleJsonMapping,
  userEmail: string
) => {

  const extendedSquadHistory: FlexibleJsonMapping[] = [];

  positionOptionsPlatform.forEach(positionKey => {
    const positionList = squad[positionKey];

    if (positionList && Array.isArray(positionList) && positionList.length > 0) {
      positionList.forEach((player: PlayerEntry) => {

        const squadHistoryAction: FlexibleJsonMapping = {
          playerId: getStrictPlayerId(player.id),
          playerName: player.fullname,
          userEmail: userEmail,
          date: new Date().toISOString(),
          fromTeamId: squadId,
        };

        extendedSquadHistory.push(squadHistoryAction);

      });
    }
  });

  return extendedSquadHistory;
};


// Delete the given comment from team history
export const deleteCommentFromTeamHistory = async (
  teamOrSquadId: string,
  isSquad: boolean,
  comment: FlexibleJsonMapping,
  club: string
) => {

  const teamOrSquadDocRef = isSquad ? getSquadDocRef(teamOrSquadId, club) : getTeamDocRef(teamOrSquadId, club);

  try {
    await updateDoc(teamOrSquadDocRef, {
      history: arrayRemove(comment)
    });
  }
  catch (error) {
    console.log(error); // eslint-disable-line no-console
  }
};


// Add a new squad
export const addTeamOrSquad = async (
  teamOrSquadName: string,
  formation: string,
  orderIndex: number,
  clubFormation: string,
  isSquad: boolean,
  userEmail: string,
  club: string,
  teamIdToCopy?: string | undefined,
): Promise<string | undefined> => {

  const teamOrSquadCollectionRef = isSquad ? getSquadsCollectionRef(club) : getTeamsCollectionRef(club);
  let newTeamOrSquad: FlexibleJsonMapping | undefined = undefined;

  // add players that potentially are added to the squad to the squad history
  const now = new Date().toISOString();
  const initialHistory: FlexibleJsonMapping[] = [];

  // teamIdToCopy may only be provided if squad
  if (teamIdToCopy) {

    const teamRef = getTeamDocRef(teamIdToCopy, club);
    const teamDoc = getDoc(teamRef);
    newTeamOrSquad = (await teamDoc).data() as FlexibleJsonMapping;

    // sort players based on role if copying from Eget lag
    if (teamIdToCopy === 'ownTeam') {
      positionOptionsPlatform.forEach(positionKey => {
        if (newTeamOrSquad && newTeamOrSquad[positionKey] && Array.isArray(newTeamOrSquad[positionKey])) {
          newTeamOrSquad[positionKey].sort((a: PlayerEntry, b: PlayerEntry) => getSortDistanceRole(a, b));
        }
      });
    }

    // add players to squad history
    positionOptionsPlatform.forEach(positionKey => {
      if (newTeamOrSquad && newTeamOrSquad[positionKey] && Array.isArray(newTeamOrSquad[positionKey])) {
        newTeamOrSquad[positionKey].forEach((player: PlayerEntry) => {
          const squadHistoryAction: FlexibleJsonMapping = {
            playerId: getStrictPlayerId(player.id),
            playerName: player.fullname,
            userEmail: userEmail,
            date: new Date().toISOString(),
          };
          initialHistory.push(squadHistoryAction);
        });
      }
    });

    if (formation !== clubFormation) {
      newTeamOrSquad = getTeamWithNewFormation(newTeamOrSquad, formation, clubFormation);
    }

    newTeamOrSquad['name'] = teamOrSquadName;
    newTeamOrSquad['formation'] = formation;
    newTeamOrSquad['orderIndex'] = orderIndex;
    newTeamOrSquad['history'] = [];
  }
  else {
    newTeamOrSquad = getEmptyTeamOrSquad(teamOrSquadName, formation, orderIndex);
  }

  try {
    const docRef = await addDoc(teamOrSquadCollectionRef, newTeamOrSquad);

    const action: FlexibleJsonMapping = {
      actionType: 'create',
    };

    if (initialHistory.length > 0) {
      initialHistory.map((action: FlexibleJsonMapping) => {
        action['toTeamId'] = docRef.id;
        return action;
      });

      const teamHistoryAction: FlexibleJsonMapping = {
        action: action,
        userEmail: userEmail,
        date: now,
      };

      try {
        await updateDoc(docRef, {
          history: arrayUnion(teamHistoryAction, ...initialHistory)
        });
      }
      catch (error) {
        console.log(error); // eslint-disable-line no-console
      }
    }
    else {
      await addTeamHistoryAction(action, docRef.id, isSquad, userEmail, club);
    }

    return docRef.id;
  }
  catch (error) {
    return undefined;
  }
};


// Edit a squad
export const editTeamOrSquad = async (
  teamOrSquadId: string,
  newTeamOrSquadName: string,
  newFormation: string,
  currentTeamOrSquad: FlexibleJsonMapping,
  isSquad: boolean,
  userEmail: string,
  club: string
) => {

  const teamOrSquadDocRef = isSquad ? getSquadDocRef(teamOrSquadId, club) : getTeamDocRef(teamOrSquadId, club);

  const currentFormation = currentTeamOrSquad['formation'];
  const history = [...currentTeamOrSquad['history'] as FlexibleJsonMapping[]];

  let newTeamOrSquad: FlexibleJsonMapping = { ...currentTeamOrSquad };
  if (newFormation !== currentFormation) {
    newTeamOrSquad = getTeamWithNewFormation(newTeamOrSquad, newFormation, currentFormation);
    newTeamOrSquad['formation'] = newFormation;

    const action: FlexibleJsonMapping = {
      actionType: 'editFormation',
      newFormation: newFormation,
    };
    const teamHistoryAction: FlexibleJsonMapping = {
      action: action,
      userEmail: userEmail,
      date: new Date().toISOString(),
    };
    history.push(teamHistoryAction);
  }

  if (newTeamOrSquadName !== currentTeamOrSquad.name) {
    newTeamOrSquad['name'] = newTeamOrSquadName;

    const action: FlexibleJsonMapping = {
      actionType: 'editName',
      newName: newTeamOrSquadName,
    };
    const teamHistoryAction: FlexibleJsonMapping = {
      action: action,
      userEmail: userEmail,
      date: new Date().toISOString(),
    };
    history.push(teamHistoryAction);
  }

  newTeamOrSquad['history'] = history;

  try {
    await setDoc(teamOrSquadDocRef, newTeamOrSquad);
  }
  catch (error) {
    console.log(error); // eslint-disable-line no-console
  }
};


// Delete a team or squad
export const deleteTeamOrSquad = async (
  teamOrSquadId: string,
  isSquad: boolean,
  orderIndex: number,
  club: string
) => {

  const batch = writeBatch(db);
  const teamsOrSquadsCollectionRef = isSquad ? getSquadsCollectionRef(club) : getTeamsCollectionRef(club);

  const snapshot = await getDocs(teamsOrSquadsCollectionRef);

  snapshot.forEach(doc => {
    if (doc.id === teamOrSquadId) {
      batch.delete(doc.ref);
    }
    else {
      // must decrement the orderIndex of all squads with an orderIndex greater than the provided orderIndex
      const docOrderIndex = doc.data()['orderIndex'];
      if (docOrderIndex > orderIndex) {
        batch.update(doc.ref, { orderIndex: docOrderIndex - 1 });
      }
    }
  });

  await batch.commit();
};


// Archive team or squad, and add action to team history
export const archiveTeamOrSquad = async (
  teamOrSquadId: string,
  teamOrSquad: FlexibleJsonMapping,
  isSquad: boolean,
  formation: string,
  userEmail: string,
  club: string
): Promise<string | undefined> => {

  const archivedTeamsCollectionRef = collection(db, 'configs', club, 'archivedTeams');

  let newTeamOrSquad: FlexibleJsonMapping = {};

  newTeamOrSquad = deepCloneObject(teamOrSquad);

  delete newTeamOrSquad['history'];
  delete newTeamOrSquad['orderIndex'];

  newTeamOrSquad['teamOrSquadId'] = teamOrSquadId;
  newTeamOrSquad['name'] = teamOrSquad['name'];
  newTeamOrSquad['date'] = new Date().toISOString();
  newTeamOrSquad['isSquad'] = isSquad;
  newTeamOrSquad['formation'] = formation;

  try {
    const action: FlexibleJsonMapping = {
      actionType: 'archive',
    };
    await addTeamHistoryAction(action, teamOrSquadId, isSquad, userEmail, club);

    const docRef = await addDoc(archivedTeamsCollectionRef, newTeamOrSquad);
    return docRef.id;
  }
  catch (error) {
    console.log(error); // eslint-disable-line no-console
    return undefined;
  }
};


// get all archived teams
export const getAllArchivedTeams = async (club: string): Promise<FlexibleJsonMapping[]> => {

  const archivedTeamsCollectionRef = collection(db, 'configs', club, 'archivedTeams');

  const q = query(archivedTeamsCollectionRef, orderBy('date', 'desc'));

  try {
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  }
  catch (error) {
    console.log(error); // eslint-disable-line no-console
    return [];
  }
};


// update delete status of given archived team
export const updateArchivedTeamIsDeletedStatus = async (archivedTeamId: string, isDeleted: boolean, club: string): Promise<boolean> => {

  const archivedTeamDocRef = doc(db, 'configs', club, 'archivedTeams', archivedTeamId);

  try {
    await updateDoc(archivedTeamDocRef, {
      isDeleted: isDeleted
    });
    return true;
  }
  catch (error) {
    console.log(error); // eslint-disable-line no-console
    return false;
  }
};


// Add action to team history (action can be 'create', 'editName', editFormation', 'archive' or 'reset')
export const addTeamHistoryAction = async (
  action: FlexibleJsonMapping,
  teamOrSquadId: string,
  isSquad: boolean,
  userEmail: string,
  club: string
) => {

  const teamOrSquadDocRef = isSquad ? getSquadDocRef(teamOrSquadId, club) : getTeamDocRef(teamOrSquadId, club);

  const teamHistoryAction: FlexibleJsonMapping = {
    action: action,
    userEmail: userEmail,
    date: new Date().toISOString(),
  };

  try {
    await updateDoc(teamOrSquadDocRef, {
      history: arrayUnion(teamHistoryAction)
    });
    return true;
  }
  catch (error) {
    console.log(error); // eslint-disable-line no-console
    return false;
  }
};


// Updates all teams with new formation
export const updateTeamsWithNewFormation = async (newFormation: string, currentFormation: string, userEmail: string, club: string) => {

  const teamSnapshots = await getDocs(getTeamsCollectionRef(club));

  teamSnapshots.forEach(async (doc) => {
    const teamId = doc.id;
    const team = doc.data();
    if (team['formation'] === currentFormation) {
      await updateTeamDocWithNewFormation(teamId, team, newFormation, currentFormation, userEmail, club);
    }
  });
};


// Updates a given team with new formation
export const updateTeamDocWithNewFormation = async (
  teamId: string,
  teamData: FlexibleJsonMapping,
  newFormation: string,
  currentFormation: string,
  userEmail: string,
  club: string
) => {

  const newTeam: FlexibleJsonMapping = getTeamWithNewFormation(teamData, newFormation, currentFormation);

  const action: FlexibleJsonMapping = {
    actionType: 'editFormation',
    newFormation: newFormation,
  };
  const teamHistoryAction: FlexibleJsonMapping = {
    action: action,
    userEmail: userEmail,
    date: new Date().toISOString(),
  };

  newTeam['history'].push(teamHistoryAction);

  try {
    await setDoc(doc(db, 'configs', club, 'teams', teamId), newTeam);
  }
  catch (error) {
    console.log(error); // eslint-disable-line no-console
  }
};


const getSquadsCollectionRef = (club: string) => {
  return collection(db, 'configs', club, 'squads');
};


const getSquadDocRef = (squadId: string, club: string) => {
  return doc(db, 'configs', club, 'squads', squadId);
};


const getTeamsCollectionRef = (club: string) => {
  return collection(db, 'configs', club, 'teams');
};


const getTeamDocRef = (teamId: string, club: string) => {
  return doc(db, 'configs', club, 'teams', teamId);
};


const getEmptyTeamOrSquad = (name: string, formation: string, orderIndex: number): FlexibleJsonMapping => {
  return {
    GK: [],
    LWB: [],
    LB: [],
    LCB: [],
    CB: [],
    RCB: [],
    RB: [],
    RWB: [],
    CDM: [],
    LCM: [],
    RCM: [],
    CAM: [],
    LM: [],
    LW: [],
    RM: [],
    RW: [],
    LS: [],
    S: [],
    RS: [],

    name: name,
    formation: formation,
    orderIndex: orderIndex,
    history: [],
  };
};
