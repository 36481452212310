import '../../platform.css';

import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';

import { staticLanguageMap } from '../../../common/static/staticLanguageMap';


export const ComingSoon: React.FC = () => {

  const userConfig = useRecoilValue(userConfigState);

  return (

    <div className='coming-soon-container'>
      <div className='coming-soon-title'>
        {userConfig ? staticLanguageMap['underDevelopment'][userConfig.language] : ''}
      </div>
    </div>

  );
};
