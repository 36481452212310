import '../../platform.css';

import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { navigationPropsState } from '../../recoil/atoms/navigationPropsState';
import { globalModalPropsState } from '../../recoil/atoms/globalModalPropsState';
import { useAddPlayerOverview } from '../../recoil/hooks/addPlayerOverview';
import { useAddPlayerDetails } from '../../recoil/hooks/addPlayerDetails';
import { AuthContextType, useAuthContext } from '../../../common/contexts/AuthContext';

import { TeamView } from '../../components/teamView/TeamView';
import { FlexibleJsonMapping, GlobalModalProps, NavigationProps, PlayerEntry, PlayerOverviews } from '../../types';
import { addPlayerToSquad, addPlayerToTeam, changePlayerPositionInTeamOrSquad } from '../../services/firestore/teamsOrSquads';
import { AddPlayerView } from '../searchPlayers/AddPlayerView';
import { getCompleteTeamHistory } from '../../services/firestore/activities';
import { TeamHistory } from '../../components/history/TeamHistory';
import { TeamMenu } from './TeamMenu';
import { addPlayerWithoutData, setPlayerProperty } from '../../services/firestore/players';
import { userHasFullOwnTeamAccess } from '../../utils/userUtils';


export interface LastDraggedOwnTeamPlayerData {
  playerId: number;
  playerName: string;
  wasAdded: boolean;
}


interface LogisticTeamViewProps {
  teamOrSquadId: string | undefined;
  teamOrSquad: FlexibleJsonMapping | undefined;
  isTabLine: boolean;

  playerData: PlayerOverviews | undefined;
  setPlayerData: (playerData: PlayerOverviews | undefined) => void;

  isSquad?: boolean;

  showFirstEleven?: boolean;
  setShowFirstEleven?: (isToggled: boolean) => void;
  benchPlayers?: FlexibleJsonMapping[];

  logisticTeamViewToggle?: boolean; // when toggled, expansions will be closed
}

export const LogisticTeamView: React.FC<LogisticTeamViewProps> = ({
  teamOrSquadId,
  teamOrSquad,
  isTabLine,

  playerData,
  setPlayerData,

  isSquad,

  showFirstEleven,
  setShowFirstEleven,
  benchPlayers,

  logisticTeamViewToggle,
}) => {

  const { currentUser } = useAuthContext() as AuthContextType;
  const userConfig = useRecoilValue(userConfigState);

  const setNavigationProps = useSetRecoilState(navigationPropsState);
  const setGlobalModalProps = useSetRecoilState(globalModalPropsState);

  const addPlayerOverview = useAddPlayerOverview();
  const addPlayerDetails = useAddPlayerDetails();

  const [teamHistory, setTeamHistory] = useState<FlexibleJsonMapping[] | undefined>(undefined);

  const [addPlayerSearchString, setAddPlayerSearchString] = useState('');
  const [isAddPlayerWithoutData, setIsAddPlayerWithoutData] = useState(false);

  const [isAddPlayerViewExpanded, setIsAddPlayerViewExpanded] = useState(false);
  const [rightSectionExpansionType, setRightSectionExpansionType] = useState<string | undefined>(undefined);

  const [lastDraggedOwnTeamPlayerData, setLastDraggedOwnTeamPlayerData] = useState<LastDraggedOwnTeamPlayerData | undefined>(undefined);

  const [draggedPlayer, setDraggedPlayer] = useState<FlexibleJsonMapping | undefined>(undefined);


  const closeAddClauseModal = useCallback(() => {
    setLastDraggedOwnTeamPlayerData(undefined);
    setGlobalModalProps(undefined);
  }, [setGlobalModalProps]);


  const confirmAddClause = useCallback(() => {
    if (lastDraggedOwnTeamPlayerData) {
      const navigationProps: NavigationProps = {
        activeTab: 'economy',
        activeSubTab: lastDraggedOwnTeamPlayerData.wasAdded ? 1 : 0,
        selectedPlayerId: lastDraggedOwnTeamPlayerData.playerId,
        selectedPlayerName: lastDraggedOwnTeamPlayerData.playerName,
      };
      setNavigationProps(navigationProps);
    }

    closeAddClauseModal();
  }, [lastDraggedOwnTeamPlayerData, closeAddClauseModal, setNavigationProps]);


  const handlePlayerDrop = async (newPosition: string) => {
    if (!userConfig) return;

    if (teamOrSquadId && draggedPlayer !== undefined && newPosition !== draggedPlayer['positionKey']) {
      const currentPosition = draggedPlayer['positionKey'];

      let playerId = draggedPlayer['id'];
      const fullname = draggedPlayer['fullname'];

      if (currentPosition === 'addPlayerTable' || currentPosition === 'addPlayerTableExistingPlayers') {

        addPlayerOverview(playerId, currentUser);

        if (teamOrSquadId === 'ownTeam' && userHasFullOwnTeamAccess(userConfig)) {

          // if player with data, we add player details and open modal to add clause
          if (!isNaN(Number(playerId))) {
            addPlayerDetails(playerId, currentUser);
            setLastDraggedOwnTeamPlayerData({
              playerId: Number(playerId),
              playerName: fullname,
              wasAdded: currentPosition === 'addPlayerTable',
            });
          }

          // in case this is a player added from the academyTeam, we reset the role
          setPlayerProperty(playerId, 'role', null, userConfig.club);
        }

        if (isAddPlayerWithoutData && currentPosition === 'addPlayerTable') {
          playerId = await addPlayerWithoutData(fullname, userConfig.club);
        }

        if (playerId === undefined) return;

        const playerData: PlayerEntry = {
          id: playerId,
          fullname: fullname,
        };

        if (isSquad) {
          addPlayerToSquad(playerData, newPosition, teamOrSquadId, userConfig.email, userConfig.club);
        }

        else if (teamOrSquad) {
          addPlayerToTeam(playerData, newPosition, teamOrSquadId, teamOrSquad['name'], userConfig.email, userConfig.club);
          if (teamOrSquadId === 'ownTeam') {
            setPlayerProperty(playerId, 'isStartingEleven', showFirstEleven, userConfig.club);
          }
        }

        setAddPlayerSearchString('');
      }
      else {
        // no position change
        if (newPosition === 'bench') {
          setPlayerProperty(playerId, 'isStartingEleven', false, userConfig.club);
        }

        else {
          await changePlayerPositionInTeamOrSquad(
            playerId,
            teamOrSquadId,
            newPosition,
            userConfig.club,
            isSquad ?? false,
            currentPosition !== 'bench' ? currentPosition : undefined,
          );

          if (currentPosition === 'bench') {
            setPlayerProperty(playerId, 'isStartingEleven', true, userConfig.club);
          }
        }
      }
    }
    setDraggedPlayer(undefined);
  };


  useEffect(() => {
    setAddPlayerSearchString('');
    setIsAddPlayerWithoutData(false);
  }, [isAddPlayerViewExpanded]);


  useEffect(() => {
    if (teamOrSquad && teamOrSquadId && userConfig?.club) {
      getCompleteTeamHistory(teamOrSquadId, teamOrSquad['history'] ?? [], userConfig.club).then((history) => {
        setTeamHistory(history);
      });
    }
  }, [teamOrSquad, teamOrSquadId, userConfig?.club]);


  useEffect(() => {
    setIsAddPlayerViewExpanded(false);
    setRightSectionExpansionType(undefined);
  }, [logisticTeamViewToggle]);


  useEffect(() => {
    if (lastDraggedOwnTeamPlayerData !== undefined) {
      const globalModalPropsToSet: GlobalModalProps = {
        globalModalType: 'ConfirmModal',
        closeGlobalModal: closeAddClauseModal,
        confirm: confirmAddClause,
        confirmTitleKey: 'addClause?',
        confirmInfoKey: lastDraggedOwnTeamPlayerData?.wasAdded ? 'addClauseForBoughtPlayerInfo' : 'addClauseForSoldPlayerInfo',
        delay: 300,
      };
      setGlobalModalProps(globalModalPropsToSet);
    }
  }, [lastDraggedOwnTeamPlayerData, closeAddClauseModal, confirmAddClause, setGlobalModalProps]);


  return (
    <div>
      <div
        className={
          'logistic-team-view-section' +
          (isAddPlayerViewExpanded ? ' logistic-team-view-section-left-expanded' : '') +
          (rightSectionExpansionType ? ' logistic-team-view-section-right-expanded' : '')
        }
        style={{ height: isTabLine ? '94vh' : '98vh', marginTop: isTabLine ? '4vh' : undefined }}
      >
        {teamOrSquadId && (
          <TeamView
            teamOrSquadId={teamOrSquadId}
            teamOrSquadData={teamOrSquad}
            isSquad={isSquad}

            draggedPlayer={draggedPlayer}
            setDraggedPlayer={setDraggedPlayer}
            handlePlayerDrop={handlePlayerDrop}
            setLastDraggedOwnTeamPlayerData={setLastDraggedOwnTeamPlayerData}

            isLeftSectionExpanded={isAddPlayerViewExpanded}
            setIsLeftSectionExpanded={setIsAddPlayerViewExpanded}
            rightSectionExpansionType={rightSectionExpansionType}
            setRightSectionExpansionType={setRightSectionExpansionType}

            showFirstEleven={showFirstEleven}
          />
        )}
      </div>

      {teamOrSquad && (
        <div>
          <div
            className={
              'logistic-team-view-side-section' +
              (isAddPlayerViewExpanded ? ' logistic-team-view-left-section-expanded' : '')
            }
            style={{ marginTop: isTabLine ? '4vh' : 0 }}
          >
            {isAddPlayerViewExpanded && (
              <div className='fade-in' style={{ height: isTabLine ? '81vh' : '85vh', marginTop: '6.5vh' }}>
                <AddPlayerView
                  searchString={addPlayerSearchString}
                  setSearchString={setAddPlayerSearchString}

                  playerData={playerData}
                  setPlayerData={setPlayerData}

                  isSquad={isSquad ?? false}
                  isOwnTeam={teamOrSquadId === 'ownTeam'}
                  isTabLine={isTabLine}

                  isAddPlayerWithoutData={isAddPlayerWithoutData}
                  setIsAddPlayerWithoutData={setIsAddPlayerWithoutData}

                  draggedPlayer={draggedPlayer}
                  setDraggedPlayer={setDraggedPlayer}
                />
              </div>
            )}
          </div>

          <div
            className={
              'logistic-team-view-side-section logistic-team-view-right-section' +
              (rightSectionExpansionType ? ' logistic-team-view-right-section-expanded' : '')
            }
            style={{ marginTop: isTabLine ? '4vh' : 0 }}
          >
            {rightSectionExpansionType === 'history' && teamHistory && (
              <div className='logistic-team-view-right-expansion-section fade-in' style={{ height: isTabLine ? '81vh' : '85vh', marginTop: '6.5vh' }}>
                <TeamHistory
                  teamOrSquadId={teamOrSquadId}
                  history={teamHistory}
                  isSquad={isSquad}
                />
              </div>
            )}

            {rightSectionExpansionType === 'menu' && teamOrSquadId && (
              <div className='logistic-team-view-right-expansion-section fade-in' style={{ height: isTabLine ? '81vh' : '85vh', marginTop: '6.5vh' }}>
                <TeamMenu
                  teamOrSquadId={teamOrSquadId}
                  teamOrSquadData={teamOrSquad}
                  isSquad={isSquad}

                  showFirstEleven={showFirstEleven}
                  setShowFirstEleven={setShowFirstEleven}
                  benchPlayers={benchPlayers}
                  draggedPlayer={draggedPlayer}
                  setDraggedPlayer={setDraggedPlayer}
                  handlePlayerDrop={handlePlayerDrop}
                />
              </div>
            )}
          </div>
        </div>
      )}

    </div>
  );
};
