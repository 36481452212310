import './input.css';

import { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { AuthContextType, useAuthContext } from '../../../common/contexts/AuthContext';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CloseIcon from '@mui/icons-material/Close';

import { staticLanguageMap } from '../../../common/static/staticLanguageMap';
import { InputField } from './InputField';
import { Club, FlexibleJsonMapping } from '../../types';
import { debounce } from '../../utils/utils';
import { searchClubs } from '../../services/fokusServer/clubs';
import { useWindowSize } from '../../../common/hooks/WindowSize';


interface ClubDropDownProps {
  id: string;
  selectedClubs: Club[];
  setSelectedClubs: (value: Club[]) => void;
  isDropDownExpanded: boolean;
  setIsDropDownExpanded: (value: boolean) => void;
  defaultDropDownText: string; // static language key
  defaultDropDownTextColor: string;
  marginBetweenOptions: number;
  emptyBackgroundId?: string;
  maxHeight?: string | number; // controls scrolling
  singleSelect?: boolean;
}


export const ClubDropDown: React.FC<ClubDropDownProps> = ({
  id,
  selectedClubs,
  setSelectedClubs,
  isDropDownExpanded,
  setIsDropDownExpanded,
  defaultDropDownText,
  defaultDropDownTextColor,
  marginBetweenOptions,
  emptyBackgroundId,
  maxHeight,
  singleSelect,
}) => {

  const { currentUser } = useAuthContext() as AuthContextType;
  const userConfig = useRecoilValue(userConfigState);

  const { width } = useWindowSize();

  const inputRef = useRef<HTMLInputElement>(null);

  const [clubsToShow, setClubsToShow] = useState<Club[]>([]);

  const [searchString, setSearchString] = useState('');
  const [debouncedSearchString, setDebouncedSearchString] = useState<string>('');
  const clearDebounceRef = useRef<() => void>(() => null);

  const [isSearchWithoutResult, setIsSearchWithoutResult] = useState(false);


  const onChangeSearchField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value);
    if (event.target.value === '') {
      setClubsToShow([]);
      setDebouncedSearchString('');
      setIsSearchWithoutResult(false);
    }
  };


  const onKeyDownSearchField = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearchForClubs(true);
    }
  };


  const handleSearchForClubs = async (isUserInitiated: boolean) => {
    if (isUserInitiated) {
      clearDebounceRef.current();
    }

    const nextPageToQuery = 1; // only considering the first page for now

    if (searchString !== '') {
      const query = {
        name: searchString.toLowerCase(),
        page: nextPageToQuery,
      };

      try {
        const result: FlexibleJsonMapping | undefined = await searchClubs(currentUser, query);

        const totalHits = result?.total_hits;
        const page = result?.current_page;
        const clubs: Club[] = result?.clubs ?? [];

        if (!result || totalHits === undefined || !page) {
          setClubsToShow([]);
        }
        else if (totalHits === 0 || clubs.length === 0) {
          setClubsToShow([]);
          setIsSearchWithoutResult(true);
        }
        else {
          setClubsToShow(clubs);
          setIsSearchWithoutResult(false);
        }
      } catch (error) {
        console.log(error); // eslint-disable-line no-console
      }
    }
  };


  const handleDropDownClick = () => {
    if (isDropDownExpanded) {
      removeDropDownExpansion();
    }

    else {
      expandDropDown();
    }
  };


  const handleOptionSelect = (club: Club, isOptionAlreadySelected: boolean) => {
    if (singleSelect) {
      setSelectedClubs(isOptionAlreadySelected ? [] : [club]);
      if (!isOptionAlreadySelected) removeDropDownExpansion();
      return;
    }

    let newSelectedClubs = selectedClubs.slice();

    if (isOptionAlreadySelected) {
      newSelectedClubs = newSelectedClubs.filter(item => item.id !== club.id);
    }
    else {
      newSelectedClubs.push(club);
    }

    setSelectedClubs(newSelectedClubs);
  };


  const expandDropDown = () => {
    // zIndex handling is not ideal, but must be handlded carefully due to interactions between multiple drop downs
    let element = document.getElementById(id);
    if (element) {
      element.style.transition = '150ms';
      element.style.height = 'auto';
      element.style.zIndex = '110';
    }

    if (emptyBackgroundId) {
      element = document.getElementById(emptyBackgroundId);
      if (element) {
        element.style.transition = '150ms';
        element.style.zIndex = '100';
        element.style.backgroundImage = 'linear-gradient(to bottom, #00000000, #00000012)';
      }
    }

    setIsDropDownExpanded(true);
  };


  const removeDropDownExpansion = () => {
    let element = document.getElementById(id);
    if (element) {
      element.style.transition = '75ms';
      element.style.height = '28px';
      element.style.zIndex = '1';
    }

    if (emptyBackgroundId) {
      element = document.getElementById(emptyBackgroundId);
      if (element) {
        element.style.transition = '75ms';
        element.style.zIndex = '-1';
        element.style.backgroundImage = 'linear-gradient(to bottom, #00000000, #00000000)';
      }
    }

    setIsDropDownExpanded(false);
  };


  const showSelectedCountries = () => {

    if (!userConfig || selectedClubs.length === 0) return '';

    let display = selectedClubs[0].name;

    for (let i = 1; i < selectedClubs.length; i++) {
      display += ', ' + selectedClubs[i].name;
    }

    return display;
  };


  useEffect(() => {
    if (!isDropDownExpanded) {
      removeDropDownExpansion();
      setSearchString('');
      setDebouncedSearchString('');
      setClubsToShow([]);
    }
    else {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [isDropDownExpanded]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (searchString.length === 0) {
      setClubsToShow([]);
    }

    const [debounceFilter, clearDebounce] = debounce(() => {
      if (searchString !== debouncedSearchString) {
        setDebouncedSearchString(searchString);
      }
    }, 500);

    debounceFilter();

    clearDebounceRef.current = clearDebounce;

    return () => {
      clearDebounce();
    };
  }, [searchString]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (debouncedSearchString !== '') {
      handleSearchForClubs(false);
    }
  }, [debouncedSearchString]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div
      className={'drop-down-select-container' + ((isDropDownExpanded || selectedClubs.length > 0) ? ' drop-down-select-container-focus' : '')}
      id={id}
      style={{ maxHeight: maxHeight }}
    >

      <div className='drop-down-select-button' onClick={() => handleDropDownClick()}>

        {selectedClubs.length > 0 && <div className='drop-down-select-button-overlay fast-fade-in'>&nbsp;</div>}

        <div className='drop-down-select-button-text'>

          {selectedClubs.length === 0 && (
            <div style={{ color: defaultDropDownTextColor }}>
              {userConfig ? staticLanguageMap[defaultDropDownText][userConfig.language] : ''}
            </div>
          )}

          {selectedClubs.length > 0 && isDropDownExpanded && (
            <div style={{ color: defaultDropDownTextColor }}>
              {userConfig
                ? (singleSelect ? staticLanguageMap['selectedClub'][userConfig.language] : staticLanguageMap['selectedClubs'][userConfig.language])
                : ''}
            </div>
          )}

          {selectedClubs.length > 0 && !isDropDownExpanded && (
            <div style={{ color: '#000000' }}>
              {showSelectedCountries()}
            </div>
          )}

        </div>

        {!isDropDownExpanded && (
          <div className='drop-down-select-icon'>
            <ArrowDropDownIcon style={{ fontSize: 24 }} />
          </div>
        )}

        {isDropDownExpanded && (
          <div className='drop-down-select-icon'>
            <ArrowDropUpIcon style={{ fontSize: 24 }} />
          </div>
        )}
      </div>

      <div className='league-drop-down-section' style={{ maxHeight: maxHeight }}>

        <div className='league-drop-down-selected-leagues'>
          {selectedClubs.map((club: Club) => {
            return (
              <div
                className={'league-drop-down-selected-league'}
                key={club.id + '-selected'}
              >
                <div className='league-drop-down-selected-league-close-icon'>
                  <CloseIcon style={{ fontSize: 16 }} onClick={() => handleOptionSelect(club, true)} />
                </div>

                <img
                  style={{ maxHeight: 13, maxWidth: 13, marginRight: 3, marginLeft: 2 }}
                  src={club.logo_url}
                  alt={club.name}
                  draggable={false}
                />

                <div className='league-drop-down-selected-league-name'>
                  {club.name}
                </div>
              </div>
            );
          })}
        </div>

        <div
          className='league-drop-down-input-container'
          style={{ marginTop: singleSelect ? (34) : (12 + (22 * Math.max(selectedClubs.length, 2))) }}>
          <InputField
            searchString={searchString}
            onChangeInputField={onChangeSearchField}
            onKeyDownInputField={onKeyDownSearchField}
            resetSearchString={() => setSearchString('')}
            defaultInput={userConfig
              ? (width > 1350 ? staticLanguageMap['searchForClub'][userConfig.language] : staticLanguageMap['search'][userConfig.language])
              : ''}
            showDefaultInput={true}
            style={{ boxShadow: '0px 0px 2px 1px #00000020', backgroundColor: '#ffffff' }}
            ref={inputRef}
          />
        </div>

        {clubsToShow.length > 0 &&
          clubsToShow.map((club: Club) => {
            const isSelected = selectedClubs.some(item => item.id === club.id);
            return (
              <div
                className={'drop-down-select-option league-drop-down-option' + (isSelected ? ' drop-down-select-option-selected' : '')}
                style={{ marginTop: marginBetweenOptions }}
                key={club.id}
                onClick={() => handleOptionSelect(club, isSelected)}
              >
                <div className='league-drop-down-option-info-row'>
                  <img
                    style={{ maxHeight: 14, maxWidth: 14, marginRight: 3 }}
                    src={club.logo_url}
                    alt={club.name}
                    draggable={false}
                  />

                  {club.name}
                </div>
              </div>
            );
          })}

        {isSearchWithoutResult && (
          <div className='league-drop-down-no-search-result'>
            {userConfig ? staticLanguageMap['noResult'][userConfig.language] : ''}
          </div>
        )}

      </div>

    </div>
  );
};
