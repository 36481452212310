

interface FokusLogoProps {
  whiteLogo: boolean;
}

export const FokusLogo: React.FC<FokusLogoProps> = ({ whiteLogo }) => {

  const colorScheme = whiteLogo ? {
    'stroke': '#ffffff',
  } : {
    'stroke': '#090c11',
  };


  const strokeColor = colorScheme.stroke;

  return (
    <div className='full-size-container'>
      <svg width='100%' height='100%' viewBox='0 0 100 100' preserveAspectRatio='none'>

        <defs>
          <linearGradient id='logo-gradient-to-bottom' x1='0%' y1='0%' x2='0%' y2='100%'>
            <stop offset='0%' stopColor='#3884ff' stopOpacity='1' />
            <stop offset='100%' stopColor='#3070d7' stopOpacity='1' />
          </linearGradient>

          <linearGradient id='logo-gradient-to-top' x1='0%' y1='100%' x2='0%' y2='0%'>
            <stop offset='0%' stopColor='#3884ff' stopOpacity='1' />
            <stop offset='100%' stopColor='#3070d7' stopOpacity='1' />
          </linearGradient>
        </defs>

        {/* <circle cx="50" cy="50" r="50" fill="#ffde00aa" />

        <circle cx="50" cy="50" r="35" fill="#23a90077" />

        <circle cx="50" cy="50" r="28" fill="#23a966aa" /> */}

        {/* <path d="M 46,99.8 A 50 50 0 0 1 46 0.2 L 46 15.2 A 35 35 0 0 0 46 84.8 z" fill="url(#logo-gradient-to-bottom)" />

        <path d="M 54,99.8 A 50 50 0 0 0 54 0.2 L 54 15.2 A 35 35 0 0 1 54 84.8 z" fill="url(#logo-gradient-to-top)" />

        <path d="M 46,77.7 A 28 28 0 0 1 46 22.3 z" fill="url(#logo-gradient-to-top)" />

        <path d="M 54,77.7 A 28 28 0 0 0 54 22.3 z" fill="url(#logo-gradient-to-bottom)" /> */}

        <line x1="0" y1="50" x2="15" y2="50" style={{ stroke: strokeColor, strokeWidth: 6 }} />
        <line x1="85" y1="50" x2="100" y2="50" style={{ stroke: strokeColor, strokeWidth: 6 }} />

        {/* <path d="M 13 50 A 50 70 0 0 0 87 50 A 50 70 0 0 0 13 50" style={{ stroke: '#ffffff', strokeWidth: 1, fill: 'none' }} /> */}

        {/* <ellipse cx="50" cy="50" rx="33" ry="19" style={{ stroke: '#ffffff', strokeWidth: 2, fill: 'none' }} /> */}

        <path d="M 11 50 C 27 78.5, 73 78.5, 89 50 " style={{ stroke: strokeColor, strokeWidth: 5, fill: 'none' }} />

        <path d="M 11 50 C 27 21.5, 73 21.5, 89 50 " style={{ stroke: strokeColor, strokeWidth: 5, fill: 'none' }} />

        <path d="M 23.5 37.5 C 11 47, 11 53, 23.5 62.5 " style={{ stroke: strokeColor, strokeWidth: 3, fill: 'none' }} />

        <path d="M 76.5 37.5 C 89 47, 89 53, 76.5 62.5 " style={{ stroke: strokeColor, strokeWidth: 3, fill: 'none' }} />

        <circle cx="50" cy="50" r="5" style={{ fill: strokeColor }} />

        {/* <circle cx="15" cy="50" r="2" style={{ fill: '#ffffff' }} />
        <circle cx="85" cy="50" r="2" style={{ fill: '#ffffff' }} /> */}

      </svg>
    </div>
  );
};
