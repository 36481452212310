import { useRecoilState } from 'recoil';
import { playerOverviewsState } from '../atoms/playerOverviewsState';
import { FirebaseUser } from '../../../firebase';
import { getPlayerOverviews } from '../../services/fokusServer/playerOverviews';


export const useAddPlayerOverview = () => {

  const [playerOverviews, setPlayerOverviews] = useRecoilState(playerOverviewsState);

  const addPlayerOverview = async (playerId: number | string, currentUser: FirebaseUser) => {

    if (!isNaN(Number(playerId)) && playerOverviews && !(playerId in playerOverviews)) {

      const player = await getPlayerOverviews([Number(playerId)], currentUser);

      if (player) {
        setPlayerOverviews(currentPlayerOverviews => ({
          ...currentPlayerOverviews,
          ...player
        }));
      }
    }

  };

  return addPlayerOverview;
};
