import axios from 'axios';

import { FirebaseUser } from '../../../firebase';
import { FlexibleJsonMapping, PlayerId, PlayerOverviews } from '../../types';

import { BASE_URL, CONTRACT_KEYS_TO_MONTHS } from './constants';
import { getBearerToken } from './token';
import { positionOptionsPlatform } from '../../static/propertyValues';


// Get players_oveview for all players on a team or squad
export const getPlayerOverviewsOnTeams = async (
  teams: FlexibleJsonMapping,
  squads: FlexibleJsonMapping,
  currentUser: FirebaseUser
): Promise<PlayerOverviews> => {

  // Get id of all players
  const playerIds = new Set<PlayerId>();

  positionOptionsPlatform.forEach(positionKey => {
    Object.keys(teams).forEach(teamKey => {
      const teamData = teams[teamKey];
      if (positionKey in teamData) {
        const positionArray = teamData[positionKey];
        positionArray.forEach((player: FlexibleJsonMapping) => {
          playerIds.add(player['id']);
        });
      }
    });
    Object.keys(squads).forEach(squadKey => {
      const squadData = squads[squadKey];
      if (positionKey in squadData) {
        const positionArray = squadData[positionKey];
        positionArray.forEach((player: FlexibleJsonMapping) => {
          playerIds.add(player['id']);
        });
      }
    });
  });

  if (playerIds.size === 0) {
    return {};
  }

  const playersOnTeams = await getPlayerOverviews(Array.from(playerIds), currentUser);

  return playersOnTeams ?? {};
};


// Get players_oveview for all players in the given archived_teams that are not already in playerOverviews
export const getNewPlayerOverviews = async (
  archivedTeams: FlexibleJsonMapping[],
  currentPlayers: PlayerOverviews,
  currentUser: FirebaseUser
): Promise<PlayerOverviews> => {

  // Get id of all players
  const newPlayerIds = new Set<number>();

  archivedTeams.forEach(teamOrSquad => {
    positionOptionsPlatform.forEach(positionKey => {
      if (positionKey in teamOrSquad) {
        const positionArray = teamOrSquad[positionKey];
        positionArray.forEach((player: FlexibleJsonMapping) => {
          if (!(player['id'] in currentPlayers)) {
            newPlayerIds.add(player['id']);
          }
        });
      }
    });
  });

  if (newPlayerIds.size === 0) {
    return {};
  }

  const playersOnTeams = await getPlayerOverviews(Array.from(newPlayerIds), currentUser);

  return playersOnTeams ?? {};
};


export const getPlayerOverviews = async (playerIds: PlayerId[], user: FirebaseUser): Promise<PlayerOverviews | undefined> => {

  playerIds = playerIds.filter((playerId) => !isNaN(Number(playerId))).map((playerId) => Number(playerId));

  if (playerIds.length === 0) return undefined;

  const token = await getBearerToken(user);
  const playerIdsString = playerIds.join(',');
  const result = await axios.get(`${BASE_URL}/players/${playerIdsString}`, {
    headers: { Authorization: token },
  });
  if (result === undefined) {
    return undefined;
  }
  return result.data;
};


export const searchPlayers = async (
  user: FirebaseUser,
  options: {
    name?: string;
    minAge?: number;
    maxAge?: number;
    maxContractLength?: string;
    includeUnknownContracts?: boolean;
    primaryPositions?: string[];
    includeSecondaryPosition?: boolean;
    competitions?: number[];
    clubs?: number[];
    minConfidence?: number;
    maxConfidence?: number;
    minClubIndex?: number;
    maxClubIndex?: number;
    minSkillIndex?: number;
    maxSkillIndex?: number;
    countryCodes?: string[];
    playerIds?: number[];
    // minHeight?: number;
    // maxHeight?: number;
    // minIndexDifference?: number;
    // maxIndexDifference?: number;
    sortBy?: string;
    page?: number;
  }
): Promise<FlexibleJsonMapping | undefined> => {

  const token = await getBearerToken(user);

  const {
    name,
    minAge,
    maxAge,
    maxContractLength,
    includeUnknownContracts,
    primaryPositions,
    includeSecondaryPosition,
    competitions,
    clubs,
    minConfidence,
    maxConfidence,
    minClubIndex,
    maxClubIndex,
    minSkillIndex,
    maxSkillIndex,
    countryCodes,
    playerIds,
    // minHeight,
    // maxHeight,
    // minIndexDifference,
    // maxIndexDifference,
    sortBy,
    page = 1,
  } = options;

  const maxContractMonths = maxContractLength ? CONTRACT_KEYS_TO_MONTHS[maxContractLength] : undefined;
  const primaryPositionsString = primaryPositions ? primaryPositions.join(',') : undefined;
  const competitionsString = competitions ? competitions.join(',') : undefined;
  const clubsString = clubs ? clubs.join(',') : undefined;
  const countryCodesString = countryCodes ? countryCodes.join(',') : undefined;

  // if the array is undefined, we don't concern with any player ids, but if the array is empty, we attempt to search for an empty set of players
  if (playerIds && playerIds.length === 0) {
    return {
      total_hits: 0,
      current_page: 1,
      players: [],
    };
  }

  const playerIdsString = playerIds ? playerIds.join(',') : undefined;

  const result = await axios.get(`${BASE_URL}/search/players/`, {
    headers: { Authorization: token },
    params: {
      name,
      min_age: minAge,
      max_age: maxAge,
      max_contract_length: maxContractMonths,
      include_unknown_contracts: includeUnknownContracts,
      primary_positions: primaryPositionsString,
      include_secondary_position: includeSecondaryPosition,
      competitions: competitionsString,
      clubs: clubsString,
      min_confidence: minConfidence,
      max_confidence: maxConfidence,
      min_club_index: minClubIndex,
      max_club_index: maxClubIndex,
      min_skill_index: minSkillIndex,
      max_skill_index: maxSkillIndex,
      country_codes: countryCodesString,
      player_ids: playerIdsString,
      // min_height: minHeight,
      // max_height: maxHeight,
      // min_index_diff: minIndexDifference,
      // max_index_diff: maxIndexDifference,
      sort_by: sortBy,
      page: page,
    },
  });

  if (!result) return undefined;

  return result.data;
};
