import '../../platform.css';
import './scouting.css';

import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { selectedPlayerState } from '../../recoil/atoms/selectedPlayerState';

import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import NotificationsIcon from '@mui/icons-material/Notifications';

import { PlayerOverviews } from '../../types';
import { PlayerView } from '../../components/playerView/PlayerView';
import { staticLanguageMap } from '../../../common/static/staticLanguageMap';
import { FixedTabLine } from '../../components/tabLines/FixedTabLine';
import { PlayerScouting } from './playerScouting/PlayerScouting';
import { SeasonScouting } from './seasonScouting/SeasonScouting';


export const pagesPerChapter = 4;


export const Scouting = () => {

  const userConfig = useRecoilValue(userConfigState);
  const selectedPlayer = useRecoilValue(selectedPlayerState);

  const [activeTab, setActiveTab] = useState(0);
  const tabOptions = ['playerSearch', 'seasonSearch', 'news'];
  const tabIcons = [
    <PersonSearchIcon key={'person-search-icon'} style={{ fontSize: 21 }} />,
    <QueryStatsIcon key={'query-stats-icon'} style={{ fontSize: 21 }} />,
    <NotificationsIcon key={'notifications-icon'} style={{ fontSize: 21 }} />
  ];

  const [playerData, setPlayerData] = useState<PlayerOverviews | undefined>(undefined);


  return (
    <div className='platform-view-section'>

      <div className={'player-view-outer-container' + (selectedPlayer ? ' player-view-outer-container-player-selected' : '')}>
        {selectedPlayer && playerData && (
          <PlayerView
            playerOverview={playerData[Number(selectedPlayer.id)]}
          />
        )}
      </div>

      <FixedTabLine
        tabOptions={tabOptions}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabIcons={tabIcons}
      />

      {activeTab === 0 && (
        <PlayerScouting
          playerData={playerData}
          setPlayerData={setPlayerData}
        />
      )}

      {activeTab === 1 && (
        <SeasonScouting
          playerData={playerData}
          setPlayerData={setPlayerData}
        />
      )}

      {activeTab === 2 && (
        <div className='scouting-view-content-container'>
          <div className='scouting-view-news-title'>
            {userConfig ? staticLanguageMap['underDevelopment'][userConfig.language] : ''}
          </div>
        </div>
      )}

      {/* {activeTab === 2 &&
      <News
      playerData={playerData}
      setPlayerData={setPlayerData}
      />} */}

    </div>
  );
};
