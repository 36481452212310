import './../scouting.css';

import { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { teamsState } from '../../../recoil/atoms/teamsState';

import ReplayIcon from '@mui/icons-material/Replay';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
// import DownloadIcon from '@mui/icons-material/Download';
// import UploadIcon from '@mui/icons-material/Upload';
// import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
// import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
// import SaveIcon from '@mui/icons-material/Save';
// import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
// import CloudUploadIcon from '@mui/icons-material/CloudUpload';
// import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

import { InputField } from '../../../components/input/InputField';
import { DropDownSelect } from '../../../components/input/DropDownSelect';
import { staticLanguageMap } from '../../../../common/static/staticLanguageMap';
import { ageKeys, contractKeys, indexKeys, minConfidenceKeys, playerScoutingOrderByKeys, positionOptionsDatabase } from '../../../static/propertyValues';
import { useWindowSize } from '../../../../common/hooks/WindowSize';
import { LeagueDropDown } from '../../../components/input/LeagueDropDown';
import { CountryDropDown } from '../../../components/input/CountryDropDown';
import { Club, FlexibleJsonMapping } from '../../../types';
import { Dialog } from '@mui/material';
import { SaveOrLoadSearchModal } from '../../../components/modals/SaveOrLoadSearchModal';
import { saveUserSearch } from '../../../services/firestore/settings';
import { ClubDropDown } from '../../../components/input/ClubDropDown';


interface PlayerScoutingFiltersProps {
  nameSearchString: string;
  setNameSearchString: (value: string) => void;
  onKeyDownNameSearchField: (event: React.KeyboardEvent<HTMLInputElement>) => void;

  selectedMaxAge: string[];
  setSelectedMaxAge: (value: string[]) => void;
  selectedContractStatus: string[];
  setSelectedContractStatus: (value: string[]) => void;
  selectedLeagues: string[];
  setSelectedLeagues: (value: string[]) => void;
  selectedPositions: string[];
  setSelectedPositions: (value: string[]) => void;
  selectedOrderBy: string[];
  setSelectedOrderBy: (value: string[]) => void;

  selectedClubs: Club[];
  setSelectedClubs: (value: Club[]) => void;
  selectedMinConfidence: string[];
  setSelectedMinConfidence: (value: string[]) => void;
  selectedMinClubIndex: string[];
  setSelectedMinClubIndex: (value: string[]) => void;
  selectedMinSkillIndex: string[];
  setSelectedMinSkillIndex: (value: string[]) => void;
  selectedNationalities: string[];
  setSelectedNationalities: (value: string[]) => void;
  selectedLogisticTeams: string[];
  setSelectedLogisticTeams: (value: string[]) => void;

  isContractStatusToggled: boolean;
  setIsContractStatusToggled: (value: boolean) => void;
  isPositionsToggled: boolean;
  setIsPositionsToggled: (value: boolean) => void;

  isAnyFilterExpandedToggle: boolean;
  setIsAnyFilterExpandedToggle: (value: boolean) => void;
  isAdvancedFiltersExpanded: boolean;
  handleAdvancedFiltersExpansion: () => void;

  handleFilterUnexpansion: (filter: string) => void;
  handleSearchReset: () => void;
  setSearchWasLoaded: (value: boolean) => void;
}

export const PlayerScoutingFilters: React.FC<PlayerScoutingFiltersProps> = ({
  nameSearchString,
  setNameSearchString,
  onKeyDownNameSearchField,

  selectedMaxAge,
  setSelectedMaxAge,
  selectedContractStatus,
  setSelectedContractStatus,
  selectedLeagues,
  setSelectedLeagues,
  selectedPositions,
  setSelectedPositions,
  selectedOrderBy,
  setSelectedOrderBy,

  selectedClubs,
  setSelectedClubs,
  selectedMinConfidence,
  setSelectedMinConfidence,
  selectedMinClubIndex,
  setSelectedMinClubIndex,
  selectedMinSkillIndex,
  setSelectedMinSkillIndex,
  selectedNationalities,
  setSelectedNationalities,
  selectedLogisticTeams,
  setSelectedLogisticTeams,

  isContractStatusToggled,
  setIsContractStatusToggled,
  isPositionsToggled,
  setIsPositionsToggled,

  isAnyFilterExpandedToggle,
  setIsAnyFilterExpandedToggle,
  isAdvancedFiltersExpanded,
  handleAdvancedFiltersExpansion,

  handleFilterUnexpansion,
  handleSearchReset,
  setSearchWasLoaded,
}) => {

  const userConfig = useRecoilValue(userConfigState);
  const teams = useRecoilValue(teamsState);

  const width = useWindowSize().width;

  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  const [modalTypeOpen, setModalTypeOpen] = useState<string | undefined>(undefined);

  const [isMaxAgeExpanded, setIsMaxAgeExpanded] = useState(false);
  const [isContractStatusExpanded, setIsContractStatusExpanded] = useState(false);
  const [isLeaguesExpanded, setIsLeaguesExpanded] = useState(false);
  const [isPositionsExpanded, setIsPositionsExpanded] = useState(false);
  const [isOrderByExpanded, setIsOrderByExpanded] = useState(false);

  const [isClubsExpanded, setIsClubsExpanded] = useState(false);
  const [isMinConfidenceExpanded, setIsMinConfidenceExpanded] = useState(false);
  const [isMinClubIndexExpanded, setIsMinClubIndexExpanded] = useState(false);
  const [isMinSkillIndexExpanded, setIsMinSkillIndexExpanded] = useState(false);
  const [isNationalitiesExpanded, setIsNationalitiesExpanded] = useState(false);
  const [isLogisticTeamsExpanded, setIsLogisticTeamsExpanded] = useState(false);


  const onChangeNameSearchField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameSearchString(event.target.value);
  };


  const handleAdvancedFiltersClick = () => {
    const filterIcon = document.getElementById('filter-icon');
    if (filterIcon) {
      filterIcon.style.transition = '0ms';
      handleAdvancedFiltersExpansion();
      filterIcon.style.transition = '125ms';
    }
  };


  const handleResetFiltersClick = () => {
    handleSearchReset();

    setNameSearchString('');

    setSelectedMaxAge([]);
    setSelectedContractStatus([]);
    setSelectedLeagues([]);
    setSelectedPositions([]);
    setSelectedOrderBy(['clubIndex']);

    setSelectedClubs([]);
    setSelectedMinConfidence([]);
    setSelectedMinClubIndex([]);
    setSelectedMinSkillIndex([]);
    setSelectedNationalities([]);
    setSelectedLogisticTeams([]);

    setIsContractStatusToggled(false);
    setIsPositionsToggled(false);
  };


  const saveSearch = async (searchName: string) => {
    if (!userConfig) return;

    const search: FlexibleJsonMapping = {
      'searchName': searchName,
      'searchType': 'playerScouting',
    };

    if (nameSearchString.length > 0) search['nameSearchString'] = nameSearchString;
    if (selectedMaxAge.length > 0) search['maxAge'] = selectedMaxAge;
    if (selectedContractStatus.length > 0) search['contractStatus'] = selectedContractStatus;
    if (selectedLeagues.length > 0) search['leagues'] = selectedLeagues;
    if (selectedPositions.length > 0) search['positions'] = selectedPositions;
    if (selectedOrderBy.length > 0) search['orderBy'] = selectedOrderBy;
    if (selectedClubs.length > 0) search['clubs'] = selectedClubs.map((club: Club) => {
      return {
        'id': club.id,
        'name': club.name,
        'logo_url': club.logo_url,
      };
    });
    if (selectedMinConfidence.length > 0) search['minConfidence'] = selectedMinConfidence;
    if (selectedMinClubIndex.length > 0) search['minClubIndex'] = selectedMinClubIndex;
    if (selectedMinSkillIndex.length > 0) search['minSkillIndex'] = selectedMinSkillIndex;
    if (selectedNationalities.length > 0) search['nationalities'] = selectedNationalities;
    if (selectedLogisticTeams.length > 0) search['logisticTeams'] = selectedLogisticTeams;

    if (isContractStatusToggled) search['isContractStatusToggled'] = true;
    if (isPositionsToggled) search['isPositionsToggled'] = true;

    saveUserSearch(search, userConfig.email, userConfig.club);
  };


  const loadSearch = (savedSearch: FlexibleJsonMapping) => {
    setNameSearchString(savedSearch['nameSearchString'] ?? '');
    setSelectedMaxAge(savedSearch['maxAge'] ?? []);
    setSelectedContractStatus(savedSearch['contractStatus'] ?? []);
    setSelectedLeagues(savedSearch['leagues'] ?? []);
    setSelectedPositions(savedSearch['positions'] ?? []);
    setSelectedOrderBy(savedSearch['orderBy'] ?? []);
    setSelectedClubs(savedSearch['clubs'] ?? []);
    setSelectedMinConfidence(savedSearch['minConfidence'] ?? []);
    setSelectedMinClubIndex(savedSearch['minClubIndex'] ?? []);
    setSelectedMinSkillIndex(savedSearch['minSkillIndex'] ?? []);
    setSelectedNationalities(savedSearch['nationalities'] ?? []);
    setSelectedLogisticTeams(savedSearch['logisticTeams'] ?? []);

    if (savedSearch['isContractStatusToggled']) setIsContractStatusToggled(true);
    if (savedSearch['isPositionsToggled']) setIsPositionsToggled(true);

    setIsAnyFilterExpandedToggle(false);
    setSearchWasLoaded(true);
  };


  useEffect(() => {
    if (!isAnyFilterExpandedToggle) {
      setIsMaxAgeExpanded(false);
      setIsContractStatusExpanded(false);
      setIsLeaguesExpanded(false);
      setIsPositionsExpanded(false);
      setIsOrderByExpanded(false);
      setIsClubsExpanded(false);
      setIsMinConfidenceExpanded(false);
      setIsMinClubIndexExpanded(false);
      setIsMinSkillIndexExpanded(false);
      setIsNationalitiesExpanded(false);
      setIsLogisticTeamsExpanded(false);
    }
    setIsAnyFilterExpandedToggle(true);
  }, [isAnyFilterExpandedToggle, setIsAnyFilterExpandedToggle]);


  useEffect(() => {
    if (!isAdvancedFiltersExpanded) {
      setShowAdvancedFilters(false);
    }
    else {
      const timer = setTimeout(() => {
        setShowAdvancedFilters(true);
      }, 200);

      return () => clearTimeout(timer);
    }
  }, [isAdvancedFiltersExpanded]);


  useEffect(() => {
    if (!isMaxAgeExpanded) {
      handleFilterUnexpansion('maxAge');
    }
  }, [isMaxAgeExpanded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isContractStatusExpanded) {
      handleFilterUnexpansion('contractStatus');
    }
  }, [isContractStatusExpanded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isLeaguesExpanded) {
      handleFilterUnexpansion('leagues');
    }
  }, [isLeaguesExpanded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isPositionsExpanded) {
      handleFilterUnexpansion('positions');
    }
  }, [isPositionsExpanded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isOrderByExpanded) {
      handleFilterUnexpansion('orderBy');
    }
  }, [isOrderByExpanded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isClubsExpanded) {
      handleFilterUnexpansion('clubs');
    }
  }, [isClubsExpanded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isMinConfidenceExpanded) {
      handleFilterUnexpansion('minConfidence');
    }
  }, [isMinConfidenceExpanded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isMinClubIndexExpanded) {
      handleFilterUnexpansion('minClubIndex');
    }
  }, [isMinClubIndexExpanded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isMinSkillIndexExpanded) {
      handleFilterUnexpansion('minSkillIndex');
    }
  }, [isMinSkillIndexExpanded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isNationalitiesExpanded) {
      handleFilterUnexpansion('nationalities');
    }
  }, [isNationalitiesExpanded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isLogisticTeamsExpanded) {
      handleFilterUnexpansion('logisticTeams');
    }
  }, [isLogisticTeamsExpanded]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div className='filter-section'>

      <Dialog
        open={modalTypeOpen !== undefined}
        onClose={() => setModalTypeOpen(undefined)}
        PaperProps={{ sx: { borderRadius: '0px' } }}
      >
        <SaveOrLoadSearchModal
          modalType={modalTypeOpen}
          searchType={'playerScouting'}
          closeModal={() => setModalTypeOpen(undefined)}
          saveSearch={saveSearch}
          loadSearch={loadSearch}
        />
      </Dialog>

      <div className='player-filter-section-input-container'>
        <InputField
          searchString={nameSearchString}
          onChangeInputField={onChangeNameSearchField}
          onKeyDownInputField={onKeyDownNameSearchField}
          resetSearchString={() => setNameSearchString('')}
          defaultInput={userConfig
            ? (width > 1150 ? staticLanguageMap['searchForName'][userConfig.language] : staticLanguageMap['name'][userConfig.language])
            : ''}
          showDefaultInput={true}
          style={{ boxShadow: '0px 0px 3px 1px #0000002d' }}
        />
      </div>

      <div className='filter-section-drop-down-title player-filter-section-age'>
        {userConfig ? staticLanguageMap['maxAge'][userConfig.language] : ''}
      </div>
      <div className='filter-section-drop-down player-filter-section-age'>
        <DropDownSelect
          id={'player-filter-section-age-drop-down'}
          dropDownOptions={ageKeys}
          isDropDownExpanded={isMaxAgeExpanded}
          setIsDropDownExpanded={setIsMaxAgeExpanded}
          selectedOptions={selectedMaxAge}
          setSelectedOptions={setSelectedMaxAge}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          marginBetweenOptions={3}
          emptyBackgroundId={'player-scouting-empty-background'}
          maxHeight={'84vh'}
        />
      </div>

      <div className='filter-section-drop-down-title player-filter-section-contract'>
        {userConfig ? staticLanguageMap['contractStatus'][userConfig.language] : ''}
      </div>
      <div className='filter-section-drop-down player-filter-section-contract'>
        <DropDownSelect
          id={'player-filter-section-contract-drop-down'}
          dropDownOptions={contractKeys}
          isDropDownExpanded={isContractStatusExpanded}
          setIsDropDownExpanded={setIsContractStatusExpanded}
          selectedOptions={selectedContractStatus}
          setSelectedOptions={setSelectedContractStatus}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          marginBetweenOptions={3}
          emptyBackgroundId={'player-scouting-empty-background'}
          toggleText={'includeUnknownContractStatus'}
          isToggled={isContractStatusToggled}
          setIsToggled={setIsContractStatusToggled}
        />
      </div>

      <div className='filter-section-drop-down-title player-filter-section-positions'>
        {userConfig ? staticLanguageMap['positions'][userConfig.language] : ''}
      </div>
      <div className='filter-section-drop-down player-filter-section-positions'>
        <DropDownSelect
          id={'player-filter-section-positions-drop-down'}
          dropDownOptions={positionOptionsDatabase}
          isDropDownExpanded={isPositionsExpanded}
          setIsDropDownExpanded={setIsPositionsExpanded}
          selectedOptions={selectedPositions}
          setSelectedOptions={setSelectedPositions}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          marginBetweenOptions={3}
          emptyBackgroundId={'player-scouting-empty-background'}
          multiSelect={true}
          toggleText={'includeSecondaryPosition'}
          isToggled={isPositionsToggled}
          setIsToggled={setIsPositionsToggled}
        />
      </div>

      <div className='filter-section-drop-down-title player-filter-section-competitions'>
        {userConfig ? staticLanguageMap['leagues'][userConfig.language] : ''}
      </div>
      <div className='filter-section-drop-down player-filter-section-competitions'>
        <LeagueDropDown
          id={'player-filter-section-competitions-drop-down'}
          isDropDownExpanded={isLeaguesExpanded}
          setIsDropDownExpanded={setIsLeaguesExpanded}
          selectedLeagues={selectedLeagues}
          setSelectedLeagues={setSelectedLeagues}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          marginBetweenOptions={3}
          emptyBackgroundId={'player-scouting-empty-background'}
          maxHeight={'84vh'}
        />
      </div>

      <div className='filter-section-drop-down-title player-filter-section-order-by'>
        {userConfig ? staticLanguageMap['orderBy'][userConfig.language] : ''}
      </div>
      <div className='filter-section-drop-down player-filter-section-order-by'>
        <DropDownSelect
          id={'player-filter-section-ordering-drop-down'}
          dropDownOptions={playerScoutingOrderByKeys}
          isDropDownExpanded={isOrderByExpanded}
          setIsDropDownExpanded={setIsOrderByExpanded}
          selectedOptions={selectedOrderBy}
          setSelectedOptions={setSelectedOrderBy}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          marginBetweenOptions={3}
          emptyBackgroundId={'player-scouting-empty-background'}
          forceSelection={true}
        />
      </div>

      <div
        className='filter-section-icon filter-section-left-icon'
        onClick={() => handleResetFiltersClick()}
        title={userConfig ? staticLanguageMap['resetFilters'][userConfig.language] : undefined}
      >
        <ReplayIcon style={{ fontSize: 20 }} />
      </div>

      <div
        id='filter-icon'
        className='filter-section-icon filter-section-right-icon'
        onClick={() => handleAdvancedFiltersClick()}
        title={userConfig ? staticLanguageMap['moreFilters'][userConfig.language] : undefined}
      >
        {!isAdvancedFiltersExpanded && <FilterAltIcon style={{ fontSize: 20, marginTop: 1 }} />}
        {isAdvancedFiltersExpanded && <CloseIcon style={{ fontSize: 20 }} />}
      </div>

      {isAdvancedFiltersExpanded && showAdvancedFilters && (
        <div className='filter-section-advanced-filter-section'>
          <div className='filter-section-drop-down-title player-filter-section-clubs'>
            {userConfig ? staticLanguageMap['clubs'][userConfig.language] : ''}
          </div>
          <div className='filter-section-drop-down player-filter-section-clubs'>
            <ClubDropDown
              id={'player-filter-section-clubs-drop-down'}
              isDropDownExpanded={isClubsExpanded}
              setIsDropDownExpanded={setIsClubsExpanded}
              selectedClubs={selectedClubs}
              setSelectedClubs={setSelectedClubs}
              defaultDropDownText={'select'}
              defaultDropDownTextColor={'#00000080'}
              marginBetweenOptions={3}
              emptyBackgroundId={'player-scouting-empty-background'}
              maxHeight={'75vh'}
            />
          </div>

          <div className='filter-section-drop-down-title player-filter-section-confidence'>
            {userConfig ? staticLanguageMap['minConfidence'][userConfig.language] : ''}
          </div>
          <div className='filter-section-drop-down player-filter-section-confidence'>
            <DropDownSelect
              id={'player-filter-section-confidence-drop-down'}
              dropDownOptions={minConfidenceKeys}
              isDropDownExpanded={isMinConfidenceExpanded}
              setIsDropDownExpanded={setIsMinConfidenceExpanded}
              selectedOptions={selectedMinConfidence}
              setSelectedOptions={setSelectedMinConfidence}
              defaultDropDownText={'select'}
              defaultDropDownTextColor={'#00000080'}
              marginBetweenOptions={3}
              emptyBackgroundId={'player-scouting-empty-background'}
              maxHeight={'75vh'}
            />
          </div>

          <div className='filter-section-drop-down-title player-filter-section-club-index'>
            {userConfig ? staticLanguageMap['minClubIndex'][userConfig.language] : ''}
          </div>
          <div className='filter-section-drop-down player-filter-section-club-index'>
            <DropDownSelect
              id={'player-filter-section-club-index-drop-down'}
              dropDownOptions={indexKeys}
              isDropDownExpanded={isMinClubIndexExpanded}
              setIsDropDownExpanded={setIsMinClubIndexExpanded}
              selectedOptions={selectedMinClubIndex}
              setSelectedOptions={setSelectedMinClubIndex}
              defaultDropDownText={'select'}
              defaultDropDownTextColor={'#00000080'}
              marginBetweenOptions={3}
              emptyBackgroundId={'player-scouting-empty-background'}
              maxHeight={'75vh'}
            />
          </div>

          <div className='filter-section-drop-down-title player-filter-section-skill-index'>
            {userConfig ? staticLanguageMap['minSkillIndex'][userConfig.language] : ''}
          </div>
          <div className='filter-section-drop-down player-filter-section-skill-index'>
            <DropDownSelect
              id={'player-filter-section-skill-index-drop-down'}
              dropDownOptions={indexKeys}
              isDropDownExpanded={isMinSkillIndexExpanded}
              setIsDropDownExpanded={setIsMinSkillIndexExpanded}
              selectedOptions={selectedMinSkillIndex}
              setSelectedOptions={setSelectedMinSkillIndex}
              defaultDropDownText={'select'}
              defaultDropDownTextColor={'#00000080'}
              marginBetweenOptions={3}
              emptyBackgroundId={'player-scouting-empty-background'}
              maxHeight={'75vh'}
            />
          </div>

          <div className='filter-section-drop-down-title player-filter-section-nationalities'>
            {userConfig ? staticLanguageMap['nationalities'][userConfig.language] : ''}
          </div>
          <div className='filter-section-drop-down player-filter-section-nationalities'>
            <CountryDropDown
              id={'player-filter-section-nationalities-drop-down'}
              isDropDownExpanded={isNationalitiesExpanded}
              setIsDropDownExpanded={setIsNationalitiesExpanded}
              selectedCountryCodes={selectedNationalities}
              setSelectedCountryCodes={setSelectedNationalities}
              defaultDropDownText={'select'}
              defaultDropDownTextColor={'#00000080'}
              marginBetweenOptions={3}
              emptyBackgroundId={'player-scouting-empty-background'}
              maxHeight={'75vh'}
            />
          </div>

          <div className='filter-section-drop-down-title player-filter-section-logistic-teams'>
            {userConfig ? staticLanguageMap['logisticTeams'][userConfig.language] : ''}
          </div>
          <div className='filter-section-drop-down player-filter-section-logistic-teams'>
            <DropDownSelect
              id={'player-filter-section-logistic-teams-drop-down'}
              dropDownOptions={teams ? Object.values(teams).map((team: FlexibleJsonMapping) => team.name) : []}
              isDropDownExpanded={isLogisticTeamsExpanded}
              setIsDropDownExpanded={setIsLogisticTeamsExpanded}
              selectedOptions={selectedLogisticTeams}
              setSelectedOptions={setSelectedLogisticTeams}
              defaultDropDownText={'select'}
              defaultDropDownTextColor={'#00000080'}
              marginBetweenOptions={3}
              emptyBackgroundId={'player-scouting-empty-background'}
              maxHeight={'75vh'}
              multiSelect={true}
            />
          </div>

          <div
            className='filter-section-icon filter-section-left-icon'
            onClick={() => setModalTypeOpen('saveSearch')}
            title={userConfig ? staticLanguageMap['saveSearch'][userConfig.language] : undefined}
          >
            <SaveAsIcon style={{ fontSize: 20, marginTop: -1 }} />
          </div>

          <div
            className='filter-section-icon filter-section-right-icon'
            onClick={() => setModalTypeOpen('loadSearch')}
            title={userConfig ? staticLanguageMap['loadSearch'][userConfig.language] : undefined}
          >
            <ArrowCircleDownIcon style={{ fontSize: 20 }} />
          </div>
        </div>
      )}

    </div>
  );
};
