/* eslint-disable max-len */
import { FlexibleJsonMapping } from '../types';


export const supportEntries: FlexibleJsonMapping = {
  'general': {
    'title': {
      'en': 'General',
      'no': 'Generelt'
    },
    'subsections': [
      {
        'title': {
          'en': 'Scout teams, Shadow team and Master team',
          'no': 'Speiderlag, Skyggelag og Masterlag'
        },
        'text': {
          'en': [
            'The Scout teams, Shadow team and Master team constitute the logistics teams, and are together with the Own team designed to make it easy for the club to keep track of transfer targets and own players. To maintain a tidy structure, a player can only exist on one team at a time (with one exception: Squad Builder, which you can read about further down the page).',
            'Scout teams _ The club\'s first filter for interesting players. The club can create its own teams, such as separate scout teams for each scout in the club, and/or other categories like specific geographies or age groups.',
            'Shadow team _ When the club determines that a player should be closely monitored, the player is moved to the Shadow Team. These are players the club wants to see live and where it is natural to document matches through reports. Ideally, the club should have at least two alternatives for each position at any time, and preferably even more players in the most critical positions.',
            'Master team _ The Master Team is intended to be filled with players the club has decided to try to acquire in the near future. The Master Team can be seen as the players the club has finished scouting.',
            'Player flow _ Players can be moved between teams by changing the team status within the player view, or by using the name search to the left in the logistics teams. Each player and each logistics team also has a log, describing who changed the team status and when this happened.'
          ],
          'no': [
            'Speiderlag, Skyggelag og Masterlag utgjør logistikklagene, og er sammen med Eget lag designet for å gjøre det enkelt for klubben å holde oversikt over overgangsmål og egne spillere. For å opprettholde en ryddig struktur kan en spiller kun eksistere på ett lag om gangen (med ett unntak: Stallbygger, som du kan lese om lenger ned på siden).',
            'Speiderlag _ Grovfilter for interessante spillere./nKlubben kan selv opprette egne lag, som for eksempel dedikerte lag til hver speider i klubben, og/eller andre kategorier som spesifikke geografier eller aldersgrupper.',
            'Skyggelag _ Spillere klubben følger tett./nKlubben ser gjerne spillerne live, og det er naturlig å dokumentere kamper gjennom rapporter. Ideelt sett har klubben til enhver tid minst to alternativer på hver posisjon, og gjerne flere spillere på posisjoner som er mer prekære.',
            'Masterlag _ Spillere klubben har bestemt seg for å hente./nMasterlaget er tiltenkt å fylles med spillere klubben forsøker å hente i nærmeste fremtid. Masterlaget kan ses på som spillerne klubben er ferdig med å speide.',
            'Flyt av spillere _ Spillere kan flyttes mellom lag ved å endre lagstatus inne på spilleren, eller ved navnesøket til venstre inne på lagene. Hver spiller og hvert lag har også en logg, med informasjon om hvem som endret lagstatus og når dette skjedde.'
          ]
        }
      },
      {
        'title': {
          'en': 'Player search and Season search',
          'no': 'Spillersøk og Sesongsøk'
        },
        'text': {
          'en': [
            'Player search _ The player search is designed to provide an easy overview of the most interesting players in a market and to search by name or club. Are you wondering which left-back in the Nordic countries under 23 years that would best fit your club, or if an incoming tip is worth following up on, this is the search for you. We like to call this the sports director search.',
            'Season search _ The season search can be used to dive deeper into the statistics of players in selected seasons and to filter and sort by specific metrics. If you want to see which midfielders have been the best passers in a given league this year, or if you want to exclude full-backs who are below average at dribbling, this is the search for you. We like to call this the scout search. The search can also be used to read the statistics of subsets of players, for instance players on your own team, using the "Logistics Team" filter.',
            'Save search _ To save time, each user can save their own custom searches both in player search and season search. This is done by first configuring the search filters, and then clicking on the save icon to the right under "More filters".'
          ],
          'no': [
            'Spillersøk _ Spillersøket er designet for å gi en enkel oversikt over de mest interessante spillerne i et marked, og til å søke opp enkelte navn eller klubber. Lurer du på hvilken venstreback i Norden under 23 år som passer best til din klubb, eller om et innkommende tips er verdt å følge videre, er dette søket for deg. Vi liker å kalle dette for sportssjef-søket.',
            'Sesongsøk _ Sesongsøket kan brukes til å dykke dypere i statistikken til spillere i valgte sesonger, og til å filtrere og sortere på spesifikke metrikker. Er du nysgjerrige på hvilke midtbanespillere som har vært de beste pasningsspillerne i OBOS-ligaen i år, eller ønsker du å utelukke backer som er under middels til å drible, er dette søket for deg. Dette liker vi å kalle for speider-søket. Søket kan også brukes til å samle og lese statistikken til spillere på Eget lag eller andre logistikklag, ved å bruke filteret “Logistikklag”.',
            'Lagre søk _ For å spare tid kan du lagre personlige søk, både spillersøk og sesongsøk. Dette gjøres ved å først konfigurere søkefiltrene, og deretter klikke på lagre-ikonet til høyre under "Flere filtre". Søkene du lagrer tilhører din bruker, og vises ikke for noen andre.'
          ]
        }
      },
      {
        'title': {
          'en': 'Archived teams',
          'no': 'Arkiverte lag'
        },
        'text': {
          'en': [
            'In preparation for a transfer window, many players can accumulate. To avoid losing valuable work and to maintain a good overview, you can archive all logistics teams, which in practice means saving a "screenshot" of previous work. To archive a team, open the "Team menu" to the right of the team and navigate to "Team actions".',
            'Our recommendation is to carry out a cleanup after each completed transfer window by:',
            '1. Archiving all your relevant teams,',
            '2. Conducting a cleanup by either resetting the teams or removing individual players.',
            'If you want to look back at targets of previous transfer windows, you can browse through the club\'s archived teams in the Archive.'
          ],
          'no': [
            'Frem mot et overgangsvindu fyller det seg gjerne opp med mange spillere på ulike lag. For å unngå å miste verdifullt arbeid og samtidig holde god orden, kan du arkivere logistikklag og se tilbake på dem senere. For å arkive et lag åpner man "Lagmenyen" til høyre på laget og navigerer til "Laghandlinger".',
            'Vår anbefaling er å gjennomføre en opprydning etter hvert fullførte overgangsvindu ved å:',
            '1. Arkivere alle relevante lag',
            '2. Gjennomføre en opprydning ved å enten nullstille lagene eller fjerne enkelspillere',
            'Hvis du ønsker å se tilbake på tidligere logistikkarbeid og gamle overgangsmål, blar du deg enkelte gjennom de daterte arkiverte lagene til klubben under Arkiv.'
          ]
        }
      },
      {
        'title': {
          'en': 'Squad Builder',
          'no': 'Stallbygger'
        },
        'text': {
          'en': [
            'Squad Builder is a flexible tool that allows the club to add and remove players without affecting the Own team or other logistics teams.',
            'One intended use is to set up the expected squads after upcoming transfer windows, including academy players, such as "Squad 2025" and "Squad 2026". When you create a squad, you will have the option to load players into the squad from existing teams, such as Own team.',
            'Other uses include creating draft teams, for instance in preparation or during a meeting with the coaching staff, or keeping track of your own players in contract negotiations.'
          ],
          'no': [
            'Stallbygger er et fleksibelt verktøy som lar klubben legge til og slette spillere uten at det påvirker Eget lag eller andre logistikklag.',
            'Et tiltenkt bruksområde er å sette opp forventet stall etter kommende overgangsvinduer, inkludert akademispillere, som for eksempel “Stall 2025” og “Stall 2026”. Når du oppretter en stall, vil du ha muligheten til å laste inn spillere fra eksisterende lag, som Eget lag.',
            'Andre bruksområder er å opprette kladdelag, for eksempel før et møte med trenerteamet, eller å holde oversikt over egne spillere i kontraktsforhandlinger.'
          ]
        }
      },
    ]
  },
  'faq': {
    'title': {
      'en': 'FAQ',
      'no': 'Vanlige spørsmål'
    },
    'subsections': [
      {
        'title': {
          'en': 'How do I add a player I can\'t find in the database?',
          'no': 'Hvordan legger jeg til en spiller jeg ikke finner i databasen?'
        },
        'text': {
          'en': [
            'To track a player without data, you can manually create the player in the name search to the left in all teams and squads.',
            'Click on the "Add Player" icon and then "Add player without data" and enter the name. After the player is created, you can enter age and nationality, and optionally your reports.',
            'When the player eventually debuts professionally, the player will most likely appear in our database quickly. You can then use the linking functionality within the player view to search for the player in the database and link that player to the manual player.'
          ],
          'no': [
            'For å følge opp juniorspillere, eller spillere som av andre grunner ikke finnes i databasen, kan du opprette spillere manuelt i navnesøket til venstre inne på alle lag og staller.',
            'Klikk på ikonet “Legg til spiller” og deretter “Legg til spiller uten data” og oppgi navnet. Etter at spilleren er opprettet kan du legge inn alder og nasjonalitet, og eventuelt dine rapporter.',
            'Når spilleren etterhvert debuterer profesjonelt vil han sannsynligvis dukke opp i vår database raskt. Da kan man bruke koblingsfunksjonaliteten inne på spilleren for å søke opp den faktiske spilleren i databasen, og koble den manuelle spilleren opp mot riktig spiller.'
          ]
        }
      },
      {
        'title': {
          'en': 'Why does the table on the Home page show fewer matches for advanced data?',
          'no': 'Hvorfor viser tabellen på Hjem-siden færre kamper spilt for avansert data?'
        },
        'text': {
          'en': [
            'The advanced data in the table on the Home page are based on event data from IMPECT. The data is collected manually and made available to us within a certain timeframe. Different leagues have different deadlines, ranging from the day after to mulitple days. We make the data available in our platform as soon as we receive it.',
            'This means that the table with advanced data also serves as an indicator of which matches we have detailed data from in your league.'
          ],
          'no': [
            'Den avanserte dataen i tabellen på Hjem-siden er basert på event-data fra IMPECT. Dataene samles inn manuelt, og gjøres tilgjengelig for oss innen en bestemt frist. Ulike ligaer har ulike frister, der enkelte kamper samles inn dagen etter, mens noen kamper kommer senere. Vi tilgjengeliggjør dataene inne på plattformen så snart vi får dem.',
            'Dette betyr at tabellen med avansert data også fungerer som en indikator på hvilke kamper vi har detaljert data fra i din nåværende liga.',
          ]
        }
      },
      {
        'title': {
          'en': 'How can I get insight into a "locked" league?',
          'no': 'Hvordan kan jeg få innsikt i en “låst” liga?'
        },
        'text': {
          'en': [
            'The club itself has chosen leagues with event data. To see the leagues you have access to, you can go to "Settings" and "Club". If you want analyses from more leagues, you can contact us directly, and we will see if we can upgrade your package or update the chosen leagues.'
          ],
          'no': [
            'Klubben har selv valgt ligaer med event-data. For å se ligaene du har tilgang kan du gå til “Innstillinger” og “Klubb”. Ønsker dere analyser fra flere ligaer kan dere ta kontakt med oss direkte, så ser vi om vi kan oppgradere pakken deres eller endre valgte ligaer.'
          ]
        }
      },
      {
        'title': {
          'en': 'I want to clean up a logistic team, but I am afraid of losing players. What can I do?',
          'no': 'Jeg ønsker å rydde opp i et logistikklag, men er redd for å miste spillerne, hva kan jeg gjøre?'
        },
        'text': {
          'en': [
            'To avoid losing valuable work and to maintain good order, you can archive all logistics teams, which in practice means saving a "screenshot" of previous work. See "Archived Teams" above. Best practice is to archive the teams after each completed transfer window, and then clean up.',
            'Additionally, each player and each team has a log that shows historical movements. If you delete a player from the Shadow Team, for example, you can still see in the log that the player was previously included.'
          ],
          'no': [
            'For å unngå å miste verdifullt arbeid, og samtidig holde god orden kan man arkivere alle logistikklag, som i praksis betyr å lagre et “skjermbilde” av tidligere arbeid. Se “Arkiverte lag” lenger oppe. Typisk bruk vil være å arkivere lagene etter hvert fullførte overgangsvindu, før man rydder opp.',
            'I tillegg har hver spiller og hvert lag en logg som viser bevegelsen historisk. Om man sletter en spiller fra Skyggelaget for eksempel, vil man fortsatte kunne se i loggen at spilleren lå inne tidligere.'
          ]
        }
      },
      {
        'title': {
          'en': 'What is the difference between Club Rating and Club Index?',
          'no': 'Hva er forskjellen på Klubbrating og Klubbindeks?'
        },
        'text': {
          'en': [
            'The difference lies in the time frame we look at. A Club Rating is an evaluation of how well a player has performed in a given club in a given season in a given competition, while a Club Index is an aggregated evaluation of how well a player has performed over a longer period of time.',
            'Imagine a player who has played in club A in Eliteserien 23, and then in club B in the first half of Eliteserien 24 and club C in the second half of Eliteserien 24. This player will then have a separate Club Rating for each of the three "periods". However, he will only have one Club Index, which is an aggregated evaluation of his time in club A, B and C. The Club Index takes into account how many minutes the player played in each of the 3 clubs, as well as how far back in time the performances are from.',
            'The same applies to Skill Rating and Skill Index.',
            'Read more about Club Rating, Club Index, Skill Rating and Skill Index further down on the page under "Models".'
          ],
          'no': [
            'Forskjellen ligger i tidsrammen vi ser på. En Klubbrating er en vurdering av hvor godt en spiller har prestert i en gitt klubb i en gitt sesong i en gitt liga, mens Klubbindeks er en samlet vurdering av hvor godt en spiller har prestert over lengre tid.',
            'Se for deg at en spiller har spilt i klubb A i Eliteserien 23, og deretter i klubb B i første halvdel av Eliteserien 24 og klubb C i andre halvdel av Eliteserien 24. Denne spilleren vil da ha en egen Klubbrating for hver av de tre "periodene". Han vil derimot kun ha én Klubbindeks, som er en samlet vurdering av tiden hans i klubb A, B og C. Klubbindeksen tar henysn til hvor mange minutter spilleren spilte i hver av de 3 klubbene, samt hvor langt tilbake i tid prestasjonene er fra.',
            'Det samme gjelder for Skillrating og Skillindeks.',
            'Les mer om Klubbrating, Klubbindeks, Skillrating og Skillindeks lengre ned på siden her under "Modeller".'
          ]
        }
      },
      {
        'title': {
          'en': 'How do I add new accounts?',
          'no': 'Hvordan legger jeg til en ny bruker?'
        },
        'text': {
          'en': [
            'You need "Admin" access to add users and manage the access of existing users. Normally, the sporting director in the club will have this access, but other users can also have this access if desired. To change who has "Admin" access, you need to contact us.',
            'If you have "Admin" access, you can add new users by going to "Settings" and "Club", and clicking on the plus icon under "Accounts".',
            'When you create an account, you will be able to choose what access the user should have, and you will be able to read more about what the different options entails. If the club wants other accesses, you can contact us.'
          ],
          'no': [
            'Du trenger en egen "Admin"-tilgang for å kunne legge til brukere og styre tilgangen til eksisterende brukere. Normalt vil sportssjefen i klubben ha denne tilgangen, men andre brukere kan også ha denne tilgangen dersom klubben ønsker det. For å endre hvem som har "Admin"-tilgang, må du ta kontakt med oss.',
            'Dersom du har "Admin"-tilgang kan du legge til nye brukere ved å gå til "Innstillinger" og "Klubb", og trykke på pluss-ikonet under "Kontoer".',
            'Når du oppretter en bruker vil du kunne velge hvilken tilgang brukeren skal ha, og du vil kunne lese mer om hva de ulike tilgangene innebærer. Dersom klubben ønsker andre tilganger, kan dere ta kontakt med oss.'
          ]
        }
      },
    ]
  },

  'models': {
    'title': {
      'en': 'Models',
      'no': 'Modeller'
    },
    'subsections': [
      {
        'title': {
          'en': 'On-ball-value (OBV)',
          'no': 'On-ball-value (OBV)'
        },
        'text': {
          'en': [
            'What is OBV _ Our OBV model is a Possession-Value model that uses machine learning techniques (AI) to assign values based on how each action affects the teams\' chances of scoring and conceding.',
            'Common metrics like Expected Goals and Expected Assists are centered around finishes, which make up less than 2% of actions in a match. To evaluate individual skills of players, we have developed a model that considers ~2500 actions in each match, from passes to dribbles to tackles.',
            'Imagine a game of chess. If a chess player makes a brilliant move, he has put himself in a better position to win the game, even though he did not necessarily win the game with that move. We use the same logic to evaluate actions on the football field. Consider the following situation: A center-back makes a perfect thourgh pass to a forward on the run, who dribbles past the last defender and then finishes the shot out wide alone with the goalkeeper. The pass and the dribble naturally increased the probability of scoring, and the model assigns positive values to these actions regardless of whether the shot went in. The shot, however, will likely receive a negative value.',
            'Data quality _ A model is never better than the quality of the data, and our model is based on event data from German IMPECT. Unlike Wyscout, for example, IMPECT tags "Bypassed opponents" in data collection, representing the differance in number of opponents between the ball and the opposition goal before and after every action. This gives the model valuable context to actions that are crucial for accuracy.',
            'Literature _ Our model has drawn inspiration from the general VAEP framework, which was published as part of a PhD thesis in 2020./nA standalone publication of VAEP from the preceeding year can be read here: <link>https://www.janvanhaaren.be/assets/papers/kdd-2019-vaep.pdf/nA more high-level explanation with animations can be read her: <link>https://dtai.cs.kuleuven.be/sports/vaep'
          ],
          'no': [
            'Hva er OBV? _ OBV-modellen vår er en Possession-Value modell som ved hjelp av maskinlæringsteknikker (KI) har lært seg å tilegne verdier basert på i hvilken grad hver handling påvirker lagenes muligheter til å score og slippe inn mål.',
            'Utbredte metrikker som Expected Goals og Expected Assists er sentrert rundt avslutninger, som utgjør mindre enn 2% av handlingene i en kamp. For å evaluere individuelle ferdigheter hos spillere har vi utviklet en modell som ser på ~2500 handlinger i hver kamp, fra pasninger til driblinger til taklinger.',
            'Se for deg et sjakkparti. Om en sjakkspiller gjennomfører et genialt trekk har han satt seg selv i en bedre posisjon til å vinne partiet, uten at han nødvendigvis vant partiet med dette trekket. Vi bruker samme logikk for å evaluere handlinger på fotballbanen, ved å vurdere om handlingene setter laget i bedre posisjoner til å score mål i nær fremtid, uavhenging av om det faktisk blir scoret mål eller ikke. Vi kan se for oss følgende situasjon: En midtstopper slår en perfekt gjennombruddspasning til en spiss på løp, som så dribler seg forbi siste forsvarer før spissen setter ballen skyhøyt over mål alene med keeper. Modellen vil da tilegne en høy positiv verdi til midtstopperen for pasningen, og en høy positiv verdi til driblingen, selv om sjansen ikke resulterte i scoring. Avslutningen, derimot, vil trolig bli tilegnet en negativ verdi.',
            'Hva bruker vi OBV til? _ OBV gir oss en ny måte å evaluere individuelle ferdigheter på. I Fokus bruker vi den for eksempel aktivt til å si noe om hvilke midstoppere som skaper mest verdi i pasningsspillet, eller hvilke backer som er best til å dra av mann.',
            'Datakvalitet _ En modell blir aldri bedre enn kvaliteten på dataen, og vår modell er basert på event-data fra tyske IMPECT. I motsetning til for eksempel Wyscout, tagger IMPECT “Bypassed opponents” i datainnsamlingen, som representerer differansen mellom antall motspillere mellom ballen og motstanderens mål før og etter alle handlinger. Dette gir modellen verdifull kontekst til handlingene som er avgjørende for treffsikkerheten.',
            'Litteratur _ Modellen vår har tatt inspirasjon fra det generelle rammverket VAEP, som ble ble publisert som en del av en doktorgrad i 2020./nEn egen publikasjon om VAEP fra året før kan leses her: <link>https://www.janvanhaaren.be/assets/papers/kdd-2019-vaep.pdf/nEn mer overordnet forklaring med animasjoner kan leses her: <link>https://dtai.cs.kuleuven.be/sports/vaep'
          ]
        }
      },
      {
        'title': {
          'en': 'Normalisation and color scale',
          'no': 'Normalisering og fargeskala'
        },
        'text': {
          'en': [
            'All metrics on players are presented on a scale from 0 to 10 based on a percentile calculation compared to players in the same position. The greener the color, the better the player scores on this metric. The stats are also normalised per 90 minutes played, except for percentages.',
            'Percentiles _ The percentiles can be interpreteded as follows: A centre-back with 8.9 in Dribble OBV scores better than 89% of center-backs in our data, placing the player in the top 11%. If the center-back scores 5.0 in Expected Goals, this indicates that the player scores better than 50%, or in other words, the player is average.',
            'We always compare players to all players in the same position, even across leagues. The reason for this is to avoid small data sets having a big impact. Imagine a season where the top-scoring forward in the league scores 25 goals, while the number two on the list only scores 10 goals. We want a scale that captures this difference and does not assign the player with only 10 goals a 9.7 in the Goals column, even though he is around the top 3% best goal scorer among forwards in the league that season.',
            'Comparison across leagues _ Although a player in Eliteserien has 8.9 in Dribble OBV, this does not mean he is as good as a player in the Premier League with the same score. The same percentile, however, indicates that two players dominate their respective leagues to about the same extent.'
          ],
          'no': [
            'Alle metrikker på spillere presenteres på en skala fra 0 til 10 basert på en persentilberegning sammenlignet med spillere i samme posisjon. Jo grønnere fargeskala, jo bedre er spilleren på denne metrikken. Alle metrikker er også normalisert per 90 spilte minutter, med unntak av prosentverdier.',
            'Persentilberegning _ Persentilverdiene kan tolkes som følger: En midtstopper med 8.9 i Dribble OBV scorer bedre enn 89% av midtstoppere i databasen vår, tilsvarende topp 11%. Om spilleren scorer 5.0 i Expected Goals tilsier dette at spilleren er bedre enn 50%, eller med andre ord, spilleren er midt på treet.',
            'Vi sammenligner alltid spillerne med alle spillere i samme posisjon, også på tvers av ligaer, for å gjøre persentilene mer stabile og unngå at små datamengder får store utslag. Vi kan se for oss en sesong hvor den mestscorende spissen i ligaen scorer 25 mål, mens nummer to på listen scorer 10 mål. Vi ønsker oss en skala som fanger opp denne forskjellen, og ikke tilegner spilleren med 10 mål 9.7 i Goals kolonnen, selv om han da vil være rundt topp 3% på antall mål scoret blant spissene i ligaen denne sesongen.',
            'Sammenligning på tvers av ligaer _ Selvom en spiller i Eliteserien har 8.9 i Dribble OBV betyr ikke dette at han er like god som en spiller i Premier League med samme verdi. Samme persentil beskriver derimot at to spillere dominerer ligaene sine i omtrent like stor grad. Det er kun Skillindeks og Klubbindeks som inkluderer en ligavekting, som forklart lenger ned på siden.'
          ]
        }
      },
      {
        'title': {
          'en': 'Skill Rating and Club Rating',
          'no': 'Skillrating og Klubbrating'
        },
        'text': {
          'en': [
            'Every player in the database with event data is assigned a Skill Rating and a Club Rating for each season in a league.',
            'Skill Rating _ Skill rating is a weighted sum of the most important skills for each position. OBV values are the most significant, but other stats can also be included. In other words, Skill Rating is Fokus\' measure of how well a player performs overall in a given season.',
            'Club Rating - Club rating is based on the same logic as Skill Rating, but can be tailored to each club\'s specific role requirements. For example, there is a big difference in the statistical profile of a target forward compared to a more creative number 10. If the club wants to focus on a target forward, we can adjust the Club Rating to value skills such as dueling strength and goal scoring, rather than creativity. This way, the club has a better chance of identifying the right players before everyone else.'
          ],
          'no': [
            'Hver spiller i databasen med event-data får tildelt en Skillrating og en Klubbrating for hver sesong i en liga.',
            'Skillrating _ Skillrating er en vektet sum av de viktigste ferdighetene for hver posisjon. OBV-verdiene har mest betydning, men også andre stats kan inngå. Skillrating er med andre ord Fokus sitt mål på hvor godt en spiller presterer overordnet i en gitt sesong.',
            'Klubbrating _ Klubbrating står sentralt i Fokus og er vårt beste forsøk på å hjelpe deg å finne de rette spillerne. Formelen bygger på samme logikk som Skillrating, men kan tilpasses til din klubbs spesifikke rollekrav. For eksempel er det stor forskjell på det statistiske avtrykket til en target-spiss 9er, sammenlignet med en mer kreativ 10er. Om klubben ønsker å rette fokuset mot en target-spiss, vil vi kunne stille Klubbratingen til å verdsette ferdigheter som duellkraft og målteft, fremfor kreativitet. På denne måten får klubben større forutsetninger for å identifisere de rette spillerne, før alle andre.'
          ]
        }
      },
      {
        'title': {
          'en': 'Skill Index and Club Index',
          'no': 'Skillindeks and Klubbindeks'
        },
        'text': {
          'en': [
            'Skill Index and Club Index are aggregated indexes based on Skill Rating and Club Rating from the last seasons. We use a formula that weighs recent performances more, while also allowing previous top performances to influence the index positively. This way, we try to capture players who have previously shown X-factor but might currently be going under the radar.',
            'League level adjustment _ The indexes are also influenced by a league weighting to reward performances in tougher leagues. In our master\'s thesis, we demonstrated that the average market value of clubs in a league strongly correlates with the overall sporting level in the league, and this forms the basis of our weighting.',
          ],
          'no': [
            'Om det er ett tall som er viktigere enn alle andre i Fokus, er det Klubbindeksen. Dette er vårt beste forsøk på å hjelpe deg å finne de rette spillerne, før andre oppdager dem. Både Klubbindeks og Skillindeks er våre forsøk på å objektivt evaluere hvilke spillere som holder det høyeste nivået.',
            'Skillindeks og Klubbindeks er aggregerte indekser basert på henholdvis Skillrating og Klubbrating fra de siste sesongene. Vi bruker en formel som vektlegger nyere prestasjoner høyere, samtidig som toppnivået tilbake i tid kan påvirke indeksene oppover. På denne måten forsøker vi å fange opp spillere som har vist X-faktor tidligere, men som nå av forskjellige grunner presterer dårligere.',
            'Ligavekting _ Indeksen påvirkes av en ligavekting for å belønne prestasjoner i tøffere ligaer. Gjennomsnittlig markedsverdi på alle klubbene i en liga er vist å korrelere sterkt med ligaens sportslige nivå, og det er dette som danner basisen for liga-vektingen vår.',
          ]
        }
      },
      {
        'title': {
          'en': 'Precision',
          'no': 'Presisjon'
        },
        'text': {
          'en': [
            'Precision is a measure of the data amount we have for a player. Minutes played in recent times are weighted higher. Low precision means fewer minutes played, and therefore less precise indexes. However, we often see that players who have a high indexes but low precision can be huge talents, so these can still be valuable to follow.'
          ],
          'no': [
            'Presisjon er et mål på datagrunnlaget til en spiller. Minutter spilt i nyere tid vektlegges høyere. Lav presisjon betyr færre minutter spilt, og dermed mindre presise indekser. Vi ser likevel ofte at spillere som har høy indeks, men lav presisjon kan være supertalenter, så disse kan uansett være verdifulle å følge med på.'
          ]
        }
      },
    ]
  },
  'stats': {
    'title': {
      'en': 'Stats',
      'no': 'Stats'
    },
    'subsections': [
      {
        'title': {
          'en': 'OBV',
          'no': 'OBV'
        },
        'text': {
          'en': [
            'Bypass OBV _ On-ball value for passes past opponents./nPlayers with high Bypass OBV are usually strong passers who often play through the lines./nRole model: Toni Kroos.',
            'Dribble OBV _ On-ball value for dribbles past opponents/nPlayers with high Dribble OBV create value by successfully dribbling their opponents./nRole model: Lionel Messi.',
            'Carry OBV _ On-ball value for positive ball carries./nPlayers with high Carry OBV are characterized by their ability to drive the ball into dangerous areas./nRole model: Frenkie de Jong.',
            'Defending OBV _ On-ball value for defensive actions./nPlayers with high Defending OBV create value through successful defensive actions such as tackles and blocks./nRole model: Declan Rice.'
          ],
          'no': [
            'Bypass OBV _ On-ball-value for pasninger forbi motstandere./nSpillere med høy Bypass OBV er som regel pasningssterke spillere som ofte slår verdiskapende pasninger gjennom ledd./nRollemodell: Toni Kroos.',
            'Dribble OBV _ On-ball-value for driblinger forbi motstandere./nSpillere med høy Dribble OBV skaper verdi gjennom å dra av motstandere. Rollemodell:/nLionel Messi.',
            'Carry OBV _ On-ball-value for positive ballføringer./nSpillere med høy Carry OBV kjennetegnes av fremoverrettede spillere med evnen til å drive fremover med ballen inn i farlige rom. Rollemodell:/nFrenkie de Jong.',
            'Defending OBV _ On-ball-value for defensive handlinger./nSpillere med høy Defending OBV skaper verdi gjennom suksessfulle defensive handlinger som taklinger og blokkeringer./nRollemodell: Declan Rice.'
          ]
        }
      },
      {
        'title': {
          'en': 'Attacking',
          'no': 'Attacking'
        },
        'text': {
          'en': [
            'Goals _ Number of goals.',
            'NP Goals _ Number of goals excluding penalties.',
            'NP xG _ Expected goals excluding penalties./nExpected Goals (xG) is a statistical measure from 0 to 1 per shot of the likelihood that the shot will result in a goal, indicating the quality of the chance.',
            'Shots _ Number of shots.',
            'SGA _ Shooting Goals Added (SGA) is the difference between xGOT (Expected goals on target) and xG./nThis metric indicates the ability to hit the target and the placement of the shots. Unlike an xG model, which is a pre-shot model, the xGOT model is a post-shot model that also considers the quality of the shot, including the direction and force. A shot off-target always gets an xGOT value of 0.',
            'Assists _ Number of assists.',
            'xA _ Expected assists, based on xG of the next action.',
            'OP xA _ Expected assists from open play.'
          ],
          'no': [
            'Goals _ Antall mål.',
            'NP Goals _ Antall mål uten straffer.',
            'NP xG _ Forventede mål uten straffer./nExpected Goals (xG) er et statistisk mål fra 0 til 1 per avslutning som sier noe om sannsynligheten for at avslutningen blir mål, og beskriver dermed kvaliteten på sjansene en spiller eller et lag kommer til.',
            'Shots _ Antall skudd.',
            'SGA _ Differansen mellom xGOT (xG on target) og xG./nSGA (Shooting Goals Added) sier noe om til hvilken grad en spiller plasserer skudd bedre enn forventet. I motsetning til en xG-modell, som er en pre-shot modell, er xGOT-modellen en post-shot modell som også tar hensyn til kvaliteten av skuddet, inkludert retning og kraft. Et skudd utenfor mål får alltid en xGOT-verdi på 0, og følgelig en negativ SGA-verdi.',
            'Assists _ Antall målgivende.',
            'xA _ Forventede målgivende, basert på xG i neste handling.',
            'OP xA _ Forventede målgivende fra åpent spill (Open Play).'
          ]
        }
      },
      {
        'title': {
          'en': 'Possession',
          'no': 'Possession'
        },
        'text': {
          'en': [
            'Passes _ Number of passes.',
            'Pass % _ Pass completion rate.',
            'Long passes _ Number of passes over 34 meters.',
            'Long pass% _ Long pass completion rate.',
            'Fouls won _ Fouls won./nFor most leagues, this metric is only available from 2023 and onwards.',
            'Dispossessions _ Number of times possession is lost, either by being tackled or losing control of the ball. High percentiles equal few dispossessions.',
            'Retentions OH _ Number of successful actions under pressure in own half./nA successful action is defined as one where the player\'s team retains possession.',
            'Retentions OH% _ Success rate for actions under pressure in own half.'
          ],
          'no': [
            'Passes _ Antall pasninger.',
            'Pass % _ Treffprosent for pasninger.',
            'Long passes _ Antall pasninger over 34 meter.',
            'Long pass% _ Treffprosent for langpasninger.',
            'Fouls won _ Frispark vunnet./nFor de fleste ligaer er denne metrikken kun tilgjengelig fra og med 2023.',
            'Dispossessions _ Antall balltap, enten ved å bli taklet eller miste kontroll på ballen. Høye persentiler betyr at spilleren sjeldent mister ballen.',
            'Retentions OH _ Antall vellykkede handlinger under press på egen halvdel./nEn vellykket handling er definert som en handling der spillerens lag beholder ballen.',
            'Retentions OH% _ Suksess-rate for handlinger under press på egen halvdel.'
          ]
        }
      },
      {
        'title': {
          'en': 'Defending',
          'no': 'Defending'
        },
        'text': {
          'en': [
            'Defensive actions _ Number of defensive actions adjusted for possession.',
            'Presses _ Number of press attempts adjusted for possession./nA press attempt is defined as an action where the player actively puts an opponent under pressure. For most leagues, this metric is only available from 2023 and onwards.',
            'Interceptions _ Number of pass interceptions adjusted for possession.',
            'Clearances _ Number of clearances under pressure adjusted for possession.',
            'Blocks _ Number of blocks adjusted for possession.',
            'Tackles _ Number of successful tackles adjusted for possession.',
            'Tackles OB _ Number of successful tackles in own box adjusted for possession.',
            'Fouls _ Number of fouls committed. High percentiles equal few fouls committed.'
          ],
          'no': [
            'Defensive actions _ Antall defensive handlinger justert for ballinnehav.',
            'Presses _ Antall pressforsøk justert for ballinnehav./nEt pressforsøk er definert som en handling der spilleren aktivt setter en motstander under press. For de fleste ligaer er denne metrikken kun tilgjengelig fra og med 2023.',
            'Interceptions _ Antall pasningsbrudd justert for ballinnehav.',
            'Clearances _ Antall klareringer under press justert for ballinnehav.',
            'Blocks _ Antall blokkeringer justert for ballinnehav.',
            'Tackles _ Antall vellykkede taklinger justert for ballinnehav.',
            'Tackles OB _ Antall vellykkede taklinger i egen boks justert for ballinnehav.',
            'Fouls _ Antall frispark laget. Høye persentiler indikerer få frispark laget.'
          ]
        }
      },
      {
        'title': {
          'en': 'Aerial',
          'no': 'Aerial'
        },
        'text': {
          'en': [
            'Aerial wins _ Number of aerial duels won.',
            'Aerial win % _ Success rate for aerial duels.',
            'Aerial wins OB _ Number of aerial duels won in own box.',
            'Aerial win % OB _ Success rate for aerial duels in own box.'
          ],
          'no': [
            'Aerial wins _ Antall hodedueller vunnet.',
            'Aerial win % _ Suksess-rate for hodedueller.',
            'Aerial wins OB _ Antall hodedueller vunnet i egen boks.',
            'Aerial win % OB _ Suksess-rate for hodedueller i egen boks.'
          ]
        }
      },
      {
        'title': {
          'en': 'Goalkeeper',
          'no': 'Goalkeeper'
        },
        'text': {
          'en': [
            'Goals prevented _ Number of expected goals prevented by the keeper per shot./nTo calculate goals prevented, we compute how many goals the keeper was expected to concede based on the quality of the shots (xGOT), and subtract the number of goals the keeper actually conceded. Unlike an xG model, which is a pre-shot model, the xGOT model is a post-shot model that also considers the quality of the shot.',
            'Saves _ Number of saves.',
            'Save % _ Save percentage.',
            'Goals conceded _ Goals conceded.',
            'Penalties saved % _ Save percentage for penalties.',
            'Aggressive actions _ Number of defensive actions outside the box.',
            'Aggressive positioning _ Average distance from own goal for defensive actions outside the box.'
          ],
          'no': [
            'Goals prevented _ Antall forventede mål forhindret av keeper per skudd./nFor å beregne goals prevented, regner vi ut hvor mange mål keeperen var forventet å slippe inn basert på kvaliteten på skuddene (xGOT), og trekker fra antall mål keeperen slapp inn. I motsetning til en xG-modell, som er en pre-shot modell, er xGOT-modellen en post-shot modell, som også tar hensyn til kvaliteten av skuddet.',
            'Saves _ Antall redninger.',
            'Save % _ Redningsprosent.',
            'Goals conceded _ Mål sluppet inn.',
            'Penalties saved % _ Redningsprosent for straffer.',
            'Aggressive actions _ Antall defensive handlinger utenfor boksen.',
            'Aggressive positioning _ Gjennomsnittlig avstand fra eget mål for defensive handlinger utenfor boksen.'
          ]
        }
      }
      ,

      {
        'title': {
          'en': 'SkillCorner',
          'no': 'SkillCorner'
        },
        'text': {
          'en': [
            'About SkillCorner _ Physical metrics from SkillCorner are collected using camera technology applied to TV images. A more detailed description can be found on their own website: <link>https://skillcorner.crunch.help/en/glossaries/physical-data-glossary/',
            'Pace _ This metric reflects the peak speed of a player and its ability to reach it multiple times or sustain it long enough. /nIt is the PSV-99 (peak sprint velocity 99th percentile) metric from SkillCorner. Here we use the top 5 PSV-99, which means that for a given player in a given season we take the average of the top 5 performances. /nYou can read more about the calculation of PSV-99 here: <link>https://skillcorner.crunch.help/en/glossaries/peak-sprint-velocity/',
            'Distance _ Total distance.',
            'Sprint distance _ Total sprint distance above 25 km/h.',
            'Sprints _ Number of sprints above 25 km/h.',
            'Distance TIP _ Total distance in possession.',
            'Distance OTIP _ Total distance out of possession.',
            'Sprint distance TIP _ Total sprinted distance in possession.',
            'Sprint distance OTIP _ Total sprinted distance out of possession.',
            'Sprints TIP _ Number of sprints above 25 km/h in possession.',
            'Sprints OTIP _ Number of sprints above 25 km/h out of possession.',
            'HSR distance _ High speed running distance between 20 and 25 km/h.',
            'HSR distance TIP _ High speed running distance between 20 and 25 km/h in possession.',
            'HSR distance OTIP _ High speed running distance between 20 and 25 km/h out of possession.'
          ],
          'no': [
            'Om SkillCorner _ Fysiske metrics fra SkillCorner er samlet inn ved hjelpe av kamerateknologi anvendt på tv-bilder. En mer detaljert beskrivelse finnes på deres egen hjemmeside: <link>https://skillcorner.crunch.help/en/glossaries/physical-data-glossary/',
            'Pace _  Denne metrikken indikerer toppfarten til en spiller, og evnen til å nå den flere ganger eller holde den lenge nok. /nDen kommer fra en metrikk SkillCorner har kalt PSV-99 (peak sprint velocity 99th percentile). Her bruker vi topp 5 PSV-99, som betyr at for en gitt spiller i en gitt sesong tar vi gjennomsnittet av spillerens topp 5 beste målinger av PSV-99. /nDu kan lese mer om beregningen av toppfart her: <link>https://skillcorner.crunch.help/en/glossaries/peak-sprint-velocity',
            'Distance _ Antall kilometre løpt.',
            'Sprint distance _ Antall kilometre sprintet over 25 km/t.',
            'Sprints _ Antall spurter over 25 km/t.',
            'Distance TIP _ Antall kilometre løpt når eget lag har ball.',
            'Distance OTIP _ Antall kilometre løpt når motstander har ball.',
            'Sprint distance TIP _ Antall kilometre sprintet når eget lag har ball.',
            'Sprint distance OTIP _ Antall kilometre sprintet når motstander har ball.',
            'Sprints TIP _ Antall spurter over 25 km/t når eget lag har ball.',
            'Sprints OTIP _ Antall spurter over 25 km/t når mostander har ball.',
            'HSR distance _ Distanse løpt i høy fart mellom 20 og 25 km/t.',
            'HSR distance TIP _ Distanse løpt i høy fart mellom 20 og 25 km/t når eget lag har ball.',
            'HSR distance OTIP _ Distanse løpt i høy fart mellom 20 og 25 km/t når mostander har ball.'
          ]
        }
      }
    ],
  },
  'contact': {
    'title': {
      'en': 'Contact us',
      'no': 'Kontakt oss'
    },
    'subsections': [
      {
        'title': {
          'en': 'Questions, suggestions, or feedback?',
          'no': 'Spørsmål, forslag eller tilbakemeldinger?'
        },
        'text': {
          'en': [
            'You can reach us at lars.gundersen@fokus.ing, markus.kim@fokus.ing or eliot.strobel@fokus.ing'
          ],
          'no': [
            'Vi er takknemlige for tilbakemeldinger, og du kan når som helst kontakte oss på lars.gundersen@fokus.ing, markus.kim@fokus.ing eller eliot.strobel@fokus.ing'
          ]
        }
      }
    ]
  }
};
