import { FlexibleJsonMapping } from '../types';
import { positionOptionsPlatform } from '../static/propertyValues';


export const getPlayerNumberIdsOnTeams = (teams: FlexibleJsonMapping, selectedLogisticTeams: string[]): number[] => {

  const playerIds = new Set<number>();

  Object.keys(teams).forEach(teamKey => {
    const teamData = teams[teamKey];
    if (selectedLogisticTeams.includes(teamData['name'])) {
      positionOptionsPlatform.forEach(positionKey => {
        if (positionKey in teamData) {
          const positionArray = teamData[positionKey];
          positionArray.forEach((player: FlexibleJsonMapping) => {
            const numberId = Number(player['id']);
            if (!isNaN(numberId)) {
              playerIds.add(numberId);
            }
          });
        }
      });
    }
  });

  return Array.from(playerIds);
};
