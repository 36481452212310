
import StarsIcon from '@mui/icons-material/Stars';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import HealingIcon from '@mui/icons-material/Healing';
import AddIcon from '@mui/icons-material/Add';
import RocketIcon from '@mui/icons-material/Rocket';
import EventSeatIcon from '@mui/icons-material/EventSeat';

import HomeIcon from '@mui/icons-material/Home';
import FlagIcon from '@mui/icons-material/Flag';
import LanguageIcon from '@mui/icons-material/Language';

import StarIcon from '@mui/icons-material/Star';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import SchoolIcon from '@mui/icons-material/School';
import GroupIcon from '@mui/icons-material/Group';
import PublicIcon from '@mui/icons-material/Public';

import SpaIcon from '@mui/icons-material/Spa';
import HdrAutoIcon from '@mui/icons-material/HdrAuto';

import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import WorkIcon from '@mui/icons-material/Work';
import PaidIcon from '@mui/icons-material/Paid';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import BlockIcon from '@mui/icons-material/Block';

import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';


export const getRoleIcon = (role: string, iconColor: string, fontSize?: number, returnAddIfNoRole?: boolean, clubLightColor?: string) => {

  fontSize = fontSize ?? 18;

  switch (role) {
    case 'keyPlayer':
      if (clubLightColor) {
        return (
          <div>
            <StarsIcon style={{ fontSize: fontSize, color: iconColor, zIndex: 1, marginTop: -3 }} />
            <div style={{
              height: fontSize - 6,
              width: fontSize - 6,
              marginTop: -fontSize,
              marginLeft: 3,
              borderRadius: 100,
              backgroundColor: clubLightColor,
            }}>
              &nbsp;
            </div>
          </div>
        );
      }
      return (
        <div>
          <StarsIcon style={{ fontSize: fontSize, color: iconColor, zIndex: 1, marginTop: 3.5 }} />
        </div>
      );
    case 'rotationPlayer':
      return (
        <div>
          <GroupIcon style={{ fontSize: fontSize, color: iconColor, marginTop: 2 }} />
        </div>
      );
    case 'loanedIn':
      return (
        <div>
          <CreditCardIcon style={{ fontSize: fontSize, color: iconColor, marginTop: 3 }} />
        </div>
      );
    case 'developmentPlayer':
      return (
        <div>
          <RocketIcon style={{ fontSize: fontSize, color: iconColor, marginTop: 3 }} />
        </div>
      );
    case 'reserve':
      return (
        <div>
          <EventSeatIcon style={{ fontSize: fontSize, color: iconColor, marginTop: 3 }} />
        </div>
      );
    case 'longTermInjured':
      return (
        <div>
          <HealingIcon style={{ fontSize: fontSize, color: iconColor, marginTop: 3 }} />
        </div>
      );
    case 'loanedOut':
      return (
        <div>
          <PersonOffIcon style={{ fontSize: fontSize, color: iconColor, marginTop: 1 }} />
        </div>
      );

    // academy roles
    case 'trialist':
      return (
        <div>
          <HdrAutoIcon style={{ fontSize: fontSize, color: iconColor, marginTop: 3 }} />
        </div>
      );
    case 'talent':
      return (
        <div>
          <StarIcon style={{ fontSize: fontSize, color: iconColor, marginTop: 3 }} />
        </div>
      );
    case 'potential':
      return (
        <div>
          <SpaIcon style={{ fontSize: fontSize, color: iconColor, marginTop: 3 }} />
        </div>
      );
  }

  if (returnAddIfNoRole) {
    return (
      <div>
        <AddIcon style={{ fontSize: fontSize, color: iconColor, marginTop: 3 }} />
      </div>
    );
  }
};


export const getLocalityIcon = (locality: string, iconColor: string, fontSize?: number) => {

  fontSize = fontSize ?? 18;

  switch (locality) {
    case 'local':
      return (
        <div>
          <HomeIcon style={{ fontSize: fontSize, color: iconColor, marginTop: 2 }} />
        </div>
      );
    case 'national':
      return (
        <div>
          <FlagIcon style={{ fontSize: fontSize, color: iconColor, marginTop: 2 }} />
        </div>
      );
    case 'international':
      return (
        <div>
          <LanguageIcon style={{ fontSize: fontSize, color: iconColor, marginTop: 2 }} />
        </div>
      );
  }
};


export const getAccessIcon = (access: string, fontSize?: number) => {

  fontSize = fontSize ?? 18;

  switch (access) {
    case 'admin':
      return (
        <div>
          <AdminPanelSettingsIcon style={{ fontSize: fontSize }} />
        </div>
      );
    case 'full':
      return (
        <div>
          <WorkIcon style={{ fontSize: fontSize }} />
        </div>
      );
    case 'economy':
      return (
        <div>
          <PaidIcon style={{ fontSize: fontSize }} />
        </div>
      );
    case 'scout':
      return (
        <div>
          <TravelExploreIcon style={{ fontSize: fontSize }} />
        </div>
      );
    case 'none':
      return (
        <div>
          <BlockIcon style={{ fontSize: fontSize }} />
        </div>
      );
  }
};


export const getTeamIcon = (currentTeam: string, color?: string, fontSize?: number) => {

  fontSize = fontSize ?? 20;

  color = color ?? '#000000ee';

  if (currentTeam === 'ownTeam') {
    return <GroupIcon style={{ fontSize: fontSize, color: color }} />;
  }

  else if (currentTeam === 'shadowTeam') {
    return <SportsSoccerIcon style={{ fontSize: fontSize, color: color }} />;
  }

  else if (currentTeam === 'masterTeam') {
    return <StarIcon style={{ fontSize: fontSize, color: color }} />;
  }

  else if (currentTeam === 'academyTeam') {
    return <SchoolIcon style={{ fontSize: fontSize, color: color }} />;
  }

  else if (currentTeam) {
    return <PublicIcon style={{ fontSize: fontSize, color: color }} />;
  }
};


export const getConditionStatusIcon = (status: string, color?: string, fontSize?: number) => {

  fontSize = fontSize ?? 20;

  color = color ?? '#000000ee';

  if (status === 'achieved') {
    return <TaskAltIcon style={{ fontSize: fontSize, color: color }} />;
  }

  else if (status === 'notAchieved') {
    return <CancelOutlinedIcon style={{ fontSize: fontSize, color: color }} />;
  }
};
