import axios from 'axios';

import { FirebaseUser } from '../../../firebase';
import { FlexibleJsonMapping } from '../../types';

import { BASE_URL, CONTRACT_KEYS_TO_MONTHS } from './constants';
import { getBearerToken } from './token';

import { RatingRequirement } from '../../components/input/RatingDropDown';
import { getNormalizedMetricName } from '../../static/playerMetrics';


export const searchPlayerClubIterations = async (
  user: FirebaseUser,
  options: {
    minAge?: number;
    maxAge?: number;
    maxContractLength?: string;
    includeUnknownContracts?: boolean;
    primaryPositions?: string[];
    includeSecondaryPosition?: boolean;
    countryCodes?: string[];
    playerIds?: number[];
    iterations?: number[];
    minMinutesPlayed?: number;
    maxMinutesPlayed?: number;
    minMinutesPlayedPercentage?: number;
    maxMinutesPlayedPercentage?: number;
    ratingRequirements?: RatingRequirement[];
    sortBy?: string;
    sortByActualValues?: boolean;
    page?: number;
  }
): Promise<FlexibleJsonMapping | undefined> => {

  const token = await getBearerToken(user);

  const {
    minAge,
    maxAge,
    maxContractLength,
    includeUnknownContracts,
    primaryPositions,
    includeSecondaryPosition,
    countryCodes,
    playerIds,
    iterations,
    minMinutesPlayed,
    maxMinutesPlayed,
    minMinutesPlayedPercentage,
    maxMinutesPlayedPercentage,
    ratingRequirements,
    sortBy,
    sortByActualValues,
    page = 1,
  } = options;

  const maxContractMonths = maxContractLength ? CONTRACT_KEYS_TO_MONTHS[maxContractLength] : undefined;
  const primaryPositionsString = primaryPositions ? primaryPositions.join(',') : undefined;
  const iterationsString = iterations ? iterations.join(',') : undefined;
  const countryCodesString = countryCodes ? countryCodes.join(',') : undefined;

  const ratingRequirementsString = ratingRequirements
    ? ratingRequirements.map(({ metric, value }) => `${getNormalizedMetricName(metric)}:${value}`).join(',')
    : undefined;

  const correctSortBy = sortBy
    ? (sortByActualValues ? sortBy.replace(/_normalized$/, '') : sortBy)
    : undefined;

  // if the array is undefined, we don't concern with any player ids, but if the array is empty, we attempt to search for an empty set of players
  if (playerIds && playerIds.length === 0) {
    return {
      total_hits: 0,
      current_page: 1,
      players: [],
    };
  }

  const playerIdsString = playerIds ? playerIds.join(',') : undefined;

  const result = await axios.get(`${BASE_URL}/search/playerClubIterations/`, {
    headers: { Authorization: token },
    params: {
      min_age: minAge,
      max_age: maxAge,
      max_contract_length: maxContractMonths,
      include_unknown_contracts: includeUnknownContracts,
      primary_positions: primaryPositionsString,
      include_secondary_position: includeSecondaryPosition,
      iterations: iterationsString,
      min_minutes_played: minMinutesPlayed,
      max_minutes_played: maxMinutesPlayed,
      min_minutes_played_percentage: minMinutesPlayedPercentage,
      max_minutes_played_percentage: maxMinutesPlayedPercentage,
      country_codes: countryCodesString,
      player_ids: playerIdsString,
      rating_requirements: ratingRequirementsString,
      sort_by: correctSortBy,
      page: page,
    },
  });

  if (!result) return undefined;

  return result.data;
};
