import { db } from '../../../firebase';

import {
  doc,
  onSnapshot,
} from 'firebase/firestore';

import { ClubScopes, FlexibleJsonMapping } from '../../types';


// Get club scopes
export const getClubScopes = (setClubcScopes: (clubcScopes: ClubScopes) => void, club: string) => {

  const docRef = doc(db, 'scopes', club);

  const unsubscribe = onSnapshot(docRef, (docSnap) => {
    if (docSnap.exists()) {
      const docData = docSnap.data();

      const competitions = docData.competitions ?? [];
      const competitionIds = competitions.map((competition: FlexibleJsonMapping) => String(competition.id));

      const skillcornerIterations = docData.skillcornerIterations ?? [];
      const skillcornerIterationIds = skillcornerIterations.map((iteration: FlexibleJsonMapping) => String(iteration.id));

      const scopes: ClubScopes = {
        competitionIds: competitionIds,
        hasFullCoverage: docData.hasFullCoverage,
        hasSkillcorner: docData.hasSkillcorner,
        skillcornerIterationIds: skillcornerIterationIds,
      };
      setClubcScopes(scopes);
    }
  });

  return unsubscribe;
};
