import { db } from '../../../firebase';
import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  doc,
  updateDoc
} from 'firebase/firestore';
import type { DocumentData } from 'firebase/firestore';

import { FlexibleJsonMapping, PlayerActivity, PlayerId, PlayerReport } from '../../types';


// Add a player activity
export const addActivity = async (activity: PlayerActivity, club: string): Promise<void> => {
  const activitiesCollectionRef = collection(db, 'configs', club, 'activities');

  await addDoc(activitiesCollectionRef, activity);
};


// Get all player activities for a team and merge together with team history and return the complete team history
export const getCompleteTeamHistory = async (teamOrSquadId: string, teamHistory: FlexibleJsonMapping[], club: string): Promise<FlexibleJsonMapping[]> => {

  const activitiesCollectionRef = collection(db, 'configs', club, 'activities');

  const toTeamIdQuery = query(activitiesCollectionRef, where('toTeamId', '==', teamOrSquadId));
  const fromTeamIdQuery = query(activitiesCollectionRef, where('fromTeamId', '==', teamOrSquadId));

  const [fromTeamDocs, toTeamDocs] = await Promise.all([
    getDocs(toTeamIdQuery),
    getDocs(fromTeamIdQuery),
  ]);

  // Extracting the data from the Firestore documents
  const extractData = (docs: DocumentData[]) => docs.map(doc => doc.data() as FlexibleJsonMapping);
  const toTeamData = extractData(toTeamDocs.docs);
  const fromTeamData = extractData(fromTeamDocs.docs);

  // Merging and sorting the complete team history
  const completeTeamHistory = [...teamHistory, ...toTeamData, ...fromTeamData];

  completeTeamHistory.sort((a, b) => a.date.localeCompare(b.date));

  return completeTeamHistory;
};


// Get all player activities and merge together with reports and return the complete player history
export const getCompletePlayerHistory = async (playerId: PlayerId, reports: PlayerReport[], club: string): Promise<FlexibleJsonMapping[]> => {

  const activitiesCollectionRef = collection(db, 'configs', club, 'activities');

  const q = query(activitiesCollectionRef, where('playerId', '==', playerId));
  const querySnapshot = await getDocs(q);

  // Extracting the data from the Firestore documents
  const extractData = (docs: DocumentData[]) => docs.map(doc => doc.data() as FlexibleJsonMapping);
  const playerActivities = extractData(querySnapshot.docs);

  // Merging and sorting the complete team history
  const completePlayerHistory = [...reports, ...playerActivities];

  completePlayerHistory.sort((a, b) => a.date.localeCompare(b.date));

  return completePlayerHistory;
};


// Get all platform activity (playerActivity, teamHistory, reports) in the last 14 days
export const getPlatformActivityLast14Days = async (teams: FlexibleJsonMapping, squads: FlexibleJsonMapping, club: string) => {

  // Calculate dates
  const today = new Date();
  const fourteenDaysAgo = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 14).toISOString().substring(0, 10);

  async function getReports(): Promise<PlayerReport[]> {
    const reportsQuery = query(
      collection(db, 'configs', club, 'reports'),
      where('date', '>=', fourteenDaysAgo)
    );
    const querySnapshot = await getDocs(reportsQuery);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) as PlayerReport[];
  }

  async function getActivities(): Promise<PlayerActivity[]> {
    const activitiesQuery = query(
      collection(db, 'configs', club, 'activities'),
      where('date', '>=', fourteenDaysAgo)
    );
    const querySnapshot = await getDocs(activitiesQuery);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) as PlayerActivity[];
  }

  const filterAndFlattenHistory = (mapping: FlexibleJsonMapping) => {
    return Object.values(mapping).flatMap(config =>
      config.history
        .filter((item: FlexibleJsonMapping) => item.date >= fourteenDaysAgo && (!item.action || item.action.actionType !== 'editName'))
        .map((item: FlexibleJsonMapping) => ({
          ...item,
          teamOrSquadName: config.name,
        }))
    );
  };

  const [reports, activities] = await Promise.all([getReports(), getActivities()]);

  const teamsHistory = filterAndFlattenHistory(teams);
  const squadsHistory = filterAndFlattenHistory(squads);

  const allActivities = [...reports, ...activities, ...teamsHistory, ...squadsHistory];
  allActivities.sort((a, b) => a.date.localeCompare(b.date));

  return allActivities;
};


// Get all player activities and merge together with reports and return the complete player history
export const getPlayerActivites = async (playerId: PlayerId, club: string): Promise<FlexibleJsonMapping[]> => {

  const activitiesCollectionRef = collection(db, 'configs', club, 'activities');

  const q = query(activitiesCollectionRef, where('playerId', '==', playerId));
  const querySnapshot = await getDocs(q);

  // Extracting the data from the Firestore documents
  const extractData = (docs: DocumentData[]) => docs.map(doc => ({ id: doc.id, ...doc.data() as FlexibleJsonMapping }));
  const playerActivities = extractData(querySnapshot.docs);

  return playerActivities;
};


// Update player of report
export const updatePlayerOfActivity = async (activityId: string, newPlayerId: PlayerId, newPlayerFullname: string, club: string) => {

  const activityDocRef = doc(db, 'configs', club, 'activities', activityId);

  try {
    await updateDoc(activityDocRef, {
      playerId: newPlayerId,
      playerName: newPlayerFullname
    });
  }
  catch (error) {
    console.error(error); // eslint-disable-line no-console
  }
};
