import '../tables.css';

import { useRecoilValue } from 'recoil';
import { playerTeamDataState } from '../../../recoil/selectors/playerTeamDataState';
import { competitionsState } from '../../../recoil/atoms/competitionsState';

import { CellProps } from 'react-table';
import { FlexibleJsonMapping, PlayerId, UserConfig } from '../../../types';
import { getDateLocaleFormat, getShortPlayerName } from '../../../utils/playerUtils';
import { staticLanguageMap } from '../../../../common/static/staticLanguageMap';
import { countryCodeToCountryInfo } from '../../../static/countries';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { LazyImage } from '../../../../common/components/LazyImage';
import { goalkeeperSeasonTableMetricGroups, metricToDisplayName, outfieldSeasonTableMetricGroups, skillcornerStats } from '../../../static/playerMetrics';
import { getMetricColumnWidth } from '../seasonTable/seasonColumns';
import { getColorBlindRatingColor, getIndexColor } from '../../../utils/colorUtils';
import { getTeamIcon } from '../../../utils/iconUtils';
import { Toggle } from '../../input/Toggle';
import { updateSeasonStatsToggles } from '../../../services/firestore/settings';


const InfoCell: React.FC<CellProps<FlexibleJsonMapping, FlexibleJsonMapping>> = ({ row }) => {

  const playerTeamData = useRecoilValue(playerTeamDataState);
  const competitions = useRecoilValue(competitionsState);

  const competitionName = competitions[row.original.competition_id]?.name;

  let currentTeamIcon: JSX.Element | undefined = undefined;
  const playerId: PlayerId = row.original['player_id'];
  if (playerTeamData && playerId in playerTeamData) {
    const currentTeam = playerTeamData[playerId].currentTeam;
    currentTeamIcon = getTeamIcon(currentTeam, undefined, 18);
  }

  return (
    <div className='player-table-info-cell'>

      <LazyImage
        src={row.original.image_url}
        alt=''
        imageClassName='player-table-info-cell-image'
        imageContainerClassName='player-table-info-cell-image-container'
      />

      <div className='player-table-info-cell-column player-season-table-info-cell-column'>

        <div className='player-table-info-cell-row'>
          <div className='player-table-info-cell-fullname'>
            {row.original.fullname.length > 25 ? getShortPlayerName(row.original.fullname) : row.original.fullname}
          </div>

          {row.original.country_code in countryCodeToCountryInfo && (
            <img
              className='player-table-info-cell-flag'
              src={countryCodeToCountryInfo[row.original.country_code]?.flagUrl}
              alt=''
              draggable={false}
            />
          )}
        </div>

        {row.original.club?.name && competitionName && (
          <div>
            <div className='player-table-info-cell-info'>
              {row.original.club?.logo_url && (
                <div className='player-table-club-logo-container'>
                  <img
                    className='player-table-club-logo'
                    src={row.original.club?.logo_url}
                    alt=''
                    draggable={false}
                  />
                </div>
              )}
              <div className='player-table-info-cell-info-text'>
                {row.original.club?.name}
              </div>
            </div>

            <div className='player-table-info-cell-info'>
              <div className='player-table-info-cell-info-text'>
                {competitionName}
              </div>
            </div>
          </div>
        )}

      </div>

      {currentTeamIcon && (
        <div className='player-table-info-cell-icon-container player-season-table-info-cell-icon-container'>
          <div className='player-table-info-cell-icon'>
            {currentTeamIcon}
          </div>
        </div>
      )}

      <div className='player-season-table-info-cell-season'>
        {row.original.season}
      </div>
    </div>
  );
};


const TextCell: React.FC<CellProps<FlexibleJsonMapping, string>> = ({ value }) => {
  return <div className='player-season-table-cell'>{value ?? '-'}</div>;
};


const MinutesCell: React.FC<CellProps<FlexibleJsonMapping, number>> = ({ row }) => {
  const minutes_played = row.original.overall ? row.original.overall.minutes_played : undefined;
  const percentage_played = row.original.overall ? (row.original.overall.minutes_played_percentage) : undefined;

  return (
    <div className='player-season-table-cell'>
      {minutes_played !== undefined ? (Math.round(minutes_played)) : '-'}
      <div style={{ marginLeft: 8, fontSize: 12, color: '#000000cc' }}>
        {minutes_played && percentage_played !== undefined ? ` (${Math.round(percentage_played * 100)}%)` : ''}
      </div>
    </div>
  );
};


const renderContractCell = () => {
  const ContractCellRenderer = ({ row }: FlexibleJsonMapping) => {
    return <ContractCell row={row} />;
  };

  ContractCellRenderer.displayName = 'ContractCellRenderer';
  return ContractCellRenderer;
};

const ContractCell: React.FC<{ row: FlexibleJsonMapping }> = ({ row }) => {
  const value = row.original.contract_expiration;
  return <div className='player-season-table-cell'>{getDateLocaleFormat(value)}</div>;
};


const renderPositionCell = (language: string) => {
  const PositionCellRenderer = ({ row }: FlexibleJsonMapping) => {
    return <PositionCell row={row} language={language} />;
  };

  PositionCellRenderer.displayName = 'PositionCellRenderer';
  return PositionCellRenderer;
};

const PositionCell: React.FC<{ row: FlexibleJsonMapping; language: string; }> = ({ row, language }) => {
  let position = row.original.primary_position;

  if (!(position in staticLanguageMap)) return <div className='player-season-table-cell'>-</div>;

  position = staticLanguageMap[position][language];

  if (row.original.secondary_position in staticLanguageMap) {
    position += ` (${staticLanguageMap[row.original.secondary_position][language]})`;
  }

  return <div className='player-season-table-cell'>{position}</div>;
};


const renderSeasonRatingCell = (
  property: string,
  metricGroupKey: string,
  metricGroupIsToggled: boolean,
  isColorBlind: boolean
) => {
  const SeasonRatingCellRenderer = ({ row }: FlexibleJsonMapping) => {
    return (
      <SeasonRatingCell
        row={row}
        property={property}
        metricGroupKey={metricGroupKey}
        metricGroupIsToggled={metricGroupIsToggled}
        isColorBlind={isColorBlind}
      />
    );
  };

  SeasonRatingCellRenderer.displayName = 'SeasonRatingCellRenderer';
  return SeasonRatingCellRenderer;
};

const SeasonRatingCell: React.FC<{
  row: FlexibleJsonMapping,
  property: string,
  metricGroupKey: string,
  metricGroupIsToggled: boolean,
  isColorBlind: boolean,
}> = ({ row, property, metricGroupKey, metricGroupIsToggled, isColorBlind }) => {

  const normalizedValue = metricGroupKey === 'Ratings' ? row.original['overall'][property] : row.original['overall'][property + '_normalized'];
  let displayValue = normalizedValue !== undefined ? Math.round(normalizedValue * 10) / 10 : '-';

  if (metricGroupIsToggled) {
    let actualValue = row.original['overall'][property];

    const isPercentage = property.endsWith('_percentage');
    if (actualValue && isPercentage) {
      actualValue = actualValue * 100;
    }

    const roundingFactor = (metricGroupKey === 'Attacking' && property !== 'n_shots_per_90') || property === 'gk_postshot_xg_prevented_difference_per_shot'
      ? 100
      : 10;

    displayValue = actualValue !== undefined ? Math.round(actualValue * roundingFactor) / roundingFactor : '-';

    if (displayValue !== '-' && isPercentage) {
      displayValue = displayValue + '%';
    }

    if (displayValue !== '-' && ['skillcorner_distance_per_90', 'skillcorner_distance_tip_per_90', 'skillcorner_distance_otip_per_90'].includes(property)) {
      displayValue = Math.round(actualValue / 100) / 10 + ' km';
    }
    if (displayValue !== '-' && [
      'skillcorner_sprinting_distance_per_90',
      'skillcorner_sprinting_distance_tip_per_90',
      'skillcorner_sprinting_distance_otip_per_90',
      'skillcorner_hsr_distance_per_90',
      'skillcorner_hsr_distance_tip_per_90',
      'skillcorner_hsr_distance_otip_per_90',
    ].includes(property)) {
      displayValue = Math.round(actualValue) + ' m';
    }
    if (displayValue !== '-' && property === 'skillcorner_psv-99_average') {
      displayValue = displayValue + ' km/h';
    }
    if (displayValue !== '-' && property === 'gk_penalty_saves_percentage') {
      displayValue = row.original['overall']['n_gk_penalties_saved'] + '/' + row.original['overall']['n_gk_penalties_faced'] + ' (' + displayValue + ')';
    }
  }

  const backgroundColor = isColorBlind
    ? getColorBlindRatingColor(normalizedValue, 0.3)
    : getIndexColor(normalizedValue, 74);

  return (
    <div className='season-table-color-cell-container'>
      <div
        className='player-season-table-color-cell'
        style={{
          backgroundColor: backgroundColor,
          fontSize: isColorBlind ? 13 : 12,
          fontWeight: isColorBlind ? 700 : 500
        }}>
        {displayValue}
      </div>
    </div>
  );
};


export const getPlayerSeasonColumns = (
  userConfig: UserConfig | null,
  isGoalkeeperSelected: boolean,
  currentChapter: number,
  hitsPerChapter: number,
  totalHits: number,
  handleChangeCurrentChapter: (isIncrement: boolean) => void,
  selectedOrderBy: string | undefined,
  secondaryClubColor: string,
  seasonStatsToggles: FlexibleJsonMapping,
  hasSkillcorner: boolean,
) => {

  if (!userConfig) return [];

  const columns: FlexibleJsonMapping[] = [
    {
      Header:
        <div className='player-season-table-top-level-header-cell-sticky'>

          {totalHits + (totalHits >= 10000 ? '+ ' : ' ') + staticLanguageMap['players'][userConfig.language]}

          {totalHits > hitsPerChapter && (
            <div className='player-table-sticky-header-cell-pagination'>
              <div className='player-table-current-chapter-range'>
                {'(' + (hitsPerChapter * currentChapter) + '-' + Math.min(hitsPerChapter * (currentChapter + 1), totalHits) + ')'}
              </div>

              {currentChapter <= 0 && (
                <div className='player-table-pagination-arrow player-table-pagination-left-arrow player-season-table-pagination-left-arrow'>
                  <KeyboardArrowLeftIcon style={{ fontSize: 16 }} />
                </div>
              )}

              {currentChapter > 0 && (
                <div
                  className={
                    'player-table-pagination-arrow player-table-pagination-arrow-abled' +
                    'player-table-pagination-left-arrow player-season-table-pagination-left-arrow'
                  }
                >
                  <KeyboardArrowLeftIcon style={{ fontSize: 16 }} onClick={() => handleChangeCurrentChapter(false)} />
                </div>
              )}

              <div className='player-table-current-chapter player-season-table-current-chapter'>
                {currentChapter + 1}
              </div>

              {currentChapter >= Math.floor(totalHits / hitsPerChapter) && (
                <div className='player-table-pagination-arrow player-table-pagination-right-arrow player-season-table-pagination-right-arrow'>
                  <KeyboardArrowRightIcon style={{ fontSize: 16 }} />
                </div>
              )}

              {currentChapter < Math.floor(totalHits / hitsPerChapter) && (
                <div
                  className={
                    'player-table-pagination-arrow player-table-pagination-arrow-abled' +
                    'player-table-pagination-right-arrow player-season-table-pagination-right-arrow'
                  }
                >
                  <KeyboardArrowRightIcon style={{ fontSize: 16 }} onClick={() => handleChangeCurrentChapter(true)} />
                </div>
              )}
            </div>
          )}

        </div>,
      id: 'season',
      sticky: 'left',
      accessor: 'season',
      Cell: InfoCell,
      width: 320,
      minWidth: 320,
    }
  ];

  columns.push({
    Header:
      <div className='player-season-table-top-level-header-cell player-season-table-top-level-header-cell-with-border'>
        Info
      </div>,
    id: 'info',
    columns: [
      {
        Header:
          <div className='player-season-table-sub-level-header-cell'>
            {staticLanguageMap['age'][userConfig.language]}
          </div>,
        id: 'age',
        accessor: 'age',
        Cell: TextCell,
        width: 90,
      },
      {
        Header:
          <div className='player-season-table-sub-level-header-cell'>
            {staticLanguageMap['contract'][userConfig.language]}
          </div>,
        id: 'contract',
        accessor: 'contract_expiration',
        Cell: renderContractCell(),
        width: 120,
      },
      {
        Header:
          <div className='player-season-table-sub-level-header-cell'>
            {staticLanguageMap['position'][userConfig.language]}
          </div>,
        id: 'position',
        Cell: renderPositionCell(userConfig.language),
        width: 110,
      },
      {
        Header:
          <div className='player-season-table-sub-level-header-cell player-season-table-sub-level-header-cell-with-border'>
            {staticLanguageMap['minutes'][userConfig.language]}
          </div>,
        id: 'minutes',
        accessor: 'minutes_played',
        Cell: MinutesCell,
        width: 110,
        isFinalSubMetric: true,
      },
    ],
  });

  const metricGroups = isGoalkeeperSelected
    ? goalkeeperSeasonTableMetricGroups
    : hasSkillcorner
      ? { ...outfieldSeasonTableMetricGroups, ...{ 'Skillcorner': skillcornerStats } }
      : outfieldSeasonTableMetricGroups;

  Object.keys(metricGroups).forEach((metricGroupKey, index) => {
    const isFinalMetricGroup = index === Object.keys(metricGroups).length - 1;

    const topLevelCell = {
      Header:
        <div className={'player-season-table-top-level-header-cell' + (!isFinalMetricGroup ? ' player-season-table-top-level-header-cell-with-border' : '')}>
          {metricGroupKey}

          {metricGroupKey !== 'Ratings' && metricGroupKey !== 'OBV' && (
            <div className='season-table-top-level-header-cell-toggle' title={staticLanguageMap['showActualValues'][userConfig.language]}>
              <Toggle
                isToggled={seasonStatsToggles[metricGroupKey]}
                setIsToggled={(value: boolean) => updateSeasonStatsToggles(
                  { ...seasonStatsToggles, [metricGroupKey]: value },
                  userConfig.email,
                  userConfig.club)}
                isSmall={true}
              />
            </div>
          )}
        </div>,
      id: metricGroupKey,

      columns: metricGroups[metricGroupKey].map((metric, index) => {
        const isFinalSubMetric = !isFinalMetricGroup && index === metricGroups[metricGroupKey].length - 1;

        const metricDisplayName = metricToDisplayName[metric][userConfig.language];
        const metricTitle = metricToDisplayName[metric]['titles'][userConfig.language];

        const isOrderByMetric = selectedOrderBy === metric;

        const cell = {
          Header:
            <div
              className={'player-season-table-sub-level-header-cell' + (isFinalSubMetric ? ' player-season-table-sub-level-header-cell-with-border' : '')}
              style={{ color: isOrderByMetric ? secondaryClubColor : undefined, fontWeight: isOrderByMetric ? 600 : undefined }}>
              {metricDisplayName}
            </div>,
          id: metric,
          Cell: renderSeasonRatingCell(metric, metricGroupKey, seasonStatsToggles[metricGroupKey], userConfig.isColorBlind),
          width: getMetricColumnWidth(metricDisplayName),
          title: metricTitle,
          isFinalSubMetric: isFinalSubMetric,
        };
        return cell;
      }),
    };

    columns.push(topLevelCell);
  });

  return columns;
};
