import './input.css';

import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { clubScopesState } from '../../recoil/atoms/clubScopesState';

import Fuse from 'fuse.js';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import KeyIcon from '@mui/icons-material/Key';
import CloseIcon from '@mui/icons-material/Close';
import BlockIcon from '@mui/icons-material/Block';

import { staticLanguageMap } from '../../../common/static/staticLanguageMap';
import { InputField } from './InputField';
import { countryCodeToCountryInfo } from '../../static/countries';
import { competitionsState } from '../../recoil/atoms/competitionsState';
import { Competition, Iteration } from '../../types';

import skill_corner_logo from '../../../assets/images/skillcorner_logo_dark.png';


interface IterationDropDownProps {
  id: string;
  selectedIterations: Iteration[];
  setSelectedIterations: (value: Iteration[]) => void;
  isDropDownExpanded: boolean;
  setIsDropDownExpanded: (value: boolean) => void;
  defaultDropDownText: string; // static language key
  defaultDropDownTextColor: string;
  marginBetweenOptions: number;
  emptyBackgroundId?: string;
  maxHeight?: string; // controls scrolling

  anySkillcornerFiltersSelected: boolean;
  setAnyIterationWithoutSkillcornerSelected: (value: boolean) => void;
}


export const IterationDropDown: React.FC<IterationDropDownProps> = ({
  id,
  selectedIterations,
  setSelectedIterations,
  isDropDownExpanded,
  setIsDropDownExpanded,
  defaultDropDownText,
  defaultDropDownTextColor,
  marginBetweenOptions,
  emptyBackgroundId,
  maxHeight,

  anySkillcornerFiltersSelected,
  setAnyIterationWithoutSkillcornerSelected,
}) => {

  const userConfig = useRecoilValue(userConfigState);
  const clubScopes = useRecoilValue(clubScopesState);
  const competitions = useRecoilValue(competitionsState);

  const [searchString, setSearchString] = useState('');

  const inputRef = useRef<HTMLInputElement>(null);

  const [fuse, setFuse] = useState<Fuse<Competition> | null>(null);
  const [sortedStaticCompetitions, setSortedStaticCompetitions] = useState<Competition[]>([]);
  const [competitionsToShow, setCompetitionsToShow] = useState<Competition[]>([]);


  const onChangeSearchField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value);
    if (event.target.value === '') {
      setCompetitionsToShow(sortedStaticCompetitions);
    }
  };


  const onKeyDownSearchField = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearchForCompetitions(searchString);
    }
  };


  const handleSearchForCompetitions = useCallback((currentSearchString: string) => {
    if (!fuse) return [];

    const searchResult = fuse.search(currentSearchString).map(result => result.item);
    setCompetitionsToShow(searchResult);
  }, [fuse]);


  const handleDropDownClick = () => {
    if (isDropDownExpanded) {
      removeDropDownExpansion();
    }

    else {
      expandDropDown();
    }
  };


  const handleOptionSelect = (iteration: Iteration, isOptionAlreadySelected: boolean) => {
    let newSelectedIterations = selectedIterations.slice();

    if (isOptionAlreadySelected) {
      newSelectedIterations = newSelectedIterations.filter(item => item.iterationId !== iteration.iterationId);
    }
    else {
      newSelectedIterations.push(iteration);
    }

    setSelectedIterations(newSelectedIterations);

    const anyIterationWithoutSkillcornerSelected = newSelectedIterations.some(selectedIteration => {
      const iterationHasSkillcorner = clubScopes !== null && clubScopes.skillcornerIterationIds.includes(selectedIteration.iterationId.toString());
      return !iterationHasSkillcorner;
    });
    setAnyIterationWithoutSkillcornerSelected(anyIterationWithoutSkillcornerSelected);
  };


  const expandDropDown = () => {
    // zIndex handling is not ideal, but must be handlded carefully due to interactions between multiple drop downs
    let element = document.getElementById(id);
    if (element) {
      element.style.transition = '150ms';
      element.style.height = 'auto';
      element.style.zIndex = '110';
    }

    if (emptyBackgroundId) {
      element = document.getElementById(emptyBackgroundId);
      if (element) {
        element.style.transition = '150ms';
        element.style.zIndex = '100';
        element.style.backgroundImage = 'linear-gradient(to bottom, #00000000, #00000012)';
      }
    }

    setIsDropDownExpanded(true);
  };


  const removeDropDownExpansion = () => {
    let element = document.getElementById(id);
    if (element) {
      element.style.transition = '75ms';
      element.style.height = '28px';
      element.style.zIndex = '1';
    }

    if (emptyBackgroundId) {
      element = document.getElementById(emptyBackgroundId);
      if (element) {
        element.style.transition = '75ms';
        element.style.zIndex = '-1';
        element.style.backgroundImage = 'linear-gradient(to bottom, #00000000, #00000000)';
      }
    }

    setIsDropDownExpanded(false);
  };


  const showSelectedCompetitions = () => {
    if (selectedIterations.length === 0 || !(Number(selectedIterations[0].competitionId) in competitions)) return '';

    let display = competitions[Number(selectedIterations[0].competitionId)].name + ' ' + selectedIterations[0].season;

    for (let i = 1; i < selectedIterations.length; i++) {
      display += ', ' + competitions[Number(selectedIterations[i].competitionId)].name + ' ' + selectedIterations[i].season;
    }

    return display;
  };


  useEffect(() => {
    if (!isDropDownExpanded) {
      removeDropDownExpansion();
      setSearchString('');
      setCompetitionsToShow(sortedStaticCompetitions);
    }
    else {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [isDropDownExpanded]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (searchString.length === 0) {
      setCompetitionsToShow(sortedStaticCompetitions);
    }
    else {
      handleSearchForCompetitions(searchString);
    }
  }, [handleSearchForCompetitions, searchString]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    const searchKeys = [
      'name',
      userConfig?.language === 'no' ? 'countryNameNo' : 'countryNameEn',
    ];

    const options = {
      keys: searchKeys,
      threshold: 0.2,
    };

    const fuseInstance = new Fuse(sortedStaticCompetitions, options);
    setFuse(fuseInstance);
  }, [sortedStaticCompetitions, userConfig?.language]);


  useEffect(() => {
    if (!clubScopes) return;

    const competitionList: Competition[] = Object.values(competitions).filter(
      competition =>
        competition.iterationsWithEventData &&
        competition.iterationsWithEventData.length > 0 &&
        (clubScopes.hasFullCoverage || clubScopes.competitionIds.includes(competition.id))
    );

    // sort leagues based on orderIndex
    competitionList.sort((a, b) => a.orderIndex - b.orderIndex);

    setSortedStaticCompetitions(competitionList);
    setCompetitionsToShow(competitionList);

  }, [clubScopes, competitions]);


  return (
    <div
      className={'drop-down-select-container' + ((isDropDownExpanded || selectedIterations.length > 0) ? ' drop-down-select-container-focus' : '')}
      id={id}
      style={{ maxHeight: maxHeight }}
    >

      <div className='drop-down-select-button' onClick={() => handleDropDownClick()}>

        {selectedIterations.length > 0 && <div className='drop-down-select-button-overlay fast-fade-in'>&nbsp;</div>}

        <div className='drop-down-select-button-text'>

          {selectedIterations.length === 0 && (
            <div style={{ color: defaultDropDownTextColor }}>
              {userConfig ? staticLanguageMap[defaultDropDownText][userConfig.language] : ''}
            </div>
          )}

          {selectedIterations.length > 0 && isDropDownExpanded && (
            <div style={{ color: defaultDropDownTextColor }}>
              {userConfig ? staticLanguageMap['selectedIterations'][userConfig.language] : ''}
            </div>
          )}

          {selectedIterations.length > 0 && !isDropDownExpanded && (
            <div style={{ color: '#000000' }}>
              {showSelectedCompetitions()}
            </div>
          )}

        </div>

        {!isDropDownExpanded && (
          <div className='drop-down-select-icon'>
            <ArrowDropDownIcon style={{ fontSize: 24 }} />
          </div>
        )}

        {isDropDownExpanded && (
          <div className='drop-down-select-icon'>
            <ArrowDropUpIcon style={{ fontSize: 24 }} />
          </div>
        )}
      </div>

      <div className='league-drop-down-section' style={{ maxHeight: maxHeight }}>

        <div className='league-drop-down-selected-leagues'>
          {selectedIterations.map((selectedIteration: Iteration) => {
            const competition = competitions[Number(selectedIteration.competitionId)];
            return (
              <div
                className={'league-drop-down-selected-league'}
                key={competition.id + '-' + selectedIteration.iterationId + '-selected'}
              >
                <div className='league-drop-down-selected-league-close-icon'>
                  <CloseIcon style={{ fontSize: 16 }} onClick={() => handleOptionSelect(selectedIteration, true)} />
                </div>

                {competition.countryCode && (
                  <img
                    className='league-flag league-drop-down-selected-league-flag'
                    src={countryCodeToCountryInfo[competition.countryCode].flagUrl}
                    alt={competition.countryCode}
                    draggable={false}
                  />
                )}

                <div className='league-drop-down-selected-league-name'>
                  {competition.name}
                  &nbsp;
                  {selectedIteration.season}
                </div>
              </div>
            );
          })}
        </div>

        <div
          className='league-drop-down-input-container'
          style={{ marginTop: 12 + (22 * Math.max(selectedIterations.length, 2)) }}>
          <InputField
            searchString={searchString}
            onChangeInputField={onChangeSearchField}
            onKeyDownInputField={onKeyDownSearchField}
            resetSearchString={() => setSearchString('')}
            defaultInput={userConfig ? staticLanguageMap['searchForIteration'][userConfig.language] : ''}
            showDefaultInput={true}
            style={{ boxShadow: '0px 0px 2px 1px #00000020', backgroundColor: '#ffffff' }}
            ref={inputRef}
          />
        </div>

        {competitionsToShow.length > 0 &&
          competitionsToShow.map((competition: Competition) => {
            return (
              <div
                className='drop-down-select-option iteration-drop-down-option'
                style={{ marginTop: marginBetweenOptions }}
                key={competition.id}
              >

                {competition.name}

                <div className='league-drop-down-option-info-row'>

                  <div>
                    {competition.countryCode && (
                      <img
                        className='league-flag league-drop-down-option-flag'
                        src={countryCodeToCountryInfo[competition.countryCode].flagUrl}
                        alt={competition.countryCode}
                        draggable={false}
                      />
                    )}
                  </div>

                  {competition.countryCode && (
                    <div className='league-drop-down-option-info-text'>
                      {countryCodeToCountryInfo[competition.countryCode].name[userConfig ? userConfig.language : 'en']}
                    </div>
                  )}

                  {competition.tier && (
                    <div className='league-drop-down-option-info-text'>
                      {competition.tier}
                    </div>
                  )}

                  {clubScopes && (clubScopes.hasFullCoverage || (clubScopes.competitionIds && clubScopes.competitionIds.includes(competition.id))) && (
                    <div className={competition.countryCode ? 'league-drop-down-option-data-access' : 'league-drop-down-option-data-access-no-country'}>
                      <KeyIcon style={{ fontSize: 15, marginBottom: -4 }} />
                    </div>
                  )}
                </div>

                {competition.iterationsWithEventData && competition.iterationsWithEventData.map((iteration: Iteration) => {

                  const isSelected = selectedIterations.some(selectedIteration => selectedIteration.iterationId === iteration.iterationId);
                  const selectedIteration: Iteration = { competitionId: Number(competition.id), iterationId: iteration.iterationId, season: iteration.season };

                  const iterationHasSkillcorner = clubScopes !== null && clubScopes.skillcornerIterationIds.includes(iteration.iterationId.toString());

                  const isDisabled = anySkillcornerFiltersSelected && !iterationHasSkillcorner;

                  return (
                    <div
                      className={
                        'drop-down-select-option'
                        + (isSelected ? ' drop-down-select-option-selected' : '')
                        + (isDisabled ? ' drop-down-select-option-disabled' : '')
                      }
                      style={{ marginTop: marginBetweenOptions }}
                      key={competition.id + '-' + iteration.iterationId}
                      onClick={() => !isDisabled ? handleOptionSelect(selectedIteration, isSelected) : null}
                    >
                      <div style={{ position: 'relative', color: isDisabled ? '#00000075' : undefined }}>
                        {iteration.season}
                        {iterationHasSkillcorner && (
                          <div style={{ position: 'absolute', top: 0, width: 15, right: 'calc(50% - ' + (iteration.season.includes('/') ? 39 : 37) + 'px' }}>
                            <img src={skill_corner_logo} alt='SkillCorner' style={{ width: 10, marginTop: 2 }} />
                          </div>
                        )}
                        {isDisabled && (
                          <div style={{ position: 'absolute', top: 1, width: 15, right: 'calc(50% - ' + (iteration.season.includes('/') ? 39 : 37) + 'px' }}>
                            <BlockIcon style={{ fontSize: 13, color: '#00000075' }} />
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}

              </div>
            );
          })}

        {competitionsToShow.length === 0 && (
          <div className='league-drop-down-no-search-result'>
            {userConfig ? staticLanguageMap['noResult'][userConfig.language] : ''}
          </div>
        )}

      </div>

    </div>
  );
};
