import './documents.css';

import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { allUserConfigsState } from '../../recoil/atoms/allUserConfigsState';
import { globalModalPropsState } from '../../recoil/atoms/globalModalPropsState';

import CloseIcon from '@mui/icons-material/Close';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import RestoreIcon from '@mui/icons-material/Restore';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { enGB, nb } from 'date-fns/locale';

import { staticLanguageMap } from '../../../common/static/staticLanguageMap';
import { getClubColor } from '../../utils/configUtils';
import { Rating } from '@mui/material';
import { PlayerSimpleTable } from '../tables/playerSimpleTable/PlayerSimpleTable';
import { FlexibleJsonMapping, GlobalModalProps, PlayerReport } from '../../types';
import { getDateLocaleFormat } from '../../utils/playerUtils';
import { FileUpload } from '../input/FileUpload';
import { getShortenedFileName } from '../modals/globalModals/PdfModal';


interface ReportProps {
  newReportTypeSelected: string | undefined;
  existingReportSelected: PlayerReport | undefined;
  handleCloseIconClick: () => void;
  handleReportButtonClick: () => void;
  isLoading?: boolean;

  commentString: string;
  overallScore?: number;
  potentialScore?: number;
  techniqueScore?: number;
  understandingScore?: number;
  attitudeScore?: number;
  influenceScore?: number;
  paceScore?: number;
  strengthScore?: number;
  staminaScore?: number;
  intensityScore?: number;
  teamString?: string;
  opponentString?: string;
  matchDate?: Date;

  setCommentString?: React.Dispatch<React.SetStateAction<string>>;
  setOverallScore?: React.Dispatch<React.SetStateAction<number>>;
  setPotentialScore?: React.Dispatch<React.SetStateAction<number>>;
  setTechniqueScore?: React.Dispatch<React.SetStateAction<number>>;
  setUnderstandingScore?: React.Dispatch<React.SetStateAction<number>>;
  setAttitudeScore?: React.Dispatch<React.SetStateAction<number>>;
  setInfluenceScore?: React.Dispatch<React.SetStateAction<number>>;
  setPaceScore?: React.Dispatch<React.SetStateAction<number>>;
  setStrengthScore?: React.Dispatch<React.SetStateAction<number>>;
  setStaminaScore?: React.Dispatch<React.SetStateAction<number>>;
  setIntensityScore?: React.Dispatch<React.SetStateAction<number>>;
  setTeamString?: React.Dispatch<React.SetStateAction<string>>;
  setOpponentString?: React.Dispatch<React.SetStateAction<string>>;
  setMatchDate?: React.Dispatch<React.SetStateAction<Date>>;

  hasSetOverallScore?: boolean;
  hasSetPotentialScore?: boolean;
  hasSetTechniqueScore?: boolean;
  hasSetUnderstandingScore?: boolean;
  hasSetAttitudeScore?: boolean;
  hasSetInfluenceScore?: boolean;
  hasSetPaceScore?: boolean;
  hasSetStrengthScore?: boolean;
  hasSetStaminaScore?: boolean;
  hasSetIntensityScore?: boolean;

  setHasSetOverallScore?: React.Dispatch<React.SetStateAction<boolean>>;
  setHasSetPotentialScore?: React.Dispatch<React.SetStateAction<boolean>>;
  setHasSetTechniqueScore?: React.Dispatch<React.SetStateAction<boolean>>;
  setHasSetUnderstandingScore?: React.Dispatch<React.SetStateAction<boolean>>;
  setHasSetAttitudeScore?: React.Dispatch<React.SetStateAction<boolean>>;
  setHasSetInfluenceScore?: React.Dispatch<React.SetStateAction<boolean>>;
  setHasSetPaceScore?: React.Dispatch<React.SetStateAction<boolean>>;
  setHasSetStrengthScore?: React.Dispatch<React.SetStateAction<boolean>>;
  setHasSetStaminaScore?: React.Dispatch<React.SetStateAction<boolean>>;
  setHasSetIntensityScore?: React.Dispatch<React.SetStateAction<boolean>>;

  overallScoreRequired?: boolean;
  potentialScoreRequired?: boolean;
  techniqueScoreRequired?: boolean;
  understandingScoreRequired?: boolean;
  attitudeScoreRequired?: boolean;
  influenceScoreRequired?: boolean;
  paceScoreRequired?: boolean;
  strengthScoreRequired?: boolean;
  staminaScoreRequired?: boolean;
  intensityScoreRequired?: boolean;
  teamStringRequired?: boolean;
  opponentStringRequired?: boolean;
  matchDateRequired?: boolean;

  submitButtonDisabled?: boolean;

  shouldShowAverageMatchReportScoreTitle?: boolean;
  isArchiveView?: boolean;
  playerData?: FlexibleJsonMapping;

  fileUrls?: string[];
  fileNames?: string[];
  handleFileUpload?: (file: File) => void;
  handleRemoveFile?: (event: React.MouseEvent, fileUrl: string, fileName: string) => void;
}


export const Report: React.FC<ReportProps> = ({
  newReportTypeSelected,
  existingReportSelected,
  handleCloseIconClick,
  handleReportButtonClick,
  isLoading,

  commentString,
  overallScore,
  potentialScore,
  techniqueScore,
  understandingScore,
  attitudeScore,
  influenceScore,
  paceScore,
  strengthScore,
  staminaScore,
  intensityScore,
  teamString,
  opponentString,
  matchDate,

  setCommentString,
  setOverallScore,
  setPotentialScore,
  setTechniqueScore,
  setUnderstandingScore,
  setAttitudeScore,
  setInfluenceScore,
  setPaceScore,
  setStrengthScore,
  setStaminaScore,
  setIntensityScore,
  setTeamString,
  setOpponentString,
  setMatchDate,

  hasSetOverallScore,
  hasSetPotentialScore,
  hasSetTechniqueScore,
  hasSetUnderstandingScore,
  hasSetAttitudeScore,
  hasSetInfluenceScore,
  hasSetPaceScore,
  hasSetStrengthScore,
  hasSetStaminaScore,
  hasSetIntensityScore,

  setHasSetOverallScore,
  setHasSetPotentialScore,
  setHasSetTechniqueScore,
  setHasSetUnderstandingScore,
  setHasSetAttitudeScore,
  setHasSetInfluenceScore,
  setHasSetPaceScore,
  setHasSetStrengthScore,
  setHasSetStaminaScore,
  setHasSetIntensityScore,

  overallScoreRequired,
  potentialScoreRequired,
  techniqueScoreRequired,
  understandingScoreRequired,
  attitudeScoreRequired,
  influenceScoreRequired,
  paceScoreRequired,
  strengthScoreRequired,
  staminaScoreRequired,
  intensityScoreRequired,
  teamStringRequired,
  opponentStringRequired,
  matchDateRequired,

  submitButtonDisabled,

  shouldShowAverageMatchReportScoreTitle,
  isArchiveView,
  playerData,

  fileUrls,
  fileNames,
  handleFileUpload,
  handleRemoveFile,
}) => {

  const userConfig = useRecoilValue(userConfigState);
  const allUsersWithSameClub = useRecoilValue(allUserConfigsState);

  const setGlobalModalProps = useSetRecoilState(globalModalPropsState);

  const dateFormat = 'dd.MM.yyyy';
  const locale = userConfig && userConfig.language === 'no'
    ? nb
    : enGB;


  const handleRatingChange = (
    scoreSetter?: React.Dispatch<React.SetStateAction<number>>,
    interactionSetter?: React.Dispatch<React.SetStateAction<boolean>>,
    hasInteracted?: boolean,
  ) => {
    return (_: React.ChangeEvent<object>, value: number | null) => {
      if ((value || hasInteracted) && scoreSetter) {
        scoreSetter(value ?? 0);
      }
      if (interactionSetter) interactionSetter(true);
    };
  };


  const handleAttachmentClick = (fileUrl: string, fileName: string) => {
    const globalModalProps: GlobalModalProps = {
      globalModalType: 'PdfModal',
      closeGlobalModal: () => setGlobalModalProps(undefined),
      fileUrl: fileUrl,
      fileName: fileName,
    };

    setGlobalModalProps(globalModalProps);
  };


  const handleSetMatchDate = (date: Date | null) => {
    if (!setMatchDate) return;

    if (!setMatchDate || !date) {
      return;
    }

    const adjustedDate = new Date(date);
    adjustedDate.setHours(12);
    setMatchDate(adjustedDate);
  };


  const clubColor = getClubColor(userConfig?.club ?? '');


  return (
    <div className='document-container'>

      <div className='fast-fade-in'>
        <div className='document-close-icon' onClick={handleCloseIconClick}>
          <CloseIcon style={{ fontSize: 24 }} />
        </div>

        {newReportTypeSelected && (
          <div className='document-title'>
            {userConfig ? staticLanguageMap['new' + newReportTypeSelected][userConfig.language] : ''}
          </div>
        )}

        {isArchiveView && playerData && (
          <div className='report-player-table-container' key={existingReportSelected?.id ?? 'newReport'}>
            <div className='fade-in'>
              <PlayerSimpleTable
                data={[playerData ?? {}]}
                tableType={'archiveReportInfo'}
              />
            </div>
          </div>
        )}

        {existingReportSelected && (
          <div className={'document-title' + (isArchiveView ? ' report-title-archive' : '')}>

            {userConfig ? staticLanguageMap[existingReportSelected.reportType][userConfig.language] : ''}

            <div className='document-title-divider'>&nbsp;</div>

            <div className='document-title-info'>
              {allUsersWithSameClub[existingReportSelected.userEmail] ? allUsersWithSameClub[existingReportSelected.userEmail].name + ', ' : ''}
              {existingReportSelected.date && getDateLocaleFormat(existingReportSelected.date.split('T')[0])}
            </div>
          </div>
        )}

        <div className='document-horizontal-divider document-horizontal-top-divider'>&nbsp;</div>

        <div className='document-horizontal-divider document-horizontal-bottom-divider'>&nbsp;</div>

        <div className='document-submit-section'>
          {userConfig && (!existingReportSelected || existingReportSelected.userEmail === userConfig.email) && !isLoading && (
            <div className='document-submit-button' onClick={(!submitButtonDisabled) ? handleReportButtonClick : undefined}>
              <div className='document-submit-button-text'>
                {userConfig
                  ? (existingReportSelected
                    ? (existingReportSelected.isDeleted
                      ? staticLanguageMap['restore'][userConfig.language]
                      : staticLanguageMap['delete'][userConfig.language])
                    + (existingReportSelected.reportType === 'Comment'
                      ? (' ' + staticLanguageMap['comment'][userConfig.language])
                      : existingReportSelected.reportType === 'Attachment'
                        ? (' ' + staticLanguageMap['attachment'][userConfig.language])
                        : (' ' + staticLanguageMap['report'][userConfig.language]))
                    : staticLanguageMap['submit' + newReportTypeSelected][userConfig.language])
                  : ''}
              </div>
              <div className='document-submit-button-icon'>
                {newReportTypeSelected && <ArrowRightAltIcon style={{ fontSize: 21 }} />}
                {existingReportSelected && existingReportSelected.isDeleted && <RestoreIcon style={{ fontSize: 21 }} />}
                {existingReportSelected && !existingReportSelected.isDeleted && <DeleteOutlineIcon style={{ fontSize: 21 }} />}
              </div>
            </div>
          )}

          {isLoading && (
            <div style={{ margin: 'auto', marginBottom: -2 }}>
              <div className='loading-spinner loading-spinner-small'>&nbsp;</div>
            </div>
          )}
        </div>

      </div>

      <div className='document-content-section'>
        {(newReportTypeSelected === 'MatchReport' || existingReportSelected?.reportType === 'MatchReport') && (
          <div className='report-content-left-column fast-fade-in'>

            <div className='report-content-rating-row report-content-input-row report-content-input-top-row'>
              <div className='report-content-input-title'>
                {userConfig ? staticLanguageMap['teamOfPlayer'][userConfig.language] : ''}
              </div>
              <input
                className='document-input-field'
                name='report-input-field-team'
                type='text'
                autoComplete='off'
                value={teamString}
                placeholder={userConfig ? staticLanguageMap['provideTeam'][userConfig.language] : ''}
                onChange={(event) => setTeamString ? setTeamString(event.target.value) : null}
                disabled={existingReportSelected !== undefined}
              />
              {teamStringRequired && (
                <div className='report-input-required fast-fade-in'>
                  *
                </div>
              )}
            </div>

            <div className='report-content-rating-row report-content-input-row'>
              <div className='report-content-input-title'>
                {userConfig ? staticLanguageMap['opponentTeam'][userConfig.language] : ''}
              </div>
              <input
                className='document-input-field'
                name='report-input-field-opponent'
                type='text'
                autoComplete='off'
                value={opponentString}
                placeholder={userConfig ? staticLanguageMap['provideTeam'][userConfig.language] : ''}
                onChange={(event) => setOpponentString ? setOpponentString(event.target.value) : null}
                disabled={existingReportSelected !== undefined}
              />
              {opponentStringRequired && (
                <div className='report-input-required fast-fade-in'>
                  *
                </div>
              )}
            </div>

            <div className='report-content-rating-row report-content-input-row'>
              <div className='report-content-input-title'>
                {userConfig ? staticLanguageMap['matchDate'][userConfig.language] : ''}
              </div>
              <div>
                <DatePicker
                  className='document-input-field'
                  selected={matchDate}
                  onChange={(date: Date | null) => handleSetMatchDate(date)}
                  locale={locale}
                  dateFormat={dateFormat}
                  popperPlacement='bottom-start'
                  disabled={existingReportSelected !== undefined}
                />
              </div>
              {matchDateRequired && (
                <div className='report-input-required fast-fade-in'>
                  *
                </div>
              )}
            </div>

            <div className='document-text-area-container report-text-area-container-match-report'>
              <textarea
                className='document-text-area'
                value={commentString}
                onChange={(event) => setCommentString ? setCommentString(event.target.value) : null}
                placeholder={userConfig
                  ? (existingReportSelected
                    ? staticLanguageMap['noCommentsProvided'][userConfig.language]
                    : staticLanguageMap['provideComments'][userConfig.language])
                  : ''}
                disabled={existingReportSelected !== undefined}
              />
            </div>
          </div>
        )}

        {(newReportTypeSelected === 'ScoutingReport' || existingReportSelected?.reportType === 'ScoutingReport') && (
          <div className='report-content-left-column fast-fade-in'>
            <div className='document-text-area-container'>
              <textarea
                className='document-text-area'
                value={commentString}
                onChange={(event) => setCommentString ? setCommentString(event.target.value) : null}
                placeholder={userConfig
                  ? (existingReportSelected
                    ? staticLanguageMap['noCommentsProvided'][userConfig.language]
                    : staticLanguageMap['provideComments'][userConfig.language])
                  : ''}
                disabled={existingReportSelected !== undefined}
              />
            </div>
          </div>
        )}

        {
          (
            (newReportTypeSelected === 'MatchReport' || existingReportSelected?.reportType === 'MatchReport') ||
            (newReportTypeSelected === 'ScoutingReport' || existingReportSelected?.reportType === 'ScoutingReport')
          ) && (
            <div className='report-content-right-column'>

              {newReportTypeSelected === 'ScoutingReport' && shouldShowAverageMatchReportScoreTitle && (
                <div className='report-content-average-title match-report-content-average-title fast-fade-in'>
                  {userConfig ? staticLanguageMap['averageMatchReports'][userConfig.language] : ''}
                </div>
              )}

              <div className='report-content-rating-row report-content-rating-top-row'>
                <div className='report-rating'>
                  <div className='report-rating-title'>
                    {userConfig ? staticLanguageMap['overallRating'][userConfig.language] : ''}
                  </div>
                  <div className='report-rating-stars'>
                    <Rating
                      name='overallScore'
                      value={overallScore}
                      onChange={handleRatingChange(setOverallScore, setHasSetOverallScore, hasSetOverallScore)}
                      max={5}
                      precision={0.5}
                      sx={{
                        fontSize: 25,
                        color: (existingReportSelected || newReportTypeSelected === 'MatchReport' || hasSetOverallScore) ? clubColor : '#ffffff55',
                        '&:hover': {
                          color: clubColor,
                        },
                        '& .MuiRating-iconEmpty': {
                          color: existingReportSelected ? '#9d9fa3' : '#bbbdc2'
                        },
                      }}
                      readOnly={existingReportSelected !== undefined}
                    />
                    {overallScoreRequired && (
                      <div className='report-overall-rating-required fast-fade-in'>
                        *
                      </div>
                    )}
                  </div>
                </div>

                {(newReportTypeSelected === 'ScoutingReport' || existingReportSelected?.reportType === 'ScoutingReport')
                  && (setPotentialScore || isArchiveView)
                  && (
                    <div className='report-rating'>
                      <div className='report-rating-title'>
                        {userConfig ? staticLanguageMap['potential'][userConfig.language] : ''}
                      </div>
                      <div className='report-rating-stars'>
                        <Rating
                          name='potentialScore'
                          value={potentialScore}
                          onChange={handleRatingChange(setPotentialScore, setHasSetPotentialScore, hasSetPotentialScore)}
                          max={5}
                          precision={0.5}
                          sx={{
                            fontSize: 25,
                            color: (existingReportSelected || newReportTypeSelected === 'MatchReport' || hasSetPotentialScore) ? clubColor : '#ffffff55',
                            '&:hover': {
                              color: clubColor,
                            },
                            '& .MuiRating-iconEmpty': {
                              color: existingReportSelected ? '#9d9fa3' : '#bbbdc2'
                            },
                          }}
                          readOnly={existingReportSelected !== undefined}
                        />
                        {potentialScoreRequired && (
                          <div className='report-overall-rating-required fast-fade-in'>
                            *
                          </div>
                        )}
                      </div>
                    </div>
                  )
                }
              </div>

              <div className='report-content-rating-row'>
                <div className='report-rating'>
                  <div className='report-rating-title'>
                    {userConfig ? staticLanguageMap['technique'][userConfig.language] : ''}
                  </div>
                  <div className='report-rating-stars'>
                    <Rating
                      name='techniqueScore'
                      value={techniqueScore}
                      onChange={handleRatingChange(setTechniqueScore, setHasSetTechniqueScore, hasSetTechniqueScore)}
                      max={5}
                      precision={1}
                      sx={{
                        fontSize: 22,
                        color: (existingReportSelected || newReportTypeSelected === 'MatchReport' || hasSetTechniqueScore) ? clubColor : '#ffffff55',
                        '&:hover': {
                          color: clubColor,
                        },
                        '& .MuiRating-iconEmpty': {
                          color: existingReportSelected ? '#9d9fa3' : '#bbbdc2'
                        },
                      }}
                      readOnly={existingReportSelected !== undefined}
                    />
                    {techniqueScoreRequired && (
                      <div className='report-rating-required fast-fade-in'>
                        *
                      </div>
                    )}
                  </div>
                </div>
                <div className='report-rating'>
                  <div className='report-rating-title'>
                    {userConfig ? staticLanguageMap['pace'][userConfig.language] : ''}
                  </div>
                  <div className='report-rating-stars'>
                    <Rating
                      name='paceScore'
                      value={paceScore}
                      onChange={handleRatingChange(setPaceScore, setHasSetPaceScore, hasSetPaceScore)}
                      max={5}
                      precision={1}
                      sx={{
                        fontSize: 22,
                        color: (existingReportSelected || newReportTypeSelected === 'MatchReport' || hasSetPaceScore) ? clubColor : '#ffffff55',
                        '&:hover': {
                          color: clubColor,
                        },
                        '& .MuiRating-iconEmpty': {
                          color: existingReportSelected ? '#9d9fa3' : '#bbbdc2'
                        },
                      }}
                      readOnly={existingReportSelected !== undefined}
                    />
                    {paceScoreRequired && (
                      <div className='report-rating-required fast-fade-in'>
                        *
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className='report-content-rating-row'>
                <div className='report-rating'>
                  <div className='report-rating-title'>
                    {userConfig ? staticLanguageMap['understanding'][userConfig.language] : ''}
                  </div>
                  <div className='report-rating-stars'>
                    <Rating
                      name='understandingScore'
                      value={understandingScore}
                      onChange={handleRatingChange(setUnderstandingScore, setHasSetUnderstandingScore, hasSetUnderstandingScore)}
                      max={5}
                      precision={1}
                      sx={{
                        fontSize: 22,
                        color: (existingReportSelected || newReportTypeSelected === 'MatchReport' || hasSetUnderstandingScore) ? clubColor : '#ffffff55',
                        '&:hover': {
                          color: clubColor,
                        },
                        '& .MuiRating-iconEmpty': {
                          color: existingReportSelected ? '#9d9fa3' : '#bbbdc2'
                        },
                      }}
                      readOnly={existingReportSelected !== undefined}
                    />
                    {understandingScoreRequired && (
                      <div className='report-rating-required fast-fade-in'>
                        *
                      </div>
                    )}
                  </div>
                </div>
                <div className='report-rating'>
                  <div className='report-rating-title'>
                    {userConfig ? staticLanguageMap['strength'][userConfig.language] : ''}
                  </div>
                  <div className='report-rating-stars'>
                    <Rating
                      name='strengthScore'
                      value={strengthScore}
                      onChange={handleRatingChange(setStrengthScore, setHasSetStrengthScore, hasSetStrengthScore)}
                      max={5}
                      precision={1}
                      sx={{
                        fontSize: 22,
                        color: (existingReportSelected || newReportTypeSelected === 'MatchReport' || hasSetStrengthScore) ? clubColor : '#ffffff55',
                        '&:hover': {
                          color: clubColor,
                        },
                        '& .MuiRating-iconEmpty': {
                          color: existingReportSelected ? '#9d9fa3' : '#bbbdc2'
                        },
                      }}
                      readOnly={existingReportSelected !== undefined}
                    />
                    {strengthScoreRequired && (
                      <div className='report-rating-required fast-fade-in'>
                        *
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className='report-content-rating-row'>
                <div className='report-rating'>
                  <div className='report-rating-title'>
                    {userConfig ? staticLanguageMap['attitude'][userConfig.language] : ''}
                  </div>
                  <div className='report-rating-stars'>
                    <Rating
                      name='attitudeScore'
                      value={attitudeScore}
                      onChange={handleRatingChange(setAttitudeScore, setHasSetAttitudeScore, hasSetAttitudeScore)}
                      max={5}
                      precision={1}
                      sx={{
                        fontSize: 22,
                        color: (existingReportSelected || newReportTypeSelected === 'MatchReport' || hasSetAttitudeScore) ? clubColor : '#ffffff55',
                        '&:hover': {
                          color: clubColor,
                        },
                        '& .MuiRating-iconEmpty': {
                          color: existingReportSelected ? '#9d9fa3' : '#bbbdc2'
                        },
                      }}
                      readOnly={existingReportSelected !== undefined}
                    />
                    {attitudeScoreRequired && (
                      <div className='report-rating-required fast-fade-in'>
                        *
                      </div>
                    )}
                  </div>
                </div>
                <div className='report-rating'>
                  <div className='report-rating-title'>
                    {userConfig ? staticLanguageMap['stamina'][userConfig.language] : ''}
                  </div>
                  <div className='report-rating-stars'>
                    <Rating
                      name='staminaScore'
                      value={staminaScore}
                      onChange={handleRatingChange(setStaminaScore, setHasSetStaminaScore, hasSetStaminaScore)}
                      max={5}
                      precision={1}
                      sx={{
                        fontSize: 22,
                        color: (existingReportSelected || newReportTypeSelected === 'MatchReport' || hasSetStaminaScore) ? clubColor : '#ffffff55',
                        '&:hover': {
                          color: clubColor,
                        },
                        '& .MuiRating-iconEmpty': {
                          color: existingReportSelected ? '#9d9fa3' : '#bbbdc2'
                        },
                      }}
                      readOnly={existingReportSelected !== undefined}
                    />
                    {staminaScoreRequired && (
                      <div className='report-rating-required fast-fade-in'>
                        *
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className='report-content-rating-row'>
                <div className='report-rating'>
                  <div className='report-rating-title'>
                    {userConfig ? staticLanguageMap['influence'][userConfig.language] : ''}
                  </div>
                  <div className='report-rating-stars'>
                    <Rating
                      name='influenceScore'
                      value={influenceScore}
                      onChange={handleRatingChange(setInfluenceScore, setHasSetInfluenceScore, hasSetInfluenceScore)}
                      max={5}
                      precision={1}
                      sx={{
                        fontSize: 22,
                        color: (existingReportSelected || newReportTypeSelected === 'MatchReport' || hasSetInfluenceScore) ? clubColor : '#ffffff55',
                        '&:hover': {
                          color: clubColor,
                        },
                        '& .MuiRating-iconEmpty': {
                          color: existingReportSelected ? '#9d9fa3' : '#bbbdc2'
                        },
                      }}
                      readOnly={existingReportSelected !== undefined}
                    />
                    {influenceScoreRequired && (
                      <div className='report-rating-required fast-fade-in'>
                        *
                      </div>
                    )}
                  </div>
                </div>
                <div className='report-rating'>
                  <div className='report-rating-title'>
                    {userConfig ? staticLanguageMap['intensity'][userConfig.language] : ''}
                  </div>
                  <div className='report-rating-stars'>
                    <Rating
                      name='intensityScore'
                      value={intensityScore}
                      onChange={handleRatingChange(setIntensityScore, setHasSetIntensityScore, hasSetIntensityScore)}
                      max={5}
                      precision={1}
                      sx={{
                        fontSize: 22,
                        color: (existingReportSelected || newReportTypeSelected === 'MatchReport' || hasSetIntensityScore) ? clubColor : '#ffffff55',
                        '&:hover': {
                          color: clubColor,
                        },
                        '& .MuiRating-iconEmpty': {
                          color: existingReportSelected ? '#9d9fa3' : '#bbbdc2'
                        },
                      }}
                      readOnly={existingReportSelected !== undefined}
                    />
                    {intensityScoreRequired && (
                      <div className='report-rating-required fast-fade-in'>
                        *
                      </div>
                    )}
                  </div>
                </div>
              </div>

            </div>
          )}

        {(newReportTypeSelected === 'Comment' || existingReportSelected?.reportType === 'Comment') && (
          <div className='document-text-area-container'>
            <textarea
              className='document-text-area'
              value={commentString}
              onChange={(event) => setCommentString ? setCommentString(event.target.value) : null}
              placeholder={userConfig
                ? (existingReportSelected
                  ? staticLanguageMap['noCommentsProvided'][userConfig.language]
                  : staticLanguageMap['provideCommentOrInformation'][userConfig.language])
                : ''}
              disabled={existingReportSelected !== undefined}
            />
          </div>
        )}

        {(newReportTypeSelected === 'Attachment' || existingReportSelected?.reportType === 'Attachment') && (
          <div className='full-size-container flex-column'>

            <div className='document-text-area-container' style={{ height: '62%', position: 'absolute', top: '3%' }}>
              {newReportTypeSelected && handleFileUpload && (
                <div style={{ margin: 'auto', paddingTop: 20, paddingBottom: 10 }}>
                  <FileUpload
                    handleFileUpload={handleFileUpload}
                    language={userConfig?.language ?? 'en'}
                    isButton={true}
                  />
                </div>
              )}

              <div className='report-file-container-existing-files'>
                {fileUrls && fileUrls.map((fileUrl, index) => {
                  if (!fileNames || fileNames.length <= index) return null;

                  const fileName = fileNames[index];
                  return (
                    <div
                      key={index}
                      className='clause-file-container-existing-file'
                      style={{ width: 'fit-content', maxWidth: 'none', marginTop: 8, marginBottom: 8, fontSize: 13, padding: '5px 8px' }}
                      onClick={() => handleAttachmentClick(fileUrl, fileName)}
                    >
                      {newReportTypeSelected && handleRemoveFile && (
                        <div
                          className='clause-content-icon clause-file-container-existing-file-close-icon'
                          onClick={(event) => handleRemoveFile(event, fileUrl, fileName)}
                        >
                          <CloseIcon style={{ fontSize: 18 }} />
                        </div>
                      )}
                      <PictureAsPdfIcon style={{ fontSize: 18, marginRight: 8 }} />
                      {getShortenedFileName(fileName, 60)}
                    </div>
                  );
                })}
              </div>
            </div>

            <div className='document-text-area-container' style={{ height: '28%', position: 'absolute', bottom: '4%' }}>
              <textarea
                className='document-text-area'
                value={commentString}
                onChange={(event) => setCommentString ? setCommentString(event.target.value) : null}
                placeholder={userConfig
                  ? (existingReportSelected
                    ? staticLanguageMap['noCommentsProvided'][userConfig.language]
                    : staticLanguageMap['provideComments'][userConfig.language])
                  : ''}
                disabled={existingReportSelected !== undefined}
              />
            </div>
          </div>
        )}

      </div>

    </div>
  );
};
