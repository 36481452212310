import { CompetitionMap, FlexibleJsonMapping, UserConfig } from '../../../types';
import { staticLanguageMap } from '../../../../common/static/staticLanguageMap';
import { goalkeeperSeasonTableMetricGroups, metricToDisplayName, outfieldSeasonTableMetricGroups, skillcornerStats } from '../../../static/playerMetrics';
import { countryCodeToCountryInfo } from '../../../static/countries';

import LockIcon from '@mui/icons-material/Lock';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import BlockIcon from '@mui/icons-material/Block';

import { getColorBlindRatingColor, getRatingColor } from '../../../utils/colorUtils';
import { Toggle } from '../../input/Toggle';
import { updateSeasonStatsToggles } from '../../../services/firestore/settings';


const renderSeasonCell = (competitions: CompetitionMap, language: string, selectedPositionKey: string) => {
  const SeasonCellRenderer = ({ row }: FlexibleJsonMapping) => {
    return (
      <SeasonCell
        row={row}
        competitions={competitions}
        language={language}
        selectedPositionKey={selectedPositionKey}
      />
    );
  };

  SeasonCellRenderer.displayName = 'SeasonCellRenderer';
  return SeasonCellRenderer;
};


const SeasonCell: React.FC<{
  row: FlexibleJsonMapping,
  competitions: CompetitionMap,
  language: string
  selectedPositionKey: string
}> = ({ row, competitions, language, selectedPositionKey }) => {

  // iteration info
  const competition = competitions[row.original.competition_id];
  const flagUrl = (competition && competition.countryCode && countryCodeToCountryInfo[competition.countryCode])
    ? countryCodeToCountryInfo[competition.countryCode].flagUrl
    : undefined;
  const competitionName = competition?.name;

  // club info
  const club = row.original.club;
  let clubName: string | undefined = club?.name;
  let clubLogoUrl: string | undefined = club?.logo_url;
  if (club?.is_national_team) {
    clubName = club?.country_code in countryCodeToCountryInfo ? countryCodeToCountryInfo[club?.country_code].name[language] : undefined;
    clubLogoUrl = club?.country_code in countryCodeToCountryInfo ? countryCodeToCountryInfo[club?.country_code].flagUrl : undefined;
  }

  // position info
  let position: string | undefined = undefined;
  if (selectedPositionKey === 'overall' && row.original.primary_position) {
    // if selectedPositionKey is overall, we display the primary position of the iteration (and secondary position if it exists)
    position = staticLanguageMap[row.original.primary_position][language];
    if (row.original.secondary_position in staticLanguageMap) {
      position += ` (${staticLanguageMap[row.original.secondary_position][language]})`;
    }
  }
  else {
    // if selectedPositionKey is a particular position, we only want to display that position
    position = staticLanguageMap[selectedPositionKey][language];
  }

  // minutes info
  let minutesPlayed = 0;
  const positionKeyTodata = row.original[selectedPositionKey]
    ? selectedPositionKey
    : (selectedPositionKey === row.original['primary_position'] ? 'overall' : selectedPositionKey);
  if (row.original[positionKeyTodata]) {
    minutesPlayed = row.original[positionKeyTodata]['minutes_played'];
    if (minutesPlayed) {
      minutesPlayed = Math.round(minutesPlayed);
    }
  }
  const minutesPlayedString = minutesPlayed + ' min';

  return (
    <div className='season-table-info-cell'>

      <div className='season-table-info-cell-season-column'>

        <div className='season-table-info-cell-row'>
          {flagUrl && (
            <img
              className='season-table-info-cell-flag'
              src={flagUrl}
              alt=''
              draggable={false}
            />
          )}

          {competitionName}

          <div style={{ marginLeft: 4 }}>
            {row.original.season}
          </div>
        </div>

        <div className='season-table-info-cell-row'>
          {clubLogoUrl && !row.original.is_national_team && (
            <div className='season-table-club-logo-container'>
              <img
                className='season-table-club-logo'
                src={clubLogoUrl}
                alt=''
                draggable={false}
              />
            </div>
          )}

          {clubLogoUrl && row.original.is_national_team && (
            <div className='season-table-club-logo-container'>
              <img
                className='season-table-info-cell-flag'
                style={{ marginTop: 1 }}
                src={clubLogoUrl}
                alt=''
                draggable={false}
              />
            </div>
          )}

          <div style={{ fontSize: 12 }}>
            {clubName}
          </div>
        </div>

      </div>

      <div className='season-table-info-cell-info-column' style={{ fontSize: 12 }}>
        <div className='season-table-info-cell-row'>
          {position}
        </div>
        <div className='season-table-info-cell-row'>
          {minutesPlayedString}
        </div>
      </div>

    </div>
  );
};


const renderSeasonRatingCell = (
  property: string,
  selectedPositionKey: string,
  metricGroupKey: string,
  metricGroupIsToggled: boolean,
  isColorBlind: boolean,
) => {
  const SeasonRatingCellRenderer = ({ row }: FlexibleJsonMapping) => {
    return (
      <SeasonRatingCell
        row={row}
        property={property}
        selectedPositionKey={selectedPositionKey}
        metricGroupKey={metricGroupKey}
        metricGroupIsToggled={metricGroupIsToggled}
        isColorBlind={isColorBlind}
      />
    );
  };

  SeasonRatingCellRenderer.displayName = 'SeasonRatingCellRenderer';
  return SeasonRatingCellRenderer;
};


const SeasonRatingCell: React.FC<{
  row: FlexibleJsonMapping,
  property: string,
  selectedPositionKey: string,
  metricGroupKey: string,
  metricGroupIsToggled: boolean,
  isColorBlind: boolean,
}> = ({ row, property, selectedPositionKey, metricGroupKey, metricGroupIsToggled, isColorBlind }) => {

  const positionKeyToData = row.original[selectedPositionKey]
    ? selectedPositionKey
    : (selectedPositionKey === row.original['primary_position'] ? 'overall' : selectedPositionKey);

  const isLockedOrBlocked = !row.original[positionKeyToData] || !row.original[positionKeyToData]['minutes_played'];

  if (isLockedOrBlocked) {
    return (<div></div>);
  }

  const normalizedValue = metricGroupKey === 'Ratings' ? row.original[positionKeyToData][property] : row.original[positionKeyToData][property + '_normalized'];
  let displayValue = normalizedValue !== undefined ? Math.round(normalizedValue * 10) / 10 : '-';

  if (metricGroupIsToggled) {
    let actualValue = row.original[positionKeyToData][property];

    const isPercentage = property.endsWith('_percentage');
    if (actualValue && isPercentage) {
      actualValue = actualValue * 100;
    }

    const roundingFactor = (metricGroupKey === 'Attacking' && property !== 'n_shots_per_90') || property === 'gk_postshot_xg_prevented_difference_per_shot'
      ? 100
      : 10;

    displayValue = actualValue !== undefined ? Math.round(actualValue * roundingFactor) / roundingFactor : '-';

    if (displayValue !== '-' && isPercentage) {
      displayValue = displayValue + '%';
    }

    if (displayValue !== '-' && ['skillcorner_distance_per_90', 'skillcorner_distance_tip_per_90', 'skillcorner_distance_otip_per_90'].includes(property)) {
      displayValue = Math.round(actualValue / 100) / 10 + ' km';
    }
    if (displayValue !== '-' && [
      'skillcorner_sprinting_distance_per_90',
      'skillcorner_sprinting_distance_tip_per_90',
      'skillcorner_sprinting_distance_otip_per_90',
      'skillcorner_hsr_distance_per_90',
      'skillcorner_hsr_distance_tip_per_90',
      'skillcorner_hsr_distance_otip_per_90',
    ].includes(property)) {
      displayValue = Math.round(actualValue) + ' m';
    }
    if (displayValue !== '-' && property === 'skillcorner_psv-99_average') {
      displayValue = displayValue + ' km/h';
    }
    if (displayValue !== '-' && property === 'gk_penalty_saves_percentage') {
      displayValue = row.original[positionKeyToData]['n_gk_penalties_saved']
        + '/'
        + row.original[positionKeyToData]['n_gk_penalties_faced']
        + ' ('
        + displayValue
        + ')';
    }
  }

  const backgroundColor = isColorBlind
    ? getColorBlindRatingColor(normalizedValue, 0.7)
    : getRatingColor(normalizedValue);

  return (
    <div className='season-table-color-cell-container'>
      <div
        className='season-table-color-cell'
        style={{
          backgroundColor: backgroundColor,
          fontSize: isColorBlind ? 13 : 12,
          fontWeight: isColorBlind ? 700 : 500,
        }}>
        {displayValue}
      </div>
    </div>
  );
};


const renderSeasonRatingOrLockCell = (property: string, selectedPositionKey: string, isColorBlind: boolean) => {
  const SeasonRatingOrLockCellRenderer = ({ row }: FlexibleJsonMapping) => {
    return <SeasonRatingOrLockCell row={row} property={property} selectedPositionKey={selectedPositionKey} isColorBlind={isColorBlind} />;
  };

  SeasonRatingOrLockCellRenderer.displayName = `SeasonRatingOrLockCellRenderer(${property})`;
  return SeasonRatingOrLockCellRenderer;
};

const SeasonRatingOrLockCell: React.FC<{ row: FlexibleJsonMapping, property: string, selectedPositionKey: string, isColorBlind: boolean }> = ({
  row,
  property,
  selectedPositionKey,
  isColorBlind,
}) => {

  const positionKeyToData = row.original[selectedPositionKey]
    ? selectedPositionKey
    : (selectedPositionKey === row.original['primary_position'] ? 'overall' : selectedPositionKey);

  if (!row.original[positionKeyToData] || !row.original[positionKeyToData]['minutes_played']) {
    return (
      <div className='season-table-text-cell'>
        <BlockIcon style={{ fontSize: 18, marginTop: -1, marginLeft: 12, }} />
        <ArrowRightAltIcon style={{ fontSize: 16, marginTop: 1, marginLeft: 1, color: '#ffffffaa' }} />
      </div>
    );
  }
  else if (!row.original['event_data_available']) {
    return (
      <div className='season-table-text-cell'>
        <LockIcon style={{ fontSize: 18, marginTop: -1, marginLeft: 12, }} />
        <ArrowRightAltIcon style={{ fontSize: 16, marginTop: 1, marginLeft: 1, color: '#ffffffaa' }} />
      </div>
    );
  }

  if (!row.original[positionKeyToData]) return <div></div>;

  const value = row.original[positionKeyToData][property];

  const backgroundColor = isColorBlind
    ? getColorBlindRatingColor(value, 0.7)
    : getRatingColor(value);

  return (
    <div className='season-table-color-cell-container'>
      <div
        className='season-table-color-cell'
        style={{
          backgroundColor: backgroundColor,
          fontSize: isColorBlind ? 13 : 12,
          fontWeight: isColorBlind ? 700 : 500,
        }}>
        {value !== undefined ? Math.round(value * 10) / 10 : '-'}
      </div>
    </div>
  );
};


const dynamicAccessor = (row: FlexibleJsonMapping, selectedPositionKey: string, property: string) => {
  const positionKeyToData = row[selectedPositionKey]
    ? selectedPositionKey
    : (selectedPositionKey === row['primary_position'] ? 'overall' : selectedPositionKey);

  return row[positionKeyToData] ? row[positionKeyToData][property] : null;
};


export const getSeasonColumns = (
  selectedPositionKey: string,
  primaryPosition: string,
  userConfig: UserConfig | null,
  seasonStatsToggles: FlexibleJsonMapping,
  competitions: CompetitionMap,
  hasSkillcorner: boolean,
) => {

  if (!userConfig) return [];

  const columns: FlexibleJsonMapping[] = [
    {
      Header:
        <div className='season-table-top-level-header-cell-sticky'>
          {staticLanguageMap['season'][userConfig.language]}

          <div
            className='season-table-top-level-header-cell-sticky-toggle-section'
            title={staticLanguageMap['showOnlyDomesticLeagues'][userConfig.language]}
            style={{ marginLeft: 63 }}
          >
            {staticLanguageMap['leagues'][userConfig.language]}
            <div className='season-table-top-level-header-cell-sticky-toggle'>
              <Toggle
                isToggled={seasonStatsToggles['showOnlyDomesticLeagues']}
                setIsToggled={(value: boolean) => updateSeasonStatsToggles(
                  { ...seasonStatsToggles, 'showOnlyDomesticLeagues': value },
                  userConfig.email,
                  userConfig.club)}
                isSmall={true}
              />
            </div>
          </div>

          <div
            className='season-table-top-level-header-cell-sticky-toggle-section'
            title={staticLanguageMap['showOnlySeasonsWith180Minutes'][userConfig.language]}
          >
            {staticLanguageMap['minutes'][userConfig.language]}
            <div className='season-table-top-level-header-cell-sticky-toggle'>
              <Toggle
                isToggled={seasonStatsToggles['showOnlySeasonsWith180Minutes'] ?? false}
                setIsToggled={(value: boolean) => updateSeasonStatsToggles(
                  { ...seasonStatsToggles, 'showOnlySeasonsWith180Minutes': value },
                  userConfig.email,
                  userConfig.club)}
                isSmall={true}
              />
            </div>
          </div>
        </div>,
      id: 'season',
      sticky: 'left',
      accessor: 'season',
      Cell: renderSeasonCell(competitions, userConfig.language, selectedPositionKey),
      width: 320,
      isStickyColumn: true,
    }
  ];

  const metricGroups = primaryPosition === 'GK'
    ? goalkeeperSeasonTableMetricGroups
    : hasSkillcorner
      ? { ...outfieldSeasonTableMetricGroups, ...{ 'Skillcorner': skillcornerStats } }
      : outfieldSeasonTableMetricGroups;

  Object.keys(metricGroups).forEach((metricGroupKey, index) => {
    const isFinalMetricGroup = index === Object.keys(metricGroups).length - 1;

    const topLevelCell = {
      Header:
        <div className={'season-table-top-level-header-cell' + (!isFinalMetricGroup ? ' season-table-top-level-header-cell-with-border' : '')}>
          {metricGroupKey}

          {metricGroupKey !== 'Ratings' && metricGroupKey !== 'OBV' && (
            <div className='season-table-top-level-header-cell-toggle' title={staticLanguageMap['showActualValues'][userConfig.language]}>
              <Toggle
                isToggled={seasonStatsToggles[metricGroupKey]}
                setIsToggled={(value: boolean) => updateSeasonStatsToggles(
                  { ...seasonStatsToggles, [metricGroupKey]: value },
                  userConfig.email,
                  userConfig.club)}
                isSmall={true}
              />
            </div>
          )}
        </div>,
      id: metricGroupKey,

      columns: metricGroups[metricGroupKey].map((metric, index) => {
        const isFinalSubMetric = !isFinalMetricGroup && index === metricGroups[metricGroupKey].length - 1;

        const metricDisplayName = metricToDisplayName[metric][userConfig.language];
        const metricTitle = metricToDisplayName[metric]['titles'][userConfig.language];

        const cell = {
          Header:
            <div className={'season-table-sub-level-header-cell' + (isFinalSubMetric ? ' season-table-sub-level-header-cell-with-border' : '')}>
              {metricDisplayName}
            </div>,
          id: metric,
          accessor: (row: FlexibleJsonMapping) => dynamicAccessor(row, selectedPositionKey, metric),
          Cell: metric === 'skill_rating'
            ? renderSeasonRatingOrLockCell(metric, selectedPositionKey, userConfig.isColorBlind)
            : renderSeasonRatingCell(metric, selectedPositionKey, metricGroupKey, seasonStatsToggles[metricGroupKey] ?? false, userConfig.isColorBlind),
          width: getMetricColumnWidth(metricDisplayName),
          title: metricTitle,
          isFinalSubMetric: isFinalSubMetric,
        };
        return cell;
      }),
    };

    columns.push(topLevelCell);
  });

  return columns;
};


export const getMetricColumnWidth = (displayName: string) => {
  return Math.max(displayName.length * 7 + 10.5, 50) + 25;
};
