import '../../platform.css';
import './ownTeam.css';

import { useState, useEffect, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { settingsState } from '../../recoil/atoms/settingsState';
import { teamsState } from '../../recoil/atoms/teamsState';
import { selectedPlayerState } from '../../recoil/atoms/selectedPlayerState';
import { playersState } from '../../recoil/atoms/playersState';
import { playerOverviewsState } from '../../recoil/atoms/playerOverviewsState';
import { FlexibleJsonMapping, PlayerEntry, PlayerMapping, PlayerOverviews } from '../../types';
import { AuthContextType, useAuthContext } from '../../../common/contexts/AuthContext';

import GroupsIcon from '@mui/icons-material/Groups';
import WindowOutlinedIcon from '@mui/icons-material/WindowOutlined';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import SchoolIcon from '@mui/icons-material/School';

// import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
// import ViewWeekOutlinedIcon from '@mui/icons-material/ViewWeekOutlined';
// import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
// import StarsIcon from '@mui/icons-material/Stars';
// import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
// import ViewListIcon from '@mui/icons-material/ViewList';
// import ReorderIcon from '@mui/icons-material/Reorder';
// import TableRowsIcon from '@mui/icons-material/TableRows';
// import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';

import { getAge, getSortDistanceRole } from '../../utils/playerUtils';
import { PlayerView } from '../../components/playerView/PlayerView';
import { FixedTabLine } from '../../components/tabLines/FixedTabLine';
import { positionOptionsPlatform } from '../../static/propertyValues';
import { OwnTeamMatrixView } from './OwnTeamMatrixView';
import { OwnTeamTableView } from './OwnTeamTableView';
import { useAddMultiplePlayerDetails } from '../../recoil/hooks/addPlayerDetails';
import { LogisticTeamView } from '../../components/teamView/LogisticTeamView';
import { getEuroToCurrency } from '../../utils/currencyUtils';


export const OwnTeam = () => {

  const { currentUser } = useAuthContext() as AuthContextType;

  const settings = useRecoilValue(settingsState);

  const selectedPlayer = useRecoilValue(selectedPlayerState);
  const players = useRecoilValue(playersState);
  const playerOverviews = useRecoilValue(playerOverviewsState);

  const addMultiplePlayerDetails = useAddMultiplePlayerDetails();
  const [isAllPlayerDetailsAdded, setIsAllPlayerDetailsAdded] = useState(false);

  const teams = useRecoilValue(teamsState);

  const [searchedPlayerData, setSearchedPlayerData] = useState<PlayerOverviews | undefined>(undefined);

  const [clubCountryCode, setClubCountryCode] = useState<string | undefined>(undefined);

  const [activeTab, setActiveTab] = useState(0);
  const [showFirstEleven, setShowFirstEleven] = useState(false);

  const tabOptions = [
    'playerSquad',
    'table',
    'matrix',
    'academy',
  ];

  const tabIcons = [
    <GroupsIcon key={'groups-icon'} style={{ fontSize: 21 }} />,
    <FormatListBulletedIcon key={'format-list-icon'} style={{ fontSize: 21 }} />,
    <WindowOutlinedIcon key={'window-icon'} style={{ fontSize: 21 }} />,
    <SchoolIcon key={'school-icon'} style={{ fontSize: 20.5 }} />,
  ];

  const [logisticTeamViewToggle, setLogisticTeamViewToggle] = useState(false);


  const addPlayerOverviewData = useCallback((player: FlexibleJsonMapping) => {
    if (playerOverviews && player.id in playerOverviews && clubCountryCode) {
      const numberId = Number(player.id);

      player['image_url'] = playerOverviews[numberId].image_url;
      player['skill_index'] = playerOverviews[numberId].overall?.skill_index;
      player['club_index'] = playerOverviews[numberId].overall?.club_index;
      player['primary_position'] = playerOverviews[numberId].primary_position;

      // values to be used, where user provided values have presedence
      player['birth_date'] = playerOverviews[numberId].birth_date;
      player['country_code'] = playerOverviews[numberId].country_code;
      player['contract_expiration'] = playerOverviews[numberId].contract_expiration;
      player['market_value'] = getEuroToCurrency(playerOverviews[numberId].market_value, clubCountryCode);

      // values that we have in our database
      player['birth_date_in_database'] = playerOverviews[numberId].birth_date;
      player['country_code_in_database'] = playerOverviews[numberId].country_code;
      player['contract_expiration_in_database'] = playerOverviews[numberId].contract_expiration;
      player['market_value_in_database'] = getEuroToCurrency(playerOverviews[numberId].market_value, clubCountryCode);
    }
  }, [clubCountryCode, playerOverviews]);


  // ownTeamConfig = {
  //     'ownTeamPlayers': map from playerId to data for all players in ownTeam,
  //     'academyTeamPlayers': map from playerId to data for all players in academyTeam,
  //     'bench': list of playerData for all players on ownTeam who are not in the starting eleven,
  // }
  const [ownTeamConfig, setOwnTeamConfig] = useState<FlexibleJsonMapping | undefined>(undefined);

  useEffect(() => {
    if (teams && players && playerOverviews) {

      const ownTeamPlayers: PlayerMapping = {};
      const academyTeamPlayers: PlayerMapping = {};
      const bench: FlexibleJsonMapping[] = [];

      positionOptionsPlatform.forEach(positionKey => {

        // ownTeam players
        if (teams['ownTeam'] && positionKey in teams['ownTeam']) {
          teams['ownTeam'][positionKey].forEach((playerEntry: PlayerEntry) => {

            let player: FlexibleJsonMapping = { ...playerEntry };

            addPlayerOverviewData(player);

            // data stored in players collection have presedence over data from playerOverviews
            let isStartingEleven = false;
            if (player.id in players) {
              player = { ...player, ...players[player.id] };
              isStartingEleven = players[player.id].isStartingEleven;
            }

            player['age'] = getAge(player['birth_date']);

            ownTeamPlayers[player.id] = player;

            if (!isStartingEleven) {
              bench.push(player);
            }
          });
        }

        // academy players
        if (teams['academyTeam'] && positionKey in teams['academyTeam']) {
          teams['academyTeam'][positionKey].forEach((playerEntry: PlayerEntry) => {

            let player: FlexibleJsonMapping = { ...playerEntry };

            addPlayerOverviewData(player);

            if (player.id in players) {
              player = { ...player, ...players[player.id] };
            }

            player['age'] = getAge(player['birth_date']);

            academyTeamPlayers[player.id] = player;
          });
        }
      });

      bench.sort((a: FlexibleJsonMapping, b: FlexibleJsonMapping) => getSortDistanceRole(a, b));

      const newOwnTeamConfig = {
        'ownTeamPlayers': ownTeamPlayers,
        'academyTeamPlayers': academyTeamPlayers,
        'bench': bench,
      };

      setOwnTeamConfig(newOwnTeamConfig);
    }
  }, [teams, players, playerOverviews, clubCountryCode]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (ownTeamConfig && !isAllPlayerDetailsAdded) {
      addMultiplePlayerDetails(Object.keys(ownTeamConfig['ownTeamPlayers']).map(Number), currentUser);
      setIsAllPlayerDetailsAdded(true);
    }
  }, [ownTeamConfig, currentUser]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (settings && !clubCountryCode) {
      setClubCountryCode(settings.clubSettings.countryCode);
    }
  }, [settings]); // eslint-disable-line react-hooks/exhaustive-deps


  const setTabAndResetExpansions = (tab: number) => {
    setActiveTab(tab);
    setLogisticTeamViewToggle(!logisticTeamViewToggle);
    setShowFirstEleven(false);
  };


  return (
    <div className='platform-view-section platform-view-section-dark'>

      <div className={'player-view-outer-container' + (selectedPlayer ? ' player-view-outer-container-player-selected' : '')}>
        {selectedPlayer && playerOverviews && (
          <PlayerView
            playerOverview={playerOverviews[Number(selectedPlayer.id)] ?? (searchedPlayerData ? searchedPlayerData[Number(selectedPlayer.id)] : undefined)}
          />
        )}
      </div>

      <FixedTabLine
        tabOptions={tabOptions}
        activeTab={activeTab}
        setActiveTab={setTabAndResetExpansions}
        tabIcons={tabIcons}
      />

      {activeTab === 0 && teams && ownTeamConfig && (
        <LogisticTeamView
          teamOrSquadId={'ownTeam'}
          teamOrSquad={teams['ownTeam']}
          isTabLine={true}
          playerData={searchedPlayerData}
          setPlayerData={setSearchedPlayerData}

          showFirstEleven={showFirstEleven}
          setShowFirstEleven={setShowFirstEleven}
          benchPlayers={ownTeamConfig['bench']}
        />
      )}

      {activeTab === 1 && ownTeamConfig && (
        <OwnTeamTableView
          ownTeamPlayers={ownTeamConfig['ownTeamPlayers']}
          academyTeamPlayers={ownTeamConfig['academyTeamPlayers']}
        />
      )}

      {activeTab === 2 && ownTeamConfig && (
        <OwnTeamMatrixView
          ownTeamPlayers={ownTeamConfig['ownTeamPlayers']}
        />
      )}

      {activeTab === 3 && teams && (
        <LogisticTeamView
          teamOrSquadId={'academyTeam'}
          teamOrSquad={teams['academyTeam']}
          isTabLine={true}
          playerData={searchedPlayerData}
          setPlayerData={setSearchedPlayerData}
        />
      )}

    </div>
  );
};
