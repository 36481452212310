import { FlexibleJsonMapping } from '../types';
import {
  formationHasBackFive,
  formationHasBackThree,
  formationHasCAM,
  formationHasCDM,
  formationHasOneStriker,
  formationHasTwoStrikers,
  formationHasWideMidfielders,
  formationHasWingers
} from './formationUtils';


const getPositionData = (positionKey: string, teamData: FlexibleJsonMapping) => {
  return {
    'position': positionKey,
    'players': teamData[positionKey] ?? [],
  };
};

const getDefendersArray = (teamData: FlexibleJsonMapping, formation: string) => {
  if (formationHasBackThree(formation)) {
    return [
      getPositionData('LCB', teamData),
      getPositionData('CB', teamData),
      getPositionData('RCB', teamData)
    ];
  }

  if (formationHasBackFive(formation)) {
    return [
      getPositionData('LWB', teamData),
      getPositionData('LCB', teamData),
      getPositionData('CB', teamData),
      getPositionData('RCB', teamData),
      getPositionData('RWB', teamData)
    ];
  }

  return [
    getPositionData('LB', teamData),
    getPositionData('LCB', teamData),
    getPositionData('RCB', teamData),
    getPositionData('RB', teamData)
  ];
};

const getMidfieldersArray = (teamData: FlexibleJsonMapping, formation: string) => {

  let midfielders: FlexibleJsonMapping[] = [];

  // Central midfielders
  if (formationHasCDM(formation)) {
    midfielders = [
      getPositionData('LCM', teamData),
      getPositionData('CDM', teamData),
      getPositionData('RCM', teamData)
    ];
  }
  else if (formationHasCAM(formation)) {
    midfielders = [
      getPositionData('LCM', teamData),
      getPositionData('CAM', teamData),
      getPositionData('RCM', teamData)
    ];
  }
  else {
    midfielders = [
      getPositionData('LCM', teamData),
      getPositionData('RCM', teamData)
    ];
  }

  // Wide midfielders
  if (formationHasWideMidfielders(formation)) {
    midfielders = [
      getPositionData('LM', teamData),
      ...midfielders,
      getPositionData('RM', teamData)
    ];
  }

  return midfielders;
};


const getAttackersArray = (teamData: FlexibleJsonMapping, formation: string) => {

  let attackers: FlexibleJsonMapping[] = [];

  // Middle attackers, if any
  if (formationHasOneStriker(formation)) {
    attackers = [
      getPositionData('S', teamData)
    ];
  }
  else if (formationHasCAM(formation) && formationHasCDM(formation)) {
    attackers = [
      getPositionData('CAM', teamData)
    ];
  }

  // Wide attackers, if any
  if (formationHasWingers(formation)) {
    attackers = [
      getPositionData('LW', teamData),
      ...attackers,
      getPositionData('RW', teamData)
    ];
  }
  else if (formationHasTwoStrikers(formation)) {
    attackers = [
      getPositionData('LS', teamData),
      ...attackers,
      getPositionData('RS', teamData)
    ];
  }

  return attackers;
};

// Gets the players on a team as a nested array based on formation
export const getTeamViewData = (teamData: FlexibleJsonMapping, formation: string) => {

  // If formation has both CDM and CAM, CAM will be part of the attackers
  // This currently assumes no formation has CDM, CAM and 3 other attackers (wingers/striker)
  return [
    getAttackersArray(teamData, formation),
    getMidfieldersArray(teamData, formation),
    getDefendersArray(teamData, formation),
    [getPositionData('GK', teamData)],
  ];
};
