import './../modals.css';

import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

import { staticLanguageMap } from '../../../../common/static/staticLanguageMap';


interface ConfirmModalProps {
  confirm: () => void;
  cancel: () => void;
  confirmTitleKey: string;
  confirmInfoKey?: string;
  confirmInfo?: string; // should maybe change pattern from passing keys to passing the text directly
  confirmKey?: string;
  cancelKey?: string;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  confirm,
  cancel,
  confirmTitleKey,
  confirmInfoKey,
  confirmInfo,
  confirmKey,
  cancelKey
}) => {

  const userConfig = useRecoilValue(userConfigState);

  return (
    <div className='modal-root-container confirm-modal-root'>

      <div className='modal-root-title'>
        {userConfig ? staticLanguageMap[confirmTitleKey][userConfig.language] : ''}
      </div>

      <div className='modal-divider modal-root-title-divider'>&nbsp;</div>

      <div className='confirm-modal-info'>
        {userConfig && !confirmInfo && confirmInfoKey && staticLanguageMap[confirmInfoKey][userConfig.language]}
        {userConfig && confirmInfo}
      </div>

      <div className='confirm-modal-row'>
        <div className='modal-button confirm-modal-button' onClick={() => confirm()}>
          <div className='modal-button-text'>
            {userConfig ? staticLanguageMap[confirmKey ?? 'confirm'][userConfig.language] : ''}
          </div>

          <CheckIcon
            style={{ fontSize: 22 }}
          />
        </div>

        <div className='modal-button confirm-modal-button' onClick={() => cancel()}>
          <div className='modal-button-text'>
            {userConfig ? staticLanguageMap[cancelKey ?? 'cancel'][userConfig.language] : ''}
          </div>

          <CloseIcon
            style={{ fontSize: 22 }}
          />
        </div>
      </div>

    </div>
  );
};
