import { useCallback, useEffect, useState } from 'react';

import { FlexibleJsonMapping, JsonMapping } from '../../types';
import { tierColors } from '../../views/home/Home';


// x, y
type HomeViewLinePoint = [number, number];


interface HomeViewLinePlotProps {
  data: FlexibleJsonMapping; // mapping from club or a competiiton id to data property (xGCretaed, ...) to a list of data points [date, value]
  activeTab: number;
  clubColor: string;
  competitionIdToTierMap: FlexibleJsonMapping;
}


export const HomeViewLinePlot: React.FC<HomeViewLinePlotProps> = ({
  data,
  activeTab,
  clubColor,
  competitionIdToTierMap,
}) => {

  const positiveColor = '#6abe88';
  const negativeColor = '#c77070';

  // const [linePoints, setLinePoints] = useState<HomeViewLinePointMapping>({});
  const [linePaths, setLinePaths] = useState<JsonMapping>({});
  const [areaPaths, setAreaPaths] = useState<JsonMapping>({});

  const [xAxisIntervals, setXAxisIntervals] = useState<FlexibleJsonMapping>({}); // mapping from year to start and end and mid x-values of the year-interval
  const [yValues, setYValues] = useState<number[]>([]);


  const getDaysSinceStartDate = (date: Date, startDate: Date) => {
    const diffInMs = date.getTime() - startDate.getTime();
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

    return Math.floor(diffInDays);
  };


  const createPathData = (points: HomeViewLinePoint[]) => {

    // need at least two points to draw a curve
    if (points.length < 2) {
      return { lPath: '', aPath: '' };
    }

    function catmullRomToBezier(p0: HomeViewLinePoint, p1: HomeViewLinePoint, p2: HomeViewLinePoint, p3: HomeViewLinePoint, tension: number): string {
      const cp1x = p1[0] + (p2[0] - p0[0]) / 6 * tension;
      const cp1y = p1[1] + (p2[1] - p0[1]) / 6 * tension;
      const cp2x = p2[0] - (p3[0] - p1[0]) / 6 * tension;
      const cp2y = p2[1] - (p3[1] - p1[1]) / 6 * tension;

      return ` C ${cp1x},${cp1y}, ${cp2x},${cp2y}, ${p2[0]},${p2[1]}`;
    }

    let line = `M ${points[0][0]},${points[0][1]}`;

    for (let i = 0; i < points.length - 1; i++) {
      const p0 = i === 0 ? points[0] : points[i - 1];
      const p1 = points[i];
      const p2 = points[i + 1];
      const p3 = i === points.length - 2 ? points[points.length - 1] : points[i + 2];

      line += catmullRomToBezier(p0, p1, p2, p3, 0.9);
    }

    const areaLine = line + ` L ${points[points.length - 1][0]},470 L ${points[0][0]},470 Z`;

    return { lPath: line, aPath: areaLine };
  };


  const setYValuesWithRounding = (maxY: number, stepSize: number) => {

    const isInteger = (num: number) => num % 1 === 0;

    const formatNumber = (num: number) => isInteger(num) ? num : parseFloat(num.toFixed(2));

    const yValues = [
      formatNumber(maxY),
      formatNumber(maxY - stepSize),
      formatNumber(maxY - 2 * stepSize),
      formatNumber(maxY - 3 * stepSize),
      formatNumber(maxY - 4 * stepSize),
    ];
    setYValues(yValues);
  };


  const getAndSetYRange = useCallback((maxYFromData: number) => {
    let maxY = 0;
    let stepSize = 0;

    if (activeTab === 0) {
      maxY = 4;
      stepSize = 1;
      setYValuesWithRounding(maxY, stepSize);
    }

    else if (activeTab === 1) {
      if (maxYFromData > 29) {
        maxY = 30;
        stepSize = 1;
        setYValuesWithRounding(maxY, stepSize);
      }
      else if (maxYFromData > 28) {
        maxY = 29;
        stepSize = 1;
        setYValuesWithRounding(maxY, stepSize);
      }
      else if (maxYFromData < 26) {
        maxY = 27;
        stepSize = 1;
        setYValuesWithRounding(maxY, stepSize);
      }
      else {
        maxY = 28;
        stepSize = 1;
        setYValuesWithRounding(maxY, stepSize);
      }
    }

    else if (activeTab === 2) {
      if (maxYFromData < 20) {
        maxY = 20;
        stepSize = 4;
        setYValuesWithRounding(maxY, stepSize);
      }
      else if (maxYFromData < 25) {
        maxY = 25;
        stepSize = 5;
        setYValuesWithRounding(maxY, stepSize);
      }
      else if (maxYFromData < 0.35) {
        maxY = 35;
        stepSize = 7;
        setYValuesWithRounding(maxY, stepSize);
      }
      else if (maxYFromData < 50) {
        maxY = 50;
        stepSize = 10;
        setYValuesWithRounding(maxY, stepSize);
      }
      else {
        maxY = 75;
        stepSize = 15;
        setYValuesWithRounding(maxY, stepSize);
      }
    }

    const minY = maxY - (5 * stepSize);
    return { minY, maxY };
  }, [activeTab]);


  const getTransformedYValue = useCallback((value: number) => {
    if (activeTab === 2) {
      return value * 100;
    }
    return value;
  }, [activeTab]);


  useEffect(() => {

    const propertyKey = Object.keys(data)[activeTab];
    const startDate = propertyKey === 'xGData'
      ? new Date(data[propertyKey]['xGCreated'][0].date)
      : new Date(data[propertyKey]['club'][0].date);
    const endDate = propertyKey === 'xGData'
      ? new Date(data[propertyKey]['xGCreated'][data['xGData']['xGCreated'].length - 1].date)
      : new Date(data[propertyKey]['club'][data[propertyKey]['club'].length - 1].date);

    // calculate units on x-axis
    const totalDaysSinceStartDate = getDaysSinceStartDate(endDate, startDate);
    const xUnitPerDay = 760 / totalDaysSinceStartDate;

    // set x-axis labels
    const xAxisIntervals: FlexibleJsonMapping = {}; // mapping from year to start and end x-values of the year-interval
    const startYear = startDate.getFullYear();
    const endYear = endDate.getFullYear();

    let previousX = 140;
    if (endYear > startYear) {
      for (let currentYear = startYear; currentYear <= endYear - 1; currentYear++) {
        const nextX = 140 + (getDaysSinceStartDate(new Date(currentYear, 11, 31), startDate) * xUnitPerDay);
        const centerX = previousX + ((nextX - previousX) / 2);
        xAxisIntervals[String(currentYear)] = {
          start: previousX,
          center: centerX,
          end: nextX,
        };
        previousX = nextX;
      }
    }

    // small offset in case a new data point is at the start of the next year
    if (previousX < 888) {
      const finalX = 900;
      xAxisIntervals[String(endYear)] = {
        start: previousX,
        center: previousX + ((finalX - previousX) / 2),
        end: finalX,
      };
    }
    setXAxisIntervals(xAxisIntervals);

    // calculate min and max y values to define the y-axis range
    // var minY = Infinity;
    let maxYFromData = -Infinity;
    if (activeTab !== 0 && data[propertyKey]['club']) {
      data[propertyKey]['club'].forEach((entry: FlexibleJsonMapping) => {
        // minY = Math.min(minY, entry.value);
        maxYFromData = Math.max(maxYFromData, entry.value);
      });
    }

    const transformedMaxY = getTransformedYValue(maxYFromData);
    const { minY, maxY } = getAndSetYRange(transformedMaxY);

    // calculate coordinates of data points
    const points: FlexibleJsonMapping = {};
    Object.entries(data[propertyKey]).forEach(([key, keyData]: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any

      const keyPoints: HomeViewLinePoint[] = [];
      for (let i = 0; i < keyData.length; i++) {
        const date = new Date(keyData[i].date);
        const daysSinceStartDate = getDaysSinceStartDate(date, startDate);
        const x = 140 + (daysSinceStartDate * xUnitPerDay);

        const value = getTransformedYValue(keyData[i].value);
        let y = 470 - ((value - minY) / (maxY - minY)) * 370;

        y = Math.min(464, y); // hack to avoid the line to go below the x-axis due to the curve

        keyPoints.push([x, y]);
      }
      points[key] = keyPoints;
    });

    // calculate line paths
    const linePathData: JsonMapping = {};
    const areaPathData: JsonMapping = {};
    Object.entries(points).forEach(([key, keyPoints]) => {
      const { lPath, aPath } = createPathData(keyPoints);
      linePathData[key] = lPath;
      areaPathData[key] = aPath;
    });

    // setLinePoints(points);
    setLinePaths(linePathData);
    setAreaPaths(areaPathData);

  }, [data, activeTab, getTransformedYValue, getAndSetYRange]);


  const getXAxisColor = (year: string) => {
    if (Number(year) % 2 === 0) {
      return '#ffffff';
    }
    return '#aaccff';
  };


  // x-axis ranges from 140 (January 1st, 2019) to 900 (current date)  ->  760 units
  // y-axis ranges from 100 (clubIndex = 10) to 470 (clubIndex = 0)    ->  370 units

  return (
    <svg className='player-view-svg-plot' viewBox={'0 0 1000 575'} preserveAspectRatio={'xMidYMid meet'}>

      <defs>
        <linearGradient id='home-view-line-plot-area-club' x1='0%' y1='0%' x2='0%' y2='100%'>
          <stop offset='0%' style={{ stopColor: clubColor, stopOpacity: 0.12 }} />
          <stop offset='100%' style={{ stopColor: clubColor, stopOpacity: 0.02 }} />
        </linearGradient>
        <linearGradient id='home-view-line-plot-area-tier-1' x1='0%' y1='0%' x2='0%' y2='100%'>
          <stop offset='0%' style={{ stopColor: tierColors['1'], stopOpacity: 0.08 }} />
          <stop offset='100%' style={{ stopColor: tierColors['1'], stopOpacity: 0.00 }} />
        </linearGradient>
        <linearGradient id='home-view-line-plot-area-tier-2' x1='0%' y1='0%' x2='0%' y2='100%'>
          <stop offset='0%' style={{ stopColor: tierColors['2'], stopOpacity: 0.08 }} />
          <stop offset='100%' style={{ stopColor: tierColors['2'], stopOpacity: 0.00 }} />
        </linearGradient>
        <linearGradient id='home-view-line-plot-area-positive' x1='0%' y1='0%' x2='0%' y2='100%'>
          <stop offset='0%' style={{ stopColor: positiveColor, stopOpacity: 0.12 }} />
          <stop offset='100%' style={{ stopColor: positiveColor, stopOpacity: 0.02 }} />
        </linearGradient>
        <linearGradient id='home-view-line-plot-area-negative' x1='0%' y1='0%' x2='0%' y2='100%'>
          <stop offset='0%' style={{ stopColor: negativeColor, stopOpacity: 0.12 }} />
          <stop offset='100%' style={{ stopColor: negativeColor, stopOpacity: 0.02 }} />
        </linearGradient>
      </defs>

      {/* <rect width='1000' height='575' style={{ fill: 'none', stroke: '#ffffff88', strokeWidth: 4 }} /> */}

      {/* Labels */}
      {/* <rect width='900' height='40' x='50' style={{ fill: 'none', stroke: '#ffffff22', strokeWidth: 4 }} /> */}

      {/* x-axis */}
      <line x1='139.6' y1='470' x2='900' y2='470' style={{ stroke: '#8c90a1', strokeWidth: 1 }} />

      {/* y-axis */}
      <line x1='140' y1='100' x2='140' y2='470' style={{ stroke: '#8c90a1', strokeWidth: 1 }} />

      {/* x-axis labels */}
      {Object.entries(xAxisIntervals).map(([year, interval]) => {
        return (
          <g key={year}>
            {interval.start !== 140 && <line x1={interval.start} y1='470' x2={interval.start} y2='485' style={{ stroke: '#8c90a1', strokeWidth: 1 }} />}
            <line x1={interval.start} y1='470' x2={interval.end} y2='470' style={{ stroke: getXAxisColor(year), strokeWidth: 1 }} />
            <text x={interval.center} y='510' textAnchor='middle' fill={'#ffffffaa'} fontSize={24} fontFamily=''>{year}</text>
          </g>
        );
      })}


      {/* y-axis labels and value lines */}
      <line x1='140' y1='100' x2='900' y2='100' style={{ stroke: '#ffffff22', strokeWidth: 1 }} />
      <line x1='125' y1='100' x2='140' y2='100' style={{ stroke: '#8c90a1', strokeWidth: 1 }} />
      <text x='100' y='108' textAnchor='end' fill='#ffffffaa' fontSize={24} fontFamily=''>{yValues.length > 0 ? yValues[0] : ''}</text>

      <line x1='140' y1='174' x2='900' y2='174' style={{ stroke: '#ffffff22', strokeWidth: 1 }} />
      <line x1='125' y1='174' x2='140' y2='174' style={{ stroke: '#8c90a1', strokeWidth: 1 }} />
      <text x='100' y='182' textAnchor='end' fill='#ffffffaa' fontSize={24} fontFamily=''>{yValues.length > 0 ? yValues[1] : ''}</text>

      <line x1='140' y1='248' x2='900' y2='248' style={{ stroke: '#ffffff22', strokeWidth: 1 }} />
      <line x1='125' y1='248' x2='140' y2='248' style={{ stroke: '#8c90a1', strokeWidth: 1 }} />
      <text x='100' y='256' textAnchor='end' fill='#ffffffaa' fontSize={24} fontFamily=''>{yValues.length > 0 ? yValues[2] : ''}</text>

      <line x1='140' y1='322' x2='900' y2='322' style={{ stroke: '#ffffff22', strokeWidth: 1 }} />
      <line x1='125' y1='322' x2='140' y2='322' style={{ stroke: '#8c90a1', strokeWidth: 1 }} />
      <text x='100' y='330' textAnchor='end' fill='#ffffffaa' fontSize={24} fontFamily=''>{yValues.length > 0 ? yValues[3] : ''}</text>

      <line x1='140' y1='396' x2='900' y2='396' style={{ stroke: '#ffffff22', strokeWidth: 1 }} />
      <line x1='125' y1='396' x2='140' y2='396' style={{ stroke: '#8c90a1', strokeWidth: 1 }} />
      <text x='100' y='404' textAnchor='end' fill='#ffffffaa' fontSize={24} fontFamily=''>{yValues.length > 0 ? yValues[4] : ''}</text>


      {/* line area path */}
      {Object.entries(areaPaths).map(([key, path]) => (
        <path
          key={key}
          d={path}
          style={{
            fill: key === 'club'
              ? 'url(#home-view-line-plot-area-club)'
              : key === 'xGCreated'
                ? 'url(#home-view-line-plot-area-positive)'
                : key === 'xGConceded'
                  ? 'url(#home-view-line-plot-area-negative)'
                  : key in competitionIdToTierMap
                    ? `url(#home-view-line-plot-area-tier-${competitionIdToTierMap[key]})`
                    : '',
            stroke: 'none'
          }} />
      ))}

      {/* line paths */}
      {Object.entries(linePaths).map(([key, path]) => (
        <path
          key={key}
          d={path}
          style={{
            fill: 'none',
            stroke: key === 'club'
              ? clubColor
              : key === 'xGCreated'
                ? positiveColor
                : key === 'xGConceded'
                  ? negativeColor
                  : tierColors[competitionIdToTierMap[key]],
            strokeWidth: 3
          }} />
      ))}

    </svg>
  );
};
