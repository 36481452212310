import './input.css';

import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import { staticLanguageMap } from '../../../common/static/staticLanguageMap';


interface SeasonDropDownProps {
  id: string;
  seasonOptions: string[];
  selectedSeasons: string[];
  setSelectedSeasons: (value: string[]) => void;
  selectedSeasonString: string;
  setSelectedSeasonString: (value: string) => void;
  isDropDownExpanded: boolean;
  setIsDropDownExpanded: (value: boolean) => void;
  defaultDropDownText: string;
  defaultDropDownTextColor: string;
  marginBetweenOptions: number;
  emptyBackgroundId?: string;
  maxHeight?: string;
}

export const SeasonDropDown: React.FC<SeasonDropDownProps> = ({
  id,
  seasonOptions,
  selectedSeasons,
  setSelectedSeasons,
  selectedSeasonString,
  setSelectedSeasonString,
  isDropDownExpanded,
  setIsDropDownExpanded,
  defaultDropDownText,
  defaultDropDownTextColor,
  marginBetweenOptions,
  emptyBackgroundId,
  maxHeight,
}) => {

  const userConfig = useRecoilValue(userConfigState);

  const [topOptionWasJustClicked, setTopOptionWasJustClicked] = useState(false);


  const handleDropDownClick = () => {
    if (isDropDownExpanded) {
      removeDropDownExpansion();
    }

    else {
      expandDropDown();
    }
  };


  const handleOptionSelect = (option: string, isOptionAlreadySelected: boolean) => {

    if (option === 'allSeasons') {
      setSelectedSeasons(isOptionAlreadySelected ? [] : seasonOptions);
      setTopOptionWasJustClicked(true);
      setTimeout(() => {
        setTopOptionWasJustClicked(false);
      }, 25);
      return;
    }

    const newSelectedOptions = selectedSeasons.slice();

    if (isOptionAlreadySelected) {
      const i = newSelectedOptions.indexOf(option);
      newSelectedOptions.splice(i, 1);
    }
    else {
      newSelectedOptions.push(option);
    }

    setSelectedSeasons(newSelectedOptions);
  };


  const expandDropDown = () => {
    // zIndex handling is not ideal, but must be handlded carefully due to interactions between multiple drop downs
    let element = document.getElementById(id);
    if (element) {
      element.style.transition = '150ms';
      element.style.height = 'auto';
      element.style.zIndex = '110';
    }

    if (emptyBackgroundId) {
      element = document.getElementById(emptyBackgroundId);
      if (element) {
        element.style.transition = '150ms';
        element.style.zIndex = '100';
        element.style.backgroundImage = 'linear-gradient(to bottom, #00000000, #00000012)';
      }
    }

    setIsDropDownExpanded(true);
  };


  const removeDropDownExpansion = () => {
    let element = document.getElementById(id);
    if (element) {
      element.style.transition = '75ms';
      element.style.height = '26px';
      element.style.zIndex = '1';
    }

    if (emptyBackgroundId) {
      element = document.getElementById(emptyBackgroundId);
      if (element) {
        element.style.transition = '75ms';
        element.style.zIndex = '-1';
        element.style.backgroundImage = 'linear-gradient(to bottom, #00000000, #00000000)';
      }
    }

    setIsDropDownExpanded(false);
  };


  useEffect(() => {
    if (!isDropDownExpanded) {
      removeDropDownExpansion();
    }
  }, [isDropDownExpanded]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (seasonOptions.length > 0) {
      if (selectedSeasons.length === seasonOptions.length) {
        setSelectedSeasonString('allSeasons');
      }
      else if (selectedSeasons.length > 0) {
        setSelectedSeasonString('someSeasons');
      }
    }
  }, [selectedSeasons, setSelectedSeasonString]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div
      className={'drop-down-select-container' + ((isDropDownExpanded || selectedSeasons.length > 0) ? ' drop-down-select-container-focus' : '')}
      id={id}
      style={{ height: 26, maxHeight: maxHeight }}
    >

      <div
        className='drop-down-select-button'
        style={{ height: 26 }}
        onClick={() => handleDropDownClick()}>

        {selectedSeasons.length > 0 && <div className='drop-down-select-button-overlay'>&nbsp;</div>}

        <div className='drop-down-select-button-text'>
          {selectedSeasons.length === 0 ? (
            <div style={{ color: defaultDropDownTextColor }}>
              {userConfig ? staticLanguageMap[defaultDropDownText][userConfig.language] : ''}
            </div>
          ) : (
            <div style={{ color: '#000000' }} className={selectedSeasonString !== 'someSeasons' ? 'fade-in' : undefined}>
              {!topOptionWasJustClicked && userConfig && staticLanguageMap[selectedSeasonString]
                ? staticLanguageMap[selectedSeasonString][userConfig.language]
                : ''}
            </div>
          )}
        </div>

        {!isDropDownExpanded && (
          <div className='drop-down-select-icon' style={{ marginTop: 1 }}>
            <ArrowDropDownIcon style={{ fontSize: 24 }} />
          </div>
        )}

        {isDropDownExpanded && (
          <div className='drop-down-select-icon' style={{ marginTop: 1 }}>
            <ArrowDropUpIcon style={{ fontSize: 24 }} />
          </div>
        )}
      </div>

      <div className='drop-down-select-space'>&nbsp;</div>

      {seasonOptions.length === 0 && (
        <div className='drop-down-select-message'>
          {userConfig ? staticLanguageMap['noOptionsAvailable'][userConfig.language] : ''}
        </div>
      )}

      {seasonOptions.length > 0 && (
        <div>
          <div
            className={'drop-down-select-option' + (selectedSeasons.length === seasonOptions.length ? ' drop-down-select-option-selected' : '')}
            style={{ marginTop: marginBetweenOptions }}
            onClick={() => handleOptionSelect('allSeasons', selectedSeasons.length === seasonOptions.length)}
          >
            {userConfig ? staticLanguageMap['allSeasons'][userConfig.language] : ''}
          </div>

          <div
            className={'drop-down-select-options' + (maxHeight ? ' drop-down-select-options-scroll' : '')}
            style={{ maxHeight: maxHeight }}>
            {seasonOptions.map(season => {

              const isSelected = selectedSeasons.includes(season);

              return (
                <div
                  className={'drop-down-select-option' + (isSelected ? ' drop-down-select-option-selected' : '')}
                  style={{ marginTop: marginBetweenOptions }}
                  key={season}
                  onClick={() => handleOptionSelect(season, isSelected)}
                >
                  {season}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
