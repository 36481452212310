import '../tables.css';

import { useRecoilValue } from 'recoil';
import { playerTeamDataState } from '../../../recoil/selectors/playerTeamDataState';
import { competitionsState } from '../../../recoil/atoms/competitionsState';

import { CellProps } from 'react-table';
import { FlexibleJsonMapping, PlayerId } from '../../../types';
import { getDateLocaleFormat, getShortPlayerName } from '../../../utils/playerUtils';
import { staticLanguageMap } from '../../../../common/static/staticLanguageMap';
import { countryCodeToCountryInfo } from '../../../static/countries';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { LazyImage } from '../../../../common/components/LazyImage';
import { getTeamIcon } from '../../../utils/iconUtils';
import { ReactNode } from 'react';


const InfoCell: React.FC<CellProps<FlexibleJsonMapping, FlexibleJsonMapping>> = ({ row }) => {

  const playerTeamData = useRecoilValue(playerTeamDataState);
  const competitions = useRecoilValue(competitionsState);

  const competitionName = competitions[row.original.club?.current_competition_id]?.name;

  let currentTeamIcon: JSX.Element | undefined = undefined;
  const playerId: PlayerId = row.original['id'];
  if (playerTeamData && playerId in playerTeamData) {
    const currentTeam = playerTeamData[playerId].currentTeam;
    currentTeamIcon = getTeamIcon(currentTeam, undefined, 18);
  }

  return (
    <div className='player-table-info-cell'>

      <LazyImage
        src={row.original.image_url ?? 'https://media.api-sports.io/football/players/0.png'}
        alt=''
        imageClassName='player-table-info-cell-image'
        imageContainerClassName='player-table-info-cell-image-container'
      />

      <div className='player-table-info-cell-column'>

        <div className='player-table-info-cell-row'>
          {row.original.fullname.length > 25 ? getShortPlayerName(row.original.fullname) : row.original.fullname}

          {row.original.country_code in countryCodeToCountryInfo && (
            <img
              className='player-table-info-cell-flag'
              src={countryCodeToCountryInfo[row.original.country_code]?.flagUrl}
              alt=''
              draggable={false}
            />
          )}
        </div>

        {row.original.club && competitionName && (
          <div>
            <div className='player-table-info-cell-info'>
              {row.original.club?.logo_url && (
                <div className='player-table-club-logo-container'>
                  <img
                    className='player-table-club-logo'
                    src={row.original.club?.logo_url}
                    alt=''
                    draggable={false}
                  />
                </div>
              )}
              <div className='player-table-info-cell-info-text'>
                {row.original.club?.name}
              </div>
            </div>

            <div className='player-table-info-cell-info'>
              <div className='player-table-info-cell-info-text'>
                {competitionName}
              </div>
            </div>
          </div>
        )}

      </div>

      {currentTeamIcon && (
        <div className='player-table-info-cell-icon-container'>
          <div className='player-table-info-cell-icon'>
            {currentTeamIcon}
          </div>
        </div>
      )}
    </div>
  );
};


const PlayerFindingInfoCell: React.FC<CellProps<FlexibleJsonMapping, FlexibleJsonMapping>> = ({ row }) => {

  const playerTeamData = useRecoilValue(playerTeamDataState);

  let currentTeamIcon: JSX.Element | undefined = undefined;
  const playerId: PlayerId = row.original['id'];
  if (playerTeamData && playerId in playerTeamData) {
    const currentTeam = playerTeamData[playerId].currentTeam;
    currentTeamIcon = getTeamIcon(currentTeam, undefined, 18);
  }

  return (
    <div className='player-table-info-cell'>

      <LazyImage
        src={row.original.image_url ?? 'https://media.api-sports.io/football/players/0.png'}
        alt=''
        imageClassName='player-table-info-cell-image'
        imageContainerClassName='player-table-info-cell-image-container'
      />

      <div className='player-table-info-cell-row'>
        {row.original.fullname.length > 25 ? getShortPlayerName(row.original.fullname) : row.original.fullname}

        {row.original.country_code in countryCodeToCountryInfo && (
          <img
            className='player-table-info-cell-flag'
            src={countryCodeToCountryInfo[row.original.country_code]?.flagUrl}
            alt=''
            draggable={false}
          />
        )}
      </div>

      {currentTeamIcon && (
        <div className='player-table-info-cell-icon-container'>
          <div className='player-table-info-cell-icon'>
            {currentTeamIcon}
          </div>
        </div>
      )}

    </div>
  );
};


const CompetitionCell: React.FC<CellProps<FlexibleJsonMapping, FlexibleJsonMapping>> = ({ row }) => {
  const competitions = useRecoilValue(competitionsState);

  const competitionName = competitions[row.original.competition_id]?.name;
  const competitionCountryCode = competitions[row.original.competition_id]?.countryCode;

  return (
    <div className='player-table-cell'>
      {competitionCountryCode && competitionCountryCode in countryCodeToCountryInfo && (
        <img
          className='simple-player-table-info-cell-flag'
          src={countryCodeToCountryInfo[competitionCountryCode]?.flagUrl}
          alt=''
          draggable={false}
        />
      )}
      {competitionName ?? '-'}
    </div>
  );
};


const ClubCell: React.FC<CellProps<FlexibleJsonMapping, FlexibleJsonMapping>> = ({ row }) => {
  const clubName = row.original.club?.name;
  const clubLogoUrl = row.original.club?.logo_url;

  if (!clubName || !clubLogoUrl) return <div className='player-table-cell'>-</div>;

  return (
    <div className='player-table-cell'>
      <div className='player-table-club-logo-container' style={{ width: 18, marginRight: 7 }}>
        <img
          className='find-player-table-club-logo'
          style={{ height: 16, marginTop: 1 }}
          src={clubLogoUrl}
          alt=''
          draggable={false}
        />
      </div>
      {clubName}
    </div>
  );
};


const TextCell: React.FC<CellProps<FlexibleJsonMapping, ReactNode>> = ({ value }) => {
  return <div className='player-table-cell'>{value ?? '-'}</div>;
};


const RatingCell: React.FC<CellProps<FlexibleJsonMapping, ReactNode>> = ({ value }) => {
  return <div className='player-table-cell'>{value !== undefined && value !== null && typeof value === 'number' ? (Math.round(value * 10) / 10) : '-'}</div>;
};


const PrecisionCell: React.FC<CellProps<FlexibleJsonMapping, ReactNode>> = ({ value }) => {
  return <div className='player-table-cell'>{value !== undefined && value !== null && typeof value === 'number' ? Math.round(value * 100) : '-'}</div>;
};


const renderContractCell = () => {
  const ContractCellRenderer = ({ row }: FlexibleJsonMapping) => {
    return <ContractCell row={row} />;
  };

  ContractCellRenderer.displayName = 'ContractCellRenderer';
  return ContractCellRenderer;
};

const ContractCell: React.FC<{ row: FlexibleJsonMapping }> = ({ row }) => {
  const value = row.original.contract_expiration;
  return <div className='player-table-cell'>{getDateLocaleFormat(value)}</div>;
};


const renderPositionCell = (language: string) => {
  const PositionCellRenderer = ({ row }: FlexibleJsonMapping) => {
    return <PositionCell row={row} language={language} />;
  };

  PositionCellRenderer.displayName = 'PositionCellRenderer';
  return PositionCellRenderer;
};


const PositionCell: React.FC<{ row: FlexibleJsonMapping; language: string; }> = ({ row, language }) => {
  let position = row.original.primary_position;

  if (!(position in staticLanguageMap)) return <div className='player-table-cell'>-</div>;

  position = staticLanguageMap[position][language];

  if (row.original.secondary_position in staticLanguageMap) {
    position += ` (${staticLanguageMap[row.original.secondary_position][language]})`;
  }

  return <div className='player-table-cell'>{position}</div>;
};


const HeightCell: React.FC<CellProps<FlexibleJsonMapping, ReactNode>> = ({ value }) => {
  return <div className='player-table-cell'>{value ? value + ' cm' : '-'}</div>;
};


export const getPlayerColumns = (
  tableWidth: number,
  language: string,
  currentChapter: number,
  hitsPerChapter: number,
  totalHits: number,
  handleChangeCurrentChapter?: (isIncrement: boolean) => void,
  isFindPlayerView?: boolean,
  isSelectedPlayer?: boolean,
) => {

  if (isFindPlayerView) {
    const remainingWidth = tableWidth - 350;
    const totalMinWidth = 630;
    const unit = remainingWidth / totalMinWidth;

    return [
      {
        Header:
          <div className='player-table-sticky-header-cell'>

            {isSelectedPlayer
              ? staticLanguageMap['selectedPlayer'][language]
              : (totalHits
                + (totalHits >= 10000 ? '+ ' : ' ')
                + (totalHits === 1 ? staticLanguageMap[' player'][language] : staticLanguageMap['players'][language]))
            }

            {totalHits > hitsPerChapter && (
              <div className='player-table-sticky-header-cell-pagination'>
                <div className='player-table-current-chapter-range'>
                  {'(' + (hitsPerChapter * currentChapter) + '-' + Math.min(hitsPerChapter * (currentChapter + 1), totalHits) + ')'}
                </div>

                {currentChapter <= 0 && (
                  <div className='player-table-pagination-arrow player-table-pagination-left-arrow'>
                    <KeyboardArrowLeftIcon style={{ fontSize: 16 }} />
                  </div>
                )}

                {currentChapter > 0 && handleChangeCurrentChapter && (
                  <div className='player-table-pagination-arrow player-table-pagination-arrow-abled player-table-pagination-left-arrow'>
                    <KeyboardArrowLeftIcon style={{ fontSize: 16 }} onClick={() => handleChangeCurrentChapter(false)} />
                  </div>
                )}

                <div className='player-table-current-chapter'>
                  {currentChapter + 1}
                </div>

                {currentChapter >= Math.floor(totalHits / hitsPerChapter) && (
                  <div className='player-table-pagination-arrow player-table-pagination-right-arrow'>
                    <KeyboardArrowRightIcon style={{ fontSize: 16 }} />
                  </div>
                )}

                {currentChapter < Math.floor(totalHits / hitsPerChapter) && handleChangeCurrentChapter && (
                  <div className='player-table-pagination-arrow player-table-pagination-arrow-abled player-table-pagination-right-arrow'>
                    <KeyboardArrowRightIcon style={{ fontSize: 16 }} onClick={() => handleChangeCurrentChapter(true)} />
                  </div>
                )}
              </div>
            )}

          </div>,

        accessor: 'id',
        sticky: 'left',
        Cell: PlayerFindingInfoCell,
        width: 350,
      },
      {
        Header: staticLanguageMap['age'][language],
        accessor: 'age',
        Cell: TextCell,
        width: unit * 90,
        minWidth: 90,
      },
      {
        Header: staticLanguageMap['position'][language],
        Cell: renderPositionCell(language),
        width: unit * 110,
        minWidth: 110,
      },
      {
        Header: staticLanguageMap['height'][language],
        accessor: 'height',
        Cell: HeightCell,
        width: unit * 90,
        minWidth: 90,
      },
      {
        Header: staticLanguageMap['league'][language],
        accessor: 'competition_id',
        Cell: CompetitionCell,
        width: unit * 170,
        minWidth: 170,
      },
      {
        Header: staticLanguageMap['team'][language],
        accessor: 'club.name',
        Cell: ClubCell,
        width: unit * 170,
        minWidth: 170,
      },
    ];
  }

  const remainingWidth = tableWidth - 350;
  const totalMinWidth = 680;
  const unit = remainingWidth / totalMinWidth;

  return [
    {
      Header:
        <div className='player-table-sticky-header-cell'>

          {totalHits + (totalHits >= 10000 ? '+ ' : ' ') + staticLanguageMap['players'][language]}

          {totalHits > hitsPerChapter && (
            <div className='player-table-sticky-header-cell-pagination'>
              <div className='player-table-current-chapter-range'>
                {'(' + (hitsPerChapter * currentChapter) + '-' + Math.min(hitsPerChapter * (currentChapter + 1), totalHits) + ')'}
              </div>

              {currentChapter <= 0 && (
                <div className='player-table-pagination-arrow player-table-pagination-left-arrow'>
                  <KeyboardArrowLeftIcon style={{ fontSize: 16 }} />
                </div>
              )}

              {currentChapter > 0 && handleChangeCurrentChapter && (
                <div className='player-table-pagination-arrow player-table-pagination-arrow-abled player-table-pagination-left-arrow'>
                  <KeyboardArrowLeftIcon style={{ fontSize: 16 }} onClick={() => handleChangeCurrentChapter(false)} />
                </div>
              )}

              <div className='player-table-current-chapter'>
                {currentChapter + 1}
              </div>

              {currentChapter >= Math.floor(totalHits / hitsPerChapter) && (
                <div className='player-table-pagination-arrow player-table-pagination-right-arrow'>
                  <KeyboardArrowRightIcon style={{ fontSize: 16 }} />
                </div>
              )}

              {currentChapter < Math.floor(totalHits / hitsPerChapter) && handleChangeCurrentChapter && (
                <div className='player-table-pagination-arrow player-table-pagination-arrow-abled player-table-pagination-right-arrow'>
                  <KeyboardArrowRightIcon style={{ fontSize: 16 }} onClick={() => handleChangeCurrentChapter(true)} />
                </div>
              )}
            </div>
          )}

        </div>,

      accessor: 'id',
      sticky: 'left',
      Cell: InfoCell,
      width: 350,
    },
    {
      Header: staticLanguageMap['age'][language],
      accessor: 'age',
      Cell: TextCell,
      width: unit * 80,
      minWidth: 80,
      orderByMetrics: ['age'],
    },
    {
      Header: staticLanguageMap['contract'][language],
      accessor: 'contract_expiration',
      Cell: renderContractCell(),
      width: unit * 110,
      minWidth: 110,
      orderByMetrics: ['contractLength'],
    },
    {
      Header: staticLanguageMap['position'][language],
      Cell: renderPositionCell(language),
      width: unit * 110,
      minWidth: 110,
    },
    {
      Header: staticLanguageMap['height'][language],
      accessor: 'height',
      Cell: HeightCell,
      width: unit * 80,
      minWidth: 80,
    },
    {
      Header: staticLanguageMap['skillIndex'][language],
      accessor: 'overall.skill_index',
      Cell: RatingCell,
      width: unit * 100,
      minWidth: 100,
      orderByMetrics: ['skillIndex', 'indexDiff'],
    },
    {
      Header: staticLanguageMap['clubIndex'][language],
      accessor: 'overall.club_index',
      Cell: RatingCell,
      width: unit * 100,
      minWidth: 100,
      orderByMetrics: ['clubIndex', 'indexDiff'],
    },
    {
      Header: staticLanguageMap['precision'][language],
      accessor: 'overall.confidence',
      Cell: PrecisionCell,
      width: unit * 100,
      minWidth: 100,
      orderByMetrics: ['confidence'],
    },
  ];
};
