import './modals.css';

import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { useGlobalModal } from '../../recoil/hooks/openGlobalModal';

import CloseIcon from '@mui/icons-material/Close';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CheckIcon from '@mui/icons-material/Check';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

// import NO from 'country-flag-icons/react/3x2/NO';
// import GB from 'country-flag-icons/react/3x2/GB';

import { staticLanguageMap } from '../../../common/static/staticLanguageMap';
import { newAccessOptions } from '../../static/propertyValues';
import { getAccessIcon } from '../../utils/iconUtils';
import { useState } from 'react';
import { InputField } from '../input/InputField';

import { getFunctions, httpsCallable } from 'firebase/functions';


const functions = getFunctions(undefined, 'europe-west1');


interface NewUserData {
  email: string;
  club: string;
  name: string;
  language: string;
  access: string;
}


interface AddAccountModalProps {
  closeModal: () => void;
}


export const AddAccountModal: React.FC<AddAccountModalProps> = ({ closeModal }) => {

  const createUser = httpsCallable(functions, 'createUser');

  const userConfig = useRecoilValue(userConfigState);

  const { handleOpenConfirmModal, handleOpenTextModal } = useGlobalModal();

  const [isLoading, setIsLoading] = useState(false);

  const [messageKey, setMessageKey] = useState<string | undefined>(undefined);

  const [emailInputString, setEmailInputString] = useState('');
  const [nameInputString, setNameInputString] = useState('');
  const [selectedAccess, setSelectedAccess] = useState<string | undefined>('scout');

  const [newAccountWasAdded, setNewAccountWasAdded] = useState(false);


  const isValidEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };


  const handleAddAccountClick = () => {
    if (!isValidEmail(emailInputString)) {
      setMessageKey('invalidEmailProvided');
      return;
    }

    handleOpenConfirmModal(
      confirmAddAccount,
      'createAccount',
      'youWillNotBeAbleToDeleteAccount'
    );
  };


  const confirmAddAccount = async () => {
    if (!userConfig || !emailInputString || !nameInputString || !selectedAccess) return;

    setIsLoading(true);

    const newUserConfig: NewUserData = {
      email: emailInputString.toLocaleLowerCase(),
      club: userConfig.club,
      name: nameInputString,
      language: userConfig.language,
      access: selectedAccess,
    };

    try {
      const success = await createUser(newUserConfig);

      if (!success) {
        setMessageKey('somethingWentWrong');
        setIsLoading(false);
        return;
      }

      setMessageKey(undefined);
      setNewAccountWasAdded(true);
      setIsLoading(false);
    }
    catch (error) {
      console.error(error); // eslint-disable-line
      setMessageKey('somethingWentWrong');
      setIsLoading(false);
    }
  };


  const buttonIsDisabled = () => {
    return !emailInputString || !nameInputString || !selectedAccess;
  };


  return (
    <div className='modal-root-container add-account-modal-root'>

      <CloseIcon
        className='modal-icon modal-exit-icon'
        style={{ fontSize: 24 }}
        onClick={() => !isLoading ? closeModal() : null}
      />

      <div className='modal-root-title'>
        {userConfig ? staticLanguageMap['addNewAccount'][userConfig.language] : ''}
      </div>

      <div className='modal-divider modal-root-title-divider'>&nbsp;</div>

      {!newAccountWasAdded && !isLoading && (
        <div className='modal-content-section' style={{ top: 100, bottom: 110, paddingBottom: 0 }}>
          <div className='add-account-modal-input-entry'>
            <div className='add-account-modal-input-title'>
              {userConfig ? staticLanguageMap['email'][userConfig.language] : ''}
            </div>
            <InputField
              searchString={emailInputString}
              onChangeInputField={(event: React.ChangeEvent<HTMLInputElement>) => setEmailInputString(event.target.value)}
              resetSearchString={() => setEmailInputString('')}
              onKeyDownInputField={undefined}
              defaultInput={userConfig ? staticLanguageMap['provideEmail'][userConfig.language] : ''}
              showDefaultInput={true}
              height={27}
              isSquare={true}
              style={{ boxShadow: '0px 0px 2px 1px #15171e69' }}
            />
          </div>

          <div className='add-account-modal-input-entry'>
            <div className='add-account-modal-input-title'>
              {userConfig ? staticLanguageMap['name'][userConfig.language] : ''}
            </div>
            <InputField
              searchString={nameInputString}
              onChangeInputField={(event: React.ChangeEvent<HTMLInputElement>) => setNameInputString(event.target.value)}
              resetSearchString={() => setNameInputString('')}
              onKeyDownInputField={undefined}
              defaultInput={userConfig ? staticLanguageMap['provideName'][userConfig.language] : ''}
              showDefaultInput={true}
              height={27}
              isSquare={true}
              style={{ boxShadow: '0px 0px 2px 1px #15171e69' }}
            />
          </div>

          <div style={{ height: 0, margin: 'auto' }}>&nbsp;</div>

          <div className='add-account-modal-input-entry'>

            {newAccessOptions.map((access: string, index: number) => {

              const isSelected = selectedAccess === access;
              const icon = getAccessIcon(access, 22);

              return (
                <div key={index} style={{ position: 'relative', marginTop: index === 0 ? 0 : 12 }}>
                  <div
                    className='modal-access-option-info-icon'
                    onClick={() => handleOpenTextModal(userConfig ? staticLanguageMap[access + 'AccessInfo'][userConfig.language] : '', 550)}
                  >
                    <InfoOutlinedIcon style={{ fontSize: 16 }} />
                  </div>
                  <div
                    key={access}
                    className={'edit-player-info-modal-role-option' + (isSelected ? ' edit-player-info-modal-role-option-selected' : '')}
                    style={{ boxSizing: 'border-box', height: 30 }}
                    onClick={() => isSelected ? setSelectedAccess(undefined) : setSelectedAccess(access)}
                  >
                    <div className='edit-player-info-modal-role-icon' style={{ marginTop: 3 }}>
                      {icon}
                    </div>
                    <div className='edit-player-info-modal-role-text'>
                      {userConfig ? staticLanguageMap[access + 'Access'][userConfig.language] : ''}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {messageKey && !newAccountWasAdded && !isLoading && (
        <div className='add-account-modal-message' style={{ color: '#ff8888' }}>
          {userConfig ? staticLanguageMap[messageKey][userConfig.language] : ''}
        </div>
      )}

      {!newAccountWasAdded && !isLoading && (
        <div
          className={'modal-button account-modal-button' + (buttonIsDisabled() ? ' modal-button-disabled' : '')}
          style={{ marginLeft: 150 }}
          onClick={() => !buttonIsDisabled() ? handleAddAccountClick() : null}>
          <div className='modal-button-text'>
            {userConfig ? staticLanguageMap['createAccount'][userConfig.language] : ''}
          </div>

          <PersonAddIcon
            style={{ fontSize: 22, marginTop: -1 }}
          />
        </div>
      )}


      {newAccountWasAdded && !isLoading && (
        <div className='add-account-modal-success-container'>
          <div className='add-account-modal-success-message'>
            <CheckIcon style={{ fontSize: 28, marginRight: 12, marginTop: -2 }} />
            {userConfig ? staticLanguageMap['accountCreated'][userConfig.language] : ''}
          </div>

          <div className='add-account-modal-success-info'>
            {userConfig ? staticLanguageMap['accountCreatedInfo'][userConfig.language] : ''}
          </div>

          <div className='add-account-modal-input-entry'>
            <div className='add-account-modal-input-title'>
              {userConfig ? staticLanguageMap['email'][userConfig.language] : ''}
            </div>
            <div style={{ fontSize: 16, userSelect: 'text' }}>
              {emailInputString}
            </div>
          </div>
        </div>
      )}

      {isLoading && <div className='loading-spinner'>&nbsp;</div>}

    </div>
  );
};
