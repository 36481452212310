import '../tables.css';

import { useEffect, useMemo, useRef } from 'react';
import { useTable, useBlockLayout, useFilters } from 'react-table';
import { useSticky } from 'react-table-sticky';

import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { settingsState } from '../../../recoil/atoms/settingsState';
import { selectedPlayerState } from '../../../recoil/atoms/selectedPlayerState';
import { clubScopesState } from '../../../recoil/atoms/clubScopesState';

import { FlexibleJsonMapping } from '../../../types';
import { getPlayerSeasonColumns } from './playerSeasonColumns';
import { debounce } from '@mui/material';
import { pagesPerChapter } from '../../../views/scouting/Scouting';
import { getClubSecondaryColor } from '../../../utils/configUtils';


interface PlayerSeasonTableProps {
  data: FlexibleJsonMapping[];
  isLoading: boolean;
  handleSearchButtonPressed: (isNewSearch: boolean, nextChapter?: number) => Promise<void>;
  currentModuloPage: number;
  currentChapter: number;
  totalHits: number;
  hitsPerPage: number;
  handleChangeCurrentChapter: (isIncrement: boolean) => void;
  selectedOrderBy: string | undefined;
  isGoalkeeperSelected: boolean;
}

export const PlayerSeasonTable: React.FC<PlayerSeasonTableProps> = ({
  data,
  isLoading,
  handleSearchButtonPressed,
  currentModuloPage,
  currentChapter,
  totalHits,
  hitsPerPage,
  handleChangeCurrentChapter,
  selectedOrderBy,
  isGoalkeeperSelected,
}) => {

  const userConfig = useRecoilValue(userConfigState);
  const settings = useRecoilValue(settingsState);
  const setSelectedPlayer = useSetRecoilState(selectedPlayerState);
  const clubScopes = useRecoilValue(clubScopesState);

  const tableContainerRef = useRef<HTMLDivElement>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: any[] = useMemo(
    () => getPlayerSeasonColumns(
      userConfig,
      isGoalkeeperSelected,
      currentChapter,
      hitsPerPage * pagesPerChapter,
      totalHits,
      handleChangeCurrentChapter,
      selectedOrderBy,
      getClubSecondaryColor(userConfig?.club ?? ''),
      settings?.userSettings.seasonStatsToggles ?? {},
      clubScopes?.hasSkillcorner ?? false
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      data,
      userConfig,
      currentChapter,
      hitsPerPage,
      totalHits,
      selectedOrderBy,
      settings?.userSettings.seasonStatsToggles,
      clubScopes?.hasSkillcorner
    ]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useBlockLayout,
    useSticky
  );


  const checkScrollPosition = async () => {
    if (!tableContainerRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = tableContainerRef.current;
    const scrollPosition = scrollTop / (scrollHeight - clientHeight);
    const threshold = 0.4 + currentModuloPage * 0.1;

    if (scrollPosition > threshold && !isLoading && currentModuloPage < 4 && ((currentChapter * hitsPerPage * pagesPerChapter) + data.length) < totalHits) {
      await handleSearchButtonPressed(false);
    }
  };


  useEffect(() => {
    const handleScroll = debounce(() => checkScrollPosition(), 100);

    const tableContainer = tableContainerRef.current;
    if (tableContainer) {
      tableContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (tableContainer) {
        tableContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps


  const getExtendedCellClassName = (cell: FlexibleJsonMapping) => {
    if (cell.column.isFinalSubMetric) {
      return ' player-season-table-body-cell-with-border';
    }
    return '';
  };


  document.documentElement.style.setProperty('--header-background-color', '#2c303f');


  return (
    <div
      ref={tableContainerRef}
      {...getTableProps()}
      className='player-table'
      style={{ overflowX: 'auto' }}>

      <div className='season-table-top-level-header-group'>
        {headerGroups.map(headerGroup => {
          const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();
          return (
            <div
              key={key}
              {...restHeaderGroupProps}
            >
              {headerGroup.headers.map((column: FlexibleJsonMapping) => {
                const { key: columnKey, ...restColumnProps } = column.getHeaderProps({ title: column.title });
                return (
                  <div
                    key={columnKey}
                    {...restColumnProps}
                  >
                    {column.render('Header')}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>

      <div
        {...getTableBodyProps()}
        className='player-table-body player-season-table-body'>
        {rows.map((row, i) => {
          prepareRow(row);
          const { key, ...restRowProps } = row.getRowProps();
          return (
            <div
              key={key}
              {...restRowProps}
              className={i % 2 === 0 ? 'player-table-body-row-even' : 'player-table-body-row-odd'}
              onClick={() => setSelectedPlayer({ id: row.original.player_id, fullname: row.original.fullname })}
            >
              {row.cells.map(cell => {
                const { key: cellKey, ...restCellProps } = cell.getCellProps();
                return (
                  <div
                    key={cellKey}
                    {...restCellProps}
                    className={
                      'player-season-table-body-cell'
                      + (i % 2 === 0 ? ' player-table-body-cell-even' : ' player-table-body-cell-odd')
                      + getExtendedCellClassName(cell)
                    }
                  >
                    {cell.render('Cell')}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>

    </div>
  );
};
