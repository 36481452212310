import './../documents.css';

import { useEffect, useReducer, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { allUserConfigsState } from '../../../recoil/atoms/allUserConfigsState';

import clauseReducer from './clauseReducer';
import initializeState, { ClauseState } from './clauseInitialState';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { enGB, nb } from 'date-fns/locale';

import { Dialog } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import RestoreIcon from '@mui/icons-material/Restore';
import EditNoteIcon from '@mui/icons-material/EditNote';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import UpdateIcon from '@mui/icons-material/Update';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import NotificationsIcon from '@mui/icons-material/Notifications';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import MoreVertIcon from '@mui/icons-material/MoreVert';

// import EuroIcon from '@mui/icons-material/Euro';
// import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
// import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import {
  ClausePayment,
  FlexibleJsonMapping,
  PlayerClause,
  ClubMapping,
  Club,
} from '../../../types';

import { staticLanguageMap } from '../../../../common/static/staticLanguageMap';
import { PlayerSimpleTable } from '../../tables/playerSimpleTable/PlayerSimpleTable';
import { SelectPlayerView } from '../../searchPlayers/SelectPlayerView';
import { ClubDropDown } from '../../input/ClubDropDown';
import { addClause, updateClause } from '../../../services/firestore/playerClauses';
import { DropDownSelect } from '../../input/DropDownSelect';
import { clauseCurrencyKeys } from '../../../static/propertyValues';
import { PaymentModal, getPaymentTitle } from '../../modals/clauseModals/PaymentModal';
import { getDateLocaleFormat } from '../../../utils/playerUtils';
import { getDisplayEconomicValue } from '../../../utils/currencyUtils';
import { FileUpload } from '../../input/FileUpload';
import { getShortenedFileName } from '../../modals/globalModals/PdfModal';
import { deleteFiles, uploadFiles } from '../../../services/storage/files';
import {
  Conditions,
  getSeasonConditionWithoutCurrentValues,
  getSubClauseWithoutCurrentValuesAndNextClubAndConditionalFees,
  getTotalConditionWithoutCurrentValue
} from './Conditions';
import { useGlobalModal } from '../../../recoil/hooks/openGlobalModal';
import { SubClauseModal } from '../../modals/clauseModals/SubClauseModal';
import { MaxConditionalFeesModal } from '../../modals/clauseModals/MaxConditionalFeesModal';


export const getPaymentCurrency = (payment: ClausePayment, state: ClauseState) => {
  const currency = payment.subClauseIndex !== undefined && state.subClauses && state.subClauses.length >= payment.subClauseIndex
    ? state.subClauses[payment.subClauseIndex].currency
    : state.currency;
  return currency;
};


export const getDisplayPaymentAmount = (payment: ClausePayment, state: ClauseState) => {

  const currency = getPaymentCurrency(payment, state);

  let amount = payment.amount;

  if (payment.subClauseIndex !== undefined) {
    const discountFactor = state.subClauses && state.subClauses.length >= payment.subClauseIndex
      ? state.subClauses[payment.subClauseIndex].totalSellOnPercentage / 100
      : 1;
    const discountedAmount = Math.round(payment.amount * discountFactor);
    amount = discountedAmount;
  }

  if (currency) {
    return getDisplayEconomicValue(amount);
  }

  return getDisplayEconomicValue(payment.amount, true);
};


interface ClauseProps {
  isSoldPlayer: boolean;
  existingClauseSelected: PlayerClause | undefined;
  setExistingClauseSelected: (clause: PlayerClause | undefined) => void;

  handleCloseIconClick: () => void;
  addOrUpdateClause: (clause: PlayerClause, isNewClause: boolean, isSoldPlayer: boolean) => void;

  selectedPlayerData: FlexibleJsonMapping | undefined;
  setSelectedPlayerData: (playerData: FlexibleJsonMapping | undefined) => void;
  allClubs: ClubMapping;
  setAllClubs: (clubs: ClubMapping) => void;
  userClubId: number;

  isClubsExpanded: boolean;
  setIsClubsExpanded: (isExpanded: boolean) => void;

  isCurrencyExpanded: boolean;
  setIsCurrencyExpanded: (isExpanded: boolean) => void;

  clauseHasUnsavedChanges: boolean;
  setClauseHasUnsavedChanges: (hasUnsavedChanges: boolean) => void;
}


export const Clause: React.FC<ClauseProps> = ({
  isSoldPlayer,
  existingClauseSelected,
  setExistingClauseSelected,

  handleCloseIconClick,
  addOrUpdateClause,

  selectedPlayerData,
  setSelectedPlayerData,
  allClubs,
  setAllClubs,
  userClubId,

  isClubsExpanded,
  setIsClubsExpanded,

  isCurrencyExpanded,
  setIsCurrencyExpanded,

  clauseHasUnsavedChanges,
  setClauseHasUnsavedChanges,
}) => {

  const userConfig = useRecoilValue(userConfigState);
  const allUsersWithSameClub = useRecoilValue(allUserConfigsState);

  const { handleOpenConfirmModal, handleOpenTextModal, handleOpenPdfModal } = useGlobalModal();

  const [isLoading, setIsLoading] = useState(false);

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  const [isPlayerSearchActive, setIsPlayerSearchActive] = useState(false);


  // holds most of the mutable state of a clause, except playerData and files, which need more careful handling
  const [state, dispatch] = useReducer(clauseReducer, undefined, () => initializeState(isSoldPlayer, userClubId, allClubs, existingClauseSelected));

  const [showPlayerIsRequired, setShowPlayerIsRequired] = useState(false);
  const [showClubIsRequired, setShowClubIsRequired] = useState(false);
  const [showTransferDateIsRequired, setShowTransferDateIsRequired] = useState(false);

  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [paymentModalHasUnsavedChanges, setPaymentModalHasUnsavedChanges] = useState(false);

  const [isMaxConditionalFeesModalOpen, setIsMaxConditionalFeesModalOpen] = useState(false);

  const [subClauseIndexToEdit, setSubClauseIndexToEdit] = useState<number | undefined>(undefined);
  const [subClauseModalHasUnsavedChanges, setSubClauseModalHasUnsavedChanges] = useState(false);


  const dateFormat = 'dd.MM.yyyy';
  const locale = userConfig && userConfig.language === 'no'
    ? nb
    : enGB;

  const today = new Date();

  const minTransferDate = new Date((new Date()).setFullYear((today).getFullYear() - 10));
  const maxTransferDate = new Date((new Date()).setFullYear((today).getFullYear() + 10));


  const setSelectedCurrency = (currency: string[]) => {
    dispatch({ type: 'SET_CURRENCY', payload: currency[0] });
  };


  const setSelectedClub = (club: Club[]) => {
    const selectedClub = club[0];

    if (!allClubs[selectedClub.id]) {
      setAllClubs({ ...allClubs, [selectedClub.id]: selectedClub });
    }

    dispatch({ type: 'SET_SELECTED_CLUB', payload: selectedClub });
  };


  const handleCloseClubIconClick = () => {
    if (state.sellOnCondition || state.totalConditions.length > 0 || state.seasonConditions.length > 0) {
      handleOpenConfirmModal(
        () => dispatch({ type: 'SET_SELECTED_CLUB', payload: undefined }),
        'removeClub',
        'removeClubInfo'
      );
    }
    else {
      dispatch({ type: 'SET_SELECTED_CLUB', payload: undefined });
    }
  };


  const handleFileUpload = (file: File) => {
    const newFileName = file.name;
    if (state.fileNames.includes(newFileName)) {
      handleOpenTextModal(staticLanguageMap['fileAlreadyUploaded'][userConfig?.language ?? 'en']);
      return;
    }

    dispatch({ type: 'UPLOAD_FILE', payload: file });
  };


  const handleRemoveAttachmentClick = (event: React.MouseEvent, fileUrl: string, fileName: string) => {
    event.stopPropagation();

    const fileExistsInStorage = existingClauseSelected?.fileNames?.includes(fileName) ?? false;
    dispatch({ type: 'REMOVE_FILE', payload: { fileUrl, fileName, fileExistsInStorage } });
  };


  useEffect(() => {
    // revoke the object url to avoid memory leaks
    return () => {
      state.fileUrls.forEach((url) => URL.revokeObjectURL(url));
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  const setRequiredFields = () => {
    if (!selectedPlayerData) setShowPlayerIsRequired(true);
    if (!state.sellingClubId || !state.buyingClubId) setShowClubIsRequired(true);
    if (!state.transferDate) setShowTransferDateIsRequired(true);
  };


  const resetRequiredFields = () => {
    setShowPlayerIsRequired(false);
    setShowClubIsRequired(false);
    setShowTransferDateIsRequired(false);
  };


  const handleAddClause = async () => {
    if (!userConfig || !selectedPlayerData || !state.sellingClubId || !state.buyingClubId || !state.transferDate) {
      setRequiredFields();
      return;
    }

    setIsLoading(true);
    resetRequiredFields();

    let clause: PlayerClause = {
      clauseVersion: '1.0.0',
      userEmail: userConfig.email,
      date: new Date().toISOString(),

      isSoldPlayer: isSoldPlayer,
      isDeleted: false,
      isResolved: false,

      playerId: selectedPlayerData.id,
      playerName: selectedPlayerData.fullname,
      sellingClubId: state.sellingClubId,
      buyingClubId: state.buyingClubId,
      transferDate: state.transferDate,
      comment: state.comment,

      currency: state.currency,
      payments: state.payments,

      sellOnCondition: state.sellOnCondition,
      totalConditions: state.totalConditions.map(totalCondition => getTotalConditionWithoutCurrentValue(totalCondition)),
      seasonConditions: state.seasonConditions.map(seasonCondition => getSeasonConditionWithoutCurrentValues(seasonCondition)),
      maxConditionalFees: state.maxConditionalFees,

      subClauses: state.subClauses ? state.subClauses.map(subClause => getSubClauseWithoutCurrentValuesAndNextClubAndConditionalFees(subClause)) : null,
    };

    // (1) add the clause to firestore and get the clauseId
    const clauseId = await addClause(clause, userConfig.club);
    if (!clauseId) {
      setIsLoading(false);
      return;
    }

    if (state.additionalClauseState.uploadedFiles.length > 0) {
      // (2) upload files to storage and get their urls
      const fileUrls = await uploadFiles(state.additionalClauseState.uploadedFiles, 'clauses', clauseId, userConfig.club);

      // (3) update the clause with the fileUrls and fileNames
      const newValues = { 'fileUrls': fileUrls, 'fileNames': state.fileNames };
      await updateClause(clauseId, newValues, userConfig.club);

      clause = { ...clause, ...newValues };
    }

    setExistingClauseSelected({ ...clause, id: clauseId });
    addOrUpdateClause({ ...clause, id: clauseId }, true, isSoldPlayer);
    setIsLoading(false);
  };


  const handleUpdateClause = async () => {
    if (
      !userConfig
      || !selectedPlayerData
      || !existingClauseSelected
      || !existingClauseSelected.id
      || !state.sellingClubId
      || !state.buyingClubId
      || !state.transferDate
    ) {
      setRequiredFields();
      return;
    }

    // only set loading if we need to update storage
    if (state.additionalClauseState.uploadedFiles.length > 0 || state.additionalClauseState.removedFileNames.length > 0) setIsLoading(true);

    resetRequiredFields();

    // (1) add and delete files in storage and get urls for new files
    const fileNameToNewUrl: { [key: string]: string } = {};
    if (state.additionalClauseState.uploadedFiles.length > 0) {
      const newFileUrls = await uploadFiles(state.additionalClauseState.uploadedFiles, 'clauses', existingClauseSelected.id, userConfig.club);
      newFileUrls.forEach((url, index) => {
        fileNameToNewUrl[state.additionalClauseState.uploadedFiles[index].name] = url;
      });
    }
    if (state.additionalClauseState.removedFileNames.length > 0) {
      await deleteFiles(state.additionalClauseState.removedFileNames, 'clauses', existingClauseSelected.id, userConfig.club);
    }

    // the url to storage will be the one from fileNameToNewUrl if the file was added now, otherwise it will be the url in fileUrls at the same index
    const newFileUrlsToSet = state.fileNames.map(
      fileName => fileNameToNewUrl[fileName] ?? state.fileUrls[state.fileNames.indexOf(fileName)]
    );

    // (2) update the clause with the new values
    const newValues = {
      playerId: selectedPlayerData.id,
      playerName: selectedPlayerData.fullname,
      sellingClubId: state.sellingClubId,
      buyingClubId: state.buyingClubId,
      transferDate: state.transferDate,
      comment: state.comment,

      fileUrls: newFileUrlsToSet,
      fileNames: state.fileNames,

      currency: state.currency,
      payments: state.payments,

      sellOnCondition: state.sellOnCondition,
      totalConditions: state.totalConditions.map(totalCondition => getTotalConditionWithoutCurrentValue(totalCondition)),
      seasonConditions: state.seasonConditions.map(seasonCondition => getSeasonConditionWithoutCurrentValues(seasonCondition)),
      maxConditionalFees: state.maxConditionalFees,

      subClauses: state.subClauses ? state.subClauses.map(subClause => getSubClauseWithoutCurrentValuesAndNextClubAndConditionalFees(subClause)) : null,

      incorrectNextClubIds: state.incorrectNextClubIds,
    };
    const success = await updateClause(existingClauseSelected.id, newValues, userConfig.club);

    if (success) {
      dispatch({ type: 'SET_IS_EDIT_MODE', payload: false });
      addOrUpdateClause({ ...existingClauseSelected, ...newValues }, false, isSoldPlayer);
    }

    setIsLoading(false);
  };


  const handleSubmitClause = async () => {
    setSubmitButtonDisabled(true);

    if (state.additionalClauseState.isEditMode) {
      await handleUpdateClause();
    }
    else {
      await handleAddClause();
    }

    setSubmitButtonDisabled(false);
  };


  const handleClauseAction = async (isDeleteOrRestore: boolean) => {
    if (!existingClauseSelected || !existingClauseSelected.id || !userConfig) return;

    setSubmitButtonDisabled(true);

    const newValues = isDeleteOrRestore
      ? { isDeleted: !existingClauseSelected.isDeleted, isResolved: false }
      : { isResolved: !existingClauseSelected.isResolved, isDeleted: false };

    const success = await updateClause(existingClauseSelected.id, newValues, userConfig.club);

    if (success) {
      addOrUpdateClause({ ...existingClauseSelected, ...newValues }, false, isSoldPlayer);

      if ((isDeleteOrRestore && !existingClauseSelected.isDeleted) || (!isDeleteOrRestore && !existingClauseSelected.isResolved)) {
        handleCloseIconClick();
      }
      else {
        if (isDeleteOrRestore) {
          setExistingClauseSelected({ ...existingClauseSelected, isDeleted: !existingClauseSelected.isDeleted, isResolved: false });
        }
        else {
          setExistingClauseSelected({ ...existingClauseSelected, isResolved: !existingClauseSelected.isResolved, isDeleted: false });
        }
      }
    }

    setSubmitButtonDisabled(false);
  };


  const handleClosePaymentModalClick = () => {
    if (paymentModalHasUnsavedChanges) {
      handleOpenConfirmModal(
        () => setIsPaymentModalOpen(false),
        state.payments.length === 0 ? 'discardPayments?' : 'discardChanges?',
        state.payments.length === 0 ? 'paymentsWillNotBeSaved' : 'changesWillNotBeSaved'
      );
    }
    else {
      setIsPaymentModalOpen(false);
    }
  };


  const addOrEditPayments = (payments: ClausePayment[], deletedPayments: ClausePayment[]) => {
    dispatch({ type: 'SET_PAYMENTS', payload: { payments, deletedPayments } });
    setIsPaymentModalOpen(false);
  };


  const openConditionsModalInEditMode = () => {
    setSubClauseIndexToEdit(state.additionalClauseState.activeSubClauseTab);
    dispatch({ type: 'SET_IS_SUB_CLAUSE_MODAL_OPEN', payload: true });
  };


  const closeSubClauseModal = () => {
    dispatch({ type: 'SET_IS_SUB_CLAUSE_MODAL_OPEN', payload: false });
    setTimeout(() => {
      setSubClauseIndexToEdit(undefined);
      setSubClauseModalHasUnsavedChanges(false);
    }, 150);
  };


  const handleCloseSubClauseModal = (forceClose: boolean) => {
    if (subClauseModalHasUnsavedChanges && !forceClose) {
      handleOpenConfirmModal(closeSubClauseModal, 'discardChanges?', 'changesWillNotBeSaved');
    }
    else {
      closeSubClauseModal();
    }
  };


  useEffect(() => {
    dispatch({ type: 'SET_TOTAL_TRANSFER_FEE_AND_CONDITIONAL_FEES' });
  }, [state.payments]);


  useEffect(() => {
    if (state.additionalClauseState.paymentHighlightIndex !== undefined) {
      const timer = setTimeout(() => {
        dispatch({ type: 'SET_PAYMENT_HIGHLIGHT_INDEX', payload: undefined });
      }, 700);
      return () => clearTimeout(timer);
    }
  }, [state.additionalClauseState.paymentHighlightIndex]);


  useEffect(() => {
    if (state.additionalClauseState.triggerClauseUpdate) {
      if (!state.additionalClauseState.isEditMode) {
        handleUpdateClause();
      }
      dispatch({ type: 'SET_TRIGGER_CLAUSE_UPDATE', payload: false });
    }
  }, [state.additionalClauseState.triggerClauseUpdate]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    // if clause already has unsaved changes, we don't bother to recheck if the clause ends up back in the same state
    if (!clauseHasUnsavedChanges) {
      if (!existingClauseSelected) {
        // if this is a new clause, the clause has unsaved changes if any data is entered
        if (
          selectedPlayerData ||
          state.additionalClauseState.uploadedFiles.length > 0 ||
          state.additionalClauseState.removedFileNames.length > 0 ||
          (state.sellingClubId && state.buyingClubId) ||
          state.transferDate ||
          state.comment ||
          state.currency ||
          state.payments.length > 0 ||
          state.sellOnCondition ||
          state.totalConditions.length > 0 ||
          state.seasonConditions.length > 0 ||
          state.subClauses
        ) {
          setClauseHasUnsavedChanges(true);
        }
      }
      else if (state.additionalClauseState.isEditMode) {
        // if clause is being edited, we just assume unsaved changes and don't bother to check if the current state actually has unsaved changes
        setClauseHasUnsavedChanges(true);
      }
    }
  }, [
    existingClauseSelected,
    selectedPlayerData,
    state,
    clauseHasUnsavedChanges,
    setClauseHasUnsavedChanges
  ]);


  useEffect(() => {
    setClauseHasUnsavedChanges(false);
    setIsLoading(false);

    dispatch({
      type: 'INITIALIZE_STATE',
      payload: initializeState(
        isSoldPlayer,
        userClubId,
        allClubs,
        existingClauseSelected,
        state
      )
    });
  }, [existingClauseSelected, isSoldPlayer, userClubId]); // eslint-disable-line react-hooks/exhaustive-deps


  const isActiveClause = !(existingClauseSelected?.isDeleted || existingClauseSelected?.isResolved);


  const buyingClub = state.buyingClubId !== undefined ? state.additionalClauseState.allClubs[state.buyingClubId] : undefined;
  const sellingClub = state.sellingClubId !== undefined ? state.additionalClauseState.allClubs[state.sellingClubId] : undefined;

  const selectedClub = state.isSoldPlayer ? buyingClub : sellingClub;


  const stateAccessorForCurrentConditionData = state.additionalClauseState.activeSubClauseTab !== undefined && state.subClauses
    ? state.subClauses[state.additionalClauseState.activeSubClauseTab]
    : state;


  const isMaxConditionalFeeReached = stateAccessorForCurrentConditionData.maxConditionalFees !== null &&
    (stateAccessorForCurrentConditionData.conditionalFees ?? 0) >= stateAccessorForCurrentConditionData.maxConditionalFees;


  const stateAccessorForLastConditionData = state.subClauses
    ? state.subClauses[state.subClauses.length - 1]
    : state;


  const shouldNotifyToResolveClause = isActiveClause
    && stateAccessorForLastConditionData.nextClubId !== undefined
    && (!stateAccessorForLastConditionData.sellOnCondition || stateAccessorForLastConditionData.sellOnCondition.isResolved);


  return (
    <div className='document-container'>

      <Dialog
        open={isPaymentModalOpen}
        onClose={() => handleClosePaymentModalClick()}
        PaperProps={{ sx: { borderRadius: '0px' } }}>
        <PaymentModal
          state={state}
          closeModal={() => handleClosePaymentModalClick()}
          payments={state.payments}
          addOrEditPayments={addOrEditPayments}
          isEdit={state.payments.length > 0}
          hasUnsavedChanges={paymentModalHasUnsavedChanges}
          setHasUnsavedChanges={setPaymentModalHasUnsavedChanges}
        />
      </Dialog>

      <Dialog
        open={state.additionalClauseState.isSubClauseModalOpen}
        onClose={() => handleCloseSubClauseModal(false)}
        PaperProps={{ sx: { borderRadius: '0px' } }}>
        <SubClauseModal
          closeModal={handleCloseSubClauseModal}
          subClauseIndexToEdit={subClauseIndexToEdit}
          setAllClubs={setAllClubs}
          hasUnsavedChanges={subClauseModalHasUnsavedChanges}
          setHasUnsavedChanges={setSubClauseModalHasUnsavedChanges}
          state={state}
          dispatch={dispatch}
        />
      </Dialog>

      <Dialog
        open={isMaxConditionalFeesModalOpen}
        onClose={() => setIsMaxConditionalFeesModalOpen(false)}
        PaperProps={{ sx: { borderRadius: '0px' } }}>
        <MaxConditionalFeesModal
          closeModal={() => setIsMaxConditionalFeesModalOpen(false)}
          state={state}
          dispatch={dispatch}
        />
      </Dialog>

      <div>
        <div className='document-close-icon' onClick={handleCloseIconClick}>
          <CloseIcon style={{ fontSize: 24 }} />
        </div>

        <div className='clause-title-row'>
          <div className='clause-column clause-left-column '>
            {existingClauseSelected === undefined && (
              <div className='clause-title'>
                {userConfig
                  ? (isSoldPlayer
                    ? staticLanguageMap['newClauseForSoldPlayer'][userConfig.language]
                    : staticLanguageMap['newClauseForBoughtPlayer'][userConfig.language])
                  : ''}
              </div>
            )}

            {existingClauseSelected !== undefined && (
              <div className='clause-title-info'>
                {allUsersWithSameClub[existingClauseSelected.userEmail] ? allUsersWithSameClub[existingClauseSelected.userEmail].name + ', ' : ''}
                {existingClauseSelected.date && getDateLocaleFormat(existingClauseSelected.date.split('T')[0])}
              </div>
            )}
          </div>

          <div className='clause-vertical-divider' style={{ backgroundColor: '#ffffff00' }}>&nbsp;</div>

          <div className='clause-column clause-right-column'>
            <div className='clause-title-transfer-fee'>
              {userConfig ? staticLanguageMap['totalTransferFee'][userConfig.language] : ''}:

              <div
                style={{
                  marginLeft: 12,
                  color: state.additionalClauseState.paymentHighlightIndex !== undefined
                    ? state.additionalClauseState.paymentHighlightIndex >= 0
                      ? '#9df78b'
                      : '#ff8888'
                    : undefined
                }}>
                {getDisplayEconomicValue(state.totalTransferFee, true)}
              </div>

              {!state.currency && (!existingClauseSelected || state.additionalClauseState.isEditMode) && (
                <div className='clause-currency-drop-down'>
                  <DropDownSelect
                    id={'clause-currency-select'}
                    dropDownOptions={clauseCurrencyKeys}
                    selectedOptions={state.currency ? [state.currency] : []}
                    setSelectedOptions={setSelectedCurrency}
                    isDropDownExpanded={isCurrencyExpanded}
                    setIsDropDownExpanded={setIsCurrencyExpanded}
                    defaultDropDownText={'currency'}
                    defaultDropDownTextColor={'#000000'}
                    marginBetweenOptions={0}
                    forceSelection={true}
                    height={24}
                  />
                </div>
              )}

              {state.currency && (
                <div className='clause-currency-drop-down'>
                  {state.currency}

                  <div
                    className='clause-content-icon'
                    style={{ marginLeft: 10, visibility: (!existingClauseSelected || state.additionalClauseState.isEditMode) ? undefined : 'hidden' }}
                    onClick={() => dispatch({ type: 'SET_CURRENCY', payload: null })}>
                    <CloseIcon style={{ fontSize: 18 }} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='document-horizontal-divider document-horizontal-top-divider'>&nbsp;</div>

        <div className='document-horizontal-divider document-horizontal-bottom-divider'>&nbsp;</div>

        <div className='document-submit-section document-submit-section-wide'>
          {(!existingClauseSelected || state.additionalClauseState.isEditMode) && !isLoading && (
            <div className='document-submit-button' onClick={(!submitButtonDisabled) ? handleSubmitClause : undefined}>
              <div className='document-submit-button-text'>
                {userConfig
                  ? (state.additionalClauseState.isEditMode
                    ? staticLanguageMap['confirmEdits'][userConfig.language]
                    : staticLanguageMap['submitClause'][userConfig.language])
                  : ''}
              </div>
              <div className='document-submit-button-icon'>
                {state.additionalClauseState.isEditMode
                  ? <CheckIcon style={{ fontSize: 21 }} />
                  : <ArrowRightAltIcon style={{ fontSize: 21 }} />}
              </div>
            </div>
          )}

          {existingClauseSelected && !state.additionalClauseState.isEditMode && !isLoading && (
            <div
              className='document-submit-button'
              onClick={!submitButtonDisabled ? () => dispatch({ type: 'SET_IS_EDIT_MODE', payload: true }) : undefined}
              style={{ width: 185 }}>
              <div className='document-submit-button-text'>
                {userConfig ? staticLanguageMap['editClause'][userConfig.language] : ''}
              </div>
              <div className='document-submit-button-icon'>
                {<EditNoteIcon style={{ fontSize: 21, marginRight: -2 }} />}
              </div>
            </div>
          )}

          {existingClauseSelected && !state.additionalClauseState.isEditMode && !isLoading && (
            <div
              className='document-submit-button'
              onClick={(!submitButtonDisabled) ? () => handleClauseAction(false) : undefined}
              style={{ width: 185 }}>
              <div className='document-submit-button-text'>
                {userConfig
                  ? (existingClauseSelected.isResolved
                    ? staticLanguageMap['reactivateClause'][userConfig.language]
                    : staticLanguageMap['resolveClause'][userConfig.language])
                  : ''}
              </div>
              <div
                className={'document-submit-button-icon' + (shouldNotifyToResolveClause ? ' document-submit-button-notification-icon' : '')}
              >
                {existingClauseSelected.isResolved
                  ? <UpdateIcon style={{ fontSize: 21, marginRight: -2 }} />
                  : shouldNotifyToResolveClause
                    ? <NotificationsIcon style={{ fontSize: 17, marginTop: -1 }} />
                    : <TaskAltIcon style={{ fontSize: 21, marginRight: -2 }} />}
              </div>
            </div>
          )}

          {existingClauseSelected && !state.additionalClauseState.isEditMode && !isLoading && (
            <div
              className='document-submit-button'
              onClick={(!submitButtonDisabled) ? () => handleClauseAction(true) : undefined}
              style={{ width: 185 }}>
              <div className='document-submit-button-text'>
                {userConfig
                  ? (existingClauseSelected.isDeleted
                    ? staticLanguageMap['restoreClause'][userConfig.language]
                    : staticLanguageMap['deleteClause'][userConfig.language])
                  : ''}
              </div>
              <div className='document-submit-button-icon'>
                {existingClauseSelected.isDeleted
                  ? <RestoreIcon style={{ fontSize: 21, marginRight: -2 }} />
                  : <DeleteOutlineIcon style={{ fontSize: 21, marginRight: -2 }} />}
              </div>
            </div>
          )}

          {isLoading && (
            <div style={{ margin: 'auto', marginBottom: -2 }}>
              <div className='loading-spinner loading-spinner-small'>&nbsp;</div>
            </div>
          )}
        </div>

      </div>

      <div className='document-content-section clause-content-section'>

        <div className='clause-column clause-left-column'>

          <div className='clause-left-column-section'>
            {selectedPlayerData && (
              <div className='clause-player-table fade-in' key={existingClauseSelected?.id ?? 'newClause'}>
                <PlayerSimpleTable
                  data={[selectedPlayerData ?? {}]}
                  tableType={'archiveReportInfo'}
                />
              </div>
            )}

            {selectedPlayerData && (!existingClauseSelected || state.additionalClauseState.isEditMode) && (
              <div className='clause-content-icon clause-remove-player-icon' onClick={() => setSelectedPlayerData(undefined)}>
                <CloseIcon style={{ fontSize: 18 }} />
              </div>
            )}

            {(!existingClauseSelected || state.additionalClauseState.isEditMode) && !selectedPlayerData && (
              <SelectPlayerView
                onPlayerClick={setSelectedPlayerData}
                titleKey={'selectPlayer'}
                maxTableHeight={'63vh'}
                setIsPlayerSearchActive={setIsPlayerSearchActive}
                marginTop={'5px'}
                marginBottom={'8px'}
              />
            )}

            {(!existingClauseSelected || state.additionalClauseState.isEditMode) && !selectedPlayerData && showPlayerIsRequired && (
              <div className='clause-content-required-star clause-content-required-star-player'>
                *
              </div>
            )}
          </div>

          {!isPlayerSearchActive && (
            <div className='clause-left-column-section clause-club-section'>
              <div className='clause-input-title'>
                {userConfig
                  ? (isSoldPlayer ? staticLanguageMap['soldTo'][userConfig.language] : staticLanguageMap['boughtFrom'][userConfig.language])
                  : ''}
              </div>

              {!selectedClub && (
                <div className='clause-club-drop-down'>
                  <ClubDropDown
                    id={'clause-clubs-drop-down'}
                    isDropDownExpanded={isClubsExpanded}
                    setIsDropDownExpanded={setIsClubsExpanded}
                    selectedClubs={[]}
                    setSelectedClubs={setSelectedClub}
                    defaultDropDownText={'selectClub'}
                    defaultDropDownTextColor={'#00000080'}
                    marginBetweenOptions={3}
                    maxHeight={'60vh'}
                    singleSelect={true}
                  />
                </div>
              )}

              {!selectedClub && showClubIsRequired && (
                <div className='clause-content-required-star clause-content-required-star-club'>
                  *
                </div>
              )}

              {selectedClub && (
                <div className='clause-input-display-field'>

                  <div className='clause-remove-icon-container'>
                    {(!existingClauseSelected || state.additionalClauseState.isEditMode) && (
                      <div className='clause-content-icon' onClick={() => handleCloseClubIconClick()}>
                        <CloseIcon style={{ fontSize: 18 }} />
                      </div>
                    )}
                  </div>

                  <div className='clause-selected-club-logo-container'>
                    <img
                      style={{ maxHeight: 16, maxWidth: 16 }}
                      src={selectedClub.logo_url}
                      alt={selectedClub.name}
                      draggable={false}
                    />
                  </div>

                  {selectedClub.name}
                </div>
              )}
            </div>
          )}

          {!isPlayerSearchActive && (
            <div className='clause-left-column-section clause-date-section'>
              <div className='clause-input-title'>
                {userConfig ? staticLanguageMap['transferDate'][userConfig.language] : ''}
              </div>

              {!state.transferDate && (
                <DatePicker
                  className='document-input-field'
                  selected={null}
                  onChange={(date: Date | null) => dispatch({ type: 'SET_TRANSFER_DATE', payload: date })}
                  locale={locale}
                  dateFormat={dateFormat}
                  popperPlacement='bottom'

                  minDate={minTransferDate}
                  maxDate={maxTransferDate}
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={100}
                  showMonthDropdown
                />
              )}

              {!state.transferDate && showTransferDateIsRequired && (
                <div className='clause-content-required-star clause-content-required-star-date'>
                  *
                </div>
              )}

              {state.transferDate && (
                <div className='clause-input-display-field'>
                  <div className='clause-remove-icon-container'>
                    {(!existingClauseSelected || state.additionalClauseState.isEditMode) && (
                      <div className='clause-content-icon' onClick={() => dispatch({ type: 'SET_TRANSFER_DATE', payload: null })}>
                        <CloseIcon style={{ fontSize: 18 }} />
                      </div>
                    )}
                  </div>

                  {getDateLocaleFormat(state.transferDate)}
                </div>
              )}
            </div>
          )}

          {!isPlayerSearchActive && !isClubsExpanded && (
            <div className='document-text-area-container clause-text-area-container fade-in'>
              <textarea
                className='document-text-area clause-text-area'
                value={state.comment}
                onChange={(event) => dispatch({ type: 'SET_COMMENT', payload: event.target.value })}
                placeholder={userConfig
                  ? (existingClauseSelected && !state.additionalClauseState.isEditMode
                    ? staticLanguageMap['noCommentsProvided'][userConfig.language]
                    : staticLanguageMap['provideComments'][userConfig.language])
                  : ''}
                disabled={(existingClauseSelected !== undefined && !state.additionalClauseState.isEditMode) || isPlayerSearchActive}
              />
            </div>
          )}

          {!isPlayerSearchActive && !isClubsExpanded && (
            <div className='clause-file-container fade-in'>

              <div className='clause-input-title' style={{ marginTop: state.fileUrls.length > 1 ? -6 : 6, width: 'fit-content', position: 'relative' }}>
                {userConfig ? staticLanguageMap['attachments'][userConfig.language] : ''}

                {state.fileUrls.length > 0 && (
                  <div style={{ position: 'absolute', left: -26, top: 0.5, fontSize: 12, color: '#ffffffaa' }}>
                    ({state.fileUrls.length})
                  </div>
                )}

                {(!existingClauseSelected || state.additionalClauseState.isEditMode) && (
                  <div style={{ position: 'absolute', right: -32, top: -3, zIndex: 1 }}>
                    <FileUpload handleFileUpload={handleFileUpload} language={userConfig?.language ?? 'en'} />
                  </div>
                )}
              </div>

              <div className='clause-file-container-existing-files-container'>
                {state.fileUrls.length === 0 && existingClauseSelected && !state.additionalClauseState.isEditMode && (
                  <div style={{ fontSize: 12, color: '#ffffffaa', marginTop: 30 }}>
                    {userConfig ? staticLanguageMap['noAttachments'][userConfig.language] : ''}
                  </div>
                )}
                <div className='clause-file-container-existing-files'>
                  {state.fileUrls.map((fileUrl, index) => {
                    if (state.fileNames.length <= index) return null;

                    const fileName = state.fileNames[index];
                    return (
                      <div
                        key={index}
                        className='clause-file-container-existing-file'
                        onClick={() => handleOpenPdfModal(fileUrl, fileName)}
                      >
                        {(!existingClauseSelected || state.additionalClauseState.isEditMode) && (
                          <div
                            className='clause-content-icon clause-file-container-existing-file-close-icon'
                            onClick={(event) => handleRemoveAttachmentClick(event, fileUrl, fileName)}
                          >
                            <CloseIcon style={{ fontSize: 18 }} />
                          </div>
                        )}
                        <PictureAsPdfIcon style={{ fontSize: 18, marginRight: 6 }} />
                        {getShortenedFileName(fileName, 22)}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}

        </div>

        <div className='clause-vertical-divider'>&nbsp;</div>

        <div className='clause-column clause-right-column'>

          <div className='clause-right-column-section clause-right-column-top-section'>
            <div className='clause-right-column-section-title-row'>
              <div className='clause-right-column-section-title'>
                {userConfig ? staticLanguageMap['payments'][userConfig.language] : ''}

                {(!existingClauseSelected || state.additionalClauseState.isEditMode) && (
                  <div
                    className='clause-content-icon'
                    style={{ position: 'absolute', right: -32, top: -4 }}
                    onClick={() => setIsPaymentModalOpen(true)}
                    title={userConfig
                      ? (state.payments.length === 0
                        ? staticLanguageMap['addPayments'][userConfig.language]
                        : staticLanguageMap['addOrEditPayments'][userConfig.language])
                      : undefined}>
                    {state.payments.length === 0 && <AddCircleOutlineIcon style={{ fontSize: 20 }} />}
                    {state.payments.length > 0 && <EditIcon style={{ fontSize: 18, marginTop: 1 }} />}
                  </div>
                )}
              </div>
            </div>

            <div className='clause-payment-row'>
              {state.payments.map((payment, index) => {
                return (
                  <div
                    className='clause-existing-payment'
                    style={{ borderColor: state.additionalClauseState.paymentHighlightIndex === index ? '#9df78b' : '#ffffff10' }}
                    key={index}>
                    <div className='clause-existing-payment-title text-no-wrap' style={{ fontSize: 11, color: '#ffffffdd' }}>
                      {getPaymentTitle(payment, userConfig?.language ?? 'en')}
                    </div>
                    <div className='clause-existing-payment-value' style={{ fontSize: 11, marginTop: 5, color: '#ffffffcc' }}>
                      {getDateLocaleFormat(payment.date)}
                    </div>
                    <div
                      className='clause-existing-payment-value'
                      style={{ display: 'flex', alignItems: 'baseline', fontSize: 12, marginLeft: 2, marginTop: 9, color: '#ffffff', textWrap: 'nowrap' }}
                    >
                      {getDisplayPaymentAmount(payment, state)}
                      <div style={{ marginLeft: 3, fontSize: 11, color: '#ffffffdd' }}>
                        {getPaymentCurrency(payment, state)}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className='clause-horizontal-divider'>&nbsp;</div>

          <div className='clause-right-column-section clause-right-column-bottom-section'>
            <div className='clause-right-column-section-title-row' style={{ marginTop: 8 }}>
              <div className='clause-right-column-section-title'>
                {userConfig ? staticLanguageMap['conditions'][userConfig.language] : ''}

                {(!existingClauseSelected || state.additionalClauseState.isEditMode) && (
                  <div
                    className='clause-content-icon'
                    style={{ position: 'absolute', right: -32, top: -4 }}
                    onClick={() => dispatch({ type: 'SET_IS_CONDITION_MODAL_OPEN', payload: true })}
                    title={userConfig ? (staticLanguageMap['add'][userConfig.language] + staticLanguageMap[' condition'][userConfig.language]) : undefined}>
                    <AddCircleOutlineIcon style={{ fontSize: 20 }} />
                  </div>
                )}
              </div>

              {(state.subClauses && state.subClauses.length > 0) && (
                <div className='clause-right-column-section-club-navigation-container'>

                  {buyingClub && (
                    <div
                      className={
                        'clause-right-column-section-club-logo-container' +
                        (state.additionalClauseState.activeSubClauseTab === undefined ? ' clause-right-column-section-club-logo-container-active' : '')
                      }
                      onClick={() => dispatch({ type: 'SET_ACTIVE_SUB_CLAUSE_TAB', payload: undefined })}
                    >
                      <img
                        style={{ maxHeight: 16, maxWidth: 16 }}
                        src={buyingClub.logo_url}
                        alt={buyingClub.name}
                        draggable={false}
                      />
                    </div>
                  )}

                  {state.subClauses.map((subClause, index) => {
                    const subClauseBuyingClub = subClause.buyingClubId !== undefined ? state.additionalClauseState.allClubs[subClause.buyingClubId] : undefined;
                    if (!subClauseBuyingClub) return null;

                    return (
                      <div
                        key={index}
                        className={
                          'clause-right-column-section-club-logo-container' +
                          (state.additionalClauseState.activeSubClauseTab === index ? ' clause-right-column-section-club-logo-container-active' : '')
                        }
                        onClick={() => dispatch({ type: 'SET_ACTIVE_SUB_CLAUSE_TAB', payload: index })}
                      >
                        <img
                          style={{ maxHeight: 16, maxWidth: 16 }}
                          src={subClauseBuyingClub.logo_url}
                          alt={subClauseBuyingClub.name}
                          draggable={false}
                        />
                      </div>
                    );
                  })}

                  {state.additionalClauseState.activeSubClauseTab !== undefined && (
                    <div
                      className='clause-right-column-section-club-navigation-vert-icon'
                      onClick={() => openConditionsModalInEditMode()}
                    >
                      <MoreVertIcon style={{ fontSize: 16 }} />
                    </div>
                  )}

                  {state.subClauses && state.additionalClauseState.activeSubClauseTab !== undefined && (
                    <div style={{ marginLeft: 3, fontSize: 11, color: '#ffffffcc' }}>
                      {state.subClauses[state.additionalClauseState.activeSubClauseTab].totalSellOnPercentage}%
                    </div>
                  )}

                  {state.subClauses
                    && state.additionalClauseState.activeSubClauseTab !== undefined
                    && state.subClauses[state.additionalClauseState.activeSubClauseTab].currency
                    && (
                      <div style={{ marginLeft: 5, fontSize: 11, color: '#ffffffcc' }}>
                        {state.subClauses[state.additionalClauseState.activeSubClauseTab].currency}
                      </div>
                    )
                  }
                </div>
              )}

              <div className='clause-right-column-section-club-max-amount-container'>
                <div
                  className='clause-right-column-section-club-max-amount-link'
                  onClick={() => setIsMaxConditionalFeesModalOpen(true)}
                  title={
                    userConfig
                      ? stateAccessorForCurrentConditionData.maxConditionalFees === null
                        ? staticLanguageMap['provideMaxAmountForConditions'][userConfig.language] + '?'
                        : staticLanguageMap['editMaxAmountForConditions'][userConfig.language]
                      : undefined
                  }
                >
                  {stateAccessorForCurrentConditionData.maxConditionalFees === null && userConfig && staticLanguageMap['maxAmount'][userConfig.language] + '?'}

                  {isMaxConditionalFeeReached && (
                    <div
                      className='clause-existing-condition-status-icon clause-existing-condition-resolved-icon'
                      style={{ pointerEvents: 'none' }}
                    >
                      <TaskAltIcon style={{ fontSize: 12, marginTop: -3 }} />
                    </div>
                  )}

                  {stateAccessorForCurrentConditionData.maxConditionalFees !== null && (
                    getDisplayEconomicValue(stateAccessorForCurrentConditionData.conditionalFees ?? 0, true)
                    + ' / ' +
                    getDisplayEconomicValue(stateAccessorForCurrentConditionData.maxConditionalFees, true)
                  )}
                </div>
              </div>
            </div>

            <Conditions
              existingClauseSelected={existingClauseSelected}
              state={state}
              dispatch={dispatch}
            />
          </div>

        </div>

      </div>

    </div >
  );
};
