import './../modals.css';

import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

import { staticLanguageMap } from '../../../../common/static/staticLanguageMap';
import { ConditionDropDown } from '../../input/ConditionDropDown';


interface SubConditionModalProps {
  newConditionThreshold: string;
  setNewConditionThreshold: (newThreshold: string) => void;

  minuteRequirementPerMatch: string | undefined;
  setMinuteRequirementPerMatch: (value: string) => void;

  addOrEditSeasonSubCondition: (index: number) => void;
  closeModal: () => void;

  seasonOrConditionIndex: number | undefined;
  isEdit: boolean;
  messageKey: string | undefined;

  seasonConditionOptions: string[];
  selectedConditionOption: string[];
  setSelectedConditionOption: (selectedOptions: string[]) => void;
  isConditionDropDownExpanded: boolean;
  setIsConditionDropDownExpanded: (isExpanded: boolean) => void;

  onKeyDownInputField: (event: React.KeyboardEvent<HTMLInputElement>, conditionType: string, seasonOrConditionIndex?: number) => void;
  getSeasonThresholdInputPlaceholder: () => string;
}

export const SubConditionModal: React.FC<SubConditionModalProps> = ({
  newConditionThreshold,
  setNewConditionThreshold,

  minuteRequirementPerMatch,
  setMinuteRequirementPerMatch,

  addOrEditSeasonSubCondition,
  closeModal,

  seasonOrConditionIndex,
  isEdit,
  messageKey,

  seasonConditionOptions,
  selectedConditionOption,
  setSelectedConditionOption,
  isConditionDropDownExpanded,
  setIsConditionDropDownExpanded,

  onKeyDownInputField,
  getSeasonThresholdInputPlaceholder,
}) => {

  const userConfig = useRecoilValue(userConfigState);


  return (
    <div className='modal-root-container' style={{ width: 550, height: 370 }}>

      {isConditionDropDownExpanded && (
        <div className='modal-empty-background' onClick={() => setIsConditionDropDownExpanded(false)}>
          &nbsp;
        </div>
      )}

      <CloseIcon
        className='modal-icon modal-exit-icon'
        style={{ fontSize: 24 }}
        onClick={() => closeModal()}
      />

      <div className='modal-root-title'>
        {userConfig
          ? (isEdit ? staticLanguageMap['editCondition'][userConfig.language] : staticLanguageMap['addCondition'][userConfig.language])
          : ''}
      </div>

      <div className='modal-divider modal-root-title-divider'>&nbsp;</div>

      <div className='condition-modal-new-total-condition-section'>
        <div className='payment-modal-new-payment-section'>
          <div className='condition-modal-season-condition-input-row' style={{ width: 500 }}>
            <div className='condition-modal-season-condition-drop-down'>
              <ConditionDropDown
                id={'clauses-modal-condition-select'}
                dropDownOptions={seasonConditionOptions}
                selectedOptions={selectedConditionOption}
                setSelectedOptions={setSelectedConditionOption}
                isDropDownExpanded={isConditionDropDownExpanded}
                setIsDropDownExpanded={setIsConditionDropDownExpanded}
                defaultDropDownText={'selectCondition'}
                defaultDropDownTextColor={'#000000'}
                marginBetweenOptions={4}

                minuteRequirementPerMatch={minuteRequirementPerMatch}
                setMinuteRequirementPerMatch={setMinuteRequirementPerMatch}

                maxHeight={'60vh'}
                height={26}
              />
            </div>

            <input
              className={
                'document-input-field document-input-field-small condition-modal-season-threshold-input' +
                (selectedConditionOption.length === 0 ? ' document-input-field-disabled' : '')
              }
              style={{ width: 240, height: 26, top: -1 }}
              name='condition-modal-input-field-amount'
              type='text'
              autoComplete='off'
              value={newConditionThreshold}
              onChange={(event) => setNewConditionThreshold(event.target.value)}
              onKeyDown={(event) => onKeyDownInputField(event, 'season', seasonOrConditionIndex)}
              disabled={selectedConditionOption.length === 0}
              placeholder={getSeasonThresholdInputPlaceholder()}
            />
          </div>

          <div className='payment-modal-divider' style={{ marginTop: 46 }}>&nbsp;</div>

          <div
            className='document-submit-button'
            style={{ height: 25 }}
            onClick={() => seasonOrConditionIndex !== undefined ? addOrEditSeasonSubCondition(seasonOrConditionIndex) : null}
          >
            <div style={{ marginLeft: 12 }}>
              {userConfig
                ? (isEdit ? staticLanguageMap['editCondition'][userConfig.language] : staticLanguageMap['addCondition'][userConfig.language])
                : ''}
            </div>

            <CheckIcon
              style={{ fontSize: 20, marginLeft: 10, marginRight: 4 }}
            />
          </div>
        </div>
      </div>

      {messageKey && (
        <div className='payment-modal-message' style={{ bottom: 60, width: '100%', textAlign: 'center' }}>
          {userConfig ? staticLanguageMap[messageKey][userConfig.language] : ''}
        </div>
      )}

    </div>
  );
};
