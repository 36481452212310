import './../modals.css';

import CloseIcon from '@mui/icons-material/Close';


interface TextModalProps {
  text: string;
  closeModal: () => void;
  width?: number;
  fontSize?: number;
}


export const TextModal: React.FC<TextModalProps> = ({ text, closeModal, width, fontSize }) => {

  const textArray = text.split('\n');

  return (
    <div className='modal-root-container' style={{
      width: width ?? 500,
      paddingTop: 60,
      paddingBottom: 60,
      paddingLeft: 30,
      paddingRight: 30,
      textAlign: 'center',
      lineHeight: '24px',
      fontSize: fontSize ?? 15,
      boxSizing: 'border-box',
      overflowY: 'auto',
    }}>

      <CloseIcon
        className='modal-icon modal-exit-icon'
        style={{ fontSize: 24 }}
        onClick={() => closeModal()}
      />

      {textArray.map((textLine: string, index: number) => {
        const isBulletPoint = textLine.startsWith('-');
        return (
          <div
            key={index}
            style={{
              marginTop: index > 0 ? 12 : undefined,
              fontSize: isBulletPoint ? 14 : undefined,
              textAlign: isBulletPoint ? 'left' : undefined,
              marginLeft: isBulletPoint ? 10 : undefined,
            }}>
            {textLine}
          </div>
        );
      })}

    </div>
  );
};
