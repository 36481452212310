import './../modals.css';

import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import ReplayIcon from '@mui/icons-material/Replay';

import { staticLanguageMap } from '../../../../common/static/staticLanguageMap';
import { InputField } from '../../input/InputField';
import { getDisplayEconomicValue } from '../../../utils/currencyUtils';
import { ClauseState } from '../../documents/clause/clauseInitialState';
import { ClauseAction } from '../../documents/clause/clauseReducer';


interface MaxConditionalFeesModalProps {
  closeModal: () => void;
  state: ClauseState;
  dispatch: React.Dispatch<ClauseAction>;
}

export const MaxConditionalFeesModal: React.FC<MaxConditionalFeesModalProps> = ({
  closeModal,
  state,
  dispatch,
}) => {

  const userConfig = useRecoilValue(userConfigState);

  const stateAccessor = state.additionalClauseState.activeSubClauseTab !== undefined && state.subClauses
    ? state.subClauses[state.additionalClauseState.activeSubClauseTab]
    : state;

  const [maxAmount, setMaxAmount] = useState(stateAccessor.maxConditionalFees ? stateAccessor.maxConditionalFees.toString() : '');

  const [messageKey, setMessageKey] = useState<string | undefined>(undefined);


  const isValidMaxAmount = (maxAmount: string) => {
    const maxAmountNumber = Number(maxAmount);
    if (isNaN(maxAmountNumber) || maxAmountNumber < 0) {
      setMessageKey('invalidMaxAmount');
      return false;
    }
    return true;
  };


  const handleConfirmMaxConditionalFees = () => {
    if (isValidMaxAmount(maxAmount)) {
      dispatch({ type: 'SET_MAX_CONDITIONAL_FEES', payload: Number(maxAmount) });
      closeModal();
    }
  };


  const handleResetMaxConditionalFees = () => {
    dispatch({ type: 'SET_MAX_CONDITIONAL_FEES', payload: null });
    closeModal();
  };


  const onChangeSearchField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessageKey(undefined);
    setMaxAmount(event.target.value.replaceAll('.', ''));
  };


  const onKeyDownSearchField = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleConfirmMaxConditionalFees();
    }
  };

  const resetSearchString = () => {
    setMessageKey(undefined);
    setMaxAmount('');
  };


  const isButtonDisabled = () => {
    return maxAmount === '' || (state.maxConditionalFees && maxAmount === state.maxConditionalFees.toString());
  };


  return (
    <div className='modal-root-container' style={{ width: 500, height: 400 }}>

      <div title={userConfig ? staticLanguageMap['deleteMaxAmount'][userConfig.language] : undefined}>
        <ReplayIcon
          className='modal-icon modal-back-icon'
          style={{ fontSize: 24 }}
          onClick={() => handleResetMaxConditionalFees()}
        />
      </div>

      <CloseIcon
        className='modal-icon modal-exit-icon'
        style={{ fontSize: 24 }}
        onClick={() => closeModal()}
      />

      <div className='modal-root-title'>
        {userConfig ? staticLanguageMap['provideMaxAmountForConditions'][userConfig.language] : ''}
      </div>

      <div className='modal-divider modal-root-title-divider'>&nbsp;</div>

      <div className='confirm-modal-info'>
        {userConfig ? staticLanguageMap['provideMaxAmountForConditionsInfo'][userConfig.language] : ''}
      </div>

      {messageKey && (
        <div className='edit-player-info-modal-invalid-input-message' style={{ top: 265 }}>
          {userConfig ? staticLanguageMap[messageKey][userConfig.language] : ''}
        </div>
      )}

      <div className='max-conditional-fees-modal-input-section'>
        <InputField
          searchString={getDisplayEconomicValue(maxAmount)}
          onChangeInputField={onChangeSearchField}
          onKeyDownInputField={onKeyDownSearchField}
          resetSearchString={resetSearchString}
          defaultInput={userConfig ? staticLanguageMap['provideMaxAmount'][userConfig.language] : ''}
          showDefaultInput={true}
        />
      </div>

      <div
        className={'modal-button modal-button-bottom' + (isButtonDisabled() ? ' modal-button-disabled' : '')}
        style={{ marginLeft: 150, bottom: 50 }}
        onClick={() => !isButtonDisabled() ? handleConfirmMaxConditionalFees() : null}
      >
        <div className='modal-button-text'>
          {userConfig ? staticLanguageMap['confirmMaxAmount'][userConfig.language] : ''}
        </div>

        <CheckIcon
          className='modal-button-icon'
          style={{ fontSize: 24 }}
        />
      </div>

    </div>
  );
};
