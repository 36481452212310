import '../../platform.css';

import { useRecoilValue } from 'recoil';
import { teamsState } from '../../recoil/atoms/teamsState';

import { selectedPlayerState } from '../../recoil/atoms/selectedPlayerState';
import { PlayerView } from '../../components/playerView/PlayerView';
import { playerOverviewsState } from '../../recoil/atoms/playerOverviewsState';
import { LogisticTeamView } from '../../components/teamView/LogisticTeamView';
import { useState } from 'react';
import { PlayerOverviews } from '../../types';


interface SingleTeamProps {
  teamId: string;
}

export const SingleTeam: React.FC<SingleTeamProps> = ({ teamId }) => {

  const selectedPlayer = useRecoilValue(selectedPlayerState);
  const playerOverviews = useRecoilValue(playerOverviewsState);

  const teams = useRecoilValue(teamsState);

  const [searchedPlayerData, setSearchedPlayerData] = useState<PlayerOverviews | undefined>(undefined);

  return (
    <div className='platform-view-section platform-view-section-dark'>

      <div className={'player-view-outer-container' + (selectedPlayer ? ' player-view-outer-container-player-selected' : '')}>
        {selectedPlayer && playerOverviews && (
          <PlayerView
            playerOverview={playerOverviews[Number(selectedPlayer.id)] ?? (searchedPlayerData ? searchedPlayerData[Number(selectedPlayer.id)] : undefined)}
          />
        )}
      </div>

      <LogisticTeamView
        teamOrSquadId={teamId}
        teamOrSquad={teams ? teams[teamId] : undefined}
        isTabLine={false}
        playerData={searchedPlayerData}
        setPlayerData={setSearchedPlayerData}
      />

    </div>
  );
};
