import '../../platform.css';
import './teamView.css';

import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { playersState } from '../../recoil/atoms/playersState';
import { playerOverviewsState } from '../../recoil/atoms/playerOverviewsState';
import { settingsState } from '../../recoil/atoms/settingsState';

import { FlexibleJsonMapping, JsonMapping, PlayerEntry, PlayerId } from '../../types';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import MenuIcon from '@mui/icons-material/Menu';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import RestoreIcon from '@mui/icons-material/Restore';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { getTeamViewData } from '../../utils/teamViewUtils';
import { FootballFieldSvg } from '../../svg/FootballFieldSvg';
import { PlayerSimpleTable } from '../tables/playerSimpleTable/PlayerSimpleTable';
import { staticLanguageMap } from '../../../common/static/staticLanguageMap';
import { getEmptyTableWidth } from '../tables/playerSimpleTable/playerSimpleColumns';
import { getAge, getStrictPlayerId } from '../../utils/playerUtils';
import { roleKeysToSortOrder } from '../../static/propertyValues';
import { removePlayerFromTeamOrSquad } from '../../services/firestore/teamsOrSquads';
import { deleteOwnTeamAndAcademyPlayerData, deletePlayerDocument } from '../../services/firestore/players';
import { LastDraggedOwnTeamPlayerData } from './LogisticTeamView';
import { userHasFullOwnTeamAccess } from '../../utils/userUtils';


interface TeamViewProps {
  teamOrSquadId: string | undefined;
  teamOrSquadData: FlexibleJsonMapping | undefined;
  isSquad?: boolean | undefined;

  draggedPlayer?: JsonMapping | undefined;
  setDraggedPlayer?: (player: JsonMapping | undefined) => void;
  handlePlayerDrop?: (newPosition: string) => void;
  setLastDraggedOwnTeamPlayerData?: (draggedPlayerData: LastDraggedOwnTeamPlayerData) => void;

  onPlayerClick?: (player: FlexibleJsonMapping) => void;

  isLeftSectionExpanded?: boolean;
  setIsLeftSectionExpanded?: (isExpanded: boolean) => void;
  rightSectionExpansionType?: string | undefined;
  setRightSectionExpansionType?: (expansionType: string | undefined) => void;

  showFirstEleven?: boolean;

  isArchivedTeamDeleted?: boolean;
  closeArchivedTeam?: () => void;
  deleteOrRestoreArchivedTeam?: () => void;
  newPlayerOverviews?: FlexibleJsonMapping;

  highlightedPlayerId?: PlayerId | undefined;
}

export const TeamView: React.FC<TeamViewProps> = ({
  teamOrSquadId,
  teamOrSquadData,
  isSquad,

  draggedPlayer,
  setDraggedPlayer,
  handlePlayerDrop,
  setLastDraggedOwnTeamPlayerData,

  onPlayerClick,

  isLeftSectionExpanded,
  setIsLeftSectionExpanded,
  rightSectionExpansionType,
  setRightSectionExpansionType,

  showFirstEleven,

  isArchivedTeamDeleted,
  closeArchivedTeam,
  deleteOrRestoreArchivedTeam,
  newPlayerOverviews,

  highlightedPlayerId,
}) => {

  const userConfig = useRecoilValue(userConfigState);
  const settings = useRecoilValue(settingsState);

  const players = useRecoilValue(playersState);
  const playerOverviews = useRecoilValue(playerOverviewsState);

  const [isExpanding, setIsExpanding] = useState(false);

  const [isIconHighlighted, setIsIconHighlighted] = useState(false);

  const [isGoalkeeperInTeam, setIsGoalkeeperInTeam] = useState(false);
  const [data, setData] = useState<FlexibleJsonMapping[] | undefined>(undefined);

  const formation = teamOrSquadData ? teamOrSquadData['formation'] : undefined;

  const teamMenu = (teamOrSquadId === 'ownTeam'
    ? settings?.userSettings?.ownTeamMenu
    : teamOrSquadId === 'academyTeam'
      ? settings?.userSettings?.academyTeamMenu
      : settings?.userSettings?.teamMenu) ?? {};


  const handleAddPlayerIconClick = () => {
    if (!setIsLeftSectionExpanded || !setRightSectionExpansionType) return;

    setIsExpanding(true);
    setIsLeftSectionExpanded(!isLeftSectionExpanded);
    setRightSectionExpansionType(undefined);
    setTimeout(() => {
      setIsExpanding(false);
    }, 250);
  };

  const handleRightIconClick = (expansionType: string) => {
    if (!setIsLeftSectionExpanded || !setRightSectionExpansionType) return;

    const isDeexpansion = rightSectionExpansionType === expansionType;
    setRightSectionExpansionType(isDeexpansion ? undefined : expansionType);

    setIsExpanding(true);
    setIsLeftSectionExpanded(false);
    setTimeout(() => {
      setIsExpanding(false);
    }, 250);
  };


  const deleteOrRestoreArchivedTeamInternal = () => {
    if (!deleteOrRestoreArchivedTeam) return;

    deleteOrRestoreArchivedTeam();

    if (isArchivedTeamDeleted) {
      setIsIconHighlighted(true);
      setTimeout(() => {
        setIsIconHighlighted(false);
      }, 750);
    }
  };


  const getTableData = (positionData: FlexibleJsonMapping) => {
    const tableData: FlexibleJsonMapping[] = [];

    positionData['players'].forEach((playerEntry: PlayerEntry) => {

      let player: FlexibleJsonMapping = { ...playerEntry };

      const playerDocument = (players && players[player.id]) ?? {};

      // should hide unavilable players on ownTeam if toggle is set
      const shouldHidePlayerDueToStartingEleven =
        teamOrSquadId === 'ownTeam' &&
        showFirstEleven &&
        !playerDocument['isStartingEleven'];

      const shouldHidePlayerDueToAvailability =
        teamOrSquadId === 'ownTeam' &&
        settings &&
        settings.userSettings &&
        settings.userSettings.ownTeamMenu &&
        settings.userSettings.ownTeamMenu.hideUnavailablePlayers &&
        (playerDocument.role === 'longTermInjured' || playerDocument.role === 'loanedOut');

      if (player && !(shouldHidePlayerDueToStartingEleven || shouldHidePlayerDueToAvailability)) {

        if (playerOverviews && player.id in playerOverviews) {

          player['image_url'] = playerOverviews[player.id].image_url;

          player['birth_date'] = playerOverviews[player.id].birth_date;
          player['country_code'] = playerOverviews[player.id].country_code;
          player['contract_expiration'] = playerOverviews[player.id].contract_expiration;

          player['skill_index'] = playerOverviews[player.id].overall?.skill_index;
          player['club_index'] = playerOverviews[player.id].overall?.club_index;
          player['primary_position'] = playerOverviews[player.id].primary_position;
          player['club_logo_url'] = playerOverviews[player.id].club?.logo_url;
        }

        if (!player['image_url'] && newPlayerOverviews && player.id in newPlayerOverviews) {
          player['image_url'] = newPlayerOverviews[player.id].image_url;
        }

        player = { ...player, ...playerDocument };

        player['age'] = getAge(player['birth_date']);

        tableData.push(player);
      }
    });

    const orderByProperty: string | undefined = teamMenu.orderBy ?? ((teamOrSquadId === 'ownTeam' || teamOrSquadId === 'academyTeam') ? 'role' : undefined);

    tableData.sort((a: FlexibleJsonMapping, b: FlexibleJsonMapping) => {
      if (!orderByProperty) return 0;

      if (!a[orderByProperty]) return 1;
      if (!b[orderByProperty]) return -1;

      const aValue = orderByProperty === 'role'
        ? (a[orderByProperty] in roleKeysToSortOrder ? roleKeysToSortOrder[a[orderByProperty]] : 0)
        : a[orderByProperty];

      const bValue = orderByProperty === 'role'
        ? (b[orderByProperty] in roleKeysToSortOrder ? roleKeysToSortOrder[b[orderByProperty]] : 0)
        : b[orderByProperty];

      return aValue > bValue ? -1 : 1;
    });

    return tableData;
  };


  const getRowClassName = (rowIndex: number, rowLength: number) => {
    const base = 'row-container';
    const indices = '-' + rowIndex + '-' + rowLength;
    return base + ' ' + base + indices + ' ' + base + indices + (formation ? '-' + formation : '');
  };

  const getPositionClassName = (rowIndex: number, rowLength: number, posIndex: number) => {
    const base = 'position-container';
    const indices = '-' + rowIndex + '-' + rowLength + '-' + posIndex;
    return base + ' ' + base + indices + ' ' + base + indices + (formation ? '-' + formation : '');
  };


  useEffect(() => {
    if (!teamOrSquadData || !formation) return;

    const teamData = getTeamViewData(teamOrSquadData, formation);
    const isGoalkeeperInTeam = teamData && teamData[3][0]['players'].length > 0;

    setIsGoalkeeperInTeam(isGoalkeeperInTeam);
    setData(teamData);
  }, [formation, teamOrSquadData]);


  const tableType = teamOrSquadId === 'ownTeamPlanner'
    ? 'ownTeamPlanner'
    : teamOrSquadId === 'archivedTeam'
      ? 'archivedTeam'
      : teamOrSquadId === 'ownTeam'
        ? 'ownTeam'
        : teamOrSquadId === 'academyTeam'
          ? 'academyTeam'
          : 'team';


  // Drag section for deleting players
  const [isDraggedOver, setIsDraggedOver] = useState(false);
  const [playerWasRecentlyRemoved, setPlayerWasRecentlyRemoved] = useState(false);

  const showDeleteIcon = draggedPlayer && draggedPlayer['positionKey'] !== 'addPlayerTable' && draggedPlayer['positionKey'] !== 'addPlayerTableExistingPlayers';

  const handleDragDrop = () => {

    if (draggedPlayer !== undefined && teamOrSquadId && userConfig) {
      const playerId = getStrictPlayerId(draggedPlayer['id']);
      removePlayerFromTeamOrSquad(playerId, teamOrSquadId, isSquad ?? false, userConfig.email, userConfig.club);

      if (teamOrSquadId === 'ownTeam' || teamOrSquadId === 'academyTeam') {

        // if this is a player in the database, we delete the player document
        if (!isNaN(Number(playerId))) {
          deletePlayerDocument(playerId, userConfig.club);

          // if player was removed from ownTeam, open modal to add clause
          if (teamOrSquadId === 'ownTeam' && userHasFullOwnTeamAccess(userConfig)) {
            if (!isNaN(Number(playerId))) {
              if (setLastDraggedOwnTeamPlayerData) {
                setLastDraggedOwnTeamPlayerData({
                  playerId: Number(playerId),
                  playerName: draggedPlayer['fullname'],
                  wasAdded: false,
                });
              }
            }
          }
        }

        // if this is a player without data, we only delete the ownTeam and academyTeam player data
        else {
          deleteOwnTeamAndAcademyPlayerData(playerId, userConfig.club);
        }
      }
    }

    setPlayerWasRecentlyRemoved(true);
    handleDragLeave();

    setTimeout(() => {
      setPlayerWasRecentlyRemoved(false);
    }, 250);

  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDragEnter = () => {
    setIsDraggedOver(true);
  };

  const handleDragLeave = () => {
    setIsDraggedOver(false);
  };


  const getBoxShadow = () => {
    if (isDraggedOver) {
      return '0px 0px 0px 3px ' + '#ff2424';
    }
    return 'none';
  };


  const getMaxTableHeight = (positionRowIndex: number) => {
    // if (teamMenu['scrollPlayers_' + teamOrSquadId]) {
    //   return '15vh';
    // }
    if (teamMenu.scrollAttackers && positionRowIndex === 0) {
      return '14vh';
    }
    if (teamMenu.scrollMidfielders && positionRowIndex === 1) {
      return '14vh';
    }
    if (teamMenu.scrollDefenders && positionRowIndex === 2) {
      return '14vh';
    }
    if (teamMenu.scrollGoalkeepers && positionRowIndex === 3) {
      return '14vh';
    }
    return undefined;
  };


  const shouldDisableAddPlayerIcon = teamOrSquadId === 'ownTeam' && !userHasFullOwnTeamAccess(userConfig);


  return (
    <div className='team-view-container'>

      <FootballFieldSvg />

      <div className={'team-view-players-section' + (isGoalkeeperInTeam ? ' team-view-players-section-with-goalkeeper' : '')}>

        {data && data.map((row, rowIndex) => (
          <div
            key={rowIndex}
            className={getRowClassName(rowIndex, row.length)}>

            {row.map((positionData: FlexibleJsonMapping, posIndex: number) => (
              <div
                key={rowIndex + posIndex}
                className={getPositionClassName(rowIndex, row.length, posIndex)}>

                <div
                  className='team-view-player-section'
                  style={{ width: getEmptyTableWidth(tableType, teamMenu) }}>
                  <PlayerSimpleTable
                    data={getTableData(positionData)}
                    tableType={tableType}
                    positionKey={positionData['position']}
                    draggedPlayer={draggedPlayer}
                    setDraggedPlayer={setDraggedPlayer}
                    handlePlayerDrop={handlePlayerDrop}
                    onPlayerClick={onPlayerClick}

                    maxHeight={getMaxTableHeight(rowIndex)}
                    scrollBarColor={'#919dcd'}

                    highlightedPlayerId={highlightedPlayerId}
                  />
                </div>

              </div>
            ))}

          </div>
        ))}

      </div>

      {showDeleteIcon && !shouldDisableAddPlayerIcon && (
        <div
          className='team-view-delete-player-icon-container-overlay'
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDrop={handleDragDrop}>
          &nbsp;
        </div>
      )}

      {teamOrSquadId !== 'archivedTeam' && teamOrSquadId !== 'ownTeamPlanner' && !shouldDisableAddPlayerIcon && (
        <div
          style={{ boxShadow: getBoxShadow(), opacity: playerWasRecentlyRemoved ? 0 : 1 }}
          className={'team-view-circular-button team-view-add-player-button' + (showDeleteIcon ? ' team-view-delete-player-icon-container' : '')}
          onClick={() => !showDeleteIcon ? handleAddPlayerIconClick() : null}>
          {!showDeleteIcon && !isLeftSectionExpanded && <PersonAddAlt1Icon style={{ fontSize: 24, marginLeft: 2, marginTop: -2 }} />}
          {!showDeleteIcon && isLeftSectionExpanded && <KeyboardArrowLeftIcon style={{ fontSize: 24, marginLeft: -1 }} />}
          {showDeleteIcon && <DeleteForeverIcon style={{ fontSize: 28 }} />}

          {!isLeftSectionExpanded && !isExpanding && (
            <div className='team-view-circular-button-hover-text-container'>
              <div className='team-view-circular-button-hover-text'>
                {userConfig ? staticLanguageMap['addPlayer'][userConfig.language] : ''}
              </div>
            </div>
          )}
        </div>
      )}

      {teamOrSquadId !== 'archivedTeam' && teamOrSquadId !== 'ownTeamPlanner' && (
        <div
          className='team-view-circular-button team-view-history-button'
          onClick={() => handleRightIconClick('history')}>
          {rightSectionExpansionType !== 'history' && <LibraryBooksIcon style={{ fontSize: 22 }} />}
          {rightSectionExpansionType === 'history' && <KeyboardArrowRightIcon style={{ fontSize: 24 }} />}
          {rightSectionExpansionType !== 'history' && !isExpanding && (
            <div className='team-view-circular-button-hover-text-container'>
              <div className='team-view-circular-button-hover-text'>
                {userConfig ? staticLanguageMap['log'][userConfig.language] : ''}
              </div>
            </div>
          )}
        </div>
      )}

      {teamOrSquadId !== 'archivedTeam' && teamOrSquadId !== 'ownTeamPlanner' && (
        <div
          className='team-view-circular-button team-view-menu-button'
          onClick={() => handleRightIconClick('menu')}>
          {rightSectionExpansionType !== 'menu' && <MenuIcon style={{ fontSize: 22 }} />}
          {rightSectionExpansionType === 'menu' && <KeyboardArrowRightIcon style={{ fontSize: 24 }} />}
          {rightSectionExpansionType !== 'menu' && !isExpanding && (
            <div className='team-view-circular-button-hover-text-container'>
              <div className='team-view-circular-button-hover-text'>
                {userConfig ? staticLanguageMap['teamMenu'][userConfig.language] : ''}
              </div>
            </div>
          )}
        </div>
      )}


      {teamOrSquadId === 'archivedTeam' && closeArchivedTeam && (
        <div
          className='team-view-circular-button team-view-close-button'
          onClick={() => closeArchivedTeam()}>
          {<CloseIcon style={{ fontSize: 22 }} />}
        </div>
      )}

      {teamOrSquadId === 'archivedTeam' && deleteOrRestoreArchivedTeam && (
        <div
          className={'team-view-circular-button team-view-delete-or-restore-button' + (isIconHighlighted ? ' team-view-circular-button-highlighted' : '')}
          onClick={() => deleteOrRestoreArchivedTeamInternal()}>
          {isArchivedTeamDeleted && <RestoreIcon style={{ fontSize: 22 }} />}
          {!isArchivedTeamDeleted && <DeleteOutlineIcon style={{ fontSize: 22 }} />}
          {!isIconHighlighted && (
            <div className='team-view-circular-button-hover-text-container'>
              <div className='team-view-circular-button-hover-text'>
                {userConfig
                  ? (isArchivedTeamDeleted ? staticLanguageMap['restore'][userConfig.language] : staticLanguageMap['delete'][userConfig.language])
                  : ''}
              </div>
            </div>
          )}
        </div>
      )}

    </div>
  );
};
