import '../../platform.css';
import './economy.css';

import { useState, useEffect, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { settingsState } from '../../recoil/atoms/settingsState';
import { navigationPropsState } from '../../recoil/atoms/navigationPropsState';
import { selectedPlayerState } from '../../recoil/atoms/selectedPlayerState';
import { playersState } from '../../recoil/atoms/playersState';
import { playerOverviewsState } from '../../recoil/atoms/playerOverviewsState';
import { playerDetailsState } from '../../recoil/atoms/playerDetailsState';
import { useGlobalModal } from '../../recoil/hooks/openGlobalModal';
import { AuthContextType, useAuthContext } from '../../../common/contexts/AuthContext';

import AddIcon from '@mui/icons-material/Add';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
// import ContactPageIcon from '@mui/icons-material/ContactPage';
// import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';

import {
  Club,
  ClubIteration,
  ClubIterationMap,
  ClubMapping,
  FlexibleJsonMapping,
  PlayerClause,
  PlayerDetails,
  PlayerId,
  PlayerMapping,
  PlayerOverviews,
  SeasonCondition,
  SeasonOrCondition,
  SeasonSubCondition,
  TotalCondition,
} from '../../types';

import { PlayerView } from '../../components/playerView/PlayerView';
import { FixedTabLine } from '../../components/tabLines/FixedTabLine';
import { getPlayerOverviews } from '../../services/fokusServer/playerOverviews';
import { staticLanguageMap } from '../../../common/static/staticLanguageMap';
import { OwnTeamBackgoundSvg } from '../../svg/OwnTeamBackgroundSvg';
import { getAllClauses } from '../../services/firestore/playerClauses';
import { Clause } from '../../components/documents/clause/Clause';
import { ExistingClauses } from '../../components/existingDocuments/ExistingClauses';
import { getClubs } from '../../services/fokusServer/clubs';
import { getPlayerDetails } from '../../services/fokusServer/playerDetails';
import { allSubConditionsAreAchievedOrPotentiallyReachedForSeason } from '../../components/accordions/SeasonAccordion';
import { getCurrentSellOnPercentage } from '../../components/modals/clauseModals/ConditionModal';


// Get the year of the first next season after the transfer date, for full-year and cross-year seasons
export const getYearOfFirstNextSeason = (transferDate: Date, isCrossYearSeason: boolean): number => {
  const month = transferDate.getMonth();
  const year = transferDate.getFullYear();

  if (isCrossYearSeason) {
    // if the transfer happens in May (index 4) or later, we assume the first next season starts after that summer
    return month < 4
      ? year - 1
      : year;
  }

  // if the transfer happens in November (index 10) or later, we assume the first next season starts next year
  return month < 10
    ? year
    : year + 1;
};


// Get the approximate start date of a season based on the season string
export const getSeasonStartDate = (seasonString: string): Date => {
  const seasonStringArray = seasonString.split('/');
  let year = seasonStringArray[0];
  let month = 1;
  if (seasonStringArray.length === 2) {
    month = 7;
    year = '20' + year;
  }
  return new Date(Number(year), month, 1);
};


// Add relevant data to a sub clause, or to the main clause if subClauseIndex is undefined
export const addSubClauseData = (
  clause: PlayerClause,
  subClauseIndex: number | undefined,
  detailsArray: ClubIteration[],
  clubs: ClubMapping,
): [boolean, number | undefined] => {

  const subClauseAccessor: FlexibleJsonMapping = subClauseIndex !== undefined && clause.subClauses
    ? clause.subClauses[subClauseIndex]
    : clause;

  const transferDate = new Date(subClauseAccessor.transferDate);
  const firstFullYearSeason = getYearOfFirstNextSeason(transferDate, false);
  const firstCrossYearSeason = getYearOfFirstNextSeason(transferDate, true);
  const sellingClubId = subClauseAccessor.sellingClubId;
  const buyingClubId = subClauseAccessor.buyingClubId;

  // iterate through all seasons and collect relevant player data
  let showNotification = false;
  let likelyNextClubId: number | undefined = undefined;

  const incorrectNextClubIds: number[] = [...subClauseAccessor.incorrectNextClubIds ?? []];
  const incorrectClubIdToIncorrectSeasons: FlexibleJsonMapping = {};

  // we want to start collecting data from the first occurence of the buying club after the transfer date, and stop when a new club is detected
  let hasFoundBuyingClub = false;

  const competitionToSeasonToMatchDetails: FlexibleJsonMapping = {};
  detailsArray.forEach((details: ClubIteration) => {

    const clubThisSeason: Club = details['club'];

    const seasonString = details['season'];
    const seasonStringArray = seasonString.split('/');
    const isCrossYearSeason = seasonStringArray.length === 2;

    let seasonYear = seasonStringArray[0];
    if (isCrossYearSeason) {
      seasonYear = '20' + seasonYear;
    }
    const seasonYearNumber = Number(seasonYear);

    // only consider seasons after the transfer date
    if ((isCrossYearSeason && seasonYearNumber < firstCrossYearSeason) || (!isCrossYearSeason && seasonYearNumber < firstFullYearSeason)) return;

    // only consider seasons for other clubs than the selling club
    if (!clubThisSeason || clubThisSeason.id === sellingClubId) return;

    // if likelyNextClub exists, the clause is likely completed and all relevant data has been collected, unless the player is on loan to likelyNextClub
    if (likelyNextClubId === undefined) {
      if ((clubThisSeason.id === buyingClubId || clubThisSeason.is_national_team)) {

        if (clubThisSeason.id === buyingClubId) {
          hasFoundBuyingClub = true;
        }

        // match_deatils should always exists, but not guranteed yet
        if (details['match_details']) {

          // special handling for european cups where qualifiers are extracted into their own virtual competition European Qualifiers with id 100000
          let competitionIdList: number[] = [];
          let competitionMatchDetailsList: FlexibleJsonMapping[][] = [];
          if (details['competition_id'] === 184 || details['competition_id'] === 185 || details['competition_id'] === 202) {
            competitionIdList = [
              details['competition_id'],
              100000,
            ];
            competitionMatchDetailsList = [
              details['match_details'].filter((matchDetail: FlexibleJsonMapping) => matchDetail['round'].toLowerCase()[0] !== 'q'),
              details['match_details'].filter((matchDetail: FlexibleJsonMapping) => matchDetail['round'].toLowerCase()[0] === 'q'),
            ];
          }
          else {
            // special handling for national teams
            const competitionId = clubThisSeason.is_national_team
              ? clubThisSeason.name ? -2 : -1 // if name exist, this is a youth national team mapping (id -2), else it is the national team (id -1)
              : details['competition_id'];

            competitionIdList = [competitionId];
            competitionMatchDetailsList = [details['match_details']];
          }

          for (let i = 0; i < competitionIdList.length; i++) {
            const competitionId = competitionIdList[i];
            const competitionMatchDetails = competitionMatchDetailsList[i];

            if (competitionId in competitionToSeasonToMatchDetails) {
              // since national competition ids are merged into virtual ids, the seasonString may already exist
              let seasonData = competitionToSeasonToMatchDetails[competitionId]['seasonData'][seasonString] ?? [];
              seasonData = [...seasonData, ...competitionMatchDetails];

              competitionToSeasonToMatchDetails[competitionId]['seasonData'][seasonString] = seasonData;
            }
            else {
              competitionToSeasonToMatchDetails[competitionId] = {
                seasonData: {
                  [seasonString]: competitionMatchDetails
                },
                isCrossYearCompetition: isCrossYearSeason,
              };
            }
          }
        }
      }
      else {
        if (incorrectNextClubIds.includes(clubThisSeason.id)) {
          // if season is 22/23 (for example), we exclude 22/23 and 2023 seasons
          // if season is 2022 (for example), we exclude 2022 and 22/23 seasons
          const newSupersetSeasonStrings = isCrossYearSeason
            ? [seasonString, (Number(seasonYear) + 1) + '']
            : [seasonString, seasonString.substring(2, 4) + '/' + (Number(seasonString.substring(2, 4)) + 1)];

          const existingIncorrectSeasons = incorrectClubIdToIncorrectSeasons[clubThisSeason.id] ?? [];
          incorrectClubIdToIncorrectSeasons[clubThisSeason.id] = [...existingIncorrectSeasons, ...newSupersetSeasonStrings];

          // remove only the first occurence of a season for the club from incorrectNextClubIds, as the player might actually tranfser to this club later
          const index = incorrectNextClubIds.findIndex((clubId: number) => clubId === clubThisSeason.id);
          if (index !== -1) {
            incorrectNextClubIds.splice(index, 1);
          }
        }
        else {
          const shouldExcludeSeason = (incorrectClubIdToIncorrectSeasons[clubThisSeason.id] ?? []).includes(seasonString);
          if (!shouldExcludeSeason && hasFoundBuyingClub) {
            likelyNextClubId = clubThisSeason.id;
          }
        }
      }
    }
  });

  // compute condition-statuses for each condition based on the collected player data
  subClauseAccessor.totalConditions.forEach((totalCondition: TotalCondition) => {

    let currentValue = totalCondition.overriddenValue?.value ?? 0;

    Object.keys(competitionToSeasonToMatchDetails).forEach((competitionId: string) => {
      if (totalCondition.competitionIds.includes(Number(competitionId))) {
        const competitionData = competitionToSeasonToMatchDetails[competitionId];

        Object.keys(competitionData.seasonData).forEach((season: string) => {
          const seasonData = competitionData.seasonData[season];

          if (!seasonData) return;

          seasonData.forEach((matchDetail: FlexibleJsonMapping) => {

            // always only consider matches after the transfer date
            if (matchDetail['match_date'] < subClauseAccessor.transferDate) return;

            // if overriddenValue is set, we only consider matches after the overridden date
            if (totalCondition.overriddenValue && matchDetail['match_date'] < totalCondition.overriddenValue.date) return;

            // if minMinutesPlayed is set, we only consider matches with at least minMinutesPlayed minutes played
            if (totalCondition.minMinutesPlayed && matchDetail['minutes_played'] < totalCondition.minMinutesPlayed) return;

            else if (totalCondition.condition === 'matchesStarted' && matchDetail['started_game']) currentValue++;
            else if (totalCondition.condition === 'matchesPlayed' && matchDetail['minutes_played'] > 0) currentValue++;
            else if (totalCondition.condition === 'minutesPlayed') currentValue += (matchDetail['minutes_played'] ?? 0);
            else if (totalCondition.condition === 'goalsScored') currentValue += (matchDetail.n_goals ?? 0);
          });
        });
      }
    });

    totalCondition.currentValue = currentValue;
  });

  // create seasonToLeagueTableDataMap for buying club
  const seasonToLeagueTableDataMap: FlexibleJsonMapping = {};
  const tableHistory: FlexibleJsonMapping[] = clubs[buyingClubId]?.table_position_history ?? [];
  tableHistory.forEach((tableData: FlexibleJsonMapping) => {
    seasonToLeagueTableDataMap[tableData.season] = {
      tablePosition: tableData.rank,
      leagueMatchesPlayed: tableData.matches,
      totalPossibleLeagueMatches: (tableData.n_clubs_in_league - 1) * 2,
      totalTeamsInLeague: tableData.n_clubs_in_league,
    };
  });

  subClauseAccessor.seasonConditions.forEach((seasonCondition: SeasonCondition) => {

    // there may be multiple minMinutesPlayed for the season condition, and and we must compute matches played for all of them
    const minMinutesPlayedList: number[] = [];
    if (seasonCondition.commonSubConditions) {
      seasonCondition.commonSubConditions.forEach((commonSubCondition: SeasonSubCondition) => {
        if (commonSubCondition.minMinutesPlayed) {
          minMinutesPlayedList.push(commonSubCondition.minMinutesPlayed);
        }
      });
    }
    if (seasonCondition.orConditions) {
      seasonCondition.orConditions.forEach((orCondition: SeasonOrCondition) => {
        orCondition.subConditions.forEach((subCondition: SeasonSubCondition) => {
          if (subCondition.minMinutesPlayed) {
            minMinutesPlayedList.push(subCondition.minMinutesPlayed);
          }
        });
      });
    }

    const seasonConditionIsCrossYear = seasonCondition.seasonStrings[0].includes('/');
    const seasonToCurrentValues: FlexibleJsonMapping = {};
    seasonCondition.seasonStrings.forEach((season: string) => {
      const subConditionToCurrentValue: FlexibleJsonMapping = {
        goalsScored: 0,
        matchesPlayed: 0,
        matchesStarted: 0,
        minutesPlayed: 0,
        totalPossibleMatches: 0,
        totalPossibleMinutes: 0,
        tablePosition: seasonToLeagueTableDataMap[season]?.tablePosition ?? undefined,
        leagueMatchesPlayed: seasonToLeagueTableDataMap[season]?.leagueMatchesPlayed ?? undefined,
        totalPossibleLeagueMatches: seasonToLeagueTableDataMap[season]?.totalPossibleLeagueMatches ?? undefined,
        totalTeamsInLeague: seasonToLeagueTableDataMap[season]?.totalTeamsInLeague ?? undefined,
      };

      minMinutesPlayedList.forEach((minMinutesPlayed: number) => {
        subConditionToCurrentValue['matchesPlayed' + minMinutesPlayed] = 0;
        subConditionToCurrentValue['matchesStarted' + minMinutesPlayed] = 0;
      });

      Object.keys(competitionToSeasonToMatchDetails).forEach((competitionId: string) => {
        if (seasonCondition.competitionIds.includes(Number(competitionId))) {
          const competitionData = competitionToSeasonToMatchDetails[competitionId];

          // some full-year season conditions may include cross-year seasons, i.e. Norwegian clubs playing European cups (22/23) in the 2022 season
          // if condition is full-year (at least one full-year competition selected) and this competition is cross-year, we adjust the season key to cross-year
          let seasonKey = season;
          const competitionIsCrossYear = competitionData.isCrossYearCompetition;
          if (!seasonConditionIsCrossYear && competitionIsCrossYear) {
            const seasonYearShort = season.substring(2, 4);
            seasonKey = seasonYearShort + '/' + (Number(seasonYearShort) + 1);
          }

          // extract season data for this competition
          if (seasonKey in competitionData.seasonData) {
            const seasonData = competitionData.seasonData[seasonKey];
            seasonData.forEach((matchDetail: FlexibleJsonMapping) => {

              // always only consider matches after the transfer date
              if (matchDetail['match_date'] < subClauseAccessor.transferDate) return;

              subConditionToCurrentValue.totalPossibleMatches++;
              subConditionToCurrentValue.totalPossibleMinutes += matchDetail['total_minutes_in_match'] ?? 0;

              subConditionToCurrentValue.minutesPlayed += matchDetail['minutes_played'] ?? 0;
              subConditionToCurrentValue.goalsScored += matchDetail['n_goals'] ?? 0;

              if (matchDetail['minutes_played'] > 0) subConditionToCurrentValue.matchesPlayed++;
              if (matchDetail['started_game']) subConditionToCurrentValue.matchesStarted++;

              // compute minMinutesPlayed for all minMinutesPlayedList
              if (minMinutesPlayedList.length > 0) {
                minMinutesPlayedList.forEach((minMinutesPlayed: number) => {
                  if (matchDetail['minutes_played'] >= minMinutesPlayed) {
                    subConditionToCurrentValue['matchesPlayed' + minMinutesPlayed]++;
                    if (matchDetail['started_game']) subConditionToCurrentValue['matchesStarted' + minMinutesPlayed]++;
                  }
                });
              }
            });
          }
        }
      });

      seasonToCurrentValues[season] = subConditionToCurrentValue;
    });
    seasonCondition.seasonToCurrentValues = seasonToCurrentValues;
  });

  // if sell-on condition exists and is not resolved and has dynamic sell-on percentages, we compute the current sell-on percentage and add it to the condition
  if (subClauseAccessor.sellOnCondition && !subClauseAccessor.sellOnCondition.isResolved && subClauseAccessor.sellOnCondition.dynamicSellOnPercentages) {
    const currentSellOn = getCurrentSellOnPercentage(subClauseAccessor.sellOnCondition.dynamicSellOnPercentages);
    subClauseAccessor.sellOnCondition.sellOnPercentage = currentSellOn;
  }

  subClauseAccessor.nextClubId = likelyNextClubId;

  // if at least one condition will get a notification displayed, we set showNotification to true
  if (!clause.isDeleted && !clause.isResolved) {
    // sellOn notification or end-of-clause notification
    showNotification = likelyNextClubId !== undefined;

    // conditions will only have notifications if the maxConditionalFee is not reached
    // the toal conditional fees does not exist in store and must be calculated
    let conditionalFees = 0;
    clause.payments.forEach(payment => {
      if (payment.conditionType && payment.conditionType !== 'sellOn' && payment.subClauseIndex === subClauseIndex) {
        conditionalFees += payment.amount;
      }
    });

    const isMaxConditionalFeeReached = subClauseAccessor.maxConditionalFees !== null && conditionalFees >= subClauseAccessor.maxConditionalFees;

    if (!isMaxConditionalFeeReached) {
      // total condition notifications
      if (!showNotification && subClauseAccessor.totalConditions.length > 0) {
        // a notification is displayed for a total condition if the current value is greater than or equal to any unresolved threshold
        showNotification = subClauseAccessor.totalConditions.some((totalCondition: TotalCondition) => {
          return totalCondition.subConditions.some((subCondition) => {
            return !subCondition.isResolved && totalCondition.currentValue && totalCondition.currentValue >= subCondition.threshold;
          });
        });
      }

      // season condition notifications
      if (!showNotification && subClauseAccessor.seasonConditions.length > 0) {
        // a notification is displayed for a season if it is neither resolved nor discarded and if every subcondition is either achieved or potentially achieved
        // potentially achieved means that the data suggest that condition is achieved, but the user has not yet confirmed it,
        // or that the season is over and the data cannot tell whether the condition was achieved
        showNotification = subClauseAccessor.seasonConditions.some((seasonCondition: SeasonCondition) => {
          return seasonCondition.seasonStrings.some((seasonString: string) => {
            const seasonStartDate = getSeasonStartDate(seasonString);
            const oneYearAgo = new Date();
            oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
            const seasonStartedOverAYearAgo = seasonStartDate < oneYearAgo;

            const overallStatus = (seasonCondition.seasonToCurrentStatuses ?? {})[seasonString]?.overallStatus;
            return !overallStatus && allSubConditionsAreAchievedOrPotentiallyReachedForSeason(seasonCondition, seasonString, seasonStartedOverAYearAgo);
          });
        });
      }
    }
  }
  return [showNotification, likelyNextClubId];
};


// Add all relevant data to the clause (could be optimized by not iterating through every season for every sub clause)
// (1) sort all season chronologically into an array
// (2) add relevant data for the main clause
// (3) iterate through all sub clauses and add relevant data to each sub clause
export const addClauseData = (
  clause: PlayerClause,
  clubIterations: ClubIterationMap,
  clubs: ClubMapping
): number | undefined => {

  // (1)
  const detailsArray: ClubIteration[] = [];
  Object.keys(clubIterations).forEach((clubIterationId: string) => {
    const clubIteration = { ...clubIterations[clubIterationId] };
    if (clubIteration['plot_date']) {
      clubIteration['sortDate'] = clubIteration['plot_date'];
    }
    else {
      const seasonString = clubIteration['season'];
      const sortDate = getSeasonStartDate(seasonString);
      clubIteration['sortDate'] = sortDate.toISOString();
    }
    clubIteration['clubIterationId'] = clubIterationId;
    detailsArray.push(clubIteration);
  });
  detailsArray.sort((a, b) => b.sortDate && a.sortDate ? a.sortDate.localeCompare(b.sortDate) : 0);

  // (2)
  let [showNotification, likelyNextClubId] = addSubClauseData(clause, undefined, detailsArray, clubs);

  // (3)
  if (clause.subClauses) {
    clause.subClauses.forEach((_, subClauseIndex) => {
      [showNotification, likelyNextClubId] = addSubClauseData(clause, subClauseIndex, detailsArray, clubs);
    });
  }

  clause.showNotification = showNotification;

  return likelyNextClubId;
};


export const Economy = () => {

  const { currentUser } = useAuthContext() as AuthContextType;

  const navigationProps = useRecoilValue(navigationPropsState);
  const { handleOpenConfirmModal } = useGlobalModal();

  const userConfig = useRecoilValue(userConfigState);
  const settings = useRecoilValue(settingsState);

  const selectedPlayer = useRecoilValue(selectedPlayerState);
  const players = useRecoilValue(playersState);
  const playerOverviews = useRecoilValue(playerOverviewsState);

  const playerDetails = useRecoilValue(playerDetailsState);
  const [playerDetailsOfAllClauses, setPlayerDetailsOfAllClauses] = useState<PlayerDetails | undefined>(undefined);

  const [allClubs, setAllClubs] = useState<ClubMapping>({});

  const [soldPlayers, setSoldPlayers] = useState<PlayerClause[]>([]);
  const [boughtPlayers, setBoughtPlayers] = useState<PlayerClause[]>([]);
  const [isClausesInitialized, setIsClausesInitialized] = useState(false);

  const [existingClauseSelected, setExistingClauseSelected] = useState<PlayerClause | undefined>(undefined);
  const [newClauseSelected, setNewClauseSelected] = useState(navigationProps.selectedPlayerId !== undefined);

  const [clauseHasUnsavedChanges, setClauseHasUnsavedChanges] = useState(false);

  const [selectedPlayerData, setSelectedPlayerData] = useState<FlexibleJsonMapping | undefined>(undefined);

  const [isClubsExpanded, setIsClubsExpanded] = useState(false);
  const [isCurrencyExpanded, setIsCurrencyExpanded] = useState(false);

  const removeExpansions = () => {
    setIsClubsExpanded(false);
    setIsCurrencyExpanded(false);
  };


  const [activeTab, setActiveTab] = useState(0);

  const tabOptions = [
    // 'contracts',
    'soldPlayers',
    'boughtPlayers',
    // 'budget' / 'payments',
  ];

  const tabIcons = [
    // <ContactPageIcon key={'contact-icon'} style={{ fontSize: 22 }} />,
    <GroupRemoveIcon key={'sold-player-icon'} style={{ fontSize: 22 }} />,
    <GroupAddIcon key={'bought-player-icon'} style={{ fontSize: 22 }} />,
    // <SignalCellularAltIcon key={'signal-icon'} style={{ fontSize: 22, marginTop: -1 }} />,
  ];


  const confirmExitClause = (action: () => void) => {
    action();
    setClauseHasUnsavedChanges(false);
  };


  const confirmCloseClause = () => {
    setExistingClauseSelected(undefined);
    setNewClauseSelected(false);
    setSelectedPlayerData(undefined);
    setClauseHasUnsavedChanges(false);
    removeExpansions();
  };


  const handleCloseIconClick = () => {
    if (clauseHasUnsavedChanges) {
      handleOpenConfirmModal(
        () => confirmExitClause(() => confirmCloseClause()),
        'exitClause?',
        existingClauseSelected ? 'changesWillNotBeSaved' : 'clauseWillNotBeSaved'
      );
    }
    else {
      confirmCloseClause();
    }
  };


  const confirmSetActiveTab = (tabIndex: number, updateTabLineCallback?: (tabIndex: number) => void) => {
    setActiveTab(tabIndex);
    if (updateTabLineCallback) {
      updateTabLineCallback(tabIndex);
    }
    confirmCloseClause();
  };


  const handleSetActiveTab = (tabIndex: number, updateTabLineCallback?: (tabIndex: number) => void) => {
    if (tabIndex !== activeTab) {
      if (clauseHasUnsavedChanges) {
        handleOpenConfirmModal(
          () => confirmExitClause(() => confirmSetActiveTab(tabIndex, updateTabLineCallback)),
          'exitClause?',
          existingClauseSelected ? 'changesWillNotBeSaved' : 'clauseWillNotBeSaved'
        );
      }
      else {
        confirmSetActiveTab(tabIndex, updateTabLineCallback);
      }
    }
  };


  const setNewExistingClauseSelected = async (clause: PlayerClause) => {
    setExistingClauseSelected(clause);
    setNewClauseSelected(false);
    await handleSetSelectedPlayerData(clause.playerId, clause.playerName);
  };


  const handleExistingClauseClicked = (clause: PlayerClause) => {
    if (clause.id !== existingClauseSelected?.id) {
      if (clauseHasUnsavedChanges) {
        handleOpenConfirmModal(
          () => confirmExitClause(async () => await setNewExistingClauseSelected(clause)),
          'exitClause?',
          existingClauseSelected ? 'changesWillNotBeSaved' : 'clauseWillNotBeSaved'
        );
      }
      else {
        setNewExistingClauseSelected(clause);
      }
    }
  };


  const handleSetSelectedPlayerData = useCallback(async (playerId: PlayerId, playerName: string) => {
    if (playerOverviews && playerId in playerOverviews) {
      setSelectedPlayerData(playerOverviews[Number(playerId)]);
    }
    else {
      setSelectedPlayerData(undefined);
      const numberId = Number(playerId);
      if (!isNaN(numberId)) {
        getPlayerOverviews([numberId], currentUser).then((player: PlayerOverviews | undefined) => {
          setSelectedPlayerData(player ? Object.values(player)[0] : {});
        });
      }
      else if (players && playerId in players) {
        const playerData = players[playerId] ?? {};
        setSelectedPlayerData({ id: playerId, ...playerData });
      }
      else {
        setSelectedPlayerData({ id: playerId, fullname: playerName });
      }
    }
  }, [currentUser, playerOverviews, players]);


  const addOrUpdateClause = async (clause: PlayerClause, isNewClause: boolean, isSoldPlayer: boolean) => {
    const clauses = isSoldPlayer ? soldPlayers : boughtPlayers;
    const setClauses = isSoldPlayer ? setSoldPlayers : setBoughtPlayers;

    let playerDetailsOfClause = playerDetailsOfAllClauses ? playerDetailsOfAllClauses[Number(clause.playerId)] : undefined;

    // fetch (if needed) playerDetails and add to playerDetailsOfAllClauses
    if (!playerDetailsOfClause) {

      if (playerDetails && clause.playerId in playerDetails) {
        playerDetailsOfClause = playerDetails[Number(clause.playerId)];
      }

      else {
        const fetchedPlayerDetails: PlayerDetails | undefined = await getPlayerDetails([clause.playerId], currentUser);
        if (fetchedPlayerDetails) {
          playerDetailsOfClause = fetchedPlayerDetails[Number(clause.playerId)];
        }
      }

      if (playerDetailsOfClause) {
        setPlayerDetailsOfAllClauses({ ...playerDetailsOfAllClauses, [clause.playerId]: playerDetailsOfClause });
      }
    }

    // add or re-calculate player data for clause
    if (playerDetailsOfClause) {
      const likelyNextClubId: number | undefined = addClauseData(clause, playerDetailsOfClause, allClubs);
      if (likelyNextClubId !== undefined && !(likelyNextClubId in allClubs)) {
        const fetchedClubs = await getClubs([likelyNextClubId], currentUser);
        if (fetchedClubs) {
          setAllClubs({ ...allClubs, ...fetchedClubs });
        }
      }
    }

    if (isNewClause) {
      setClauses([clause, ...clauses]);
      setExistingClauseSelected(clause);
    }
    else {
      const updatedClauses = clauses.map((existingClause: PlayerClause) => {
        if (existingClause.id === clause.id) {
          setExistingClauseSelected(clause);
          return clause;
        }
        return existingClause;
      });
      setClauses(updatedClauses);
    }
  };


  useEffect(() => {

    // (1) Load all clauses from firestore
    // (2) Load playerDetails for all players with a clause
    // (3) Load all clubs included in the clauses
    // (4) Calculate clausePlayerData for each clause and append to the clause (and add potentially existing nextClub to allClubs)
    // When clauses are updated or added, (2) and (4) should be done only for the new or updated clause by the addOrUpdateClause function,
    // while (4) is maintained continuously whenever new clubs are selected
    const fetchAndProcessClauses = async () => {
      if (userConfig?.club && playerDetails && settings?.clubSettings.clubId) {
        // (1) Load all clauses from firestore
        const clauses: PlayerClause[] = await getAllClauses(userConfig.club);

        // (2)
        let playerDetailsOfPlayersWithClauses: PlayerMapping = {};
        const playerIdsToFetch = new Set<PlayerId>();

        clauses.forEach(clause => {
          if (clause.playerId in playerDetails) {
            playerDetailsOfPlayersWithClauses[clause.playerId] = playerDetails[Number(clause.playerId)];
          } else {
            playerIdsToFetch.add(clause.playerId);
          }
        });

        const playerIdsToFetchArray = Array.from(playerIdsToFetch);
        if (playerIdsToFetchArray.length > 0) {
          const fetchedPlayerDetails: PlayerDetails | undefined = await getPlayerDetails(playerIdsToFetchArray, currentUser);
          if (fetchedPlayerDetails) {
            playerDetailsOfPlayersWithClauses = { ...playerDetailsOfPlayersWithClauses, ...fetchedPlayerDetails };
          }
        }

        // (3)
        const allClubIds = new Set<number>();
        allClubIds.add(settings.clubSettings.clubId);

        clauses.forEach(clause => {
          allClubIds.add(clause.sellingClubId);
          allClubIds.add(clause.buyingClubId);
          if (clause.nextClubId !== undefined) allClubIds.add(clause.nextClubId);
          if (clause.incorrectNextClubIds) clause.incorrectNextClubIds.forEach(clubId => allClubIds.add(clubId)); // todo: might not be needed
          if (clause.subClauses) {
            clause.subClauses.forEach(subClause => {
              allClubIds.add(subClause.sellingClubId);
              allClubIds.add(subClause.buyingClubId);
              if (subClause.nextClubId !== undefined) allClubIds.add(subClause.nextClubId);
            });
          }
        });

        const allClubIdsArray = Array.from(allClubIds);
        let clubs: ClubMapping = {};
        if (allClubIdsArray.length > 0) {
          const fetchedClubs = await getClubs(allClubIdsArray, currentUser);
          if (fetchedClubs) {
            clubs = fetchedClubs;
          }
        }

        // (4)
        const soldPlayerClauses: PlayerClause[] = [];
        const boughtPlayerClauses: PlayerClause[] = [];
        const newClubIdsToFetch = new Set<number>();

        clauses.forEach(clause => {
          const likelyNextClubId: number | undefined = addClauseData(clause, playerDetailsOfPlayersWithClauses[clause.playerId], clubs);
          if (likelyNextClubId !== undefined) {
            newClubIdsToFetch.add(likelyNextClubId);
          }
          if (clause.isSoldPlayer) soldPlayerClauses.push(clause);
          else boughtPlayerClauses.push(clause);
        });

        const newClubIdsToFetchArray = Array.from(newClubIdsToFetch);
        if (newClubIdsToFetchArray.length > 0) {
          const fetchedClubs = await getClubs(newClubIdsToFetchArray, currentUser);
          if (fetchedClubs) {
            clubs = { ...clubs, ...fetchedClubs };
          }
        }

        // sort clauses first by showNotification, then by transfer date
        const getClauseSortDistance = (a: PlayerClause, b: PlayerClause) => {
          if (a.showNotification && !b.showNotification) return -1;
          if (!a.showNotification && b.showNotification) return 1;

          return b.transferDate.localeCompare(a.transferDate);
        };

        soldPlayerClauses.sort(getClauseSortDistance);
        boughtPlayerClauses.sort(getClauseSortDistance);

        setSoldPlayers(soldPlayerClauses);
        setBoughtPlayers(boughtPlayerClauses);
        setAllClubs(clubs);

        setPlayerDetailsOfAllClauses(playerDetailsOfPlayersWithClauses);
        setIsClausesInitialized(true);
      }
    };

    fetchAndProcessClauses();
  }, [currentUser, userConfig?.club, playerDetails, settings?.clubSettings.clubId]);


  useEffect(() => {
    if (navigationProps.selectedPlayerId !== undefined && navigationProps.selectedPlayerName !== undefined) {
      handleSetSelectedPlayerData(navigationProps.selectedPlayerId, navigationProps.selectedPlayerName);
      setNewClauseSelected(true);
    }
  }, [handleSetSelectedPlayerData, navigationProps]);


  return (
    <div className='platform-view-section platform-view-section-dark'>

      <div className={'player-view-outer-container' + (selectedPlayer ? ' player-view-outer-container-player-selected' : '')}>
        {selectedPlayer && playerOverviews && (
          <PlayerView
            playerOverview={selectedPlayerData}
          />
        )}
      </div>

      <FixedTabLine
        tabOptions={tabOptions}
        activeTab={activeTab}
        setActiveTab={handleSetActiveTab}
        initialActiveTab={navigationProps.activeSubTab}
        tabIcons={tabIcons}
        useUpdateTabLineCallback={true}
      />

      <div className='economy-container'>

        <div className='economy-view-container'>
          <OwnTeamBackgoundSvg />
        </div>

        <div className='economy-view-container'>

          {(isClubsExpanded || isCurrencyExpanded) &&
            <div className='full-size-container absolute-container economy-empty-background' onClick={() => removeExpansions()}>&nbsp;</div>
          }

          <div className='economy-existing-clauses-container fade-in'>
            <ExistingClauses
              isSoldPlayer={activeTab === 0}
              clauses={activeTab === 0 ? soldPlayers : boughtPlayers}
              allClubs={allClubs}
              existingClauseSelected={existingClauseSelected}
              handleExistingClauseClicked={handleExistingClauseClicked}
              isClausesInitialized={isClausesInitialized}
            />
          </div>

          <div className='economy-clause-container'>
            {(existingClauseSelected || newClauseSelected) && settings?.clubSettings.clubId && (
              <Clause
                isSoldPlayer={activeTab === 0}
                existingClauseSelected={existingClauseSelected}
                setExistingClauseSelected={setExistingClauseSelected}

                handleCloseIconClick={handleCloseIconClick}
                addOrUpdateClause={addOrUpdateClause}

                selectedPlayerData={selectedPlayerData}
                setSelectedPlayerData={setSelectedPlayerData}
                allClubs={allClubs}
                setAllClubs={setAllClubs}
                userClubId={settings.clubSettings.clubId}

                isClubsExpanded={isClubsExpanded}
                setIsClubsExpanded={setIsClubsExpanded}
                isCurrencyExpanded={isCurrencyExpanded}
                setIsCurrencyExpanded={setIsCurrencyExpanded}

                clauseHasUnsavedChanges={clauseHasUnsavedChanges}
                setClauseHasUnsavedChanges={setClauseHasUnsavedChanges}
              />
            )}

            {!existingClauseSelected && !newClauseSelected && (
              <div
                className='document-submit-button new-document-button'
                style={{ width: 190 }}
                onClick={() => setNewClauseSelected(true)}>
                <div className='document-submit-button-text'>
                  {userConfig ? staticLanguageMap['addNewClause'][userConfig.language] : ''}
                </div>
                <div className='document-button-icon new-document-button-icon'>
                  <AddIcon style={{ fontSize: 21, marginTop: 2 }} />
                </div>
              </div>
            )}
          </div>
        </div>

        {/* {activeTab === 'X' &&
        <div className='own-team-view-coming-soon-title'>
          {userConfig ? staticLanguageMap['underDevelopment'][userConfig.language] : ''}
        </div>} */}

      </div>

    </div>
  );
};
