import '../../platform.css';
import './accordions.css';

import { useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


interface SupportAccordionProps {
  title: string;
  text: string[];
}


export const SupportAccordion: React.FC<SupportAccordionProps> = ({
  title,
  text,
}) => {

  const [isExpanded, setIsExpanded] = useState(false);


  const handleAccordionClick = () => {
    const element = document.getElementById('accordion-' + title);
    if (element) {
      if (isExpanded) {
        element.style.height = '40px';
      }
      else {
        // element.scrollIntoView({ behavior: 'smooth' });
        element.style.height = element.scrollHeight + 'px';
      }
    }
    setIsExpanded(!isExpanded);
  };


  return (
    <div
      id={'accordion-' + title}
      className={'support-accordion-container' + (isExpanded ? ' support-accordion-container-expanded' : '')}
      onClick={() => handleAccordionClick()}>
      <div className='support-accordion-title'>
        {title}
      </div>

      <div className={'support-accordion-text' + (isExpanded ? ' support-accordion-text-expanded' : '')}>
        {text.map((line, lineIndex) => {

          const lineList = line.split(' _ ');

          if (lineList.length > 1) {
            return (
              <div key={lineIndex} style={{ marginTop: 30 }}>
                <div style={{ marginBottom: 8, fontWeight: 500, }}>
                  {lineList[0]}
                </div>
                {lineList[1].split('/n').map((subline, subIndex) => {

                  const subLineList = subline.split('<link>');

                  if (subLineList.length === 1) {
                    return (
                      <div key={subIndex} style={{ marginBottom: 6 }}>
                        {subline}
                      </div>
                    );
                  }

                  return (
                    <div key={subIndex} style={{ marginBottom: 10 }}>
                      {subLineList[0]}
                      <a
                        href={subLineList[1]}
                        className='text-no-wrap'
                        style={{
                          color: '#8bd3ff',
                          fontWeight: 500,
                          textDecoration: 'none',
                        }}
                        target='_blank'
                        rel='noreferrer'
                        onClick={(event) => event.stopPropagation()}>
                        {subLineList[1]}
                      </a>
                    </div>
                  );

                })}
              </div>
            );
          }

          return (
            <div key={lineIndex} style={{ marginTop: 16 }}>
              {lineList[0].split('/n').map((subline, subIndex) => {
                return (
                  <div key={subIndex} style={{ marginBottom: 6 }}>
                    {subline}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>

      <div className={'support-accordion-icon-container' + (isExpanded ? ' support-accordion-icon-container-rotated' : '')}>
        {<KeyboardArrowDownIcon style={{ fontSize: 20 }} />}
      </div>
    </div>
  );
};
