

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deepCloneObject = (obj: any): any => {

  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  // If the object is an array, handle the elements
  if (Array.isArray(obj)) {
    return obj.map(deepCloneObject);
  }

  // Create a new object with the same prototype
  const clone = Object.create(Object.getPrototypeOf(obj));

  // Recursively copy properties, unfreezing as we go
  Object.keys(obj).forEach(key => {
    clone[key] = deepCloneObject(obj[key]);
  });

  return clone;
};


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const debounce = <F extends (...args: any[]) => any>(func: F, waitFor: number) => {
  let timeout: ReturnType<typeof setTimeout> | null = null;

  const debounced = (...args: Parameters<F>) => {
    if (timeout !== null) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => func(...args), waitFor);
  };

  return [debounced, () => timeout && clearTimeout(timeout)] as const;
};


export const stringArraysAreEqual = (array1: string[], array2: string[]): boolean => {
  if (array1.length !== array2.length) return false;

  for (let i = 0; i < array1.length; i++) {
    if (array1[i] !== array2[i]) return false;
  }

  return true;
};


export const stringArraysContainSameElements = (array1: string[], array2: string[]): boolean => {
  const sortedArray1 = [...array1].sort();
  const sortedArray2 = [...array2].sort();

  return stringArraysAreEqual(sortedArray1, sortedArray2);
};


export const generateRandomId = (length?: number): string => {
  length = length ?? 16;

  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return `${Date.now().toString(36)}-${result}`;
};


export const getEnglishOrdinalSuffix = (number: number): string => {
  const j = number % 10;
  const k = number % 100;

  if (j === 1 && k !== 11) {
    return 'st';
  }

  if (j === 2 && k !== 12) {
    return 'nd';
  }

  if (j === 3 && k !== 13) {
    return 'rd';
  }

  return 'th';
};
