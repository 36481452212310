import '../tables.css';

import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { settingsState } from '../../../recoil/atoms/settingsState';
import { competitionsState } from '../../../recoil/atoms/competitionsState';
import { clubScopesState } from '../../../recoil/atoms/clubScopesState';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { useMemo } from 'react';
import { useTable, useBlockLayout, useSortBy, useFilters } from 'react-table';
import { useSticky } from 'react-table-sticky';

import { FlexibleJsonMapping } from '../../../types';
import { getSeasonColumns } from './seasonColumns';


interface SeasonTableProps {
  data: FlexibleJsonMapping[];
  selectedPositionKey: string;
  primaryPosition: string;
  selectedClubIterationId: string | undefined;
  setSelectedClubIterationId: (index: string) => void;
}

export const SeasonTable: React.FC<SeasonTableProps> = ({
  data,
  selectedPositionKey,
  primaryPosition,
  selectedClubIterationId,
  setSelectedClubIterationId,
}) => {

  const userConfig = useRecoilValue(userConfigState);
  const settings = useRecoilValue(settingsState);
  const competitions = useRecoilValue(competitionsState);
  const clubScopes = useRecoilValue(clubScopesState);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: any[] = useMemo(
    () => getSeasonColumns(
      selectedPositionKey,
      primaryPosition,
      userConfig,
      settings?.userSettings.seasonStatsToggles ?? {},
      competitions,
      clubScopes?.hasSkillcorner ?? false),
    [userConfig, settings, competitions, clubScopes, selectedPositionKey, primaryPosition]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useSortBy,
    useBlockLayout,
    useSticky
  );


  const getExtendedRowClassName = (row: FlexibleJsonMapping, index: number) => {
    if (!row.original.event_data_available) {
      return 'empty';
    }

    if (row.original.club_iteration_id === selectedClubIterationId) {
      return 'selected';
    }

    const positionKeyToData = row.original[selectedPositionKey]
      ? selectedPositionKey
      : (selectedPositionKey === row.original.primary_position ? 'overall' : selectedPositionKey);

    const details = row.original[positionKeyToData];
    if (!details || !details['minutes_played']) {
      return 'empty';
    }

    return index % 2 === 0 ? 'even' : 'odd';
  };


  const getExtendedCellClassName = (cell: FlexibleJsonMapping) => {
    if (cell.column.isFinalSubMetric) {
      return ' season-table-body-cell-with-border';
    }
    return '';
  };


  return (
    <div {...getTableProps()} className='season-table'>

      <div className='season-table-top-level-header-group'>
        {headerGroups.map(headerGroup => {
          const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();
          return (
            <div
              key={key}
              {...restHeaderGroupProps}
            >
              {headerGroup.headers.map((column: FlexibleJsonMapping) => {
                const { key: columnKey, ...restColumnProps } = column.getHeaderProps(
                  column.isStickyColumn ? undefined : column.getSortByToggleProps({ title: column.title })
                );
                return (
                  <div
                    key={columnKey}
                    {...restColumnProps}
                  >
                    {column.render('Header')}
                    <div className='season-table-sort-icon' style={{ marginLeft: column.width ? column.width - 20 : undefined }}>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? <KeyboardArrowDownIcon style={{ fontSize: 18, marginBottom: -6, marginTop: -6 }} />
                          : <KeyboardArrowUpIcon style={{ fontSize: 18, marginBottom: -6, marginTop: -6 }} />
                        : ''
                      }
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>

      <div
        {...getTableBodyProps()}
        className='season-table-body'>
        {rows.map((row, i) => {
          prepareRow(row);
          const { key, ...restRowProps } = row.getRowProps();
          return (
            <div
              key={key}
              {...restRowProps}
              className={'season-table-body-row season-table-body-row-' + getExtendedRowClassName(row, i)}
              onClick={() => (getExtendedRowClassName(row, i) !== 'empty' ? setSelectedClubIterationId(row.original.club_iteration_id) : null)}
            >
              {row.cells.map(cell => {
                const { key: cellKey, ...restCellProps } = cell.getCellProps();
                return (
                  <div
                    key={cellKey}
                    {...restCellProps}
                    className={'season-table-body-cell season-table-body-cell-' + getExtendedRowClassName(row, i) + getExtendedCellClassName(cell)}
                  >
                    {cell.render('Cell')}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>

    </div>
  );
};
