import './input.css';

import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';

import Fuse from 'fuse.js';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CloseIcon from '@mui/icons-material/Close';

import { staticLanguageMap } from '../../../common/static/staticLanguageMap';
import { InputField } from './InputField';
import { EnhancedCountryInfo, countryCodeListSubset, countryCodeToCountryInfo, enhancedCountryList } from '../../static/countries';


interface CountryDropDownProps {
  id: string;
  selectedCountryCodes: string[];
  setSelectedCountryCodes: (value: string[]) => void;
  isDropDownExpanded: boolean;
  setIsDropDownExpanded: (value: boolean) => void;
  defaultDropDownText: string; // static language key
  defaultDropDownTextColor: string;
  marginBetweenOptions: number;
  emptyBackgroundId?: string;
  maxHeight?: string | number; // controls scrolling
  singleSelect?: boolean;
}


export const CountryDropDown: React.FC<CountryDropDownProps> = ({
  id,
  selectedCountryCodes,
  setSelectedCountryCodes,
  isDropDownExpanded,
  setIsDropDownExpanded,
  defaultDropDownText,
  defaultDropDownTextColor,
  marginBetweenOptions,
  emptyBackgroundId,
  maxHeight,
  singleSelect,
}) => {

  const userConfig = useRecoilValue(userConfigState);

  const [searchString, setSearchString] = useState('');

  const inputRef = useRef<HTMLInputElement>(null);

  const [fuse, setFuse] = useState<Fuse<EnhancedCountryInfo> | null>(null);

  const [countriesToShow, setCountriesToShow] = useState<string[]>([]);


  const onChangeSearchField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value);
    if (event.target.value === '') {
      setCountriesToShow(countryCodeListSubset);
    }
  };


  const onKeyDownSearchField = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearchForCountries(searchString);
    }
  };


  const handleSearchForCountries = useCallback((currentSearchString: string) => {
    if (!fuse) return [];

    const searchResult = fuse.search(currentSearchString).map(result => result.item);
    setCountriesToShow(searchResult.map(item => item.countryCode));
  }, [fuse]);


  const handleDropDownClick = () => {
    if (isDropDownExpanded) {
      removeDropDownExpansion();
    }

    else {
      expandDropDown();
    }
  };


  const handleOptionSelect = (countryCode: string, isOptionAlreadySelected: boolean) => {
    if (singleSelect) {
      setSelectedCountryCodes(isOptionAlreadySelected ? [] : [countryCode]);
      if (!isOptionAlreadySelected) removeDropDownExpansion();
      return;
    }

    let newSelectedCountryCodes = selectedCountryCodes.slice();

    if (isOptionAlreadySelected) {
      newSelectedCountryCodes = newSelectedCountryCodes.filter(item => item !== countryCode);
    }
    else {
      newSelectedCountryCodes.push(countryCode);
    }

    setSelectedCountryCodes(newSelectedCountryCodes);
  };


  const expandDropDown = () => {
    // zIndex handling is not ideal, but must be handlded carefully due to interactions between multiple drop downs
    let element = document.getElementById(id);
    if (element) {
      element.style.transition = '150ms';
      element.style.height = 'auto';
      element.style.zIndex = '110';
    }

    if (emptyBackgroundId) {
      element = document.getElementById(emptyBackgroundId);
      if (element) {
        element.style.transition = '150ms';
        element.style.zIndex = '100';
        element.style.backgroundImage = 'linear-gradient(to bottom, #00000000, #00000012)';
      }
    }

    setIsDropDownExpanded(true);
  };


  const removeDropDownExpansion = () => {
    let element = document.getElementById(id);
    if (element) {
      element.style.transition = '75ms';
      element.style.height = '28px';
      element.style.zIndex = '1';
    }

    if (emptyBackgroundId) {
      element = document.getElementById(emptyBackgroundId);
      if (element) {
        element.style.transition = '75ms';
        element.style.zIndex = '-1';
        element.style.backgroundImage = 'linear-gradient(to bottom, #00000000, #00000000)';
      }
    }

    setIsDropDownExpanded(false);
  };


  const showSelectedCountries = () => {

    if (!userConfig) return '';

    let display = countryCodeToCountryInfo[selectedCountryCodes[0]].name[userConfig.language];

    for (let i = 1; i < selectedCountryCodes.length; i++) {
      display += ', ' + countryCodeToCountryInfo[selectedCountryCodes[i]].name[userConfig.language];
    }

    return display;
  };


  useEffect(() => {
    if (!isDropDownExpanded) {
      removeDropDownExpansion();
      setSearchString('');
      setCountriesToShow(countryCodeListSubset);
    }
    else {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [isDropDownExpanded]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (searchString.length === 0) {
      setCountriesToShow(countryCodeListSubset);
    }
    else {
      handleSearchForCountries(searchString);
    }
  }, [handleSearchForCountries, searchString]);


  useEffect(() => {
    const searchKey = 'name.' + (userConfig?.language ?? 'en');

    const options = {
      keys: [searchKey],
      threshold: 0.2,
    };

    const fuseInstance = new Fuse(enhancedCountryList, options);
    setFuse(fuseInstance);
  }, [userConfig?.language]);


  return (
    <div
      className={'drop-down-select-container' + ((isDropDownExpanded || selectedCountryCodes.length > 0) ? ' drop-down-select-container-focus' : '')}
      id={id}
      style={{ maxHeight: maxHeight }}
    >

      <div className='drop-down-select-button' onClick={() => handleDropDownClick()}>

        {selectedCountryCodes.length > 0 && <div className='drop-down-select-button-overlay fast-fade-in'>&nbsp;</div>}

        <div className='drop-down-select-button-text'>

          {selectedCountryCodes.length === 0 && (
            <div style={{ color: defaultDropDownTextColor }}>
              {userConfig ? staticLanguageMap[defaultDropDownText][userConfig.language] : ''}
            </div>
          )}

          {selectedCountryCodes.length > 0 && isDropDownExpanded && (
            <div style={{ color: defaultDropDownTextColor }}>
              {userConfig
                ? (singleSelect ? staticLanguageMap['selectedCountry'][userConfig.language] : staticLanguageMap['selectedCountries'][userConfig.language])
                : ''}
            </div>
          )}

          {selectedCountryCodes.length > 0 && !isDropDownExpanded && (
            <div style={{ color: '#000000' }}>
              {showSelectedCountries()}
            </div>
          )}

        </div>

        {!isDropDownExpanded && (
          <div className='drop-down-select-icon'>
            <ArrowDropDownIcon style={{ fontSize: 24 }} />
          </div>
        )}

        {isDropDownExpanded && (
          <div className='drop-down-select-icon'>
            <ArrowDropUpIcon style={{ fontSize: 24 }} />
          </div>
        )}
      </div>

      <div className='league-drop-down-section' style={{ maxHeight: maxHeight }}>

        <div className='league-drop-down-selected-leagues'>
          {selectedCountryCodes.map((countryCode: string) => {
            return (
              <div
                className={'league-drop-down-selected-league'}
                key={countryCode + '-selected'}
              >
                <div className='league-drop-down-selected-league-close-icon'>
                  <CloseIcon style={{ fontSize: 16 }} onClick={() => handleOptionSelect(countryCode, true)} />
                </div>

                <img
                  className='league-flag league-drop-down-selected-league-flag'
                  src={countryCodeToCountryInfo[countryCode].flagUrl}
                  alt={countryCode}
                  draggable={false}
                />

                <div className='league-drop-down-selected-league-name'>
                  {userConfig ? countryCodeToCountryInfo[countryCode].name[userConfig.language] : ''}
                </div>
              </div>
            );
          })}
        </div>

        <div
          className='league-drop-down-input-container'
          style={{ marginTop: singleSelect ? (34) : (12 + (22 * Math.max(selectedCountryCodes.length, 2))) }}>
          <InputField
            searchString={searchString}
            onChangeInputField={onChangeSearchField}
            onKeyDownInputField={onKeyDownSearchField}
            resetSearchString={() => setSearchString('')}
            defaultInput={userConfig ? staticLanguageMap['searchForCountry'][userConfig.language] : ''}
            showDefaultInput={true}
            style={{ boxShadow: '0px 0px 2px 1px #00000020', backgroundColor: '#ffffff' }}
            ref={inputRef}
          />
        </div>

        {countriesToShow.length > 0 &&
          countriesToShow.map((countryCode: string) => {
            const isSelected = selectedCountryCodes.some(item => item === countryCode);
            return (
              <div
                className={'drop-down-select-option league-drop-down-option' + (isSelected ? ' drop-down-select-option-selected' : '')}
                style={{ marginTop: marginBetweenOptions }}
                key={countryCode}
                onClick={() => handleOptionSelect(countryCode, isSelected)}
              >
                <div className='league-drop-down-option-info-row'>
                  <img
                    className='league-flag league-drop-down-selected-league-flag'
                    src={countryCodeToCountryInfo[countryCode].flagUrl}
                    alt={countryCode}
                    draggable={false}
                  />

                  {userConfig ? countryCodeToCountryInfo[countryCode].name[userConfig.language] : ''}
                </div>
              </div>
            );
          })}

        {countriesToShow.length === 0 && (
          <div className='league-drop-down-no-search-result'>
            {userConfig ? staticLanguageMap['noResult'][userConfig.language] : ''}
          </div>
        )}

      </div>

    </div>
  );
};
