import { db } from '../../../firebase';
import { countryCodeToCountryInfo } from '../../static/countries';

import {
  collection,
  onSnapshot,
  orderBy,
  query,
} from 'firebase/firestore';

import { Competition, CompetitionMap, FirestoreCompetition } from '../../types';


// Get all competitions
export const getCompetitions = (setCompetitionsState: (competitionMap: CompetitionMap) => void) => {

  const competitionsRef = collection(db, 'competitions');

  const q = query(competitionsRef, orderBy('orderIndex', 'asc'));

  const unsubscribe = onSnapshot(q, (querySnapshot) => {

    const competitionMap: CompetitionMap = {};

    querySnapshot.forEach((doc) => {
      const firestoreCompetition = doc.data() as FirestoreCompetition;

      let countryNameEn: string | undefined = undefined;
      let countryNameNo: string | undefined = undefined;

      if (firestoreCompetition.countryCode) {
        const countryInfo = countryCodeToCountryInfo[firestoreCompetition.countryCode];
        if (countryInfo) {
          countryNameEn = countryInfo.name['en'];
          countryNameNo = countryInfo.name['no'];
        }
      }

      const competition: Competition = {
        ...firestoreCompetition,
        id: doc.id,
        countryNameEn: countryNameEn,
        countryNameNo: countryNameNo
      };

      competitionMap[Number(doc.id)] = competition;
    });

    setCompetitionsState(competitionMap);
  });

  return unsubscribe;
};
