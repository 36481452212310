

export const BASE_URL = 'https://fokus-server-s64pj2el2a-ew.a.run.app';


export const CONTRACT_KEYS_TO_MONTHS: { [key: string]: number } = {
  'freeAgent': 0,
  'under6months': 6,
  'under12months': 12,
  'under18months': 18,
  'under2years': 24,
  'under3years': 36,
  'under4years': 48,
};
