import './../modals.css';

import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';

import CloseIcon from '@mui/icons-material/Close';

import { staticLanguageMap } from '../../../../common/static/staticLanguageMap';
import { getConditionStatusIcon } from '../../../utils/iconUtils';


interface SeasonSubConditionStatusModalProps {
  currentStatus: string | null;
  newStatusSuggestion: string | undefined;
  conditionDescriptions: string[];
  handleUpdateSeasonSubConditionStatus: (newStatus: string | null) => void;
  closeModal: () => void;
}

export const SeasonSubConditionStatusModal: React.FC<SeasonSubConditionStatusModalProps> = ({
  currentStatus,
  newStatusSuggestion,
  conditionDescriptions,
  handleUpdateSeasonSubConditionStatus,
  closeModal
}) => {

  const userConfig = useRecoilValue(userConfigState);

  const statusOptions = ['achieved', 'notAchieved'];

  return (
    <div className='modal-root-container' style={{ width: 400, height: 320 + (conditionDescriptions.length * 20) }}>

      <CloseIcon
        className='modal-icon modal-exit-icon'
        style={{ fontSize: 24 }}
        onClick={() => closeModal()}
      />

      <div className='modal-root-title'>
        {userConfig
          ? (currentStatus ? staticLanguageMap['editConditionStatus'][userConfig.language] : staticLanguageMap['provideConditionStatus'][userConfig.language])
          : ''}
      </div>

      <div className='modal-divider modal-root-title-divider'>&nbsp;</div>

      <div className='confirm-modal-info flex-column' style={{ height: 60 + (conditionDescriptions.length * 20) }}>
        {conditionDescriptions.map((description: string, index: number) => {
          return (
            <div key={index} style={{ marginTop: index > 0 ? 12 : undefined }}>
              {description}
            </div>
          );
        })}
      </div>

      {newStatusSuggestion && (
        <div style={{ position: 'absolute', width: 340, marginLeft: 30, bottom: 175, textAlign: 'center' }}>
          {userConfig ? staticLanguageMap['suggestedStatusInfo'][userConfig.language] : ''}
          {userConfig ? staticLanguageMap[' ' + newStatusSuggestion][userConfig.language] : ''}
        </div>
      )}

      <div className='condition-status-modal-options'>
        {statusOptions.map((status: string, index: number) => {

          const isSelected = status === currentStatus;

          const icon = getConditionStatusIcon(status, '#ffffffcc', 22);

          return (
            <div
              key={status}
              className={
                'edit-player-info-modal-role-option' +
                (isSelected ? ' edit-player-info-modal-role-option-selected' : '')
              }
              style={{ marginTop: index === 0 ? 2 : 18, boxSizing: 'border-box', height: 32, width: 170 }}
              onClick={() => handleUpdateSeasonSubConditionStatus(isSelected ? null : status)}
            >
              <div className='edit-player-info-modal-role-icon' style={{ marginTop: 3 }}>
                {icon}
              </div>
              <div className='edit-player-info-modal-role-text'>
                {userConfig ? staticLanguageMap[status][userConfig.language] : ''}
              </div>
            </div>
          );
        })}
      </div>

    </div>
  );
};
