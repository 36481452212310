import './tabLines.css';

import { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';

import { getClubColor } from '../../utils/configUtils';
import { staticLanguageMap } from '../../../common/static/staticLanguageMap';

import LockIcon from '@mui/icons-material/Lock';
import BlockIcon from '@mui/icons-material/Block';
import { FlexibleJsonMapping } from '../../types';


interface FixedTabLineProps {
  tabOptions: string[] | JSX.Element[];
  tabIcons?: JSX.Element[];
  tabTitles?: string[];

  activeTab: number,
  setActiveTab: (tab: number, updateTabLineCallback?: (tab: number) => void) => void;
  initialActiveTab?: number;

  marginTop?: string;
  marginTopLine?: string;

  isPlayerView?: boolean;
  playerOverview?: FlexibleJsonMapping;
  isLoadingPlayerOverview?: boolean;

  withoutTabLine?: boolean;
  useUpdateTabLineCallback?: boolean;
}


export const FixedTabLine: React.FC<FixedTabLineProps> = ({
  tabOptions,
  tabIcons,
  tabTitles,

  activeTab,
  setActiveTab,
  initialActiveTab,

  marginTop,
  marginTopLine,

  isPlayerView,
  playerOverview,
  isLoadingPlayerOverview,

  withoutTabLine,
  useUpdateTabLineCallback,
}) => {

  const userConfig = useRecoilValue(userConfigState);

  const [tabLineLeft, setTabLineLeft] = useState('0%');

  const lineWidth = 14 - (tabOptions.length / 2);


  const updateTabLine = (tab: number) => {
    const line = document.getElementById('fixed-tab-line');
    if (line && tabOptions.length > 0) {
      const tabWidth = 100 / tabOptions.length;
      const tabOffset = (tabWidth - lineWidth) / 2;
      setTabLineLeft((tabOffset + (tabWidth * tab)) + '%');
    }
  };


  const handleTabClick = (tab: number, isInitialTabClick?: boolean) => {
    if (useUpdateTabLineCallback && !isInitialTabClick) {
      setActiveTab(tab, updateTabLine);
    }
    else {
      setActiveTab(tab);
      if (!withoutTabLine) {
        updateTabLine(tab);
      }
    }
  };


  useEffect(() => {
    if (!withoutTabLine) {
      handleTabClick(initialActiveTab ?? 0, true);
    }
  }, [withoutTabLine, initialActiveTab]); // eslint-disable-line react-hooks/exhaustive-deps


  const clubColor = getClubColor(userConfig?.club ?? '');


  return (
    <div className='tab-lines-tab-section'>

      {!withoutTabLine && (
        <div
          id='fixed-tab-line'
          className='tab-lines-tab-line fixed-tab-line'
          style={{
            backgroundColor: clubColor,
            left: tabLineLeft,
            marginTop: marginTopLine,
            width: lineWidth + '%'
          }}>&nbsp;</div>
      )}

      {tabOptions.map((tabOption, index) => {

        // the tab is either 1) enabled, 2) blocked (and disabled), or 3) locked (and disabled)

        const tabIsEnabled = !isPlayerView || index === 0 || index === 3
          || (index === 1 && !isLoadingPlayerOverview && playerOverview && playerOverview['event_data_available'])
          || (index === 2 && !isLoadingPlayerOverview && playerOverview && playerOverview['has_career_stats']);

        const tabIsLocked = isPlayerView
          && index === 1
          && !isLoadingPlayerOverview
          && playerOverview
          && playerOverview['event_data_exists']
          && !playerOverview['event_data_available'];

        const tabIsBlocked = isPlayerView
          && (index === 1 || index === 2)
          && !isLoadingPlayerOverview
          && !tabIsEnabled
          && !tabIsLocked;


        return (
          <div
            key={index}
            title={tabTitles && userConfig ? staticLanguageMap[tabTitles[index]][userConfig.language] : undefined}
            className={
              'tab-lines-tab fixed-tab-lines-tab' + (activeTab === index
                ? isPlayerView ? ' tab-lines-tab-player-view-active' : ' tab-lines-tab-active'
                : isPlayerView ? ' tab-lines-tab-player-view' : ''
              ) + (!tabIsEnabled ? ' tab-lines-tab-player-view-disabled' : '')}
            style={{ marginTop: marginTop }}
            onClick={() => tabIsEnabled ? handleTabClick(index) : null}
          >

            {!tabIcons && typeof tabOption === 'string' && (
              <div className='tab-lines-tab-title'>
                {userConfig && tabOption in staticLanguageMap ? staticLanguageMap[tabOption][userConfig.language] : tabOption}
              </div>
            )}

            {typeof tabOption !== 'string' && (
              <div className='tab-lines-tab-single-icon'>
                {tabOption}
              </div>
            )}

            {tabIcons && typeof tabOption === 'string' && (
              <div className='tab-lines-tab-row'>
                <div className='tab-lines-tab-icon'>
                  {tabIsLocked && <LockIcon style={{ fontSize: 21 }} />}
                  {tabIsBlocked && <BlockIcon style={{ fontSize: 21 }} />}
                  {tabIsEnabled && tabIcons[index]}
                  {isLoadingPlayerOverview && <BlockIcon style={{ fontSize: 21, color: '#00000000' }} />}
                </div>
                <div className='tab-lines-tab-title'>
                  {userConfig && tabOption in staticLanguageMap ? staticLanguageMap[tabOption][userConfig.language] : tabOption}
                </div>
              </div>
            )}

          </div>
        );
      })}

    </div>
  );
};
