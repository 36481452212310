import '../tables.css';

import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTable, useBlockLayout, useSortBy } from 'react-table';
import { useSticky } from 'react-table-sticky';

import { FlexibleJsonMapping } from '../../../types';
import { getTeamColumns } from './teamColumns';
import { useWindowSize } from '../../../../common/hooks/WindowSize';
import { getNavigationWidth } from '../../navigation/Navigation';
import { settingsState } from '../../../recoil/atoms/settingsState';


interface TeamTableProps {
  data: FlexibleJsonMapping[];
  isAdvancedTeamDataToggled: boolean;
}


export const TeamTable: React.FC<TeamTableProps> = ({ data, isAdvancedTeamDataToggled }) => {

  const userConfig = useRecoilValue(userConfigState);
  const settings = useRecoilValue(settingsState);

  const width = useWindowSize().width;

  const getTableWidth = useCallback((currentWidth: number) => {
    const navigationWidth = getNavigationWidth(userConfig);
    const homeContainerWidth = currentWidth - 40 - navigationWidth;
    const tableWidth = (homeContainerWidth * 0.6) - 20;
    return tableWidth;
  }, [userConfig]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [columns, setColumns] = useState<any[]>(useMemo(
    () => getTeamColumns(getTableWidth(width), isAdvancedTeamDataToggled, userConfig?.language ?? 'en'),
    [isAdvancedTeamDataToggled, userConfig?.language, width, getTableWidth]
  ));

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setSortBy,
  }: FlexibleJsonMapping = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    useBlockLayout,
    useSticky
  );


  useEffect(() => {
    setColumns(getTeamColumns(getTableWidth(width), isAdvancedTeamDataToggled, userConfig?.language ?? 'en'));
    setSortBy([]);
  }, [isAdvancedTeamDataToggled, userConfig?.language, width, getTableWidth, setSortBy]);


  return (
    <div {...getTableProps()} className='team-table'>

      {headerGroups.map((headerGroup: FlexibleJsonMapping) => {
        const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();
        return (
          <div key={key} {...restHeaderGroupProps} className='team-table-header-group'>
            {headerGroup.headers.map((column: FlexibleJsonMapping) => {
              const { key: columnKey, ...restColumnProps } = column.getHeaderProps(column.getSortByToggleProps());
              return (
                <div key={columnKey} {...restColumnProps} className='team-table-header-cell'>
                  <div className='team-table-header-cell-content'>
                    {column.icon ? column.render('Header') : column.Header}
                    <div>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? <KeyboardArrowDownIcon style={{ fontSize: 20, marginLeft: 2, marginTop: column.icon ? 0 : 4 }} />
                          : <KeyboardArrowUpIcon style={{ fontSize: 20, marginLeft: 2, marginTop: column.icon ? 0 : 4 }} />
                        : ''
                      }
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}

      <div {...getTableBodyProps()} className='team-table-body'>
        {rows.map((row: FlexibleJsonMapping, i: number) => {
          prepareRow(row);
          const { key: rowKey, ...restRowProps } = row.getRowProps();
          return (
            <div
              key={rowKey}
              {...restRowProps}
              style={{ ...restRowProps.style, height: (80 / rows.length) + 'vh' }}
              className={
                'team-table-body-row' +
                (i % 2 === 0 ? ' team-table-body-row-even' : ' team-table-body-row-odd') +
                (row.original.club_id === settings?.clubSettings.clubId ? ' team-table-body-row-club' : '')
              }
            >

              {row.cells.map((cell: FlexibleJsonMapping) => {
                const { key: cellKey, ...restCellProps } = cell.getCellProps();
                return (
                  <div
                    key={cellKey}
                    {...restCellProps}
                    className={
                      'team-table-body-cell' +
                      (i % 2 === 0 ? ' team-table-body-cell-even' : ' team-table-body-cell-odd') +
                      (row.original.club_id === settings?.clubSettings.clubId ? ' team-table-body-cell-club' : '')
                    }
                  >
                    {cell.render('Cell')}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>

    </div>
  );
};
