import {
  ClauseCondition,
  ClausePayment,
  Club,
  ClubMapping,
  DatedValue,
  NumberToNumberMapping,
  PlayerSubClause,
  SeasonCondition,
  SeasonConditionStatus,
  SeasonConditionStatusMap,
  SellOnCondition,
  TotalCondition
} from '../../../types';
import { deepCloneObject, stringArraysContainSameElements } from '../../../utils/utils';
import { getYearOfFirstNextSeason } from '../../../views/economy/Economy';
import { ClauseState, getPaymentAmountInClauseCurrency, SubClauseState } from './clauseInitialState';


export type ClauseAction =
  | { type: 'INITIALIZE_STATE'; payload: ClauseState }
  | { type: 'SET_TRANSFER_DATE'; payload: Date | null }
  | { type: 'SET_SELECTED_CLUB'; payload: Club | undefined }
  | { type: 'SET_COMMENT'; payload: string }
  | { type: 'UPLOAD_FILE'; payload: File }
  | { type: 'REMOVE_FILE'; payload: { fileName: string, fileUrl: string, fileExistsInStorage: boolean } }
  | { type: 'SET_TOTAL_TRANSFER_FEE_AND_CONDITIONAL_FEES'; }
  | { type: 'SET_CURRENCY'; payload: string | null }
  | { type: 'SET_PAYMENTS'; payload: { payments: ClausePayment[], deletedPayments: ClausePayment[] } }
  | { type: 'ADD_OR_EDIT_CONDITION'; payload: { newCondition: ClauseCondition, conditionToEdit: ClauseCondition | undefined } }
  | { type: 'DELETE_CONDITION'; payload: string }
  | { type: 'SET_OVERRIDDEN_TOTAL_CONDITION_VALUE'; payload: { overriddenValue: DatedValue | null, totalConditionIndex: number } }
  | { type: 'RESOLVE_TOTAL_CONDITION'; payload: { totalConditionIndex: number, totalSubConditionIndex: number, date?: Date | null } }
  | { type: 'REACTIVATE_TOTAL_CONDITION'; payload: { totalConditionIndex: number, totalSubConditionIndex: number } }
  | {
    type: 'UPDATE_SEASON_SUB_CONDITION_STATUS'; payload: {
      conditionId: string,
      seasonString: string,
      commonSubConditionIndex?: number | undefined,
      orConditionIndex?: number | undefined,
      newStatus: string | null
    }
  }
  | {
    type: 'UPDATE_SEASON_CONDITION_STATUS'; payload: {
      conditionId: string,
      seasonString: string,
      newStatus: string | null,
      achievedOrConditionIndex?: number | undefined,
      date?: Date | null
    }
  }
  | { type: 'RESOLVE_SELL_ON_CONDITION'; payload: { amount: number, date?: Date | null } }
  | { type: 'REACTIVATE_SELL_ON_CONDITION' }
  | {
    type: 'ADD_OR_EDIT_SUB_CLAUSE';
    payload: {
      sellingClubId: number,
      buyingClub: Club,
      transferDate: string,
      currency: string | null,
      sellOnPercentage: number,
      totalSellOnPercentage: number,
      subClauseIndexToEdit: number | undefined
    }
  }
  | { type: 'DELETE_SUB_CLAUSE'; payload: number }
  | { type: 'SET_MAX_CONDITIONAL_FEES'; payload: number | null }
  | { type: 'SET_INCORRECT_NEXT_CLUB'; payload: number }
  | { type: 'REGRET_INCORRECT_NEXT_CLUB'; payload: number }
  | { type: 'SET_ALL_CLUBS'; payload: ClubMapping }
  | { type: 'SET_IS_EDIT_MODE'; payload: boolean }
  | { type: 'SET_IS_CONDITION_MODAL_OPEN'; payload: boolean }
  | { type: 'SET_IS_SUB_CLAUSE_MODAL_OPEN'; payload: boolean }
  | { type: 'SET_ACTIVE_SUB_CLAUSE_TAB'; payload: number | undefined }
  | { type: 'SET_PAYMENT_HIGHLIGHT_INDEX'; payload: number | undefined }
  | { type: 'SET_TRIGGER_CLAUSE_UPDATE'; payload: boolean }


const clauseReducer = (state: ClauseState, action: ClauseAction): ClauseState => {
  switch (action.type) {

    case 'INITIALIZE_STATE':
      return action.payload;

    case 'SET_TRANSFER_DATE':
      return getState_SET_TRANSFER_DATE(action.payload, state);

    case 'SET_SELECTED_CLUB':
      return getState_SET_SELECTED_CLUB(action.payload, state);

    case 'SET_COMMENT':
      return { ...state, comment: action.payload };

    case 'UPLOAD_FILE':
      return getState_UPLOAD_FILE(action.payload, state);

    case 'REMOVE_FILE':
      return getState_REMOVE_FILE(action.payload, state);

    case 'SET_TOTAL_TRANSFER_FEE_AND_CONDITIONAL_FEES':
      return getState_SET_TOTAL_TRANSFER_FEE_AND_CONDITIONAL_FEES(state);

    case 'SET_CURRENCY':
      return { ...state, currency: action.payload };

    case 'SET_PAYMENTS':
      return getState_SET_PAYMENTS(action.payload, state);

    case 'ADD_OR_EDIT_CONDITION':
      return getState_ADD_OR_EDIT_CONDITION(action.payload, state);

    case 'DELETE_CONDITION':
      return getState_DELETE_CONDITION(action.payload, state);

    case 'SET_OVERRIDDEN_TOTAL_CONDITION_VALUE':
      return getState_SET_OVERRIDDEN_TOTAL_CONDITION_VALUE(action.payload, state);

    case 'RESOLVE_TOTAL_CONDITION':
      return getState_RESOLVE_TOTAL_CONDITION(action.payload, state);

    case 'REACTIVATE_TOTAL_CONDITION':
      return getState_REACTIVATE_TOTAL_CONDITION(action.payload, state);

    case 'UPDATE_SEASON_SUB_CONDITION_STATUS':
      return getState_UPDATE_SEASON_SUB_CONDITION_STATUS(action.payload, state);

    case 'UPDATE_SEASON_CONDITION_STATUS':
      return getState_UPDATE_SEASON_CONDITION_STATUS(action.payload, state);

    case 'RESOLVE_SELL_ON_CONDITION':
      return getState_RESOLVE_SELL_ON_CONDITION(action.payload, state);

    case 'REACTIVATE_SELL_ON_CONDITION':
      return getState_REACTIVATE_SELL_ON_CONDITION(state);

    case 'ADD_OR_EDIT_SUB_CLAUSE':
      return getState_ADD_OR_EDIT_SUB_CLAUSE(action.payload, state);

    case 'DELETE_SUB_CLAUSE':
      return getState_DELETE_SUB_CLAUSE(action.payload, state);

    case 'SET_MAX_CONDITIONAL_FEES':
      return getState_SET_MAX_CONDITIONAL_FEES(action.payload, state);

    case 'SET_INCORRECT_NEXT_CLUB':
      return getState_SET_INCORRECT_NEXT_CLUB(action.payload, state);

    case 'REGRET_INCORRECT_NEXT_CLUB':
      return getState_REGRET_INCORRECT_NEXT_CLUB(action.payload, state);

    case 'SET_ALL_CLUBS':
      return { ...state, additionalClauseState: { ...state.additionalClauseState, allClubs: action.payload } };

    case 'SET_IS_EDIT_MODE':
      return { ...state, additionalClauseState: { ...state.additionalClauseState, isEditMode: action.payload } };

    case 'SET_IS_CONDITION_MODAL_OPEN':
      return { ...state, additionalClauseState: { ...state.additionalClauseState, isConditionModalOpen: action.payload } };

    case 'SET_IS_SUB_CLAUSE_MODAL_OPEN':
      return { ...state, additionalClauseState: { ...state.additionalClauseState, isSubClauseModalOpen: action.payload } };

    case 'SET_ACTIVE_SUB_CLAUSE_TAB':
      return { ...state, additionalClauseState: { ...state.additionalClauseState, activeSubClauseTab: action.payload } };

    case 'SET_PAYMENT_HIGHLIGHT_INDEX':
      return { ...state, additionalClauseState: { ...state.additionalClauseState, paymentHighlightIndex: action.payload } };

    case 'SET_TRIGGER_CLAUSE_UPDATE':
      return { ...state, additionalClauseState: { ...state.additionalClauseState, triggerClauseUpdate: action.payload } };

    default:
      return state;
  }
};


const getState_SET_TRANSFER_DATE = (
  date: Date | null,
  state: ClauseState
) => {

  if (!date) {
    return { ...state, transferDate: undefined };
  }

  const adjustedDate = new Date(date);
  adjustedDate.setHours(12);
  const transferDate = adjustedDate.toISOString().split('T')[0];

  const seasonConditions = getNewSeasonConditionsWithNewTransferDate(adjustedDate, state.seasonConditions);

  return { ...state, transferDate, seasonConditions };
};


// if any seasonConditions are already added and date changes, we update their season strings
// if allSeasons is selected, we re-calculate and set the new season options
// if not, and if any specific season is selected that is no longer valid, we remove this season
const getNewSeasonConditionsWithNewTransferDate = (
  newDate: Date,
  seasonConditions: SeasonCondition[],
) => {
  if (seasonConditions.length > 0) {
    seasonConditions = seasonConditions.map(seasonCondition => {

      const isCrossYear = seasonCondition.seasonStrings.some(seasonString => seasonString.includes('/'));
      const firstYear = getYearOfFirstNextSeason(newDate, isCrossYear);
      const newSeasonOptions: string[] = [];
      for (let i = 0; i < 10; i++) {
        const season = isCrossYear
          ? (String(firstYear + i).substring(2)) + '/' + (String(firstYear + i + 1).substring(2))
          : firstYear + i;
        newSeasonOptions.push(String(season));
      }

      if (seasonCondition.seasonString === 'allSeasons') {
        return {
          ...seasonCondition,
          seasonStrings: newSeasonOptions
        };
      }
      else {
        return {
          ...seasonCondition,
          seasonStrings: seasonCondition.seasonStrings.filter(seasonString => newSeasonOptions.includes(seasonString))
        };
      }
    });
  }

  return seasonConditions;
};


const getState_SET_SELECTED_CLUB = (
  selectedClub: Club | undefined,
  state: ClauseState
) => {

  const sellingClubId = state.isSoldPlayer ? state.sellingClubId : selectedClub?.id;
  const buyingClubId = state.isSoldPlayer ? selectedClub?.id : state.buyingClubId;

  // add the new club to allClubs if it does not already exist
  const allClubs = { ...state.additionalClauseState.allClubs };
  if (selectedClub && !allClubs[selectedClub.id]) {
    allClubs[selectedClub.id] = selectedClub;
  }

  // if there is no buying club, or if the buying club is the user's club, the totalConditions and seasonConditions need not be updated
  if (!selectedClub || buyingClubId === undefined || !state.isSoldPlayer) {
    return {
      ...state,
      sellingClubId,
      buyingClubId,
      additionalClauseState: { ...state.additionalClauseState, allClubs }
    };
  }

  const { totalConditions, seasonConditions } = getNewConditionsWithNewBuyingClub(selectedClub, state.totalConditions, state.seasonConditions);

  return {
    ...state,
    sellingClubId,
    buyingClubId,
    totalConditions,
    seasonConditions,
    additionalClauseState: { ...state.additionalClauseState, allClubs }
  };
};


const getNewCompeitionIds = (selectedClub: Club, condition: TotalCondition | SeasonCondition) => {
  const newPossibleCompetitionIds = selectedClub.possible_competition_ids ?? [];
  if (condition.competitionString === 'allCompetitions') {
    return newPossibleCompetitionIds;
  }
  else {
    return condition.competitionIds.filter(competitionId => newPossibleCompetitionIds.includes(competitionId));
  }
};


const getNewConditionsWithNewBuyingClub = (
  newBuyingClub: Club,
  totalConditions: TotalCondition[],
  seasonConditions: SeasonCondition[],
) => {
  // if totalConditions or seasonConditions already exist, their competitions are updates to attempt to align with the new buying club
  totalConditions = totalConditions.map(totalCondition => {
    return { ...totalCondition, competitionIds: getNewCompeitionIds(newBuyingClub, totalCondition) };
  });

  seasonConditions = seasonConditions.map(seasonCondition => {
    return { ...seasonCondition, competitionIds: getNewCompeitionIds(newBuyingClub, seasonCondition) };
  });

  return { totalConditions, seasonConditions };
};


const getState_UPLOAD_FILE = (
  file: File,
  state: ClauseState
) => {
  const uploadedFiles = [...state.additionalClauseState.uploadedFiles, file];
  const additionalClauseState = { ...state.additionalClauseState, uploadedFiles };

  const fileNames = [...state.fileNames, file.name];
  const fileUrls = [...state.fileUrls, URL.createObjectURL(file)];
  return { ...state, fileNames, fileUrls, additionalClauseState };
};


const getState_REMOVE_FILE = (
  payload: { fileName: string, fileUrl: string, fileExistsInStorage: boolean },
  state: ClauseState
) => {

  let removedFileNames = state.additionalClauseState.removedFileNames;

  // only add the file to removedFileNames if it is not already there and it exists in storage
  if (payload.fileExistsInStorage && !state.additionalClauseState.removedFileNames.includes(payload.fileName)) {
    removedFileNames = [...state.additionalClauseState.removedFileNames, payload.fileName];
  }

  // we remove the file from uploadedFiles in case it was added in this session
  const uploadedFiles = state.additionalClauseState.uploadedFiles.filter(file => file.name !== payload.fileName);

  const additionalClauseState = { ...state.additionalClauseState, removedFileNames, uploadedFiles };

  const fileNames = state.fileNames.filter(fileName => fileName !== payload.fileName);
  const fileUrls = state.fileUrls.filter(fileUrl => fileUrl !== payload.fileUrl);

  return { ...state, fileNames, fileUrls, additionalClauseState };
};


const getState_SET_TOTAL_TRANSFER_FEE_AND_CONDITIONAL_FEES = (state: ClauseState) => {

  let totalTransferFee = 0;
  let conditionalFees = 0;
  const subClauseConditionalFees: NumberToNumberMapping = {};

  state.payments.forEach(payment => {
    totalTransferFee += getPaymentAmountInClauseCurrency(payment, state.subClauses, state.currency);

    if (payment.conditionType && payment.conditionType !== 'sellOn') {
      if (payment.subClauseIndex === undefined) {
        conditionalFees += payment.amount;
      }
      else {
        if (!subClauseConditionalFees[payment.subClauseIndex]) {
          subClauseConditionalFees[payment.subClauseIndex] = payment.amount;
        }
        else {
          subClauseConditionalFees[payment.subClauseIndex] += payment.amount;
        }
      }
    }
  });

  const subClauses = state.subClauses?.map((subClause, index) => {
    return { ...subClause, conditionalFee: subClauseConditionalFees[index] ?? 0 };
  }) ?? null;

  return { ...state, totalTransferFee, conditionalFees, subClauses };
};


const getState_SET_PAYMENTS = (
  payload: { payments: ClausePayment[], deletedPayments: ClausePayment[] },
  state: ClauseState
) => {

  if (payload.deletedPayments.length === 0) {
    return { ...state, payments: payload.payments };
  }

  // if conditional payments are deleted, the related condition will be unresolved
  const { totalConditions, seasonConditions, sellOnCondition } = getNewConditionsWithDeletedPayments(state, undefined, payload.deletedPayments);

  // iterate through all subClauses and update their conditions
  const subClauses = state.subClauses?.map((subClause, index) => {
    const { totalConditions, seasonConditions, sellOnCondition } = getNewConditionsWithDeletedPayments(state, index, payload.deletedPayments);
    return { ...subClause, totalConditions, seasonConditions, sellOnCondition };
  }) ?? null;

  return { ...state, totalConditions, seasonConditions, sellOnCondition, payments: payload.payments, subClauses };
};


const getNewConditionsWithDeletedPayments = (
  state: ClauseState,
  subClauseIndex: number | undefined,
  deletedPayments: ClausePayment[]
) => {

  const stateAccessor = subClauseIndex !== undefined && state.subClauses
    ? state.subClauses[subClauseIndex]
    : state;

  let totalConditions = stateAccessor.totalConditions;
  let seasonConditions = deepCloneObject(stateAccessor.seasonConditions) as SeasonCondition[];
  let sellOnCondition = stateAccessor.sellOnCondition;

  if (state.sellOnCondition && deletedPayments.some(payment => payment.conditionType === 'sellOn')) {
    sellOnCondition = { ...sellOnCondition, isResolved: false } as SellOnCondition;
  }

  totalConditions = totalConditions.map(condition => {
    const newSubConditions = condition.subConditions.map(subCondition => {
      if (deletedPayments.some(
        payment => (payment.totalConditionThreshold === subCondition.threshold && payment.conditionId === condition.conditionId)
      )) {
        return { ...subCondition, isResolved: false, resolvedAmount: null };
      }
      return subCondition;
    });

    return { ...condition, subConditions: newSubConditions };
  });

  seasonConditions = seasonConditions.map(condition => {
    let newTotalResolvedAmount = condition.totalResolvedAmount ?? 0;
    const newSeasonToCurrentStatuses: SeasonConditionStatusMap = { ...condition.seasonToCurrentStatuses };

    Object.keys(condition.seasonToCurrentStatuses).forEach(seasonString => {
      const newCurrentSeasonStatuses = condition.seasonToCurrentStatuses[seasonString];
      if (deletedPayments.some(payment => (payment.seasonString === seasonString && payment.conditionId === condition.conditionId))) {
        newTotalResolvedAmount -= (newCurrentSeasonStatuses.resolvedAmount ?? 0);
        newCurrentSeasonStatuses.overallStatus = null;
        newCurrentSeasonStatuses.resolvedAmount = null;
      }
      newSeasonToCurrentStatuses[seasonString] = newCurrentSeasonStatuses;
    });

    return { ...condition, totalResolvedAmount: Math.max(newTotalResolvedAmount ?? 0, 0), seasonToCurrentStatuses: newSeasonToCurrentStatuses };
  });

  return { totalConditions, seasonConditions, sellOnCondition };
};


const getState_ADD_OR_EDIT_CONDITION = (
  payload: { newCondition: ClauseCondition, conditionToEdit: ClauseCondition | undefined, },
  state: ClauseState
) => {

  if (payload.newCondition.conditionType === 'sellOn') {
    const currentSellOnCondition = payload.conditionToEdit as SellOnCondition;
    const newSellOnCondition = payload.newCondition as SellOnCondition;

    let payments = state.payments;

    // if the condition is resolved, we may need to update the related payment (this is a bit overkill and does not work if the clause has sub clauses)
    if (currentSellOnCondition && newSellOnCondition.isResolved && !state.subClauses) {

      const currentIsNet = currentSellOnCondition.isNet;
      const newIsNet = newSellOnCondition.isNet;

      const currentPercentage = currentSellOnCondition.sellOnPercentage;
      const newPercentage = newSellOnCondition.sellOnPercentage;

      const isNetUpdate = currentIsNet !== newIsNet;
      const isPercentageUpdate = currentPercentage !== newPercentage;

      if (isNetUpdate || isPercentageUpdate) {
        // if either isNet or percentage has changed, we recalculate the related payment amount
        const currentAmount = state.payments.find(payment => payment.conditionId === currentSellOnCondition.conditionId)?.amount ?? 0;
        const yourTransferFee = state.totalTransferFee - currentAmount;

        let currentNewTransferFee = currentAmount / (currentPercentage / 100);
        if (currentIsNet) currentNewTransferFee += yourTransferFee;

        const newAmount = (currentNewTransferFee - (newIsNet ? yourTransferFee : 0)) * (newPercentage / 100);

        // update payment amount
        payments = state.payments.map(payment => {
          if (payment.conditionId === currentSellOnCondition.conditionId) {
            return { ...payment, amount: newAmount };
          }
          return payment;
        });
      }
    }

    // activeSubClauseTab decides which sellOnCondition to add or update
    if (state.additionalClauseState.activeSubClauseTab !== undefined && state.subClauses) {
      const subClauses = state.subClauses.map((subClause, index) => {
        if (index === state.additionalClauseState.activeSubClauseTab) {
          return { ...subClause, sellOnCondition: newSellOnCondition };
        }
        return subClause;
      });

      return { ...state, payments, subClauses };
    }

    return { ...state, payments, sellOnCondition: newSellOnCondition };
  }

  else if (payload.newCondition.conditionType === 'total') {

    // activeSubClauseTab decides which totalConditions to add or update
    let totalConditions = state.additionalClauseState.activeSubClauseTab !== undefined && state.subClauses
      ? state.subClauses[state.additionalClauseState.activeSubClauseTab].totalConditions
      : state.totalConditions;

    const currentTotalCondition = payload.conditionToEdit as TotalCondition;
    const newTotalCondition = payload.newCondition as TotalCondition;

    let payments = state.payments;
    let paymentHighlightIndex: number | undefined = undefined;

    // if the condition (selectedCondition) has changed, we delete all related payments and update the isResolved status
    // if the condition (selectedCondition) has not changed, we keep all payments whose threshold still exist and we update the amount
    if (currentTotalCondition) {

      const conditionHasChanged = newTotalCondition.condition !== currentTotalCondition.condition;

      payments = [];

      if (conditionHasChanged) {
        payments = state.payments.filter(payment => payment.conditionId !== currentTotalCondition.conditionId);
        if (payments.length < state.payments.length) {
          paymentHighlightIndex = -1;
        }

        newTotalCondition.subConditions = newTotalCondition.subConditions.map(subCondition => {
          return { ...subCondition, isResolved: false, resolvedAmount: null };
        });
      }
      else {
        state.payments.map(payment => {
          if (payment.conditionId === currentTotalCondition.conditionId) {
            const subCondition = newTotalCondition.subConditions.find(subCondition => subCondition.threshold === payment.totalConditionThreshold);
            if (subCondition) {
              payments.push({ ...payment, amount: subCondition.resolvedAmount ?? subCondition.amount });
            }
          }
          else {
            payments.push(payment);
          }
        });
      }

      totalConditions = totalConditions.map((existingCondition: TotalCondition) => {
        if (existingCondition.conditionId === currentTotalCondition.conditionId) {
          return newTotalCondition;
        }
        return existingCondition;
      });
    }
    else {
      totalConditions = [...totalConditions, newTotalCondition];
    }

    const additionalClauseState = { ...state.additionalClauseState, paymentHighlightIndex };

    if (state.additionalClauseState.activeSubClauseTab !== undefined && state.subClauses) {
      const subClauses = state.subClauses.map((subClause, index) => {
        if (index === state.additionalClauseState.activeSubClauseTab) {
          return { ...subClause, totalConditions };
        }
        return subClause;
      });

      return { ...state, payments, subClauses, additionalClauseState };
    }

    return { ...state, totalConditions, payments, additionalClauseState };
  }

  else if (payload.newCondition.conditionType === 'season') {

    // activeSubClauseTab decides which seasonConditions to add or update
    let seasonConditions = state.additionalClauseState.activeSubClauseTab !== undefined && state.subClauses
      ? state.subClauses[state.additionalClauseState.activeSubClauseTab].seasonConditions
      : state.seasonConditions;

    const currentSeasonCondition = payload.conditionToEdit as SeasonCondition;
    const newSeasonCondition = payload.newCondition as SeasonCondition;

    let payments = state.payments;
    let paymentHighlightIndex: number | undefined = undefined;

    if (currentSeasonCondition) {
      seasonConditions = seasonConditions.map((existingCondition: SeasonCondition) => {
        if (existingCondition.conditionId === currentSeasonCondition.conditionId) {

          // the season statues and payments must be updated if any relevant changes are made to the condition
          const isSeasonUpdate = !stringArraysContainSameElements(
            existingCondition.seasonStrings, newSeasonCondition.seasonStrings
          );

          const isCommonSubConditionUpdate = !stringArraysContainSameElements(
            (existingCondition.commonSubConditions ?? []).map(
              subCondition => (subCondition.condition + (subCondition.condition === 'other' ? '' : subCondition.threshold))
            ),
            (newSeasonCondition.commonSubConditions ?? []).map(
              subCondition => (subCondition.condition + (subCondition.condition === 'other' ? '' : subCondition.threshold))
            )
          );

          const isOrConditionUpdate = !stringArraysContainSameElements(
            (existingCondition.orConditions ?? []).map(
              orCondition => orCondition.subConditions.map(
                subCondition => (subCondition.condition + (subCondition.condition === 'other' ? '' : subCondition.threshold))
              ).join('')
            ),
            (newSeasonCondition.orConditions ?? []).map(
              orCondition => orCondition.subConditions.map(
                subCondition => (subCondition.condition + (subCondition.condition === 'other' ? '' : subCondition.threshold))
              ).join('')
            )
          );

          const isStandardAmountUpdate = existingCondition.amount !== newSeasonCondition.amount;
          const isAdvancedAmountUpdate = !stringArraysContainSameElements(
            (existingCondition.orConditions ?? []).map(orCondition => orCondition.amount.toString()),
            (newSeasonCondition.orConditions ?? []).map(orCondition => orCondition.amount.toString())
          );

          let newTotalResolvedAmount = 0;

          // if any subconditions have changed, we reset all statuses and delete all payments
          if (isCommonSubConditionUpdate || isOrConditionUpdate) {
            payments = payments.filter(payment => payment.conditionId !== currentSeasonCondition.conditionId);
            newSeasonCondition.seasonToCurrentStatuses = {};
          }

          else if (isSeasonUpdate) {
            const newSeasonToCurrentStatuses: SeasonConditionStatusMap = {};
            const seasonsThatWereRemoved = existingCondition.seasonStrings.filter(seasonString => !newSeasonCondition.seasonStrings.includes(seasonString));

            // for all new season, copy over their statuses
            newSeasonCondition.seasonStrings.forEach(seasonString => {
              newSeasonToCurrentStatuses[seasonString] = newSeasonCondition.seasonToCurrentStatuses[seasonString] ?? {};
            });

            // for all removed seasons, delete their payments
            seasonsThatWereRemoved.forEach(seasonString => {
              payments = payments.filter(payment => {
                const isSeasonPayment = (payment.conditionId === currentSeasonCondition.conditionId && payment.seasonString === seasonString);
                return !isSeasonPayment;
              });
            });

            newSeasonCondition.seasonToCurrentStatuses = newSeasonToCurrentStatuses;
          }

          // if amount has changed, we update related payment amounts and the resolvedAmount of the condition status and the totalResolvedAmount
          if (isStandardAmountUpdate || isAdvancedAmountUpdate) {
            // we sort by season here in case updates causes the maxAmount to be reached
            payments.sort((a, b) => {
              if (!a.seasonString) return 1;
              if (!b.seasonString) return -1;
              return a.seasonString.localeCompare(b.seasonString);
            });

            let maxAmountIsReached = false;
            payments = payments.map(payment => {

              if (payment.conditionId === currentSeasonCondition.conditionId && payment.seasonString) {
                // get the new correct payment amount for the payment
                let newAmount = newSeasonCondition.amount;
                if (!newAmount) {
                  const achievedOrConditionIndex = newSeasonCondition.seasonToCurrentStatuses[payment.seasonString]?.orConditionStatuses?.findIndex(
                    (status: string | null) => status === 'achieved'
                  );
                  if (achievedOrConditionIndex !== undefined && achievedOrConditionIndex !== -1 && newSeasonCondition.orConditions) {
                    newAmount = newSeasonCondition.orConditions[achievedOrConditionIndex].amount;
                  }
                }

                if (newAmount) {
                  if (maxAmountIsReached) {
                    newAmount = 0;
                  }
                  // if new payments cause the maxAmount to be reached, we crop the amount
                  else if (newSeasonCondition.maxAmount && (newTotalResolvedAmount + newAmount >= newSeasonCondition.maxAmount)) {
                    newAmount = Math.max(newSeasonCondition.maxAmount - newTotalResolvedAmount, 0);
                    maxAmountIsReached = true;
                  }

                  newSeasonCondition.seasonToCurrentStatuses[payment.seasonString].resolvedAmount = newAmount === 0 ? null : newAmount;

                  if (newAmount === 0) {
                    newSeasonCondition.seasonToCurrentStatuses[payment.seasonString].overallStatus = null;
                  }

                  newTotalResolvedAmount -= payment.amount;
                  newTotalResolvedAmount += newAmount;

                  return { ...payment, amount: newAmount };
                }
              }

              return payment;
            });

            payments = payments.filter(payment => payment.amount !== 0);

            payments.sort((a, b) => {
              if (!a.date) return 1;
              if (!b.date) return -1;
              return a.date.localeCompare(b.date);
            });
          }

          // compute the new newTotalResolvedAmount based on the new payments for this condition
          newTotalResolvedAmount = payments.reduce(
            (total, payment) => total + (payment.conditionId === currentSeasonCondition.conditionId ? payment.amount : 0), 0
          );
          newSeasonCondition.totalResolvedAmount = Math.max(newTotalResolvedAmount, 0);

          if (payments.length < payments.length) {
            paymentHighlightIndex = -1;
          }

          return newSeasonCondition;
        }
        return existingCondition;
      });
    }
    else {
      seasonConditions = [...seasonConditions, newSeasonCondition];
    }

    const additionalClauseState = { ...state.additionalClauseState, paymentHighlightIndex };

    if (state.additionalClauseState.activeSubClauseTab !== undefined && state.subClauses) {
      const subClauses = state.subClauses.map((subClause, index) => {
        if (index === state.additionalClauseState.activeSubClauseTab) {
          return { ...subClause, seasonConditions };
        }
        return subClause;
      });

      return { ...state, payments, subClauses, additionalClauseState };
    }

    return { ...state, payments, seasonConditions, additionalClauseState };
  }

  return state;
};


const getState_DELETE_CONDITION = (
  conditionId: string,
  state: ClauseState
) => {

  const stateAccessor = state.additionalClauseState.activeSubClauseTab !== undefined && state.subClauses
    ? state.subClauses[state.additionalClauseState.activeSubClauseTab]
    : state;

  let sellOnCondition = stateAccessor.sellOnCondition;
  let totalConditions = stateAccessor.totalConditions;
  let seasonConditions = stateAccessor.seasonConditions;

  let payments = state.payments;
  let paymentHighlightIndex = state.additionalClauseState.paymentHighlightIndex;

  if (conditionId) {
    if (sellOnCondition?.conditionId === conditionId) {
      sellOnCondition = null;
    }
    totalConditions = totalConditions.filter(totalCondition => totalCondition.conditionId !== conditionId);
    seasonConditions = seasonConditions.filter(seasonCondition => seasonCondition.conditionId !== conditionId);

    // if condition has payments, the payments must be deleted
    payments = payments.filter(payment => payment.conditionId !== conditionId);
    if (payments.length < state.payments.length) {
      paymentHighlightIndex = -1;
    }
  }

  const additionalClauseState = { ...state.additionalClauseState, paymentHighlightIndex };

  if (additionalClauseState.activeSubClauseTab !== undefined && state.subClauses) {
    const subClauses = state.subClauses.map((subClause, index) => {
      if (index === state.additionalClauseState.activeSubClauseTab) {
        return { ...subClause, totalConditions, seasonConditions, sellOnCondition };
      }
      return subClause;
    });

    return { ...state, payments, subClauses, additionalClauseState };
  }

  return { ...state, payments, sellOnCondition, totalConditions, seasonConditions, additionalClauseState };
};


const getState_SET_OVERRIDDEN_TOTAL_CONDITION_VALUE = (
  payload: { overriddenValue: DatedValue | null, totalConditionIndex: number },
  state: ClauseState
) => {

  const stateAccessor = state.additionalClauseState.activeSubClauseTab !== undefined && state.subClauses
    ? state.subClauses[state.additionalClauseState.activeSubClauseTab]
    : state;

  const totalConditions = stateAccessor.totalConditions.map((existingCondition, conditionIndex) => {
    if (conditionIndex === payload.totalConditionIndex) {
      return {
        ...existingCondition,
        overriddenValue: payload.overriddenValue,
      };
    }
    return existingCondition;
  });

  const additionalClauseState = { ...state.additionalClauseState, triggerClauseUpdate: true };

  if (additionalClauseState.activeSubClauseTab !== undefined && state.subClauses) {
    const subClauses = state.subClauses.map((subClause, index) => {
      if (index === state.additionalClauseState.activeSubClauseTab) {
        return { ...subClause, totalConditions };
      }
      return subClause;
    });

    return { ...state, subClauses, additionalClauseState };
  }

  return { ...state, totalConditions, additionalClauseState };
};


const getState_RESOLVE_TOTAL_CONDITION = (
  payload: { totalConditionIndex: number, totalSubConditionIndex: number, date?: Date | null },
  state: ClauseState
) => {

  const stateAccessor = state.additionalClauseState.activeSubClauseTab !== undefined && state.subClauses
    ? state.subClauses[state.additionalClauseState.activeSubClauseTab]
    : state;

  const condition = stateAccessor.totalConditions[payload.totalConditionIndex];

  // if amount causes the maxAmount to be reached, we crop the amount
  const amount = condition.subConditions[payload.totalSubConditionIndex].amount;
  let resolvedAmount: number | undefined = undefined;
  if (stateAccessor.maxConditionalFees && ((stateAccessor.conditionalFees ?? 0) + amount > stateAccessor.maxConditionalFees)) {
    resolvedAmount = Math.max(stateAccessor.maxConditionalFees - (stateAccessor.conditionalFees ?? 0), 0);
  }

  const totalConditions = stateAccessor.totalConditions.map((existingCondition, conditionIndex) => {
    if (conditionIndex === payload.totalConditionIndex && existingCondition.subConditions) {
      return {
        ...existingCondition,
        subConditions: existingCondition.subConditions.map((subCondition, index) => {
          if (index === payload.totalSubConditionIndex) {
            const newSubCondition = { ...subCondition, isResolved: true };
            if (resolvedAmount !== undefined) {
              newSubCondition.resolvedAmount = resolvedAmount;
            }
            return newSubCondition;
          }
          return subCondition;
        })
      };
    }
    return existingCondition;
  });

  const newPayment: ClausePayment = {
    amount: resolvedAmount ?? amount,

    conditionType: 'total',
    conditionId: condition.conditionId,

    totalCondition: condition.condition,
    totalConditionThreshold: condition.subConditions[payload.totalSubConditionIndex].threshold,
  };

  if (state.additionalClauseState.activeSubClauseTab !== undefined) {
    newPayment.subClauseIndex = state.additionalClauseState.activeSubClauseTab;
  }

  if (payload.date) {
    newPayment.date = payload.date.toISOString().split('T')[0];
  }

  const payments = [...state.payments, newPayment];
  payments.sort((a, b) => {
    if (!a.date) return 1;
    if (!b.date) return -1;
    return a.date.localeCompare(b.date);
  });

  const paymentHighlightIndex = payments.findIndex(
    payment => (payment.totalConditionThreshold === newPayment.totalConditionThreshold && payment.conditionId === newPayment.conditionId)
  );

  const additionalClauseState = { ...state.additionalClauseState, triggerClauseUpdate: true, paymentHighlightIndex };

  if (additionalClauseState.activeSubClauseTab !== undefined && state.subClauses) {
    const subClauses = state.subClauses.map((subClause, index) => {
      if (index === state.additionalClauseState.activeSubClauseTab) {
        return { ...subClause, totalConditions };
      }
      return subClause;
    });

    return { ...state, payments, subClauses, additionalClauseState };
  }

  return { ...state, payments, totalConditions, additionalClauseState };
};


const getState_REACTIVATE_TOTAL_CONDITION = (
  payload: { totalConditionIndex: number, totalSubConditionIndex: number },
  state: ClauseState
) => {

  const stateAccessor = state.additionalClauseState.activeSubClauseTab !== undefined && state.subClauses
    ? state.subClauses[state.additionalClauseState.activeSubClauseTab]
    : state;

  // update isResolved status of the subCondition
  const totalConditions = stateAccessor.totalConditions.map((existingCondition, conditionIndex) => {
    if (conditionIndex === payload.totalConditionIndex && existingCondition.subConditions) {
      return {
        ...existingCondition,
        subConditions: existingCondition.subConditions.map((subCondition, index) => {
          return payload.totalSubConditionIndex === index
            ? { ...subCondition, isResolved: false, resolvedAmount: null }
            : subCondition;
        })
      };
    }
    return existingCondition;
  });

  // remove the payment
  const index = payload.totalConditionIndex;
  const subIndex = payload.totalSubConditionIndex;
  const payments = state.payments.filter(payment => !(
    payment.totalConditionThreshold === totalConditions[index].subConditions[subIndex].threshold &&
    payment.conditionId === totalConditions[index].conditionId
  ));

  const additionalClauseState = { ...state.additionalClauseState, triggerClauseUpdate: true, paymentHighlightIndex: -1 };

  if (additionalClauseState.activeSubClauseTab !== undefined && state.subClauses) {
    const subClauses = state.subClauses.map((subClause, index) => {
      if (index === state.additionalClauseState.activeSubClauseTab) {
        return { ...subClause, totalConditions };
      }
      return subClause;
    });

    return { ...state, payments, subClauses, additionalClauseState };
  }

  return { ...state, payments, totalConditions, additionalClauseState };
};


const getState_RESOLVE_SELL_ON_CONDITION = (
  payload: { amount: number, date?: Date | null },
  state: ClauseState
) => {

  const stateAccessor = state.additionalClauseState.activeSubClauseTab !== undefined && state.subClauses
    ? state.subClauses[state.additionalClauseState.activeSubClauseTab]
    : state;

  const sellOnCondition = { ...stateAccessor.sellOnCondition, isResolved: true } as SellOnCondition;

  const newPayment: ClausePayment = {
    amount: payload.amount,
    conditionType: 'sellOn',
    conditionId: sellOnCondition.conditionId,
  };

  if (state.additionalClauseState.activeSubClauseTab !== undefined) {
    newPayment.subClauseIndex = state.additionalClauseState.activeSubClauseTab;
  }

  if (payload.date) {
    newPayment.date = payload.date.toISOString().split('T')[0];
  }

  const payments = [...state.payments, newPayment];
  payments.sort((a, b) => {
    if (!a.date) return 1;
    if (!b.date) return -1;
    return a.date.localeCompare(b.date);
  });

  const paymentHighlightIndex = payments.findIndex(payment => payment.conditionId === sellOnCondition.conditionId);
  const additionalClauseState = { ...state.additionalClauseState, triggerClauseUpdate: true, paymentHighlightIndex };

  if (additionalClauseState.activeSubClauseTab !== undefined && state.subClauses) {
    const subClauses = state.subClauses.map((subClause, index) => {
      if (index === state.additionalClauseState.activeSubClauseTab) {
        return { ...subClause, sellOnCondition };
      }
      return subClause;
    });

    return { ...state, payments, subClauses, additionalClauseState };
  }

  return { ...state, payments, sellOnCondition, additionalClauseState };
};


const getState_REACTIVATE_SELL_ON_CONDITION = (
  state: ClauseState
) => {
  const stateAccessor = state.additionalClauseState.activeSubClauseTab !== undefined && state.subClauses
    ? state.subClauses[state.additionalClauseState.activeSubClauseTab]
    : state;

  const sellOnCondition = { ...stateAccessor.sellOnCondition, isResolved: false } as SellOnCondition;
  const payments = state.payments.filter(payment => payment.conditionId !== sellOnCondition.conditionId);
  const additionalClauseState = { ...state.additionalClauseState, triggerClauseUpdate: true, paymentHighlightIndex: -1 };

  if (additionalClauseState.activeSubClauseTab !== undefined && state.subClauses) {
    const subClauses = state.subClauses.map((subClause, index) => {
      if (index === state.additionalClauseState.activeSubClauseTab) {
        return { ...subClause, sellOnCondition };
      }
      return subClause;
    });

    return { ...state, payments, subClauses, additionalClauseState };
  }

  return { ...state, payments, sellOnCondition, additionalClauseState };
};


const getState_UPDATE_SEASON_SUB_CONDITION_STATUS = (
  payload: {
    conditionId: string,
    seasonString: string,
    commonSubConditionIndex?: number | undefined,
    orConditionIndex?: number | undefined,
    newStatus: string | null
  },
  state: ClauseState
) => {

  const stateAccessor = state.additionalClauseState.activeSubClauseTab !== undefined && state.subClauses
    ? state.subClauses[state.additionalClauseState.activeSubClauseTab]
    : state;

  let payments = state.payments;
  let paymentHighlightIndex: number | undefined = undefined;

  const seasonConditions = stateAccessor.seasonConditions.map(existingCondition => {
    if (existingCondition.conditionId === payload.conditionId) {

      const currentSeasonStatus: SeasonConditionStatus = { ...existingCondition.seasonToCurrentStatuses[payload.seasonString] };
      const currentOverallStatus = currentSeasonStatus.overallStatus;

      const newSeasonToCurrentStatuses: SeasonConditionStatusMap = deepCloneObject(existingCondition.seasonToCurrentStatuses);

      let newTotalResolvedAmount = (existingCondition.totalResolvedAmount ?? 0);

      let newCurrentStatuses: SeasonConditionStatus = newSeasonToCurrentStatuses[payload.seasonString];
      if (!newCurrentStatuses || Object.keys(newCurrentStatuses).length === 0) {
        newCurrentStatuses = {
          overallStatus: null
        };

        if (existingCondition.commonSubConditions) {
          newCurrentStatuses.commonSubConditionStatuses = existingCondition.commonSubConditions.map(() => null);
        }

        if (existingCondition.orConditions) {
          newCurrentStatuses.orConditionStatuses = existingCondition.orConditions.map(() => null);
        }
      }

      if (payload.commonSubConditionIndex !== undefined && newCurrentStatuses.commonSubConditionStatuses) {
        newCurrentStatuses.commonSubConditionStatuses[payload.commonSubConditionIndex] = payload.newStatus;
      }
      else if (payload.orConditionIndex !== undefined && newCurrentStatuses.orConditionStatuses) {
        // if an orCondition is achieved, all other orConditions must be discarded
        if (payload.newStatus === 'achieved') {
          newCurrentStatuses.orConditionStatuses = newCurrentStatuses.orConditionStatuses.map(() => 'notAchieved');
        }
        newCurrentStatuses.orConditionStatuses[payload.orConditionIndex] = payload.newStatus;

        // in an orCondition is achieved and overall status is resolved, payment and resolvedAmount and totalResolvedAmount must be updated
        if (payload.newStatus === 'achieved' && currentOverallStatus === 'resolved') {

          newTotalResolvedAmount -= (newCurrentStatuses.resolvedAmount ?? 0);
          const currentAmount = newCurrentStatuses.resolvedAmount;
          let newAmount = currentAmount;

          payments = payments.map((payment, paymentIndex) => {
            if (payment.conditionId === payload.conditionId
              && payment.seasonString === payload.seasonString
              && existingCondition.orConditions
              && payload.orConditionIndex !== undefined
            ) {
              paymentHighlightIndex = paymentIndex;
              newAmount = existingCondition.orConditions[payload.orConditionIndex].amount;

              // if the maximum amount (either for season clause of all conditions) is surpassed by this payment, the amount must be cropped
              const totalFeesLeft = stateAccessor.maxConditionalFees ? stateAccessor.maxConditionalFees - (stateAccessor.conditionalFees ?? 0) : Infinity;
              const conditionFeesLeft = existingCondition.maxAmount ? existingCondition.maxAmount - newTotalResolvedAmount : Infinity;
              const maxAmountOfPayment = Math.min(totalFeesLeft, conditionFeesLeft) + (currentAmount ?? 0);

              if (newAmount > maxAmountOfPayment) {
                newAmount = maxAmountOfPayment;
              }

              return { ...payment, amount: newAmount };
            }
            return payment;
          });

          newCurrentStatuses.resolvedAmount = newAmount;

          newTotalResolvedAmount += (newAmount ?? 0);
        }
      }

      // if overall status was resolved and is now discarded, related payments must be deleted and the overall status must be updated
      // the overall status will be discarded if a common subcondition is discarded, or if it is an orCondition and all orConditions are now discarded
      const shouldDiscardOverallStatus = payload.newStatus === 'notAchieved' &&
        (
          payload.commonSubConditionIndex !== undefined ||
          (newCurrentStatuses.orConditionStatuses && newCurrentStatuses.orConditionStatuses.every(status => status === 'notAchieved'))
        );

      const shouldDeletePayment = currentOverallStatus === 'resolved' && shouldDiscardOverallStatus;

      if (shouldDeletePayment) {
        payments = payments.filter(
          payment => !(payment.conditionId === payload.conditionId && payment.seasonString === payload.seasonString)
        );
        paymentHighlightIndex = -1;

        newTotalResolvedAmount -= (newCurrentStatuses.resolvedAmount ?? 0);
        newCurrentStatuses.resolvedAmount = null;
      }

      if (shouldDiscardOverallStatus) {
        newCurrentStatuses.overallStatus = 'discarded';
      }

      newSeasonToCurrentStatuses[payload.seasonString] = newCurrentStatuses;

      return {
        ...existingCondition,
        totalResolvedAmount: Math.max(newTotalResolvedAmount ?? 0, 0),
        seasonToCurrentStatuses: newSeasonToCurrentStatuses
      };
    }

    return existingCondition;
  });

  const additionalClauseState = { ...state.additionalClauseState, triggerClauseUpdate: true, paymentHighlightIndex };

  if (additionalClauseState.activeSubClauseTab !== undefined && state.subClauses) {
    const subClauses = state.subClauses.map((subClause, index) => {
      if (index === state.additionalClauseState.activeSubClauseTab) {
        return { ...subClause, seasonConditions };
      }
      return subClause;
    });

    return { ...state, payments, subClauses, additionalClauseState };
  }

  return { ...state, payments, seasonConditions, additionalClauseState };
};


const getState_UPDATE_SEASON_CONDITION_STATUS = (
  payload: {
    conditionId: string,
    seasonString: string,
    newStatus: string | null,
    achievedOrConditionIndex?: number | undefined,
    date?: Date | null
  },
  state: ClauseState
) => {

  const stateAccessor = state.additionalClauseState.activeSubClauseTab !== undefined && state.subClauses
    ? state.subClauses[state.additionalClauseState.activeSubClauseTab]
    : state;

  let payments = state.payments;
  let paymentHighlightIndex: number | undefined = undefined;

  const seasonConditions = stateAccessor.seasonConditions.map(existingCondition => {
    if (existingCondition.conditionId === payload.conditionId) {

      const newSeasonToCurrentStatuses: SeasonConditionStatusMap = deepCloneObject(existingCondition.seasonToCurrentStatuses);

      let newCurrentStatuses: SeasonConditionStatus = newSeasonToCurrentStatuses[payload.seasonString];
      if (!newCurrentStatuses) {
        newCurrentStatuses = {
          overallStatus: null
        };

        if (existingCondition.commonSubConditions) {
          newCurrentStatuses.commonSubConditionStatuses = existingCondition.commonSubConditions.map(() => null);
        }

        if (existingCondition.orConditions) {
          newCurrentStatuses.orConditionStatuses = existingCondition.orConditions.map(() => null);
        }
      }

      // if condition is now resolved, all common subconditions and achievedOrConditionIndex (if provided) must be achieved
      // if achievedOrConditionIndex is not already achieved, it must now be achieved and all other orConditions must be discarded
      let newTotalResolvedAmount = existingCondition.totalResolvedAmount ?? 0;
      if (payload.newStatus === 'resolved') {

        let amount = existingCondition.amount; // either amount exists on condition (standardCondition) or achievedOrConditionIndex is provided (orCondition)

        if (existingCondition.commonSubConditions) {
          newCurrentStatuses.commonSubConditionStatuses = existingCondition.commonSubConditions.map(() => 'achieved');
        }

        if (existingCondition.orConditions) {
          newCurrentStatuses.orConditionStatuses = existingCondition.orConditions.map((_, index) => {
            return index === payload.achievedOrConditionIndex ? 'achieved' : 'notAchieved';
          });

          if (payload.achievedOrConditionIndex !== undefined) {
            amount = existingCondition.orConditions[payload.achievedOrConditionIndex].amount;
          }
        }

        // if the maximum amount (either for season clause of all conditions) is surpassed by this payment, the amount must be cropped
        const totalFeesLeft = stateAccessor.maxConditionalFees ? stateAccessor.maxConditionalFees - (stateAccessor.conditionalFees ?? 0) : Infinity;
        const conditionFeesLeft = existingCondition.maxAmount ? existingCondition.maxAmount - newTotalResolvedAmount : Infinity;
        const maxAmountOfPayment = Math.min(totalFeesLeft, conditionFeesLeft);

        if ((amount ?? 0) > maxAmountOfPayment) {
          amount = maxAmountOfPayment;
        }

        newCurrentStatuses.resolvedAmount = amount;
        newTotalResolvedAmount += (amount ?? 0);

        const newPayment: ClausePayment = {
          amount: amount ?? 0,
          conditionType: 'season',
          conditionId: payload.conditionId,
          seasonString: payload.seasonString,
        };

        if (state.additionalClauseState.activeSubClauseTab !== undefined) {
          newPayment.subClauseIndex = state.additionalClauseState.activeSubClauseTab;
        }

        if (payload.date) {
          newPayment.date = payload.date.toISOString().split('T')[0];
        }

        payments = [...payments, newPayment];
        payments.sort((a, b) => {
          if (!a.date) return 1;
          if (!b.date) return -1;
          return a.date.localeCompare(b.date);
        });

        paymentHighlightIndex = payments.findIndex(
          payment => (payment.conditionId === payload.conditionId && payment.seasonString === payload.seasonString)
        );
      }

      // if condition was resolved, and is now discarded or reactivated, related payment must be deleted, and subcondition statuses must be reset
      else if (newCurrentStatuses.overallStatus === 'resolved') {
        payments = payments.filter(payment => !(payment.conditionId === payload.conditionId && payment.seasonString === payload.seasonString));
        paymentHighlightIndex = -1;

        newTotalResolvedAmount -= (newCurrentStatuses.resolvedAmount ?? 0);
        newCurrentStatuses.resolvedAmount = null;

        if (existingCondition.commonSubConditions) {
          newCurrentStatuses.commonSubConditionStatuses = existingCondition.commonSubConditions.map(() => null);
        }

        if (existingCondition.orConditions) {
          newCurrentStatuses.orConditionStatuses = existingCondition.orConditions.map(() => null);
        }
      }

      newCurrentStatuses.overallStatus = payload.newStatus;
      newSeasonToCurrentStatuses[payload.seasonString] = newCurrentStatuses;

      return {
        ...existingCondition,
        seasonToCurrentStatuses: newSeasonToCurrentStatuses,
        totalResolvedAmount: Math.max(newTotalResolvedAmount ?? 0, 0),
      };
    }

    return existingCondition;
  });

  const additionalClauseState = { ...state.additionalClauseState, triggerClauseUpdate: true, paymentHighlightIndex };

  if (additionalClauseState.activeSubClauseTab !== undefined && state.subClauses) {
    const subClauses = state.subClauses.map((subClause, index) => {
      if (index === state.additionalClauseState.activeSubClauseTab) {
        return { ...subClause, seasonConditions };
      }
      return subClause;
    });

    return { ...state, payments, subClauses, additionalClauseState };
  }

  return { ...state, payments, seasonConditions, additionalClauseState };
};


const getState_ADD_OR_EDIT_SUB_CLAUSE = (
  payload: {
    sellingClubId: number,
    buyingClub: Club,
    transferDate: string,
    currency: string | null,
    sellOnPercentage: number,
    totalSellOnPercentage: number,
    subClauseIndexToEdit: number | undefined
  },
  state: ClauseState
) => {

  let subClauses = state.subClauses ? [...state.subClauses] : [];

  if (payload.subClauseIndexToEdit !== undefined) {
    subClauses = subClauses.map((existingSubClause, subClauseIndex) => {
      if (subClauseIndex === payload.subClauseIndexToEdit) {

        let totalConditions = existingSubClause.totalConditions;
        let seasonConditions = existingSubClause.seasonConditions;

        const isBuyingClubUpdate = existingSubClause.buyingClubId !== payload.buyingClub.id;
        if (isBuyingClubUpdate) {
          // add the new club to allClubs if it does not already exist
          const allClubs = { ...state.additionalClauseState.allClubs };
          if (!allClubs[payload.buyingClub.id]) {
            allClubs[payload.buyingClub.id] = payload.buyingClub;
          }

          const newConditions = getNewConditionsWithNewBuyingClub(payload.buyingClub, totalConditions, seasonConditions);
          totalConditions = newConditions.totalConditions;
          seasonConditions = newConditions.seasonConditions;
        }

        const isTransferDateUpdate = existingSubClause.transferDate !== payload.transferDate;
        if (isTransferDateUpdate) {
          seasonConditions = getNewSeasonConditionsWithNewTransferDate(new Date(payload.transferDate), seasonConditions);
        }

        // const isCurrencyUpdate = existingSubClause.currency !== payload.currency;
        // const isSellOnPercentageUpdate = existingSubClause.sellOnPercentage !== payload.sellOnPercentage;

        return {
          ...existingSubClause,
          buyingClubId: payload.buyingClub.id,
          transferDate: payload.transferDate,
          currency: payload.currency,
          sellOnPercentage: payload.sellOnPercentage,
          totalConditions,
          seasonConditions,
        };
      }
      return existingSubClause;
    });
  }
  else {
    const newSubClause: PlayerSubClause = {
      sellingClubId: payload.sellingClubId,
      buyingClubId: payload.buyingClub.id,
      transferDate: payload.transferDate,

      sellOnPercentage: payload.sellOnPercentage,
      totalSellOnPercentage: payload.totalSellOnPercentage,
      currency: payload.currency,

      sellOnCondition: null,
      totalConditions: [],
      seasonConditions: [],
      maxConditionalFees: null,
    };

    subClauses.push(newSubClause);
  }

  // if buyingClub is not in allClubs, we add it
  const allClubs = { ...state.additionalClauseState.allClubs };
  if (!allClubs[payload.buyingClub.id]) {
    allClubs[payload.buyingClub.id] = payload.buyingClub;
  }

  // if a new subClause is added, we set the activeSubClauseTab to the new subClause
  const activeSubClauseTab = payload.subClauseIndexToEdit === undefined ? subClauses.length - 1 : state.additionalClauseState.activeSubClauseTab;

  return {
    ...state,
    subClauses,
    additionalClauseState: {
      ...state.additionalClauseState,
      allClubs,
      activeSubClauseTab,
      triggerClauseUpdate: true,
      isEditMode: payload.subClauseIndexToEdit === undefined ? true : state.additionalClauseState.isEditMode
    }
  };
};


const getState_DELETE_SUB_CLAUSE = (
  subClauseIndex: number,
  state: ClauseState
) => {
  let subClauses: SubClauseState[] | null = state.subClauses ? state.subClauses.filter((_, index) => index < subClauseIndex) : [];
  if (subClauses.length === 0) {
    subClauses = null;
  }

  // also delete all payments related to the subClause
  const payments = state.payments.filter(payment => (payment.subClauseIndex === undefined || payment.subClauseIndex < subClauseIndex));

  const additionalClauseState = {
    ...state.additionalClauseState,
    activeSubClauseTab: undefined,
    triggerClauseUpdate: true
  };
  return { ...state, payments, subClauses, additionalClauseState };
};


const getState_SET_MAX_CONDITIONAL_FEES = (
  maxConditionalFees: number | null,
  state: ClauseState
) => {
  if (state.additionalClauseState.activeSubClauseTab !== undefined && state.subClauses) {
    const subClauses = state.subClauses.map((subClause, index) => {
      if (index === state.additionalClauseState.activeSubClauseTab) {
        return { ...subClause, maxConditionalFees };
      }
      return subClause;
    });

    return { ...state, subClauses, additionalClauseState: { ...state.additionalClauseState, triggerClauseUpdate: true } };
  }

  return { ...state, maxConditionalFees, additionalClauseState: { ...state.additionalClauseState, triggerClauseUpdate: true } };
};


const getState_SET_INCORRECT_NEXT_CLUB = (
  incorrectNextClubId: number,
  state: ClauseState
) => {
  if (state.additionalClauseState.activeSubClauseTab !== undefined && state.subClauses) {
    const subClauses = state.subClauses.map((subClause, index) => {
      if (index === state.additionalClauseState.activeSubClauseTab) {
        return { ...subClause, incorrectNextClubIds: [...(state.incorrectNextClubIds ?? []), incorrectNextClubId] };
      }
      return subClause;
    });

    return { ...state, subClauses, additionalClauseState: { ...state.additionalClauseState, triggerClauseUpdate: true } };
  }

  return {
    ...state,
    incorrectNextClubIds: [...(state.incorrectNextClubIds ?? []), incorrectNextClubId],
    additionalClauseState: { ...state.additionalClauseState, triggerClauseUpdate: true }
  };
};


const getState_REGRET_INCORRECT_NEXT_CLUB = (
  incorrectNextClubId: number,
  state: ClauseState
) => {

  const stateAccessor = state.additionalClauseState.activeSubClauseTab !== undefined && state.subClauses
    ? state.subClauses[state.additionalClauseState.activeSubClauseTab]
    : state;

  const currentIncorrectNextClubIds = stateAccessor.incorrectNextClubIds ?? [];
  const newIncorrectNextClubIds: number[] = [];

  // if an incorrect transfer is regretted, we remove the club and all subsequent clubs from the list
  let isClubFound = false;
  for (const id of currentIncorrectNextClubIds) {
    if (id === incorrectNextClubId) {
      isClubFound = true;
    }
    if (!isClubFound) {
      newIncorrectNextClubIds.push(id);
    }
  }

  if (state.additionalClauseState.activeSubClauseTab !== undefined && state.subClauses) {
    const subClauses = state.subClauses.map((subClause, index) => {
      if (index === state.additionalClauseState.activeSubClauseTab) {
        return { ...subClause, incorrectNextClubIds: newIncorrectNextClubIds };
      }
      return subClause;
    });

    return { ...state, subClauses, additionalClauseState: { ...state.additionalClauseState, triggerClauseUpdate: true } };
  }

  return {
    ...state,
    incorrectNextClubIds: newIncorrectNextClubIds,
    additionalClauseState: { ...state.additionalClauseState, triggerClauseUpdate: true }
  };
};


export default clauseReducer;
