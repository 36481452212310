import './modals.css';

import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { settingsState } from '../../recoil/atoms/settingsState';
import { useGlobalModal } from '../../recoil/hooks/openGlobalModal';

import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { InputField } from '../input/InputField';
import { staticLanguageMap } from '../../../common/static/staticLanguageMap';
import { FlexibleJsonMapping } from '../../types';
import { deleteSearch } from '../../services/firestore/settings';


interface SaveOrLoadSearchModalProps {
  modalType: string | undefined;
  searchType: string;
  closeModal: () => void;
  saveSearch: (searchName: string) => void,
  loadSearch: (savedSearch: FlexibleJsonMapping) => void,
}


export const SaveOrLoadSearchModal: React.FC<SaveOrLoadSearchModalProps> = ({ modalType, searchType, closeModal, saveSearch, loadSearch }) => {

  const userConfig = useRecoilValue(userConfigState);
  const settings = useRecoilValue(settingsState);

  const { handleOpenConfirmModal } = useGlobalModal();

  const [searchString, setSearchString] = useState('');

  const [buttonDisabled, setButtonDisabled] = useState(false);


  const onChangeSearchField = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length < 50) {
      setSearchString(event.target.value);
    }
  };


  const handleSaveSearch = async () => {
    if (!userConfig || buttonDisabled || searchString.length === 0) return;

    setButtonDisabled(true);

    saveSearch(searchString);

    setButtonDisabled(false);

    closeModal();
  };


  const handleLoadSearch = async (search: FlexibleJsonMapping) => {
    if (!userConfig || buttonDisabled) return;

    setButtonDisabled(true);

    loadSearch(search);

    setButtonDisabled(false);

    closeModal();
  };


  const handleDeleteSearch = (index: number, event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    handleOpenConfirmModal(
      () => confirmDelete(index),
      'deleteSearch',
      'noRegretInfo',
    );
  };


  const confirmDelete = (index: number) => {
    if (!userConfig) return;

    deleteSearch(index, userConfig.email, userConfig.club);
  };


  return (
    <div
      className='modal-root-container save-or-load-search-modal-root'
      style={{ height: modalType === 'saveSearch' ? 310 : modalType === 'loadSearch' ? undefined : 0 }}
    >

      <CloseIcon
        className='modal-icon modal-exit-icon'
        style={{ fontSize: 24 }}
        onClick={() => closeModal()}
      />

      <div className='modal-root-title'>
        {modalType === 'saveSearch' && userConfig && staticLanguageMap['saveSearch'][userConfig.language]}
        {modalType === 'loadSearch' && userConfig && staticLanguageMap['loadSearch'][userConfig.language]}
      </div>

      <div className='modal-divider modal-root-title-divider'>&nbsp;</div>

      {modalType === 'saveSearch' && (
        <div>
          <div className='modal-section-title' style={{ marginTop: 30 }}>
            {userConfig ? staticLanguageMap['provideNameOfSearch'][userConfig.language] : ''}
          </div>

          <div className='save-or-load-search-modal-input-field'>
            <InputField
              searchString={searchString}
              onChangeInputField={onChangeSearchField}
              resetSearchString={() => setSearchString('')}
              onKeyDownInputField={undefined}
              defaultInput={userConfig ? staticLanguageMap['provideName'][userConfig.language] : ''}
              showDefaultInput={true}
              style={{ boxShadow: '0px 0px 2px 1px #15171e69' }}
            />
          </div>
        </div>
      )}

      {modalType === 'loadSearch' && (
        <div className='save-or-load-search-saved-searches'>
          {settings && settings.userSettings['savedSearches'] && settings.userSettings['savedSearches'].length > 0 &&
            settings.userSettings['savedSearches'].map((search: FlexibleJsonMapping, index: number) => {

              if (search['searchType'] !== searchType) return null;

              return (
                <div className='save-or-load-search-saved-search' key={index} onClick={() => handleLoadSearch(search)}>
                  <div className='save-or-load-search-saved-search-name'>
                    {search['searchName']}
                  </div>
                  <div className='save-or-load-search-saved-icon-container'>
                    <div
                      className='save-or-load-search-saved-icon'
                      title={userConfig ? staticLanguageMap['deleteSearch'][userConfig.language] : ''}
                      onClick={(event) => handleDeleteSearch(index, event)}>
                      <DeleteOutlineIcon style={{ fontSize: 20 }} />
                    </div>
                  </div>
                </div>
              );
            })}

          {!(settings
            && settings.userSettings['savedSearches']
            && settings.userSettings['savedSearches'].filter((savedSearch: FlexibleJsonMapping) => savedSearch.searchType === searchType).length > 0) &&
            (
              <div className='save-or-load-search-no-saved-searches'>
                {userConfig ? staticLanguageMap['noSavedSearches'][userConfig.language] : ''}
              </div>
            )
          }
        </div>
      )}

      {modalType === 'saveSearch' && searchString.length > 0 && (
        <div className='modal-button modal-button-middle' style={{ marginLeft: 150 }} onClick={() => handleSaveSearch()}>
          <div className='modal-button-text'>
            {userConfig ? staticLanguageMap['saveSearch'][userConfig.language] : ''}
          </div>

          {modalType === 'saveSearch' && (
            <SaveIcon
              className='modal-button-icon'
              style={{ fontSize: 24 }}
            />
          )}
        </div>
      )}

    </div>
  );
};
