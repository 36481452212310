import '../../platform.css';
import './settings.css';

import { useState } from 'react';

import AccountCircle from '@mui/icons-material/AccountCircle';
import GroupsIcon from '@mui/icons-material/Groups';
import HelpIcon from '@mui/icons-material/Help';

import { FixedTabLine } from '../../components/tabLines/FixedTabLine';
import { Account } from './Account';
import { ClubSettings } from './ClubSettings';
import { Support } from './Support';


export const Settings: React.FC = () => {

  const [activeTab, setActiveTab] = useState(0);

  const tabOptions = [
    'account',
    'club',
    'help'
  ];

  const tabIcons = [
    <AccountCircle key={'account-icon'} style={{ fontSize: 22 }} />,
    <GroupsIcon key={'groups-icon'} style={{ fontSize: 22 }} />,
    <HelpIcon key={'help-icon'} style={{ fontSize: 22 }} />
  ];

  return (
    <div className='platform-view-section'>

      <FixedTabLine
        tabOptions={tabOptions}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabIcons={tabIcons}
      />

      {activeTab === 0 && <Account />}

      {activeTab === 1 && <ClubSettings />}

      {activeTab === 2 && <Support />}

    </div>
  );
};
