import '../../platform.css';
import './searchPlayers.css';

import { AuthContextType, useAuthContext } from '../../../common/contexts/AuthContext';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { playersState } from '../../recoil/atoms/playersState';
import { playerOverviewsState } from '../../recoil/atoms/playerOverviewsState';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { playerTeamDataState } from '../../recoil/selectors/playerTeamDataState';
import { FlexibleJsonMapping, JsonMapping, PlayerEntry, PlayerId, PlayerOverview, PlayerOverviews } from '../../types';

import SwipeRightIcon from '@mui/icons-material/SwipeRight';

import { PlayerSimpleTable } from '../tables/playerSimpleTable/PlayerSimpleTable';
import { InputField } from '../input/InputField';
import { staticLanguageMap } from '../../../common/static/staticLanguageMap';
import { debounce } from '../../utils/utils';
import { searchPlayers } from '../../services/fokusServer/playerOverviews';
import { teamsState } from '../../recoil/atoms/teamsState';
import { positionOptionsPlatform } from '../../static/propertyValues';
import { getSortDistanceRole } from '../../utils/playerUtils';
import { Toggle } from '../input/Toggle';


interface AddPlayerViewProps {
  searchString: string;
  setSearchString: (value: string) => void;

  playerData: PlayerOverviews | undefined;
  setPlayerData: (playerData: PlayerOverviews | undefined) => void;

  isSquad: boolean;
  isOwnTeam: boolean;
  isTabLine: boolean;

  isAddPlayerWithoutData: boolean;
  setIsAddPlayerWithoutData: (value: boolean) => void;

  draggedPlayer: JsonMapping | undefined;
  setDraggedPlayer: (player: JsonMapping | undefined) => void;
}

export const AddPlayerView: React.FC<AddPlayerViewProps> = ({
  searchString,
  setSearchString,

  playerData,
  setPlayerData,

  isSquad,
  isOwnTeam,
  isTabLine,

  isAddPlayerWithoutData,
  setIsAddPlayerWithoutData,

  draggedPlayer,
  setDraggedPlayer,
}) => {

  const { currentUser } = useAuthContext() as AuthContextType;
  const userConfig = useRecoilValue(userConfigState);

  const players = useRecoilValue(playersState);
  const playerOverviews = useRecoilValue(playerOverviewsState);
  const playerTeamData = useRecoilValue(playerTeamDataState);
  const teams = useRecoilValue(teamsState);

  const [ownTeamPlayers, setOwnTeamPlayers] = useState<FlexibleJsonMapping[]>([]);
  const [academyPlayers, setAcademyPlayers] = useState<FlexibleJsonMapping[]>([]);
  const [showAcademyPlayers, setShowAcademyPlayers] = useState(false);

  const [existingPlayersWithoutData, setExistingPlayersWithoutData] = useState<FlexibleJsonMapping[]>([]);

  const [debouncedSearchString, setDebouncedSearchString] = useState<string>('');
  const clearDebounceRef = useRef<() => void>(() => null);

  const [searchWasReset, setSearchWasReset] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState<JsonMapping[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalHits, setTotalHits] = useState(0);

  const [isSearchWithoutResult, setIsSearchWithoutResult] = useState(false);

  const [showText, setShowText] = useState(false);


  const onChangeSearchField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value);
    if (event.target.value === '') {
      setTableData([]);
      setDebouncedSearchString('');
      setIsSearchWithoutResult(false);
    }
    else if (isAddPlayerWithoutData) {
      setTableData(
        [{
          id: 'newPlayerWithoutData',
          fullname: event.target.value
        }]
      );
      setIsSearchWithoutResult(true);
    }
  };


  const resetSearchField = useCallback(() => {
    setSearchString('');
    setDebouncedSearchString('');
    setTableData([]);
    setIsSearchWithoutResult(false);
  }, [setSearchString]);


  const onKeyDownSearchField = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (!isAddPlayerWithoutData) {
        handleSearchForPlayers(true, true);
      }
    }
  };

  const handleSearchForPlayers = async (isUserInitiated: boolean, isNewSearch: boolean) => {

    setIsLoading(true);
    setSearchWasReset(false);

    if (isUserInitiated) {
      clearDebounceRef.current();
    }

    const nextPageToQuery = isNewSearch ? 1 : currentPage + 1;

    if (searchString !== '') {
      const query = {
        name: searchString.toLowerCase(),
        page: nextPageToQuery,
      };

      try {
        const result: FlexibleJsonMapping | undefined = await searchPlayers(currentUser, query);

        const totalHits = result?.total_hits;
        const page = result?.current_page;
        const players: PlayerOverview[] = result?.players ?? [];

        if (!result || totalHits === undefined || !page) {
          setTableData([]);
        }
        else if (totalHits === 0 || (isNewSearch && players.length === 0)) {
          setIsSearchWithoutResult(true);
          setTableData([]);
          setCurrentPage(0);
          setTotalHits(0);
        }
        else {
          const playersObject = players.reduce((acc: PlayerOverviews, player: PlayerOverview) => {
            acc[player['id']] = player;
            return acc;
          }, {});

          const newTableData: FlexibleJsonMapping[] = [];

          // todo: do we need to create a playerCopy, or can we just use player?
          players.forEach((player: PlayerOverview) => {
            const playerCopy: FlexibleJsonMapping = {
              id: player['id'],
              image_url: player['image_url'],
              fullname: player['fullname'],
              club: player.club,
              country_code: player['country_code'],
              primary_position: player['primary_position'],
            };
            if (playerTeamData && playerCopy['id'] in playerTeamData) {
              playerCopy['currentTeam'] = playerTeamData[playerCopy['id']].currentTeam;
            }
            newTableData.push(playerCopy);
          });

          setIsSearchWithoutResult(false);

          setTableData(isNewSearch ? newTableData : [...tableData, ...newTableData]);
          setPlayerData(isNewSearch ? playersObject : { ...playersObject, ...playerData });

          setCurrentPage(page);
          setTotalHits(totalHits);
        }
      } catch (error) {
        console.log(error); // eslint-disable-line no-console
      }
    }

    setIsLoading(false);
  };


  useEffect(() => {
    if (searchString === '') {
      resetSearchField();
    }
  }, [resetSearchField, searchString]);


  const handleMessageLinkClick = () => {
    setSearchString('');
    setDebouncedSearchString('');
    setIsSearchWithoutResult(false);
    if (isAddPlayerWithoutData) {
      setIsAddPlayerWithoutData(false);
    }
    else {
      setIsAddPlayerWithoutData(true);
    }
  };


  useEffect(() => {
    if (players && playerTeamData) {
      const existingPlayersWithoutData: FlexibleJsonMapping[] = [];

      Object.keys(players).forEach((playerId: PlayerId) => {
        if (isNaN(Number(playerId))) {
          const player = { ...players[playerId] };

          if (playerTeamData && playerId in playerTeamData) {
            player['currentTeam'] = playerTeamData[playerId].currentTeam;
          }

          existingPlayersWithoutData.push(player);
        }
      });

      setExistingPlayersWithoutData(existingPlayersWithoutData);
    }
  }, [players, playerTeamData]);


  useEffect(() => {
    const newTableData: FlexibleJsonMapping[] = [];

    tableData.forEach((player: FlexibleJsonMapping) => {
      const playerCopy: FlexibleJsonMapping = {
        id: player['id'],
        image_url: player['image_url'],
        fullname: player['fullname'],
        club: player.club,
        country_code: player['country_code'],
        primary_position: player['primary_position'],
      };
      if (playerTeamData && playerCopy['id'] in playerTeamData) {
        playerCopy['currentTeam'] = playerTeamData[playerCopy['id']].currentTeam;
      }
      newTableData.push(playerCopy);
    });

    setTableData(newTableData);
  }, [playerTeamData]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (teams && players && playerOverviews && (isSquad || isOwnTeam)) {
      const ownTeam: FlexibleJsonMapping[] = [];
      const academy: FlexibleJsonMapping[] = [];

      positionOptionsPlatform.forEach(positionKey => {

        if (isSquad && teams['ownTeam'] && positionKey in teams['ownTeam']) {
          teams['ownTeam'][positionKey].forEach((player: PlayerEntry) => {
            let playerCopy: FlexibleJsonMapping = { ...player, currentTeam: 'ownTeam' };

            if (player['id'] in playerOverviews) {
              playerCopy['image_url'] = playerOverviews[Number(player['id'])]['image_url'];
              playerCopy['primary_position'] = playerOverviews[Number(player['id'])]['primary_position'];
            }
            if (player['id'] in players) {
              playerCopy = { ...playerCopy, ...players[player['id']] };
            }

            ownTeam.push(playerCopy);
          });
        }

        if (teams['academyTeam'] && positionKey in teams['academyTeam']) {
          teams['academyTeam'][positionKey].forEach((player: PlayerEntry) => {
            let playerCopy: FlexibleJsonMapping = { currentTeam: 'academyTeam', ...player };

            if (player['id'] in playerOverviews) {
              playerCopy['image_url'] = playerOverviews[Number(player['id'])]['image_url'];
              playerCopy['primary_position'] = playerOverviews[Number(player['id'])]['primary_position'];
            }
            if (player['id'] in players) {
              playerCopy = { ...playerCopy, ...players[player['id']] };
            }

            academy.push(playerCopy);
          });
        }

      });

      ownTeam.sort((a: FlexibleJsonMapping, b: FlexibleJsonMapping) => getSortDistanceRole(a, b));
      academy.sort((a: FlexibleJsonMapping, b: FlexibleJsonMapping) => getSortDistanceRole(a, b, true));

      setOwnTeamPlayers(ownTeam);
      setAcademyPlayers(academy);
    }
  }, [teams, players, playerOverviews, isSquad, isOwnTeam]);


  useEffect(() => {
    if (searchString.length === 0) {
      setTableData([]);
    }

    if (!isAddPlayerWithoutData) {
      const [debounceFilter, clearDebounce] = debounce(() => {
        if (searchString !== debouncedSearchString) {
          setDebouncedSearchString(searchString);
        }
      }, 500);

      debounceFilter();

      clearDebounceRef.current = clearDebounce;

      return () => {
        clearDebounce();
      };
    }
  }, [searchString]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (debouncedSearchString !== '') {
      handleSearchForPlayers(false, true);
    }
  }, [debouncedSearchString]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    const timer = setTimeout(() => {
      setShowText(true);
    }, 250);

    return () => clearTimeout(timer);
  }, []);


  const resetSearch = () => {
    setSearchString('');
    setTableData([]);
    setIsSearchWithoutResult(false);
    setSearchWasReset(true);
  };


  useEffect(() => {
    if (searchWasReset) {
      const timer = setTimeout(() => {
        setTableData([]);
      }, 250);

      return () => clearTimeout(timer);
    }
  }, [searchWasReset]);


  return (
    <div className='add-player-view-container'>

      {showText && tableData.length > 0 && !searchWasReset && (
        <div className='add-player-view-swipe-icon' style={{ top: isAddPlayerWithoutData ? '21vh' : '19vh' }}>
          <SwipeRightIcon style={{ fontSize: 22 }} />
        </div>
      )}

      <div className='add-player-view-message-link fade-in' onClick={handleMessageLinkClick}>
        {showText && userConfig
          ? (isAddPlayerWithoutData
            ? staticLanguageMap['searchForPlayer?'][userConfig.language]
            : staticLanguageMap['addPlayerWithoutData?'][userConfig.language])
          : ''}
      </div>

      {isAddPlayerWithoutData && (
        <div className='add-player-view-title'>
          {showText && userConfig ? staticLanguageMap['addPlayerWithoutData'][userConfig.language] : ''}
        </div>
      )}

      {showText && (
        <div
          style={{ marginTop: isAddPlayerWithoutData ? '9vh' : '4vh' }}
          className='add-player-view-input-field-container'
        >
          <InputField
            searchString={searchString}
            onChangeInputField={onChangeSearchField}
            onKeyDownInputField={onKeyDownSearchField}
            resetSearchString={resetSearch}
            defaultInput={
              userConfig
                ? (isAddPlayerWithoutData
                  ? staticLanguageMap['providePlayerName'][userConfig.language]
                  : staticLanguageMap['searchForName'][userConfig.language])
                : ''}
            showDefaultInput={showText}
            style={{ boxShadow: '0px 0px 4px 2px #0e101324' }}
          />
        </div>
      )}

      {(searchString.length > 0 || isSearchWithoutResult || tableData.length > 0) && !searchWasReset && (
        <div className='add-player-view-result-section'>

          {isSearchWithoutResult && !isAddPlayerWithoutData && tableData.length === 0 && (
            <div className='add-player-view-no-result fade-in'>
              {userConfig ? staticLanguageMap['noResult'][userConfig['language']] : ''}
            </div>
          )}

          {tableData.length > 0 && (
            <div className='add-player-view-table'>
              <PlayerSimpleTable
                data={tableData}
                tableType={isAddPlayerWithoutData ? 'addPlayerSimpleViewTeamIcon' : 'addPlayerView'}
                maxHeight={isTabLine ? '70vh' : '74vh'}
                positionKey={'addPlayerTable'}
                draggedPlayer={draggedPlayer}
                setDraggedPlayer={setDraggedPlayer}

                isLoading={isLoading}
                handleSearchForPlayers={handleSearchForPlayers}
                currentPage={currentPage}
                totalHits={totalHits}
              />
            </div>
          )}

        </div>
      )}


      {showText
        && ownTeamPlayers.length > 0
        && isSquad
        && !isAddPlayerWithoutData
        && searchString.length === 0
        && !isSearchWithoutResult
        && tableData.length === 0
        && (
          <div className='add-player-view-result-section'>

            {academyPlayers.length > 0 && (
              <div className='add-player-view-academy-toggle-section'>
                {userConfig ? staticLanguageMap['showAcademyPlayers'][userConfig.language] : ''}
                <div className='add-player-view-academy-toggle'>
                  <Toggle isToggled={showAcademyPlayers} setIsToggled={setShowAcademyPlayers} boxShadow={'0px 0px 2px 1px #181a2366'} />
                </div>
              </div>
            )}

            <div
              style={{ marginTop: academyPlayers.length === 0 ? '3vh' : undefined }}
              className='add-player-view-table'>
              <PlayerSimpleTable
                data={showAcademyPlayers ? academyPlayers : ownTeamPlayers}
                tableType={'addPlayerSimpleViewRoleIcon'}
                maxHeight={academyPlayers.length === 0 ? '67vh' : '64vh'}
                positionKey={'addPlayerTableExistingPlayers'}
                draggedPlayer={draggedPlayer}
                setDraggedPlayer={setDraggedPlayer}
              />
            </div>

          </div>
        )}


      {showText
        && academyPlayers.length > 0
        && isOwnTeam
        && !isAddPlayerWithoutData
        && searchString.length === 0
        && !isSearchWithoutResult
        && tableData.length === 0
        && (
          <div
            className='add-player-view-existing-players'
            style={{ height: isTabLine ? '67vh' : '71vh', marginTop: '14vh' }}>
            <div className='add-player-view-existing-players-title'>
              {userConfig ? staticLanguageMap['academyPlayers'][userConfig.language] : ''}
            </div>

            <div className='add-player-view-players-without-data-table'>
              <PlayerSimpleTable
                data={academyPlayers}
                tableType={'addPlayerSimpleViewRoleIcon'}
                maxHeight={isTabLine ? '62vh' : '66vh'}
                positionKey={'addPlayerTableExistingPlayers'}
                draggedPlayer={draggedPlayer}
                setDraggedPlayer={setDraggedPlayer}
              />
            </div>
          </div>
        )}


      {isAddPlayerWithoutData && existingPlayersWithoutData.length > 0 && (
        <div
          className='add-player-view-existing-players'
          style={{ height: isTabLine ? '55vh' : '59vh', marginTop: '27vh' }}>
          <div className='add-player-view-existing-players-title'>
            {userConfig ? staticLanguageMap['existingPlayersWithoutData'][userConfig.language] : ''}
          </div>

          <div className='add-player-view-players-without-data-table'>
            <PlayerSimpleTable
              data={existingPlayersWithoutData}
              tableType={'addPlayerSimpleViewTeamIcon'}
              maxHeight={isTabLine ? '50vh' : '54vh'}
              positionKey={'addPlayerTableExistingPlayers'}
              draggedPlayer={draggedPlayer}
              setDraggedPlayer={setDraggedPlayer}
            />
          </div>
        </div>
      )}

    </div>
  );
};
