import { db } from '../../../firebase';

import {
  collection,
  doc,
  setDoc,
  onSnapshot,
  arrayUnion,
  getDoc,
  updateDoc,
} from 'firebase/firestore';

import { ClubSettings, FlexibleJsonMapping, JsonMapping, Settings } from '../../types';
import { updateTeamsWithNewFormation } from './teamsOrSquads';


// get and set settings for club and
export const getSettings = (setSettings: (settings: Settings) => void, userEmail: string, club: string) => {

  const settingsCollectionRef = collection(db, 'configs', club, 'settings');

  const unsubscribe = onSnapshot(settingsCollectionRef, snapshot => {

    let clubSettings: ClubSettings | undefined;
    let userSettings: FlexibleJsonMapping | undefined;

    snapshot.forEach((doc) => {
      if (doc.id === 'clubSettings') {
        const clubSettingsObject = doc.data();
        clubSettings = {
          clubId: clubSettingsObject.clubId,
          clubName: clubSettingsObject.clubName,
          formation: clubSettingsObject.formation ?? '433',
          countryCode: clubSettingsObject.countryCode ?? 'NO',
          iterations: clubSettingsObject.iterations ?? [],
        };
      }
      else if (doc.id === userEmail) {
        userSettings = doc.data() as FlexibleJsonMapping;
      }
    });

    if (!clubSettings) return;

    const settings: Settings = {
      clubSettings: clubSettings,
      userSettings: userSettings ?? {},
    };

    setSettings(settings);
  });

  return unsubscribe;
};


// update club settings (formation)
export const updateClubSettings = async (newClubSettings: JsonMapping, currentFormation: string, userEmail: string, club: string) => {

  // Update all team configs with new formation if a new formation is set
  if (newClubSettings['formation'] !== currentFormation) {
    await updateTeamsWithNewFormation(newClubSettings['formation'], currentFormation, userEmail, club);
  }

  // Update settings
  const clubSettingsDocRef = doc(db, 'configs', club, 'settings', 'clubSettings');

  try {
    await setDoc(clubSettingsDocRef, {
      formation: newClubSettings['formation']
    }, { merge: true });
  }
  catch (error) {
    console.error(error); // eslint-disable-line no-console
  }
};


// Save a new search
export const saveUserSearch = async (search: FlexibleJsonMapping, userEmail: string, club: string) => {

  const userDocRef = doc(db, 'configs', club, 'settings', userEmail);

  try {
    await setDoc(userDocRef, {
      savedSearches: arrayUnion(search)
    }, { merge: true });
  }
  catch (error) {
    console.error(error); // eslint-disable-line no-console
  }
};


// Delete a saved search
export const deleteSearch = async (searchIndex: number, userEmail: string, club: string) => {

  const userDocRef = doc(db, 'configs', club, 'settings', userEmail);

  const docSnap = await getDoc(userDocRef);

  if (docSnap.exists()) {
    const data = docSnap.data();

    if (Array.isArray(data.savedSearches)) {
      data.savedSearches.splice(searchIndex, 1);

      try {
        await updateDoc(userDocRef, {
          savedSearches: data.savedSearches
        });
      }
      catch (error) {
        console.error(error); // eslint-disable-line no-console
      }
    }
  }
};


// Update teamMenu or ownTeamMenu settings
export const updateTeamOrOwnTeamMenu = async (menu: FlexibleJsonMapping, teamOrSquadId: string, userEmail: string, club: string) => {

  const userDocRef = doc(db, 'configs', club, 'settings', userEmail);

  const menuField = teamOrSquadId === 'ownTeam'
    ? 'ownTeamMenu'
    : teamOrSquadId === 'academyTeam'
      ? 'academyTeamMenu'
      : 'teamMenu';

  try {
    await setDoc(userDocRef, {
      [menuField]: menu
    }, { merge: true });
  }
  catch (error) {
    console.error(error); // eslint-disable-line no-console
  }
};


// Update season stats toggles
export const updateSeasonStatsToggles = async (newSeasonStatsToggles: FlexibleJsonMapping, userEmail: string, club: string) => {

  const userDocRef = doc(db, 'configs', club, 'settings', userEmail);

  try {
    await setDoc(userDocRef, {
      ['seasonStatsToggles']: newSeasonStatsToggles
    }, { merge: true });
  }
  catch (error) {
    console.error(error); // eslint-disable-line no-console
  }
};
