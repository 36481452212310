import '../tables.css';

import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTable, useBlockLayout, useFilters } from 'react-table';
import { useSticky } from 'react-table-sticky';

import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { selectedPlayerState } from '../../../recoil/atoms/selectedPlayerState';
import { FlexibleJsonMapping } from '../../../types';
import { getPlayerColumns } from './playerColumns';
import { useWindowSize } from '../../../../common/hooks/WindowSize';
import { getNavigationWidth } from '../../navigation/Navigation';
import { debounce } from '@mui/material';
import { pagesPerChapter } from '../../../views/scouting/Scouting';
import { getClubSecondaryColor } from '../../../utils/configUtils';


interface PlayerTableProps {
  data: FlexibleJsonMapping[];

  isLoading?: boolean;
  handleSearchButtonPressed?: (isUserInitiated: boolean, isNewSearch: boolean, nextChapter?: number) => Promise<void>;
  currentModuloPage: number;
  currentChapter: number;
  totalHits: number;
  hitsPerPage: number;
  handleChangeCurrentChapter?: (isIncrement: boolean) => void;

  selectedOrderBy?: string | undefined;
  headerBackgroundColor?: string;
  isFindPlayerView?: boolean;
  handlePlayerClick?: (player: FlexibleJsonMapping) => void;
  isSelectedPlayer?: boolean;
}

export const PlayerTable: React.FC<PlayerTableProps> = ({
  data,
  isLoading,
  handleSearchButtonPressed,
  currentModuloPage,
  currentChapter,
  totalHits,
  hitsPerPage,
  handleChangeCurrentChapter,
  selectedOrderBy,
  headerBackgroundColor,
  isFindPlayerView,
  handlePlayerClick,
  isSelectedPlayer,
}) => {

  const userConfig = useRecoilValue(userConfigState);
  const setSelectedPlayer = useSetRecoilState(selectedPlayerState);

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const width = useWindowSize().width;

  const getTableWidth = useCallback((currentWidth: number) => {
    return currentWidth - 24 - getNavigationWidth(userConfig);
  }, [userConfig]);


  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: any[] = useMemo(
    () => getPlayerColumns(
      getTableWidth(width),
      userConfig?.language ?? 'en',
      currentChapter,
      hitsPerPage * pagesPerChapter,
      totalHits,
      handleChangeCurrentChapter,
      isFindPlayerView,
      isSelectedPlayer),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      getTableWidth,
      width,
      userConfig?.language,
      currentChapter,
      hitsPerPage,
      totalHits,
      isFindPlayerView,
      isSelectedPlayer
    ]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useBlockLayout,
    useSticky
  );


  const handleOnPlayerClick = (row: FlexibleJsonMapping) => {
    if (isSelectedPlayer) return;

    if (handlePlayerClick) {
      handlePlayerClick(row.original);
    }
    else {
      setSelectedPlayer({ id: row.original.id, fullname: row.original.fullname });
    }
  };


  const checkScrollPosition = async () => {
    if (!tableContainerRef.current || !handleSearchButtonPressed) return;

    const { scrollTop, scrollHeight, clientHeight } = tableContainerRef.current;
    const scrollPosition = scrollTop / (scrollHeight - clientHeight);
    const threshold = 0.4 + currentModuloPage * 0.1;

    if (scrollPosition > threshold && !isLoading && currentModuloPage < 4 && ((currentChapter * hitsPerPage * pagesPerChapter) + data.length) < totalHits) {
      await handleSearchButtonPressed(false, false);
    }
  };


  useEffect(() => {
    const handleScroll = debounce(() => checkScrollPosition(), 100);

    const tableContainer = tableContainerRef.current;
    if (tableContainer) {
      tableContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (tableContainer) {
        tableContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps


  const clubColor = getClubSecondaryColor(userConfig?.club ?? '');
  document.documentElement.style.setProperty('--club-color', clubColor);
  document.documentElement.style.setProperty('--header-background-color', headerBackgroundColor ?? '#2c303f');


  return (
    <div
      ref={tableContainerRef}
      {...getTableProps()}
      className='player-table'
      style={{ overflowX: getTableWidth(width) < 1030 ? 'auto' : 'hidden' }}
    >

      {headerGroups.map(headerGroup => {
        const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();
        return (
          <div
            key={key}
            {...restHeaderGroupProps}
            className='player-table-header-group'
          >
            {headerGroup.headers.map((column: FlexibleJsonMapping) => {
              const { key: columnKey, ...restColumnProps } = column.getHeaderProps();
              return (
                <div
                  key={columnKey}
                  {...restColumnProps}
                  className={
                    'player-table-header-cell' +
                    ((column.orderByMetrics && column.orderByMetrics.includes(selectedOrderBy)) ? ' player-table-header-cell-sorted' : '')
                  }
                >
                  {column.render('Header')}
                </div>
              );
            })}
          </div>
        );
      })}

      <div
        {...getTableBodyProps()}
        className='player-table-body'>
        {rows.map((row, i) => {
          prepareRow(row);
          const { key, ...restRowProps } = row.getRowProps();
          return (
            <div
              key={key}
              {...restRowProps}
              className={i % 2 === 0 ? ('player-table-body-row-even' + (isSelectedPlayer ? '-hover-disabled' : '')) : 'player-table-body-row-odd'}
              onClick={() => handleOnPlayerClick(row)}
            >
              {row.cells.map(cell => {
                const { key: cellKey, ...restCellProps } = cell.getCellProps();
                return (
                  <div
                    key={cellKey}
                    {...restCellProps}
                    className={'player-table-body-cell' + (i % 2 === 0 ? ' player-table-body-cell-even' : ' player-table-body-cell-odd')}
                  >
                    {cell.render('Cell')}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>

    </div>
  );
};
