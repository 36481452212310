import { selector } from 'recoil';
import { teamsState } from '../atoms/teamsState';
import { PlayerMapping, PlayerEntry } from '../../types';
import { positionOptionsPlatform } from '../../static/propertyValues';


export const playerTeamDataState = selector({
  key: 'playerTeamData',
  get: ({ get }) => {
    const teams = get(teamsState);

    const playerTeamData: PlayerMapping = {};

    if (teams) {
      positionOptionsPlatform.forEach(positionKey => {
        Object.keys(teams).forEach(teamId => {

          const teamData = teams[teamId];
          if (positionKey in teamData) {

            const positionArray = teamData[positionKey];
            positionArray.forEach((player: PlayerEntry) => {

              playerTeamData[player.id] = {
                currentTeam: teamId,
                currentPosition: positionKey,
              };
            });
          }
        });
      });
    }

    return playerTeamData;
  },
});
