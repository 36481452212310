import axios from 'axios';

import { FirebaseUser } from '../../../firebase';
import { ClubMapping } from '../../types';

import { BASE_URL } from './constants';
import { getBearerToken } from './token';


export const getClubs = async (clubIds: number[], user: FirebaseUser): Promise<ClubMapping | undefined> => {

  clubIds = clubIds.filter((clubId) => !isNaN(Number(clubId))).map((clubId) => Number(clubId));

  if (clubIds.length === 0) return undefined;

  const token = await getBearerToken(user);
  const clubIdsString = clubIds.join(',');

  const result = await axios.get(`${BASE_URL}/clubs/${clubIdsString}`, {
    headers: { Authorization: token },
  });

  if (result === undefined) {
    return undefined;
  }

  return result.data;
};


export const searchClubs = async (
  user: FirebaseUser,
  options: {
    name?: string;
    competitions?: number[];
    countryCodes?: string[];
    page?: number;
  }
): Promise<ClubMapping[] | undefined> => {

  const token = await getBearerToken(user);

  const {
    name,
    competitions,
    countryCodes,
    page = 1,
  } = options;

  const competitionsString = competitions ? competitions.join(',') : undefined;
  const countryCodesString = countryCodes ? countryCodes.join(',') : undefined;

  const result = await axios.get(`${BASE_URL}/search/clubs/`, {
    headers: { Authorization: token },
    params: {
      name,
      competitions: competitionsString,
      country_codes: countryCodesString,
      page: page,
    },
  });

  if (!result) return undefined;

  return result.data;
};
