import { useCallback, useEffect, useState } from 'react';

import { FlexibleJsonMapping } from '../../types';
import { metricToDisplayName, outfieldCompareRadarMetrics, positionToRadarMetrics } from '../../static/playerMetrics';


interface CompareRadarPlotProps {
  playerDetailsArray: FlexibleJsonMapping[];
  selectedClubIterationIndex: number | undefined;
  selectedPositionKey: string | undefined;
  clubColor: string;

  otherPlayerDetailsArray: FlexibleJsonMapping[];
  otherSelectedClubIterationIndex: number | undefined;
  otherSelectedPositionKey: string | undefined;
  clubContrastColor: string;

  isGoalkeeper: boolean;
  language: string;
}


// xPoint, yPoint, metric, xText, yText, textAnchor
type PlayerRadarPoint = [number, number, string, number, number, string];


export const CompareRadarPlot: React.FC<CompareRadarPlotProps> = ({
  playerDetailsArray,
  selectedClubIterationIndex,
  selectedPositionKey,
  clubColor,

  otherPlayerDetailsArray,
  otherSelectedClubIterationIndex,
  otherSelectedPositionKey,
  clubContrastColor,

  isGoalkeeper,
  language
}) => {


  const [radarPoints, setRadarPoints] = useState<PlayerRadarPoint[]>([]);
  const [radarPath, setRadarPath] = useState<string>('');

  const [otherRadarPoints, setOtherRadarPoints] = useState<PlayerRadarPoint[]>([]);
  const [otherRadarPath, setOtherRadarPath] = useState<string>('');


  const createPathData = (points: PlayerRadarPoint[]) => {

    // need at least three points for a radar plot
    if (points.length < 3) {
      return '';
    }

    let d = `M ${points[0][0]},${points[0][1]}`;

    function catmullRomSegment(p0: PlayerRadarPoint, p1: PlayerRadarPoint, p2: PlayerRadarPoint, p3: PlayerRadarPoint, tension = 1): string {
      const cp1x = p1[0] + (p2[0] - p0[0]) / 6 * tension;
      const cp1y = p1[1] + (p2[1] - p0[1]) / 6 * tension;
      const cp2x = p2[0] - (p3[0] - p1[0]) / 6 * tension;
      const cp2y = p2[1] - (p3[1] - p1[1]) / 6 * tension;
      return `C ${cp1x},${cp1y} ${cp2x},${cp2y} ${p2[0]},${p2[1]}`;
    }

    for (let i = 0; i < points.length; i++) {
      const p0 = points[i === 0 ? points.length - 1 : i - 1];
      const p1 = points[i];
      const p2 = points[i + 1 === points.length ? 0 : i + 1];
      const p3 = points[i + 2 >= points.length ? (i + 2) % points.length : i + 2];
      d += catmullRomSegment(p0, p1, p2, p3, 0.3);
    }

    d += ' Z';

    return d;
  };


  const calculatePointCoordinates = (index: number, value: number, totalPoints: number): [number, number] => {
    const angleDeg = index * (360 / totalPoints);
    const angleRad = angleDeg * (Math.PI / 180);

    const radius = 26 * value;

    const xOffset = radius * Math.cos(angleRad);
    const yOffset = radius * Math.sin(angleRad);

    return [xOffset + 500, yOffset + 420];
  };


  const calculateTextCoordinatesAndAnchor = useCallback((index: number, totalPoints: number): [number, number, string] => {
    const angleDeg = index * (360 / totalPoints);
    const angleRad = angleDeg * (Math.PI / 180);

    const radius = 295;

    let xOffset = radius * Math.cos(angleRad);
    let yOffset = radius * Math.sin(angleRad);
    let textAnchor = 'middle';

    if (isGoalkeeper) {
      if ([0, 1, 7].includes(index)) {
        textAnchor = 'start';
      }
      else if ([3, 4, 5].includes(index)) {
        textAnchor = 'end';
      }

      // text positioning
      yOffset += 8;
      if (index === 1 || index === 7) {
        xOffset -= 4;
      }
      else if (index === 3 || index === 5) {
        xOffset += 4;
      }
      else if (index === 2) {
        yOffset += 14;
      }
      else if (index === 6) {
        yOffset -= 4;
      }
    }
    else {
      if ([0, 1, 2, 10, 11].includes(index)) {
        textAnchor = 'start';
      }
      else if ([4, 5, 6, 7, 8].includes(index)) {
        textAnchor = 'end';
      }

      // text positioning
      yOffset += 8;
      if (index === 2 || index === 4) {
        yOffset += 4;
      }
      else if (index === 3) {
        yOffset += 14;
      }
      else if (index === 8 || index === 10) {
        yOffset -= 4;
      }
      else if (index === 9) {
        yOffset -= 14;
      }
    }

    return [xOffset + 500, yOffset + 420, textAnchor];
  }, [isGoalkeeper]);


  const setPointsAndPath = useCallback((
    detailsArray: FlexibleJsonMapping[],
    clubIterationIndex: number,
    positionKey: string,
    setPoints: (points: PlayerRadarPoint[]) => void,
    setPath: (path: string) => void,
  ) => {

    const primaryPositionOfIteration = detailsArray[clubIterationIndex]['primary_position'];

    // if a has only played the selected position for a given iteration, the positionKey for this iteration will be 'overall'
    const positionKeyTodata = detailsArray[clubIterationIndex][positionKey]
      ? positionKey
      : (positionKey === primaryPositionOfIteration ? 'overall' : positionKey);

    if (detailsArray[clubIterationIndex][positionKeyTodata]) {
      const metrics = isGoalkeeper ? positionToRadarMetrics['GK'] : outfieldCompareRadarMetrics;

      const points: PlayerRadarPoint[] = [];

      metrics.forEach((metric, index) => {

        const metricValue = detailsArray[clubIterationIndex][positionKeyTodata][metric + '_normalized'] ?? 0;

        const [xPoint, yPoint] = calculatePointCoordinates(index, metricValue, metrics.length);

        const [xText, yText, textAnchor] = calculateTextCoordinatesAndAnchor(index, metrics.length);

        points.push([
          xPoint,
          yPoint,
          metric,
          xText,
          yText,
          textAnchor,
        ]);
      });

      const path = createPathData(points);
      setPoints(points);
      setPath(path);
    }
    else {
      setPoints([]);
      setPath('');
    }
  }, [calculateTextCoordinatesAndAnchor, isGoalkeeper]);


  useEffect(() => {
    if (playerDetailsArray.length > 0 && selectedClubIterationIndex !== undefined && selectedPositionKey !== undefined) {
      setPointsAndPath(playerDetailsArray, selectedClubIterationIndex, selectedPositionKey, setRadarPoints, setRadarPath);
    }
    else {
      setRadarPoints([]);
      setRadarPath('');
    }
  }, [selectedPositionKey, selectedClubIterationIndex, isGoalkeeper, playerDetailsArray, setPointsAndPath]);


  useEffect(() => {
    if (otherPlayerDetailsArray.length > 0 && otherSelectedClubIterationIndex !== undefined && otherSelectedPositionKey !== undefined) {
      setPointsAndPath(otherPlayerDetailsArray, otherSelectedClubIterationIndex, otherSelectedPositionKey, setOtherRadarPoints, setOtherRadarPath);
    }
    else {
      setOtherRadarPoints([]);
      setOtherRadarPath('');
    }
  }, [otherSelectedPositionKey, otherSelectedClubIterationIndex, isGoalkeeper, otherPlayerDetailsArray, setPointsAndPath]);


  return (
    <svg className='player-view-svg-plot' viewBox={'0 0 1000 840'} preserveAspectRatio={'xMidYMid meet'}>

      {/* <rect width='1000' height='840' style={{ fill: 'none', stroke: '#ffffff88', strokeWidth: 4 }} /> */}

      {/* lines */}
      {isGoalkeeper && (
        <g>
          <line x1="230" y1="420" x2="770" y2="420" style={{ stroke: '#ffffff55', strokeWidth: 1 }} />
          <line x1="500" y1="150" x2="500" y2="690" style={{ stroke: '#ffffff55', strokeWidth: 1 }} />
          <line x1="309.08" y1="229.08" x2="690.92" y2="610.92" style={{ stroke: '#ffffff55', strokeWidth: 1 }} />
          <line x1="690.92" y1="229.08" x2="309.08" y2="610.92" style={{ stroke: '#ffffff55', strokeWidth: 1 }} />
        </g>
      )}

      {!isGoalkeeper && (
        <g>
          <line x1="230" y1="420" x2="770" y2="420" style={{ stroke: '#ffffff55', strokeWidth: 1 }} />
          <line x1="500" y1="150" x2="500" y2="690" style={{ stroke: '#ffffff55', strokeWidth: 1 }} />
          <line x1="266.17" y1="285" x2="733.83" y2="555" style={{ stroke: '#ffffff55', strokeWidth: 1 }} />
          <line x1="365" y1="186.17" x2="635" y2="653.83" style={{ stroke: '#ffffff55', strokeWidth: 1 }} />
          <line x1="635" y1="186.17" x2="365" y2="653.83" style={{ stroke: '#ffffff55', strokeWidth: 1 }} />
          <line x1="733.83" y1="285" x2="266.17" y2="555" style={{ stroke: '#ffffff55', strokeWidth: 1 }} />
        </g>
      )}

      {/* circles */}
      <circle cx='500' cy='420' r='260' style={{ fill: '#8c90a100', stroke: '#ffffff66', strokeWidth: 1 }} />
      <circle cx='500' cy='420' r='195' style={{ fill: '#8c90a104', stroke: '#ffffff44' }} />
      <circle cx='500' cy='420' r='130' style={{ fill: '#8c90a103', stroke: '#ffffff33', strokeWidth: 1 }} />
      <circle cx='500' cy='420' r='65' style={{ fill: '#8c90a102', stroke: '#ffffff22', strokeWidth: 1 }} />

      {/* points */}
      {radarPoints.map(([xPoint, yPoint, metric, xText, yText, textAnchor]) => (
        <g key={metric}>
          <circle cx={xPoint} cy={yPoint} r={5} style={{ fill: clubColor, stroke: 'none' }} />
          <text x={xText} y={yText} textAnchor={textAnchor} fill='#ffffffbb' fontSize={22}>
            {metricToDisplayName[metric][language]}
            <title>{metricToDisplayName[metric]['titles'][language]}</title>
          </text>
        </g>
      ))}
      {otherRadarPoints.map(([xPoint, yPoint, metric]) => (
        <g key={metric}>
          <circle cx={xPoint} cy={yPoint} r={5} style={{ fill: clubContrastColor, stroke: 'none' }} />
        </g>
      ))}

      {/* radar paths */}
      <path d={otherRadarPath} style={{ fill: clubContrastColor + '27', stroke: clubContrastColor, strokeWidth: 3 }} />
      <path d={radarPath} style={{ fill: clubColor + '27', stroke: clubColor, strokeWidth: 3 }} />

    </svg>
  );
};
