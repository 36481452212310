import { FlexibleJsonMapping, StringToStringArrayMapping } from '../types';


const commonOutfieldRadarMetrics: string[] = [
  'dribble_obv_per_90',
  'carry_obv_prog_per_90',
  'smart_pass_obv_per_90',
];


const centreBackRadarMetrics: string[] = [
  'np_xg_per_90',
  'n_aerial_duels_won_per_90',
  'n_interceptions_padj_per_90',
  'n_defensive_actions_padj_per_90',
  ...commonOutfieldRadarMetrics,
  'n_passes_per_90',
];


const fullBackRadarMetrics: string[] = [
  'n_fouls_won_per_90',
  'n_interceptions_padj_per_90',
  // 'n_defending_ground_duels_won_padj_per_90',
  'n_defensive_actions_padj_per_90',
  'defending_obv_per_90',
  ...commonOutfieldRadarMetrics,
  'np_xgi_per_90',
];


const defensiveMidfielderRadarMetrics: string[] = [
  // 'n_dribble_turnovers_per_90',
  'n_aerial_duels_won_per_90',
  'n_interceptions_padj_per_90',
  // 'n_defending_ground_duels_won_padj_per_90',
  'n_defensive_actions_padj_per_90',
  'defending_obv_per_90',
  ...commonOutfieldRadarMetrics,
  // 'open_play_xa_per_90',
  'n_passes_per_90',

];


const midfielderRadarMetrics: string[] = [
  'np_xgi_per_90',
  'n_fouls_won_per_90',
  'n_interceptions_padj_per_90',
  'n_defensive_actions_padj_per_90',
  ...commonOutfieldRadarMetrics,
  // 'open_play_xa_per_90',
  'n_passes_per_90',
];


const wingerRadarMetrics: string[] = [
  'np_xg_per_90',
  'n_shots_per_90', // TODO: replace with 'Touches in box'
  'n_fouls_won_per_90',
  'n_defensive_actions_padj_per_90',
  ...commonOutfieldRadarMetrics,
  'open_play_xa_per_90',
];


const strikerRadarMetrics: string[] = [
  'np_xg_per_90',
  'n_shots_per_90',
  'n_aerial_duels_won_per_90',
  'n_defensive_actions_padj_per_90',
  ...commonOutfieldRadarMetrics,
  'open_play_xa_per_90',
];


const goalkeeperRadarMetrics: string[] = [
  'successful_long_passes_percentage',
  'gk_saves_percentage',
  'n_gk_defensive_actions_outside_box_per_90',
  'gk_postshot_xg_prevented_difference_per_shot',
  'smart_pass_obv_per_90',
  'n_passes_per_90',
  'successful_pass_percentage',
  'n_long_passes_per_90',
];


export const positionToRadarMetrics: StringToStringArrayMapping = {
  'GK': goalkeeperRadarMetrics,
  'LB': fullBackRadarMetrics,
  'RB': fullBackRadarMetrics,
  'CB': centreBackRadarMetrics,
  'CDM': defensiveMidfielderRadarMetrics,
  'CM': midfielderRadarMetrics,
  'CAM': wingerRadarMetrics,
  'LW': wingerRadarMetrics,
  'RW': wingerRadarMetrics,
  'S': strikerRadarMetrics,
};


export const outfieldCompareRadarMetrics: string[] = [
  // 'n_receptions_in_box_per_90', //TODO: replace
  'np_xg_per_90',
  'n_dribble_turnovers_per_90',
  'n_aerial_duels_won_per_90',
  'n_interceptions_padj_per_90',
  'n_defensive_actions_padj_per_90',
  'defending_obv_per_90',
  'dribble_obv_per_90',
  'carry_obv_prog_per_90',
  'smart_pass_obv_per_90',
  'n_passes_per_90',
  'successful_pass_percentage',
  'open_play_xa_per_90',
];


export const outfieldSeasonTableMetricGroups: StringToStringArrayMapping = {

  'Ratings': [
    'skill_rating',
    'club_rating',
  ],

  'OBV': [
    'smart_pass_obv_per_90',
    'dribble_obv_per_90',
    'carry_obv_prog_per_90',
    'defending_obv_per_90',
    // 'np_postshot_xg_difference_per_90', Todo: create new 'SOTA' shot_obv

  ],

  'Attacking': [
    'n_goals_per_90',
    'n_np_goals_per_90',
    'np_xg_per_90',
    'n_shots_per_90',
    'np_postshot_xg_difference_per_90',
    // 'n_receptions_in_box_per_90', Todo: create new with all touches in box
    'n_assists_per_90',
    // 'n_open_play_assists_per_90', //Todo: create new
    'xa_per_90',
    'open_play_xa_per_90',
  ],
  'Possession': [
    'n_passes_per_90',
    'successful_pass_percentage',
    'n_long_passes_per_90',
    'successful_long_passes_percentage',
    // 'n_pass_turnovers_per_90',
    'n_fouls_won_per_90',
    'n_dribble_turnovers_per_90',
    'n_successful_receptions_under_pressure_own_half_per_90',
    'successful_receptions_under_pressure_own_half_percentage',
  ],

  'Defending': [
    'n_defensive_actions_padj_per_90',
    'n_presses_padj_per_90',
    'n_interceptions_padj_per_90',
    'n_clearances_padj_per_90',
    'n_blocks_padj_per_90',
    'n_defending_ground_duels_won_padj_per_90',
    'n_defending_ground_duels_won_in_own_box_padj_per_90',
    'n_fouls_per_90',
  ],

  'Aerial': [
    'n_aerial_duels_won_per_90',
    'aerial_duels_won_percentage',
    'n_aerial_duels_won_in_own_box_per_90',
    'aerial_duels_won_in_own_box_percentage',
  ],
};


export const skillcornerStats = [
  'skillcorner_psv-99_average',
  'skillcorner_distance_per_90',
  'skillcorner_distance_tip_per_90',
  'skillcorner_distance_otip_per_90',
  'skillcorner_sprinting_distance_per_90',
  'skillcorner_sprinting_distance_tip_per_90',
  'skillcorner_sprinting_distance_otip_per_90',
  'skillcorner_count_sprint_per_90',
  'skillcorner_count_sprint_tip_per_90',
  'skillcorner_count_sprint_otip_per_90',
  'skillcorner_hsr_distance_per_90',
  'skillcorner_hsr_distance_tip_per_90',
  'skillcorner_hsr_distance_otip_per_90',
];


export const outfieldSeasonTableMetrics = Object.values(outfieldSeasonTableMetricGroups).reduce((acc, current) => {
  return acc.concat(current);
}, []);


export const goalkeeperSeasonTableMetricGroups: StringToStringArrayMapping = {

  'Ratings': [
    'skill_rating',
    'club_rating',
  ],

  'OBV': [
    'smart_pass_obv_per_90',
  ],

  'Shot stopping': [
    'gk_postshot_xg_prevented_difference_per_shot',
    'n_gk_saves_per_90',
    'gk_saves_percentage',
    'n_gk_goals_conceded_per_90',
    'gk_penalty_saves_percentage',
  ],

  'Distribution': [
    'n_passes_per_90',
    'successful_pass_percentage',
    'n_long_passes_per_90',
    'successful_long_passes_percentage',
    // 'n_pass_turnovers_per_90',
  ],

  'Aggressiveness': [
    'n_gk_defensive_actions_outside_box_per_90',
    'average_defensive_actions_distance_from_own_goal',
    // 'n_gk_catch_per_90', #TODO: Endre til "høye baller fanget?"
  ],
};


export const commonSeasonTableMetrics = [
  'skill_rating',
  'club_rating',
  'smart_pass_obv_per_90',
];


export const getNormalizedMetricName = (rating: string) => {
  if (rating === 'club_rating' || rating === 'skill_rating') return rating;

  if (rating.endsWith('_normalized')) return rating;

  return rating + '_normalized';
};


export const metricToDisplayName: FlexibleJsonMapping = {

  // ratings
  'skill_rating': {
    'en': 'Skill rating',
    'no': 'Skillrating',
    'titles': {
      'en': 'Performance rating compared to players in the same position',
      'no': 'Ferdighetsrating sammenlignet med spillere i samme posisjon',
    }
  },
  'club_rating': {
    'en': 'Club rating',
    'no': 'Klubbrating',
    'titles': {
      'en': 'Performance rating tailored to the clubs target profiles compared to players in the same position',
      'no': 'Ferdighetsrating tilpasset klubbens spillerroller sammenlignet med spillere i samme posisjon',
    }
  },


  // obv
  'total_open_play_obv_per_90': {
    'en': 'Total OBV',
    'no': 'Total OBV',
    'titles': {
      'en': 'On-ball-value of all actions (per 90)',
      'no': 'On-ball-value for alle handlinger (per 90)',
    }
  },
  'smart_pass_obv_per_90': {
    'en': 'Bypass OBV',
    'no': 'Bypass OBV',
    'titles': {
      'en': 'On-ball-value of passes surpassing opponents (per 90)',
      'no': 'On-ball-value for pasninger forbi motstandere (per 90)',
    }
  },
  'dribble_obv_per_90': {
    'en': 'Dribble OBV',
    'no': 'Dribble OBV',
    'titles': {
      'en': 'On-ball-value of dribbles (per 90)',
      'no': 'On-ball-value for driblinger forbi motstandere (per 90)',
    }
  },
  'carry_obv_prog_per_90': {
    'en': 'Carry OBV',
    'no': 'Carry OBV',
    'titles': {
      'en': 'On-ball-value of progressive carries (per 90)',
      'no': 'On-ball-value for positive ballføringer (per 90)',
    }
  },
  'ground_duel_obv_per_90': {
    'en': 'Ground duel OBV',
    'no': 'Ground duel OBV',
    'titles': {
      'en': 'On-ball-value of ground duels (per 90)',
      'no': 'On-ball-value for dueller på bakke (per 90)',
    }
  },
  'aerial_duel_obv_per_90': {
    'en': 'Aerial duel OBV',
    'no': 'Aerial duel OBV',
    'titles': {
      'en': 'On-ball-value of aerial duels (per 90)',
      'no': 'On-ball-value for hodedueller (per 90)',
    }
  },
  'defending_obv_per_90': {
    'en': 'Defending OBV',
    'no': 'Defending OBV',
    'titles': {
      'en': 'On-ball-value of defensive actions (per 90)',
      'no': 'On-ball-value for defensive handlinger (per 90)',
    }
  },
  'cross_obv_per_90': {
    'en': 'Cross OBV',
    'no': 'Cross OBV',
    'titles': {
      'en': 'On-ball-value of crosses (per 90)',
      'no': 'On-ball-value for innlegg (per 90)',
    }
  },
  'receival_obv_per_90': {
    'en': 'Receival OBV',
    'no': 'Receival OBV',
    'titles': {
      'en': 'On-ball-value of receptions (per 90)',
      'no': 'On-ball-value for mottak (per 90)',
    }
  },
  'defending_obv_padj_per_90': {
    'en': 'Defending OBV',
    'no': 'Defending OBV',
    'titles': {
      'en': 'On-ball-value of defensive actions adjusted for possession (per 90)',
      'no': 'On-ball-value for defensive handlinger justert for ballinnehav (per 90)',
    }
  },
  'interception_obv_per_90': {
    'en': 'Interception OBV',
    'no': 'Interception OBV',
    'titles': {
      'en': 'On-ball-value of interceptions (per 90)',
      'no': 'On-ball-value for pasningsbrudd (per 90)',
    }
  },
  'interception_obv_padj_per_90': {
    'en': 'Interception OBV',
    'no': 'Interception OBV',
    'titles': {
      'en': 'On-ball-value of interceptions adjusted for possession (per 90)',
      'no': 'On-ball-value for pasningsbrudd justert for ballinnehav (per 90)',
    }
  },
  'clearance_obv_per_90': {
    'en': 'Clearance OBV',
    'no': 'Clearance OBV',
    'titles': {
      'en': 'On-ball-value of clearances (per 90)',
      'no': 'On-ball-value for klareringer (per 90)',
    }
  },
  'clearance_obv_padj_per_90': {
    'en': 'Clearance OBV',
    'no': 'Clearance OBV',
    'titles': {
      'en': 'On-ball-value of clearances adjusted for possession (per 90)',
      'no': 'On-ball-value for klareringer justert for ballinnehav (per 90)',
    }
  },
  'block_obv_per_90': {
    'en': 'Block OBV',
    'no': 'Block OBV',
    'titles': {
      'en': 'On-ball-value of blocks (per 90)',
      'no': 'On-ball-value for blokkeringer (per 90)',
    }
  },
  'block_obv_padj_per_90': {
    'en': 'Block OBV',
    'no': 'Block OBV',
    'titles': {
      'en': 'On-ball-value of blocks adjusted for possession (per 90)',
      'no': 'On-ball-value for blokkeringer justert for ballinnehav (per 90)',
    }
  },


  // possession
  'n_passes_per_90': {
    'en': 'Passes',
    'no': 'Passes',
    'titles': {
      'en': 'Number of passes (per 90)',
      'no': 'Antall pasninger (per 90)',
    }
  },
  'successful_pass_percentage': {
    'en': 'Pass %',
    'no': 'Pass %',
    'titles': {
      'en': 'Success rate of passes',
      'no': 'Treffprosent for pasninger',
    }
  },
  'n_long_passes_per_90': {
    'en': 'Long passes',
    'no': 'Long passes',
    'titles': {
      'en': 'Number of passes over 34 meters (per 90)',
      'no': 'Antall pasninger over 34 meter (per 90)',
    }
  },
  'successful_long_passes_percentage': {
    'en': 'Long pass %',
    'no': 'Long pass %',
    'titles': {
      'en': 'Success rate of long passes',
      'no': 'Treffprosent for langpasninger',
    }
  },
  'n_bypassed_opponents_per_90': {
    'en': 'Bypassed opponents',
    'no': 'Bypassed opponents',
    'titles': {
      'en': 'Number of dribbles/passes surpassing opponents (per 90)',
      'no': 'Antall driblinger/pasninger forbi motstandere (per 90)',
    }
  },
  'n_smart_passes_per_90': {
    'en': 'Bypasses',
    'no': 'Bypasses',
    'titles': {
      'en': 'Number of passes surpassing opponents (per 90)',
      'no': 'Antall pasninger forbi motstandere (per 90)',
    }
  },
  'n_successful_passes_per_90': {
    'en': 'Passes completed',
    'no': 'Passes completed',
    'titles': {
      'en': 'Number of successful passes (per 90)',
      'no': 'Antall vellykkede pasninger (per 90)',
    }
  },
  'n_successful_long_passes_per_90': {
    'en': 'Long passes completed',
    'no': 'Long passes completed',
    'titles': {
      'en': 'Number of successful long passes (per 90)',
      'no': 'Antall vellykkede langpasninger (per 90)',
    }
  },
  'n_deep_progressions_per_90': {
    'en': 'Deep progressions',
    'no': 'Deep progressions',
    'titles': {
      'en': 'Number of carries/passes into the final third (per 90)',
      'no': 'Antall føringer/pasninger inn i siste tredjedel (per 90)',
    }
  },
  'n_dribbles_per_90': {
    'en': 'Dribbles',
    'no': 'Dribbles',
    'titles': {
      'en': 'Number of dribbles surpassing opponents (per 90)',
      'no': 'Antall driblinger forbi motstandere (per 90)',
    }
  },
  'n_hold_up_plays_per_90': {
    'en': 'Hold-up plays',
    'no': 'Hold-up plays',
    'titles': {
      'en': 'Number of hold-up plays (per 90)',
      'no': 'Antall hold-up plays (per 90)',
    }
  },
  'successful_hold_up_plays_percentage': {
    'en': 'Hold-up plays %',
    'no': 'Hold-up plays %',
    'titles': {
      'en': 'Success rate of hold-up plays',
      'no': 'Suksess-rate for hold-up plays',
    }
  },

  'n_fouls_won_per_90': {
    'en': 'Fouls won',
    'no': 'Fouls won',
    'titles': {
      'en': 'Number of fouls won (per 90)',
      'no': 'Antall frispark vunnet (per 90)',
    }
  },

  'n_successful_receptions_under_pressure_own_half_per_90': {
    'en': 'Retentions OH',
    'no': 'Retentions OH',
    'titles': {
      'en': 'Successful actions under pressure in own half (per 90)',
      'no': 'Antall vellykkede handlinger under press på egen halvdel (per 90)',
    }
  },

  'successful_receptions_under_pressure_own_half_percentage': {
    'en': 'Retentions OH %',
    'no': 'Retentions OH %',
    'titles': {
      'en': 'Success rate of receptions in own half under pressure',
      'no': 'Suksess-rate for handlinger under press på egen halvdel',
    }
  },

  'n_pass_turnovers_per_90': {
    'en': 'Turnovers',
    'no': 'Turnovers',
    'titles': {
      'en': 'Number of passes failed (per 90)',
      'no': 'Antall feilpasninger (per 90)',
    }
  },

  'n_dribble_turnovers_per_90': {
    'en': 'Dispossessions',
    'no': 'Dispossessions',
    'titles': {
      'en': 'Number of lost balls, either by being tackled or miscontrol (per 90)',
      'no': 'Antall balltap, enten ved å bli taklet eller miste kontroll på ballen (per 90)',
    }
  },


  // attacking
  'n_goals_per_90': {
    'en': 'Goals',
    'no': 'Goals',
    'titles': {
      'en': 'Number of goals (per 90)',
      'no': 'Antall mål (per 90)',
    }
  },
  'n_np_goals_per_90': {
    'en': 'NP goals',
    'no': 'NP goals',
    'titles': {
      'en': 'Number of non-penalty goals (per 90)',
      'no': 'Antall mål uten straffer (per 90)',
    }
  },
  'xg_per_90': {
    'en': 'xG',
    'no': 'xG',
    'titles': {
      'en': 'Expected goals (per 90)',
      'no': 'Forventede mål (per 90)',
    }
  },
  'np_xg_per_90': {
    'en': 'NP xG',
    'no': 'NP xG',
    'titles': {
      'en': 'Non-penalty expected goals (per 90)',
      'no': 'Forventede mål uten straffer (per 90)',
    }
  },
  'n_shots_per_90': {
    'en': 'Shots',
    'no': 'Shots',
    'titles': {
      'en': 'Number of shots (per 90)',
      'no': 'Antall skudd (per 90)',
    }
  },
  'n_assists_per_90': {
    'en': 'Assists',
    'no': 'Assists',
    'titles': {
      'en': 'Number of assists (per 90)',
      'no': 'Antall assists (per 90)',
    }
  },
  'n_open_play_assists_per_90': {
    'en': 'OP assists',
    'no': 'OP assists',
    'titles': {
      'en': 'Number of assists from open play',
      'no': 'Antall assists fra åpent spill',
    }
  },
  'xa_per_90': {
    'en': 'xA',
    'no': 'xA',
    'titles': {
      'en': 'Expected assists (per 90)',
      'no': 'Forventede assists (per 90)',
    }
  },
  'open_play_xa_per_90': {
    'en': 'OP xA',
    'no': 'OP xA',
    'titles': {
      'en': 'Expected assists from open play (per 90)',
      'no': 'Forventede assists fra åpent spill (per 90)',
    }
  },
  'np_xgi_per_90': {
    'en': 'NP xGI',
    'no': 'NP xGI',
    'titles': {
      'en': 'Non-penalty expected goal involvements (per 90)',
      'no': 'Forventede målinvolveringer uten straffer (per 90)',
    }
  },
  'n_receptions_in_box_per_90': {
    'en': 'Receptions IB',
    'no': 'Receptions IB',
    'titles': {
      'en': "Number of receptions in opponent's box (per 90)",
      'no': 'Antall mottak i motstanders boks (per 90)',
    }
  },
  'np_postshot_xg_difference_per_90': {
    'en': 'SGA',
    'no': 'SGA',
    'titles': {
      'en': 'Shooting Goals Added (SGA) = Expected goals on target added above expected (per 90)',
      'no': 'Shooting Goals Added (SGA) = Differansen mellom XGOT og xG, altså hvor mye bedre skyter spillerne enn forventet (per 90)',
    }
  },
  'np_postshot_xg_difference_sota_per_90': {
    'en': 'Shot',
    'no': 'SGA SOTA',
    'titles': {
      'en': 'Shooting Goals Added (SGA) = Expected goals on target added above expected (per 90)',
      'no': 'Shooting Goals Added (SGA) = Differansen mellom XGOT og xG, altså hvor mye bedre skyter spillerne enn forventet (per 90)',
    }
  },


  // defensive
  'n_presses_padj_per_90': {
    'en': 'Presses',
    'no': 'Presses',
    'titles': {
      'en': 'Number of possession-adjusted pressing attempts (per 90)',
      'no': 'Antall pressforsøk justert for ballinnehav (per 90)',
    }
  },
  'n_ground_duels_won_per_90': {
    'en': 'Ground duel wins',
    'no': 'Ground duel wins',
    'titles': {
      'en': 'Number of ground duels won (per 90)',
      'no': 'Antall dueller på bakken vunnet (per 90)',
    }
  },
  'n_defensive_actions_padj_per_90': {
    'en': 'Defensive actions',
    'no': 'Defensive actions',
    'titles': {
      'en': 'Number of possession-adjusted defensive actions (per 90)',
      'no': 'Antall defensive handlinger justert for ballinnehav (per 90)',
    }
  },

  // defending ground duel is the same as ground duel won in code
  'n_defending_ground_duels_won_padj_per_90': {
    'en': 'Tackles',
    'no': 'Tackles',
    'titles': {
      'en': 'Number of possession-adjusted successful tackles (per 90)',
      'no': 'Antall vellykkede taklinger justert for ballinnehav (per 90)',
    }
  },
  'n_defending_ground_duels_won_in_own_box_padj_per_90': {
    'en': 'Tackles OB',
    'no': 'Tackles OB',
    'titles': {
      'en': 'Number of successful tackles in own box (per 90)',
      'no': 'Antall vellykkede taklinger i egen boks justert for ballinnehav (per 90)',
    }
  },
  'n_interceptions_padj_per_90': {
    'en': 'Interceptions',
    'no': 'Interceptions',
    'titles': {
      'en': 'Number of possession-adjusted interceptions (per 90)',
      'no': 'Antall pasningsbrudd justert for ballinnehav (per 90)',
    }
  },
  'n_clearances_padj_per_90': {
    'en': 'Clearances',
    'no': 'Clearances',
    'titles': {
      'en': 'Number of clearances under pressure adjusted for possession (per 90)',
      'no': 'Antall klareringer under press justert for ballinnehav (per 90)',
    }
  },
  'n_blocks_padj_per_90': {
    'en': 'Blocks',
    'no': 'Blocks',
    'titles': {
      'en': 'Number of blocks adjusted for possession (per 90)',
      'no': 'Antall blokkeringer justert for ballinnehav (per 90)',
    }
  },
  'n_fouls_per_90': {
    'en': 'Fouls',
    'no': 'Fouls',
    'titles': {
      'en': 'Number of fouls committed (per 90)',
      'no': 'Antall frispark laget (per 90)',
    }
  },


  // aerial
  'n_aerial_duels_per_90': {
    'en': 'Aerial duels',
    'no': 'Aerial duels',
    'titles': {
      'en': 'Number of aerial duels (per 90)',
      'no': 'Antall hodedueller (per 90)',
    }
  },
  'n_aerial_duels_won_per_90': {
    'en': 'Aerial wins',
    'no': 'Aerial wins',
    'titles': {
      'en': 'Number of aerial duels won (per 90)',
      'no': 'Antall hodedueller vunnet (per 90)',
    }
  },
  'aerial_duels_won_percentage': {
    'en': 'Aerial win %',
    'no': 'Aerial win %',
    'titles': {
      'en': 'Success rate of aerial duels',
      'no': 'Suksess-rate for hodedueller',
    }
  },
  'n_aerial_duels_in_own_box_per_90': {
    'en': 'Aerial duels OB',
    'no': 'Aerial duels OB',
    'titles': {
      'en': 'Number of aerial duels in own box (per 90)',
      'no': 'Antall hodedueller i egen boks (per 90)',
    }
  },
  'n_aerial_duels_won_in_own_box_per_90': {
    'en': 'Aerial wins OB',
    'no': 'Aerial wins OB',
    'titles': {
      'en': 'Number of aerial duels won in own box (per 90)',
      'no': 'Antall hodedueller vunnet i egen boks (per 90)',
    }
  },
  'aerial_duels_won_in_own_box_percentage': {
    'en': 'Aerial win % OB',
    'no': 'Aerial win % OB',
    'titles': {
      'en': 'Success rate of aerial duels in own box',
      'no': 'Suksess-rate for hodedueller i egen boks',
    }
  },


  // skillcorner
  'skillcorner_psv-99_average': {
    'en': 'Pace',
    'no': 'Pace',
    'titles': {
      'en': 'Peak sprint velocity (PSV-99) = Maximum speed',
      'no': 'Peak sprint velocity (PSV-99) = Toppfart',
    }
  },
  'skillcorner_distance_per_90': {
    'en': 'Distance',
    'no': 'Distance',
    'titles': {
      'en': 'Total distance (per 90)',
      'no': 'Antall kilometre løpt (per 90)',
    }
  },
  'skillcorner_sprinting_distance_per_90': {
    'en': 'Sprint distance',
    'no': 'Sprint distance',
    'titles': {
      'en': 'Total sprint distance above 25 km/h (per 90)',
      'no': 'Antall kilometre sprintet over 25 km/t (per 90)',
    }
  },
  'skillcorner_count_sprint_per_90': {
    'en': 'Sprints',
    'no': 'Sprints',
    'titles': {
      'en': 'Number of sprints above 25 km/h (per 90)',
      'no': 'Antall spurter over 25 km/t (per 90)',
    }
  },
  // 'skillcorner_count_medium_acceleration_per_90': {
  //   'en': 'Medium accelerations',
  //   'no': 'Medium accelerations',
  //   'titles': {
  //     'en': 'Number of medium accelerations (per 90)',
  //     'no': 'Antall moderate akselerasjoner (per 90)',
  //   }
  // },
  // 'skillcorner_count_high_acceleration_per_90': {
  //   'en': 'High accelerations',
  //   'no': 'High accelerations',
  //   'titles': {
  //     'en': 'Number of high accelerations (per 90)',
  //     'no': 'Antall høye akselerasjoner (per 90)',
  //   }
  // },
  'skillcorner_distance_tip_per_90': {
    'en': 'Distance TIP',
    'no': 'Distance TIP',
    'titles': {
      'en': 'Total distance in possession (per 90)',
      'no': 'Antall kilometre løpt når eget lag har ball (per 90)',
    }
  },
  'skillcorner_distance_otip_per_90': {
    'en': 'Distance OTIP',
    'no': 'Distance OTIP',
    'titles': {
      'en': 'Total distance out of in possession (per 90)',
      'no': 'Antall kilometre løpt når motstander har ball (per 90)',
    }
  },
  'skillcorner_sprinting_distance_tip_per_90': {
    'en': 'Sprint distance TIP',
    'no': 'Sprint distance TIP',
    'titles': {
      'en': 'Total sprinted distance in possession (per 90)',
      'no': 'Antall kilometre sprintet når eget lag har ball (per 90)',
    }
  },
  'skillcorner_sprinting_distance_otip_per_90': {
    'en': 'Sprint distance OTIP',
    'no': 'Sprint distance OTIP',
    'titles': {
      'en': 'Total sprinted distance out of in possession (per 90)',
      'no': 'Antall kilometre sprintet når motstander har ball (per 90)',
    }
  },
  'skillcorner_count_sprint_tip_per_90': {
    'en': 'Sprints TIP',
    'no': 'Sprints TIP',
    'titles': {
      'en': 'Number of sprints above 25 km/h in possession (per 90)',
      'no': 'Antall spurter over 25 km/t når eget lag har ball (per 90)',
    }
  },
  'skillcorner_count_sprint_otip_per_90': {
    'en': 'Sprints OTIP',
    'no': 'Sprints OTIP',
    'titles': {
      'en': 'Number of sprints above 25 km/h out of possession (per 90)',
      'no': 'Antall spurter over 25 km/t når mostander har ball (per 90)',
    }
  },
  'skillcorner_hsr_distance_per_90': {
    'en': 'HSR distance',
    'no': 'HSR distance',
    'titles': {
      'en': 'High speed running distance between 20 and 25 km/h (per 90)',
      'no': 'Distanse løpt i høy fart mellom 20 og 25 km/t (per 90)',
    }
  },
  'skillcorner_hsr_distance_tip_per_90': {
    'en': 'HSR distance TIP',
    'no': 'HSR distance TIP',
    'titles': {
      'en': 'High speed running distance between 20 and 25 km/h in possession (per 90)',
      'no': 'Distanse løpt i høy fart mellom 20 og 25 km/t når eget lag har ball (per 90)',
    }
  },
  'skillcorner_hsr_distance_otip_per_90': {
    'en': 'HSR distance OTIP',
    'no': 'HSR distance OTIP',
    'titles': {
      'en': 'High speed running distance between 20 and 25 km/h out of possession (per 90)',
      'no': 'Distanse løpt i høy fart mellom 20 og 25 km/t når mostander har ball (per 90)',
    }
  },


  // goalkeeping
  'gk_postshot_xg_prevented_difference_per_90': {
    'en': 'xGOT prevented',
    'no': 'xGOT prevented',
    'titles': {
      'en': 'Expected goals prevented by goalkeeper (per 90)',
      'no': 'Antall forventede mål forhindret av keeper (per 90))',
    }
  },
  'gk_postshot_xg_prevented_difference_per_shot': {
    'en': 'Goals prevented',
    'no': 'Goals prevented',
    'titles': {
      'en': 'Expected goals prevented per shots faced',
      'no': 'Antall forventede mål forhindret av keeper per skudd',
    }
  },
  'n_gk_saves_per_90': {
    'en': 'Saves',
    'no': 'Saves',
    'titles': {
      'en': 'Number of saves (per 90)',
      'no': 'Antall redninger (per 90)',
    }
  },
  'gk_saves_percentage': {
    'en': 'Save %',
    'no': 'Save %',
    'titles': {
      'en': 'Percentage of shots saved',
      'no': 'Redningsprosent',
    }
  },
  'n_gk_catch_per_90': {
    'en': 'Claims',
    'no': 'Claims',
    'titles': {
      'en': 'Number of catches (per 90)',
      'no': 'Antall baller holdt (per 90)',
    }
  },
  'n_gk_penalties_saved': {
    'en': 'Penalties saved',
    'no': 'Penalties saved',
    'titles': {
      'en': 'Number of penalties saved',
      'no': 'Antall straffer reddet',
    }
  },
  'n_gk_penalties_faced': {
    'en': 'Penalties faced',
    'no': 'Penalties faced',
    'titles': {
      'en': 'Number of penalties faced',
      'no': 'Antall straffer møtt',
    }
  },
  'gk_penalty_saves_percentage': {
    'en': 'Penalties saved %',
    'no': 'Penalties saved %',
    'titles': {
      'en': 'Percentage of penalties saved',
      'no': 'Redningsprosent for straffer',
    }
  },
  'n_gk_defensive_actions_outside_box_per_90': {
    'en': 'Aggressive actions', // Defensive actions outside box
    'no': 'Aggressive actions', // Defensive actions outside box
    'titles': {
      'en': 'Number of defensive actions outside the box (per 90)',
      'no': 'Antall defensive handlinger utenfor boksen (per 90)',
    }
  },
  'average_defensive_actions_distance_from_own_goal': {
    'en': 'Aggressive positioning', // Defensive actions distance
    'no': 'Aggressive positioning', // Defensive actions distance
    'titles': {
      'en': 'Average distance of defensive actions from own goal',
      'no': 'Gjennomsnittlig avstand fra eget mål for defensive handlinger utenfor boksen',
    }
  },
  'n_successful_actions_under_pressure_per_90': {
    'en': 'Successful actions under pressure',
    'no': 'Successful actions under pressure',
    'titles': {
      'en': 'Number of successful actions under pressure (per 90)',
      'no': 'Antall vellykkede handlinger under press (per 90)',
    }
  },
  'n_gk_goals_conceded_per_90': {
    'en': 'Goals conceded',
    'no': 'Goals conceded',
    'titles': {
      'en': 'Number of goals conceded (per 90)',
      'no': 'Antall mål sluppet inn (per 90)',
    }
  },
};


// const allMetrics = [
//   'total_obv_per_90',
//   'total_open_play_obv_per_90',
//   'dribble_obv_per_90',
//   'dribble_obv_prog_per_90',
//   'carry_obv_per_90',
//   'carry_obv_prog_per_90',
//   'dc_obv_per_90',
//   'pass_obv_per_90',
//   'pass_obv_prog_per_90',
//   'smart_pass_obv_per_90',
//   'smart_pass_obv_prog_per_90',
//   'cross_obv_per_90',
//   'shot_obv_per_90',
//   'receival_obv_per_90',
//   'ground_duel_obv_per_90',
//   'defending_ground_duel_obv_per_90',
//   'defending_ground_duel_obv_padj_per_90',
//   'aerial_duel_obv_per_90',
//   'interception_obv_per_90',
//   'interception_obv_padj_per_90',
//   'clearance_obv_per_90',
//   'clearance_obv_padj_per_90',
//   'block_obv_per_90',
//   'block_obv_padj_per_90',
//   'free_kick_obv_per_90',
//   'corner_obv_per_90',
//   'recovery_obv_per_90',
//   'recovery_obv_padj_per_90',
//   'defending_obv_per_90',
//   'defending_obv_padj_per_90',
//   'gk_save_obv_per_90',
//   'gk_catch_obv_per_90',

//   'total_obv_per_90_normalized',
//   'total_open_play_obv_per_90_normalized',
//   'dribble_obv_per_90_normalized',
//   'dribble_obv_prog_per_90_normalized',
//   'carry_obv_per_90_normalized',
//   'carry_obv_prog_per_90_normalized',
//   'dc_obv_per_90_normalized',
//   'pass_obv_per_90_normalized',
//   'pass_obv_prog_per_90_normalized',
//   'smart_pass_obv_per_90_normalized',
//   'smart_pass_obv_prog_per_90_normalized',
//   'cross_obv_per_90_normalized',
//   'shot_obv_per_90_normalized',
//   'receival_obv_per_90_normalized',
//   'ground_duel_obv_per_90_normalized',
//   'defending_ground_duel_obv_per_90_normalized',
//   'defending_ground_duel_obv_padj_per_90_normalized',
//   'aerial_duel_obv_per_90_normalized',
//   'interception_obv_per_90_normalized',
//   'interception_obv_padj_per_90_normalized',
//   'clearance_obv_per_90_normalized',
//   'clearance_obv_padj_per_90_normalized',
//   'block_obv_per_90_normalized',
//   'block_obv_padj_per_90_normalized',
//   'free_kick_obv_per_90_normalized',
//   'corner_obv_per_90_normalized',
//   'recovery_obv_per_90_normalized',
//   'recovery_obv_padj_per_90_normalized',
//   'defending_obv_per_90_normalized',
//   'defending_obv_padj_per_90_normalized',
//   'gk_save_obv_per_90_normalized',
//   'gk_catch_obv_per_90_normalized',
//   'n_gk_shots_faced_per_90_normalized',
//   'n_gk_penalties_saved_per_90_normalized',
//   'n_gk_penalties_faced_per_90_normalized',


//   'n_goals',
//   'n_assists',

//   'n_goals_per_90',
//   'n_np_goals_per_90',
//   'n_shots_per_90',
//   'n_shots_on_goal_per_90',
//   'xg_per_90',
//   'postshot_xg_per_90',
//   'np_xg_per_90',
//   'np_postshot_xg_per_90',
//   'np_xg_difference_per_90',
//   'np_postshot_xg_difference_per_90',
//   'xa_per_90',
//   'open_play_xa_per_90',
//   'np_xgi_per_90',
//   'n_assists_per_90',
//   'n_receptions_in_box_per_90',
//   'n_receptions_in_box_pp_padj',
//   'n_bypassed_opponents_per_90',
//   'n_dribbles_per_90',
//   'n_dribbles_pp_padj',
//   'n_successful_dribbles_per_90',
//   'n_carries_per_90',
//   'n_carries_pp_padj',
//   'n_successful_carries_per_90',
//   'n_passes_per_90',
//   'n_passes_pp_padj',
//   'n_successful_passes_per_90',
//   'n_smart_passes_per_90',
//   'n_smart_passes_pp_padj',
//   'n_successful_smart_passes_per_90',
//   'n_long_passes_per_90',
//   'n_long_passes_pp_padj',
//   'n_successful_long_passes_per_90',
//   'n_crosses_per_90',
//   'n_crosses_pp_padj',
//   'n_successful_crosses_per_90',
//   'n_deep_progressions_per_90',
//   'n_deep_progressions_pp_padj',
//   'n_hold_up_plays_per_90',
//   'n_successful_hold_up_plays_per_90',
//   'n_fouls_won_per_90',
//   'n_dribble_turnovers_per_90',
//   'n_dribble_turnovers_pp_padj',
//   'n_pass_turnovers_per_90',
//   'n_pass_turnovers_pp_padj',
//   'n_successful_receptions_under_pressure_own_half_per_90',
//   'n_actions_under_pressure_per_90',
//   'n_successful_actions_under_pressure_per_90',
//   'n_free_kicks_per_90',
//   'n_corners_per_90',
//   'n_recoveries_per_90',
//   'n_recoveries_padj_per_90',
//   'n_interceptions_per_90',
//   'n_interceptions_padj_per_90',
//   'n_clearances_per_90',
//   'n_clearances_padj_per_90',
//   'n_blocks_per_90',
//   'n_blocks_padj_per_90',
//   'n_ground_duels_per_90',
//   'n_ground_duels_won_per_90',
//   'n_defending_ground_duels_won_per_90',
//   'n_defending_ground_duels_won_padj_per_90',
//   'n_defending_ground_duels_won_in_own_box_per_90',
//   'n_defending_ground_duels_won_in_own_box_padj_per_90',
//   'n_aerial_duels_per_90',
//   'n_aerial_duels_won_per_90',
//   'n_aerial_duels_in_own_box_per_90',
//   'n_aerial_duels_in_own_box_padj_per_90',
//   'n_aerial_duels_won_in_own_box_per_90',
//   'n_aerial_duels_won_in_own_box_padj_per_90',
//   'n_presses_per_90',
//   'n_presses_padj_per_90',
//   'n_defensive_actions_per_90',
//   'n_defensive_actions_padj_per_90',
//   'n_fouls_per_90',
//   'n_gk_saves_per_90',
//   'n_gk_catch_per_90',
//   'gk_postshot_xg_saved_per_90',
//   'gk_postshot_xg_faced_per_90',
//   'gk_postshot_xg_conceded_per_90',
//   'n_gk_goals_conceded_per_90',
//   'gk_postshot_xg_prevented_difference_per_90',
//   'n_gk_shots_faced_per_90',
//   'n_gk_penalties_saved_per_90',
//   'n_gk_penalties_faced_per_90',
//   'n_gk_defensive_actions_outside_box_per_90',

//   'n_goals_per_90_normalized',
//   'n_np_goals_per_90_normalized',
//   'n_shots_per_90_normalized',
//   'n_shots_on_goal_per_90_normalized',
//   'xg_per_90_normalized',
//   'postshot_xg_per_90_normalized',
//   'np_xg_per_90_normalized',
//   'np_postshot_xg_per_90_normalized',
//   'np_xg_difference_per_90_normalized',
//   'np_postshot_xg_difference_per_90_normalized',
//   'xa_per_90_normalized',
//   'open_play_xa_per_90_normalized',
//   'np_xgi_per_90_normalized',
//   'n_assists_per_90_normalized',
//   'n_receptions_in_box_per_90_normalized',
//   'n_receptions_in_box_pp_padj_normalized',
//   'n_bypassed_opponents_per_90_normalized',
//   'n_dribbles_per_90_normalized',
//   'n_dribbles_pp_padj_normalized',
//   'n_successful_dribbles_per_90_normalized',
//   'n_carries_per_90_normalized',
//   'n_carries_pp_padj_normalized',
//   'n_successful_carries_per_90_normalized',
//   'n_passes_per_90_normalized',
//   'n_passes_pp_padj_normalized',
//   'n_successful_passes_per_90_normalized',
//   'n_smart_passes_per_90_normalized',
//   'n_smart_passes_pp_padj_normalized',
//   'n_successful_smart_passes_per_90_normalized',
//   'n_long_passes_per_90_normalized',
//   'n_long_passes_pp_padj_normalized',
//   'n_successful_long_passes_per_90_normalized',
//   'n_crosses_per_90_normalized',
//   'n_crosses_pp_padj_normalized',
//   'n_successful_crosses_per_90_normalized',
//   'n_deep_progressions_per_90_normalized',
//   'n_deep_progressions_pp_padj_normalized',
//   'n_hold_up_plays_per_90_normalized',
//   'n_successful_hold_up_plays_per_90_normalized',
//   'n_fouls_won_per_90_normalized',
//   'n_dribble_turnovers_per_90_normalized',
//   'n_dribble_turnovers_pp_padj_normalized',
//   'n_pass_turnovers_per_90_normalized',
//   'n_pass_turnovers_pp_padj_normalized',
//   'n_successful_receptions_under_pressure_own_half_per_90_normalized',
//   'n_actions_under_pressure_per_90_normalized',
//   'n_successful_actions_under_pressure_per_90_normalized',
//   'n_free_kicks_per_90_normalized',
//   'n_corners_per_90_normalized',
//   'n_recoveries_per_90_normalized',
//   'n_recoveries_padj_per_90_normalized',
//   'n_interceptions_per_90_normalized',
//   'n_interceptions_padj_per_90_normalized',
//   'n_clearances_per_90_normalized',
//   'n_clearances_padj_per_90_normalized',
//   'n_blocks_per_90_normalized',
//   'n_blocks_padj_per_90_normalized',
//   'n_ground_duels_per_90_normalized',
//   'n_ground_duels_won_per_90_normalized',
//   'n_defending_ground_duels_won_per_90_normalized',
//   'n_defending_ground_duels_won_padj_per_90_normalized',
//   'n_defending_ground_duels_won_in_own_box_per_90_normalized',
//   'n_defending_ground_duels_won_in_own_box_padj_per_90_normalized',
//   'n_aerial_duels_per_90_normalized',
//   'n_aerial_duels_won_per_90_normalized',
//   'n_aerial_duels_in_own_box_per_90_normalized',
//   'n_aerial_duels_in_own_box_padj_per_90_normalized',
//   'n_aerial_duels_won_in_own_box_per_90_normalized',
//   'n_aerial_duels_won_in_own_box_padj_per_90_normalized',
//   'n_presses_per_90_normalized',
//   'n_presses_padj_per_90_normalized',
//   'n_defensive_actions_per_90_normalized',
//   'n_defensive_actions_padj_per_90_normalized',
//   'n_fouls_per_90_normalized',
//   'n_gk_saves_per_90_normalized',
//   'n_gk_catch_per_90_normalized',
//   'gk_postshot_xg_saved_per_90_normalized',
//   'gk_postshot_xg_faced_per_90_normalized',
//   'gk_postshot_xg_conceded_per_90_normalized',
//   'n_gk_goals_conceded_per_90_normalized',
//   'gk_postshot_xg_prevented_difference_per_90_normalized',

//   'successful_dribble_percentage',
//   'successful_carry_percentage',
//   'successful_pass_percentage',
//   'successful_smart_pass_percentage',
//   'successful_long_passes_percentage',
//   'successful_hold_up_plays_percentage',
//   'aerial_duels_won_percentage',
//   'aerial_duels_won_in_own_box_percentage',
//   'ground_duels_won_percentage',
//   'successful_actions_under_pressure_percentage',
//   'successful_receptions_under_pressure_own_half_percentage',
//   'gk_saves_percentage',
//   'gk_penalty_saves_percentage',
//   'gk_postshot_xg_prevented_difference_per_shot',
//   'average_defensive_actions_distance_from_own_goal',
//   'n_gk_defensive_actions_outside_box_per_90_normalized',


//   'successful_dribble_percentage_normalized',
//   'successful_carry_percentage_normalized',
//   'successful_pass_percentage_normalized',
//   'successful_smart_pass_percentage_normalized',
//   'successful_long_passes_percentage_normalized',
//   'successful_hold_up_plays_percentage_normalized',
//   'aerial_duels_won_percentage_normalized',
//   'aerial_duels_won_in_own_box_percentage_normalized',
//   'ground_duels_won_percentage_normalized',
//   'successful_actions_under_pressure_percentage_normalized',
//   'successful_receptions_under_pressure_own_half_percentage_normalized',
//   'gk_saves_percentage_normalized',
//   'gk_penalty_saves_percentage_normalized',
//   'gk_postshot_xg_prevented_difference_per_shot_normalized',
//   'average_defensive_actions_distance_from_own_goal_normalized',
// ];
