import './playerView.css';

import 'react-circular-progressbar/dist/styles.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { settingsState } from '../../recoil/atoms/settingsState';
import { selectedPlayerState } from '../../recoil/atoms/selectedPlayerState';
import { playerDetailsState } from '../../recoil/atoms/playerDetailsState';

import BlockIcon from '@mui/icons-material/Block';
import LockIcon from '@mui/icons-material/Lock';

import { ClubIteration, FlexibleJsonMapping } from '../../types';
import { staticLanguageMap } from '../../../common/static/staticLanguageMap';
import { getClubColor } from '../../utils/configUtils';
import { SeasonTable } from '../tables/seasonTable/SeasonTable';
import { PlayerLinePlot, minMinutesPlayedThresholdPlayerLinePlot } from '../plots/PlayerLinePlot';
import { PlayerRadarPlot } from '../plots/PlayerRadarPlot';
import { positionOptionsDatabase } from '../../static/propertyValues';
import { competitionsState } from '../../recoil/atoms/competitionsState';


interface PlayerViewOverviewProps {
  playerOverview: FlexibleJsonMapping | undefined;
}

export const PlayerViewOverview: React.FC<PlayerViewOverviewProps> = ({ playerOverview }) => {

  const userConfig = useRecoilValue(userConfigState);
  const settings = useRecoilValue(settingsState);
  const selectedPlayer = useRecoilValue(selectedPlayerState);
  const competitions = useRecoilValue(competitionsState);

  const playerDetails = useRecoilValue(playerDetailsState);
  const [playerDetailsArray, setPlayerDetailsArray] = useState<ClubIteration[]>([]);

  const [positionsPlayed, setPositionPlayed] = useState<string[]>([]);
  const [selectedPosition, setSelectedPosition] = useState(0);
  const [selectedPositionKey, setSelectedPositionKey] = useState('overall');

  const [selectedClubIterationId, setSelectedClubIterationId] = useState<string | undefined>(undefined);
  const [shouldShowSeasonTable, setShouldShowSeasonTable] = useState(false);
  const [linePlotTitleIcon, setLinePlotTitleIcon] = useState<string | undefined>(undefined);

  const [confidence, setConfidence] = useState(-1);
  const [possibleConfidence, setPossibleConfidence] = useState(-1);


  const handlePositionSelected = (position: string, index: number) => {
    setSelectedPositionKey(index === 0 ? 'overall' : position);
    setSelectedPosition(index);
  };


  const getOverviewProperty = (property: string) => {
    if (
      playerOverview
      && playerOverview[selectedPositionKey]
      && playerOverview[selectedPositionKey][property] !== undefined
      && playerOverview[selectedPositionKey][property] !== null
    ) {
      return Math.round(playerOverview[selectedPositionKey][property] * 10) / 10;
    }
    return '-';
  };


  const getOverviewRank = (property: string) => {
    if (playerOverview && playerOverview[selectedPositionKey]) {
      return playerOverview[selectedPositionKey][property];
    }
    return '-';
  };


  const getRadarPlotTitle = () => {

    // linePlotTitleIcon is undefined until data is initialized and is used here as a boolean loading flag
    if (!linePlotTitleIcon) return '';

    if (playerDetails && selectedPlayer && selectedPlayer.id in playerDetails && selectedClubIterationId !== undefined && competitions) {
      const clubIteration = playerDetails[Number(selectedPlayer.id)][selectedClubIterationId];
      const competition = competitions[clubIteration['competition_id']];
      if (competition) {
        return competition.name + ' ' + clubIteration['season'];
      }
    }
    if (playerOverview && playerOverview['event_data_exists'] && !playerOverview['event_data_available']) {
      return <LockIcon style={{ fontSize: 20, marginTop: -3 }} />;
    }

    if (playerOverview) {
      return <BlockIcon style={{ fontSize: 20, marginTop: -3 }} />;
    }
  };


  const getLinePlotTitle = () => {
    if (userConfig && linePlotTitleIcon === 'none') {
      return staticLanguageMap['clubRatingDevelopment'][userConfig.language];
    }

    if (linePlotTitleIcon === 'lock') {
      return <LockIcon style={{ fontSize: 20, marginTop: -3 }} />;
    }

    if (linePlotTitleIcon === 'block') {
      return <BlockIcon style={{ fontSize: 20, marginTop: -3 }} />;
    }
  };


  useEffect(() => {
    if (playerDetails && selectedPlayer && selectedPlayer.id in playerDetails && settings) {

      // console.log('playerDetails', playerDetails[Number(selectedPlayer.id)]);

      const details = playerDetails[Number(selectedPlayer.id)];
      const detailsArray: ClubIteration[] = [];

      Object.keys(details).forEach((clubIterationId) => {
        if (details[clubIterationId]['event_data_exists']) {
          detailsArray.push({ ...details[clubIterationId], 'club_iteration_id': clubIterationId });
        }
      });

      if (detailsArray.length > 0) {
        setShouldShowSeasonTable(true);
      }

      // console.log('detailsArray', detailsArray);

      detailsArray.sort((a, b) => b['plot_date'] && a['plot_date'] ? b['plot_date'].localeCompare(a['plot_date']) : 0);

      const detailsWithToggleConstraintsArray: ClubIteration[] = [];
      let anyLeagueWithMinutes = false;

      // we only want to set a new selectedClubIterationId if none is selected or if the selected one is no longer available or blocked
      const rejectedClubIterationIds: string[] = [];
      let newSelectedClubIterationId: string | undefined = undefined;

      // line plot title is either shown if htere is a point to show, or it is blocked or locked
      let isAnyLinePoint = false;
      let couldBeAnyLinePoint = false;

      detailsArray.forEach((details) => {
        if (details['event_data_exists']) {

          const eventDataAvailable = details['event_data_available'];

          const positionKeyTodata = details[selectedPositionKey]
            ? selectedPositionKey
            : (selectedPositionKey === details['primary_position'] ? 'overall' : selectedPositionKey);

          const competitionIsLeague = details['competition_id'] in competitions && competitions[details['competition_id']].type === 'League';
          const minutesPlayed = details[positionKeyTodata] ? details[positionKeyTodata]['minutes_played'] : 0;

          const seasonIsConstrainedByMinutes = settings.userSettings.seasonStatsToggles
            && settings.userSettings.seasonStatsToggles['showOnlySeasonsWith180Minutes']
            && minutesPlayed < 180;

          const seasonIsConstrainedByLeague = settings.userSettings.seasonStatsToggles
            && settings.userSettings.seasonStatsToggles['showOnlyDomesticLeagues']
            && !competitionIsLeague;

          const seasonIsBlocked = minutesPlayed === 0;

          if (!seasonIsConstrainedByMinutes && !seasonIsConstrainedByLeague) {
            if (
              eventDataAvailable &&
              !anyLeagueWithMinutes &&
              competitionIsLeague &&
              minutesPlayed > 0
            ) {
              newSelectedClubIterationId = details['club_iteration_id'];
              anyLeagueWithMinutes = true;
            }
            else if (
              eventDataAvailable &&
              !anyLeagueWithMinutes &&
              !competitionIsLeague &&
              minutesPlayed > 0
            ) {
              newSelectedClubIterationId = details['club_iteration_id'];
            }

            detailsWithToggleConstraintsArray.push(details);
          }

          if (seasonIsConstrainedByMinutes || seasonIsConstrainedByLeague || seasonIsBlocked) {
            rejectedClubIterationIds.push(details['club_iteration_id']);
          }

          if (competitionIsLeague && minutesPlayed >= minMinutesPlayedThresholdPlayerLinePlot) {
            if (eventDataAvailable) {
              isAnyLinePoint = true;
            }
            couldBeAnyLinePoint = true;
          }
        }
      });

      if (selectedClubIterationId === undefined || rejectedClubIterationIds.includes(selectedClubIterationId)) {
        setSelectedClubIterationId(newSelectedClubIterationId);
      }

      setLinePlotTitleIcon(isAnyLinePoint ? 'none' : couldBeAnyLinePoint ? 'lock' : 'block');

      setPlayerDetailsArray(detailsWithToggleConstraintsArray);
    }
  }, [competitions, playerDetails, selectedPlayer, selectedPositionKey, settings]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    const timer = setTimeout(() => {
      setConfidence(playerOverview && playerOverview[selectedPositionKey] ? playerOverview[selectedPositionKey]['confidence'] ?? 0 : 0);
      setPossibleConfidence(playerOverview && playerOverview[selectedPositionKey] ? playerOverview[selectedPositionKey]['possible_confidence'] ?? 0 : 0);
    }, 200);

    return () => clearTimeout(timer);
  }, [playerOverview, selectedPositionKey]);


  useEffect(() => {
    if (playerOverview) {
      const positionsPlayed = positionOptionsDatabase
        .filter((position) => playerOverview[position])
        .sort((a, b) => playerOverview[b]['possible_confidence'] - playerOverview[a]['possible_confidence']);
      if (positionsPlayed.length > 0) {
        setPositionPlayed(positionsPlayed);
      }
      else if (playerOverview['primary_position']) {
        setPositionPlayed([playerOverview['primary_position']]);
      }
    }
  }, [playerOverview]);


  const clubColor = getClubColor(userConfig?.club ?? '');


  return (
    <div className='player-view-main-section'>

      <div className='player-view-overview-top-section'>

        <div className='player-view-overview-top-left-section'>

          <div className='player-view-overview-position-section'>
            <div className='player-view-overview-position-title'>
              {userConfig ? staticLanguageMap['positionsWithData'][userConfig.language] : ''}
            </div>
            <div className='player-view-overview-positions-played' >
              {(positionsPlayed.length > 1 ? ['all', ...positionsPlayed] : positionsPlayed).map((position, index) => {
                return (
                  <div
                    key={index}
                    className={'player-view-overview-position' + (index === selectedPosition ? ' player-view-overview-position-selected' : '')}
                    style={{ border: '1px solid ' + (index === selectedPosition ? clubColor : 'transparent'), marginLeft: index > 0 ? '0.5vw' : undefined }}
                    onClick={() => handlePositionSelected(position, index)}>
                    {userConfig ? staticLanguageMap[position][userConfig.language] : ''}
                  </div>
                );
              })}
            </div>
          </div>

          <div className='player-view-overview-index-container'>

            <div className='player-view-overview-index-row'>
              <div className='player-view-overview-index-column'>
                <div className='player-view-overview-rating-title'>
                  {userConfig ? staticLanguageMap['skillIndex'][userConfig.language] : ''}
                </div>
                <div className='player-view-overview-rating-value' title={getOverviewRank('skill_index_rank')}>
                  {getOverviewProperty('skill_index')}
                </div>
              </div>

              <div className='player-view-overview-index-column'>
                <div className='player-view-overview-rating-title'>
                  {userConfig ? staticLanguageMap['clubIndex'][userConfig.language] : ''}
                </div>
                <div className='player-view-overview-rating-value' title={getOverviewRank('club_index_rank')}>
                  {getOverviewProperty('club_index')}
                </div>
              </div>
            </div>

            <div className='player-view-overview-index-divider'>&nbsp;</div>

            <div className='player-view-overview-index-row'>
              <div className='player-view-overview-index-column'>
                <div className='player-view-overview-precision-title'>
                  {userConfig ? staticLanguageMap['possiblePrecision'][userConfig.language] : ''}
                </div>
                <div className='player-view-overview-precision-progress'>
                  <CircularProgressbar
                    value={Math.round(possibleConfidence * 100)}
                    styles={buildStyles({
                      strokeLinecap: 'butt',
                      pathTransitionDuration: 0.6,
                      pathColor: '#5e6f85',
                      trailColor: '#3e4459',
                    })}
                  />
                  <div className='player-view-overview-precision-value player-view-overview-possible-precision-value'>
                    {possibleConfidence >= 0 ? Math.round(possibleConfidence * 100) : ''}
                  </div>
                </div>
              </div>

              <div className='player-view-overview-index-column'>
                <div className='player-view-overview-precision-title'>
                  {userConfig ? staticLanguageMap['precision'][userConfig.language] : ''}
                </div>
                <div className='player-view-overview-precision-progress'>
                  <CircularProgressbar
                    value={Math.round(confidence * 100)}
                    styles={buildStyles({
                      strokeLinecap: 'butt',
                      pathTransitionDuration: 0.6,
                      pathColor: clubColor,
                      trailColor: '#3e4459',
                    })}
                  />
                  <div className='player-view-overview-precision-value'>
                    {confidence >= 0 ? Math.round(confidence * 100) : ''}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='player-view-overview-top-middle-section'>
          <div style={{ width: '100%', height: '100%' }}>
            <div className='player-view-overview-top-right-section-title'>
              {getLinePlotTitle()}
            </div>
            <div className='player-view-overview-top-right-section-divider'>&nbsp;</div>
            <div className='player-view-plot-container player-view-line-plot-container'>
              <PlayerLinePlot
                playerDetailsArray={playerDetailsArray}
                selectedPositionKey={selectedPositionKey}
                clubColor={clubColor}
                selectedClubIterationId={selectedClubIterationId}
                competitions={competitions}
              />
            </div>
          </div>
        </div>

        <div className='player-view-overview-top-right-section'>
          {userConfig && (
            <div style={{ width: '100%', height: '100%' }}>
              <div className='player-view-overview-top-right-section-title'>
                {getRadarPlotTitle()}
              </div>
              <div className='player-view-overview-top-right-section-divider'>&nbsp;</div>
              <div className='player-view-plot-container player-view-radar-plot-container'>
                <PlayerRadarPlot
                  playerDetails={playerDetails && selectedPlayer && selectedPlayer.id in playerDetails ? playerDetails[Number(selectedPlayer.id)] : {}}
                  selectedClubIterationId={selectedClubIterationId}
                  selectedPositionKey={selectedPositionKey}
                  clubColor={clubColor}
                  language={userConfig.language}
                />
              </div>
            </div>
          )}
        </div>

      </div>

      <div className='player-view-overview-bottom-section'>
        {playerOverview && playerDetailsArray && shouldShowSeasonTable && (
          <div className='player-view-overview-season-table-section'>

            <div className='player-view-overview-season-table'>
              <SeasonTable
                data={playerDetailsArray}
                selectedPositionKey={selectedPositionKey}
                primaryPosition={positionsPlayed[0]}
                selectedClubIterationId={selectedClubIterationId}
                setSelectedClubIterationId={setSelectedClubIterationId}
              />
              &nbsp;
            </div>

          </div>
        )}
      </div>

    </div>
  );
};
