import './modals.css';

import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { useGlobalModal } from '../../recoil/hooks/openGlobalModal';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { staticLanguageMap } from '../../../common/static/staticLanguageMap';
import { UserConfig } from '../../types';
import { editAccessOptions, editFokusAccessOptions } from '../../static/propertyValues';
import { getAccessIcon } from '../../utils/iconUtils';
import { useState } from 'react';
import { updateUserConfig } from '../../services/firestore/users';


interface AccountModalProps {
  user: UserConfig | undefined;
  closeModal: () => void;
  isFokusAccount?: boolean;
}

export const AccountModal: React.FC<AccountModalProps> = ({ user, closeModal, isFokusAccount }) => {

  const userConfig = useRecoilValue(userConfigState);

  const { handleOpenTextModal } = useGlobalModal();

  const [currentAccess, setCurrentAccess] = useState<string | undefined>(user?.access);


  const handleNewAccessClick = (access: string, isSelected: boolean) => {
    const newAccess = isSelected ? undefined : access;
    setCurrentAccess(newAccess);
  };


  const confirmEdits = () => {
    if (user && currentAccess && user.access !== currentAccess) {
      updateUserConfig(user?.email, 'access', currentAccess);
      closeModal();
    }
  };

  return (
    <div className='modal-root-container account-modal-root'>

      <CloseIcon
        className='modal-icon modal-exit-icon'
        style={{ fontSize: 24 }}
        onClick={() => closeModal()}
      />

      <div className='modal-root-title'>
        {userConfig && user
          ? (staticLanguageMap['editAccessFor'][userConfig.language] + ' ' + user.name)
          : ''}
      </div>

      <div className='modal-divider modal-root-title-divider'>&nbsp;</div>

      <div style={{ margin: 'auto', marginTop: 34 }}>
        {(isFokusAccount ? editFokusAccessOptions : editAccessOptions).map((access: string, index: number) => {

          const isSelected = user !== undefined && currentAccess === access;
          const icon = user ? getAccessIcon(access, 22) : undefined;

          return (
            <div key={index} style={{ position: 'relative', marginTop: index === 0 ? 2 : 18 }}>
              <div
                className='modal-access-option-info-icon'
                onClick={() => handleOpenTextModal(userConfig ? staticLanguageMap[access + 'AccessInfo'][userConfig.language] : '', 550)}
              >
                <InfoOutlinedIcon style={{ fontSize: 16 }} />
              </div>
              <div
                key={access}
                className={'edit-player-info-modal-role-option' + (isSelected ? ' edit-player-info-modal-role-option-selected' : '')}
                style={{ boxSizing: 'border-box', height: 32 }}
                onClick={() => handleNewAccessClick(access, isSelected)}
              >
                <div className='edit-player-info-modal-role-icon' style={{ marginTop: 3 }}>
                  {icon}
                </div>
                <div className='edit-player-info-modal-role-text'>
                  {userConfig && user ? staticLanguageMap[access + 'Access'][userConfig.language] : ''}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div
        className={'modal-button account-modal-button' + (currentAccess !== undefined && user && user.access !== currentAccess ? '' : ' modal-button-disabled')}
        onClick={() => confirmEdits()}>
        <div className='modal-button-text'>
          {userConfig ? staticLanguageMap['confirmNewAccess'][userConfig.language] : ''}
        </div>

        <CheckIcon
          style={{ fontSize: 22 }}
        />
      </div>

    </div>
  );
};
