import { StringToNumberMapping } from '../types';


export const countryCodeToCurrencyEuroMultiplier: StringToNumberMapping = {
  'JP': 170,
  'NO': 11.94,
  'DK': 7.5,
  'SE': 12.79,
};


export const currencyToEuroMultiplier: StringToNumberMapping = {
  'EUR': 1,
  'NOK': 11.94,
  'DKK': 7.5,
  'SEK': 12.79,
  'GBP': 0.86,
  'USD': 1.09,
};
