import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthContextProvider } from './common/contexts/AuthContext';
import { RecoilRoot } from 'recoil';

// import { Home } from './site/views/home/Home';
import { Login } from './site/views/login/Login';
import { Platform } from './platform/Platform';


function App() {
  return (
    <div>
      <BrowserRouter>
        <AuthContextProvider>
          <Routes>

            {/* <Route
              path="/"
              element={
                <Home />
              }
            /> */}

            <Route
              path="/"
              element={
                <Login />
              }
            />

            <Route
              path="/platform"
              element={(
                <RecoilRoot>
                  <Platform />
                </RecoilRoot>
              )}
            />

          </Routes>
        </AuthContextProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
