import './../modals.css';

import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { enGB, nb } from 'date-fns/locale';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import ReplayIcon from '@mui/icons-material/Replay';

import { staticLanguageMap } from '../../../../common/static/staticLanguageMap';
import { DatedValue } from '../../../types';
import { getDateLocaleFormat } from '../../../utils/playerUtils';
import { ClauseState } from '../../documents/clause/clauseInitialState';
import { ClauseAction } from '../../documents/clause/clauseReducer';


interface OverrideTotalConditionModalProps {
  closeModal: () => void;
  state: ClauseState;
  dispatch: React.Dispatch<ClauseAction>;
  totalConditionIndex: number | undefined;
}

export const OverrideTotalConditionModal: React.FC<OverrideTotalConditionModalProps> = ({
  closeModal,
  state,
  dispatch,
  totalConditionIndex,
}) => {

  const userConfig = useRecoilValue(userConfigState);

  const [newValue, setNewValue] = useState('');
  const [providedDate, setProvidedDate] = useState<Date | null>(new Date());
  const buttonIsDisabled = !newValue || !providedDate;


  const dateFormat = 'dd.MM.yyyy';
  const locale = userConfig && userConfig.language === 'no'
    ? nb
    : enGB;

  const minPaymentDate = new Date((new Date()).setFullYear((new Date()).getFullYear() - 10));
  const maxPaymentDate = new Date((new Date()).setFullYear((new Date()).getFullYear() + 10));

  const handleSetProvidedDate = (date: Date | null) => {
    if (!date) {
      setProvidedDate(null);
      return;
    }
    const adjustedDate = new Date(date);
    adjustedDate.setHours(12);
    setProvidedDate(adjustedDate);
  };


  const onChangeInputField = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (value !== '') {
      const numberValue = Number(value);
      if (isNaN(numberValue) || numberValue < 0) return;
    }

    setNewValue(value);
  };


  const onKeyDownInputField = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      if (!buttonIsDisabled) handleOverrideTotalCondition(Number(newValue), providedDate.toISOString().split('T')[0]);
    }
  };


  const handleOverrideTotalCondition = (newValue: number | null, providedDate?: string) => {
    if (totalConditionIndex !== undefined) {
      const overriddenValue: DatedValue | null = newValue !== null && providedDate ? { value: newValue, date: providedDate } : null;
      dispatch({ type: 'SET_OVERRIDDEN_TOTAL_CONDITION_VALUE', payload: { overriddenValue, totalConditionIndex } });
    }
    closeModal();
  };


  const stateAccessor = state.additionalClauseState.activeSubClauseTab !== undefined && state.subClauses
    ? state.subClauses[state.additionalClauseState.activeSubClauseTab]
    : state;
  const previousOverriddenValue = totalConditionIndex !== undefined ? stateAccessor.totalConditions[totalConditionIndex].overriddenValue : undefined;


  return (
    <div className='modal-root-container override-total-condition-modal-root'>

      <CloseIcon
        className='modal-icon modal-exit-icon'
        style={{ fontSize: 24 }}
        onClick={() => closeModal()}
      />

      <div className='modal-root-title'>
        {userConfig ? staticLanguageMap['incorrectValue?'][userConfig.language] : ''}
      </div>

      <div className='modal-divider modal-root-title-divider'>&nbsp;</div>

      <div className='modal-content-section'>

        <div style={{ width: '80%', margin: 'auto', textAlign: 'center', marginTop: 28 }}>
          {userConfig ? staticLanguageMap['overrideValueInfo'][userConfig.language] : ''}
        </div>

        <div className='override-total-condition-modal-date-section'>
          <div style={{ marginBottom: 8 }}>
            {userConfig ? staticLanguageMap['provideDate'][userConfig.language] : ''}
          </div>
          <DatePicker
            className='document-input-field document-input-field-small'
            selected={providedDate}
            onChange={(date: Date | null) => handleSetProvidedDate(date)}
            onKeyDown={(event) => onKeyDownInputField(event)}
            locale={locale}
            dateFormat={dateFormat}
            popperPlacement='bottom'

            minDate={minPaymentDate}
            maxDate={maxPaymentDate}
            showYearDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={100}
            showMonthDropdown
          />
        </div>

        <div className='override-total-condition-modal-value-container'>
          <div className='override-total-condition-modal-value-section'>
            <div style={{ marginBottom: 8 }}>
              {userConfig ? staticLanguageMap['provideNewValue'][userConfig.language] : ''}
            </div>
            <input
              className='document-input-field document-input-field-small'
              name='payments-modal-input-field-amount'
              type='text'
              autoComplete='off'
              value={newValue}
              onChange={(event) => onChangeInputField(event)}
              onKeyDown={(event) => onKeyDownInputField(event)}
            />
          </div>
        </div>
      </div>

      {previousOverriddenValue && (
        <div className='override-total-condition-modal-previous-value'>
          {userConfig
            ? staticLanguageMap['previousOverridenValue'][userConfig.language]
            : ''}
          : {previousOverriddenValue.value} {'(' + getDateLocaleFormat(previousOverriddenValue.date) + ')'}

          <div
            title={userConfig ? staticLanguageMap['resetOverriddenValue'][userConfig.language] : ''}
            className='override-total-condition-modal-previous-value-reset-icon'
            onClick={() => handleOverrideTotalCondition(null)}
          >
            <ReplayIcon style={{ fontSize: 18 }} />
          </div>
        </div>
      )}

      <div
        className={'modal-button modal-button-bottom' + (buttonIsDisabled ? ' modal-button-disabled' : '')}
        style={{ marginLeft: 150 }}
        onClick={() => !buttonIsDisabled ? handleOverrideTotalCondition(Number(newValue), providedDate.toISOString().split('T')[0]) : null}
      >
        <div className='modal-button-text'>
          {userConfig ? staticLanguageMap['overrideValue'][userConfig.language] : ''}
        </div>

        <CheckIcon className='modal-button-icon' style={{ fontSize: 24 }} />
      </div>

    </div>
  );
};
