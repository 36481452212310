import { FlexibleJsonMapping } from '../types';
import { formationToPositionOptions } from '../static/propertyValues';
import { deepCloneObject } from './utils';


export const formationHasBackThree = (formation: string) => {
  return formation.startsWith('3');
};

export const formationHasBackFour = (formation: string) => {
  return formation.startsWith('4');
};

export const formationHasBackFive = (formation: string) => {
  return formation.startsWith('5');
};

export const formationHasCDM = (formation: string) => {
  return formationToPositionOptions[formation].includes('CDM');
};

export const formationHasCAM = (formation: string) => {
  return formationToPositionOptions[formation].includes('CAM');
};

export const formationHasWingers = (formation: string) => {
  return formationToPositionOptions[formation].includes('LW');
};

export const formationHasWideMidfielders = (formation: string) => {
  return formationToPositionOptions[formation].includes('LM');
};

export const formationHasTwoStrikers = (formation: string) => {
  return formationToPositionOptions[formation].includes('LS');
};

export const formationHasOneStriker = (formation: string) => {
  return formationToPositionOptions[formation].includes('S');
};


// Swap positions on each side (LW & RW to LM & RM, etc.)
const swapWidePosition = (oldTeam: FlexibleJsonMapping, newTeam: FlexibleJsonMapping, oldPositionKey: string, newPositionKey: string) => {
  ['L', 'R'].forEach(side => {
    newTeam[side + newPositionKey] = (newTeam[side + newPositionKey] ?? []).concat(oldTeam[side + oldPositionKey] ?? []);
    newTeam[side + oldPositionKey] = [];
  });
};

// Move multiple old positions to a single new position (RS & LS to S, etc.)
const moveMultiplePositions = (oldTeam: FlexibleJsonMapping, newTeam: FlexibleJsonMapping, oldPositions: string[], newPositionKey: string) => {
  oldPositions.forEach(position => {
    newTeam[newPositionKey] = (newTeam[newPositionKey] ?? []).concat(oldTeam[position] ?? []);
    newTeam[position] = [];
  });
};

// Move a single central position (CDM, CAM, S) to two alternating positions (LCM & RCM, LS & RS)
const alternateCentralPosition = (oldTeam: FlexibleJsonMapping, newTeam: FlexibleJsonMapping, oldPosition: string, newPositionKey: string) => {
  const newLeftSidePlayers = [];
  const newRightSidePlayers = [];

  if (oldTeam[oldPosition]) {
    for (let i = 0; i < oldTeam[oldPosition].length; i++) {
      if (i % 2 === 0) {
        newLeftSidePlayers.push(oldTeam[oldPosition][i]);
      }
      else {
        newRightSidePlayers.push(oldTeam[oldPosition][i]);
      }
    }
  }

  newTeam['L' + newPositionKey] = (newTeam['L' + newPositionKey] ?? []).concat(newLeftSidePlayers);
  newTeam['R' + newPositionKey] = (newTeam['R' + newPositionKey] ?? []).concat(newRightSidePlayers);
  newTeam[oldPosition] = [];
};


// Currently supports transition between these formations:
// 433, 442, 4312, 4231, 4141, 4411, 343, 541, 523, 532
//
// Possible positions are:
//
//               GK
// RWB, RB, RCB, CB, LCB, LB, LWB
//              CDM
//  RM,       RCM, LCM        LM
//              CAM,
//   RW,   RS,   S,   LS,    LW
//
// Every formation will have these positions:
//
//               GK
//            RCB, LCB
//            RCM, RCM
//
export const getTeamWithNewFormation = (teamData: FlexibleJsonMapping, newFormation: string, currentFormation: string,) => {

  const newTeam: FlexibleJsonMapping = deepCloneObject(teamData);

  // Centre backs
  // If moving from 3 centre backs to 2
  if (!formationHasBackFour(currentFormation) && formationHasBackFour(newFormation)) {
    // CB to LCB/RCB
    alternateCentralPosition(teamData, newTeam, 'CB', 'CB');
  }

  // Full backs
  // If moving from 4 at the back to 5
  if (formationHasBackFour(currentFormation) && formationHasBackFive(newFormation)) {
    swapWidePosition(teamData, newTeam, 'B', 'WB');
  }
  // If moving from 5 at the back to 4
  else if (formationHasBackFive(currentFormation) && formationHasBackFour(newFormation)) {
    swapWidePosition(teamData, newTeam, 'WB', 'B');
  }

  // Central midfielders
  // If current formation has CDM and new formation does not
  if (formationHasCDM(currentFormation) && !formationHasCDM(newFormation)) {
    // CDM to LCM/RCM
    alternateCentralPosition(teamData, newTeam, 'CDM', 'CM');
  }
  // If current formation has CAM and new formation does not
  if (formationHasCAM(currentFormation) && !formationHasCAM(newFormation)) {
    // CAM to LCM/RCM
    alternateCentralPosition(teamData, newTeam, 'CAM', 'CM');
  }

  // Wide midfielders and wingers
  // Special handling if moving to or from a 343
  if (currentFormation === '343' || newFormation === '343') {
    // If moving from 343, the wide midfielders will be moved to the back line and the wingers will either remain or be moved to wide-midfield/wide-striker
    if (currentFormation === '343') {
      const newFullBackKey = formationHasBackFour(newFormation) ? 'B' : 'WB';
      swapWidePosition(teamData, newTeam, 'M', newFullBackKey);

      // if a formation does not have wingers, it will have wide midfielders or wide strikers
      if (!formationHasWingers(newFormation)) {
        if (formationHasWideMidfielders(newFormation)) {
          // winger to LM/RM
          swapWidePosition(teamData, newTeam, 'W', 'M');
        }
        else {
          // winger to LS/RS
          swapWidePosition(teamData, newTeam, 'W', 'S');
        }
      }
    }

    // If moving to 343, the full backs will be moved to wide midfielders and the potential wide midfielders will be moved to wingers
    if (newFormation === '343') {
      if (!formationHasWingers(currentFormation)) {
        if (formationHasWideMidfielders(currentFormation)) {
          // LM/RM to winger
          swapWidePosition(teamData, newTeam, 'M', 'W');
        }
      }

      const oldFullBackKey = formationHasBackFour(currentFormation) ? 'B' : 'WB';
      swapWidePosition(teamData, newTeam, oldFullBackKey, 'M');
    }
  }

  // If current formation has wingers and new formation has wide midfielders
  else if (formationHasWingers(currentFormation) && formationHasWideMidfielders(newFormation)) {
    // W to M
    swapWidePosition(teamData, newTeam, 'W', 'M');
  }
  // If current formation has wide midfielders and new formation has wingers
  else if (formationHasWideMidfielders(currentFormation) && formationHasWingers(newFormation)) {
    // M to W
    swapWidePosition(teamData, newTeam, 'M', 'W');
  }
  // If current formation has wingers or wide midfielders and new formation has none
  else if (
    (formationHasWingers(currentFormation) || formationHasWideMidfielders(currentFormation)) &&
    !(formationHasWingers(newFormation) || formationHasWideMidfielders(newFormation))
  ) {
    // LM/LW to LS
    moveMultiplePositions(teamData, newTeam, ['LM', 'LW'], 'LS');

    // RM/RW to RS
    moveMultiplePositions(teamData, newTeam, ['RM', 'RW'], 'RS');
  }

  // Strikers
  // If moving from 2 strikers to 1
  if (formationHasTwoStrikers(currentFormation) && formationHasOneStriker(newFormation)) {
    // LS and RS to S
    moveMultiplePositions(teamData, newTeam, ['LS', 'RS'], 'S');
  }
  // If moving from 1 striker to 2
  else if (formationHasOneStriker(currentFormation) && formationHasTwoStrikers(newFormation)) {
    // S to LS/RS
    alternateCentralPosition(teamData, newTeam, 'S', 'S');
  }

  newTeam['formation'] = newFormation;

  return newTeam;
};
