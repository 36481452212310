import './history.css';

import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { allUserConfigsState } from '../../recoil/atoms/allUserConfigsState';
import { teamsState } from '../../recoil/atoms/teamsState';

import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import DescriptionIcon from '@mui/icons-material/Description';
import MessageIcon from '@mui/icons-material/Message';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import { FlexibleJsonMapping } from '../../types';
import { staticLanguageMap } from '../../../common/static/staticLanguageMap';
import { staticTeamKeys } from '../../static/propertyValues';
import { getDateLocaleFormat } from '../../utils/playerUtils';
import { getShortUserName } from '../../utils/userUtils';


interface PlayerHistoryProps {
  history: FlexibleJsonMapping[];
}


export const PlayerHistory: React.FC<PlayerHistoryProps> = ({ history }) => {

  const userConfig = useRecoilValue(userConfigState);
  const allUsersWithSameClub = useRecoilValue(allUserConfigsState);
  const teams = useRecoilValue(teamsState);

  const [showHistory, setShowHistory] = useState(false);


  const shouldShowDate = (index: number) => {
    if (index === 0) return true;

    const previousDate = history[index - 1]['date'].split('T')[0];
    const currentDate = history[index]['date'].split('T')[0];

    const previousUser = history[index - 1]['userEmail'];
    const currentUser = history[index]['userEmail'];

    return previousDate !== currentDate || previousUser !== currentUser;
  };


  const getHistoryInfoText = (playerAction: FlexibleJsonMapping, actionType: string, isCurrentUser: boolean) => {
    if (!userConfig || !teams) return '';

    const userName = isCurrentUser
      ? staticLanguageMap['you'][userConfig.language]
      : allUsersWithSameClub[playerAction['userEmail']]
        ? getShortUserName(allUsersWithSameClub[playerAction['userEmail']].name)
        : staticLanguageMap['unknownUser'][userConfig.language];

    if (actionType === 'playerActivity') {

      const fromTeamName: string | undefined = playerAction['fromTeamId']
        ? (staticTeamKeys.includes(playerAction['fromTeamId'])
          ? staticLanguageMap[playerAction['fromTeamId']][userConfig.language]
          : teams[playerAction['fromTeamId']] ? teams[playerAction['fromTeamId']]['name'] : playerAction['fromTeamName'])
        : undefined;

      const toTeamName: string | undefined = playerAction['toTeamId']
        ? (staticTeamKeys.includes(playerAction['toTeamId'])
          ? staticLanguageMap[playerAction['toTeamId']][userConfig.language]
          : teams[playerAction['toTeamId']] ? teams[playerAction['toTeamId']]['name'] : playerAction['toTeamName'])
        : undefined;

      if (fromTeamName && toTeamName) {
        return userName
          + ' '
          + staticLanguageMap['movedPlayerFrom'][userConfig.language]
          + ' '
          + fromTeamName
          + ' '
          + staticLanguageMap['to'][userConfig.language]
          + ' '
          + toTeamName;
      }
      else if (fromTeamName) {
        return userName
          + ' '
          + staticLanguageMap['removedPlayerFrom'][userConfig.language]
          + ' '
          + fromTeamName;
      }
      else if (toTeamName) {
        return userName
          + ' '
          + staticLanguageMap['addedPlayerTo'][userConfig.language]
          + ' '
          + toTeamName;
      }

      return actionType;
    }

    if (actionType === 'report') {

      const actionText = playerAction['reportType'] === 'Attachment'
        ? (staticLanguageMap['uploaded'][userConfig.language] + ' ' + staticLanguageMap['anAttachment'][userConfig.language])
        : (staticLanguageMap['leftA'][userConfig.language] + ' ' + staticLanguageMap[playerAction['reportType']][userConfig.language].toLocaleLowerCase());

      return userName
        + ' '
        + actionText;
    }

    return actionType;
  };


  const scrollToBottom = () => {
    const commentField = document.querySelector('.player-history-action-section');
    if (commentField) {
      commentField.scrollTop = commentField.scrollHeight;
    }
  };


  useEffect(() => {
    const timer = setTimeout(() => {
      setShowHistory(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);


  useEffect(() => {
    scrollToBottom();
  }, []);


  return (
    <div className='player-history-container'>

      <div className={'team-history-action-section player-history-action-section' + (showHistory ? ' team-history-action-section-show' : '')}>
        {history && history.map((playerAction, index) => {

          const actionType = playerAction['reportType'] ? 'report' : 'playerActivity';

          const isCurrentUser = userConfig !== null && playerAction['userEmail'] === userConfig.email;

          return (
            <div
              key={index}
              className='team-history-action player-history-action'
              style={{ marginTop: index === 0 ? 0 : undefined }}
            >

              {shouldShowDate(index) && (
                <div className={'team-history-date-row player-history-date-row' + (index === 0 ? ' team-history-first-date-row' : '')}>

                  {isCurrentUser && <div className='team-history-date-divider player-history-date-divider'>&nbsp;</div>}

                  <div className={'team-history-date player-history-date' + (isCurrentUser ? ' team-history-date-current-user' : '')}>
                    {getDateLocaleFormat(playerAction['date'].split('T')[0])}
                  </div>

                  {!isCurrentUser && <div className='team-history-date-divider player-history-date-divider'>&nbsp;</div>}

                </div>
              )}

              <div
                className={
                  'team-history-info-text player-history-info-text'
                  + (isCurrentUser ? ' team-history-info-text-current-user' : '')
                  + (actionType === 'report' ? (isCurrentUser ? ' player-history-report-info-current-user' : ' player-history-report-info-other-user') : '')
                }
              >
                {getHistoryInfoText(playerAction, actionType, isCurrentUser)}

                {actionType === 'report' && (
                  <div
                    className={
                      'player-history-report-icon' +
                      (isCurrentUser ? ' player-history-report-icon-current-user' : ' player-history-report-icon-other-user')
                    }
                  >
                    {playerAction['reportType'] === 'ScoutingReport' && <PlagiarismIcon style={{ fontSize: 22, color: '#ffffffaa' }} />}
                    {playerAction['reportType'] === 'MatchReport' && <DescriptionIcon style={{ fontSize: 22, color: '#ffffffaa' }} />}
                    {playerAction['reportType'] === 'Comment' && <MessageIcon style={{ fontSize: 21, marginTop: 1, color: '#ffffffaa' }} />}
                    {playerAction['reportType'] === 'Attachment' && <PictureAsPdfIcon style={{ fontSize: 21, marginTop: 1, color: '#ffffffaa' }} />}
                  </div>
                )}
              </div>

            </div>
          );
        })}
      </div>

    </div>
  );
};
