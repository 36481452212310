import './../scouting.css';

import { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { teamsState } from '../../../recoil/atoms/teamsState';

import ReplayIcon from '@mui/icons-material/Replay';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
// import DownloadIcon from '@mui/icons-material/Download';
// import UploadIcon from '@mui/icons-material/Upload';
// import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
// import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
// import SaveIcon from '@mui/icons-material/Save';
// import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
// import CloudUploadIcon from '@mui/icons-material/CloudUpload';
// import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

import { DropDownSelect } from '../../../components/input/DropDownSelect';
import { staticLanguageMap } from '../../../../common/static/staticLanguageMap';
import { positionOptionsDatabase, minuteAbsoluteKeys, minutePercentageKeys, ageKeys, contractKeys } from '../../../static/propertyValues';
import { FlexibleJsonMapping, Iteration } from '../../../types';
import { Dialog } from '@mui/material';
import { SaveOrLoadSearchModal } from '../../../components/modals/SaveOrLoadSearchModal';
import { saveUserSearch } from '../../../services/firestore/settings';
import { CountryDropDown } from '../../../components/input/CountryDropDown';
import { RatingDropDown, RatingRequirement } from '../../../components/input/RatingDropDown';
import { IterationDropDown } from '../../../components/input/IterationDropDown';
import { MetricDropDown } from '../../../components/input/MetricDropDown';


interface SeasonScoutingFiltersProps {
  selectedMinutes: string[];
  setSelectedMinutes: (value: string[]) => void;
  selectedMaxAge: string[];
  setSelectedMaxAge: (value: string[]) => void;
  selectedContractStatus: string[];
  setSelectedContractStatus: (value: string[]) => void;
  selectedPositions: string[];
  setSelectedPositions: (value: string[]) => void;
  selectedIterations: Iteration[];
  setSelectedIterations: (value: Iteration[]) => void;
  selectedOrderBy: string[];
  setSelectedOrderBy: (value: string[]) => void;

  selectedRatings: RatingRequirement[];
  setSelectedRatings: (value: RatingRequirement[]) => void;
  selectedNationalities: string[];
  setSelectedNationalities: (value: string[]) => void;
  selectedLogisticTeams: string[];
  setSelectedLogisticTeams: (value: string[]) => void;

  isContractStatusToggled: boolean;
  setIsContractStatusToggled: (value: boolean) => void;
  isPositionsToggled: boolean;
  setIsPositionsToggled: (value: boolean) => void;

  isAnyFilterExpandedToggle: boolean;
  setIsAnyFilterExpandedToggle: (value: boolean) => void;
  isAdvancedFiltersExpanded: boolean;
  handleAdvancedFiltersExpansion: () => void;

  handleFilterUnexpansion: (filter: string) => void;
  handleSearchReset: () => void;
  setSearchWasLoaded: (value: boolean) => void;
}

export const SeasonScoutingFilters: React.FC<SeasonScoutingFiltersProps> = ({
  selectedMinutes,
  setSelectedMinutes,
  selectedMaxAge,
  setSelectedMaxAge,
  selectedContractStatus,
  setSelectedContractStatus,
  selectedPositions,
  setSelectedPositions,
  selectedIterations,
  setSelectedIterations,
  selectedOrderBy,
  setSelectedOrderBy,

  selectedRatings,
  setSelectedRatings,
  selectedNationalities,
  setSelectedNationalities,
  selectedLogisticTeams,
  setSelectedLogisticTeams,

  isContractStatusToggled,
  setIsContractStatusToggled,
  isPositionsToggled,
  setIsPositionsToggled,

  isAnyFilterExpandedToggle,
  setIsAnyFilterExpandedToggle,
  isAdvancedFiltersExpanded,
  handleAdvancedFiltersExpansion,

  handleFilterUnexpansion,
  handleSearchReset,
  setSearchWasLoaded,
}) => {

  const userConfig = useRecoilValue(userConfigState);
  const teams = useRecoilValue(teamsState);

  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  const [modalTypeOpen, setModalTypeOpen] = useState<string | undefined>(undefined);

  const [isMinutesExpanded, setIsMinutesExpanded] = useState(false);
  const [isMaxAgeExpanded, setIsMaxAgeExpanded] = useState(false);
  const [isContractStatusExpanded, setIsContractStatusExpanded] = useState(false);
  const [isPositionsExpanded, setIsPositionsExpanded] = useState(false);
  const [isIterationsExpanded, setIsIterationsExpanded] = useState(false);
  const [isOrderByExpanded, setIsOrderByExpanded] = useState(false);

  const [isRatingsExpanded, setIsRatingsExpanded] = useState(false);
  const [isNationalitiesExpanded, setIsNationalitiesExpanded] = useState(false);
  const [isLogisticTeamsExpanded, setIsLogisticTeamsExpanded] = useState(false);

  const [anySkillcornerFiltersSelected, setAnySkillcornerFiltersSelected] = useState(false);
  const [anyIterationWithoutSkillcornerSelected, setAnyIterationWithoutSkillcornerSelected] = useState(false);


  const handleAdvancedFiltersClick = () => {
    const filterIcon = document.getElementById('filter-icon');
    if (filterIcon) {
      filterIcon.style.transition = '0ms';
      handleAdvancedFiltersExpansion();
      filterIcon.style.transition = '125ms';
    }
  };


  const handleResetFiltersClick = () => {
    handleSearchReset();

    setSelectedMinutes([]);
    setSelectedMaxAge([]);
    setSelectedContractStatus([]);
    setSelectedPositions([]);
    setSelectedIterations([]);
    setSelectedOrderBy(['club_rating']);

    setSelectedRatings([]);
    setSelectedNationalities([]);
    setSelectedLogisticTeams([]);

    setIsContractStatusToggled(false);
    setIsPositionsToggled(false);

    setAnySkillcornerFiltersSelected(false);
    setAnyIterationWithoutSkillcornerSelected(false);
  };


  const saveSearch = async (searchName: string) => {
    if (!userConfig) return;

    const search: FlexibleJsonMapping = {
      'searchName': searchName,
      'searchType': 'seasonScouting',
    };

    if (selectedMinutes.length > 0) search['minMinutes'] = selectedMinutes;
    if (selectedMaxAge.length > 0) search['maxAge'] = selectedMaxAge;
    if (selectedContractStatus.length > 0) search['contractStatus'] = selectedContractStatus;
    if (selectedPositions.length > 0) search['positions'] = selectedPositions;
    if (selectedIterations.length > 0) search['iterations'] = selectedIterations;
    if (selectedOrderBy.length > 0) search['orderBy'] = selectedOrderBy;

    if (selectedRatings.length > 0) search['ratings'] = selectedRatings;
    if (selectedNationalities.length > 0) search['nationalities'] = selectedNationalities;
    if (selectedLogisticTeams.length > 0) search['logisticTeams'] = selectedLogisticTeams;

    if (isContractStatusToggled) search['isContractStatusToggled'] = true;
    if (isPositionsToggled) search['isPositionsToggled'] = true;

    saveUserSearch(search, userConfig.email, userConfig.club);
  };


  const loadSearch = (savedSearch: FlexibleJsonMapping) => {
    setSelectedMinutes(savedSearch['minMinutes'] ? savedSearch['minMinutes'] : []);
    setSelectedMaxAge(savedSearch['maxAge'] ? savedSearch['maxAge'] : []);
    setSelectedContractStatus(savedSearch['contractStatus'] ? savedSearch['contractStatus'] : []);
    setSelectedPositions(savedSearch['positions'] ? savedSearch['positions'] : []);
    setSelectedIterations(savedSearch['iterations'] ? savedSearch['iterations'] : []);
    setSelectedOrderBy(savedSearch['orderBy'] ? savedSearch['orderBy'] : []);

    setSelectedRatings(savedSearch['ratings'] ? savedSearch['ratings'] : []);
    setSelectedNationalities(savedSearch['nationalities'] ? savedSearch['nationalities'] : []);
    setSelectedLogisticTeams(savedSearch['logisticTeams'] ? savedSearch['logisticTeams'] : []);

    if (savedSearch['isContractStatusToggled']) setIsContractStatusToggled(true);
    if (savedSearch['isPositionsToggled']) setIsPositionsToggled(true);

    setIsAnyFilterExpandedToggle(false);
    setSearchWasLoaded(true);
  };


  useEffect(() => {
    if (!isAnyFilterExpandedToggle) {
      setIsMinutesExpanded(false);
      setIsMaxAgeExpanded(false);
      setIsContractStatusExpanded(false);
      setIsPositionsExpanded(false);
      setIsIterationsExpanded(false);
      setIsOrderByExpanded(false);

      setIsRatingsExpanded(false);
      setIsNationalitiesExpanded(false);
      setIsLogisticTeamsExpanded(false);
    }
    setIsAnyFilterExpandedToggle(true);
  }, [isAnyFilterExpandedToggle, setIsAnyFilterExpandedToggle]);


  useEffect(() => {
    if (!isAdvancedFiltersExpanded) {
      setShowAdvancedFilters(false);
    }
    else {
      const timer = setTimeout(() => {
        setShowAdvancedFilters(true);
      }, 200);

      return () => clearTimeout(timer);
    }
  }, [isAdvancedFiltersExpanded]);


  useEffect(() => {
    if (!isMinutesExpanded) {
      handleFilterUnexpansion('minutes');
    }
  }, [isMinutesExpanded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isMaxAgeExpanded) {
      handleFilterUnexpansion('maxAge');
    }
  }, [isMaxAgeExpanded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isContractStatusExpanded) {
      handleFilterUnexpansion('contractStatus');
    }
  }, [isContractStatusExpanded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isPositionsExpanded) {
      handleFilterUnexpansion('positions');
    }
  }, [isPositionsExpanded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isIterationsExpanded) {
      handleFilterUnexpansion('iterations');
    }
  }, [isIterationsExpanded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isOrderByExpanded) {
      handleFilterUnexpansion('orderBy');
    }
  }, [isOrderByExpanded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isRatingsExpanded) {
      handleFilterUnexpansion('ratings');
    }
  }, [isRatingsExpanded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isNationalitiesExpanded) {
      handleFilterUnexpansion('nationalities');
    }
  }, [isNationalitiesExpanded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isLogisticTeamsExpanded) {
      handleFilterUnexpansion('logisticTeams');
    }
  }, [isLogisticTeamsExpanded]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div className='filter-section'>

      <Dialog
        open={modalTypeOpen !== undefined}
        onClose={() => setModalTypeOpen(undefined)}
        PaperProps={{ sx: { borderRadius: '0px' } }}
      >
        <SaveOrLoadSearchModal
          modalType={modalTypeOpen}
          searchType={'seasonScouting'}
          closeModal={() => setModalTypeOpen(undefined)}
          saveSearch={saveSearch}
          loadSearch={loadSearch}
        />
      </Dialog>

      <div className='filter-section-drop-down-title season-filter-section-minutes'>
        {userConfig ? staticLanguageMap['minMinutesPlayed'][userConfig.language] : ''}
      </div>
      <div className='filter-section-drop-down season-filter-section-minutes'>
        <DropDownSelect
          id={'season-filter-section-minutes-drop-down'}
          dropDownOptions={minuteAbsoluteKeys}
          isDropDownExpanded={isMinutesExpanded}
          setIsDropDownExpanded={setIsMinutesExpanded}
          selectedOptions={selectedMinutes}
          setSelectedOptions={setSelectedMinutes}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          marginBetweenOptions={3}
          emptyBackgroundId={'season-scouting-empty-background'}
          maxHeight={'80vh'}
          dropDownSecondCategoryOptions={minutePercentageKeys}
        />
      </div>

      <div className='filter-section-drop-down-title season-filter-section-age'>
        {userConfig ? staticLanguageMap['maxAge'][userConfig.language] : ''}
      </div>
      <div className='filter-section-drop-down season-filter-section-age'>
        <DropDownSelect
          id={'season-filter-section-age-drop-down'}
          dropDownOptions={ageKeys}
          isDropDownExpanded={isMaxAgeExpanded}
          setIsDropDownExpanded={setIsMaxAgeExpanded}
          selectedOptions={selectedMaxAge}
          setSelectedOptions={setSelectedMaxAge}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          marginBetweenOptions={3}
          emptyBackgroundId={'season-scouting-empty-background'}
          maxHeight={'84vh'}
        />
      </div>

      <div className='filter-section-drop-down-title season-filter-section-contract'>
        {userConfig ? staticLanguageMap['contractStatus'][userConfig.language] : ''}
      </div>
      <div className='filter-section-drop-down season-filter-section-contract'>
        <DropDownSelect
          id={'season-filter-section-contract-drop-down'}
          dropDownOptions={contractKeys}
          isDropDownExpanded={isContractStatusExpanded}
          setIsDropDownExpanded={setIsContractStatusExpanded}
          selectedOptions={selectedContractStatus}
          setSelectedOptions={setSelectedContractStatus}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          marginBetweenOptions={3}
          emptyBackgroundId={'season-scouting-empty-background'}
          toggleText={'includeUnknownContractStatus'}
          isToggled={isContractStatusToggled}
          setIsToggled={setIsContractStatusToggled}
        />
      </div>

      <div className='filter-section-drop-down-title season-filter-section-positions'>
        {userConfig ? staticLanguageMap['positions'][userConfig.language] : ''}
      </div>
      <div className='filter-section-drop-down season-filter-section-positions'>
        <DropDownSelect
          id={'season-filter-section-positions-drop-down'}
          dropDownOptions={positionOptionsDatabase}
          isDropDownExpanded={isPositionsExpanded}
          setIsDropDownExpanded={setIsPositionsExpanded}
          selectedOptions={selectedPositions}
          setSelectedOptions={setSelectedPositions}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          marginBetweenOptions={3}
          emptyBackgroundId={'season-scouting-empty-background'}
          multiSelect={true}
          toggleText={'includeSecondaryPosition'}
          isToggled={isPositionsToggled}
          setIsToggled={setIsPositionsToggled}
          incompatibleOptions={'GK'}
          disabledOptions={anySkillcornerFiltersSelected ? ['GK'] : undefined}
        />
      </div>

      <div className='filter-section-drop-down-title season-filter-section-iterations'>
        {userConfig ? staticLanguageMap['seasons'][userConfig.language] : ''}
      </div>
      <div className='filter-section-drop-down season-filter-section-iterations'>
        <IterationDropDown
          id={'season-filter-section-iterations-drop-down'}
          isDropDownExpanded={isIterationsExpanded}
          setIsDropDownExpanded={setIsIterationsExpanded}
          selectedIterations={selectedIterations}
          setSelectedIterations={setSelectedIterations}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          marginBetweenOptions={3}
          emptyBackgroundId={'season-scouting-empty-background'}
          maxHeight={'84vh'}
          anySkillcornerFiltersSelected={anySkillcornerFiltersSelected}
          setAnyIterationWithoutSkillcornerSelected={setAnyIterationWithoutSkillcornerSelected}
        />
      </div>

      <div className='filter-section-drop-down-title season-filter-section-order-by'>
        {userConfig ? staticLanguageMap['orderBy'][userConfig.language] : ''}
      </div>
      <div className='filter-section-drop-down season-filter-section-order-by'>
        <MetricDropDown
          id={'season-filter-section-order-by-drop-down'}
          isDropDownExpanded={isOrderByExpanded}
          setIsDropDownExpanded={setIsOrderByExpanded}
          selectedOptions={selectedOrderBy}
          setSelectedOptions={setSelectedOrderBy}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          marginBetweenOptions={3}
          emptyBackgroundId={'season-scouting-empty-background'}
          maxHeight={'84vh'}
          forceSelection={true}
          isGoalkeeperSelected={selectedPositions.includes('GK')}
          anyIterationWithoutSkillcornerSelected={anyIterationWithoutSkillcornerSelected}
          setAnySkillcornerFiltersSelected={setAnySkillcornerFiltersSelected}
        />
      </div>

      <div
        className='filter-section-icon filter-section-left-icon'
        onClick={() => handleResetFiltersClick()}
        title={userConfig ? staticLanguageMap['resetFilters'][userConfig.language] : undefined}
      >
        <ReplayIcon style={{ fontSize: 20 }} />
      </div>

      <div
        id='filter-icon'
        className='filter-section-icon filter-section-right-icon'
        onClick={() => handleAdvancedFiltersClick()}
        title={userConfig ? staticLanguageMap['moreFilters'][userConfig.language] : undefined}
      >
        {!isAdvancedFiltersExpanded && <FilterAltIcon style={{ fontSize: 20, marginTop: 1 }} />}
        {isAdvancedFiltersExpanded && <CloseIcon style={{ fontSize: 20 }} />}
      </div>

      {isAdvancedFiltersExpanded && showAdvancedFilters && (
        <div className='filter-section-advanced-filter-section'>

          <div className='filter-section-drop-down-title season-filter-section-ratings'>
            {userConfig ? staticLanguageMap['ratingRequirements'][userConfig.language] : ''}
          </div>
          <div className='filter-section-drop-down season-filter-section-ratings'>
            <RatingDropDown
              id={'season-filter-section-ratings-drop-down'}
              selectedRatings={selectedRatings}
              setSelectedRatings={setSelectedRatings}
              isDropDownExpanded={isRatingsExpanded}
              setIsDropDownExpanded={setIsRatingsExpanded}
              defaultDropDownText={'select'}
              defaultDropDownTextColor={'#00000080'}
              marginBetweenOptions={3}
              emptyBackgroundId={'season-scouting-empty-background'}
              maxHeight={'75vh'}
              isGoalkeeperSelected={selectedPositions.includes('GK')}
              anyIterationWithoutSkillcornerSelected={anyIterationWithoutSkillcornerSelected}
              setAnySkillcornerFiltersSelected={setAnySkillcornerFiltersSelected}
            />
          </div>

          <div className='filter-section-drop-down-title season-filter-section-nationalities'>
            {userConfig ? staticLanguageMap['nationalities'][userConfig.language] : ''}
          </div>
          <div className='filter-section-drop-down season-filter-section-nationalities'>
            <CountryDropDown
              id={'season-filter-section-nationalities-drop-down'}
              isDropDownExpanded={isNationalitiesExpanded}
              setIsDropDownExpanded={setIsNationalitiesExpanded}
              selectedCountryCodes={selectedNationalities}
              setSelectedCountryCodes={setSelectedNationalities}
              defaultDropDownText={'select'}
              defaultDropDownTextColor={'#00000080'}
              marginBetweenOptions={3}
              emptyBackgroundId={'season-scouting-empty-background'}
              maxHeight={'75vh'}
            />
          </div>

          <div className='filter-section-drop-down-title season-filter-section-logistic-teams'>
            {userConfig ? staticLanguageMap['logisticTeams'][userConfig.language] : ''}
          </div>
          <div className='filter-section-drop-down season-filter-section-logistic-teams'>
            <DropDownSelect
              id={'season-filter-section-logistic-teams-drop-down'}
              dropDownOptions={teams ? Object.values(teams).map((team: FlexibleJsonMapping) => team.name) : []}
              isDropDownExpanded={isLogisticTeamsExpanded}
              setIsDropDownExpanded={setIsLogisticTeamsExpanded}
              selectedOptions={selectedLogisticTeams}
              setSelectedOptions={setSelectedLogisticTeams}
              defaultDropDownText={'select'}
              defaultDropDownTextColor={'#00000080'}
              marginBetweenOptions={3}
              emptyBackgroundId={'season-scouting-empty-background'}
              maxHeight={'75vh'}
              multiSelect={true}
            />
          </div>

          <div
            className='filter-section-icon filter-section-left-icon'
            onClick={() => setModalTypeOpen('saveSearch')}
            title={userConfig ? staticLanguageMap['saveSearch'][userConfig.language] : undefined}
          >
            <SaveAsIcon style={{ fontSize: 20, marginTop: -1 }} />
          </div>

          <div
            className='filter-section-icon filter-section-right-icon'
            onClick={() => setModalTypeOpen('loadSearch')}
            title={userConfig ? staticLanguageMap['loadSearch'][userConfig.language] : undefined}
          >
            <ArrowCircleDownIcon style={{ fontSize: 20 }} />
          </div>
        </div>
      )}

    </div>
  );
};
