import '../tables.css';

import LockIcon from '@mui/icons-material/Lock';
import BlockIcon from '@mui/icons-material/Block';

import { getMonthsLeftOfContract, getPlayerNameWithMaxLength, getShortPlayerName, getShortestPlayerName, getYearShort } from '../../../utils/playerUtils';
import { CellProps } from 'react-table';
import { FlexibleJsonMapping } from '../../../types';
import { countryCodeToCountryInfo } from '../../../static/countries';
import { LazyImage } from '../../../../common/components/LazyImage';
import { getRoleIcon, getTeamIcon } from '../../../utils/iconUtils';
import { getAgeIndexEquivalent, getContractExpirationIndexEquivalent, getIndexColor } from '../../../utils/colorUtils';


const renderInfoCell = (disablePlayersWithoutEventData: boolean, isGoalkeeper?: boolean) => {
  const InfoCellRenderer = ({ row }: FlexibleJsonMapping) => {
    return <InfoCell row={row} disablePlayersWithoutEventData={disablePlayersWithoutEventData} isGoalkeeper={isGoalkeeper} />;
  };

  InfoCellRenderer.displayName = 'InfoCellRenderer';
  return InfoCellRenderer;
};


const InfoCell: React.FC<{ row: FlexibleJsonMapping, disablePlayersWithoutEventData: boolean, isGoalkeeper?: boolean }> = ({
  row,
  disablePlayersWithoutEventData,
  isGoalkeeper
}) => {

  const disablePlayer = (disablePlayersWithoutEventData && !row.original['event_data_available'])
    || (isGoalkeeper !== undefined && (isGoalkeeper === (row.original['primary_position'] !== 'GK')));

  return (
    <div className='player-simple-table-info-cell'>

      <LazyImage
        src={row.original.image_url ?? 'https://media.api-sports.io/football/players/0.png'}
        alt=''
        imageClassName='player-simple-table-info-cell-image'
        imageContainerClassName='player-simple-table-info-cell-image-container'
      />

      <div className='player-simple-table-info-cell-column'>

        <div className='player-simple-table-info-cell-row'>
          <div className='player-simple-table-info-cell-name' style={{ color: disablePlayer ? '#4e4e4e' : 'none' }}>
            {getPlayerNameWithMaxLength(row.original.fullname, 16)}
          </div>

          {row.original.country_code in countryCodeToCountryInfo && (
            <img
              className='player-simple-table-info-cell-flag'
              src={countryCodeToCountryInfo[row.original.country_code]?.flagUrl}
              alt=''
              draggable={false}
            />
          )}
        </div>

        <div className='player-simple-table-info-cell-row'>
          {row.original.club?.logo_url && (
            <div className='player-simple-table-club-logo-container'>
              <img
                className='player-simple-table-info-cell-club-logo'
                src={row.original.club?.logo_url}
                alt=''
                draggable={false}
              />
            </div>
          )}
          {row.original.club?.name && (
            <div className='player-simple-table-info-cell-info' style={{ color: disablePlayer ? '#4e4e4e' : 'none' }}>
              {row.original.club?.name}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};


const ImageCell: React.FC<CellProps<FlexibleJsonMapping, string>> = ({ value }) => {
  return (
    <div className='player-simple-table-image-cell'>
      <img src={value ?? 'https://media.api-sports.io/football/players/0.png'} alt='' className='player-simple-table-image' style={{ height: 31 }} draggable={false} />
    </div>
  );
};


const SmallImageCell: React.FC<CellProps<FlexibleJsonMapping, string>> = ({ value }) => {
  return (
    <div className='player-simple-table-image-cell'>
      <img src={value ?? 'https://media.api-sports.io/football/players/0.png'} alt='' className='player-simple-table-image' style={{ height: 23 }} draggable={false} />
    </div>
  );
};


const renderValueCell = (
  property: string,
  valueTransformer?: (value: any) => any, // eslint-disable-line @typescript-eslint/no-explicit-any
  isColored?: boolean,
  isOwnTeam?: boolean,
  isAcademyTeam?: boolean,
  fontWeight?: number,
  isSmallCell?: boolean
) => {
  const ValueCellRenderer = ({ row }: FlexibleJsonMapping) => {
    return (
      <ValueCell
        row={row}
        property={property}
        valueTransformer={valueTransformer}
        isColored={isColored}
        isOwnTeam={isOwnTeam}
        isAcademyTeam={isAcademyTeam}
        fontWeight={fontWeight}
        isSmallCell={isSmallCell}
      />
    );
  };

  ValueCellRenderer.displayName = `ValueCellRenderer(${property})`;
  return ValueCellRenderer;
};


interface ValueCellProps {
  row: FlexibleJsonMapping;
  property: string;
  valueTransformer?: (value: any) => any; // eslint-disable-line @typescript-eslint/no-explicit-any
  isColored?: boolean;
  isOwnTeam?: boolean;
  isAcademyTeam?: boolean;
  fontWeight?: number;
  isSmallCell?: boolean;
}

const ValueCell: React.FC<ValueCellProps> = ({
  row,
  property,
  valueTransformer,
  isColored,
  isOwnTeam,
  isAcademyTeam,
  fontWeight,
  isSmallCell,
}) => {

  let value = row.original[property];

  if (valueTransformer) {
    value = valueTransformer(value);
  }

  if (isSmallCell) {
    return (
      <div className='player-simple-table-cell-container' style={{ height: 25 }}>
        <div className='player-simple-table-cell-fullname' style={{ fontSize: 12, fontWeight: 500 }}>{value}</div>
      </div>
    );
  }

  const className = property === 'fullname'
    ? 'player-simple-table-cell-fullname' + (isOwnTeam ? (' player-simple-table-cell-fullname-' + row.original.role) : '')
    : 'player-simple-table-cell-info';

  const backgroundColor = isColored
    ? (
      (property === 'age' || property === 'birth_date')
        ? getIndexColor(
          getAgeIndexEquivalent(row.original['age'], isAcademyTeam),
          69)
        : (property === 'contract_expiration'
          ? getIndexColor(
            getContractExpirationIndexEquivalent(row.original['contract_expiration']),
            69
          )
          : getIndexColor(value, 69))
    )
    : undefined;

  return (
    <div
      className='player-simple-table-cell-container'
      style={{ backgroundColor: backgroundColor }}
    >
      <div className={className} style={{ fontWeight: fontWeight ?? 500 }}>{value}</div>
    </div>
  );
};


const FlagCell: React.FC<CellProps<FlexibleJsonMapping, string>> = ({ value }) => {
  const flagUrl = value ? countryCodeToCountryInfo[value]?.flagUrl : undefined;

  if (!flagUrl) return <div>&nbsp;</div>;

  return (
    <div className='player-simple-table-cell-container'>
      <img
        className='player-simple-table-flag'
        src={flagUrl}
        alt=''
        draggable={false}
      />
    </div>
  );
};


const ClubLogoCell: React.FC<CellProps<FlexibleJsonMapping, string>> = ({ value }) => {

  if (!value) return <div>&nbsp;</div>;

  return (
    <div className='player-simple-table-cell-container'>
      <img
        className='player-simple-table-club-logo'
        src={value}
        alt=''
        draggable={false}
      />
    </div>
  );
};


const renderRoleAndTeamIconCell = (clubLightColor: string, useRoleIcon: boolean) => {
  const RoleAndTeamIconCellRenderer = ({ row }: FlexibleJsonMapping) => {
    if (useRoleIcon && (row.original.currentTeam === 'ownTeam' || row.original.currentTeam === 'academyTeam')) {
      return <RoleCell row={row} clubLightColor={clubLightColor} />;
    }
    return <IconCell row={row} />;
  };

  RoleAndTeamIconCellRenderer.displayName = 'RoleAndTeamIconCellRenderer';
  return RoleAndTeamIconCellRenderer;
};


const renderRoleCell = (clubLightColor: string) => {
  const RoleCellRenderer = ({ row }: FlexibleJsonMapping) => {
    return <RoleCell row={row} clubLightColor={clubLightColor} />;
  };

  RoleCellRenderer.displayName = 'RoleCellRenderer';
  return RoleCellRenderer;
};

const RoleCell: React.FC<{ row: FlexibleJsonMapping, clubLightColor: string }> = ({ row, clubLightColor }) => {
  const icon = getRoleIcon(row.original.role, '#353a48', undefined, undefined, clubLightColor);
  return (
    <div className='player-simple-table-cell-container'>
      {icon}
    </div>
  );
};


const renderIconCell = (disablePlayersWithoutEventData?: boolean, isGoalkeeper?: boolean) => {
  const IconCellRenderer = ({ row }: FlexibleJsonMapping) => {
    return <IconCell row={row} disablePlayersWithoutEventData={disablePlayersWithoutEventData} isGoalkeeper={isGoalkeeper} />;
  };

  IconCellRenderer.displayName = 'IconCellRenderer';
  return IconCellRenderer;
};

const IconCell: React.FC<{ row: FlexibleJsonMapping, disablePlayersWithoutEventData?: boolean, isGoalkeeper?: boolean }> = ({
  row,
  disablePlayersWithoutEventData,
  isGoalkeeper
}) => {

  if (disablePlayersWithoutEventData && !row.original['event_data_available']) {
    if (row.original['event_data_exists']) {
      return (
        <div className='player-simple-table-icon-container'>
          <LockIcon style={{ fontSize: 18, color: '#353a48' }} />
        </div>
      );
    }
    return (
      <div className='player-simple-table-icon-container'>
        <BlockIcon style={{ fontSize: 18, color: '#353a48' }} />
      </div>
    );
  }

  if (isGoalkeeper !== undefined && (isGoalkeeper === (row.original['primary_position'] !== 'GK'))) {
    return (
      <div className='player-simple-table-icon-container'>
        <BlockIcon style={{ fontSize: 18, color: '#353a48' }} />
      </div>
    );
  }

  const currentTeam = row.original.currentTeam;
  const currentTeamIcon = getTeamIcon(currentTeam, '#353a48', 18);

  if (currentTeamIcon) {
    return (
      <div className='player-simple-table-icon-container'>
        {currentTeamIcon}
      </div>
    );
  }

  return <div>&nbsp;</div>;
};


export const getPlayerSimpleColumns = (
  clubLightColor: string,
  tableType: string,
  teamMenu: FlexibleJsonMapping,
  disablePlayersWithoutEventData?: boolean,
  isGoalkeeper?: boolean
) => {

  if (tableType === 'addPlayerView') {
    return addPlayerTable(disablePlayersWithoutEventData ?? false, isGoalkeeper);
  }

  if (tableType === 'addPlayerSimpleViewTeamIcon') {
    return addPlayerSimpleViewTable(clubLightColor, false);
  }

  if (tableType === 'addPlayerSimpleViewRoleIcon') {
    return addPlayerSimpleViewTable(clubLightColor, true);
  }

  if (tableType === 'ownTeamPlanner') {
    return ownTeamPlannerTable;
  }

  if (tableType === 'archivedTeam') {
    return archivedTeamTable;
  }

  if (tableType === 'archiveReportInfo') {
    return archiveReportInfoTable;
  }

  if (tableType === 'bench') {
    return benchTable;
  }

  return teamViewPlayerTable(clubLightColor, tableType, teamMenu);
};


const teamViewPlayerTable = (clubLightColor: string, tableType: string, teamMenu: FlexibleJsonMapping) => {

  const columns = [
    {
      accessor: 'image_url',
      width: 32,
      Cell: ImageCell,
    },
    {
      accessor: 'fullname',
      width: teamMenu['shortNames'] ? 104 : 138,
      Cell: renderValueCell(
        'fullname',
        teamMenu['shortNames'] ? getShortestPlayerName : getShortPlayerName,
        false,
        tableType === 'ownTeam'
      ),
    },
  ];

  if (teamMenu['role']) {
    columns.push({
      accessor: 'role',
      width: 21,
      Cell: renderRoleCell(clubLightColor),
    });
  }

  if (teamMenu['country_code']) {
    columns.push({
      accessor: 'country_code',
      width: 21,
      Cell: FlagCell,
    });
  }

  if (teamMenu['club_logo_url']) {
    columns.push({
      accessor: 'club_logo_url',
      width: 21,
      Cell: ClubLogoCell,
    });
  }

  if (teamMenu['birth_date']) {
    columns.push({
      accessor: 'birth_date',
      width: 21,
      Cell: renderValueCell(
        'birth_date',
        getYearShort,
        teamMenu.colored === 'birth_date',
        undefined,
        tableType === 'academyTeam'
      ),
    });
  }

  if (teamMenu['age']) {
    columns.push({
      accessor: 'age',
      width: 21,
      Cell: renderValueCell(
        'age',
        (value: string) => value ? Math.round(Number(value)) : undefined,
        teamMenu.colored === 'age',
        undefined,
        tableType === 'academyTeam'
      ),
    });
  }

  if (teamMenu['contract_expiration']) {
    columns.push({
      accessor: 'contract_expiration',
      width: 21,
      Cell: renderValueCell(
        'contract_expiration',
        getMonthsLeftOfContract,
        teamMenu.colored === 'contract_expiration'
      ),
    });
  }

  if (teamMenu['club_index']) {
    columns.push({
      accessor: 'club_index',
      width: 26,
      Cell: renderValueCell(
        'club_index',
        (value: string) => value !== undefined && value !== null ? Math.round(Number(value) * 10) / 10 : undefined,
        teamMenu.colored === 'club_index'
      ),
    });
  }

  if (teamMenu['skill_index']) {
    columns.push({
      accessor: 'skill_index',
      width: 26,
      Cell: renderValueCell(
        'skill_index',
        (value: string) => value !== undefined && value !== null ? Math.round(Number(value) * 10) / 10 : undefined,
        teamMenu.colored === 'skill_index'
      ),
    });
  }

  if (shouldAddPaddingColumn(teamMenu)) {
    columns.push({
      accessor: 'id',
      width: 3,
      Cell: () => null,
    });
  }

  return columns;
};


const addPlayerTable = (disablePlayersWithoutEventData: boolean, isGoalkeeper?: boolean) => [
  {
    accessor: 'id',
    width: 199,
    Cell: renderInfoCell(disablePlayersWithoutEventData, isGoalkeeper),
  },
  {
    accessor: 'currentTeam',
    width: 28,
    Cell: renderIconCell(disablePlayersWithoutEventData, isGoalkeeper),
  },
];


const addPlayerSimpleViewTable = (clubLightColor: string, useRoleIcon: boolean) => [
  {
    accessor: 'image_url',
    width: 32,
    Cell: ImageCell,
  },
  {
    accessor: 'fullname',
    width: 156,
    Cell: renderValueCell('fullname', getShortPlayerName, false, false, false, 400),
  },
  {
    accessor: 'id',
    width: 32,
    Cell: renderRoleAndTeamIconCell(clubLightColor, useRoleIcon),
  },
];


const ownTeamPlannerTable = [
  {
    accessor: 'image_url',
    width: 25,
    Cell: SmallImageCell,
  },
  {
    accessor: 'fullname',
    width: 115,
    Cell: renderValueCell('fullname', getShortestPlayerName, undefined, undefined, undefined, undefined, true),
  },
];


const archivedTeamTable = [
  {
    accessor: 'image_url',
    width: 32,
    Cell: ImageCell,
  },
  {
    accessor: 'fullname',
    width: 138,
    Cell: renderValueCell('fullname', getShortPlayerName),
  },
];


const archiveReportInfoTable = [
  {
    accessor: 'image_url',
    width: 34,
    Cell: ImageCell,
  },
  {
    accessor: 'fullname',
    width: 180,
    Cell: renderValueCell('fullname', getShortPlayerName),
  },
];


const benchTable = [
  {
    accessor: 'image_url',
    width: 32,
    Cell: ImageCell,
  },
  {
    accessor: 'fullname',
    width: 148,
    Cell: renderValueCell('fullname', getShortPlayerName),
  },
];


// add padding to table if no numeric columns are shown
const shouldAddPaddingColumn = (menu: FlexibleJsonMapping) => {
  return !(menu['club_index'] || menu['skill_index'] || menu['age'] || menu['birth_date'] || menu['contract_expiration']);
};


export const getEmptyTableWidth = (tableType: string, teamMenu: FlexibleJsonMapping) => {

  let baseWidth = 170;

  if (tableType === 'ownTeam' || tableType === 'academyTeam' || tableType === 'team') {
    if (teamMenu.shortNames) baseWidth -= 34;
    if (teamMenu.role) baseWidth += 21;
    if (teamMenu.country_code) baseWidth += 21;
    if (teamMenu.club_logo_url) baseWidth += 21;
    if (teamMenu.birth_date) baseWidth += 21;
    if (teamMenu.age) baseWidth += 21;
    if (teamMenu.contract_expiration) baseWidth += 21;
    if (teamMenu.club_index) baseWidth += 26;
    if (teamMenu.skill_index) baseWidth += 26;
    if (shouldAddPaddingColumn(teamMenu)) baseWidth += 3;
  }

  else if (tableType === 'bench') {
    baseWidth = 180;
  }

  else if (tableType === 'ownTeamPlanner') {
    baseWidth = 140;
  }

  return baseWidth;
};
