import '../../platform.css';
import './settings.css';

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { AuthContextType, useAuthContext } from '../../../common/contexts/AuthContext';
import { updateUserConfig } from '../../services/firestore/users';

import EditNoteIcon from '@mui/icons-material/EditNote';
import ReplayIcon from '@mui/icons-material/Replay';
import LogoutIcon from '@mui/icons-material/Logout';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import NO from 'country-flag-icons/react/3x2/NO';
import GB from 'country-flag-icons/react/3x2/GB';

import { staticLanguageMap } from '../../../common/static/staticLanguageMap';
import { InputField } from '../../components/input/InputField';
import { OwnTeamBackgoundSvg } from '../../svg/OwnTeamBackgroundSvg';
import { Dialog } from '@mui/material';
import { AccountModal } from '../../components/modals/AccountModal';


interface AccountProps {
  noAccessView?: boolean;
  maintenanceMessage?: string; // if provided, the platform is under maintenance
}

export const Account: React.FC<AccountProps> = ({ noAccessView, maintenanceMessage }) => {

  const { logout, resetPassword } = useAuthContext() as AuthContextType;

  const navigate = useNavigate();

  const userConfig = useRecoilValue(userConfigState);

  const [nameString, setNameString] = useState('');
  const [isEditName, setIsEditName] = useState(false);
  const [message, setMessage] = useState('');

  const isFokusAccount = userConfig?.email.split('@')[0] === 'fokus';
  const [isEditFokusAccessModalOpen, setIsEditFokusAccessModalOpen] = useState(false);


  const handleSignOut = async () => {
    await logout();
    navigate('/');
  };


  const handleResetPassword = async () => {
    if (userConfig) {
      setMessage('');

      await resetPassword(userConfig.email);

      setMessage('emailSent');
    }
  };


  const handleFlagClick = (language: string) => {
    if (userConfig && language !== userConfig.language) {
      updateUserConfig(userConfig.email, 'language', language);
    }
  };


  const onChangeSearchField = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length < 20) {
      setNameString(event.target.value);
    }
  };


  const submitNameEdit = () => {
    if (nameString.length > 0 && userConfig) {
      updateUserConfig(userConfig.email, 'name', nameString);
      setIsEditName(false);
      setNameString('');
    }
  };


  const onKeyDownSearchField = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      submitNameEdit();
    }
  };


  const cancelNameEdit = () => {
    setIsEditName(false);
    setNameString('');
  };


  const isNoAccessOrMaintenanceView = noAccessView || maintenanceMessage;


  return (
    <div className={'settings-view-container' + (isNoAccessOrMaintenanceView ? ' settings-view-container-no-access' : '')}>

      <Dialog
        open={isEditFokusAccessModalOpen}
        onClose={() => setIsEditFokusAccessModalOpen(false)}
        PaperProps={{ sx: { borderRadius: '0px' } }}>
        <AccountModal
          user={userConfig ?? undefined}
          closeModal={() => setIsEditFokusAccessModalOpen(false)}
          isFokusAccount={true}
        />
      </Dialog>

      <div className={isNoAccessOrMaintenanceView ? 'account-no-access-background' : 'own-team-background'}>
        <OwnTeamBackgoundSvg />
      </div>

      <div className='account-view-space'>&nbsp;</div>
      <div className='account-view-space'>&nbsp;</div>

      <div className='account-view-name-row'>
        {isFokusAccount && (
          <div className='account-view-fokus-menu' onClick={() => setIsEditFokusAccessModalOpen(true)}>
            <MoreVertIcon style={{ fontSize: 26 }} />
          </div>
        )}

        <div className='account-view-name'>
          {userConfig?.name ?? ''}
        </div>

        {!isEditName && !isNoAccessOrMaintenanceView && (
          <div className='account-view-edit-name-icon' onClick={() => setIsEditName(true)}>
            <EditNoteIcon style={{ fontSize: 20 }} />

            <div className='account-view-edit-name-hover-text'>
              {userConfig ? staticLanguageMap['editName'][userConfig.language] : ''}
            </div>
          </div>
        )}
      </div>

      <div className='account-view-email-row'>
        {userConfig?.email ?? ''}
      </div>

      <div className='account-view-edit-name-row'>
        {isEditName && (
          <div className='account-view-edit-name-input'>
            <InputField
              searchString={nameString}
              onChangeInputField={onChangeSearchField}
              onKeyDownInputField={onKeyDownSearchField}
              resetSearchString={() => setNameString('')}
              defaultInput={userConfig ? staticLanguageMap['provideName'][userConfig.language] : ''}
              showDefaultInput={true}
            />
            <div className='account-view-edit-name-input-icon account-view-edit-name-input-close-icon' onClick={() => cancelNameEdit()}>
              <CloseIcon style={{ fontSize: 22, marginTop: 1 }} />
            </div>
            <div className='account-view-edit-name-input-icon account-view-edit-name-input-check-icon' onClick={() => submitNameEdit()}>
              <CheckCircleOutlineIcon style={{ fontSize: 22 }} />
            </div>
          </div>
        )}
        &nbsp;
      </div>

      {noAccessView && (
        <div className='account-no-access-row'>
          {userConfig ? staticLanguageMap['noAccessInfo'][userConfig.language] : ''}
        </div>
      )}

      {maintenanceMessage && (
        <div className='account-no-access-row'>
          {maintenanceMessage}
        </div>
      )}

      <div className='account-view-space'>&nbsp;</div>

      <div className='account-view-language-section'>
        <div className='account-view-language-title'>
          {userConfig ? staticLanguageMap['language'][userConfig.language] : ''}
        </div>
        <div className='account-view-language-row'>
          <div
            className={'account-view-flag' + (userConfig?.language === 'no' ? ' account-view-flag-selected' : ' account-view-flag-not-selected')}
            onClick={() => handleFlagClick('no')}
          >
            <NO />
            <div className={'account-view-flag-overlay' + (userConfig?.language === 'no' ? '' : ' account-view-flag-overlay-not-selected')}>&nbsp;</div>
          </div>
          <div
            className={'account-view-flag' + (userConfig?.language === 'en' ? ' account-view-flag-selected' : ' account-view-flag-not-selected')}
            onClick={() => handleFlagClick('en')}
          >
            <GB />
            <div className={'account-view-flag-overlay' + (userConfig?.language === 'en' ? '' : ' account-view-flag-overlay-not-selected')}>&nbsp;</div>
          </div>
        </div>
      </div>

      {!isNoAccessOrMaintenanceView && <div className='account-view-space'>&nbsp;</div>}

      <div className='account-view-message-row'>
        {message !== '' && <CheckCircleIcon style={{ fontSize: 20, marginTop: -1, marginRight: 7 }} />}
        {message !== '' && userConfig ? staticLanguageMap[message][userConfig.language] : ''}
      </div>

      {!isNoAccessOrMaintenanceView && (
        <div className='account-logout-button' onClick={() => handleResetPassword()}>
          <div className='account-logout-button-text'>
            {userConfig ? staticLanguageMap['resetPassword'][userConfig.language] : ''}
          </div>
          <div className='account-logout-button-icon'>
            <ReplayIcon style={{ fontSize: 22 }} />
          </div>
        </div>
      )}

      <div className='account-view-space'>&nbsp;</div>

      <div className='account-logout-button' onClick={() => handleSignOut()}>
        <div className='account-logout-button-text'>
          {userConfig ? staticLanguageMap['logout'][userConfig.language] : ''}
        </div>
        <div className='account-logout-button-icon'>
          <LogoutIcon style={{ fontSize: 22 }} />
        </div>
      </div>

      <div className='account-view-space'>&nbsp;</div>
      <div className='account-view-space'>&nbsp;</div>

    </div>
  );
};
