import './playerView.css';

import 'react-circular-progressbar/dist/styles.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import { AuthContextType, useAuthContext } from '../../../common/contexts/AuthContext';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { selectedPlayerState } from '../../recoil/atoms/selectedPlayerState';
import { playerDetailsState } from '../../recoil/atoms/playerDetailsState';
import { competitionsState } from '../../recoil/atoms/competitionsState';
import { playersState } from '../../recoil/atoms/playersState';

import CloseIcon from '@mui/icons-material/Close';

import { ClubIteration, ClubIterationMap, FlexibleJsonMapping } from '../../types';
import { staticLanguageMap } from '../../../common/static/staticLanguageMap';
import { getClubColor, getClubContrastColor } from '../../utils/configUtils';
import { SelectPlayerView } from '../searchPlayers/SelectPlayerView';
import { countryCodeToCountryInfo } from '../../static/countries';
import { PlayerIterationDropDown } from '../input/PlayerIterationDropDown';
import { positionOptionsDatabase } from '../../static/propertyValues';
import { useAddPlayerDetails } from '../../recoil/hooks/addPlayerDetails';
import { CompareRadarPlot } from '../plots/CompareRadarPlot';
import {
  getAge,
  getDateLocaleFormat,
  getDisplayCompetition,
  getDisplayPosition,
  getPlayerPropertyValue,
  getShortPlayerName
} from '../../utils/playerUtils';


interface PlayerViewCompareProps {
  isGoalkeeper: boolean;
}

export const PlayerViewCompare: React.FC<PlayerViewCompareProps> = ({ isGoalkeeper }) => {

  const { currentUser } = useAuthContext() as AuthContextType;

  const userConfig = useRecoilValue(userConfigState);
  const competitions = useRecoilValue(competitionsState);
  const players = useRecoilValue(playersState);
  const playerDetails = useRecoilValue(playerDetailsState);
  const addPlayerDetails = useAddPlayerDetails();

  // selected player
  const selectedPlayer = useRecoilValue(selectedPlayerState);

  const [playerDetailsArray, setPlayerDetailsArray] = useState<ClubIteration[]>([]);
  const [selectedClubIterationIndex, setSelectedClubIterationIndex] = useState<number | undefined>(undefined);

  const [positionsPlayed, setPositionPlayed] = useState<string[]>([]);
  const [selectedPosition, setSelectedPosition] = useState<number | undefined>(undefined);
  const [selectedPositionKey, setSelectedPositionKey] = useState<string | undefined>(undefined);

  const [isIterationDropDownExpanded, setIsIterationDropDownExpanded] = useState<boolean>(false);


  // other player
  const [otherPlayerOverview, setOtherPlayerOverview] = useState<FlexibleJsonMapping | undefined>(undefined);
  const [otherPlayerConfidence, setOtherPlayerConfidence] = useState<number>(0);

  const [otherPlayerDetailsArray, setOtherPlayerDetailsArray] = useState<ClubIteration[]>([]);
  const [otherSelectedClubIterationIndex, setOtherSelectedClubIterationIndex] = useState<number | undefined>(undefined);

  const [otherPositionsPlayed, setOtherPositionPlayed] = useState<string[]>([]);
  const [otherSelectedPosition, setOtherSelectedPosition] = useState<number | undefined>(undefined);
  const [otherSelectedPositionKey, setOtherSelectedPositionKey] = useState<string | undefined>(undefined);

  const [isOtherIterationDropDownExpanded, setIsOtherIterationDropDownExpanded] = useState<boolean>(false);


  const handlePositionSelected = (position: string, index: number) => {
    setSelectedPositionKey(index === 0 ? 'overall' : position);
    setSelectedPosition(index);
  };


  const handleOtherPositionSelected = (position: string, index: number) => {
    setOtherSelectedPositionKey(index === 0 ? 'overall' : position);
    setOtherSelectedPosition(index);
  };


  const getOverviewIndex = (property: string) => {
    if (otherPlayerOverview && otherPlayerOverview['overall'] && otherPlayerOverview['overall'][property] !== undefined) {
      return Math.round(otherPlayerOverview['overall'][property] * 10) / 10;
    }
    return '-';
  };


  const removeDropDownExpansions = () => {
    setIsIterationDropDownExpanded(false);
    setIsOtherIterationDropDownExpanded(false);
  };


  const handleOtherPlayerCloseClick = () => {
    setOtherPlayerOverview(undefined);
    setOtherSelectedClubIterationIndex(undefined);
    setOtherSelectedPositionKey(undefined);
    setOtherSelectedPosition(undefined);
    setOtherPlayerDetailsArray([]);
  };


  const setDetailsArrayAndSelectedIndex = useCallback((
    details: ClubIterationMap,
    setDetailsArray: (array: ClubIteration[]) => void,
    setSelectedIteration: (value: number) => void
  ) => {

    const detailsArray: ClubIteration[] = [];

    Object.keys(details).forEach((clubIterationId) => {
      if (details[clubIterationId]['event_data_exists']) {
        detailsArray.push(details[clubIterationId]);
      }
    });

    detailsArray.sort((a, b) => b['plot_date'] && a['plot_date'] ? b['plot_date'].localeCompare(a['plot_date']) : 0);

    let anyLeagueWithMinutes = false;
    detailsArray.forEach((details, index) => {
      if (details['event_data_available'] && competitions) {
        if (
          !anyLeagueWithMinutes &&
          details['competition_id'] in competitions &&
          competitions[details['competition_id']].type === 'League' &&
          details['overall'] &&
          details['overall']['minutes_played'] > 0
        ) {
          setSelectedIteration(index);
          anyLeagueWithMinutes = true;
        }
        else if (
          !anyLeagueWithMinutes &&
          details['competition_id'] in competitions &&
          competitions[details['competition_id']].type === 'Cup' &&
          details['overall'] &&
          details['overall']['minutes_played'] > 0
        ) {
          setSelectedIteration(index);
        }
      }
    });

    setDetailsArray(detailsArray);
  }, [competitions]);


  const handleIterationDropDownClick = (iterationIndex: number | undefined) => {
    setSelectedPositionKey('overall');
    setSelectedPosition(0);
    setSelectedClubIterationIndex(iterationIndex);
  };


  const handleOtherIterationDropDownClick = (iterationIndex: number | undefined) => {
    setOtherSelectedPositionKey('overall');
    setOtherSelectedPosition(0);
    setOtherSelectedClubIterationIndex(iterationIndex);
  };


  useEffect(() => {
    if (playerDetailsArray.length === 0 && playerDetails && selectedPlayer && selectedPlayer.id in playerDetails) {
      const details = playerDetails[Number(selectedPlayer.id)];
      setDetailsArrayAndSelectedIndex(details, setPlayerDetailsArray, setSelectedClubIterationIndex);
    }

    if (otherPlayerDetailsArray.length === 0 && playerDetails && otherPlayerOverview && otherPlayerOverview.id in playerDetails) {
      const details = playerDetails[otherPlayerOverview.id];
      setDetailsArrayAndSelectedIndex(details, setOtherPlayerDetailsArray, setOtherSelectedClubIterationIndex);
    }
  }, [playerDetails, playerDetailsArray.length, selectedPlayer, otherPlayerDetailsArray.length, otherPlayerOverview, setDetailsArrayAndSelectedIndex]);


  useEffect(() => {
    if (otherPlayerOverview && playerDetails && !(otherPlayerOverview.id in playerDetails)) {
      addPlayerDetails(otherPlayerOverview.id, currentUser);
    }
  }, [currentUser, otherPlayerOverview, playerDetails]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (selectedClubIterationIndex === undefined) {
      setPositionPlayed([]);
    }
    else {
      const iterationData = playerDetailsArray[selectedClubIterationIndex];
      let positionsPlayed = positionOptionsDatabase
        .filter((position) => iterationData[position] && iterationData[position]['minutes_played'] > 0)
        .sort((a, b) => iterationData[b]['minutes_played'] - iterationData[a]['minutes_played']);

      if (positionsPlayed.length === 0 && playerDetailsArray[selectedClubIterationIndex]['primary_position']) {
        positionsPlayed = [playerDetailsArray[selectedClubIterationIndex]['primary_position']];
      }

      setPositionPlayed(positionsPlayed);
      if (positionsPlayed.length > 0) {
        setSelectedPositionKey('overall');
        setSelectedPosition(0);
      }
    }
  }, [selectedClubIterationIndex, playerDetailsArray]);


  useEffect(() => {
    if (otherSelectedClubIterationIndex === undefined || otherPlayerDetailsArray.length === 0) {
      setOtherPositionPlayed([]);
    }
    else {
      const iterationData = otherPlayerDetailsArray[otherSelectedClubIterationIndex];
      let positionsPlayed = positionOptionsDatabase
        .filter((position) => iterationData[position] && iterationData[position]['minutes_played'] > 0)
        .sort((a, b) => iterationData[b]['minutes_played'] - iterationData[a]['minutes_played']);

      if (positionsPlayed.length === 0 && otherPlayerDetailsArray[otherSelectedClubIterationIndex]['primary_position']) {
        positionsPlayed = [otherPlayerDetailsArray[otherSelectedClubIterationIndex]['primary_position']];
      }

      setOtherPositionPlayed(positionsPlayed);
      if (positionsPlayed.length > 0) {
        setOtherSelectedPositionKey('overall');
        setOtherSelectedPosition(0);
      }
    }
  }, [otherSelectedClubIterationIndex, otherPlayerDetailsArray]);


  useEffect(() => {
    const timer = setTimeout(() => {
      setOtherPlayerConfidence(otherPlayerOverview && otherPlayerOverview['overall'] ? otherPlayerOverview['overall']['confidence'] ?? 0 : 0);
    }, 100);

    return () => clearTimeout(timer);
  }, [otherPlayerOverview]);


  const clubColor = getClubColor(userConfig?.club ?? '');
  const clubContrastColor = getClubContrastColor(userConfig?.club ?? '');


  return (
    <div className='player-view-main-section'>

      {(isIterationDropDownExpanded || isOtherIterationDropDownExpanded) &&
        <div className='player-view-compare-empty-background' onClick={() => removeDropDownExpansions()}>&nbsp;</div>
      }

      <div className='player-view-compare-iteration-section'>

        <div className='player-view-compare-iteration-section-line' style={{ backgroundColor: clubColor }}>&nbsp;</div>
        <div
          className='player-view-compare-iteration-section-line player-view-compare-iteration-section-line-other-player'
          style={{ backgroundColor: clubContrastColor }}
        >
          &nbsp;
        </div>

        <div className='player-view-compare-iteration-player-section'>
          <div className='player-view-compare-iteration-player-section-title'>
            {selectedPlayer ? getShortPlayerName(selectedPlayer.fullname) : ''}
          </div>

          <div className='player-view-compare-iteration-drop-down'>
            <PlayerIterationDropDown
              id={'player-view-compare-iteration-drop-down'}
              dropDownOptions={playerDetailsArray}
              selectedOption={selectedClubIterationIndex}
              handleIterationClick={handleIterationDropDownClick}
              isDropDownExpanded={isIterationDropDownExpanded}
              setIsDropDownExpanded={setIsIterationDropDownExpanded}
              emptyBackgroundId={'player-view-compare-empty-background'}
              defaultDropDownText={'selectSeason'}
              defaultDropDownTextColor={'#00000080'}
              marginBetweenOptions={3}
              maxHeight={'70vh'}
            />
          </div>

          <div className='player-view-overview-positions-played player-view-compare-positions-played'>
            {(positionsPlayed.length > 1 ? ['all', ...positionsPlayed] : positionsPlayed).map((position, index) => {
              return (
                <div
                  key={index}
                  className={'player-view-overview-position' + (index === selectedPosition ? ' player-view-overview-position-selected' : '')}
                  style={{ border: '1px solid ' + (index === selectedPosition ? clubColor : 'transparent'), marginLeft: index > 0 ? '0.5vw' : undefined }}
                  onClick={() => handlePositionSelected(position, index)}
                >
                  {userConfig ? staticLanguageMap[position][userConfig.language] : ''}
                </div>
              );
            })}
          </div>

        </div>

        <div className='player-view-compare-iteration-section-divider'>&nbsp;</div>

        {otherPlayerOverview && (
          <div className='player-view-compare-iteration-player-section'>
            <div className='player-view-compare-iteration-player-section-title-container-other-player'>
              <div className='player-view-compare-iteration-player-section-title-other-player'>
                {getShortPlayerName(otherPlayerOverview.fullname)}
              </div>
            </div>

            <div className='player-view-compare-iteration-drop-down-other-player'>
              <PlayerIterationDropDown
                id={'player-view-compare-iteration-drop-down-other-player'}
                dropDownOptions={otherPlayerDetailsArray}
                selectedOption={otherSelectedClubIterationIndex}
                handleIterationClick={handleOtherIterationDropDownClick}
                isDropDownExpanded={isOtherIterationDropDownExpanded}
                setIsDropDownExpanded={setIsOtherIterationDropDownExpanded}
                emptyBackgroundId={'player-view-compare-empty-background'}
                defaultDropDownText={'selectSeason'}
                defaultDropDownTextColor={'#00000080'}
                marginBetweenOptions={3}
                maxHeight={'70vh'}
              />
            </div>

            <div className='player-view-overview-positions-played player-view-compare-positions-played'>
              {(otherPositionsPlayed.length > 1 ? ['all', ...otherPositionsPlayed] : otherPositionsPlayed).map((position, index) => {
                return (
                  <div
                    key={index}
                    className={'player-view-overview-position' + (index === otherSelectedPosition ? ' player-view-overview-position-selected' : '')}
                    style={{
                      border: '1px solid ' + (index === otherSelectedPosition ? clubContrastColor : 'transparent'),
                      marginLeft: index > 0 ? '0.5vw' : undefined
                    }}
                    onClick={() => handleOtherPositionSelected(position, index)}>
                    {userConfig ? staticLanguageMap[position][userConfig.language] : ''}
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {!otherPlayerOverview && (
          <div className='player-view-compare-iteration-player-section'>
            <div className='player-view-compare-iteration-player-section-no-player-selected'>
              {userConfig ? staticLanguageMap['noPlayerSelected'][userConfig.language] : ''}
            </div>
          </div>
        )}
      </div>

      <div className='player-view-compare-data-section'>
        <div className='player-view-compare-iteration-section-line' style={{ backgroundColor: clubColor }}>&nbsp;</div>
        <div
          className='player-view-compare-iteration-section-line player-view-compare-iteration-section-line-other-player'
          style={{ backgroundColor: clubContrastColor }}
        >
          &nbsp;
        </div>

        {playerDetailsArray.length > 0 && selectedClubIterationIndex !== undefined && selectedPositionKey !== undefined && (
          <div className='player-view-compare-data-info-column'>
            <div className='player-view-compare-data-info-column-entry'>
              <div className='player-view-compare-data-info-column-label'>
                {userConfig ? staticLanguageMap['team'][userConfig.language] : ''}
              </div>
              <div className='player-view-compare-data-info-column-value' style={{ marginTop: 4 }}>
                {playerDetailsArray[selectedClubIterationIndex]['club']?.logo_url && (
                  <img
                    className={
                      'player-view-compare-data-info-column-club-logo' +
                      (playerDetailsArray[selectedClubIterationIndex]['club']?.is_national_team ? ' player-view-compare-flag' : '')
                    }
                    src={playerDetailsArray[selectedClubIterationIndex]['club']?.logo_url}
                    alt=''
                    draggable={false}
                  />
                )}
                {playerDetailsArray[selectedClubIterationIndex]['club']?.is_national_team &&
                  playerDetailsArray[selectedClubIterationIndex]['club']?.country_code in countryCodeToCountryInfo
                  ? countryCodeToCountryInfo[playerDetailsArray[selectedClubIterationIndex]['club']?.country_code ?? ''].name[userConfig?.language ?? 'en']
                  : playerDetailsArray[selectedClubIterationIndex]['club']?.name ?? '-'}
              </div>
            </div>
            <div className='player-view-compare-data-info-column-entry'>
              <div className='player-view-compare-data-info-column-label'>
                {userConfig ? staticLanguageMap['minutes'][userConfig.language] : ''}
              </div>
              <div className='player-view-compare-data-info-column-value'>
                {playerDetailsArray[selectedClubIterationIndex][selectedPositionKey]['minutes_played']
                  ? Math.round(playerDetailsArray[selectedClubIterationIndex][selectedPositionKey]['minutes_played'])
                  : '-'}
              </div>
            </div>
            <div className='player-view-compare-data-info-column-entry'>
              <div className='player-view-compare-data-info-column-label'>
                {userConfig ? staticLanguageMap['skillRating'][userConfig.language] : ''}
              </div>
              <div className='player-view-compare-data-info-column-value' style={{ fontSize: 16, fontWeight: 500, marginTop: 3 }}>
                {playerDetailsArray[selectedClubIterationIndex][selectedPositionKey]['skill_rating'] !== undefined
                  ? Math.round(playerDetailsArray[selectedClubIterationIndex][selectedPositionKey]['skill_rating'] * 10) / 10
                  : '-'}
              </div>
            </div>
            <div className='player-view-compare-data-info-column-entry'>
              <div className='player-view-compare-data-info-column-label'>
                {userConfig ? staticLanguageMap['clubRating'][userConfig.language] : ''}
              </div>
              <div className='player-view-compare-data-info-column-value' style={{ fontSize: 16, fontWeight: 500, marginTop: 3 }}>
                {playerDetailsArray[selectedClubIterationIndex][selectedPositionKey]['club_rating'] !== undefined
                  ? Math.round(playerDetailsArray[selectedClubIterationIndex][selectedPositionKey]['club_rating'] * 10) / 10
                  : '-'}
              </div>
            </div>
            <div className='player-view-compare-data-info-column-entry'>
              <div className='player-view-compare-data-info-column-label'>
                {userConfig ? staticLanguageMap['goals'][userConfig.language] : ''}
              </div>
              <div className='player-view-compare-data-info-column-value'>
                {playerDetailsArray[selectedClubIterationIndex][selectedPositionKey]['n_goals'] ?? '-'}
              </div>
            </div>
            <div className='player-view-compare-data-info-column-entry'>
              <div className='player-view-compare-data-info-column-label'>
                {userConfig ? staticLanguageMap['assists'][userConfig.language] : ''}
              </div>
              <div className='player-view-compare-data-info-column-value'>
                {playerDetailsArray[selectedClubIterationIndex][selectedPositionKey]['n_assists'] ?? '-'}
              </div>
            </div>
          </div>
        )}

        <div className='player-view-compare-radar-section'>
          {userConfig && (
            <CompareRadarPlot
              playerDetailsArray={playerDetailsArray}
              selectedClubIterationIndex={selectedClubIterationIndex}
              selectedPositionKey={selectedPositionKey ?? 'overall'}
              clubColor={clubColor}

              otherPlayerDetailsArray={otherPlayerDetailsArray}
              otherSelectedClubIterationIndex={otherSelectedClubIterationIndex}
              otherSelectedPositionKey={otherSelectedPositionKey ?? 'overall'}
              clubContrastColor={clubContrastColor}

              isGoalkeeper={isGoalkeeper}
              language={userConfig.language} />
          )}
        </div>

        {otherPlayerDetailsArray.length > 0 && otherSelectedClubIterationIndex !== undefined && otherSelectedPositionKey !== undefined && (
          <div className='player-view-compare-data-info-column player-view-compare-data-info-column-other-player'>
            <div className='player-view-compare-data-info-column-entry-other-player'>
              <div className='player-view-compare-data-info-column-label player-view-compare-data-info-column-label-other-player'>
                {userConfig ? staticLanguageMap['team'][userConfig.language] : ''}
              </div>
              <div className='player-view-compare-data-info-column-value player-view-compare-data-info-column-value-other-player' style={{ marginTop: 4 }}>
                {otherPlayerDetailsArray[otherSelectedClubIterationIndex]['club']?.logo_url && (
                  <img
                    className={
                      'player-view-compare-data-info-column-club-logo' +
                      (otherPlayerDetailsArray[otherSelectedClubIterationIndex]['club']?.is_national_team ? ' player-view-compare-flag' : '')
                    }
                    src={otherPlayerDetailsArray[otherSelectedClubIterationIndex]['club']?.logo_url}
                    alt=''
                    draggable={false}
                  />
                )}
                {otherPlayerDetailsArray[otherSelectedClubIterationIndex]['club']?.is_national_team &&
                  otherPlayerDetailsArray[otherSelectedClubIterationIndex]['club']?.country_code in countryCodeToCountryInfo
                  ? countryCodeToCountryInfo[otherPlayerDetailsArray[otherSelectedClubIterationIndex]['club']?.country_code ?? '']
                    .name[userConfig?.language ?? 'en']
                  : otherPlayerDetailsArray[otherSelectedClubIterationIndex]['club']?.name ?? '-'}
              </div>
            </div>
            <div className='player-view-compare-data-info-column-entry-other-player'>
              <div className='player-view-compare-data-info-column-label player-view-compare-data-info-column-label-other-player'>
                {userConfig ? staticLanguageMap['minutes'][userConfig.language] : ''}
              </div>
              <div className='player-view-compare-data-info-column-value player-view-compare-data-info-column-value-other-player'>
                {otherPlayerDetailsArray[otherSelectedClubIterationIndex][otherSelectedPositionKey]['minutes_played']
                  ? Math.round(otherPlayerDetailsArray[otherSelectedClubIterationIndex][otherSelectedPositionKey]['minutes_played'])
                  : '-'}
              </div>
            </div>
            <div className='player-view-compare-data-info-column-entry-other-player'>
              <div className='player-view-compare-data-info-column-label player-view-compare-data-info-column-label-other-player'>
                {userConfig ? staticLanguageMap['skillRating'][userConfig.language] : ''}
              </div>
              <div
                className='player-view-compare-data-info-column-value player-view-compare-data-info-column-value-other-player'
                style={{ fontSize: 16, fontWeight: 500, marginTop: 3 }}
              >
                <div className='player-view-compare-data-info-column-rank player-view-compare-data-info-column-rank-other-player'>
                  {otherPlayerDetailsArray[otherSelectedClubIterationIndex][otherSelectedPositionKey]['skill_rating_rank']
                    ? '(' + otherPlayerDetailsArray[otherSelectedClubIterationIndex][otherSelectedPositionKey]['skill_rating_rank'] + ')'
                    : ''}
                </div>
                {otherPlayerDetailsArray[otherSelectedClubIterationIndex][otherSelectedPositionKey]['skill_rating'] !== undefined
                  ? Math.round(otherPlayerDetailsArray[otherSelectedClubIterationIndex][otherSelectedPositionKey]['skill_rating'] * 10) / 10
                  : '-'}
              </div>
            </div>
            <div className='player-view-compare-data-info-column-entry-other-player'>
              <div className='player-view-compare-data-info-column-label player-view-compare-data-info-column-label-other-player'>
                {userConfig ? staticLanguageMap['clubRating'][userConfig.language] : ''}
              </div>
              <div
                className='player-view-compare-data-info-column-value player-view-compare-data-info-column-value-other-player'
                style={{ fontSize: 16, fontWeight: 500, marginTop: 3 }}
              >
                {otherPlayerDetailsArray[otherSelectedClubIterationIndex][otherSelectedPositionKey]['club_rating'] !== undefined
                  ? Math.round(otherPlayerDetailsArray[otherSelectedClubIterationIndex][otherSelectedPositionKey]['club_rating'] * 10) / 10
                  : '-'}
              </div>
            </div>
            {/* <div className='player-view-compare-data-info-column-entry-other-player'>
              <div className='player-view-compare-data-info-column-label player-view-compare-data-info-column-label-other-player'>
                {userConfig ? staticLanguageMap['matchesPlayed'][userConfig.language] : ''}
              </div>
              <div className='player-view-compare-data-info-column-value player-view-compare-data-info-column-value-other-player'>
                {otherSelectedPositionKey === 'overall' ? otherPlayerDetailsArray[otherSelectedClubIterationIndex]['appearances'] ?? '-' : '-'}
              </div>
            </div>
            <div className='player-view-compare-data-info-column-entry-other-player'>
              <div className='player-view-compare-data-info-column-label player-view-compare-data-info-column-label-other-player'>
                {userConfig ? staticLanguageMap['matchesStarted'][userConfig.language] : ''}
              </div>
              <div className='player-view-compare-data-info-column-value player-view-compare-data-info-column-value-other-player'>
                {otherSelectedPositionKey === 'overall' ? otherPlayerDetailsArray[otherSelectedClubIterationIndex]['games_started'] ?? '-' : '-'}
              </div>
            </div> */}
            <div className='player-view-compare-data-info-column-entry-other-player'>
              <div className='player-view-compare-data-info-column-label player-view-compare-data-info-column-label-other-player'>
                {userConfig ? staticLanguageMap['goals'][userConfig.language] : ''}
              </div>
              <div className='player-view-compare-data-info-column-value player-view-compare-data-info-column-value-other-player'>
                {otherPlayerDetailsArray[otherSelectedClubIterationIndex][otherSelectedPositionKey]['n_goals'] ?? '-'}
              </div>
            </div>
            <div className='player-view-compare-data-info-column-entry-other-player'>
              <div className='player-view-compare-data-info-column-label player-view-compare-data-info-column-label-other-player'>
                {userConfig ? staticLanguageMap['assists'][userConfig.language] : ''}
              </div>
              <div className='player-view-compare-data-info-column-value player-view-compare-data-info-column-value-other-player'>
                {otherPlayerDetailsArray[otherSelectedClubIterationIndex][otherSelectedPositionKey]['n_assists'] ?? '-'}
              </div>
            </div>
          </div>
        )}


      </div>

      <div className='player-view-compare-other-player-section'>

        {/* Todo: disable players without data or players that do not match isGoalkeeper status */}
        {!otherPlayerOverview && (
          <div>
            <SelectPlayerView
              onPlayerClick={setOtherPlayerOverview}
              titleKey={'selectPlayerForComparison'}
              maxTableHeight={'62vh'}
              isGoalkeeper={isGoalkeeper}
              disablePlayersWithoutEventData={true}
            />
          </div>
        )}

        {otherPlayerOverview && (
          <div className='player-view-compare-other-player-view'>

            <CloseIcon
              className='modal-icon modal-exit-icon'
              style={{ fontSize: 26, top: 16, right: 16 }}
              onClick={() => handleOtherPlayerCloseClick()}
            />

            <div className='player-view-compare-other-image-container'>
              <img src={otherPlayerOverview['image_url'] ?? 'https://media.api-sports.io/football/players/0.png'} alt='' className='player-view-image' draggable={false} />
            </div>

            <div className='player-view-compare-other-name'>
              {otherPlayerOverview['fullname']}
            </div>

            <div className='player-view-compare-other-player-rating-section'>

              <div className='player-view-compare-other-player-rating-entry'>
                <div className='player-view-compare-other-player-rating-label'>
                  {userConfig ? staticLanguageMap['skill_index'][userConfig.language] : ''}
                </div>
                <div className='player-view-compare-other-player-rating-value'>
                  {getOverviewIndex('skill_index')}
                </div>
              </div>

              <div className='player-view-compare-other-player-rating-entry'>
                <div className='player-view-compare-other-player-rating-label'>
                  {userConfig ? staticLanguageMap['club_index'][userConfig.language] : ''}
                </div>
                <div className='player-view-compare-other-player-rating-value'>
                  {getOverviewIndex('club_index')}
                </div>
              </div>

              <div className='player-view-compare-other-player-rating-entry'>
                <div className='player-view-compare-other-player-rating-label'>
                  {userConfig ? staticLanguageMap['precision'][userConfig.language] : ''}
                </div>
                <div className='player-view-compare-other-player-rating-value'>
                  <CircularProgressbar
                    value={Math.round(otherPlayerConfidence * 100)}
                    styles={buildStyles({
                      strokeLinecap: 'butt',
                      pathTransitionDuration: 0.6,
                      pathColor: clubContrastColor,
                      trailColor: '#3e4459',
                    })}
                  />
                  <div className='player-view-compare-other-player-precision-value'>
                    {otherPlayerConfidence > 0 ? Math.round(otherPlayerConfidence * 100) : '-'}
                  </div>
                </div>
              </div>

            </div>

            <div className='player-view-compare-other-player-info-section'>
              <div className='player-view-compare-other-player-info-row'>
                <div className='player-view-compare-other-player-info-label'>
                  {userConfig ? staticLanguageMap['age'][userConfig.language] : ''}
                </div>
                <div className='player-view-compare-other-player-info-value'>
                  {getPlayerPropertyValue(
                    'birth_date',
                    otherPlayerOverview,
                    players && selectedPlayer ? players[selectedPlayer?.id] : undefined,
                    getAge) ?? '-'}
                </div>
              </div>

              <div className='player-view-compare-other-player-info-row'>
                <div className='player-view-compare-other-player-info-label'>
                  {userConfig ? staticLanguageMap['position'][userConfig.language] : ''}
                </div>
                <div className='player-view-compare-other-player-info-value'>
                  {getDisplayPosition(otherPlayerOverview, userConfig)}
                </div>
              </div>

              <div className='player-view-compare-other-player-info-row'>
                <div className='player-view-compare-other-player-info-label'>
                  {userConfig ? staticLanguageMap['nationality'][userConfig.language] : ''}
                </div>
                <div className='player-view-compare-other-player-info-value'>
                  {getPlayerPropertyValue(
                    'country_code',
                    otherPlayerOverview,
                    players && selectedPlayer ? players[selectedPlayer?.id] : undefined
                  ) &&
                    (
                      <img
                        className='player-view-compare-flag'
                        src={countryCodeToCountryInfo[
                          getPlayerPropertyValue(
                            'country_code',
                            otherPlayerOverview,
                            players && selectedPlayer ? players[selectedPlayer?.id] : undefined)
                        ].flagUrl}
                        alt=''
                        draggable={false}
                      />
                    )}
                  {getPlayerPropertyValue(
                    'country_code',
                    otherPlayerOverview,
                    players && selectedPlayer ? players[selectedPlayer?.id] : undefined) && userConfig
                    ? countryCodeToCountryInfo[
                      getPlayerPropertyValue(
                        'country_code',
                        otherPlayerOverview,
                        players && selectedPlayer ? players[selectedPlayer?.id] : undefined)
                    ].name[userConfig.language]
                    : '-'}
                </div>
              </div>

              <div className='player-view-compare-other-player-info-row'>
                <div className='player-view-compare-other-player-info-label'>
                  {userConfig ? staticLanguageMap['league'][userConfig.language] : ''}
                </div>
                <div className='player-view-compare-other-player-info-value'>
                  {getDisplayCompetition(otherPlayerOverview, competitions)}
                </div>
              </div>

              <div className='player-view-compare-other-player-info-row'>
                <div className='player-view-compare-other-player-info-label'>
                  {userConfig ? staticLanguageMap['team'][userConfig.language] : ''}
                </div>
                <div className='player-view-compare-other-player-info-value'>
                  {otherPlayerOverview?.club?.logo_url && (
                    <img
                      className='player-view-compare-club-logo'
                      src={otherPlayerOverview.club.logo_url}
                      alt=''
                      draggable={false}
                    />
                  )}
                  {otherPlayerOverview?.club?.name ?? '-'}
                </div>
              </div>

              <div className='player-view-compare-other-player-info-row'>
                <div className='player-view-compare-other-player-info-label'>
                  {userConfig ? staticLanguageMap['height'][userConfig.language] : ''}
                </div>
                <div className='player-view-compare-other-player-info-value'>
                  {getPlayerPropertyValue('height', otherPlayerOverview) ?? '-'}
                </div>
              </div>

              <div className='player-view-compare-other-player-info-row'>
                <div className='player-view-compare-other-player-info-label'>
                  {userConfig ? staticLanguageMap['contract'][userConfig.language] : ''}
                </div>
                <div className='player-view-compare-other-player-info-value'>
                  {getPlayerPropertyValue(
                    'contract_expiration',
                    otherPlayerOverview,
                    players && selectedPlayer ? players[selectedPlayer?.id] : undefined,
                    (value) => getDateLocaleFormat(value)) ?? '-'}
                </div>
              </div>
            </div>

          </div>
        )}

      </div>
    </div>
  );
};
