import '../../platform.css';
import './settings.css';

import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { clubScopesState } from '../../recoil/atoms/clubScopesState';

import { supportEntries } from '../../static/support';
import { FlexibleJsonMapping } from '../../types';
import { OwnTeamBackgoundSvg } from '../../svg/OwnTeamBackgroundSvg';
import { SupportAccordion } from '../../components/accordions/SupportAccordion';


export const Support: React.FC = () => {

  const userConfig = useRecoilValue(userConfigState);
  const clubScopes = useRecoilValue(clubScopesState);

  return (
    <div className='settings-view-container'>

      <div className='own-team-background'>
        <OwnTeamBackgoundSvg />
      </div>

      <div className='support-view-container'>

        {userConfig && Object.keys(supportEntries).map((key, index) => {
          return (
            <div className='support-section' key={index}>
              <div className='support-section-title'>
                {supportEntries[key].title[userConfig.language]}
              </div>

              {supportEntries[key].subsections.map((subsection: FlexibleJsonMapping, subIndex: number) => {
                if (subsection.title[userConfig.language] === 'SkillCorner' && clubScopes && !clubScopes.hasSkillcorner) return null;
                return (
                  <div key={subIndex} className='support-accordion'>
                    <SupportAccordion title={subsection.title[userConfig.language]} text={subsection.text[userConfig.language]} />
                  </div>
                );
              })}
            </div>
          );
        })}

      </div>
    </div>
  );
};
