import './../modals.css';

import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { enGB, nb } from 'date-fns/locale';

import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';

import { staticLanguageMap } from '../../../../common/static/staticLanguageMap';
import { ClausePayment } from '../../../types';
import { useEffect, useState } from 'react';
import { getDisplayEconomicValue } from '../../../utils/currencyUtils';
import { getDateLocaleFormat } from '../../../utils/playerUtils';
import { getDisplayPaymentAmount, getPaymentCurrency } from '../../documents/clause/Clause';
import { ClauseState } from '../../documents/clause/clauseInitialState';


export const getPaymentTitle = (payment: ClausePayment, language: string) => {
  if (payment.conditionType === 'sellOn') {
    return staticLanguageMap['sellOn'][language];
  }

  if (payment.conditionType === 'season') {
    return staticLanguageMap['season'][language] + ' ' + payment.seasonString;
  }

  if (payment.conditionType === 'total') {
    return payment.totalConditionThreshold + staticLanguageMap[' ' + payment.totalCondition][language];
  }

  return staticLanguageMap['unconditioned'][language];
};


interface PaymentModalProps {
  state: ClauseState;
  closeModal: () => void;
  payments: ClausePayment[];
  addOrEditPayments: (payments: ClausePayment[], deletedPayments: ClausePayment[]) => void;
  isEdit: boolean;
  hasUnsavedChanges: boolean;
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void;
}


export const PaymentModal: React.FC<PaymentModalProps> = ({
  state,
  closeModal,
  payments,
  addOrEditPayments,
  isEdit,
  hasUnsavedChanges,
  setHasUnsavedChanges,
}) => {

  const userConfig = useRecoilValue(userConfigState);

  const dateFormat = 'dd.MM.yyyy';
  const locale = userConfig && userConfig.language === 'no'
    ? nb
    : enGB;


  const [newPayments, setNewPayments] = useState<ClausePayment[]>(payments);
  const [deletedPayments, setDeletedPayments] = useState<ClausePayment[]>([]);

  const [newPaymentAmount, setNewPaymentAmount] = useState('');
  const [newPaymentDate, setNewPaymentDate] = useState<Date | null>(null);

  const [paymentIndexToEdit, setPaymentIndexToEdit] = useState<number | undefined>(undefined);
  const [paymentIndexToEditIsConditional, setPaymentIndexToEditIsConditional] = useState<boolean>(false);

  const [messageKey, setMessageKey] = useState<string | undefined>(undefined);


  const handleSetNewPaymentDate = (date: Date | null) => {
    if (!date) {
      setNewPaymentDate(null);
      return;
    }
    const adjustedDate = new Date(date);
    adjustedDate.setHours(12);
    setNewPaymentDate(adjustedDate);
  };


  const onChangeAmountInputField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessageKey(undefined);
    setNewPaymentAmount(event.target.value.replaceAll('.', ''));
  };


  const onKeyDownInputField = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      addOrEditPayment();
    }
  };


  const addOrEditPayment = () => {
    if (!newPaymentAmount) {
      setMessageKey('noAmountProvided');
      return;
    }

    const amount = Number(newPaymentAmount.replaceAll('.', ''));

    if (isNaN(amount) || amount <= 0) {
      setMessageKey('invalidAmount');
      return;
    }

    setMessageKey(undefined);

    if (paymentIndexToEdit !== undefined) {
      const newPaymentsToSet = [...newPayments];
      const editedPayment = newPaymentsToSet[paymentIndexToEdit];
      editedPayment.amount = amount;
      if (newPaymentDate) {
        editedPayment.date = newPaymentDate.toISOString().split('T')[0];
      }
      else {
        delete editedPayment.date;
      }
      newPaymentsToSet[paymentIndexToEdit] = editedPayment;

      newPaymentsToSet.sort((a, b) => {
        if (!a.date) return 1;
        if (!b.date) return -1;
        return a.date.localeCompare(b.date);
      });

      setNewPayments(newPaymentsToSet);
      setPaymentIndexToEdit(undefined);
      setPaymentIndexToEditIsConditional(false);
      setNewPaymentAmount('');
      setNewPaymentDate(null);
      return;
    }

    const newPayment: ClausePayment = {
      amount: amount,
    };

    if (newPaymentDate) {
      newPayment.date = newPaymentDate.toISOString().split('T')[0];
    }

    const newPaymentsToSet = [...newPayments, newPayment];
    newPaymentsToSet.sort((a, b) => {
      if (!a.date) return 1;
      if (!b.date) return -1;
      return a.date.localeCompare(b.date);
    });

    setNewPayments(newPaymentsToSet);
  };


  const handleEditIconClick = (index: number) => {
    setPaymentIndexToEdit(index);
    setPaymentIndexToEditIsConditional(newPayments[index].conditionId !== undefined);

    const paymentDate = newPayments[index].date;
    let date: Date | null = null;
    if (paymentDate) {
      date = new Date(paymentDate);
    }

    const displayPaymentAmount = getDisplayPaymentAmount(newPayments[index], state);

    setNewPaymentAmount(displayPaymentAmount);
    setNewPaymentDate(date);
  };


  const cancelEditPayment = () => {
    setPaymentIndexToEdit(undefined);
    setPaymentIndexToEditIsConditional(false);
    setMessageKey(undefined);
    setNewPaymentAmount('');
    setNewPaymentDate(null);
  };


  const deletePayment = (index: number) => {
    setDeletedPayments([...deletedPayments, newPayments[index]]);

    const newPaymentsToSet = [...newPayments];
    newPaymentsToSet.splice(index, 1);
    setNewPayments(newPaymentsToSet);
  };


  useEffect(() => {
    setHasUnsavedChanges(
      payments.length !== newPayments.length ||
      payments.some((payment, index) => {
        return payment.date !== newPayments[index].date || payment.amount !== newPayments[index].amount;
      })
    );
  }, [newPayments, payments]); // eslint-disable-line react-hooks/exhaustive-deps


  const minPaymentDate = new Date((new Date()).setFullYear((new Date()).getFullYear() - 10));
  const maxPaymentDate = new Date((new Date()).setFullYear((new Date()).getFullYear() + 10));


  return (
    <div className='modal-root-container payment-modal-root'>

      <CloseIcon
        className='modal-icon modal-exit-icon'
        style={{ fontSize: 24 }}
        onClick={() => closeModal()}
      />

      <div className='modal-root-title'>
        {userConfig
          ? (isEdit ? staticLanguageMap['addOrEditPayments'][userConfig.language] : staticLanguageMap['addUnconditionedPayments'][userConfig.language])
          : ''}
      </div>

      <div className='modal-divider modal-root-title-divider'>&nbsp;</div>

      <div className='payment-modal-new-payment-container'>
        {messageKey && (
          <div className='payment-modal-message'>
            {userConfig ? staticLanguageMap[messageKey][userConfig.language] : ''}
          </div>
        )}

        <div className='payment-modal-new-payment-section'>

          <div className='payment-modal-new-payment-input-row'>
            <div className='margin-auto'>
              <DatePicker
                className='document-input-field document-input-field-small'
                selected={newPaymentDate}
                onChange={(date: Date | null) => handleSetNewPaymentDate(date)}
                onKeyDown={(event) => onKeyDownInputField(event)}
                locale={locale}
                dateFormat={dateFormat}
                placeholderText={userConfig ? staticLanguageMap['date'][userConfig.language] + '?' : ''}
                popperPlacement='top-start'

                minDate={minPaymentDate}
                maxDate={maxPaymentDate}
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={100}
                showMonthDropdown
              />
            </div>

            <input
              className={
                'document-input-field document-input-field-small margin-auto' +
                (paymentIndexToEditIsConditional ? ' document-input-field-disabled' : '')
              }
              name='payment-modal-input-field-amount'
              type='text'
              autoComplete='off'
              value={getDisplayEconomicValue(newPaymentAmount)}
              placeholder={userConfig ? staticLanguageMap['amount'][userConfig.language] : ''}
              onChange={(event) => onChangeAmountInputField(event)}
              onKeyDown={(event) => onKeyDownInputField(event)}
              disabled={paymentIndexToEditIsConditional}
            />
          </div>

          <div className='payment-modal-divider'>&nbsp;</div>

          <div
            className='document-submit-button'
            style={{ height: 25 }}
            onClick={() => addOrEditPayment()}>
            <div style={{ marginLeft: 12 }}>
              {userConfig
                ? (paymentIndexToEdit !== undefined
                  ? staticLanguageMap['editPayment'][userConfig.language]
                  : staticLanguageMap['addPayment'][userConfig.language])
                : ''}
            </div>

            <CheckIcon
              style={{ fontSize: 20, marginLeft: 10, marginRight: 4 }}
            />
          </div>
        </div>
      </div>

      <div className='payment-modal-existing-payments'>
        {newPayments.map((payment, index) => {
          return (
            <div key={index} className='payment-modal-existing-payment'>

              <div
                className='clause-content-icon payment-modal-existing-payment-delete-icon'
                title={userConfig ? staticLanguageMap['deletePayment'][userConfig.language] : ''}
                onClick={() => deletePayment(index)}>
                <DeleteOutlineIcon style={{ fontSize: 17, marginTop: 1 }} />
              </div>

              <div className='payment-modal-existing-payment-value' style={{ width: 150 }}>
                {getDateLocaleFormat(payment.date)}
              </div>

              <div className='payment-modal-existing-payment-value' style={{ alignItems: 'baseline', width: 150 }}>
                {getDisplayPaymentAmount(payment, state)}
                <div style={{ marginLeft: 3, fontSize: 11, color: '#ffffffdd' }}>
                  {getPaymentCurrency(payment, state)}
                </div>
                {/* {getDisplayPaymentAmount(payment, state)} */}
              </div>

              <div className='payment-modal-existing-payment-value' style={{ width: 150, fontSize: 12, color: '#ffffffbb' }}>
                {getPaymentTitle(payment, userConfig?.language ?? 'en')}
              </div>

              <div
                className={
                  'clause-content-icon payment-modal-existing-payment-edit-icon' +
                  (index === paymentIndexToEdit ? ' payment-modal-existing-payment-edit-icon-selected' : '')
                }
                title={userConfig ? staticLanguageMap['editPayment'][userConfig.language] : ''}
                onClick={() => index === paymentIndexToEdit ? cancelEditPayment() : handleEditIconClick(index)}>
                <EditIcon style={{ fontSize: 17, marginTop: 1 }} />
              </div>

            </div>
          );
        })}
      </div>

      <div
        className={'modal-button modal-button-bottom' + (!hasUnsavedChanges ? ' modal-button-disabled' : '')}
        style={{ marginLeft: 180 }}
        onClick={() => (hasUnsavedChanges ? addOrEditPayments(newPayments, deletedPayments) : null)}
      >
        <div className='modal-button-text'>
          {userConfig
            ? (isEdit ? staticLanguageMap['confirmEdits'][userConfig.language] : staticLanguageMap['addPayments'][userConfig.language])
            : ''}
        </div>

        {!isEdit && <AddIcon className='modal-button-icon' style={{ fontSize: 24 }} />}
        {isEdit && <CheckIcon className='modal-button-icon' style={{ fontSize: 24 }} />}
      </div>

    </div>
  );
};
