import './playerView.css';
import './../documents/documents.css';

import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { selectedPlayerState } from '../../recoil/atoms/selectedPlayerState';
import { useGlobalModal } from '../../recoil/hooks/openGlobalModal';

import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PostAddIcon from '@mui/icons-material/PostAdd';
import AddCommentIcon from '@mui/icons-material/AddComment';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import { FlexibleJsonMapping, PlayerReport } from '../../types';
import { staticLanguageMap } from '../../../common/static/staticLanguageMap';
import { getClubColor } from '../../utils/configUtils';
import { Rating } from '@mui/material';
import { OwnTeamBackgoundSvg } from '../../svg/OwnTeamBackgroundSvg';
import { addReport, updateReportFileNamesAndFileUrls, updateReportIsDeletedStatus } from '../../services/firestore/reports';
import { ExistingReports } from '../existingDocuments/ExistingReports';
import { Report } from '../documents/Report';
import { getStrictPlayerId } from '../../utils/playerUtils';
import { uploadFiles } from '../../services/storage/files';


interface PlayerViewReportsProps {
  playerOverview: FlexibleJsonMapping | undefined;
  reports: PlayerReport[];
  setReports: (newReports: PlayerReport[]) => void;
}

export const PlayerViewReports: React.FC<PlayerViewReportsProps> = ({ playerOverview, reports, setReports }) => {

  const userConfig = useRecoilValue(userConfigState);
  const selectedPlayer = useRecoilValue(selectedPlayerState);

  const { handleOpenConfirmModal, handleOpenTextModal } = useGlobalModal();

  const [newReportTypeSelected, setNewReportTypeSelected] = useState<string | undefined>(undefined);
  const [existingReportSelected, setExistingReportSelected] = useState<PlayerReport | undefined>(undefined);

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  const [isLoading, setIsLoading] = useState(false);


  // report fields
  const [commentString, setCommentString] = useState<string>('');

  const [overallScore, setOverallScore] = useState(0);
  const [potentialScore, setPotentialScore] = useState(0);

  const [techniqueScore, setTechniqueScore] = useState(0);
  const [understandingScore, setUnderstandingScore] = useState(0);
  const [attitudeScore, setAttitudeScore] = useState(0);
  const [influenceScore, setInfluenceScore] = useState(0);

  const [paceScore, setPaceScore] = useState(0);
  const [strengthScore, setStrengthScore] = useState(0);
  const [staminaScore, setStaminaScore] = useState(0);
  const [intensityScore, setIntensityScore] = useState(0);

  const [teamString, setTeamString] = useState<string>(playerOverview?.club?.name ?? '');
  const [opponentString, setOpponentString] = useState<string>('');
  const [matchDate, setMatchDate] = useState(new Date());

  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [fileUrls, setFileUrls] = useState<string[]>([]);
  const [fileNames, setFileNames] = useState<string[]>([]);

  const handleFileUpload = (file: File) => {
    const newFileName = file.name;
    if (fileNames.includes(newFileName)) {
      handleOpenTextModal(staticLanguageMap['fileAlreadyUploaded'][userConfig?.language ?? 'en']);
      return;
    }

    setUploadedFiles([...uploadedFiles, file]);
    setFileNames([...fileNames, newFileName]);

    const objectURL = URL.createObjectURL(file);
    setFileUrls([...fileUrls, objectURL]);
  };


  const handleRemoveFile = (event: React.MouseEvent, fileUrl: string, fileName: string) => {
    event.stopPropagation();

    const newFiles = uploadedFiles.filter(file => file.name !== fileName);
    const newFileNames = fileNames.filter(name => name !== fileName);
    const newFileUrls = fileUrls.filter(url => url !== fileUrl);

    setUploadedFiles(newFiles);
    setFileNames(newFileNames);
    setFileUrls(newFileUrls);
  };

  useEffect(() => {
    // revoke the object url to avoid memory leaks
    return () => {
      fileUrls.forEach((url) => URL.revokeObjectURL(url));
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  // score interactions
  const [hasSetOverallScore, setHasSetOverallScore] = useState(false);
  const [hasSetPotentialScore, setHasSetPotentialScore] = useState(false);

  const [hasSetTechniqueScore, setHasSetTechniqueScore] = useState(false);
  const [hasSetUnderstandingScore, setHasSetUnderstandingScore] = useState(false);
  const [hasSetAttitudeScore, setHasSetAttitudeScore] = useState(false);
  const [hasSetInfluenceScore, setHasSetInfluenceScore] = useState(false);

  const [hasSetPaceScore, setHasSetPaceScore] = useState(false);
  const [hasSetStrengthScore, setHasSetStrengthScore] = useState(false);
  const [hasSetStaminaScore, setHasSetStaminaScore] = useState(false);
  const [hasSetIntensityScore, setHasSetIntensityScore] = useState(false);


  // average scores
  const [averageScoutReportOverallScore, setAverageScoutReportOverallScore] = useState(0);
  const [averageScoutReportPotentialScore, setAverageScoutReportPotentialScore] = useState(0);

  const [averageScoutReportTechniqueScore, setAverageScoutReportTechniqueScore] = useState(0);
  const [averageScoutReportUnderstandingScore, setAverageScoutReportUnderstandingScore] = useState(0);
  const [averageScoutReportAttitudeScore, setAverageScoutReportAttitudeScore] = useState(0);
  const [averageScoutReportInfluenceScore, setAverageScoutReportInfluenceScore] = useState(0);

  const [averageScoutReportPaceScore, setAverageScoutReportPaceScore] = useState(0);
  const [averageScoutReportStrengthScore, setAverageScoutReportStrengthScore] = useState(0);
  const [averageScoutReportStaminaScore, setAverageScoutReportStaminaScore] = useState(0);
  const [averageScoutReportIntensityScore, setAverageScoutReportIntensityScore] = useState(0);


  const [averageMatchReportOverallScore, setAverageMatchReportOverallScore] = useState(0);

  const [averageMatchReportTechniqueScore, setAverageMatchReportTechniqueScore] = useState(0);
  const [averageMatchUnderstandingScore, setAverageMatchUnderstandingScore] = useState(0);
  const [averageMatchReportAttitudeScore, setAverageMatchReportAttitudeScore] = useState(0);
  const [averageMatchReportInfluenceScore, setAverageMatchReportInfluenceScore] = useState(0);

  const [averageMatchReportPaceScore, setAverageMatchReportPaceScore] = useState(0);
  const [averageMatchReportStrengthScore, setAverageMatchReportStrengthScore] = useState(0);
  const [averageMatchReportStaminaScore, setAverageMatchReportStaminaScore] = useState(0);
  const [averageMatchReportIntensityScore, setAverageMatchReportIntensityScore] = useState(0);


  // fields required
  const [overallScoreRequired, setOverallScoreRequired] = useState(false);
  const [potentialScoreRequired, setPotentialScoreRequired] = useState(false);

  const [techniqueScoreRequired, setTechniqueScoreRequired] = useState(false);
  const [understandingScoreRequired, setUnderstandingScoreRequired] = useState(false);
  const [attitudeScoreRequired, setAttitudeScoreRequired] = useState(false);
  const [influenceScoreRequired, setInfluenceScoreRequired] = useState(false);

  const [paceScoreRequired, setPaceScoreRequired] = useState(false);
  const [strengthScoreRequired, setStrengthScoreRequired] = useState(false);
  const [staminaScoreRequired, setStaminaScoreRequired] = useState(false);
  const [intensityScoreRequired, setIntensityScoreRequired] = useState(false);

  const [teamStringRequired, setTeamStringRequired] = useState(false);
  const [opponentStringRequired, setOpponentStringRequired] = useState(false);
  const [matchDateRequired, setMatchDateRequired] = useState(false);


  const resetReportFields = () => {
    setNewReportTypeSelected(undefined);
    setExistingReportSelected(undefined);

    setCommentString('');

    setOverallScore(0);
    setPotentialScore(0);

    setTechniqueScore(0);
    setUnderstandingScore(0);
    setAttitudeScore(0);
    setInfluenceScore(0);

    setPaceScore(0);
    setStrengthScore(0);
    setStaminaScore(0);
    setIntensityScore(0);

    setTeamString(playerOverview?.club?.name ?? '');
    setOpponentString('');
    setMatchDate(new Date());

    setUploadedFiles([]);
    setFileUrls([]);
    setFileNames([]);

    setOverallScoreRequired(false);
    setPotentialScoreRequired(false);

    setTechniqueScoreRequired(false);
    setUnderstandingScoreRequired(false);
    setAttitudeScoreRequired(false);
    setInfluenceScoreRequired(false);

    setPaceScoreRequired(false);
    setStrengthScoreRequired(false);
    setStaminaScoreRequired(false);
    setIntensityScoreRequired(false);

    setTeamStringRequired(false);
    setOpponentStringRequired(false);
    setMatchDateRequired(false);


    setHasSetOverallScore(false);
    setHasSetPotentialScore(false);

    setHasSetTechniqueScore(false);
    setHasSetUnderstandingScore(false);
    setHasSetAttitudeScore(false);
    setHasSetInfluenceScore(false);

    setHasSetPaceScore(false);
    setHasSetStrengthScore(false);
    setHasSetStaminaScore(false);
    setHasSetIntensityScore(false);
  };


  const currentNewReportHasAnyFieldProvided = () => {

    // common for all report types
    if (commentString.trim().length > 0) return true;

    if (newReportTypeSelected === 'MatchReport') {
      return overallScore > 0
        || techniqueScore > 0
        || understandingScore > 0
        || attitudeScore > 0
        || influenceScore > 0
        || paceScore > 0
        || strengthScore > 0
        || staminaScore > 0
        || intensityScore > 0
        || commentString.trim().length > 0;
    }

    if (newReportTypeSelected === 'ScoutingReport') {
      return hasSetOverallScore
        || hasSetPotentialScore
        || hasSetTechniqueScore
        || hasSetUnderstandingScore
        || hasSetAttitudeScore
        || hasSetInfluenceScore
        || hasSetPaceScore
        || hasSetStrengthScore
        || hasSetStaminaScore
        || hasSetIntensityScore
        || commentString.trim().length > 0;
    }

    if (newReportTypeSelected === 'Attachment') {
      return fileUrls.length > 0;
    }

    return false;
  };


  const handleCloseIconClick = () => {
    if (newReportTypeSelected && currentNewReportHasAnyFieldProvided()) {
      handleOpenConfirmModal(
        () => confirmExitReport(),
        newReportTypeSelected === 'Comment' ? 'exitComment?' : newReportTypeSelected === 'Attachment' ? 'exitAttachment?' : 'exitReport?',
        newReportTypeSelected === 'Comment' ? 'exitCommentInfo' : newReportTypeSelected === 'Attachment' ? 'exitAttachmentInfo' : 'exitReportInfo'
      );
    }
    else {
      resetReportFields();
    }
  };


  const confirmExitReport = (reportClicked?: PlayerReport) => {
    setNewReportTypeSelected(undefined);

    if (reportClicked) {
      handleExistingReportSelected(reportClicked);
    }
    else {
      resetReportFields();
    }
  };


  const handleNewReportTypeSelected = (reportType: string) => {
    if (reportType === 'ScoutingReport') {
      setOverallScore(averageMatchReportOverallScore);
      setTechniqueScore(averageMatchReportTechniqueScore);
      setUnderstandingScore(averageMatchUnderstandingScore);
      setAttitudeScore(averageMatchReportAttitudeScore);
      setInfluenceScore(averageMatchReportInfluenceScore);
      setPaceScore(averageMatchReportPaceScore);
      setStrengthScore(averageMatchReportStrengthScore);
      setStaminaScore(averageMatchReportStaminaScore);
      setIntensityScore(averageMatchReportIntensityScore);
    }

    setNewReportTypeSelected(reportType);
  };


  const handleExistingReportClicked = (report: PlayerReport) => {
    if (newReportTypeSelected && currentNewReportHasAnyFieldProvided()) {
      handleOpenConfirmModal(
        () => confirmExitReport(report),
        newReportTypeSelected === 'Comment' ? 'exitComment?' : newReportTypeSelected === 'Attachment' ? 'exitAttachment?' : 'exitReport?',
        newReportTypeSelected === 'Comment' ? 'exitCommentInfo' : newReportTypeSelected === 'Attachment' ? 'exitAttachmentInfo' : 'exitReportInfo'
      );
      return;
    }
    else {
      handleExistingReportSelected(report);
    }
  };


  const handleExistingReportSelected = (report: PlayerReport) => {
    setNewReportTypeSelected(undefined);

    setCommentString(report.reportFields.comment ?? '');

    if (report.reportType === 'ScoutingReport' || report.reportType === 'MatchReport') {
      setOverallScore(report.reportFields.overallScore);
      setTechniqueScore(report.reportFields.techniqueScore);
      setUnderstandingScore(report.reportFields.understandingScore);
      setAttitudeScore(report.reportFields.attitudeScore);
      setInfluenceScore(report.reportFields.influenceScore);

      setPaceScore(report.reportFields.paceScore);
      setStrengthScore(report.reportFields.strengthScore);
      setStaminaScore(report.reportFields.staminaScore);
      setIntensityScore(report.reportFields.intensityScore);

      if (report.reportType === 'ScoutingReport') {
        setPotentialScore(report.reportFields.potentialScore);
      }
      else if (report.reportType === 'MatchReport') {
        setTeamString(report.reportFields.team);
        setOpponentString(report.reportFields.opponent);
        setMatchDate(new Date(report.reportFields.matchDate));
      }
    }

    if (report.reportType === 'Attachment') {
      setFileNames(report.reportFields.fileNames ?? []);
      setFileUrls(report.reportFields.fileUrls ?? []);
    }

    setExistingReportSelected(report);
  };


  const handleReportButtonClick = async () => {

    if (!userConfig || submitButtonDisabled) return;
    setSubmitButtonDisabled(true);
    setIsLoading(true);

    if (existingReportSelected) {
      const reportUpdated = await updateReportIsDeletedStatus(existingReportSelected.id as string, !existingReportSelected.isDeleted, userConfig.club);
      if (reportUpdated) {
        setReports(reports.map(report => {
          if (report.id === existingReportSelected?.id) {
            report.isDeleted = !report.isDeleted;
          }
          return report;
        }));
      }
      handleCloseIconClick();
    }

    else {
      const newReport = await submitNewReport();
      if (newReport && newReport.id) {

        // if this is an attachment report, we do the submit in three steps
        // (1) add report to firestore and get id, 2) upload files to storage and get urls, 3) update report with urls both in firestore and in state
        if (newReportTypeSelected === 'Attachment') {
          const newFileUrls = await uploadFiles(uploadedFiles, 'reports', newReport.id, userConfig.club);

          if (newFileUrls && newFileUrls.length > 0) {
            const success = await updateReportFileNamesAndFileUrls(newReport.id, fileNames, newFileUrls, userConfig.club);

            if (success) {
              newReport.reportFields.fileNames = fileNames;
              newReport.reportFields.fileUrls = newFileUrls;

            }
          }
        }

        setReports([newReport, ...reports]);
        resetReportFields();
      }
    }
    setSubmitButtonDisabled(false);
    setIsLoading(false);
  };


  const submitNewReport = async () => {

    if (!newReportTypeSelected || !selectedPlayer || !userConfig) return;

    if (newReportTypeSelected === 'Comment' && commentString.trim().length === 0) {
      setCommentString('');
      return;
    }

    if (newReportTypeSelected === 'Attachment' && uploadedFiles.length === 0) {
      return;
    }

    if (newReportTypeSelected === 'ScoutingReport') {
      let anyRequiredFieldIsMissing = false;
      if (!(hasSetOverallScore && overallScore > 0)) {
        setOverallScoreRequired(true);
        anyRequiredFieldIsMissing = true;
      } else { setOverallScoreRequired(false); }

      if (!(hasSetPotentialScore && potentialScore > 0)) {
        setPotentialScoreRequired(true);
        anyRequiredFieldIsMissing = true;
      } else { setPotentialScoreRequired(false); }

      if (!(hasSetTechniqueScore && techniqueScore > 0)) {
        setTechniqueScoreRequired(true);
        anyRequiredFieldIsMissing = true;
      } else { setTechniqueScoreRequired(false); }

      if (!(hasSetUnderstandingScore && understandingScore > 0)) {
        setUnderstandingScoreRequired(true);
        anyRequiredFieldIsMissing = true;
      } else { setUnderstandingScoreRequired(false); }

      if (!(hasSetAttitudeScore && attitudeScore > 0)) {
        setAttitudeScoreRequired(true);
        anyRequiredFieldIsMissing = true;
      } else { setAttitudeScoreRequired(false); }

      if (!(hasSetInfluenceScore && influenceScore > 0)) {
        setInfluenceScoreRequired(true);
        anyRequiredFieldIsMissing = true;
      } else { setInfluenceScoreRequired(false); }

      if (!(hasSetPaceScore && paceScore > 0)) {
        setPaceScoreRequired(true);
        anyRequiredFieldIsMissing = true;
      } else { setPaceScoreRequired(false); }

      if (!(hasSetStrengthScore && strengthScore > 0)) {
        setStrengthScoreRequired(true);
        anyRequiredFieldIsMissing = true;
      } else { setStrengthScoreRequired(false); }

      if (!(hasSetStaminaScore && staminaScore > 0)) {
        setStaminaScoreRequired(true);
        anyRequiredFieldIsMissing = true;
      } else { setStaminaScoreRequired(false); }

      if (!(hasSetIntensityScore && intensityScore > 0)) {
        setIntensityScoreRequired(true);
        anyRequiredFieldIsMissing = true;
      } else { setIntensityScoreRequired(false); }

      if (anyRequiredFieldIsMissing) return;
    }

    if (newReportTypeSelected === 'MatchReport') {
      let anyRequiredFieldIsMissing = false;
      if (overallScore === 0) {
        setOverallScoreRequired(true);
        anyRequiredFieldIsMissing = true;
      } else { setOverallScoreRequired(false); }

      if (teamString.trim().length === 0) {
        setTeamStringRequired(true);
        setTeamString('');
        anyRequiredFieldIsMissing = true;
      } else { setTeamStringRequired(false); }

      if (opponentString.trim().length === 0) {
        setOpponentStringRequired(true);
        setOpponentString('');
        anyRequiredFieldIsMissing = true;
      } else { setOpponentStringRequired(false); }

      if (!matchDate) {
        setMatchDateRequired(true);
        anyRequiredFieldIsMissing = true;
      }
      else { setMatchDateRequired(false); }

      if (anyRequiredFieldIsMissing) return;
    }

    const report: PlayerReport = {
      playerId: getStrictPlayerId(selectedPlayer.id),
      playerName: selectedPlayer.fullname,
      userEmail: userConfig.email,
      date: new Date().toISOString(),
      isDeleted: false,
      reportType: newReportTypeSelected,
      reportVersion: '1.0.0',
      reportFields: {
        'comment': commentString,
      },
    };

    if (newReportTypeSelected === 'ScoutingReport' || newReportTypeSelected === 'MatchReport') {
      report.reportFields = {
        ...report.reportFields,
        'overallScore': overallScore,
        'techniqueScore': techniqueScore,
        'understandingScore': understandingScore,
        'attitudeScore': attitudeScore,
        'influenceScore': influenceScore,
        'paceScore': paceScore,
        'strengthScore': strengthScore,
        'staminaScore': staminaScore,
        'intensityScore': intensityScore,
      };

      if (newReportTypeSelected === 'ScoutingReport') {
        report.reportFields = {
          ...report.reportFields,
          'potentialScore': potentialScore,
        };
      }
      else if (newReportTypeSelected === 'MatchReport') {
        report.reportFields = {
          ...report.reportFields,
          'team': teamString,
          'opponent': opponentString,
          'matchDate': matchDate.toISOString().split('T')[0],
        };
      }
    }

    const reportId = await addReport(report, userConfig.club);

    if (reportId) {
      report.id = reportId;
    }
    return report;
  };


  useEffect(() => {
    if (reports.length > 0) {
      let overallRatingScoutReportSum = 0;
      let potentialRatingScoutReportSum = 0;
      let techniqueRatingScoutReportSum = 0;
      let understandingRatingScoutReportSum = 0;
      let attitudeRatingScoutReportSum = 0;
      let influenceRatingScoutReportSum = 0;
      let paceRatingScoutReportSum = 0;
      let strengthRatingScoutReportSum = 0;
      let staminaRatingScoutReportSum = 0;
      let intensityRatingScoutReportSum = 0;
      let scoutReportCount = 0;

      let overallRatingMatchReportSum = 0;
      let techniqueRatingMatchReportSum = 0;
      let understandingRatingMatchReportSum = 0;
      let attitudeRatingMatchReportSum = 0;
      let influenceRatingMatchReportSum = 0;
      let paceRatingMatchReportSum = 0;
      let strengthRatingMatchReportSum = 0;
      let staminaRatingMatchReportSum = 0;
      let intensityRatingMatchReportSum = 0;
      let matchReportSum = 0;
      for (let i = 0; i < reports.length; i++) {
        if (reports[i].reportType === 'ScoutingReport') {
          overallRatingScoutReportSum += reports[i].reportFields.overallScore;
          potentialRatingScoutReportSum += reports[i].reportFields.potentialScore;
          techniqueRatingScoutReportSum += reports[i].reportFields.techniqueScore;
          understandingRatingScoutReportSum += reports[i].reportFields.understandingScore;
          attitudeRatingScoutReportSum += reports[i].reportFields.attitudeScore;
          influenceRatingScoutReportSum += reports[i].reportFields.influenceScore;
          paceRatingScoutReportSum += reports[i].reportFields.paceScore;
          strengthRatingScoutReportSum += reports[i].reportFields.strengthScore;
          staminaRatingScoutReportSum += reports[i].reportFields.staminaScore;
          intensityRatingScoutReportSum += reports[i].reportFields.intensityScore;
          scoutReportCount++;
        }
        else if (reports[i].reportType === 'MatchReport') {
          overallRatingMatchReportSum += reports[i].reportFields.overallScore;
          techniqueRatingMatchReportSum += reports[i].reportFields.techniqueScore;
          understandingRatingMatchReportSum += reports[i].reportFields.understandingScore;
          attitudeRatingMatchReportSum += reports[i].reportFields.attitudeScore;
          influenceRatingMatchReportSum += reports[i].reportFields.influenceScore;
          paceRatingMatchReportSum += reports[i].reportFields.paceScore;
          strengthRatingMatchReportSum += reports[i].reportFields.strengthScore;
          staminaRatingMatchReportSum += reports[i].reportFields.staminaScore;
          intensityRatingMatchReportSum += reports[i].reportFields.intensityScore;
          matchReportSum++;
        }
      }
      if (scoutReportCount > 0) {
        setAverageScoutReportOverallScore(overallRatingScoutReportSum / scoutReportCount);
        setAverageScoutReportPotentialScore(potentialRatingScoutReportSum / scoutReportCount);
        setAverageScoutReportTechniqueScore(techniqueRatingScoutReportSum / scoutReportCount);
        setAverageScoutReportUnderstandingScore(understandingRatingScoutReportSum / scoutReportCount);
        setAverageScoutReportAttitudeScore(attitudeRatingScoutReportSum / scoutReportCount);
        setAverageScoutReportInfluenceScore(influenceRatingScoutReportSum / scoutReportCount);
        setAverageScoutReportPaceScore(paceRatingScoutReportSum / scoutReportCount);
        setAverageScoutReportStrengthScore(strengthRatingScoutReportSum / scoutReportCount);
        setAverageScoutReportStaminaScore(staminaRatingScoutReportSum / scoutReportCount);
        setAverageScoutReportIntensityScore(intensityRatingScoutReportSum / scoutReportCount);
      }
      if (matchReportSum > 0) {
        setAverageMatchReportOverallScore(overallRatingMatchReportSum / matchReportSum);
        setAverageMatchReportTechniqueScore(techniqueRatingMatchReportSum / matchReportSum);
        setAverageMatchUnderstandingScore(understandingRatingMatchReportSum / matchReportSum);
        setAverageMatchReportAttitudeScore(attitudeRatingMatchReportSum / matchReportSum);
        setAverageMatchReportInfluenceScore(influenceRatingMatchReportSum / matchReportSum);
        setAverageMatchReportPaceScore(paceRatingMatchReportSum / matchReportSum);
        setAverageMatchReportStrengthScore(strengthRatingMatchReportSum / matchReportSum);
        setAverageMatchReportStaminaScore(staminaRatingMatchReportSum / matchReportSum);
        setAverageMatchReportIntensityScore(intensityRatingMatchReportSum / matchReportSum);
      }
    }
  }, [reports]);


  const clubColor = getClubColor(userConfig?.club ?? '');


  return (
    <div className='player-view-main-section'>

      <div className='player-view-reports-existing-reports-container'>
        <ExistingReports
          reports={reports}
          existingReportSelected={existingReportSelected}
          handleExistingReportClicked={handleExistingReportClicked}
          isPlayerView={true}
        />
      </div>


      <div className={'player-view-reports-right-section' + (existingReportSelected ? ' player-view-reports-right-section-existing-report' : '')}>

        <div className='player-view-reports-right-section-background'>
          <OwnTeamBackgoundSvg />
        </div>

        {!newReportTypeSelected && !existingReportSelected && (
          <div className='document-content-section'>

            <div className='report-content-left-column'>
              <div className='player-view-reports-new-report-buttons-column'>
                <div
                  style={{ width: 200 }}
                  className='document-submit-button new-document-button'
                  onClick={() => handleNewReportTypeSelected('ScoutingReport')}
                >
                  <div className='document-submit-button-text'>
                    {userConfig ? staticLanguageMap['newScoutingReport'][userConfig.language] : ''}
                  </div>
                  <div className='document-submit-button-icon new-document-button-icon'>
                    <NoteAddIcon style={{ fontSize: 21 }} />
                  </div>
                </div>

                <div
                  style={{ width: 200 }}
                  className='document-submit-button new-document-button'
                  onClick={() => handleNewReportTypeSelected('MatchReport')}
                >
                  <div className='document-submit-button-text'>
                    {userConfig ? staticLanguageMap['newMatchReport'][userConfig.language] : ''}
                  </div>
                  <div className='document-submit-button-icon new-document-button-icon'>
                    <PostAddIcon style={{ fontSize: 21 }} />
                  </div>
                </div>

                <div
                  style={{ width: 200 }}
                  className='document-submit-button new-document-button'
                  onClick={() => handleNewReportTypeSelected('Comment')}
                >
                  <div className='document-submit-button-text'>
                    {userConfig ? staticLanguageMap['newComment'][userConfig.language] : ''}
                  </div>
                  <div className='document-submit-button-icon new-document-button-icon'>
                    <AddCommentIcon style={{ fontSize: 21, marginTop: 1 }} />
                  </div>
                </div>

                <div
                  style={{ width: 200 }}
                  className='document-submit-button new-document-button'
                  onClick={() => handleNewReportTypeSelected('Attachment')}
                >
                  <div className='document-submit-button-text'>
                    {userConfig ? staticLanguageMap['newAttachment'][userConfig.language] : ''}
                  </div>
                  <div className='document-submit-button-icon new-document-button-icon'>
                    <AttachFileIcon style={{ fontSize: 21, marginTop: 1 }} />
                  </div>
                </div>
              </div>
            </div>

            <div className='report-content-right-column'>

              <div className='report-content-average-title'>
                {userConfig ? staticLanguageMap['averageRating'][userConfig.language] : ''}
              </div>

              <div className='report-content-rating-row report-content-rating-top-row'>
                <div className='report-rating'>
                  <div className='report-rating-title'>
                    {userConfig ? staticLanguageMap['overallRating'][userConfig.language] : ''}
                  </div>
                  <div className='report-rating-stars'>
                    <Rating
                      name='averageScoutReportOverallScore'
                      value={averageScoutReportOverallScore}
                      max={5}
                      precision={0.1}
                      sx={{ fontSize: 25, color: clubColor, '& .MuiRating-iconEmpty': { color: '#9d9fa3' } }}
                      readOnly={true}
                    />
                  </div>
                </div>
                <div className='report-rating'>
                  <div className='report-rating-title'>
                    {userConfig ? staticLanguageMap['potential'][userConfig.language] : ''}
                  </div>
                  <div className='report-rating-stars'>
                    <Rating
                      name='averageScoutReportPotentialScore'
                      value={averageScoutReportPotentialScore}
                      max={5}
                      precision={0.1}
                      sx={{ fontSize: 25, color: clubColor, '& .MuiRating-iconEmpty': { color: '#9d9fa3' } }}
                      readOnly={true}
                    />
                  </div>
                </div>
              </div>

              <div className='report-content-rating-row'>
                <div className='report-rating'>
                  <div className='report-rating-title'>
                    {userConfig ? staticLanguageMap['technique'][userConfig.language] : ''}
                  </div>
                  <div className='report-rating-stars'>
                    <Rating
                      name='averageScoutReportTechniqueScore'
                      value={averageScoutReportTechniqueScore}
                      max={5}
                      precision={0.1}
                      sx={{ fontSize: 22, color: clubColor, '& .MuiRating-iconEmpty': { color: '#9d9fa3' } }}
                      readOnly={true}
                    />
                  </div>
                </div>
                <div className='report-rating'>
                  <div className='report-rating-title'>
                    {userConfig ? staticLanguageMap['pace'][userConfig.language] : ''}
                  </div>
                  <div className='report-rating-stars'>
                    <Rating
                      name='averageScoutReportPaceScore'
                      value={averageScoutReportPaceScore}
                      max={5}
                      precision={0.1}
                      sx={{ fontSize: 22, color: clubColor, '& .MuiRating-iconEmpty': { color: '#9d9fa3' } }}
                      readOnly={true}
                    />
                  </div>
                </div>
              </div>

              <div className='report-content-rating-row'>
                <div className='report-rating'>
                  <div className='report-rating-title'>
                    {userConfig ? staticLanguageMap['understanding'][userConfig.language] : ''}
                  </div>
                  <div className='report-rating-stars'>
                    <Rating
                      name='averageUnderstandingScore'
                      value={averageScoutReportUnderstandingScore}
                      max={5}
                      precision={0.1}
                      sx={{ fontSize: 22, color: clubColor, '& .MuiRating-iconEmpty': { color: '#9d9fa3' } }}
                      readOnly={true}
                    />
                  </div>
                </div>
                <div className='report-rating'>
                  <div className='report-rating-title'>
                    {userConfig ? staticLanguageMap['strength'][userConfig.language] : ''}
                  </div>
                  <div className='report-rating-stars'>
                    <Rating
                      name='averageScoutReportStrengthScore'
                      value={averageScoutReportStrengthScore}
                      max={5}
                      precision={0.1}
                      sx={{ fontSize: 22, color: clubColor, '& .MuiRating-iconEmpty': { color: '#9d9fa3' } }}
                      readOnly={true}
                    />
                  </div>
                </div>
              </div>

              <div className='report-content-rating-row'>
                <div className='report-rating'>
                  <div className='report-rating-title'>
                    {userConfig ? staticLanguageMap['attitude'][userConfig.language] : ''}
                  </div>
                  <div className='report-rating-stars'>
                    <Rating
                      name='averageScoutReportAttitudeScore'
                      value={averageScoutReportAttitudeScore}
                      max={5}
                      precision={0.1}
                      sx={{ fontSize: 22, color: clubColor, '& .MuiRating-iconEmpty': { color: '#9d9fa3' } }}
                      readOnly={true}
                    />
                  </div>
                </div>
                <div className='report-rating'>
                  <div className='report-rating-title'>
                    {userConfig ? staticLanguageMap['stamina'][userConfig.language] : ''}
                  </div>
                  <div className='report-rating-stars'>
                    <Rating
                      name='averageScoutReportStaminaScore'
                      value={averageScoutReportStaminaScore}
                      max={5}
                      precision={0.1}
                      sx={{ fontSize: 22, color: clubColor, '& .MuiRating-iconEmpty': { color: '#9d9fa3' } }}
                      readOnly={true}
                    />
                  </div>
                </div>
              </div>

              <div className='report-content-rating-row'>
                <div className='report-rating'>
                  <div className='report-rating-title'>
                    {userConfig ? staticLanguageMap['influence'][userConfig.language] : ''}
                  </div>
                  <div className='report-rating-stars'>
                    <Rating
                      name='averageScoutReportInfluenceScore'
                      value={averageScoutReportInfluenceScore}
                      max={5}
                      precision={0.1}
                      sx={{ fontSize: 22, color: clubColor, '& .MuiRating-iconEmpty': { color: '#9d9fa3' } }}
                      readOnly={true}
                    />
                  </div>
                </div>
                <div className='report-rating'>
                  <div className='report-rating-title'>
                    {userConfig ? staticLanguageMap['intensity'][userConfig.language] : ''}
                  </div>
                  <div className='report-rating-stars'>
                    <Rating
                      name='averageScoutReportIntensityScore'
                      value={averageScoutReportIntensityScore}
                      max={5}
                      precision={0.1}
                      sx={{ fontSize: 22, color: clubColor, '& .MuiRating-iconEmpty': { color: '#9d9fa3' } }}
                      readOnly={true}
                    />
                  </div>
                </div>
              </div>

            </div>

          </div>
        )}

        {(newReportTypeSelected || existingReportSelected) && (
          <Report
            newReportTypeSelected={newReportTypeSelected}
            existingReportSelected={existingReportSelected}
            handleCloseIconClick={handleCloseIconClick}
            handleReportButtonClick={handleReportButtonClick}
            isLoading={isLoading}

            commentString={commentString}
            overallScore={overallScore}
            potentialScore={potentialScore}
            techniqueScore={techniqueScore}
            understandingScore={understandingScore}
            attitudeScore={attitudeScore}
            influenceScore={influenceScore}
            paceScore={paceScore}
            strengthScore={strengthScore}
            staminaScore={staminaScore}
            intensityScore={intensityScore}
            teamString={teamString}
            opponentString={opponentString}
            matchDate={matchDate}

            setCommentString={setCommentString}
            setOverallScore={setOverallScore}
            setPotentialScore={setPotentialScore}
            setTechniqueScore={setTechniqueScore}
            setUnderstandingScore={setUnderstandingScore}
            setAttitudeScore={setAttitudeScore}
            setInfluenceScore={setInfluenceScore}
            setPaceScore={setPaceScore}
            setStrengthScore={setStrengthScore}
            setStaminaScore={setStaminaScore}
            setIntensityScore={setIntensityScore}
            setTeamString={setTeamString}
            setOpponentString={setOpponentString}
            setMatchDate={setMatchDate}

            hasSetOverallScore={hasSetOverallScore}
            hasSetPotentialScore={hasSetPotentialScore}
            hasSetTechniqueScore={hasSetTechniqueScore}
            hasSetUnderstandingScore={hasSetUnderstandingScore}
            hasSetAttitudeScore={hasSetAttitudeScore}
            hasSetInfluenceScore={hasSetInfluenceScore}
            hasSetPaceScore={hasSetPaceScore}
            hasSetStrengthScore={hasSetStrengthScore}
            hasSetStaminaScore={hasSetStaminaScore}
            hasSetIntensityScore={hasSetIntensityScore}

            setHasSetOverallScore={setHasSetOverallScore}
            setHasSetPotentialScore={setHasSetPotentialScore}
            setHasSetTechniqueScore={setHasSetTechniqueScore}
            setHasSetUnderstandingScore={setHasSetUnderstandingScore}
            setHasSetAttitudeScore={setHasSetAttitudeScore}
            setHasSetInfluenceScore={setHasSetInfluenceScore}
            setHasSetPaceScore={setHasSetPaceScore}
            setHasSetStrengthScore={setHasSetStrengthScore}
            setHasSetStaminaScore={setHasSetStaminaScore}
            setHasSetIntensityScore={setHasSetIntensityScore}

            overallScoreRequired={overallScoreRequired}
            potentialScoreRequired={potentialScoreRequired}
            techniqueScoreRequired={techniqueScoreRequired}
            understandingScoreRequired={understandingScoreRequired}
            attitudeScoreRequired={attitudeScoreRequired}
            influenceScoreRequired={influenceScoreRequired}
            paceScoreRequired={paceScoreRequired}
            strengthScoreRequired={strengthScoreRequired}
            staminaScoreRequired={staminaScoreRequired}
            intensityScoreRequired={intensityScoreRequired}
            teamStringRequired={teamStringRequired}
            opponentStringRequired={opponentStringRequired}
            matchDateRequired={matchDateRequired}

            submitButtonDisabled={submitButtonDisabled}
            shouldShowAverageMatchReportScoreTitle={newReportTypeSelected === 'ScoutingReport' && averageMatchReportOverallScore > 0}

            fileUrls={fileUrls}
            fileNames={fileNames}
            handleFileUpload={handleFileUpload}
            handleRemoveFile={handleRemoveFile}
          />
        )}

      </div>

    </div>
  );
};
