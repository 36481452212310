import { FlexibleJsonMapping, PlayerCollection, PlayerDocument, PlayerEntry, PlayerId, PlayerReport } from '../../types';
import { db } from '../../../firebase';

import {
  collection,
  addDoc,
  doc,
  onSnapshot,
  setDoc,
  deleteDoc,
  getDocs,
  getDoc
} from 'firebase/firestore';
import { getStrictPlayerId } from '../../utils/playerUtils';
import { removePlayerFromAllSquads, removePlayerFromAllTeams, removePlayerFromTeamOrSquad } from './teamsOrSquads';
import { getReportsForPlayer, updatePlayerOfReport } from './reports';
import { getPlayerActivites, updatePlayerOfActivity } from './activities';
import { positionOptionsPlatform } from '../../static/propertyValues';


// Get players
export const getPlayers = (setPlayers: (players: PlayerCollection) => void, club: string) => {

  const playersCollectionRef = collection(db, 'configs', club, 'players');

  const unsubscribe = onSnapshot(playersCollectionRef, snapshot => {

    const players: PlayerCollection = {};
    snapshot.forEach((doc) => {
      const playerId: PlayerId = getStrictPlayerId(doc.id);
      players[playerId] = { ...doc.data(), id: playerId } as PlayerDocument;
    });

    setPlayers(players);
  });

  return unsubscribe;
};


// Add a new player that was added manually without data to the players collection and return the document id as the new player id
export const addPlayerWithoutData = async (fullname: string, club: string): Promise<string | undefined> => {

  const playersCollectionRef = collection(db, 'configs', club, 'players');

  const dateAdded = (new Date()).toISOString().split('T')[0];

  try {
    const docRef = await addDoc(playersCollectionRef, { fullname: fullname, dateAdded: dateAdded });
    return docRef.id;
  }
  catch (error) {
    return undefined;
  }
};


// Set a property of a player document
export const setPlayerProperty = async (playerId: PlayerId, property: string, value: unknown, club: string) => {

  const playerDocRef = doc(db, 'configs', club, 'players', String(playerId));

  try {
    await setDoc(playerDocRef, {
      [property]: value
    }, { merge: true });
  }
  catch (error) {
    console.error(error); // eslint-disable-line no-console
  }
};


// Delete a player document and remove from all teams and squad (archived teams and reports and history will remain)
export const deletePlayerFromPlatform = async (playerId: PlayerId, userEmail: string, club: string) => {

  const playerDocRef = doc(db, 'configs', club, 'players', String(playerId));

  try {
    await deleteDoc(playerDocRef);
    await removePlayerFromAllTeams(playerId, club);
    await removePlayerFromAllSquads(playerId, userEmail, club);
  }
  catch (error) {
    console.error(error); // eslint-disable-line no-console
  }
};


// Delete a player document
export const deletePlayerDocument = async (playerId: PlayerId, club: string) => {

  const playerDocRef = doc(db, 'configs', club, 'players', String(playerId));

  deleteDoc(playerDocRef);
};


// Delete data from the player collection related to ownTeam
export const deleteOwnTeamAndAcademyPlayerData = async (playerId: PlayerId, club: string) => {

  const playerDocRef = doc(db, 'configs', club, 'players', String(playerId));

  await setDoc(playerDocRef, {
    contract_expiration: null,
    market_value: null,
    isStartingEleven: null,
    role: null,
    clubValue: null,
    signingCost: null,
    salary: null,
    bonuses: null,
    isLocal: null,
  }, { merge: true });
};


// Connect a player without data to a player with data
export const connectPlayerWithoutData = async (
  currentPlayerId: PlayerId,
  currentTeam: string,

  newPlayerId: PlayerId,
  newPlayerFullname: string,
  newPlayerTeam: string,

  userEmail: string,
  club: string
) => {

  // every instance of currentPlayerId (and fullname) in reports, activities, archivedTeams and squads will be replaced with newPlayerId (and newPlayerFullname)

  // reports
  const reportsOfPlayer: PlayerReport[] = await getReportsForPlayer(currentPlayerId, club);
  reportsOfPlayer.forEach(report => {
    if (report.id) {
      updatePlayerOfReport(report.id, newPlayerId, newPlayerFullname, club);
    }
  });

  // activities
  const activitiesOfPlayer: FlexibleJsonMapping[] = await getPlayerActivites(currentPlayerId, club);
  activitiesOfPlayer.forEach(activity => {
    updatePlayerOfActivity(activity.id, newPlayerId, newPlayerFullname, club);
  });

  // archivedTeams
  const archivedTeamsCollectionRef = collection(db, 'configs', club, 'archivedTeams');
  const archivedTeamsSnapshot = await getDocs(archivedTeamsCollectionRef);
  archivedTeamsSnapshot.docs.forEach(doc => {
    const archivedTeam = doc.data();
    positionOptionsPlatform.forEach(position => {
      if (archivedTeam[position]) {
        const newPositionList: PlayerEntry[] = [];
        archivedTeam[position].forEach((player: PlayerEntry) => {
          if (player.id === currentPlayerId) {
            newPositionList.push({ id: newPlayerId, fullname: newPlayerFullname });
          }
          else {
            newPositionList.push(player);
          }
        });
        archivedTeam[position] = newPositionList;
      }
    });
    setDoc(doc.ref, archivedTeam);
  });

  // squads
  const squadsCollectionRef = collection(db, 'configs', club, 'squads');
  const squadsSnapshot = await getDocs(squadsCollectionRef);
  squadsSnapshot.docs.forEach(doc => {
    const squad = doc.data();
    positionOptionsPlatform.forEach(position => {
      if (squad[position]) {
        const newPositionList: PlayerEntry[] = [];
        squad[position].forEach((player: PlayerEntry) => {
          if (player.id === currentPlayerId) {
            newPositionList.push({ id: newPlayerId, fullname: newPlayerFullname });
          }
          else {
            newPositionList.push(player);
          }
        });
        squad[position] = newPositionList;
      }
    });

    const newSquadHistory = squad.history.map((item: FlexibleJsonMapping) => {
      if (item.playerId === currentPlayerId) {
        item.playerId = newPlayerId;
        item.playerName = newPlayerFullname;
      }
      return item;
    });
    squad.history = newSquadHistory;

    setDoc(doc.ref, squad);
  });


  // update player collection appropriately - the player document should only remain in case currentTeam is 'ownTeam'
  let currentPlayerDocument: FlexibleJsonMapping | undefined = undefined;
  if (currentTeam === 'ownTeam') {
    const playerDocRef = doc(db, 'configs', club, 'players', String(currentPlayerId));
    currentPlayerDocument = (await getDoc(playerDocRef)).data();
    if (currentPlayerDocument) {
      currentPlayerDocument['dateAdded'] = null;
      currentPlayerDocument['fullname'] = newPlayerFullname;
    }
  }

  deletePlayerDocument(currentPlayerId, club);
  deletePlayerDocument(newPlayerId, club);

  if (currentPlayerDocument) {
    const playerDocRef = doc(db, 'configs', club, 'players', String(newPlayerId));
    await setDoc(playerDocRef, currentPlayerDocument);
  }

  // update currentTeam and newPlayerTeam correctly
  if (newPlayerTeam) {
    await removePlayerFromTeamOrSquad(newPlayerId, newPlayerTeam, false, userEmail, club);
  }
  if (currentTeam) {
    const teamDocRef = doc(db, 'configs', club, 'teams', currentTeam);
    const currentTeamData = (await getDoc(teamDocRef)).data();

    positionOptionsPlatform.forEach(position => {
      if (currentTeamData && currentTeamData[position]) {
        const newPositionList: PlayerEntry[] = [];
        currentTeamData[position].forEach((player: PlayerEntry) => {
          if (player.id === currentPlayerId) {
            newPositionList.push({ id: newPlayerId, fullname: newPlayerFullname });
          }
          else {
            newPositionList.push(player);
          }
        });
        currentTeamData[position] = newPositionList;
      }
    });

    setDoc(teamDocRef, currentTeamData);
  }

};
