import './playerView.css';

import { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { selectedPlayerState } from '../../recoil/atoms/selectedPlayerState';
import { playerTeamDataState } from '../../recoil/selectors/playerTeamDataState';
import { useGlobalModal } from '../../recoil/hooks/openGlobalModal';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CallMergeIcon from '@mui/icons-material/CallMerge';

import { staticLanguageMap } from '../../../common/static/staticLanguageMap';
import { PlayerFinding } from '../../views/scouting/playerFinding/PlayerFinding';
import { connectPlayerWithoutData, deletePlayerFromPlatform } from '../../services/firestore/players';
import { FlexibleJsonMapping } from '../../types';
import { PlayerTable } from '../tables/playerTable/PlayerTable';
import { getStrictPlayerId } from '../../utils/playerUtils';
import { useAddPlayerOverview } from '../../recoil/hooks/addPlayerOverview';
import { useAddPlayerDetails } from '../../recoil/hooks/addPlayerDetails';
import { AuthContextType, useAuthContext } from '../../../common/contexts/AuthContext';


interface PlayerViewWithoutDataProps {
  setIsConnectingPlayer: (isConnectingPlayer: boolean) => void;
}

export const PlayerViewWithoutData: React.FC<PlayerViewWithoutDataProps> = ({ setIsConnectingPlayer }) => {

  const { currentUser } = useAuthContext() as AuthContextType;
  const userConfig = useRecoilValue(userConfigState);

  const playerTeamData = useRecoilValue(playerTeamDataState);
  const [selectedPlayer, setSelectedPlayer] = useRecoilState(selectedPlayerState);

  const { handleOpenConfirmModal } = useGlobalModal();

  const addPlayerOverview = useAddPlayerOverview();
  const addPlayerDetails = useAddPlayerDetails();

  const [selectedPlayerToMap, setSelectedPlayerToMap] = useState<FlexibleJsonMapping | undefined>(undefined);

  const [isAnyFilterExpandedToggle, setIsAnyFilterExpandedToggle] = useState(false);


  const confirmDeletion = () => {
    if (!selectedPlayer || !userConfig) return;
    deletePlayerFromPlatform(selectedPlayer.id, userConfig.email, userConfig.club);
    setSelectedPlayer(undefined);
  };


  const connectPlayer = async () => {
    if (!selectedPlayer || !userConfig || !selectedPlayerToMap || !playerTeamData) return;

    setIsConnectingPlayer(true);

    const currentPlayerId = getStrictPlayerId(selectedPlayer.id);
    const currentTeam = playerTeamData[currentPlayerId] ? playerTeamData[currentPlayerId].currentTeam : undefined;

    const newPlayerId = getStrictPlayerId(selectedPlayerToMap.id);
    const newPlayerFullname = selectedPlayerToMap.fullname;
    const newPlayerTeam = playerTeamData[selectedPlayerToMap.id] ? playerTeamData[selectedPlayerToMap.id].currentTeam : undefined;

    await connectPlayerWithoutData(
      currentPlayerId,
      currentTeam,
      newPlayerId,
      newPlayerFullname,
      newPlayerTeam,
      userConfig.email,
      userConfig.club
    );

    addPlayerOverview(newPlayerId, currentUser);

    if (currentTeam === 'ownTeam') {
      addPlayerDetails(newPlayerId, currentUser);
    }

    setSelectedPlayerToMap(undefined);
    setSelectedPlayer(undefined);
    setIsConnectingPlayer(false);
  };


  return (
    <div className='player-view-main-section'>

      <div
        className='full-size-container absolute-container'
        id='player-finding-empty-background'
        onClick={() => setIsAnyFilterExpandedToggle(false)}
      >
        &nbsp;
      </div>

      <div className='player-view-without-data-top-section'>
        <div className='player-view-without-data-description'>
          <div>{userConfig ? staticLanguageMap['playerWasAddedWithoutData'][userConfig.language] : ''}</div>
          <div style={{ marginTop: '1vh' }}>{userConfig ? staticLanguageMap['playerWasAddedWithoutDataDescription'][userConfig.language] : ''}</div>
        </div>

        <div
          className='player-view-without-data-delete-player-button'
          onClick={() => handleOpenConfirmModal(confirmDeletion, 'deletePlayer', 'deletePlayerInfo')}
        >
          {userConfig ? staticLanguageMap['deletePlayer'][userConfig.language] : ''}
          <div className='player-view-without-data-delete-player-icon'>
            <DeleteForeverIcon style={{ fontSize: 20 }} />
          </div>
        </div>
      </div>

      {!selectedPlayerToMap && (
        <div className='player-view-without-data-bottom-section'>
          <PlayerFinding
            isAnyFilterExpandedToggle={isAnyFilterExpandedToggle}
            setIsAnyFilterExpandedToggle={setIsAnyFilterExpandedToggle}
            handlePlayerClick={setSelectedPlayerToMap}
          />
        </div>
      )}

      {selectedPlayerToMap && (
        <div className='player-view-without-data-bottom-section'>
          <div className='player-view-without-data-bottom-section-table-container'>
            <PlayerTable
              data={[selectedPlayerToMap]}

              currentModuloPage={0}
              currentChapter={0}
              totalHits={1}
              hitsPerPage={50}

              headerBackgroundColor={'#222530'}
              isFindPlayerView={true}
              isSelectedPlayer={true}
            />
          </div>

          <div
            className='player-view-without-data-connect-player-button'
            onClick={() => handleOpenConfirmModal(connectPlayer, 'connectPlayer', 'noRegretInfo')}
          >
            {userConfig ? staticLanguageMap['connectPlayer'][userConfig.language] : ''}
            <div style={{ marginLeft: 10, marginBottom: -3 }}>
              <CallMergeIcon style={{ fontSize: 24 }} />
            </div>
          </div>

          <div className='player-view-without-data-connect-player-link' onClick={() => setSelectedPlayerToMap(undefined)}>
            {userConfig ? staticLanguageMap['searchAgain?'][userConfig.language] : ''}
          </div>

        </div>
      )}

    </div>
  );
};
