import '../../platform.css';
import './../../components/documents/documents.css';
import './archive.css';

import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { selectedPlayerState } from '../../recoil/atoms/selectedPlayerState';
import { playerOverviewsState } from '../../recoil/atoms/playerOverviewsState';
import { playersState } from '../../recoil/atoms/playersState';
import { getNewPlayerOverviews, getPlayerOverviews } from '../../services/fokusServer/playerOverviews';
import { AuthContextType, useAuthContext } from '../../../common/contexts/AuthContext';

import FolderSharedIcon from '@mui/icons-material/FolderShared';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';

import { PlayerView } from '../../components/playerView/PlayerView';
import { FixedTabLine } from '../../components/tabLines/FixedTabLine';
import { getAllReports, updateReportIsDeletedStatus } from '../../services/firestore/reports';
import { ExistingReports } from '../../components/existingDocuments/ExistingReports';
import { OwnTeamBackgoundSvg } from '../../svg/OwnTeamBackgroundSvg';
import { Report } from '../../components/documents/Report';
import { getAllArchivedTeams, updateArchivedTeamIsDeletedStatus } from '../../services/firestore/teamsOrSquads';
import { FlexibleJsonMapping, PlayerId, PlayerOverviews, PlayerReport } from '../../types';
import { ExistingArchivedTeams } from '../../components/existingDocuments/ExistingArchivedTeams';
import { TeamView } from '../../components/teamView/TeamView';
import { ReportStatistics } from './ReportStatistics';


export const Archive = () => {

  const { currentUser } = useAuthContext() as AuthContextType;
  const userConfig = useRecoilValue(userConfigState);

  const selectedPlayer = useRecoilValue(selectedPlayerState);
  const players = useRecoilValue(playersState);
  const playerOverviews = useRecoilValue(playerOverviewsState);

  const [newPlayerOverviews, setNewPlayerOverviews] = useState<PlayerOverviews>({});

  const [selectedUserEmail, setSelectedUserEmail] = useState<string>('');

  const [allReports, setAllReports] = useState<PlayerReport[]>([]);
  const [reportsToShow, setReportsToShow] = useState<PlayerReport[]>([]);
  const [isReportsLoading, setIsReportsLoading] = useState<boolean>(true);
  const [reportSelected, setReportSelected] = useState<PlayerReport | undefined>(undefined);

  const [archivedTeams, setArchivedTeams] = useState<FlexibleJsonMapping[]>([]);
  const [isArchivedTeamsLoading, setIsArchivedTeamsLoading] = useState<boolean>(true);
  const [archivedTeamSelected, setArchivedTeamSelected] = useState<FlexibleJsonMapping | undefined>(undefined);

  const [selectedPlayerData, setSelectedPlayerData] = useState<FlexibleJsonMapping | undefined>(undefined);


  const [activeTab, setActiveTab] = useState(0);

  const tabOptions = [
    'reports',
    'archivedTeams',
  ];
  const tabIcons = [
    <FolderSharedIcon key={'folder-shared-icon'} style={{ fontSize: 21, marginTop: 1 }} />,
    <FolderSpecialIcon key={'folder-special-icon'} style={{ fontSize: 21, marginTop: 1 }} />,
  ];


  const handleTabClick = (tabIndex: number) => {
    setActiveTab(tabIndex);
    setReportSelected(undefined);
    setArchivedTeamSelected(undefined);
    setSelectedPlayerData(undefined);
  };


  const handleSetSelectedPlayerData = async (playerId: PlayerId, playerName: string) => {
    if (playerOverviews && playerId in playerOverviews) {
      setSelectedPlayerData(playerOverviews[Number(playerId)]);
    }
    else if (playerId in newPlayerOverviews) {
      setSelectedPlayerData(newPlayerOverviews[Number(playerId)]);
    }
    else {
      setSelectedPlayerData(undefined);
      const numberId = Number(playerId);
      if (!isNaN(numberId)) {
        getPlayerOverviews([numberId], currentUser).then((player: PlayerOverviews | undefined) => {
          setSelectedPlayerData(player ? Object.values(player)[0] : {});
        });
      }
      else if (players && playerId in players) {
        const playerData = players[playerId] ?? {};
        setSelectedPlayerData({ id: playerId, ...playerData });
      }
      else {
        setSelectedPlayerData({ id: playerId, fullname: playerName });
      }
    }
  };


  const handleCloseIconClick = () => {
    setReportSelected(undefined);
    setArchivedTeamSelected(undefined);
    setSelectedPlayerData(undefined);
  };


  // report fields
  const [commentString, setCommentString] = useState<string>('');

  const [overallScore, setOverallScore] = useState(0);
  const [potentialScore, setPotentialScore] = useState(0);

  const [techniqueScore, setTechniqueScore] = useState(0);
  const [understandingScore, setUnderstandingScore] = useState(0);
  const [attitudeScore, setAttitudeScore] = useState(0);
  const [influenceScore, setInfluenceScore] = useState(0);

  const [paceScore, setPaceScore] = useState(0);
  const [strengthScore, setStrengthScore] = useState(0);
  const [staminaScore, setStaminaScore] = useState(0);
  const [intensityScore, setIntensityScore] = useState(0);

  const [teamString, setTeamString] = useState<string>('');
  const [opponentString, setOpponentString] = useState<string>('');
  const [matchDate, setMatchDate] = useState(new Date());

  const [fileUrls, setFileUrls] = useState<string[]>([]);
  const [fileNames, setFileNames] = useState<string[]>([]);


  const handleExistingReportClicked = async (report: PlayerReport) => {
    setCommentString(report.reportFields.comment);
    setOverallScore(report.reportFields.overallScore);
    setPotentialScore(report.reportFields.potentialScore);
    setTechniqueScore(report.reportFields.techniqueScore);
    setUnderstandingScore(report.reportFields.understandingScore);
    setAttitudeScore(report.reportFields.attitudeScore);
    setInfluenceScore(report.reportFields.influenceScore);
    setPaceScore(report.reportFields.paceScore);
    setStrengthScore(report.reportFields.strengthScore);
    setStaminaScore(report.reportFields.staminaScore);
    setIntensityScore(report.reportFields.intensityScore);
    setTeamString(report.reportFields.team);
    setOpponentString(report.reportFields.opponent);
    setMatchDate(new Date(report.reportFields.matchDate));
    setFileUrls(report.reportFields.fileUrls);
    setFileNames(report.reportFields.fileNames);

    setReportSelected(report);

    handleSetSelectedPlayerData(report.playerId, report.playerName);
  };


  const handleReportButtonClick = async () => {
    if (!reportSelected || !userConfig) return;

    const reportUpdated = await updateReportIsDeletedStatus(reportSelected.id as string, !reportSelected.isDeleted, userConfig.club);
    if (reportUpdated) {
      setAllReports(allReports.map(report => {
        if (report.id === reportSelected?.id) {
          report.isDeleted = !report.isDeleted;
        }
        return report;
      }));
    }

    if (reportSelected.isDeleted) {
      handleCloseIconClick();
    }
  };


  const deleteOrRestoreArchivedTeam = async () => {
    if (!userConfig || !archivedTeamSelected) return;

    const archivedTeamUpdated = await updateArchivedTeamIsDeletedStatus(archivedTeamSelected['id'], !archivedTeamSelected['isDeleted'], userConfig.club);
    if (archivedTeamUpdated) {
      setArchivedTeams(archivedTeams.map(team => {
        if (team.id === archivedTeamSelected['id']) {
          team['isDeleted'] = !team['isDeleted'];
        }
        return team;
      }));
    }

    if (archivedTeamSelected['isDeleted']) {
      handleCloseIconClick();
    }
  };


  useEffect(() => {
    if (userConfig?.club) {
      getAllReports(userConfig.club).then((allReports: PlayerReport[]) => {
        setAllReports(allReports);
        setIsReportsLoading(false);
      });

      getAllArchivedTeams(userConfig.club).then((allArchivedTeams: FlexibleJsonMapping[]) => {
        setArchivedTeams(allArchivedTeams);
        setIsArchivedTeamsLoading(false);
      });
    }
  }, [userConfig?.club]);


  useEffect(() => {
    if (!isArchivedTeamsLoading && archivedTeams.length > 0 && playerOverviews) {
      getNewPlayerOverviews(archivedTeams, playerOverviews, currentUser).then((newPlayerOverviews: PlayerOverviews) => {
        setNewPlayerOverviews(newPlayerOverviews);
      });
    }
  }, [archivedTeams, currentUser, isArchivedTeamsLoading, playerOverviews]);


  useEffect(() => {
    if (allReports.length > 0) {
      if (selectedUserEmail === 'all') {
        setReportsToShow(allReports);
      }
      else {
        setReportsToShow(allReports.filter(report => report.userEmail === selectedUserEmail));
      }
    }
  }, [allReports, selectedUserEmail]);


  return (
    <div className='platform-view-section platform-view-section-dark'>

      <div className={'player-view-outer-container' + (selectedPlayer ? ' player-view-outer-container-player-selected' : '')}>
        {selectedPlayer && (
          <PlayerView
            playerOverview={
              playerOverviews && selectedPlayer.id in playerOverviews ?
                playerOverviews[Number(selectedPlayer.id)]
                : selectedPlayer.id in newPlayerOverviews
                  ? newPlayerOverviews[Number(selectedPlayer.id)]
                  : selectedPlayerData && Object.keys(selectedPlayerData).length === 2
                    ? undefined
                    : selectedPlayerData
            }
          />
        )}
      </div>

      {!archivedTeamSelected && (
        <div className='player-view-reports-right-section-background'>
          <OwnTeamBackgoundSvg />
        </div>
      )}

      <FixedTabLine
        tabOptions={tabOptions}
        activeTab={activeTab}
        setActiveTab={handleTabClick}
        tabIcons={tabIcons}
      />

      {activeTab === 0 && (
        <div className='archive-view-container'>
          {!isReportsLoading && (
            <div className='archive-existing-reports-container fade-in'>
              <ExistingReports
                reports={reportsToShow}
                existingReportSelected={reportSelected}
                handleExistingReportClicked={handleExistingReportClicked}
                isPlayerView={false}
              />
            </div>
          )}
          <div className='archive-report-container'>
            {reportSelected && (
              <Report
                newReportTypeSelected={undefined}
                existingReportSelected={reportSelected}
                handleCloseIconClick={handleCloseIconClick}
                handleReportButtonClick={handleReportButtonClick}

                commentString={commentString}
                overallScore={overallScore}
                potentialScore={potentialScore}
                techniqueScore={techniqueScore}
                understandingScore={understandingScore}
                attitudeScore={attitudeScore}
                influenceScore={influenceScore}
                paceScore={paceScore}
                strengthScore={strengthScore}
                staminaScore={staminaScore}
                intensityScore={intensityScore}
                teamString={teamString}
                opponentString={opponentString}
                matchDate={matchDate}

                isArchiveView={true}
                playerData={selectedPlayerData}

                fileUrls={fileUrls}
                fileNames={fileNames}
              />
            )}

            {!reportSelected && !isReportsLoading && (
              <ReportStatistics
                reports={reportsToShow}
                selectedUserEmail={selectedUserEmail}
                setSelectedUserEmail={setSelectedUserEmail} />
            )}
          </div>
        </div>
      )}


      {activeTab === 1 && !isArchivedTeamsLoading && (
        <div className='archive-view-container'>
          <div className='archive-archived-teams-container fade-in'>
            <ExistingArchivedTeams
              archivedTeams={archivedTeams}
              archivedTeamSelected={archivedTeamSelected}
              handleArchivedTeamClicked={setArchivedTeamSelected}
            />
          </div>
          {archivedTeamSelected && (
            <div className='logistic-team-view-section archived-team-view-section'>
              <TeamView
                teamOrSquadId={'archivedTeam'}
                teamOrSquadData={archivedTeamSelected}
                isArchivedTeamDeleted={archivedTeamSelected['isDeleted']}
                closeArchivedTeam={handleCloseIconClick}
                deleteOrRestoreArchivedTeam={deleteOrRestoreArchivedTeam}
                newPlayerOverviews={newPlayerOverviews}
              />
            </div>
          )}
        </div>
      )}

    </div>
  );
};
