import './teamView.css';

import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { useGlobalModal } from '../../recoil/hooks/openGlobalModal';

import SwapVertIcon from '@mui/icons-material/SwapVert';
import BrushIcon from '@mui/icons-material/Brush';
import ReplayIcon from '@mui/icons-material/Replay';
import SaveAltIcon from '@mui/icons-material/SaveAlt';

import { FlexibleJsonMapping, JsonMapping } from '../../types';
import { staticLanguageMap } from '../../../common/static/staticLanguageMap';
import { archiveTeamOrSquad, resetTeamOrSquad } from '../../services/firestore/teamsOrSquads';
import { settingsState } from '../../recoil/atoms/settingsState';
import { updateTeamOrOwnTeamMenu } from '../../services/firestore/settings';
import { Toggle } from '../input/Toggle';
import { PlayerSimpleTable } from '../tables/playerSimpleTable/PlayerSimpleTable';


interface TeamMenuProps {
  teamOrSquadId: string;
  teamOrSquadData: FlexibleJsonMapping | undefined;

  isSquad?: boolean;

  showFirstEleven?: boolean;
  setShowFirstEleven?: (isToggled: boolean) => void;
  benchPlayers?: FlexibleJsonMapping[];

  draggedPlayer?: FlexibleJsonMapping | undefined;
  setDraggedPlayer?: (player: FlexibleJsonMapping | undefined) => void;
  handlePlayerDrop?: (newPosition: string) => void;
}


export const TeamMenu: React.FC<TeamMenuProps> = ({
  teamOrSquadId,
  teamOrSquadData,

  isSquad,

  showFirstEleven,
  setShowFirstEleven,
  benchPlayers,

  draggedPlayer,
  setDraggedPlayer,
  handlePlayerDrop,
}) => {

  const userConfig = useRecoilValue(userConfigState);
  const settings = useRecoilValue(settingsState);

  const { handleOpenConfirmModal } = useGlobalModal();

  const [teamButtonDisabled, setTeamButtonDisabled] = useState(false);


  const menu = (teamOrSquadId === 'ownTeam'
    ? settings?.userSettings?.ownTeamMenu
    : teamOrSquadId === 'academyTeam'
      ? settings?.userSettings?.academyTeamMenu
      : settings?.userSettings?.teamMenu) ?? {};


  const menuOptions = teamOrSquadId === 'ownTeam'
    ? ['shortNames', 'role', 'nationality', 'age', 'birthYear', 'contract', 'clubIndex', 'skillIndex']
    : teamOrSquadId === 'academyTeam'
      ? ['shortNames', 'role', 'nationality', 'age', 'birthYear', 'contract']
      : ['shortNames', 'nationality', 'club', 'age', 'birthYear', 'contract', 'clubIndex', 'skillIndex'];


  const displayOptionToPropertyMap: JsonMapping = {
    'shortNames': 'shortNames',
    'role': 'role',
    'nationality': 'country_code',
    'club': 'club_logo_url',
    'age': 'age',
    'birthYear': 'birth_date',
    'contract': 'contract_expiration',
    'clubIndex': 'club_index',
    'skillIndex': 'skill_index',
  };


  const propertiesWithIcons = ['age', 'birth_date', 'contract_expiration', 'club_index', 'skill_index'];


  const [showContent, setShowContent] = useState(false);

  const [isIconHighlighted, setIsIconHighlighted] = useState<string | undefined>(undefined);

  const formation = teamOrSquadData ? teamOrSquadData['formation'] : undefined;


  const handleOptionClick = (option: string, isOptionAlreadySelected: boolean) => {
    if (!userConfig || !settings) return;

    const newMenu = { ...menu };
    newMenu[option] = !isOptionAlreadySelected;

    if (isOptionAlreadySelected && menu['colored'] === option) {
      newMenu['colored'] = null;
    }

    if (option === 'club_index') {
      newMenu['skill_index'] = false;
      if (menu['colored'] === 'skill_index') {
        newMenu['colored'] = null;
      }
    }
    else if (option === 'skill_index') {
      newMenu['club_index'] = false;
      if (menu['colored'] === 'club_index') {
        newMenu['colored'] = null;
      }
    }

    else if (option === 'birth_date') {
      newMenu['age'] = false;
      if (menu['colored'] === 'age') {
        newMenu['colored'] = null;
      }
    }
    else if (option === 'age') {
      newMenu['birth_date'] = false;
      if (menu['colored'] === 'birth_date') {
        newMenu['colored'] = null;
      }
    }

    updateTeamOrOwnTeamMenu(newMenu, teamOrSquadId, userConfig.email, userConfig.club);
  };


  const toggleUnavailablePlayers = (isToggled: boolean) => {
    if (!userConfig || !settings) return;

    const newMenu = { ...menu };
    newMenu['hideUnavailablePlayers'] = isToggled;

    updateTeamOrOwnTeamMenu(newMenu, teamOrSquadId, userConfig.email, userConfig.club);
  };


  const toggleScrollTables = (isToggled: boolean, toggleProperty?: string) => {
    if (!userConfig || !settings || !toggleProperty) return;

    const newMenu = { ...menu };
    newMenu[toggleProperty] = isToggled;

    updateTeamOrOwnTeamMenu(newMenu, teamOrSquadId, userConfig.email, userConfig.club);
  };


  const handleIconClick = (option: string, isOptionAlreadySelected: boolean, iconProperty: string, event: React.MouseEvent<HTMLElement>) => {
    if (!userConfig || !settings) return;
    event.stopPropagation();

    const newMenu = { ...menu };
    newMenu[iconProperty] = isOptionAlreadySelected ? null : option;

    if (iconProperty === 'colored' && !isOptionAlreadySelected) {
      newMenu[option] = true;

      if (option === 'club_index') {
        newMenu['skill_index'] = false;
      }
      else if (option === 'skill_index') {
        newMenu['club_index'] = false;
      }
      else if (option === 'birth_date') {
        newMenu['age'] = false;
      }
      else if (option === 'age') {
        newMenu['birth_date'] = false;
      }
    }

    updateTeamOrOwnTeamMenu(newMenu, teamOrSquadId, userConfig.email, userConfig.club);
  };


  const resetTeam = async () => {
    if (!userConfig || !teamOrSquadId || !teamOrSquadData || teamButtonDisabled) return;
    setTeamButtonDisabled(true);

    await resetTeamOrSquad(teamOrSquadId, teamOrSquadData, isSquad ?? false, userConfig.email, userConfig.club);

    setIsIconHighlighted('reset');
    setTimeout(() => {
      setIsIconHighlighted(undefined);
      setTeamButtonDisabled(false);
    }, 750);
  };


  const archiveTeam = async () => {
    if (!userConfig || !teamOrSquadId || !teamOrSquadData || !formation || teamButtonDisabled) return;
    setTeamButtonDisabled(true);

    await archiveTeamOrSquad(teamOrSquadId, teamOrSquadData, isSquad ?? false, formation, userConfig.email, userConfig.club);

    setIsIconHighlighted('archive');
    setTimeout(() => {
      setIsIconHighlighted(undefined);
      setTeamButtonDisabled(false);
    }, 750);
  };


  useEffect(() => {
    const timer = setTimeout(() => {
      setShowContent(true);
    }, 250);

    return () => clearTimeout(timer);
  }, []);


  return (
    <div
      className='team-menu-container'
      style={{
        top: teamOrSquadId === 'ownTeam' ? '2vh' : teamOrSquadId === 'academyTeam' ? 0 : '-2vh',
        height: (teamOrSquadId === 'academyTeam' || teamOrSquadId === 'ownTeam') ? '100%' : undefined,
      }}
    >
      {teamOrSquadId === 'ownTeam' && showFirstEleven !== undefined && setShowFirstEleven && (
        <div className='team-menu-toggle-container' style={{ marginTop: '-4vh' }}>
          <div className='team-menu-toggle-section' style={{ width: userConfig && userConfig.language === 'no' ? 145 : 170 }}>
            <div className='team-menu-toggle-text'>
              {showContent && userConfig ? staticLanguageMap['showStartingEleven'][userConfig.language] : ''}
            </div>
            <div className='team-menu-toggle'>
              {showContent && <Toggle isToggled={showFirstEleven} setIsToggled={setShowFirstEleven} boxShadow={'0px 0px 2px 1px #181a2366'} />}
            </div>
          </div>
        </div>
      )}

      {teamOrSquadId === 'ownTeam' && (
        <div className='team-menu-toggle-container' style={{ marginTop: 10 }}>
          <div
            className='team-menu-toggle-section'
            title={userConfig ? staticLanguageMap['hideUnavailablePlayersDescription'][userConfig.language] : ''}
            style={{ width: userConfig && userConfig.language === 'no' ? 145 : 170 }}
          >
            <div className='team-menu-toggle-text'>
              {showContent && userConfig ? staticLanguageMap['hideUnavailablePlayers'][userConfig.language] : ''}
            </div>
            <div className='team-menu-toggle'>
              {showContent && (
                <Toggle
                  isToggled={menu['hideUnavailablePlayers']}
                  setIsToggled={toggleUnavailablePlayers}
                  boxShadow={'0px 0px 2px 1px #181a2366'}
                />
              )}
            </div>
          </div>
        </div>
      )}

      {teamOrSquadId === 'academyTeam' && (
        <div className='team-menu-toggle-container' style={{ marginTop: 0 }}>
          <div
            className='team-menu-toggle-section'
            title={undefined}
            style={{ width: 145 }}
          >
            <div className='team-menu-toggle-text'>
              {showContent && userConfig ? staticLanguageMap['scrollGoalkeepers'][userConfig.language] : ''}
            </div>
            <div className='team-menu-toggle'>
              {showContent && (
                <Toggle
                  isToggled={menu['scrollGoalkeepers']}
                  setIsToggled={toggleScrollTables}
                  boxShadow={'0px 0px 2px 1px #181a2366'}
                  callBackProperty={'scrollGoalkeepers'}
                />
              )}
            </div>
          </div>
        </div>
      )}

      {teamOrSquadId === 'academyTeam' && (
        <div className='team-menu-toggle-container' style={{ marginTop: 10 }}>
          <div
            className='team-menu-toggle-section'
            title={undefined}
            style={{ width: 145 }}
          >
            <div className='team-menu-toggle-text'>
              {showContent && userConfig ? staticLanguageMap['scrollDefenders'][userConfig.language] : ''}
            </div>
            <div className='team-menu-toggle'>
              {showContent && (
                <Toggle
                  isToggled={menu['scrollDefenders']}
                  setIsToggled={toggleScrollTables}
                  boxShadow={'0px 0px 2px 1px #181a2366'}
                  callBackProperty={'scrollDefenders'}
                />
              )}
            </div>
          </div>
        </div>
      )}

      {teamOrSquadId === 'academyTeam' && (
        <div className='team-menu-toggle-container' style={{ marginTop: 10 }}>
          <div
            className='team-menu-toggle-section'
            title={undefined}
            style={{ width: 145 }}>
            <div className='team-menu-toggle-text'
            >
              {showContent && userConfig ? staticLanguageMap['scrollMidfielders'][userConfig.language] : ''}
            </div>
            <div className='team-menu-toggle'>
              {showContent && (
                <Toggle
                  isToggled={menu['scrollMidfielders']}
                  setIsToggled={toggleScrollTables}
                  boxShadow={'0px 0px 2px 1px #181a2366'}
                  callBackProperty={'scrollMidfielders'}
                />
              )}
            </div>
          </div>
        </div>
      )}

      {teamOrSquadId === 'academyTeam' && (
        <div className='team-menu-toggle-container' style={{ marginTop: 10 }}>
          <div
            className='team-menu-toggle-section'
            title={undefined}
            style={{ width: 145 }}>
            <div className='team-menu-toggle-text'
            >
              {showContent && userConfig ? staticLanguageMap['scrollAttackers'][userConfig.language] : ''}
            </div>
            <div className='team-menu-toggle'>
              {showContent && (
                <Toggle
                  isToggled={menu['scrollAttackers']}
                  setIsToggled={toggleScrollTables}
                  boxShadow={'0px 0px 2px 1px #181a2366'}
                  callBackProperty={'scrollAttackers'}
                />
              )}
            </div>
          </div>
        </div>
      )}

      {/* {teamOrSquadId !== 'ownTeam' && teamOrSquadId !== 'academyTeam' &&
      <div className='team-menu-toggle-container'>
        <div
        className='team-menu-toggle-section'
        title={undefined}
        style={{ width: 115 }}>
          <div className='team-menu-toggle-text'>
            {showContent && userConfig ? staticLanguageMap['scrollPlayers'][userConfig.language] : ''}
          </div>
          <div className='team-menu-toggle'>
            {showContent &&
            <Toggle
            isToggled={menu['scrollPlayers_' + teamOrSquadId]}
            setIsToggled={toggleScrollTables}
            boxShadow={'0px 0px 2px 1px #181a2366'}
            callBackProperty={'scrollPlayers_' + teamOrSquadId}/>}
          </div>
        </div>
      </div>} */}

      {!showFirstEleven && (
        <div
          className='team-menu-player-info-section'
          style={{
            paddingTop: (teamOrSquadId === 'academyTeam' || teamOrSquadId === 'ownTeam') ? '2vh' : undefined,
          }}
        >
          <div className='team-menu-title fade-in' style={{ marginBottom: (teamOrSquadId === 'ownTeam' || teamOrSquadId === 'academyTeam') ? 0 : '1vh' }}>
            {showContent && userConfig ? staticLanguageMap['showPlayerInfo'][userConfig.language] : ''}
          </div>

          {menuOptions.map((displayOption: string, index: number) => {
            const property = displayOptionToPropertyMap[displayOption];

            const marginTop = (index === 0 || property === 'birth_date' || property === 'skill_index')
              ? (teamOrSquadId === 'ownTeam' ? '1.7vh' : '1.9vh')
              : (teamOrSquadId === 'ownTeam' ? '2.5vh' : '2.7vh');

            const hasIcon = propertiesWithIcons.includes(property);

            const isSelected = menu[property];
            const isOrderedBy = menu.orderBy === property;
            const isColored = menu.colored === property;

            return (
              <div
                key={index}
                className={'team-menu-info-option fade-in' + (isSelected ? ' team-menu-info-option-selected' : '')}
                style={{ marginTop: marginTop, borderColor: isSelected ? '#b3cbee80' : '#ffffff00' }}
                onClick={() => handleOptionClick(property, isSelected)}
              >
                {showContent && userConfig ? staticLanguageMap[displayOption][userConfig.language] : ''}

                {hasIcon && (
                  <div
                    className={'team-menu-info-option-icon team-menu-info-option-icon-color-toggle' + (isColored ? ' team-menu-info-option-icon-selected' : '')}
                    onClick={(event) => handleIconClick(property, isColored, 'colored', event)}
                    title={userConfig ? staticLanguageMap['showColors'][userConfig.language] : ''}
                  >
                    <BrushIcon style={{ fontSize: 17 }} />
                  </div>
                )}

                {hasIcon && (
                  <div
                    className={'team-menu-info-option-icon' + (isOrderedBy ? ' team-menu-info-option-icon-selected' : '')}
                    onClick={(event) => handleIconClick(property, isOrderedBy, 'orderBy', event)}
                    title={userConfig ? staticLanguageMap['orderBy'][userConfig.language] : ''}
                  >
                    <SwapVertIcon style={{ fontSize: 17 }} />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}

      {!showFirstEleven && (
        <div className='team-menu-team-actions-section'>
          <div className='team-menu-title fade-in' style={{ marginBottom: (teamOrSquadId === 'ownTeam' || teamOrSquadId === 'academyTeam') ? 0 : '1vh' }}>
            {showContent && userConfig
              ? (isSquad ? staticLanguageMap['squadActions'][userConfig.language] : staticLanguageMap['teamActions'][userConfig.language])
              : ''
            }
          </div>

          <div
            className='team-menu-info-option fade-in'
            style={{ marginTop: '1.9vh' }}
            onClick={() => handleOpenConfirmModal(archiveTeam, isSquad ? 'archiveSquad' : 'archiveTeam', isSquad ? 'archiveSquadInfo' : 'archiveTeamInfo')}
          >
            {showContent && userConfig
              ? (isSquad ? staticLanguageMap['archiveSquad'][userConfig.language] : staticLanguageMap['archiveTeam'][userConfig.language])
              : ''
            }
            <div
              className={
                'team-menu-info-option-icon team-menu-info-option-icon-no-hover' +
                (isIconHighlighted === 'archive' ? ' team-menu-info-option-icon-highlighted' : '')
              }
            >
              <SaveAltIcon style={{ fontSize: 17 }} />
            </div>
          </div>

          {teamOrSquadId !== 'ownTeam' && teamOrSquadId !== 'academyTeam' && (
            <div
              className='team-menu-info-option fade-in'
              style={{ marginTop: '1.9vh' }}
              onClick={() => handleOpenConfirmModal(resetTeam, isSquad ? 'resetSquad' : 'resetTeam', isSquad ? 'resetSquadInfo' : 'resetTeamInfo')}
            >
              {showContent && userConfig
                ? (isSquad ? staticLanguageMap['resetSquad'][userConfig.language] : staticLanguageMap['resetTeam'][userConfig.language])
                : ''
              }
              <div
                className={
                  'team-menu-info-option-icon team-menu-info-option-icon-no-hover' +
                  (isIconHighlighted === 'reset' ? ' team-menu-info-option-icon-highlighted' : '')
                }
              >
                <ReplayIcon style={{ fontSize: 17 }} />
              </div>
            </div>
          )}
        </div>
      )}

      {showFirstEleven && benchPlayers && (
        <div className='own-team-menu-bench-players-table-container fade-in'>
          <div className='own-team-menu-bench-players-table'>
            <PlayerSimpleTable
              data={benchPlayers}
              tableType={'bench'}
              maxHeight={'74vh'}
              positionKey={'bench'}
              draggedPlayer={draggedPlayer}
              setDraggedPlayer={setDraggedPlayer}
              handlePlayerDrop={handlePlayerDrop}
            />
          </div>
        </div>
      )}

    </div>
  );
};
