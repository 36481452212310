import { selector } from 'recoil';
import { squadsState } from '../atoms/squadsState';
import { PlayerMapping, PlayerEntry } from '../../types';
import { positionOptionsPlatform } from '../../static/propertyValues';


export const playerSquadDataState = selector({
  key: 'playerSquadData',
  get: ({ get }) => {
    const squads = get(squadsState);

    const playerSquadData: PlayerMapping = {};

    if (squads) {
      positionOptionsPlatform.forEach(positionKey => {
        Object.keys(squads).forEach(squadId => {

          const squadData = squads[squadId];
          if (positionKey in squadData) {

            const positionArray = squadData[positionKey];
            positionArray.forEach((player: PlayerEntry) => {

              if (player.id in playerSquadData) {
                playerSquadData[player.id][squadId] = positionKey;
              }
              else {
                playerSquadData[player.id] = {
                  [squadId]: positionKey,
                };
              }

            });
          }
        });
      });
    }

    return playerSquadData;
  },
});
