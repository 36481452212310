import { CellProps } from 'react-table';
import { CompetitionMap, FlexibleJsonMapping, JsonMapping, UserConfig } from '../../../types';
import { staticLanguageMap } from '../../../../common/static/staticLanguageMap';
import { countryCodeToCountryInfo } from '../../../static/countries';
import { Toggle } from '../../input/Toggle';
import { updateSeasonStatsToggles } from '../../../services/firestore/settings';


const renderSeasonCell = (competitions: CompetitionMap) => {
  const SeasonCellRenderer = ({ row }: FlexibleJsonMapping) => {
    return <SeasonCell row={row} competitions={competitions} />;
  };

  SeasonCellRenderer.displayName = 'SeasonCellRenderer';
  return SeasonCellRenderer;
};

const SeasonCell: React.FC<{ row: FlexibleJsonMapping, competitions: CompetitionMap, }> = ({ row, competitions }) => {

  const competition = competitions[row.original.competition_id];

  const flagUrl = (competition && competition.countryCode && countryCodeToCountryInfo[competition.countryCode])
    ? countryCodeToCountryInfo[competition.countryCode].flagUrl
    : undefined;

  let competitionName = competition?.name;

  // temporary fix for duplicate entries
  if (competitionName && competitionName[competitionName.length - 1] === ')' && competitionName[competitionName.length - 3] === '(') {
    competitionName = competitionName.slice(0, -4);
  }

  return (
    <div className='season-table-info-cell career-table-info-cell'>

      <div className='season-table-info-cell-season-column career-table-info-cell-season-column'>

        <div className='season-table-info-cell-row' style={{ marginTop: 2, marginBottom: 2 }}>
          {flagUrl && (
            <img
              className='season-table-info-cell-flag'
              src={flagUrl}
              alt=''
              draggable={false}
            />
          )}

          {competitionName}
        </div>

        <div className='season-table-info-cell-row' style={{ marginTop: 2, marginBottom: 2 }}>
          {row.original.club?.logo_url && (
            <div className='season-table-club-logo-container'>
              <img
                className='season-table-club-logo'
                src={row.original.club?.logo_url}
                alt=''
                draggable={false}
              />
            </div>
          )}

          <div style={{ fontSize: 12 }}>
            {row.original.club?.name}
          </div>
        </div>

      </div>

      <div className='season-table-info-cell-info-column' style={{ width: 50, paddingRight: 15 }}>
        <div className='season-table-info-cell-row' style={{ justifyContent: 'end' }}>
          {row.original.season}
        </div>
      </div>

    </div>
  );
};


const renderNationalTeamCell = (language: string) => {
  const NationalTeamCellRenderer = ({ row }: FlexibleJsonMapping) => {
    return <NationalTeamCell row={row} language={language} />;
  };

  NationalTeamCellRenderer.displayName = 'NationalTeamCellRenderer';
  return NationalTeamCellRenderer;
};

const NationalTeamCell: React.FC<{ row: FlexibleJsonMapping, language: string, }> = ({ row, language }) => {

  const flagUrl = row.original.country_code in countryCodeToCountryInfo ? countryCodeToCountryInfo[row.original.country_code].flagUrl : undefined;
  const countryName = row.original.country_code in countryCodeToCountryInfo ? countryCodeToCountryInfo[row.original.country_code].name[language] : undefined;

  return (
    <div className='career-table-national-info-cell'>

      <div className='season-table-info-cell-row'>
        {flagUrl && (
          <img
            className='season-table-info-cell-flag'
            src={flagUrl}
            alt=''
            draggable={false}
          />
        )}

        <div style={{ marginRight: 4 }}>
          {countryName}
        </div>

        {row.original.club?.name}
      </div>

    </div>
  );
};


const TextCell: React.FC<CellProps<JsonMapping, string>> = ({ value }) => {
  return <div className='season-table-text-cell'>{value ?? '-'}</div>;
};


const renderPositionCell = (language: string) => {
  const PositionCellRenderer = ({ row }: FlexibleJsonMapping) => {
    return <PositionCell row={row} language={language} />;
  };

  PositionCellRenderer.displayName = 'PositionCellRenderer';
  return PositionCellRenderer;
};

const PositionCell: React.FC<{ row: FlexibleJsonMapping, language: string, }> = ({ row, language }) => {
  let position = row.original.primary_position;
  if (position in staticLanguageMap) {
    position = staticLanguageMap[position][language];
  }

  if (row.original.secondary_position) {
    let secondary_position = row.original.secondary_position;
    if (secondary_position in staticLanguageMap) {
      secondary_position = staticLanguageMap[secondary_position][language];
    }
    position += ' (' + secondary_position + ')';
  }
  return <div className='season-table-text-cell'>{position}</div>;
};


export const getCareerColumns = (
  isNationalTeam: boolean,
  userConfig: UserConfig | null,
  tableWidth: number,
  competitions: CompetitionMap,
  seasonStatsToggles: FlexibleJsonMapping,
) => {

  if (!userConfig) return [];

  const stickyColumnWidth = 330;
  const remainingWidth = tableWidth - stickyColumnWidth;

  if (isNationalTeam) {
    const columns: FlexibleJsonMapping[] = [
      {
        Header:
          <div className='career-table-top-level-header-cell-sticky'>
            {staticLanguageMap['nationalTeamTitle'][userConfig.language]}
          </div>,
        accessor: 'club.name',
        sticky: 'left',
        Cell: renderNationalTeamCell(userConfig.language),
        width: stickyColumnWidth,
        isStickyColumn: true,
      },
      {
        Header: staticLanguageMap['matches'][userConfig.language],
        accessor: 'appearances',
        Cell: TextCell,
        width: remainingWidth * 0.2,
        minWidth: 80,
      },
      {
        Header: staticLanguageMap['starts'][userConfig.language],
        accessor: 'games_started',
        Cell: TextCell,
        width: remainingWidth * 0.2,
        minWidth: 90,
      },
      {
        Header: staticLanguageMap['position'][userConfig.language],
        accessor: 'position',
        Cell: renderPositionCell(userConfig.language),
        width: remainingWidth * 0.2,
        minWidth: 90,
      },
      {
        Header: staticLanguageMap['goals'][userConfig.language],
        accessor: 'n_goals',
        Cell: TextCell,
        width: remainingWidth * 0.2,
        minWidth: 90,
      },
      {
        Header: staticLanguageMap['assists'][userConfig.language],
        accessor: 'n_assists',
        Cell: TextCell,
        width: remainingWidth * 0.2,
        minWidth: 90,
      },
    ];

    return columns;
  }

  const columns: FlexibleJsonMapping[] = [
    {
      Header:
        <div className='career-table-top-level-header-cell-sticky'>
          <div style={{ width: 140 }}>
            {staticLanguageMap['clubTeamTitle'][userConfig.language]}
          </div>

          <div
            className='season-table-top-level-header-cell-sticky-toggle-section'
            title={staticLanguageMap['showOnlyDomesticLeagues'][userConfig.language]}
            style={{ width: 140, justifyContent: 'end' }}>
            {staticLanguageMap['leagues'][userConfig.language]}
            <div className='season-table-top-level-header-cell-sticky-toggle'>
              <Toggle
                isToggled={seasonStatsToggles['hideCareerCups']}
                setIsToggled={(value: boolean) => updateSeasonStatsToggles(
                  { ...seasonStatsToggles, 'hideCareerCups': value },
                  userConfig.email,
                  userConfig.club)}
                isSmall={true}
              />
            </div>
          </div>
        </div>,
      accessor: 'season',
      sticky: 'left',
      Cell: renderSeasonCell(competitions),
      width: stickyColumnWidth,
      isStickyColumn: true,
    },
    {
      Header: staticLanguageMap['matches'][userConfig.language],
      accessor: 'appearances',
      Cell: TextCell,
      width: remainingWidth * 0.2,
      minWidth: 90,
    },
    {
      Header: staticLanguageMap['starts'][userConfig.language],
      accessor: 'games_started',
      Cell: TextCell,
      width: remainingWidth * 0.2,
      minWidth: 90,
    },
    {
      Header: staticLanguageMap['position'][userConfig.language],
      accessor: 'position',
      Cell: renderPositionCell(userConfig.language),
      width: remainingWidth * 0.2,
      minWidth: 90,
    },
    {
      Header: staticLanguageMap['goals'][userConfig.language],
      accessor: 'n_goals',
      Cell: TextCell,
      width: remainingWidth * 0.2,
      minWidth: 90,
    },
    {
      Header: staticLanguageMap['assists'][userConfig.language],
      accessor: 'n_assists',
      Cell: TextCell,
      width: remainingWidth * 0.2,
      minWidth: 90,
    },
  ];

  return columns;
};
