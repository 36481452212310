import { useEffect, useState, useRef } from 'react';


interface LazyImageProps {
  src: string;
  alt: string;
  imageClassName: string;
  imageContainerClassName: string;
}


export const LazyImage: React.FC<LazyImageProps> = ({ src, alt, imageClassName, imageContainerClassName }) => {

  const [isLoaded, setIsLoaded] = useState(false);
  const imgRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setIsLoaded(true);
          observer.unobserve(entry.target);
        }
      });
    });

    const delay = 100; // Math.floor(Math.random() * 100) + 50;

    const timer = setTimeout(() => {
      if (imgRef.current) {
        observer.observe(imgRef.current);
      }
    }, delay);

    const cleanup = () => {
      clearTimeout(timer);
      observer.disconnect();
    };

    return cleanup;
  }, []);

  return (
    <div ref={imgRef} className={imageContainerClassName}>
      {isLoaded && (
        <img
          src={src}
          alt={alt}
          className={imageClassName}
          draggable={false}
        />
      )}
    </div>
  );
};
