import './../modals.css';

import { Dispatch, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { enGB, nb } from 'date-fns/locale';

import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import UpdateIcon from '@mui/icons-material/Update';
import CheckIcon from '@mui/icons-material/Check';

import { staticLanguageMap } from '../../../../common/static/staticLanguageMap';
import { SeasonCondition, SeasonConditionStatus, SeasonOrCondition } from '../../../types';
import { getDisplayEconomicValue } from '../../../utils/currencyUtils';
import { ClauseAction } from '../../documents/clause/clauseReducer';
import { ClauseState } from '../../documents/clause/clauseInitialState';


interface SeasonConditionStatusModalProps {
  state: ClauseState;
  isResolveSeasonConditionModalOpen: boolean;
  seasonCondition: SeasonCondition;
  seasonString: string;
  dispatch: Dispatch<ClauseAction>;
  closeModal: () => void;
}

export const SeasonConditionStatusModal: React.FC<SeasonConditionStatusModalProps> = ({
  state,
  isResolveSeasonConditionModalOpen,
  seasonCondition,
  seasonString,
  closeModal,
  dispatch,
}) => {

  const userConfig = useRecoilValue(userConfigState);

  const [isResolveConditionView, setIsResolveConditionView] = useState<boolean>(false);

  const dateFormat = 'dd.MM.yyyy';
  const locale = userConfig && userConfig.language === 'no'
    ? nb
    : enGB;

  const minPaymentDate = new Date((new Date()).setFullYear((new Date()).getFullYear() - 10));
  const maxPaymentDate = new Date((new Date()).setFullYear((new Date()).getFullYear() + 10));

  const [paymentDate, setPaymentDate] = useState<Date | null>(null);

  const seasonStatus: SeasonConditionStatus = seasonCondition.seasonToCurrentStatuses[seasonString];
  const alreadyAchievedOrConditionIndex: number = seasonStatus?.orConditionStatuses?.findIndex((status: string | null) => status === 'achieved') ?? -1;

  const [achievedOrConditionIndex, setAchievedOrConditionIndex] = useState<number | undefined>(
    alreadyAchievedOrConditionIndex !== -1 ? alreadyAchievedOrConditionIndex : undefined
  );


  const handleSetPaymentDate = (date: Date | null) => {
    if (!date) {
      setPaymentDate(null);
      return;
    }
    const adjustedDate = new Date(date);
    adjustedDate.setHours(12);
    setPaymentDate(adjustedDate);
  };


  const onKeyDownInputField = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleResolveCondition('resolved');
    }
  };


  const handleResolveCondition = (newStatus: string | null) => {
    const payload = {
      conditionId: seasonCondition.conditionId,
      seasonString: seasonString,
      newStatus: newStatus,
      achievedOrConditionIndex: achievedOrConditionIndex,
      date: paymentDate
    };

    dispatch({ type: 'UPDATE_SEASON_CONDITION_STATUS', payload: payload });
    closeModal();
  };


  // If condition is already resolved, it may be discarded or reactivated
  // If condition is discarded, it may be resolved or reactivated
  // If condition does not have a status, it may be resolved or discarded

  const overallStatus = seasonStatus?.overallStatus;


  const stateAccessor = state.additionalClauseState.activeSubClauseTab !== undefined && state.subClauses
    ? state.subClauses[state.additionalClauseState.activeSubClauseTab]
    : state;

  const isMaxConditionalFeeReached = stateAccessor.maxConditionalFees !== null &&
    (stateAccessor.conditionalFees ?? 0) >= stateAccessor.maxConditionalFees;

  const shouldDisableModal = isMaxConditionalFeeReached && overallStatus !== 'resolved';

  return (
    <div className='modal-root-container resolve-condition-modal-root'>

      <CloseIcon
        className='modal-icon modal-exit-icon'
        style={{ fontSize: 24 }}
        onClick={() => closeModal()}
      />

      {isResolveConditionView && (
        <ArrowBackIcon
          className='modal-icon modal-back-icon'
          style={{ fontSize: 24 }}
          onClick={() => setIsResolveConditionView(false)} />
      )}

      <div className='modal-root-title'>
        {userConfig && isResolveSeasonConditionModalOpen
          ? (
            (
              isResolveConditionView
                ? staticLanguageMap['resolveCondition'][userConfig.language]
                : overallStatus === 'resolved'
                  ? staticLanguageMap['reactivateOrDiscardCondition'][userConfig.language]
                  : overallStatus === 'discarded'
                    ? staticLanguageMap['resolveOrReactivateCondition'][userConfig.language]
                    : staticLanguageMap['resolveOrDiscardCondition'][userConfig.language]
            ) +
            ' ' +
            staticLanguageMap['for'][userConfig.language] +
            ' ' +
            seasonString
          )
          : ''}
      </div>

      <div className='modal-divider modal-root-title-divider'>&nbsp;</div>

      {shouldDisableModal && (
        <div className='modal-content-section'>
          <div style={{ margin: 'auto', paddingBottom: 15 }}>
            {userConfig ? staticLanguageMap['maxAmountReachedMessage'][userConfig.language] : ''}
          </div>
        </div>
      )}

      {!shouldDisableModal && (
        <div className='modal-content-section'>
          {!isResolveConditionView && (
            <div className='condition-modal-new-condition-button-column' style={{ height: 340 }}>
              {overallStatus !== 'resolved' && (
                <div
                  className='document-submit-button new-document-button'
                  style={{ width: 200, height: 30 }}
                  onClick={() => setIsResolveConditionView(true)}>
                  <div className='document-submit-button-text'>
                    {userConfig ? staticLanguageMap['resolveCondition'][userConfig.language] : ''}
                  </div>
                  <div className='document-button-icon new-document-button-icon'>
                    <TaskAltIcon style={{ fontSize: 21, marginTop: 2 }} />
                  </div>
                </div>
              )}
              {overallStatus && (
                <div
                  className='document-submit-button new-document-button'
                  style={{ width: 200, height: 30 }}
                  onClick={() => handleResolveCondition(null)}>
                  <div className='document-submit-button-text'>
                    {userConfig ? staticLanguageMap['reactivateCondition'][userConfig.language] : ''}
                  </div>
                  <div className='document-button-icon new-document-button-icon'>
                    <UpdateIcon style={{ fontSize: 21, marginTop: 2 }} />
                  </div>
                </div>
              )}
              {overallStatus !== 'discarded' && (
                <div
                  className='document-submit-button new-document-button'
                  style={{ width: 200, height: 30 }}
                  onClick={() => handleResolveCondition('discarded')}>
                  <div className='document-submit-button-text'>
                    {userConfig ? staticLanguageMap['discardCondition'][userConfig.language] : ''}
                  </div>
                  <div className='document-button-icon new-document-button-icon'>
                    <CancelOutlinedIcon style={{ fontSize: 21, marginTop: 2 }} />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {isResolveConditionView && (
        <div className='modal-content-section'>

          {seasonCondition.amount && (
            <div className='resolve-condition-modal-info-row'>
              {getDisplayEconomicValue(seasonCondition.amount, true)}
            </div>
          )}

          {!seasonCondition.amount && seasonCondition.orConditions && alreadyAchievedOrConditionIndex !== -1 && (
            <div className='resolve-condition-modal-info-row'>
              {getDisplayEconomicValue(seasonCondition.orConditions[alreadyAchievedOrConditionIndex].amount, true)}
            </div>
          )}

          {!seasonCondition.amount && alreadyAchievedOrConditionIndex === -1 && (
            <div className='resolve-condition-modal-amount-row'>
              {seasonCondition.orConditions && seasonCondition.orConditions.map((orCondition: SeasonOrCondition, index: number) => {
                return (
                  <div
                    key={index}
                    className={'resolve-condition-modal-amount' + (index === achievedOrConditionIndex ? ' resolve-condition-modal-amount-selected' : '')}
                    onClick={() => setAchievedOrConditionIndex(index)}>
                    {getDisplayEconomicValue(orCondition.amount, true)}
                  </div>
                );
              })}
            </div>
          )}

          <div className='margin-auto' style={{ marginTop: 30 }}>
            <DatePicker
              className='document-input-field'
              selected={paymentDate}
              onChange={(date: Date | null) => handleSetPaymentDate(date)}
              onKeyDown={(event) => onKeyDownInputField(event)}
              locale={locale}
              dateFormat={dateFormat}
              placeholderText={userConfig ? staticLanguageMap['paymentDate'][userConfig.language] + '?' : ''}
              popperPlacement='bottom'

              minDate={minPaymentDate}
              maxDate={maxPaymentDate}
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={100}
              showMonthDropdown
            />
          </div>
        </div>
      )}

      {isResolveConditionView && (
        <div
          className={'modal-button modal-button-bottom' + (!(seasonCondition.amount || achievedOrConditionIndex !== undefined) ? ' modal-button-disabled' : '')}
          style={{ marginLeft: 150 }}
          onClick={() => (seasonCondition.amount || achievedOrConditionIndex !== undefined) ? handleResolveCondition('resolved') : null}
        >
          <div className='modal-button-text'>
            {userConfig ? staticLanguageMap['resolveCondition'][userConfig.language] : ''}
          </div>

          <CheckIcon className='modal-button-icon' style={{ fontSize: 24 }} />
        </div>
      )}

    </div>
  );
};
