import '../../platform.css';
import './ownTeam.css';

import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { settingsState } from '../../recoil/atoms/settingsState';

import { FlexibleJsonMapping, PlayerId, PlayerMapping } from '../../types';
import { OwnTeamTable } from '../../components/tables/ownTeamTable/OwnTeamTable';
import { getMonthsLeftOfContract } from '../../utils/playerUtils';
import { academyRoleKeysToSortOrder, localityKeysToSortOrder, roleKeysToSortOrder } from '../../static/propertyValues';
import { getRoundedEconomicValue } from '../../utils/currencyUtils';


interface OwnTeamTableViewProps {
  ownTeamPlayers: PlayerMapping;
  academyTeamPlayers: PlayerMapping;
}

export const OwnTeamTableView: React.FC<OwnTeamTableViewProps> = ({ ownTeamPlayers, academyTeamPlayers }) => {

  const settings = useRecoilValue(settingsState);

  const [ownTeamData, setOwnTeamData] = useState<FlexibleJsonMapping[]>([]);
  const [academyTeamData, setAcademyTeamData] = useState<FlexibleJsonMapping[]>([]);

  const [tableSortBy, setTableSortBy] = useState<FlexibleJsonMapping | undefined>(undefined);
  const [lastTableSortBy, setLastTableSortBy] = useState<FlexibleJsonMapping | undefined>(undefined);

  const [lastOwnTeamTableSortedOrder, setLastOwnTeamTableSortedOrder] = useState<PlayerId[] | undefined>(undefined);
  const [lastAcademyTableSortedOrder, setLastAcademyTableSortedOrder] = useState<PlayerId[] | undefined>(undefined);


  const preparePlayers = (
    players: PlayerMapping,
    setData: (data: FlexibleJsonMapping[]) => void,
    lastSortedOrder: PlayerId[] | undefined,
    setLastSortedOrder: (value: PlayerId[] | undefined) => void,
    roleKeys: { [key: string]: number },
  ) => {
    if (Object.keys(players).length === 0) return;

    let tableData: FlexibleJsonMapping[] = [];

    if (!lastSortedOrder || tableSortBy !== lastTableSortBy) {
      tableData = Object.values(players).sort((a: FlexibleJsonMapping, b: FlexibleJsonMapping) => {
        const tableSortByToUse = tableSortBy ?? { id: 'role', desc: true };

        const sortProperty = tableSortByToUse.id;
        const oneValue = tableSortByToUse.desc ? 1 : -1;
        const minusOneValue = tableSortByToUse.desc ? -1 : 1;

        if (!a[sortProperty]) return oneValue;
        if (!b[sortProperty]) return minusOneValue;

        const aValue = tableSortByToUse.id === 'role'
          ? (a[sortProperty] in roleKeys ? roleKeys[a[sortProperty]] : 0)
          : tableSortByToUse.id === 'locality'
            ? (a[sortProperty] in localityKeysToSortOrder ? localityKeysToSortOrder[a[sortProperty]] : 0)
            : a[sortProperty];

        const bValue = tableSortByToUse.id === 'role'
          ? (b[sortProperty] in roleKeys ? roleKeys[b[sortProperty]] : 0)
          : tableSortByToUse.id === 'locality'
            ? (b[sortProperty] in localityKeysToSortOrder ? localityKeysToSortOrder[b[sortProperty]] : 0)
            : b[sortProperty];

        return aValue > bValue ? minusOneValue : oneValue;
      });
    }
    else {
      tableData = lastSortedOrder.filter((id: PlayerId) => players[id]).map((id: PlayerId) => players[id]);
    }

    setLastSortedOrder(tableData.map((player: FlexibleJsonMapping) => player.id));
    setLastTableSortBy(tableSortBy);

    let totalNumberOfPlayers = 0;
    let [totalAge, playersWithAge]: [number, number] = [0, 0];
    let [totalMonthsLeftOfContract, playersWithMonthsLeftOfContract] = [0, 0];

    let totalMarketValue = 0;
    let totalSigningCost = 0;
    let totalSalary = 0;
    let totalBonuses = 0;

    const countryCodeMap: { [key: string]: number } = {};
    const localityMap: { [key: string]: number } = {};
    const roleMap: { [key: string]: number } = {};

    tableData.forEach((player: FlexibleJsonMapping) => {
      totalNumberOfPlayers++;

      if (player.age) {
        totalAge += player.age;
        playersWithAge++;
      }

      if (player.contract_expiration) {
        totalMonthsLeftOfContract += getMonthsLeftOfContract(player.contract_expiration) as number;
        playersWithMonthsLeftOfContract++;
      }

      if (player.market_value) totalMarketValue += player.market_value;
      if (player.signingCost) totalSigningCost += player.signingCost;
      if (player.salary) totalSalary += player.salary;
      if (player.bonuses) totalBonuses += player.bonuses;

      if (player.country_code) {
        if (countryCodeMap[player.country_code]) {
          countryCodeMap[player.country_code]++;
        }
        else {
          countryCodeMap[player.country_code] = 1;
        }
      }

      if (player.locality) {
        if (localityMap[player.locality]) {
          localityMap[player.locality]++;
        }
        else {
          localityMap[player.locality] = 1;
        }
      }

      if (player.role) {
        if (roleMap[player.role]) {
          roleMap[player.role]++;
        }
        else {
          roleMap[player.role] = 1;
        }
      }
    });

    const nationalCountryCode = settings?.clubSettings?.countryCode ?? 'NO';
    let numberOfNatives = 0;
    let numberOfForeigners = 0;
    for (const code in countryCodeMap) {
      if (code === nationalCountryCode) {
        numberOfNatives += countryCodeMap[code];
      }
      else {
        numberOfForeigners += countryCodeMap[code];
      }
    }

    tableData.push({
      'fullname': 'tableSummary',

      'averageAge': playersWithAge ? (Math.round((totalAge / playersWithAge) * 10) / 10) : undefined,
      'averageMonthsLeftOfContract': playersWithMonthsLeftOfContract ? Math.round(totalMonthsLeftOfContract / playersWithMonthsLeftOfContract) : undefined,

      'market_valueTotal': totalMarketValue ? getRoundedEconomicValue(totalMarketValue) : undefined,
      'signingCostTotal': totalSigningCost ? getRoundedEconomicValue(totalSigningCost) : undefined,
      'salaryTotal': totalSalary ? getRoundedEconomicValue(totalSalary) : undefined,
      'bonusesTotal': totalBonuses ? getRoundedEconomicValue(totalBonuses) : undefined,

      'numberOfLocalPlayers': localityMap['local'] ?? 0,
      'numberOfNationalPlayers': localityMap['national'] ?? 0,
      'numberOfInternationalPlayers': localityMap['international'] ?? 0,

      'nationalCountryCode': nationalCountryCode,
      'numberOfNatives': numberOfNatives,
      'numberOfForeigners': numberOfForeigners,

      'roleMap': roleMap,

      'numberOfPlayers': totalNumberOfPlayers,
    });

    setData(tableData);
  };


  useEffect(() => {
    preparePlayers(
      ownTeamPlayers,
      setOwnTeamData,
      lastOwnTeamTableSortedOrder,
      setLastOwnTeamTableSortedOrder,
      roleKeysToSortOrder,
    );
  }, [ownTeamPlayers, tableSortBy]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    preparePlayers(
      academyTeamPlayers,
      setAcademyTeamData,
      lastAcademyTableSortedOrder,
      setLastAcademyTableSortedOrder,
      academyRoleKeysToSortOrder,
    );
  }, [academyTeamPlayers, tableSortBy]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div className='own-team-view-container'>

      <div className='own-team-table-container'>
        <OwnTeamTable
          data={settings?.userSettings.seasonStatsToggles?.showAcademyPlayersInOwnTeamTable ? academyTeamData : ownTeamData}
          tableSortBy={tableSortBy}
          setTableSortBy={setTableSortBy}
        />
      </div>

    </div>
  );
};
