import { CompetitionMap, FlexibleJsonMapping, PlayerId, UserConfig } from '../types';
import { staticLanguageMap } from '../../common/static/staticLanguageMap';
import { academyRoleKeysToSortOrder, accessKeysToSortOrder, roleKeysToSortOrder } from '../static/propertyValues';


// Get playerId, where a number is returned if possible, else string
export const getStrictPlayerId = (playerId: PlayerId) => {
  const numberId = Number(playerId);
  if (isNaN(numberId)) {
    return playerId;
  }
  return numberId;
};


// If player has more than three names, returns first name and last name and initials of middle names
export const getShortPlayerName = (playerName: string) => {
  if (playerName === undefined) return ' ';

  playerName = playerName.trim().replace('"', '');

  const names = playerName.split(' ');
  if (names.length < 3) {
    return playerName;
  }
  let shortenedName = names[0];
  for (let i = 1; i < names.length - 1; i++) {
    if (names[i][0] !== undefined) {
      shortenedName += ' ' + names[i][0] + '.';
    }
  }
  shortenedName += ' ' + names[names.length - 1];
  return shortenedName;
};


// Get initial of first name and full last name
export const getShortestPlayerName = (playerName: string) => {
  if (playerName === undefined) return ' ';

  playerName = playerName.trim().replace('"', '');

  const names = playerName.split(' ');
  if (names.length < 2) {
    return playerName;
  }

  let shortenedName = names[0][0] + '.';
  shortenedName += ' ' + names[names.length - 1];
  return shortenedName;
};


// Get shorten player name, unless full name is short enough
export const getPlayerNameWithMaxLength = (playerName: string, maxLength: number) => {
  if (playerName === undefined) return ' ';

  playerName = playerName.trim().replace('"', '');

  if (playerName.length <= maxLength) {
    return playerName;
  }

  const names = playerName.split(' ');
  if (names.length < 2) {
    return playerName;
  }

  let name = names[0];
  for (let i = 1; i < names.length - 1; i++) {
    if (names[i][0] !== undefined) {
      name += ' ' + names[i][0] + '.';
    }
  }

  const shortName = name + ' ' + names[names.length - 1];
  if (shortName.length <= maxLength) {
    return shortName;
  }

  return name + ' ' + names[names.length - 1][0] + '.';
};


// return the first name pattern that is not longer than maxLength, whith preferences fullname, shortname, firstAndLastName, shortestname
export const getShortPlayerNameWithMaxLength = (playerName: string, maxLength: number, shortestNameIsAnOption?: boolean) => {
  if (playerName === undefined) return ' ';

  playerName = playerName.trim().replace('"', '');

  if (playerName.length <= maxLength) {
    return playerName;
  }

  const names = playerName.split(' ');
  if (names.length < 2) {
    return playerName;
  }

  let shortName = names[0];
  for (let i = 1; i < names.length - 1; i++) {
    if (names[i][0] !== undefined) {
      shortName += ' ' + names[i][0] + '.';
    }
  }
  shortName = shortName + ' ' + names[names.length - 1];
  if (shortName.length <= maxLength) {
    return shortName;
  }

  const firstAndLastName = names[0] + ' ' + names[names.length - 1];
  if (firstAndLastName.length <= maxLength || !shortestNameIsAnOption) {
    return firstAndLastName;
  }

  const shortestName = names[0][0] + '. ' + names[names.length - 1];
  return shortestName;
};


// Returns first name and last name
export const getFirstAndLastName = (playerName: string) => {
  playerName = playerName.trim().replace('"', '');

  if (playerName === undefined) {
    return ' ';
  }
  const names = playerName.split(' ');
  if (names.length < 3) {
    return playerName;
  }
  return names[0] + ' ' + names[names.length - 1]; // [0] + '.';
};


// Returns first name and last name if not too long, else first initial and last name
export const getShortFirstAndLastName = (playerName: string) => {
  playerName = playerName.trim().replace('"', '');

  if (playerName === undefined) {
    return ' ';
  }
  const names = playerName.split(' ');
  if (names.length < 2) {
    return playerName;
  }

  playerName = names[0] + ' ' + names[names.length - 1];

  if (playerName.length <= 16) {
    return playerName;
  }

  return names[0][0] + '. ' + names[names.length - 1];
};


// Returns first name and initials of all other names
export const getFirstNameAndInitials = (playerName: string) => {
  if (playerName === undefined) return ' ';

  playerName = playerName.trim().replace('"', '');
  const names = playerName.split(' ');
  if (names.length < 2) {
    return playerName;
  }
  let shortenedName = names[0];
  for (let i = 1; i < names.length; i++) {
    if (names[i][0] !== undefined) {
      shortenedName += ' ' + names[i][0] + '.';
    }
  }
  return shortenedName;
};


// Gets the year of a date
export const getYear = (date: string) => {
  if (date) {
    return date.split('-')[0];
  }
  return '';
};


// Gets last two digits of the year of a date
export const getYearShort = (date: string) => {
  if (date) {
    return date.split('-')[0].substring(2);
  }
  return '';
};


// Gets the date in locale format (dd/mm/yyyy, yyyy//mm/dd, etc.)
export const getDateLocaleFormat = (date: string | undefined) => {
  if (!date) {
    return '-';
  }

  const dateList = date.split('-');

  return dateList[2] + '.' + dateList[1] + '.' + dateList[0];
};


// Get number of months left of a contract
export const getMonthsLeftOfContract = (contractExpiration: string): number | undefined => {
  if (!contractExpiration) return undefined;

  const expirationDate = new Date(contractExpiration);
  const currentDate = new Date();

  const startYear = currentDate.getFullYear();
  const startMonth = currentDate.getMonth();

  const endYear = expirationDate.getFullYear();
  const endMonth = expirationDate.getMonth();

  const monthsLeft = (endYear - startYear) * 12 + (endMonth - startMonth);

  return Math.max(0, monthsLeft);
};


// Get age based on birth date
export const getAge = (birthDate: string) => {
  if (!birthDate || birthDate === '') return undefined;

  const date = new Date(birthDate);
  const currentDate = new Date();
  const diffInMilliseconds = currentDate.getTime() - date.getTime();

  const diffInYears = diffInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);

  return Number(diffInYears.toFixed(1));
};


// Gets sort distance between two players based on role (descending, where order of roles defined above)
export const getSortDistanceRole = (a: FlexibleJsonMapping, b: FlexibleJsonMapping, useAcademyRole?: boolean) => {
  const roleKeys = useAcademyRole ? academyRoleKeysToSortOrder : roleKeysToSortOrder;

  if (!b.role || !roleKeys[b.role]) {
    return -1;
  }
  if (!a.role || !roleKeys[a.role]) {
    return 1;
  }
  return roleKeys[b.role] - roleKeys[a.role];
};


// Gets sort distance between two access categories
export const getSortDistanceAccess = (a: FlexibleJsonMapping, b: FlexibleJsonMapping) => {
  const aHasAccess = a.access && accessKeysToSortOrder[a.access];
  const bHasAccess = b.access && accessKeysToSortOrder[b.access];

  if (!bHasAccess && aHasAccess) return -1;
  if (!aHasAccess && bHasAccess) return 1;

  if (aHasAccess && bHasAccess) {
    return accessKeysToSortOrder[b.access] - accessKeysToSortOrder[a.access];
  }

  return a.name.localeCompare(b.name);
};


// Gets sort distance between two players based on a number property (descending)
export const getSortDistanceNumberProperty = (a: FlexibleJsonMapping, b: FlexibleJsonMapping, property: string) => {
  if (!a[property] && !b[property]) {
    return getSortDistanceRole(a, b);
  }
  if (!b[property]) {
    return -1;
  }
  if (!a[property]) {
    return 1;
  }
  return b[property] - a[property];
};


// Gets sort distance between two players based on a date property (descending)
export const getSortDistanceDateProperty = (a: FlexibleJsonMapping, b: FlexibleJsonMapping, property: string) => {
  if (!a[property] && !b[property]) {
    return getSortDistanceRole(a, b);
  }
  if (!a[property]) {
    return -1;
  }
  if (!b[property]) {
    return 1;
  }
  const a_time = new Date(a[property]).getTime();
  const b_time = new Date(b[property]).getTime();
  return b_time - a_time;
};


// Player property getters

export const getPlayerPropertyValue = (
  property: string,
  playerOverview?: FlexibleJsonMapping | undefined,
  playerDocument?: FlexibleJsonMapping | undefined,
  valueTransformer?: (value: any) => any, // eslint-disable-line @typescript-eslint/no-explicit-any
) => {

  // user provided values have presedence
  if (playerDocument && property in playerDocument) {
    const value = playerDocument[property];
    if (valueTransformer) {
      return valueTransformer(value);
    }
    return value;
  }

  if (playerOverview && property in playerOverview) {
    const value = playerOverview[property];
    if (valueTransformer) {
      return valueTransformer(value);
    }
    return value;
  }

  return undefined;
};


export const getDisplayPosition = (playerOverview: FlexibleJsonMapping | undefined, userConfig: UserConfig | null) => {
  if (!playerOverview || !playerOverview['primary_position'] || !userConfig) return '-';

  let position = playerOverview['primary_position'];

  position = staticLanguageMap[position][userConfig.language];

  if (playerOverview['secondary_position'] in staticLanguageMap) {
    position += ` (${staticLanguageMap[playerOverview['secondary_position']][userConfig.language]})`;
  }

  return position;
};


export const getDisplayCompetition = (playerOverview: FlexibleJsonMapping | undefined, competitions: CompetitionMap) => {
  if (playerOverview && playerOverview.club && playerOverview.club.current_competition_id) {
    return competitions[playerOverview.club.current_competition_id]?.name ?? '-';
  }
  return '-';
};
